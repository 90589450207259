import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const ButtonGroup = ( { buttonNames }) => {

  function acceptBtn(Btn) {

        if(Btn.name === "Accept") {
            return(
                <button 
                    className="btn btn-primary  new_button mr-1"
                    variant="contained" 
                    size="small" 
                    onClick = {Btn.onClick}
                >
                    {Btn.name}
                </button>
                )
            } else {
                return(
                    <button 
                        className="btn btn-secondary  new_button mr-1"
                        variant="contained" size="small" 
                        onClick = {Btn.onClick}
                    >
                        {Btn.name}
                    </button>
                    )
            }
    }

    return(
        <Stack direction="row" spacing={1}> 
        { buttonNames.map( (Btn) => {
             return acceptBtn(Btn)
        })
    }
    </Stack>
    )
}

export default ButtonGroup;