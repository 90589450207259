import React, { useState, useEffect } from 'react';
import LoadingOverlay from "react-loading-overlay";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Stack from '@mui/material/Stack';
import DistributionTemperatureListing from './DistributionTemperatureListing';
import DistributionTemperatureData from './DistributionTemperatureData';

const DistributionTemperature = (props) => {
    const { actions, distributionTemperature } = props;
    const [searchFilter, setSearchFilter] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const cardTheme = {
        cardHeaderStylePref: {
            background: 'linear-gradient(to right bottom, #D8D8D8, #E0E0E0)',
            color: '#000000',
            height: 30,
            padding: 0,
        }
    }
    const [distributionTemperatures, setDistributionTemperatures] = useState("");
    const [distributionTemperatureEnglish, setDistributionTemperatureEnglish] = useState("");
    const [isEditModelVisible, setIsEditModelVisible] = useState(false);

    useEffect(() => {
        actions.getDistributionTemperature();
        setIsLoading(false);
    }, []);

    const handleChangeValue = (e) => {
        setDistributionTemperatures(distributionTemperatures);
        setDistributionTemperatureEnglish(distributionTemperatureEnglish);
    };

    const closemodal = () => {
        setDistributionTemperatures("");
        setDistributionTemperatureEnglish("");
        setIsEditModelVisible(false);
    };

    // For Saving the Room details
    const saveMethod = (e) => {
        let req = {};
        req.roomid = 0;
        req.distributionTemperature = distributionTemperatures;
        req.createdby = "loggedinusername";
        req.distributionTemperatureEnglish = distributionTemperatureEnglish;
    };

    return (
        <>
            <LoadingOverlay active={isLoading} spinner>
                <Box sx={{ flexGrow: 1 }}>
                    <Stack direction="row" spacing={1}>
                        <label className='title_main mt-1 ml-4'
                        >
                            {t("distributiontemperature.distributiontemperature")}
                        </label>
                    </Stack>
                </Box>

                <Container maxWidth="xl">
                    <Card variant="outlined">
                        <CardHeader title={t("phase.SearchPlaceholder")} />
                        <Divider />
                        <CardContent>
                            <DistributionTemperatureData onSearch={(data) => setSearchFilter(data)} />
                        </CardContent>
                    </Card>
                    <br />
                    <DistributionTemperatureListing distributionTemperature={distributionTemperature} actions={actions} searchFilter={searchFilter} setIsLoading={setIsLoading} />
                    <br />
                </Container>
                <br />
                <div>
                    <Modal
                        dialogClassName="my-modal"
                        centered
                        show={isEditModelVisible}
                    >
                        <Modal.Header closeButton centered="true" className="bg-theme-blue">
                            <h5 className="modal-title">{t("distributiontemperature.createnewdistributiontemperature")}
                            </h5>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-4 m0">
                                <div className="col-12">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="" className="col-form-label">
                                                    {t("distributiontemperature.temperaturediscription")}<span className="req">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="distributiontemp"
                                                    className="form-control custom-form-control"
                                                    name="distributionTemperature"
                                                    value={distributionTemperatures}
                                                    onChange={handleChangeValue}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="" className="col-form-label">
                                                    {t("distributiontemperature.distributionTemperatureEnglish")}<span className="req">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    id="distributiontempeng"
                                                    name="distributionTemperatureEnglish"
                                                    className="form-control custom-form-control"
                                                    value={distributionTemperatureEnglish}
                                                    onChange={handleChangeValue}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <button
                                type="button"
                                id="savemethod"
                                className="btn btn-primary btn-inner-close"
                                onClick={(e) => saveMethod(e)}
                            >
                                {t('phase.save')}
                            </button>
                            <button
                                id="closeButtonForSave"
                                type="button"
                                className="btn btn-black btn-inner-close"
                                onClick={(e) => closemodal(e)}
                            >
                                {t('commonlabels.cancelbutton')}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default DistributionTemperature;