import React, { useEffect, useState } from "react";
import ProcessShippingRequestListingComponent from "./ProcessShippingRequestListingComponent";
import ShippingDataSearchComponent from "./ProcessShippingSearchComponent";
import { useTranslation } from "react-i18next";
import StatusFlow from "../shippingRequest/statusFlow";

import {
  HAND_BACK,
  CANCEL_REQUEST,
  PROCESS_MANAGER,
  CLOSE,
  INSPECT,
} from "../../constants";
import PageLayout from "../common/PageLayout";

const ProcessShippingRequest = (props) => {
  const {
    actions,
    processShippmentList,
    allMaterials,
    shippingSearchAutocompleteData,
    shippingType,
    tableProcessShippingOptions
  } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shippingDetail, setShippingDetail] = useState({});
  const [actionName, setActionName] = useState("");
  const { t } = useTranslation();

  useEffect(async () => {
    await actions.getProcessShippmentList();
    setIsLoading(false)
  }, []);

  const setRedirection = () => {
    actions.redirectAction("/process/shipping");
  };

  const handleHandBack = (rowData) => {
    setActionName(HAND_BACK);
    setShippingDetail(rowData);
    actions.saveTableData(rowData);
    setRedirection();
  };

  const handleCancelRequest = (rowData) => {
    setActionName(CANCEL_REQUEST);
    setShippingDetail(rowData);
    actions.saveTableData(rowData);
    setRedirection();
  };

  const handleCloseRequest = (rowData) => {
    setActionName(CLOSE);
    setShippingDetail(rowData);
    actions.saveTableData(rowData);
    setRedirection();
  };
  const handlerShippingInspect = (rowData) => {
    setActionName(INSPECT);
    setShippingDetail(rowData);
    setRedirection();
  };

  const getStatusFlow = () => {
    if (actionName === HAND_BACK) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={HAND_BACK}
          roleName={PROCESS_MANAGER}
          goBack="/process/shipping"
        />
      );
    }
    if (actionName === INSPECT) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={INSPECT}
          roleName={PROCESS_MANAGER}
          goBack="/process/shipping"
        />
      );
    }
    if (actionName === CANCEL_REQUEST) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={CANCEL_REQUEST}
          roleName={PROCESS_MANAGER}
          goBack="/process/shipping"
        />
      );
    }

    if (actionName === CLOSE) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={CLOSE}
          roleName={PROCESS_MANAGER}
          goBack="/process/shipping"
        />
      );
    }
  };
  const searchFilterData = (data) => {
    setSearchFilter(data);
  };

  return (
    <>
      {Object.keys(shippingDetail).length > 0 &&
        shippingDetail.id !== 0 &&
        getStatusFlow()}

      <PageLayout
        className="title_main mt-1"
        title={t("process_shipping_request.process_shipping_request")}
        isLoading={isLoading}
        searchComponent={
          <ShippingDataSearchComponent
            onSearch={searchFilterData}
            searchKey="processShippingRequest"
          />
        }
        mainComponent={
          !isLoading &&
          <ProcessShippingRequestListingComponent
            processShippmentList={processShippmentList}
            actions={actions}
            searchFilter={searchFilter}
            setIsLoading={setIsLoading}
            allMaterials={allMaterials}
            handleHandBack={handleHandBack}
            handleCancelRequest={handleCancelRequest}
            handleCloseRequest={handleCloseRequest}
            handlerShippingInspect={handlerShippingInspect}
            shippingSearchAutocompleteData={shippingSearchAutocompleteData}
            shippingType={shippingType}
            tableProcessShippingOptions={tableProcessShippingOptions}
          />
        }
      />
    </>
  );
};

export default ProcessShippingRequest;
