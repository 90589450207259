import types from "./types";
import fetchData from "../util";

const saveUser = (...params) => {
  return fetchData(types.SAVE_USER, "saveUser", params);
};
const editUser = (...params) => {
  return fetchData(types.EDIT_USER, "editUser", params);
};

const getMasterDataRoles = () => {
  return fetchData(types.GET_ALL_MASTER_ROLES, "getMasterDataRoles");
};
const getAllAddress = () => {
  return fetchData(types.GET_ALL_ADDRESS, "getAllAddress");
};
const getAllAddressList = () => {
  return fetchData(types.GET_ALL_ADDRESS_LIST, "getAllAddressList");
};
const getAllCountry = () => {
  return fetchData(types.GET_ALL_COUNTRY, "getAllCountry");
};
const getAllAddressNotApproved = () => {
  return fetchData(types.GET_ADDRESS_NOT_APPROVED, "getAllAddressNotApproved");
};
const getAddress = (...params) => {
  return fetchData(types.GET_ADDRESS, "getAddress", params);
};
const getAddressShipping = (...params) => {
  return fetchData(types.GET_ADDRESS_SHIPPING, "getAddressShipping", params);
};
const saveAddress = (...params) => {
  return fetchData(types.SAVE_ADDRESS, "saveAddress", params);
};
const deleteAddress = (...params) => {
  return fetchData(types.SAVE_ADDRESS, "deleteAddress", params);
};
const getMyAddress = (...params) => {
  return fetchData(types.GET_MY_ADDRESS, "getMyAddress", params);
};
const editAddress = (...params) => {
  return fetchData(types.EDIT_ADDRESS, "editAddress", params);
};
const updateAddressAccess = (...params) => {
  return fetchData(types.EDIT_ADDRESS, "updateAddressAccess", params);
};
const updateAddressApproval = (...params) => {
  return fetchData(types.EDIT_ADDRESS, "updateAddressApproval", params);
};
const getAddressRecipient = (...params) => {
  return fetchData(types.EDIT_ADDRESS, "getAddressRecipient", params);
};
const getAllUsers = () => {
  return fetchData(types.GET_USERS, "getAllUsers");
};
const isLoader = (payload = {}) => {
  return {
    type: types.IS_LOADER,
    payload,
  };
};

const updateApproveAddressReviewStatus = (...params) => {
  return fetchData(types.UPDATE_APPROVAL_ADDRESS_REVIEW_STATUS, "updateApproveAddressReviewStatus", params);
}

const getSelectedAddressData = (...params) => {
  return fetchData(types.GET_SELECTED_ADDRESS_DATA, "getSelectedAddressData", params);
}

const setTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_ADDRESS_TABLE_OPTIONS, payload: data });
  };
};
const setApprovalTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_ADDRESS_APPROVAL_TABLE_OPTIONS, payload: data });
  };
};
const setAddressSearchData = (data)=>{
  return {
    type: types.SET_ADDRESS_SEARCH_DATA,
    payload: data
  }
}
const setApprovalAddressSearchData = (data)=>{
  return {
    type: types.SET_APPROVAL_ADDRESS_SEARCH_DATA,
    payload: data
  }
}
export default {
  setApprovalAddressSearchData,
  setAddressSearchData,
  setTableOptions,
  setApprovalTableOptions,
  getMasterDataRoles,
  saveUser,
  editUser,
  getAllAddress,
  getMyAddress,
  getAddress,
  saveAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getAllAddressNotApproved,
  getAddressShipping,
  isLoader,
  getAllCountry,
  deleteAddress,
  getAllUsers,
  getAllAddressList,
  updateApproveAddressReviewStatus,
  getSelectedAddressData,
};
