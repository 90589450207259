import React from "react";
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { STATUS, SUBMIT, USERROLES, INSPECT } from "../../constants";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SaveChangesRequest = (props) => {

  const { t } = useTranslation();
  const {
    // getShippingResponse,
    // shippingRequestDetails,
    // handleDBMaterialSaveAndClose,
    // handleDBMaterialCancel,
    handleMaterialSubmit,
    handleMaterialSaveAndClose,
    handleMaterialSave,
    handleMaterialCancel,
    classes,
    currentStatus,
    shippingActionType
  } = props
  const history = useHistory();
  const userType = JSON.parse(localStorage.getItem("userType"));

  if (shippingActionType === INSPECT) {
    return (
      <div className="col-4 pull-right pt10 pr-0 text-right">
    <Button
      className="cancelbutton mr-2 mb-3 no-marggin"
      variant="contained"
      size="small"
      onClick={() => handleMaterialCancel()}
    >
      {t('materials.back')}
    </Button>
    </div>
    )
  }
  if(shippingActionType === SUBMIT){
    return(
      <div className="col-4 pull-right pt10 pr-0 text-right">
      <Button variant="contained" size="small" className="Savebutton mr-2 mb-3" onClick={() => handleMaterialSubmit(STATUS.REGULATORY_REVIEWER_1)}>{t('shipping_request.submit')}</Button>
      <Button
     variant="contained" size="small" className="cancelbutton mr-2 mb-3 no-marggin"
      onClick={() => handleMaterialCancel()}
    >
      {t('commonlabels.cancelbutton')}
    </Button>
    </div>
    )
  }
  if (currentStatus === STATUS.INBOX) {
    return (
      <div className="col-4 pull-right pt10 pr-0 text-right">
        <Button
          className="btn btn-primary new_button"

          variant="contained" size="small"
          onClick={() => handleMaterialSaveAndClose(STATUS.INBOX, false, false)}
        >
          {t('commonlabels.saveButton')}
        </Button>&nbsp;
        <Button
          className="btn btn-primary new_button"
          variant="contained"
          size="small"
          onClick={() =>
            handleMaterialSaveAndClose(STATUS.SHIPER_REVIEW_DONE, true, true)
          }
        >
          {t("commonlabels.acceptButton")}
        </Button>
        &nbsp;
        <Button
          className="btn btn-secondary cancelbutton"
          onClick={() => handleMaterialSaveAndClose(STATUS.INPUT, true, false)}
          variant="contained"
          size="small"
        >
          {t("materials.deny_approval")}
        </Button>
        &nbsp;
        <Button
          className="btn btn-secondary cancelbutton"
          variant="contained"
          size="small"
          onClick={() => handleMaterialCancel()}
        >
          {t("commonlabels.cancelbutton")}
        </Button>
      </div>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={2} className={classes.Addbtn}>
        {(userType.includes(USERROLES.PROCESSMANAGER) || userType.includes(USERROLES.APPLICANT)) ?
          <>
            <div className="pt10">
              {(currentStatus === STATUS.INPUT || currentStatus === undefined) ?
                <Button variant="contained" size="small" className="Savebutton mr-2 mb-3" onClick={() => handleMaterialSubmit(STATUS.REGULATORY_REVIEWER_1)}>{t('shipping_request.submit')}</Button>
                : <></>
              }
              <Button
                variant="contained"
                size="small"
                className="Savebutton mr-2 mb-3"
                disabled={(currentStatus === STATUS.REGULATORY_REVIEWER_1 || currentStatus === STATUS.REGULATORY_REVIEWER_2)}
                onClick={() => handleMaterialSaveAndClose()}
              >
                {t('shipping_request.save_and_close')}
              </Button>
              <Button
                variant="contained"
                size="small"
                className="Savebutton mr-2 mb-3"
                disabled={(currentStatus === STATUS.REGULATORY_REVIEWER_1 || currentStatus === STATUS.REGULATORY_REVIEWER_2)}
                onClick={() => handleMaterialSave()}
              >
                {t('commonlabels.saveButton')}
              </Button>
              <Button
                variant="contained"
                size="small"
                className="cancelbutton mr-2 mb-3"
                onClick={() => handleMaterialCancel()}
              >
                {t('commonlabels.cancelbutton')}
              </Button>
            </div></>
          :
          <>
            <div className="pt10">
              {(currentStatus === STATUS.INPUT || currentStatus === undefined) ?
                <Button variant="contained" disabled size="small" className="mb-3 mr-2 " onClick={() => handleMaterialSubmit(STATUS.REGULATORY_REVIEWER_1)}> {t('shipping_request.submit')}</Button>
                : <></>
              }
              <Button variant="contained" disabled size="small" className="mb-3 mr-2 " onClick={() => handleMaterialSaveAndClose()}> {t('shipping_request.save_and_close')}</Button>
              <Button variant="contained" disabled size="small" className="mb-3 mr-2 no-marggin" onClick={() => handleMaterialSave()}> {t('commonlabels.saveButton')} </Button>
              <Button variant="contained" size="small" className="cancelbutton mr-2 mb-3 no-marggin" onClick={() => {
                if (userType.length === 1 && userType.includes(USERROLES.ADMIN)) {
                  history.push("/approveAddress")
                } else {
                  history.push("/regulatoryReviewer")
                }
              }} >  {t('commonlabels.cancelbutton')
                }  </Button>
            </div>
          </>}
      </Stack>

      {/* } */}

    </>
  )


}


export default SaveChangesRequest;