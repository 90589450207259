import { STATUS } from "../constants";
import { useTranslation } from "react-i18next";

export const sortFunction = (field) => {
  return (a, b) => {
    const val1 = (a[field] || "").trim();
    const val2 = (b[field] || "").trim();
    const val1Num = Number(val1);
    const val2Num = Number(val2);
    if (Number.isNaN(val1Num) || Number.isNaN(val2Num)) {
      return val1.localeCompare(val2);
    } else {
      return val1Num - val2Num;
    }
  };
};

export const stringSortFunction = (field) => {
  return (a, b) => {
    const val1 = (a[field] || "").trim();
    const val2 = (b[field] || "").trim();
    return val1.localeCompare(val2);
  };
};

export const dateSortFunction = (field) => {
  return (a, b) => {
    const val1 = a[field];
    const val2 = b[field];
    const val1Num = val1 !== null ? new Date(val1) : 0;
    const val2Num = val2 !== null ? new Date(val2) : 0;
    if (val1Num > val2Num) {
      return 1;
    } else if (val1Num < val2Num) {
      return -1;
    } else {
      return 0;
    }
  };
};

export const SortFunctionNew = (field) => {
  return (a, b) => {
    const val1 = a[field];
    const val2 = b[field];
    const val1Num = val1 && val1 !== null ? val1.toString().toLowerCase() : "";
    const val2Num = val2 && val2 !== null ? val2.toString().toLowerCase() : "";
    if (val1Num > val2Num) {
      return -1;
    } else if (val1Num < val2Num) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const checkRole = (userType) => {
  if (userType.includes(1)) {
    return true;
  } else if (userType.includes(2)) {
    return true;
  } else if (userType.includes(3)) {
    return true;
  }
};

export const ifRequestCompletedApprovals = (status) => {
  return ![
    null,
    undefined,
    STATUS.INPUT,
    STATUS.INBOX,
    STATUS.AWAITING_FOR_APPROVAL,
    STATUS.REGULATORY_REVIEWER_1,
    STATUS.REGULATORY_REVIEWER_2,
  ].includes(status)
}

export const SortStatusFunctionNew = (field) => {
  const { t } = useTranslation();
  return (a, b) => {
    const val1 = t("status." + a[field]);
    const val2 = t("status." + b[field]);
    const val1Num = val1 && val1 !== null ? val1.toString().toLowerCase() : "";
    const val2Num = val2 && val2 !== null ? val2.toString().toLowerCase() : "";
    if (val1Num > val2Num) {
      return -1;
    } else if (val1Num < val2Num) {
      return 1;
    } else {
      return 0;
    }
  };
};