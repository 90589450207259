import React from "react";
import { useState,useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import { useStyles } from "../common/ThemeStye";
import Select from "../common/CustomSelect";
import { STATUS, SUBMIT, USERROLES } from "../../constants";
import FileManager from "../common/FileManager/FileManager";
import { ifRequestCompletedApprovals } from "../../Services/util";
import _ from "lodash";
import {
  CANCEL_REQUEST, INSPECT, HAND_BACK
} from "../../constants";

const ShipmentReviewerSection = (props) => {
  const {actions,shippingActionType, extra, readOnly, brokers, currentStatus, shippingRequestDetails,attachmentRead=true} = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const userType = JSON.parse(localStorage.getItem("userType"));

  const brokerType=brokers.find((obj)=>obj.id === extra.broker_id)
  const isbrokervalid = (obj) =>{
    if(obj?.is_active == null || Object.keys(obj).length == 0) return true
      if(obj?.is_active == true){
        return true
      }
   else{
        return false;
      }
  }

  const inprogressstatus = [
    null,
    undefined,
    STATUS.INPUT,
    STATUS.AWAITING_FOR_APPROVAL,
    STATUS.INBOX
  ]
  return (
    <>
    <br></br>
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t('shipping_request.review')}{" "}
            </Typography>
          }
          className={classes.header}
        />
       <CardContent className={currentStatus === STATUS.INBOX?(shippingActionType === CANCEL_REQUEST || shippingActionType === INSPECT || shippingActionType === HAND_BACK)?'':"importantSection":''}>
          <div className="row  mt-2 mr-0 no-marrgin " data-name="shipment_review_section">

            <div className="col-lg-2  col-12 form-outline form-margin">
              <label className="form-label">{t('shipping_request.attach_documents')}:</label>

              <FileManager
                  tableName="reviewer"
                files={extra?.reviewer_attach_documents}
                onChange={(files) =>
                  actions.setExtraData({
                    ...extra,
                    'reviewer_attach_documents' : files,
                  })}
                multiple={true}
                readOnly={ shippingActionType === INSPECT || shippingActionType === SUBMIT || !attachmentRead  || shippingActionType === CANCEL_REQUEST || shippingActionType === HAND_BACK}
                disabled={!(userType.includes(USERROLES.PROCESSMANAGER) || userType.includes(USERROLES.APPLICANT) || userType.includes(USERROLES.ADMIN)) || shippingActionType === CANCEL_REQUEST}

              />

            </div>

            <div className="col-lg-2  col-12 form-outline form-margin" style={{border:(isbrokervalid(brokerType))?"":"3px solid orange"}}>
              <label className="form-label">{t('shipping_request.broker_information')}:</label>
              <Select
                className="form-control form-textbox"
                name={"broker_id"}
                list={
                  inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
                    ? brokers.filter(item => item.is_active === true)
                    : brokers
                }
                value={extra?.broker_id || ""}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
                // readOnly={readOnly}
                isDisabled={shippingActionType === CANCEL_REQUEST || shippingActionType === HAND_BACK || readOnly || !(userType.includes(USERROLES.PROCESSMANAGER) || userType.includes(USERROLES.APPLICANT) || userType.includes(USERROLES.ADMIN)) || currentStatus === STATUS.IN_DELIVERY}

                            />
            </div>

            <div className="col-lg-3  col-12 form-outline form-margin">
              <label className="form-label">{t('shipping_request.broker_contact_details')}:</label>
              <div dangerouslySetInnerHTML={{__html:brokers?.find(broker=>broker?.id === extra?.broker_id)?.broker_address}}></div>
            </div>
            
          </div>
          
        </CardContent>
      </Card>
    </>
  );
};

export default ShipmentReviewerSection;
