export default {
  GET_ADDRESS: "GET_ADDRESS",
  GET_ADDRESS_SHIPPING: "GET_ADDRESS_SHIPPING",
  GET_ADDRESS_NOT_APPROVED: "GET_ADDRESS_NOT_APPROVED",
  GET_ALL_ADDRESS: "GET_ALL_ADDRESS",
  GET_ALL_ADDRESS_LIST: "GET_ALL_ADDRESS_LIST",
  GET_MY_ADDRESS: "GET_MY_ADDRESS",
  GET_ALL_COUNTRY: "GET_ALL_COUNTRY",
  SAVE_ADDRESS: "SAVE_ADDRESS",
  EDIT_ADDRESS: "EDIT_ADDRESS",
  IS_LOADER: "IS_LOADER",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  GET_USERS: "GET_USERS",
  SAVE_USER: "SAVE_USER",
  EDIT_USER: "EDIT_USER",
  GET_ALL_MASTER_ROLES: "GET_ALL_MASTER_ROLES",
  UPDATE_APPROVAL_ADDRESS_REVIEW_STATUS: "UPDATE_APPROVAL_ADDRESS_REVIEW_STATUS",
  GET_SELECTED_ADDRESS_DATA: "GET_SELECTED_ADDRESS_DATA",
  SET_ADDRESS_TABLE_OPTIONS:"SET_ADDRESS_TABLE_OPTIONS",
  SET_ADDRESS_APPROVAL_TABLE_OPTIONS:"SET_ADDRESS_APPROVAL_TABLE_OPTIONS",
  SET_ADDRESS_SEARCH_DATA:"SET_ADDRESS_SEARCH_DATA",
  SET_APPROVAL_ADDRESS_SEARCH_DATA:"SET_APPROVAL_ADDRESS_SEARCH_DATA"
};
