import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import TwoWeeksListingComponent from "./TwoWeeksListingComponent";
import TwoWeeksSearch from "./TwoWeeksSearch";
const CountryTwoWeeksComponent = (props) => {
  const { actions, ShippingsTwoWeeks, countries,countrie } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  //const [row, setRow] = useState([]);
  //const { t } = useTranslation();
  useEffect(() => {
    actions.getCountries().then(() => {
      actions.getShippingsTwoWeeks().then((res) => {
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <div className="form-padding twoweeksdiv2">
          <TwoWeeksSearch
            countries={countrie}
            onSearch={(data) => setSearchFilter(data)}
            searchKey={"twoweekSearch"}
          />
        </div>
        <div className="form-padding  twoweeksdiv">
          <TwoWeeksListingComponent
            listData={ShippingsTwoWeeks}
            actions={actions}
            searchFilter={searchFilter}
            setIsLoading={setIsLoading}
          />
        </div>
      </LoadingOverlay>
    </>
  );
};

export default CountryTwoWeeksComponent;
