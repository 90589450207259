import { React, useState, forwardRef, useEffect } from "react";
import moment from "moment";
import Model from "../../shared/model"; 
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { addressSearchStyles } from "../../Admin/Address/addressStyles";

import MaterialTable from "@material-table/core";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from 'xlsx';
import _ from 'lodash';
import {DateFormat} from "../../common/utils/DateUtil";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { dateSortFunction, SortFunctionNew } from "../../../Services/util";

const TwoWeeksListingComponent = (props) => {
  const { listData, searchFilter } = props;
  const [filteredData, setfilteredData] = useState(listData);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
const [requiredFileName, setrequiredFileName] = useState("");
const classes = addressSearchStyles();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  const handleCSVExport = async () => {
 
    let dataForExport = exportDataBuilder()
    let wb = utils.book_new(), ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Delivery time more than 14 days");
    writeFile(wb,   requiredFileName+".xlsx");
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>
              
             
  
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
   
     
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport()
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>
        
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportDataBuilder = () => {
    let copyOfFilteredData
    if (filteredData.length === 0) {
        copyOfFilteredData = _.cloneDeep(filteredData)
    }
    else if (filteredData.length > 0) {
        copyOfFilteredData = _.cloneDeep(filteredData)
    }
   
    copyOfFilteredData.forEach((item) => {
        //changing column names
        if (item.created_on) {
          item.created_on =   DateFormat(item.created_on,i18n.resolvedLanguage);
        }
        if (item.date_of_recipient) {
          item.date_of_recipient =   DateFormat(item.date_of_recipient,i18n.resolvedLanguage);
        } if (item.editdate) {
          item.editdate =   DateFormat(item.editdate,i18n.resolvedLanguage);
        } if (item.shipment_date_mon) {
          item.shipment_date_mon =   DateFormat(item.shipment_date_mon,i18n.resolvedLanguage);
        }
         
        item["Tracking Number"] = item["tracking_number"];
        item["Dispatch Date - Elanco"] =  item["shipment_date_mon"];
        item["Date of Receipt"] = item["date_of_recipient"];
        item["Recipient "] = item["final_receipt"];
        item["Country of Destination"] = item["name_english"]+ " " + "(" +item["code"]+ ")";
        item["Edit User"] = item["updated_by"];
        item["Status"] = t("status."+item["status"]);
        
        item[("Processing Time (Days)")] = item["days"];

        delete item["days"];

        delete item["internal_distribution_number"];
        delete item["code"];
        delete item["address"];
        delete item["address_id"];
        delete item["close_date"];
         delete item["sap_delivery_number"];
        delete item["german_name"];
        delete item["entity"];
        delete item["email"];
        delete item["country_id"];
        delete item["valid"];
        delete item["updated_by"];
        delete item["updated_on"];
        delete item["id"];
        delete item["created_by"];
        delete item["created_on"];
        delete item["shipment_date_lev"];
        delete item["country_id"];
        delete item["tracking_number"];
        delete item["shipment_date_mon"];
        delete item["date_of_recipient"];
        delete item["final_receipt"];
        delete item["name_english"];
        delete item["updated_by"];
        delete item["status"];



        					

        




     
      
        for (let key in item) {
            if (item[key] === null) {
                item[key] = ""
            }
        }
    })
    return _.cloneDeep(copyOfFilteredData);
}
  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key],
    );
    const filteredData =
      listData &&
      listData.filter((item) => {
        let startDate = moment(item.shipment_date_mon);
        let endDate = moment(item.close_date);
        let result = endDate.diff(startDate, "days");
        if (result < 14) {
          return false;
        } else {
          for (let searchParam of validSearchKeys) {
            if (
              ["shipment_date_mon", "date_of_recipient"].includes(searchParam)
            ) {
              if (searchParam === "shipment_date_mon") {
                const createDate = DateFormat(item.shipment_date_mon,i18n.resolvedLanguage);
                const startDate = DateFormat(searchFilter[searchParam],i18n.resolvedLanguage);
                // const startDate = moment
                //   .parseZone(searchFilter[searchParam])
                //   .format("DD-MM-YYYY");
                if (!(createDate === startDate)) {
                  return false;
                }
                continue;
              } else {
                const createDate = DateFormat(item.close_date,i18n.resolvedLanguage);
                // const endDate = moment
                //   .parseZone(searchFilter[searchParam])
                //   .format("DD-MM-YYYY");
                  const endDate = DateFormat(searchFilter[searchParam],i18n.resolvedLanguage);
                if (!(createDate === endDate)) {
                  return false;
                }
                continue;
              }
            }

            if (
              !item[searchParam] ||
              !item[searchParam]
                .toLowerCase()
                .includes(searchFilter[searchParam].toLowerCase())
            ) {
              return false;
            }
          }
          return true;
        }
      });
    setfilteredData(filteredData);
  }, [searchFilter, listData]);
  const { t, i18n } = useTranslation();
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };
  return (
    <>
    <div className="row m0 no_padding container-fluid">
        <div className="no_padding col-12 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
          <div className={classes.actionButtonsTop}>
           <button
              className="btn btn-primary btn-inner-close new_button mr-1"
             // disabled={get(addressSelected, "id", 0) === 0 ? true : false}
              onClick={() => {   setIsExportModalVisible(true); }}>
              {t("shippingrequest.export")}
            </button>
            </div>.
          {/* //  {inspectHideAndShow()} */}
          </div>
        </div>
      </div>
      <Card variant="outlined">
        <CardHeader
          title={t("approve_recipient.search_text")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
            <MaterialTable
              columns={[
                {
                  title: <span>{t("shipping_request.tracking_number")}</span>,
                  field: "tracking_number",
                  customSort: SortFunctionNew("tracking_number"),
                },
                {
                  title: <span>{t("shipping_request.dispatch_date_mon")}</span>,
                  render: (rowData) => (
                    <span>{dateLocalization(rowData.shipment_date_mon)}</span>
                  ),
                  customSort: dateSortFunction("shipment_date_mon"),
                },
                {
                  title: <span>{t("shipping_request.date_of_receipt")}</span>,
                  render: (rowData) => (
                    <span>{dateLocalization(rowData.close_date)}</span>
                  ),
                  customSort: dateSortFunction("close_date"),
                },
                {
                  title: <span>{t("shippingrequest.finalrecipient")}</span>,
                  field: "final_receipt",
                  customSort: SortFunctionNew("final_receipt"),
                },
                {
                  title: <span>{t("shippingrequest.addreesedcountry")}</span>,
                  field: "name_english",
                  render: (rowData) =>
                    `${rowData.name_english} (${rowData.code})`,
                },
                {
                  title: <span>{t("process_shipping_request.edit_user")}</span>,
                  field: "updated_by",
                  customSort: SortFunctionNew("updated_by"),
                },
                {
                  title: <span>{t("address.Status")}</span>,
                  render: (rowData) => t("status." + rowData.status),
                  customSort: SortFunctionNew("status"),
                },
                {
                  title: <span>{t("quries.timeOfDelivery")}</span>,
                  render: (rowData) => {
                    let startDate = moment(rowData.shipment_date_mon);
                    let endDate = moment(rowData.close_date);
                    let result = endDate.diff(startDate, "days");
                    return <span>{result}</span>;
                  },
                  customSort: (a, b) => {
                    let startDateA = moment(a.shipment_date_mon);
                    let endDateA = moment(a.close_date);
                    let resultA = endDateA.diff(startDateA, "days");
                    let startDateB = moment(b.shipment_date_mon);
                    let endDateB = moment(b.close_date);
                    let resultB = endDateB.diff(startDateB, "days");
                    return resultB - resultA;
                  },
                },
              ]}
              data={filteredData}
              title="Render Image Preview"
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              icons={tableIcons}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: {
                  height: "30px",
                  padding: "0px",
                },

                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 40],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
          </div>
        </CardContent>
      </Card>
      <Model
          showheader={isExportModalVisible}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBody()}
          modalfooter={exportModalFooter()}
        ></Model>
    </>
  );
};

export default TwoWeeksListingComponent;
