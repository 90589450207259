import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import shipping from "../../redux/shipping";
import ProcessShippingRequest from "../processShippingRequest/ProcessShippingRequest";
import CheckProcessShippingRequestComponent from "../processShippingRequest/CheckProcessShippingRequestComponent";
import materials from "../../redux/materials";
import regulatory from "../../redux/regulatory";
import ShipmentDocumentationFile from "./shipmentdocumentation";
import reportsActions from "../../redux/reports";
import tableListing from "../../redux/tableListing";
const {
  saveReportsAttachments,
  getReportsAttachments,
  deleteReportsAttachments,
} = reportsActions.actions;
const { getAllShippingData, setProcessShippingTableOptions } = regulatory.actions;
const {
  getProcessShippmentList,
  getShippingRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  getShippingActionType,
  clearShippingMaterialSelectedValue,
  addShippingMaterialSelect,
  redirectAction,
  getShippingAttachment,
  getShipperDetails,
  getShippingType,
  revertApproval
} = shipping.actions;

const { saveTableData,setProcessShippingSearchData } = tableListing.actions;

const mapStateToProps = (state) => ({
  processShippmentList: state[shipping.name].processShippmentList,
  allMaterials: state[materials.name].allMaterials,
  shippingSearchAutocompleteData:
    state[shipping.name].shippingSearchAutocompleteData,
  shippingType: state[shipping.name].shippingType,
  tableProcessShippingOptions: state[regulatory.name].tableProcessShippingOptions,
  processShippingSearchData: state[tableListing.name].processShippingSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setProcessShippingSearchData,
      setProcessShippingTableOptions,
      deleteReportsAttachments,
      saveReportsAttachments,
      getReportsAttachments,
      getAllShippingData,
      getProcessShippmentList,
      getShippingRequestDetails,
      getDeputyShippingDetails,
      getMaterialShippingDetails,
      getShippingActionType,
      clearShippingMaterialSelectedValue,
      addShippingMaterialSelect,
      redirectAction,
      getShippingAttachment,
      getShipperDetails,
      saveTableData,
      getShippingType,
      revertApproval,
    },
    dispatch,
  ),
});

export default {
  ProcessShippingRequest: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProcessShippingRequest),
  CheckProcessShippingRequestComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CheckProcessShippingRequestComponent),
  ShipmentDocumentationFile: connect(mapStateToProps, mapDispatchToProps)(ShipmentDocumentationFile),
};
