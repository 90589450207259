import React from 'react'

import "./invoice.css"

export const InvoiceItemSize4 = ({ title, children }) => {
    return (
      <div className="col-6 border_shown">
        <div className="invoice_section_header">{title}</div>
        {children}
      </div>
    );
  };
  
export const InvoiceItemSize12 = ({ title, children }) => {
    return (
      <div className="col-12 border_shown">
        <div className="invoice_section_header">{title}</div>
        {children}
      </div>
    );
  };