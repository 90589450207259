import axios from '../api.js';
import * as config from '../config';

export default {
    getShippingHistory: async (ids) => {
        const response = await axios.post(config.baseURL + '/api/process/shippment/logs', {ids});
        return response.data;
    },
    setShippingStatus: async(data)=>{
        const response = await axios.post(config.baseURL + '/api/process/shippment/status/update', data);
        return response.data;
    },
    getShippingHistoryDeatails: async(id)=>{
        const response = await axios.get(config.baseURL + `/api/process/shippment/details/${id}`);
        return response.data;
    },
    checkIfShippmentIsValid: async(id)=>{
        const response = await axios.get(config.baseURL + `/api/validate/shipping/${id}`);
        return response.data;
    },
    excelSelectedData :async(id) => {
            const response = await axios.get(config.baseURL + `/api/exportSelectedData/${id}`);
            return response;

}

}
