import { get } from "lodash";
export const isShippingRequestChanged = (shippingRequestDetail, fields) => {
  let isFieldChanged = false;

  if (shippingRequestDetail.length > 0) {
    if (shippingRequestDetail[0].address_id !== fields.address_id) {
      isFieldChanged = true;
    }

    if (
      shippingRequestDetail[0].internal_distribution_number !==
      fields.internal_distribution_number
    ) {
      isFieldChanged = true;
    }

    if (shippingRequestDetail[0].recipient_id !== fields.recipient_id) {
      isFieldChanged = true;
    }

    if (
      shippingRequestDetail[0].distributiontemperature_id !==
      fields.distributiontemperature_id
    ) {
      isFieldChanged = true;
    }
  }

  return isFieldChanged;
};

export const getAllMaterials = (materialShippingDetails) => {
  const getAllMaterials = materialShippingDetails.map(
    (materialShippingDetail) => {
      const shippingMaterial = {
        id: materialShippingDetail.id,
        materailName: {
          id: materialShippingDetail.materialdescription_id,
          description: materialShippingDetail.description,
          label: materialShippingDetail.description,
          value: materialShippingDetail.materialdescription_id,
        },
        amountAndUnit: materialShippingDetail.amount,
        valueOfMaterial: materialShippingDetail.value_per_sample,
        batchNo: materialShippingDetail.batch_number,
        aggregation: materialShippingDetail.phase_id,
        countryOrigin: materialShippingDetail.country_id,
        comment: materialShippingDetail.c_comment,
        aggregationName: materialShippingDetail.phase_english,
        countryName: materialShippingDetail.name_english,
        materialNumber: materialShippingDetail.material_number,
        realSubNo: materialShippingDetail.real_material_number,
        tariffClassificationNo:
          materialShippingDetail.statistical_material_number,
        statMaterialNo: materialShippingDetail.statistical_material_number,
        approved: materialShippingDetail.approved,
        isHazardous: materialShippingDetail.is_hazardous,
        hazardousds: materialShippingDetail.hazardous_sds,
        quantity: materialShippingDetail.quantity,
        isBlindedMaterial: materialShippingDetail.is_blinded_material,
        transportClassification: materialShippingDetail.transport_class,
        packGroup: materialShippingDetail.pack_group,
        documents: get(materialShippingDetail, "documents", []),
        unit: materialShippingDetail.unit,
        containerType: materialShippingDetail.container_type,
        unitType:
          materialShippingDetail.abbrevaton == null
            ? ""
            : materialShippingDetail.abbrevaton,
        curency:
          materialShippingDetail.curency_type == null
            ? ""
            : materialShippingDetail.curency_type,
        manufactureCountry: materialShippingDetail.manufacture_country,
        hazard_doc_path: materialShippingDetail.hazard_doc_path,
        hazard_doc_name: materialShippingDetail.hazard_doc_name,
        htsCode: materialShippingDetail.hts_code,
      };

      return { ...materialShippingDetail, ...shippingMaterial };
    },
  );
  return getAllMaterials;
};

export const setMaterialDefaultChecked = (
) => {
  // const shippingMaterial = {
  //     country_id: countryOrigin,
  //     materialdescription_id: materailName.id,
  //     id: materailIdSelect || meterailId,
  //     amount: amountAndUnit,
  //     value_per_sample: valueOfMaterial,
  //     batch_number: batchNo,
  //     phase_id: aggregation,
  //     c_comment: comment,
  //     shipping_request_id: shippingRequestDetails[0].id,
  //     created_by: sessionStorage.getItem('userName'),
  //     materailName: {
  //       id: materailName.id,
  //       description: materailName.description,
  //       label: materailName.description,
  //       value: materailName.id
  //     },
  //     countryOrigin,
  //     amountAndUnit,
  //     valueOfMaterial,
  //     valueOfMaterial,
  //     batchNo,
  //     aggregation,
  //     comment
  //   }
};

export const setEmptyMaterail = () => {
  const meterailId = new Date().getTime();

  const shippingMaterial = {
    id: meterailId,
    materailName: {
      id: "",
      description: "",
      label: "",
      value: "",
    },
    amountAndUnit: "",
    valueOfMaterial: "",
    batchNo: "",
    aggregation: "",
    countryOrigin: "",
    comment: "",
    isHazardous: false,
    hazardousds:false,
    quantity: null,
    isBlindedMaterial: false,
    transportClassification: "",
    packGroup:"",
    documents: [],
    unit: "",
    containerType: "",
    unitType: "",
    curency: 0,
    manufactureCountry: "",
    hazard_doc_path: "",
    hazard_doc_name: "",
    htsCode: "",
  };

  return shippingMaterial;
};

export const updateShippingMaterail = (
  shippingMaterialSelect,
  materailIdSelect,
  shippingMaterial,
) => {
  return shippingMaterialSelect.map((m) => {
    if (m.id === materailIdSelect) {
      m.aggregation = shippingMaterial.aggregation;
      m.amountAndUnit = shippingMaterial.amountAndUnit;
      m.countryOrigin = shippingMaterial.countryOrigin;
      m.batchNo = shippingMaterial.batchNo;
      m.comment = shippingMaterial.comment;
      m.materailName.description = shippingMaterial.materailName.description;
      m.materailName.id = shippingMaterial.materailName.id;
      m.materailName.label = shippingMaterial.materailName.label;
      m.materailName.value = shippingMaterial.materailName.value;
      m.valueOfMaterial = shippingMaterial.valueOfMaterial;
      m.materailName.value = shippingMaterial.materailName.value;
      m.materailName.approved = shippingMaterial.materailName.approved;
      m.materialdescription_id = shippingMaterial.materailName.id;
      m.isHazardous = shippingMaterial.isHazardous;
      m.hazardousds = shippingMaterial.hazardousds;
      m.quantity = shippingMaterial.quantity;
      m.isBlindedMaterial= shippingMaterial.isBlindedMaterial;
      m.transportClassification = shippingMaterial.transportClassification;
      m.packGroup=shippingMaterial.packGroup;
      m.documents = get(shippingMaterial, "documents", []);
      m.unit = shippingMaterial.unit;
      m.containerType = shippingMaterial.containerType;
      m.unitType = shippingMaterial.unitType;
      m.curency = shippingMaterial.curency;
      m.manufactureCountry = shippingMaterial.manufactureCountry;
      m.hazard_doc_path = shippingMaterial.hazard_doc_path;
      m.hazard_doc_name = shippingMaterial.hazard_doc_name;
      m.htsCode = shippingMaterial.htsCode;
      m.hts_code_germany =  shippingMaterial.hts_code_germany;
      m.hts_code_china =  shippingMaterial.hts_code_china;
      m.un_no =  shippingMaterial.un_no ||  shippingMaterial.materailName.un_no;
      m.materailName.un_no =  shippingMaterial.un_no ||  shippingMaterial.materailName.un_no;
    }
    return m;
});
};

export const updateAllShippingMaterails = (
  updateShippingMaterials,
  shippingRequestDetails,
) => {
  return updateShippingMaterials.map((materialObj) => ({
      country_id: materialObj.countryOrigin,
      materialdescription_id: materialObj.materailName.id,
      id: materialObj.id,
      amount: materialObj.amountAndUnit,
      value_per_sample: materialObj.valueOfMaterial,
      batch_number: materialObj.batchNo,
      phase_id: materialObj.aggregation,
      c_comment: materialObj.comment,
      shipping_request_id: shippingRequestDetails[0].id,
      created_by: sessionStorage.getItem("userName"),
      materailName: materialObj.materailName,
      countryOrigin: materialObj.countryOrigin,
      amountAndUnit: materialObj.amountAndUnit,
      valueOfMaterial: materialObj.valueOfMaterial,
      batchNo: materialObj.batchNo,
      aggregation: materialObj.aggregation,
      comment: materialObj.comment,
      isHazardous: materialObj.isHazardous,
      hazardousds:materialObj.hazardousds,
      quantity: materialObj.quantity,
      isBlindedMaterial: materialObj.isBlindedMaterial,
      transportClassification: materialObj.transportClassification,
      packGroup:materialObj.packGroup,
      documents: get(materialObj, "documents", []),
      unit: materialObj.unit,
      unitType: materialObj.unitType,
      containerType: materialObj.container_type,
      curency: materialObj.curency,
      manufactureCountry: materialObj.manufactureCountry,
      hazard_doc_path: materialObj.hazard_doc_path,
      hazard_doc_name: materialObj.hazard_doc_name,
      htsCode: materialObj.hts_code,
    }));
};
