import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import address from "../../redux/address";
import shipping from "../../redux/shipping";
import CommentsComponent from "./CommentsComponent";
import NewRequestCommentsComponent from "./NewRequestCommentsComponent";
import AlertTagNavbarComponent from "./AlertTagNavbarComponent";
import navBar from "../../redux/navbar";

const {
  getUserDetails,
  saveAlertDetails,
  getAlertCommentsByShippingRequestID,
  getAlertByuserId,
  setNewShippingRequestCommentsList,
  getAllusers,
  redirectAction,
  updateAlertCountByUserID,
  getUnreadAlertsList,
  clearshippingRequest,
  archiveAlertTag,
  unarchiveAlertTag
} = shipping.actions;

const { getAlertNotificationsByuserId } = navBar.actions

const mapStateToProps = (state) => ({
  isLoader: state[address.name].isLoader,
  allUsers: state[shipping.name].allUsers,
  shippingRequestDetails: state[shipping.name].shippingRequestDetails,
  AlertCommentsList: state[shipping.name].AlertCommentsList,
  newShippingRequestCommentsList: state[shipping.name].newShippingRequestCommentsList,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      clearshippingRequest,
      getUserDetails,
      saveAlertDetails,
      getAlertCommentsByShippingRequestID,
      getAlertByuserId,
      setNewShippingRequestCommentsList,
      getAllusers,
      redirectAction,
      updateAlertCountByUserID,
      getAlertNotificationsByuserId,
      getUnreadAlertsList,
      unarchiveAlertTag,
      archiveAlertTag
    },
    dispatch,
  ),
})

export default {
  CommentsComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CommentsComponent),
  AlertTagNavbarComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AlertTagNavbarComponent),
  NewRequestCommentsComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewRequestCommentsComponent),
};