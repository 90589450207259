import React from "react";

import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

import {
  STATUS,
  INSPECT,
  SUBMIT,
  HAND_BACK,
  EDIT,
  CANCEL_REQUEST,
  CLOSE,
} from "../../../constants";

const styles = {
  header: {
    width: "100%",
    height: 30,
    backgroundColor: "#dfdfdf",
  },
  Addbtn: {
    float: "right",
  },
  TopBtn: {
    float: "left",
  },
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0px 16px",
    borderRight: "1px solid #e5e5e5",
  },
}))(TableCell);

const MaterialTable = (props) => {
  const { t } = useTranslation();

  const { shippingMaterialSelect = [], phases, redirectTo = '',allContainerList } = props;
 
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <TableHead
          sx={{
            height: "30%",
          }}
        >
          <TableRow
            sx={{
              borderRight: "1px solid #e5e5e5",
              backgroundColor: "#98AFC7",
              "& th": {
                color: "#FFFFFF",
              },
            }}
          >
            <StyledTableCell>
              {t("shippingrequest.material(name)")}{" "}
            </StyledTableCell>
            <StyledTableCell>
              {t("process_shipping_request.amount_and_unit")}
            </StyledTableCell>

            <StyledTableCell>
              {t("process_shipping_request.country_of_origin")}
            </StyledTableCell>
            <StyledTableCell>
              {t("process_shipping_request.batch_no")}
            </StyledTableCell>
            <StyledTableCell>
              {t("process_shipping_request.aggregation_state")}
            </StyledTableCell>
            <StyledTableCell>
              {t("shippingrequest.type_of_container")}
            </StyledTableCell>
            <StyledTableCell>
            {t("shippingrequest.quantityOfContainer(s)")}
            </StyledTableCell>
            <StyledTableCell>
            {t("shippingrequest.unitPerContainer")}
            </StyledTableCell>
            <StyledTableCell>
              {t("process_shipping_request.comment")}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shippingMaterialSelect.map((row) => (
            <StyledTableRow
              key={row.id}
              sx={{  
                borderRight: "1px solid #e5e5e5",
              }}
              className={redirectTo === "/process/shipping" && row.approved === 0 ? "solid-red-border" : null}
            >
              <StyledTableCell component="th" scope="row">
                {row.description}
              </StyledTableCell>
              <StyledTableCell>{row.amount}</StyledTableCell>

              <StyledTableCell>{row.name_english}</StyledTableCell>
              <StyledTableCell>{row.batch_number}</StyledTableCell>
              <StyledTableCell>
                {
                  phases.find(
                    (item) => item.phase_english === row.phase_english
                  )?.phase_english
                }
              </StyledTableCell>
              <StyledTableCell>
                {
                  allContainerList.find(
                    (item) => item.id === row?.container_type
                  )?.container_type
                }
              </StyledTableCell>
              <StyledTableCell>
                {row.quantity}
              </StyledTableCell>
              <StyledTableCell>
                {row.unit + " " + row.abbrevaton}
              </StyledTableCell>
              <StyledTableCell>{row.c_comment}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ShippingMaterailCard = (props) => {
  const { t } = useTranslation();

  const {
    shippingRequestDetails,
    materialShippingDetails,
    shippingActionType,
    shippingMaterialSelect,
    classes,
    phases,
    allContainerList
  } = props;

  const listOfMaterialsToBeApproved = shippingMaterialSelect.filter(item => item.approved === 0);
  // useEffect( () => {

  //     if(shippingRequestDetails.length > 0 ) {
  //         actions.getMaterialShippingDetails(shippingRequestDetails[0])
  //     }

  // }, [shippingRequestDetails.length > 0])

  // useEffect(() => {
  //     if(materialShippingDetails.length > 0) {
  //         const allMaterials = getAllMaterials(materialShippingDetails)
  //         actions.addShippingMaterialSelect(allMaterials)
  //     }
  // }, [materialShippingDetails.length > 0 ]);

  const status =
    shippingRequestDetails.length > 0 && shippingRequestDetails[0].status;

    const getMaterials = (material) => (
    <>
      <div>
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography className="heading_box">
                {" "}
                {t("shipping_log_fields.material")}{" "}
              </Typography>
            }
            className={classes.header}
          />
          {
            props.redirectTo === "/process/shipping" && listOfMaterialsToBeApproved && listOfMaterialsToBeApproved.length > 0 ?(
              <Card variant="outlined">
                <CardContent className="p-2 notice transparantbg">
                  <Alert severity="warning">
                      {t("material.material_not_approved")}
                  </Alert>
                </CardContent>
              </Card>
            ) : ("")
          }
          <CardContent>
            <MaterialTable shippingMaterialSelect={material} phases={phases} redirectTo={props.redirectTo} allContainerList={allContainerList} />
            <br />
          </CardContent>
        </Card>
      </div>
    </>
  );

  if (shippingActionType === INSPECT) {
    return getMaterials(materialShippingDetails);
  }

  if (
    shippingActionType === EDIT &&
    (status === STATUS.IN_PROGRESS)
  ) {
    return getMaterials(materialShippingDetails);
  }

  if (shippingActionType === SUBMIT && status === STATUS.INPUT) {
    return getMaterials(materialShippingDetails);
  }

  if (
    shippingActionType === HAND_BACK ||
    shippingActionType === CANCEL_REQUEST ||
    shippingActionType === CLOSE
  ) {
    return getMaterials(materialShippingDetails);
  }

  return (
    <>
      {shippingRequestDetails.length > 0 && shippingActionType !== EDIT ? (
        getMaterials([])
      ) : (
        <></>
      )}
    </>
  );
};

export default withStyles(styles)(ShippingMaterailCard);
