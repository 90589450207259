import React from "react";
import {REDIRECTION_URL} from '../constants';
import { useTranslation } from "react-i18next";
function NotAuthorized() {
    const { t } = useTranslation();
    // Contact Page 
    return (

        <>
            <div className="container-fluid ">
                <div className="row  col-12">
                    <div className="countrytime">
                        <div className="title_main "><b>{t("contactPage.title")}</b></div>
                        <div className="mt-3"> {t("contactPage.heading")}  <a href={REDIRECTION_URL.escParam} target="_new"> {t("contactPage.click")}</a></div>
                        <div className="mt-2"> {t("contactPage.mailto")} <a href="mailto:GlobalStudyMaterials@elancoah.com"> GlobalStudyMaterials@elancoah.com </a></div>
                        <div  className="mt-2">{t("contactPage.thankyou")}</div>
                    </div>
                </div>
            </div>

        </>

    )
}
export default NotAuthorized;