import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import shipping from "../../redux/shipping";
import AddDeputiesComponent from "./AddDeputiesComponent";

const {
  getDeputies,
  addShippingDeputies,
  clearShippingDeputies,
  addShippingDeputiesDetails,
} = shipping.actions;

const mapStateToProps = (state) => ({
  shippingDeputies: state[shipping.name].shippingDeputies,
  shippingDeputySelect: state[shipping.name].shippingDeputySelect,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDeputies,
      addShippingDeputies,
      clearShippingDeputies,
      addShippingDeputiesDetails,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddDeputiesComponent);
