import types from "./types";

const initialState = {
  tableRecipientOptions: {},
  tableApproveRecipientOptions:{},
  allRecipients: [],
  allTitle: [],
  loader: true,
  recipientSearchData: {
    id: "",
    address_id: "",
    title_id: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    valid: false,
    submitted: false,
    approved: false,
    created_by: "",
    startDate: "",
    endDate: "",
    searchKey: undefined,
    city: "",
    name_english: "",
  },
};
const recipientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_RECIPIENTS:
      return {
        ...state,
        allRecipients: action.payload,
      };
    case types.GET_ALL_TITLE:
      return {
        ...state,
        allTitle: action.payload,
      };
    case types.SET_RECIPIENT_SEARCH_DATA: {
      return {
        ...state,
        recipientSearchData: action.payload,
      };
    }
    case types.SET_RECIPIENT_TABLE_OPTIONS: {
      return {
        ...state,
        tableRecipientOptions: action.payload,
      };
    }
    case types.SET_RECIPIENT_APPROVE_TABLE_OPTIONS: {
      return {
        ...state,
        tableApproveRecipientOptions: action.payload,
      };
    }
    default:
      return state;
  }
};

export default recipientsReducer;
