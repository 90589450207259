import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "../common/CustomSelect";
import DropDownSearch from "../common/DropDownSearch";
import { Link } from "react-router-dom";
import FileManager from "../common/FileManager/FileManager";
import { STATUS, USERROLES } from "../../constants";
import AutocompleteText from "../common/AutocompleteText";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import HelpIcon from "@mui/icons-material/Help";
import { HelpDialog } from "../common/Confirmation";

const ShippingMaterialForm = (props) => {
  const { t } = useTranslation();

  const {
    shippingMaterials,
    countries,
    handleMaterialName,
    hnaldeChageAmountAndUnit,
    handleChageBatchNo,
    handleChangeAggregation,
    handleChangeCountryOrigin,
    handleChangeComment,
    materailName,
    amountAndUnit,
    batchNo,
    aggregation,
    countryOrigin,
    comment,
    phases,
    isHazardous,
    hazardousds,
    hnaldeChageHazardous,
    hnaldeChageHazardousds,
    transportClassification,
    hnaldeChageTransportClassification,
    documents,
    hnaldeChageDocuments,
    quantity,
    hnaldeChageQuantity,
    unit,
    hnaldeChageUnit,
    unitType,
    handleChangeUnitType,
    curency,
    handleCurency,
    manufactureCountry,
    hnaldeChageManufactureCountry,
    allUnits,
    allCurrency,
    htsCode,
    handleHtsCode,
    unNo,
    allContainer,
    containerType,
    handleChangeContainerType,
    setMaterialSpecificData,
    materialSpecificData,
    setPckGrp,
    packGroup,
    handleChangeBlindedMaterial,
    isBlindedMaterial,
    shippingRequestDetails
  } = props;
  const status = _.get(shippingRequestDetails, ["0", "status"], undefined);
  const [showCurency, setShowCurency] = useState(" ");
  useEffect(() => {
    if (curency) {
      const data = allCurrency.filter((item) => item.id === curency);
      if (data.length > 0) {
        setShowCurency(data[0].currency_code);
      }
    }
  }, [curency]);
  const userType = JSON.parse(localStorage.getItem("userType"));
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationBlindedMaterialOpen, setConfirmationBlindedMaterialOpen] = useState(false);
  const [confirmationOpenMaterial, setConfirmationOpenMaterial] =
    useState(false);
  const handleCancelConfirmationMaterial = () => {
    setConfirmationOpenMaterial(false);
  };

  const openHelpMaterial = () => {
    setConfirmationOpenMaterial(true);
  };

  const openBlindedMaterial = () => {
    setConfirmationBlindedMaterialOpen(true);
  };

  const handleCancelBlindedMaterial = () => {
    setConfirmationBlindedMaterialOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
  };

  const openHelp = () => {
    setConfirmationOpen(true);
  };


  return (
    <>
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {t("shippingrequest.material(name)")}{" "}
          <span style={{ color: "red" }}>*</span>
        </div>
        {userType.includes(USERROLES.PROCESSMANAGER) ||
          userType.includes(USERROLES.APPLICANT) ||
          userType.includes(USERROLES.ADMIN) ? (

          <DropDownSearch
            list={_(shippingMaterials)
              .map((item) => {
                item.description = item.description.trim();
                return item;
              })
              .filter(item=>{
                if(item.valid==1){
                 return true;
                }else{
                  return false;
                }
              })
              .uniqBy("description")
              .sortBy("description")
              .value()}
            label=" "
            handleChange={handleMaterialName}
            value={materailName?.description}
          />
        ) : (
          <TextField
            disabled={
              !(
                userType.includes(USERROLES.PROCESSMANAGER) ||
                userType.includes(USERROLES.APPLICANT) ||
                userType.includes(USERROLES.ADMIN)
              )
            }
            id="value-of-material"
            margin="normal"
            variant="outlined"
            size="small"
            fullWidth
            value={materailName.description}
          />
        )}
      </div>

      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3"> </div>
        <div>
          {/* real sub no.:<br/> */}
          {userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.APPLICANT) ||
            userType.includes(USERROLES.ADMIN) ? (
            <Link to="/new/material">
              {t("shipping_request.material_not_found_url")}
            </Link>
          ) : (
            <Link to="" style={{ pointerEvents: "none", color: "black" }}>
              {t("shipping_request.material_not_found_url")}
            </Link>
          )}
        </div>
      </div>

      <div
        className="col-lg-12 mt-2"
        style={{ display: "flex" }}
        data-name="hts_code_field"
      >
        <div className="col-lg-12 form-label" data-name="hts-fields" style={{ display: 'flex', pointerEvents: 'none' }}>
          <div>
            <br /><br />
            {t('materials.tariff_classification_no')}
          </div>
          <div style={{ margin: '4px' }}>
            <p>{t("materials.hs_germany")}</p>
            <TextField
              id="hts_code_germany"
              margin="normal"
              variant="outlined"
              size="small"
              disabled={
                !(
                  (userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.ADMIN))
                )
              }
              fullWidth
              value={materialSpecificData?.hts_code_germany}
              onChange={(e) => setMaterialSpecificData({
                ...materialSpecificData,
                'hts_code_germany': e.target.value
              })}
            />
          </div>

          <div style={{ margin: '4px' }}>
            <p>{t("materials.htsus")}</p>
            <TextField
              id="Hts"
              margin="normal"
              variant="outlined"
              size="small"
              disabled={
                !(
                  userType.includes(USERROLES.PROCESSMANAGER) ||
                  userType.includes(USERROLES.ADMIN)
                )
              }
              fullWidth
              value={htsCode}
              onChange={handleHtsCode}
            />
          </div>

          <div style={{ margin: '4px' }}>
            <p>{t("materials.hs_foreign")}</p>
            <TextField
              id="hts_code_china"
              margin="normal"
              variant="outlined"
              size="small"
              disabled={
                !(
                  userType.includes(USERROLES.PROCESSMANAGER) ||
                  userType.includes(USERROLES.ADMIN)
                )
              }
              fullWidth
              value={materialSpecificData?.hts_code_china}
              onChange={(e) => setMaterialSpecificData({
                ...materialSpecificData,
                'hts_code_china': e.target.value
              })}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {t("shipping_log_fields.is_hazardous")}
        </div>
        <div className="col-lg-3 pl-0">
          <Checkbox
            disabled={true}
            margin="normal"
            variant="outlined"
            size="small"
            checked={isHazardous}
            onClick={hnaldeChageHazardous}
          />
        </div>
        {isHazardous ? (
          <div className="col-lg-3 form-label">
            {t("materials.unNo")} : {unNo}
          </div>
        ) : (
          <></>
        )}

        <div className="col-lg-3"></div>
      </div>
      
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {t("shipping_log_fields.hazardous_sds")}
        </div>
        <div className="col-lg-3 pl-0">
          <Checkbox
            disabled={true}
            margin="normal"
            variant="outlined"
            size="small"
            checked={hazardousds}
            onClick={hnaldeChageHazardousds}
          />
        </div>
      </div>
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {t("material.Class_Sub")}{" "}
        </div>
        <TextField
          id="transport-classification"
          margin="normal"
          variant="outlined"
          size="small"
          disabled={true}
          fullWidth
          value={transportClassification}
          onChange={hnaldeChageTransportClassification}
        />
      </div>
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {t("material.pck_grp")}{" "}
        </div>
        <TextField
          id="transport-classification"
          margin="normal"
          variant="outlined"
          size="small"
          disabled={true}
          fullWidth
          value={packGroup}
          onChange={hnaldeChageTransportClassification}
        />
      </div>
     
      <div
        className="col-lg-12 mt-2"
        style={{ display: "flex" }}
        data-name="file_upload_field"
      >
        <div className="col-lg-3 form-label">
          {t("shipping_request.attchement_section_header")}{" "}
          <span style={{ color: "red" }}>*</span>{" "}
          <span title={t("shipping_request.helpTitle")}>
            <HelpIcon onClick={openHelp} />
          </span>
        </div>
        <FileManager
          tableName="Shipping_material"
          files={documents}
          onChange={hnaldeChageDocuments}
          multiple={true}
          readOnly={false}
          isDisabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
        />
      
        <div className="col-lg-4 form-label">{t("shipping_request.blinded_material")}
        <span>
            <HelpIcon onClick={openBlindedMaterial} />
            <HelpDialog
            title={t("shipping_request.helpTitle")}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: t("shipping_request.blinded_material_hover"),
                }}
              />
            }
            confirmationOpen={confirmationBlindedMaterialOpen}
            handleCancelConfirmation={handleCancelBlindedMaterial}
          />
          </span>
          
        <Checkbox
            disabled={(status && status !== STATUS.INPUT)}
            margin="normal"
            variant="outlined"
            size="small"
            checked={isBlindedMaterial}
            onClick={handleChangeBlindedMaterial}
          />
       </div>
      </div>
    
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-2" style={{ display: "flex" }} data-name="quantity_of_container">
            <div className="col-lg-3 form-label">
              {t("shippingrequest.quantityOfContainer(s)")}{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              disabled={
                !(
                  userType.includes(USERROLES.PROCESSMANAGER) ||
                  userType.includes(USERROLES.APPLICANT) ||
                  userType.includes(USERROLES.ADMIN)
                )
              }
              id="quantity"
              margin="normal"
              variant="outlined"
              type="number"
              size="small"
              fullWidth
              value={quantity}
              onChange={hnaldeChageQuantity}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
            <div className="col-lg-3 form-label">
              {t("shippingrequest.type_of_container")}
              <span style={{ color: "red" }}>*</span>
            </div>
            <Select
              isDisabled={
                !(
                  userType.includes(USERROLES.PROCESSMANAGER) ||
                  userType.includes(USERROLES.APPLICANT) ||
                  userType.includes(USERROLES.ADMIN)
                )
              }
              labelName=" "
              list={allContainer.filter((obj)=>obj.is_active===true)
                .sort((a, b) => {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();
                if (a === b) return 0;
                return a < b ? -1 : 1;
              })}
              value={containerType}
              onChange={handleChangeContainerType}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mt-3" style={{ display: "flex" }}>
            <div className="col-lg-3 form-label">
              {t("shippingrequest.unitPerContainer")}{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              disabled={
                !(
                  userType.includes(USERROLES.PROCESSMANAGER) ||
                  userType.includes(USERROLES.APPLICANT) ||
                  userType.includes(USERROLES.ADMIN)
                )
              }
              id="unit"
              margin="normal"
              variant="outlined"
              size="small"
              type="number"
              value={unit}
              onChange={hnaldeChageUnit}
            />
            <div className="col-lg-4">
              <Select
                isDisabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  )
                }
                labelName=" "
                list={allUnits.filter(item => item.isactive === true)}
                keyname={"abbrevaton"}
                value={unitType}
                onChange={handleChangeUnitType}
              />
            </div>
          </div>
        </div>
      </div>



      <div className="col-lg-12 mt-2" style={{ display: "flex" }} data-name="total_change_material">
        <div className="col-lg-3 form-label">
          {t("shippingrequest.total_value_of_material")}
          <span title={t("shipping_request.helpTitle")}>
            <HelpIcon onClick={openHelpMaterial} />
          </span>
          <HelpDialog
            title={t("shipping_request.helpTitle")}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: t("shipping_request.helpTextTotalValueOfMaterials"),
                }}
              />
            }
            confirmationOpen={confirmationOpenMaterial}
            handleCancelConfirmation={handleCancelConfirmationMaterial}
          />
        </div>
        <TextField
          id="amount-and-unit"
          margin="normal"
          variant="outlined"
          type="number"
          size="small"
          fullWidth
          disabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
          value={amountAndUnit}
          onChange={hnaldeChageAmountAndUnit}
        />
        <div className="col-lg-4">
          <Select
            isDisabled={
              !(
                userType.includes(USERROLES.PROCESSMANAGER) ||
                userType.includes(USERROLES.APPLICANT) ||
                userType.includes(USERROLES.ADMIN)
              )
            }
            labelName=" "
            list={allCurrency.filter(item => item.is_active === true)}
            keyname={"currency_code"}
            value={curency}
            onChange={handleCurency}
          />
        </div>
      </div>

      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {" "}
          {t("process_shipping_request.batch_no")}{" "}
          <span style={{ color: "red" }}>*</span>
        </div>
        <TextField
          disabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
          id="internal order id"
          margin="normal"
          variant="outlined"
          size="small"
          fullWidth
          value={batchNo}
          onChange={handleChageBatchNo}
        />
      </div>

      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {" "}
          {t("process_shipping_request.aggregation_state")}
          <span style={{ color: "red" }}>*</span>
        </div>
        <Select
          isDisabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
          labelName=" "
          list={phases
            .filter(item => item.valid === 1)
            .sort((a, b) => {
              a = a.name.toLowerCase();
              b = b.name.toLowerCase();
              if (a === b) return 0;
              return a < b ? -1 : 1;
            })
          }
          value={aggregation}
          onChange={handleChangeAggregation}
        />
      </div>

      <div className="col-lg-12 mt-2" style={{ display: "flex" }} >
        <div className="col-lg-3 form-label">
          {" "}
          {t("process_shipping_request.country_of_origin")}{" "}
          <span style={{ color: "red" }}>*</span>
          <Tooltip title={t("shipping_request.country_of_orgin_tooltip")}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Select
          isDisabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
          labelName=" "
          list={countries}
          value={countryOrigin}
          onChange={handleChangeCountryOrigin}
        />
      </div>
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label" style={{ display: "flex" }}>
          {t("process_shipping_request.country_of_manufacturer")}
          <span style={{ color: "red" }}>*</span>
          <Tooltip
            title={t("shipping_request.country_of_manufacturer_tooltip")}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
        <TextField
          disabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
          id="manufacture_country"
          margin="normal"
          variant="outlined"
          size="small"
          fullWidth
          value={manufactureCountry}
          onChange={hnaldeChageManufactureCountry}
        />
      </div>
      <div className="col-lg-12 mt-2" style={{ display: "flex" }}>
        <div className="col-lg-3 form-label">
          {" "}
          {t("shipping_request.Comments")}{" "}
        </div>
        <TextareaAutosize
          disabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            )
          }
          id="internal order id"
          aria-label="Comments"
          margin="normal"
          variant="outlined"
          size="small"
          minRows={3}
          style={{ width: "100%" }}
          value={comment}
          onChange={handleChangeComment}
        />
      </div>
      <br />
      <HelpDialog
        title={t("shipping_request.helpTitle")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                "shipping_material_validations.shipping_material_document_help"
              ),
            }}
          />
        }
        confirmationOpen={confirmationOpen}
        handleCancelConfirmation={handleCancelConfirmation}
      />
    </>
  );
};

export default ShippingMaterialForm;
