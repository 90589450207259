import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import RecipientsListingComponent from "../../approvals/Recipients/RecipientsListingComponent";
import RecipientsSerarch from "../../Admin/Recipient/RecipientsSearchComponent";
import { addressContainerStyles } from "../../approvals/Recipients/addressStyles";
import PageLayout from "../../common/PageLayout";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../adalConfig";
import MyApplicationRecipientSearch from "./MyApplicationRecipientSearch";
import { useLocation } from "react-router-dom";

const MyApplicationRecipientsComponent = (props) => {
  const { actions, allRecipients, countries } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [flag, setFlag] = useState(false);
  const location = useLocation();
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const classes = addressContainerStyles();
  useEffect(() => {
    actions.getCountries().then(() => {
      actions.getAllrecipients().then((ress) => {
        actions.getAllAddress().then((res) => {
          let data = [];
          for (let item of res.data) {
            data.push({
              id: item.id,
              name: `${item.entity},${item.department},${item.street},${item.city},${item.name_english}`,
            });
          }
          setAddressList(
            data.sort((a, b) => {
              a = a.name.toLowerCase();
              b = b.name.toLowerCase();
              if (a == b) return 0;
              return a < b ? -1 : 1;
            }),
          );
          setIsLoading(false);
        });
      });
    });

  }, []);
  useEffect(() => {
    if (location.pathname == "/approve/recipients") {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [location.pathname]);
  return (
    <>
      <PageLayout
        title={t("my_recipients.my_recipients")}
        searchComponent={
          <MyApplicationRecipientSearch
            onSearch={(data) => setSearchFilter(data)}
            addressList={addressList}
            searchKey={"myApplicationRecipients"}
            countries={countries}
          />
        }
        mainComponent={
          <RecipientsListingComponent
            listData={allRecipients.filter(
              (item) => item.created_by === userId,
            )}
            actions={actions}
            searchFilter={searchFilter}
            setIsLoading={setIsLoading}
            hideDeleteButton={false}
            hideCheckBox={true}
            hideCheckButton={true}
            myrecipient={flag}
          />
        }
        isLoading={isLoading}
      />
    </>
  );
};

export default MyApplicationRecipientsComponent;
