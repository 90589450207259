export const wordBreakDownSearch = (field)=>{
    return (options, { inputValue }) => {
        const searchBreakdown = inputValue.split(" ");
        const optionsNew = options.filter((option) => {
          for (const searchWord of searchBreakdown) {
            if (!option[field]?.toLowerCase()?.includes(searchWord?.toLowerCase())) {
              return false;
            }
          }
          return true;
        });
        return optionsNew;
      }
}