import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import address from "../../../redux/address";
import shipping from "../../../redux/shipping";
import CountryComponent from "./CountryComponent";
import CountryTwoWeeksComponent from "./CountryTwoWeeksComponent";
const {
  getAllAddress,
  saveAddress,
  getAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getAllCountry,
} = address.actions;
const {
  getCountries,
  getCities,
  getShippingsCountry,
  getShippingsTwoWeeks,
} = shipping.actions;

const mapStateToProps = (state) => ({
  allAddress: state[address.name].allAddress,
  isLoader: state[address.name].isLoader,
  countries: state[address.name].allCountry,
  countrie: state[shipping.name].countries,
  cities: state[shipping.name].cities,
  ShippingsCountry: state[shipping.name].ShippingsCountry,
  ShippingsTwoWeeks: state[shipping.name].ShippingsTwoWeeks,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllAddress,
      getAllCountry,
      getAddress,
      getCountries,
      getCities,
      saveAddress,
      editAddress,
      updateAddressAccess,
      updateAddressApproval,
      getAddressRecipient,
      getShippingsCountry,
      getShippingsTwoWeeks,
    },
    dispatch,
  ),
});

export default {
  CountryComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CountryComponent),
  CountryTwoWeeksComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CountryTwoWeeksComponent),
};
