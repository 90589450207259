import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import logistics from "../../../redux/logistics";
import LogisticsComponent from "./Logistics";

const {
    getLogisticsParterDictionaryData
    , saveLogisticsPartner
    , updateLogisticsPartner
} = logistics.actions;

const mapStateToProps = (state) => ({
    logisticsPartnerData: state[logistics.name].logisticsPartnerData
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getLogisticsParterDictionaryData
            , saveLogisticsPartner
            , updateLogisticsPartner
        }
        , dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsComponent);
