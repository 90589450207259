import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import shipping from '../../redux/shipping';
import DestinationAddressComponent from './DestinationAddressComponent'


const { 
    destinationAddrSelect,
    clearDestinationAddrSelect,
    getCountries,
    redirectAction,
} = shipping.actions

const mapStateToProps = (state) => ({
    destinationAddrList: state[shipping.name].destinationAddrList,
    countries: state[shipping.name].countries
})


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            destinationAddrSelect,
            clearDestinationAddrSelect,
            getCountries,
            redirectAction
        },
        dispatch
    )
})

export default connect(
mapStateToProps,
mapDispatchToProps
)(DestinationAddressComponent)