export default class UserUtil {
  constructor(user, extended) {
    this.user = user;
    this.extended = extended;
  }

  toString() {
    const userItems = ["title", "first_name", "last_name"];

    if (!this.user) {
      return "";
    }

    let userString = userItems
      .map((key) => this.user[key])
      .filter((item) => item)
      .join(" ");

    if (this.extended) {
      const contactString = ["email", "phone"]
        .map((key) => this.user[key])
        .filter((item) => item)
        .join(", ");
      if (contactString.trim()) {
        userString = `${userString.trim()}, ${contactString}`;
      }
    }

    return userString;
  }

  /**
   * Compare userUtil classes.
   *
   * @param {userUtil} user2
   */
  compare(user2) {
    return this.toString().localeCompare(user2.toString());
  }
}
