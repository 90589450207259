import i18n  from "i18next";
import { DateFormat } from "../common/utils/DateUtil";

const formateDateField = (data = []) => {
    data.forEach((element) => {
        if (element.created_on) {
            element.created_on = DateFormat(
                element.created_on
                , i18n.resolvedLanguage
            );
        }
        if (element.shipment_date_mon) {
            element.shipment_date_mon = DateFormat(
                element.shipment_date_mon
                , i18n.resolvedLanguage
            );
        }
        if (element.shipment_date_lev) {
            element.shipment_date_lev = DateFormat(
                element.shipment_date_lev
                , i18n.resolvedLanguage
            );
        }

        if (element.date_of_recipient) {
            element.date_of_recipient = DateFormat(
                element.date_of_recipient
                , i18n.resolvedLanguage
            );
        }

        if (element.updated_on) {
            element.updated_on = DateFormat(
                element.updated_on
                , i18n.resolvedLanguage
            );
        }
        if (element.close_date) {
            element.close_date = DateFormat (
                element.close_date
                , i18n.resolvedLanguage
            )
        }
    })

    return data;
}

export default {
    formateDateField
};