import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import axios from "../../api";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import shipping from "../../redux/shipping";
import * as config from "../../config";
import { authContext } from "../../adalConfig";
import TabMenu from "../common/TabMenu";
import { STATUS, TYPE_OF_REPORT_ATTACHMENTS } from "../../../src/constants";
import deleteIcon from "../../assests/images/deleteIcon.svg";

const Reports = (shippingDetails) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { instance } = useMsal();

  const userId = instance.getActiveAccount().name;

  const [selectedFiles, setSelectedFiles] = useState({});
  const [documents, setDocuments] = useState([]);
  const [uploadFlag, setuploadFlag] = useState("");

  const [existingdocuments, setExistingDocuments] = useState([]);

  useEffect(async () => {
    if (
      shippingDetails.shippingDetails.length !== 0 &&
      shippingDetails.shippingDetails[0].id
    ) {
      const reportType = TYPE_OF_REPORT_ATTACHMENTS.Reports;
      const documentInput = {
        id: shippingDetails.shippingDetails[0].id,
        reportType: reportType,
      }
      let data = await shippingDetails.actions.getReportsAttachments(
        documentInput
      );
      setExistingDocuments(data.data);
    }
    setIsLoading(false);
  }, []);

  const exportADSdata = async () => {
    setIsLoading(true);
    window.location.href =
      config.baseURL +
      `/api/exportADS/` +
      shippingDetails.shippingDetails[0].id +
      "/" +
      userId;
    setIsLoading(false);
  };

  const exportADS = () => {
    return (
      <span>
        {shippingDetails?.shippingDetails.length !== 0 ? (
          <a
            href="#"
            style={{
              color: "#007bff",
              paddingLeft: "10px",
              textDecoration: "none",
            }}
            id="openmodalForEdit"
            onClick={(e) => {
              exportADSdata();
            }}
          >
            {t("export.export") +
              " " +
              t("invoice.ADR_(road_transport_for_EU)")}
            <DownloadIcon />
          </a>
        ) : (
          <a
            href="#"
            style={{
              pointerEvents: "none",
              color: "black",
              paddingLeft: "10px",
              textDecoration: "none",
            }}
            id="openmodalForEdit"
          >
            {t("export.export") +
              " " +
              t("invoice.ADR_(road_transport_for_EU)")}
            <DownloadIcon />
          </a>
        )}
      </span>
    );
  };
  const exportIATAData = async () => {
    setIsLoading(true);
    axios
      .get(
        config.baseURL +
          `/api/exportIATA/` +
          shippingDetails.shippingDetails[0].id,
      )
      .then((response) => {
        downloadTxtFile(response);
      });
    setIsLoading(false);
  };
  const downloadTxtFile = (data) => {
    const linkSource = `data:application/pdf;base64,${data.data}`;
    const downloadLink = document.createElement("a");
    const fileName = "shippers-declaration-column-format-fillable.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportIATA = () => {
    return (
      <span>
        {shippingDetails?.shippingDetails.length !== 0 ? (
          <a
            href="#"
            style={{
              color: "#007bff",
              paddingLeft: "10px",
              textDecoration: "none",
            }}
            id="openmodalForEdit"
            onClick={(e) => {
              exportIATAData();
            }}
          >
            {"PDF " + t("invoice.IATA_(for_US_air_freight)")}
            <DownloadIcon />
          </a>
        ) : (
          <a
            href="#"
            style={{
              pointerEvents: "none",
              color: "black",
              paddingLeft: "10px",
              textDecoration: "none",
            }}
            id="openmodalForEdit"
          >
            {"PDF " + t("invoice.IATA_(for_US_air_freight)")}
            <DownloadIcon />
          </a>
        )}
      </span>
    );
  };

  const encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileSelect = async (e) => {
    setuploadFlag("upload");
    const newFiles = [];
    let fileData;

    if (e.target.files.length !== 0) {
      for (const file of e.target.files) {
        if (
          file.type === "application/pdf" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          fileData = {
            name: file.name,
            encodedData: await encodeFile(file),
            id: false,
            size: file.size,
          };
          newFiles.push(fileData);
        } else {
          setuploadFlag("");
          document.getElementById("fileUpload").value = null;
          toast.error(t("commonlabels.reports_error_msg"));
        }
      }
    } else {
      setuploadFlag("");
      document.getElementById("fileUpload").value = null;
    }

    setSelectedFiles(fileData);
  };
  const UploadAttachments = async () => {
    setuploadFlag("");
    try {
      const shippingidValue = shippingDetails.shippingDetails[0].id;
      const userName = userId;
      const reportType = TYPE_OF_REPORT_ATTACHMENTS.Reports;
      setDocuments([
        ...documents,
        { ...selectedFiles, shippingidValue, userName, reportType },
      ]);

      setSelectedFiles({});
      document.getElementById("fileUpload").value = null;
    } catch (e) {
      console.error(e);
      // toast.error("Something went wrong.");
    }
  };
  const removeFile = async (index) => {
    const filteredFiles = documents.filter((file, index2) => index2 !== index);
    setDocuments(filteredFiles);
  };

  const removeFileForExistingAttachments = async (index, id, reportType) => {
    setIsLoading(true);
    const documentInput = {
        id: id,
        reportType: reportType,
      }
    let data = await shippingDetails.actions.deleteReportsAttachments(documentInput);
    if (data.data.IsSuccess === true) {
      const filteredFiles = existingdocuments.filter(
        (file, index2) => index2 !== index,
      );
      setExistingDocuments(filteredFiles);
      setIsLoading(false);
      toast.success(t("commonlabels.deleteSuccessfullyMsg"));
    } else {
      setIsLoading(false);
      toast.error("Something went wrong.");
    }
  };
  const saveReports = async () => {
    setIsLoading(true);
    shippingDetails.actions.saveReportsAttachments(documents).then(() => {
      setIsLoading(false);
      setDocuments([]);
      setExistingDocuments([]);
      toast.success(t("commonlabels.savedSuccessfullyMsg"));
      history.push({
        pathname: "/process/shipping",
      });
    });
  };
  const cancel = () => {
    history.push({
      pathname: "/process/shipping",
    });
  };
  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <TabMenu />
        <div className="container-fluid">
          <div className="mr2"></div>
          <div>
            <div className="row ml15 mr15 mlr15 border">
              <div className="row col-12 pt10">
                {exportADS()}
                {exportIATA()}
              </div>
              {shippingDetails?.shippingDetails[0]?.status === STATUS.INBOX ? (
                <div className="row col-12 pt10">
                  <div className="col-12   heading_box">
                    {t("material.Add/View_Documents")}{" "}
                  </div>
                  <div className="row col-md-12 pl-0 pr-0">
                    <div className="col-md-3 pt10 ml">
                      <input
                        type="file"
                        id="fileUpload"
                        onChange={(e) => handleFileSelect(e)}
                        accept=".xlsx,.pdf"
                      />
                    </div>
                    <div className="col-md-3 pt10">
                      <button
                        className="btn btn-primary new_button"
                        onClick={UploadAttachments}
                        disabled={uploadFlag === ""}
                      >
                        {t("dashboard.FileUploadtitle")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-12 pt10">
                <div className="row pl-0">
                  <>
                    {documents.length === 0 &&
                    existingdocuments?.length === 0 ? (
                      <table id="" className="editmaterialtable">
                        {" "}
                        {shippingDetails?.shippingDetails[0]?.status ===
                        STATUS.INBOX ? (
                          <tr>
                            <th>{t("material.file_name")}</th>

                            <th>{t("shipping_request.delete")}</th>
                          </tr>
                        ) : (
                          <tr>
                            <th>{t("material.file_name")}</th>
                          </tr>
                        )}
                        <tbody>
                          <tr>{t("commonlabels.emptyDataSourceMessage")}</tr>
                        </tbody>
                      </table>
                    ) : (
                      <div className="col-md-12">
                        {/* {documents.length > 0 ? ( */}
                        <div className="row pl-0 mb10">
                          <table id="" className="editmaterialtable">
                            {shippingDetails?.shippingDetails[0]?.status ===
                            STATUS.INBOX ? (
                              <tr>
                                <th>{t("material.file_name")}</th>

                                <th>{t("shipping_request.delete")}</th>
                              </tr>
                            ) : (
                              <tr>
                                <th>{t("material.file_name")}</th>
                              </tr>
                            )}
                            {existingdocuments?.map((val, key) => {
                              return (
                                <>
                                  <tbody>
                                    <tr key={key}>
                                      <td>
                                        {
                                          <a
                                            href={
                                              config.baseURL +
                                              "/api/downloadFile/" +
                                              val.id +
                                              "/reports"
                                            }
                                          >
                                            {val.file_name}
                                          </a>
                                        }
                                      </td>
                                      {shippingDetails?.shippingDetails[0]
                                        ?.status === STATUS.INBOX ? (
                                        <td>
                                          <a
                                            id=""
                                            onClick={(e) => {
                                              removeFileForExistingAttachments(
                                                key,
                                                val.id,
                                                TYPE_OF_REPORT_ATTACHMENTS.Reports,
                                              );
                                            }}
                                          >
                                            <img
                                              style={{ cursor: "pointer" }}
                                              alt="t"
                                              className="dlt_icon"
                                              src={deleteIcon}
                                            />
                                          </a>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  </tbody>
                                </>
                              );
                            })}
                            {documents.map((val, key) => {
                              return (
                                <>
                                  <tbody>
                                    <tr key={key}>
                                      <td>{val.name}</td>

                                      <td>
                                        <a
                                          id=""
                                          onClick={(e) => {
                                            removeFile(key);
                                          }}
                                        >
                                          <img
                                            style={{ cursor: "pointer" }}
                                            alt="t"
                                            className="dlt_icon"
                                            src={deleteIcon}
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              );
                            })}
                          </table>
                        </div>

                        {/* ) : ("")} */}
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>

          <div className="row col-12 mb50 pull-right pt10 pl0">
            <div className="row col-12">
              {shippingDetails?.shippingDetails[0]?.status === STATUS.INBOX ? (
                <button
                  className="btn btn-primary new_button"
                  disabled={documents.length === 0}
                  onClick={saveReports}
                >
                  {t("materials.save")}
                </button>
              ) : (
                ""
              )}
              &nbsp;
              <button
                className="btn btn-secondary cancelbutton"
                onClick={cancel}
              >
                {t("materials.cancel")}
              </button>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state[shipping.name].redirectTo,
  shippingDetails: state[shipping.name].shippingRequestDetails,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
