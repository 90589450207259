import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { get } from "lodash";
import ReactLoading from "react-loading";
import moment from "moment";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ShippingData from "./ShippingData";
import AdminShippingData from "./AdminShippingData";
import ViewData from "./ViewData";
import { useLocation, useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import Model from "../shared/model";
import StatusFlow from "../shippingRequest/statusFlow";
import CustomizedSnackbar from "../common/CustomizedSnackbar";
import { INSPECT, SUBMIT, PROCESS_MANAGER, STATUS, CANCEL_REQUEST } from "../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import * as config from "../../config";
import { getAllMaterials } from "../shippingMaterials/common";
import { toast } from "react-toastify";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { DateFormat,DateWithEndTime,DateWithDefaultTime } from "../common/utils/DateUtil";
import excelExport from "../shared/excelExport.js"
import dateFormatter from "../shared/dateFormatter.js";

const ShippingRequests = (props) => {
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const [searchFilter, setSearchFilter] = useState([]);
  const [searchAdminFilter, setSearchAdminFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shippingSelectedRow, setShippingSelectedRow] = useState({
    tableData: { id: 0 },
  });
  const [firstRowData, setFirstRowData] = useState({});
  const [shippingDetail, setShippingDetail] = useState({});
  const [actionName, setActionName] = useState("");
  const [stackOpen, setStackOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState({});
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");

  const [filteredData, setfilteredData] = useState();

  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const {
    actions,
    shippingList,
    myShippings,
    deleteShipping,
    updateShipping,
    shippingRequestValid,
    allMaterials,
    shippingType,
    tableOptions,
    tableAdminShippingOptions,
    shippingSearchData
  } = props;

  useEffect(() => {
    actions.getShippingType();
    actions.clearshippingRequest();
    actions.setShippingSearchData(shippingSearchData)
    actions.setTableOptions(tableOptions)
    actions.setAdminShippingTableOptions(tableAdminShippingOptions)
  }, []);

  useEffect(() => {
    actions.redirectAction(location.pathname);
    if (location.pathname === "/myShippings") {
      actions.getMyShippings(instance.getActiveAccount().username).then(() => {
        setIsLoading(false);
      });
    } else {
      actions.getShippings().then(() => {
        setIsLoading(false);
      });
    }
  }, [deleteShipping, location.pathname, shippingSelectedRow, updateShipping]);

  const handleShippingEdit = (row) => {
    actions.setShippingSearchData(searchFilter);
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    actions.getShippingActionType("EDIT");
    actions.clearShippingMaterialSelectedValue();
    actions.addShippingMaterialSelect([]);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
    actions.getAllShippingData();
    actions.saveTableData(row);
    if (location.pathname === "/myShippings") {
      actions.redirectAction("/myShippings");
    } else {
      actions.redirectAction("/shippingRequests");
    }
    history.push("/shipping");
  };

  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
  };
  const exportDataToExcel = async () => {
    let excelDataProperty = [
      { "displayName": "Shipping Request#", "dbColumn": "internal_distribution_number", "width": 20 },
      { "displayName": "Status", "dbColumn": "status", "width": 20 },
      { "displayName": "Create User", "dbColumn": "created_by", "width": 50 },
      { "displayName": "Address", "dbColumn": "address", "width": 50 },
      { "displayName": "Recipient", "dbColumn": "final_receipt", "width": 20 },
      { "displayName": "Create Date", "dbColumn": "created_on", "width": 25 },
      { "displayName": "Dispatch Date - Elanco", "dbColumn": "shipment_date_mon", "width": 20 },
      { "displayName": "Dispatch Date - Logistics Partner", "dbColumn": "shipment_date_lev", "width": 30 },
      { "displayName": "Date of Receipt", "dbColumn": "close_date", "width": 20 },
      { "displayName": "Valid", "dbColumn": "valid", "width": 10 },
      { "displayName": "Under Shipping Review", "dbColumn": "review_by_shipping", "width": 50 },
    ];

    actions.exportExcel().then((response) => {
      let dataForExport = dateFormatter.formateDateField(response.data);
      dataForExport.forEach((item) => {
        if (/^[,]*$/.test(item.address.trim())) {
          item.address = "";
        }
        item.status = t("status." + item.status);
      });
      excelExport.exportAllData(excelDataProperty, dataForExport, "Shipping_Request_Data", requiredFileName);
    });
  };

  const handleCSVExport1 = async () => {
    let dataForExport = exportDataBuilder1();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "My_Shipping_Request_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  useEffect(() => {
    let validSearchKeys = Object.keys(searchAdminFilter)
      .filter((item) => item !== "searchKey")
      .filter((key) => !!searchAdminFilter[key]);

    let filteredData = myShippings.filter((item) => {
      for (let searchParam of validSearchKeys) {
        if (["submitted", "approved", "valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (searchParam === "startDate") {
            const createDate = moment(item.created_on);
            const startDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.created_on);
            const endDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateLevStart", "shipmentDateLevEnd"].includes(searchParam)
        ) {
          if (searchParam === "shipmentDateLevStart") {
            const createDate = moment(item.shipment_date_lev);
            const startDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.shipment_date_lev);
            const endDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
        ) {
          if (searchParam === "shipmentDateMomStart") {
            const createDate = moment(item.shipment_date_mom);
            const startDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.shipment_date_mom);
            const endDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["dateOfRecipientStart", "dateOfRecipientEnd"].includes(searchParam)
        ) {
          if (searchParam === "dateOfRecipientStart") {
            const createDate = moment(item.date_of_recipient);
            const startDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.date_of_recipient);
            const endDate = moment(searchAdminFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          [
            "id",
            "status",
            "entity",
            "created_by",
            "tracking_number",
            "street",
            "postcode",
            "city",
            "address",
            "final_receipt",
            "sap_delivery_number",
            "description",
            "name_english",
            "internal_distribution_number",
          ].includes(searchParam)
        ) {
          if (
            !item[searchParam] ||
            !(item[searchParam] + "")
              .toLowerCase()
              .includes(searchAdminFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
        }
      }
      return true;
    });

    filteredData = filteredData.reduce((a, val) => {
      if (!a.find((item) => item.id == val.id)) {
        a.push(val);
      }
      return a;
    }, []);

    setfilteredData(filteredData);

  }, [searchAdminFilter, myShippings]);
  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter)
      .filter((item) => item !== "searchKey")
      .filter((key) => !!searchFilter[key]);

    let filteredData = myShippings.filter((item) => {
      for (let searchParam of validSearchKeys) {
        if (["submitted", "approved", "valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (searchParam === "startDate") {
            const createDate = moment(item.created_on);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.created_on);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateLevStart", "shipmentDateLevEnd"].includes(searchParam)
        ) {
          if (searchParam === "shipmentDateLevStart") {
            const createDate = moment(item.shipment_date_lev);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.shipment_date_lev);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
        ) {
          if (searchParam === "shipmentDateMomStart") {
            const createDate = moment(item.shipment_date_mom);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.shipment_date_mom);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["dateOfRecipientStart", "dateOfRecipientEnd"].includes(searchParam)
        ) {
          if (searchParam === "dateOfRecipientStart") {
            const createDate = moment(item.date_of_recipient);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.date_of_recipient);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          [
            "id",
            "status",
            "entity",
            "created_by",
            "tracking_number",
            "street",
            "postcode",
            "city",
            "address",
            "final_receipt",
            "sap_delivery_number",
            "description",
            "name_english",
            "internal_distribution_number",
          ].includes(searchParam)
        ) {
          // if (
          //   !item[searchParam] ||
          //   !(item[searchParam] + "")
          //     .toLowerCase()
          //     .includes(searchFilter[searchParam].toLowerCase())
          // ) {
          //   return false;
          // }

          if (
            searchParam=="status" && searchFilter[searchParam].length>0 && (
              !searchFilter[searchParam].includes(item[searchParam].toLowerCase())
  
            )
          ) {
            return false;
          }
    if (
      searchParam=="name_english" && searchFilter[searchParam].length>0 && !(
       searchFilter[searchParam].includes(item[searchParam])
     )
   ) {
     return false;
   }
         if (
          searchParam=="final_receipt" && searchFilter[searchParam].length>0 && !(
           searchFilter[searchParam].includes(item[searchParam])
         )
       ) {
         return false;
       }
    
        }
      }
      return true;
    });

    filteredData = filteredData.reduce((a, val) => {
      if (!a.find((item) => item.id == val.id)) {
        a.push(val);
      }
      return a;
    }, []);

    setfilteredData(filteredData);
  }, [searchFilter, myShippings]);

  const exportDataBuilder1 = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      let count = 0;
      //changing column names
      copyOfFilteredData &&
        copyOfFilteredData.forEach((element) => {
          if (element.created_on) {
            element.created_on = DateFormat(
              element.created_on,
              i18n.resolvedLanguage,
            );
          }
          if (element.shipment_date_mon) {
            element.shipment_date_mon = DateFormat(
              element.shipment_date_mon,
              i18n.resolvedLanguage,
            );
          }
          if (element.shipment_date_lev) {
            element.shipment_date_lev = DateFormat(
              element.shipment_date_lev,
              i18n.resolvedLanguage,
            );
          }

          if (element.date_of_recipient) {
            element.date_of_recipient = DateFormat(
              element.date_of_recipient,
              i18n.resolvedLanguage,
            );
          }

          if (element.updated_on) {
            element.updated_on = DateFormat(
              element.updated_on,
              i18n.resolvedLanguage,
            );
          }
          if (element.first_name) {
            element.first_name = element.first_name + " " + element.last_name;
          }
          if (count === 0) {
            if (
              element.entity ||
              element.building ||
              element.street ||
              element.postcode ||
              element.city ||
              element.name_english
            ) {
              count = count + 1;
              element.entity =
                (element.entity === null ? "" : element.entity + ", ") +
                (element.building === null ? "" : element.building + ", ") +
                (element.street === null ? "" : element.street + ", ") +
                (element.postcode === null ? "" : element.postcode + ", ") +
                (element.city === null ? "" : element.city + ", ") +
                (element.name_english.trim() === "()"
                  ? ""
                  : element.name_english);
            }
          }
        });
      item["Create Date"] = item["created_on"];
      item["Shipping Request#"] = item["internal_distribution_number"];
      item["Recipient "] = item["final_receipt"];
      item["Address"] = item["entity"];

      item["Date Of Receipt "] = item["date_of_recipient"];
      item["Status"] = t("status." + item["status"]);
      item["Edit Date"] = item["updated_on"];
      item["Under Shipping Review"] = item["review_by_shipping"];

      delete item["handling_type"];
      delete item["is_shippment_hazardous"];
      delete item["email"];
      delete item["review_by_shipping"];
      delete item["country_id"];
      delete item["approved"];
      delete item["submitted"];
      delete item["updated_by"];
      delete item["is_active"];
      delete item["state"];
      delete item["name_english"];

      delete item["created_on"];
      delete item["internal_distribution_number"];
      delete item["final_receipt"];
      delete item["description"];
      delete item["address"];
      delete item["date_of_recipient"];
      delete item["status"];

      delete item["tableData"];
      delete item["entity"];
      delete item["department"];
      delete item["building"];
      delete item["street"];
      delete item["postcode"];
      delete item["city"];
      delete item["recipient_id"];
      delete item["sapnumber"];

      delete item["created_on"];
      delete item["created_by"];

      delete item["address_id"];
      delete item["close_date"];
      delete item["created_by"];
      delete item["entity"];
      delete item["id"];
      delete item["internal_distribution_number"];
      delete item["recipient_id"];
      delete item["sap_delivery_number"];
      delete item["shipment_date_lev"];
      delete item["shipment_date_mon"];
      delete item["tracking_number"];
      delete item["valid"];
      delete item["updated_on"];
      delete item["review_by"];
      delete item["address_recipient_locked_data"];
      delete item["handling_type"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };
  const ExportBtn = () => {
    return (
      <button
        onClick={(e) => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={shippingSelectedRow.tableData.id === 0}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        {location.pathname === "/myShippings" ? (
          <button
            type="button"
            id="Yes"
            disabled={requiredFileName !== "" ? false : true}
            className="btn btn-primary btn-inner-close"
            onClick={(e) => {
              handleCSVExport1();
              setIsExportModalVisible(false);
              setrequiredFileName("");
            }}
          >
            Export
          </button>
        ) : (
          <button
            type="button"
            id="Yes"
            disabled={requiredFileName !== "" ? false : true}
            className="btn btn-primary btn-inner-close"
            onClick={(e) => {
              exportDataToExcel();
              setIsExportModalVisible(false);
              setrequiredFileName("");
            }}
          >
            Export
          </button>
        )}
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const ExportSelectedBtn = async () => {
    if (location.pathname === "/myShippings") {
      window.location.href =
        config.baseURL +
        `/api/exportSelectedData/` +
        shippingSelectedRow.id +
        "/" +
        requiredFileName +
        "/My_Shipping_Request_Data";
    } else {
      window.location.href =
        config.baseURL +
        `/api/exportSelectedData/` +
        shippingSelectedRow.id +
        "/" +
        requiredFileName +
        "/My_Shipping_Request_Data";
    }
  };
  const handleYesConfirmation = () => {
    setIsLoading(true);
    setConfirmationOpen(false);
    //delete
    actions
      .deleteShipping(deleteRecord)
      .then((res) => {
        toast.success(t("commonlabels.deleteSuccessfullyMsg"));
        setStackOpen(true);
        setIsLoading(false);
      })
      .catch((error) => {
      });
  };
  const handleShippingDelete = (rowData) => {
    setConfirmationOpen(true);
    setDeleteRecord(rowData);
  };

  const handlerShippingInspect = () => {
    localStorage.setItem("shippingAdminSortAndPagination", JSON.stringify(tableAdminShippingOptions))

    let rowData = {};
    if (shippingSelectedRow.tableData.id === 0) {
      rowData = firstRowData;
    }
    if (shippingSelectedRow.tableData.id !== 0) {
      rowData = shippingSelectedRow;
    }
    setActionName(INSPECT);
    setShippingDetail(rowData);
    actions.saveTableData(rowData);
  };

  const handlerShippingSubmit = () => {
    let rowData = {};
    if (shippingSelectedRow.tableData.id === 0) {
      rowData = firstRowData;
    }
    if (shippingSelectedRow.tableData.id !== 0) {
      rowData = shippingSelectedRow;
    }
    setActionName(SUBMIT);
    setShippingDetail(rowData);
    actions.getDeputyShippingDetails(rowData);
    actions.getMaterialShippingDetails(rowData);
    actions.saveTableData(rowData);
  };

  const getShippingRequst = async (rowData) => {
    const shippingRes = await actions.getShippingRequestDetails(rowData);
    return shippingRes.data;
  };

  const getShippingDeputies = async (rowData) => {
    const shippingRes = await actions.getDeputyShippingDetails(rowData);
    return shippingRes.data;
  };

  const getShippingMaterials = async (rowData) => {
    const shippingRes = await actions.getMaterialShippingDetails(rowData);
    const materialShippingDetails = shippingRes.data;
    if (materialShippingDetails.length) {
      const allMaterials = getAllMaterials(materialShippingDetails);
      const shippingMaterials = await actions.addShippingMaterialSelect(
        allMaterials,
      );
      if (shippingMaterials.hasOwnProperty("payload")) {
        return shippingMaterials.payload;
      }
    } else {
      return [];
    }
  };

  //copy
  const handlerShippingCopy = async () => {
    setIsLoading(true);
    let rowData = {};
    if (shippingSelectedRow.tableData.id === 0) {
      rowData = firstRowData;
    }
    if (shippingSelectedRow.tableData.id !== 0) {
      rowData = shippingSelectedRow;
    }

    setShippingDetail(rowData);

    const shippingResponse = await getShippingRequst(rowData);
    const shippingDeputies = await getShippingDeputies(rowData);
    const shippingMaterials = await getShippingMaterials(rowData);
    const shipper = (await actions.getShipperDetails(rowData)).data;
    const newShipper = shipper.map(item => ({ ...item, id: null }));

    if (!shippingResponse.length) {
      setIsLoading(false);
      return true;
    }

    const params = {
      status: STATUS.INPUT,
      created_on: "",
      updated_on: "",
      shipper: newShipper,
      copy: true,
    };
    const createPayload = { ...shippingResponse[0], ...params };

    actions
      .createShipping(createPayload)
      .then((res) => {
        const payload = {
          shippingResponse: res.data,
          updated_by: instance.getActiveAccount().username,
          status: STATUS.INPUT,
          created_on: "",
          copy: true,
          shipping_deputies: [],
          shippingMaterials: shippingMaterials,
          shipper,
        };

        const newPayload = { ...res.data[0], ...payload };

        actions
          .updateShipping(newPayload)
          .then((res) => {
            toast.success(
              t("materials.copy_success") +
              "." +
              "\n" +
              t("materials.copy_success_no_attachments"),
            );
            setStackOpen(true);
            //setIsLoading(false)
            actions.clearshippingRequest();
            //  actions.setShippingSearchData(shippingSearchData);
            //actions.setTableOptions(tableOptions)
            //actions.setAdminShippingTableOptions(tableAdminShippingOptions)
          })
          .catch((error) => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handlerActiveAndDeActive = (row, value) => {
    const payload = {
      row,
      value,
    };
    setIsLoading(true);
    actions
      .updateShippingRequestValid(payload)
      .then((res) => {
        setAlertMessage("Shipping request valid has been updated");
        actions.clearshippingRequest();
        actions.setShippingSearchData(shippingSearchData);
        actions.setTableOptions(tableOptions);
        actions.setAdminShippingTableOptions(tableAdminShippingOptions);
        if (res.data && res.data.length) {
          let upDateRow = {
            ...row,
            valid: value,
          };
          setShippingSelectedRow(upDateRow);
          setIsLoading(false);
        }
        setStackOpen(true);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleHistoryLogs = () => {
    if (firstRowData && shippingSelectedRow.tableData.id === 0) {
      history.push(`/shippings/log/${firstRowData.id}`);
      actions.setAdminShippingTableOptions(tableAdminShippingOptions)
      actions.saveTableData(firstRowData);
    } else {
      history.push(`/shippings/log/${shippingSelectedRow.id}`);
      actions.setAdminShippingTableOptions(tableAdminShippingOptions)
      actions.saveTableData(shippingSelectedRow);
    }
  };

  const handlerShippingSelectedRow = (row) => {
    setShippingSelectedRow(row);
  };

  const getFirstRowData = (row) => {
    setFirstRowData(row);
  };

  const searchFilterData = (data) => {
    setSearchFilter(data);
  };

  const searchAdminFilterData = (data) => {
    setSearchAdminFilter(data);
  };

  const modalFooterEditMethod = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-inner-close"
          onClick={(e) => handleYesConfirmation(e)}
        >
          {t("commonlabels.yesButton")}
        </button>
        <button
          type="button"
          className="btn btn-black btn-inner-close"
          onClick={(e) => handleCancelConfirmation(e)}
        >
          {t("commonlabels.noButton")}
        </button>
      </>
    );
  };

  const InspectBtn = ({ btnStatus, handleClick = {} }) => {
    return (
      <button
        disabled={btnStatus}
        className="btn btn-primary btn-inner-close new_button mr-2"
        onClick={handleClick}
      >
        {t("shipping_request.inspect")}
      </button>
    );
  };

  const SubmitBtn = ({ btnStatus, handleClick = {} }) => {
    if (location.pathname === "/myShippings") {
      return (
        <button
          disabled={btnStatus}
          className="btn btn-primary btn-inner-close new_button mr-2"
          onClick={handleClick}
        >
          {t("shipping_request.submit")}
        </button>
      );
    }
    return <></>;
  };

  const ActiveBtn = ({ handleClick = {} }) => {
    if (location.pathname === "/shippingRequests") {
      return (
        <button
          className="btn btn-inner-approve  new_button mr-2"
          onClick={handleClick}
        >
          {t("shipping_request.activate")}
        </button>
      );
    }
    return <></>;
  };

  const DeActiveBtn = ({ handleClick = {} }) => {
    if (location.pathname === "/shippingRequests") {
      return (
        <button
          className="btn btn-inner-approve  new_button mr-2"
          onClick={handleClick}
        >
          {t("shipping_request.deActivate")}
        </button>
      );
    }
    return <></>;
  };

  const inspectHideAndShow = () => {
    return (
      <InspectBtn
        btnStatus={
          !shippingSelectedRow.status ||
          shippingSelectedRow.status === STATUS.INPUT
        }
        handleClick={handlerShippingInspect}
      />
    );
  };

  const submitHideAndShow = () => {
    // if (
    //   firstRowData.status === STATUS.INPUT &&
    //   shippingSelectedRow.tableData.id === 0
    // ) {
    //   return (
    //     <SubmitBtn btnStatus={false} handleClick={handlerShippingSubmit} />
    //   );
    // }

    if (shippingSelectedRow.status === STATUS.INPUT) {
      return (
        <SubmitBtn btnStatus={false} handleClick={handlerShippingSubmit} />
      );
    }

    return <SubmitBtn btnStatus={true} handleClick={handlerShippingSubmit} />;
  };

  const ativeAndDeactive = () => {
    if (firstRowData.valid === 1 && shippingSelectedRow.tableData.id === 0) {
      return (
        <DeActiveBtn
          handleClick={() => handlerActiveAndDeActive(firstRowData, 0)}
        />
      );
    }

    if (
      (firstRowData.valid === 0 || firstRowData.valid === null) &&
      shippingSelectedRow.tableData.id === 0
    ) {
      return (
        <ActiveBtn
          handleClick={() => handlerActiveAndDeActive(firstRowData, 1)}
        />
      );
    }

    if (shippingSelectedRow.valid === 1) {
      return (
        <DeActiveBtn
          handleClick={() => handlerActiveAndDeActive(shippingSelectedRow, 0)}
        />
      );
    }

    if (shippingSelectedRow.valid === 0 || shippingSelectedRow.valid === null) {
      return (
        <ActiveBtn
          handleClick={() => handlerActiveAndDeActive(shippingSelectedRow, 1)}
        />
      );
    }
  };

  const handleRedirectTo = () => {
    actions.redirectAction("/myShippings");
    history.push("/shipping");
  };

  const newShippingBtn = () => {
    if (location.pathname === "/myShippings") {
      return (
        <button
          className="btn btn-primary btn-inner-close new_button mr-2"
          onClick={handleRedirectTo}
        >
          {t("shipping_request.create_a_new_shipping_request")}
        </button>
      );
    }
  };

  const copyBtn = () => {
    if (location.pathname === "/myShippings") {
      return (
        <button
          className="btn btn-primary btn-inner-close new_button mr2"
          onClick={handlerShippingCopy}
          disabled={!shippingSelectedRow?.status}
        >
          {t("shipping_request.copy")}
        </button>
      );
    }
  };

  const logBtn = () => {
    if (location.pathname === "/shippingRequests") {
      return (
        <button
          className="btn btn-primary btn-inner-close new_button mr-2"
          onClick={handleHistoryLogs}
          disabled={!shippingSelectedRow?.status}
        >
          {t("shipping_request.log")}
        </button>
      );
    }
    return <></>;
  };

  const cancelRequest = () => {
    if (location.pathname === "/shippingRequests") {
      return (
        <button
          className="btn btn-primary btn-inner-close new_button mr-2"
          disabled={get(shippingSelectedRow, "id", 0) === 0 || [STATUS.INPUT, STATUS.CANCELLED,STATUS.IN_DELIVERY].includes(shippingSelectedRow?.status) ? true : false}
          onClick={() => handleCancelRequest(shippingSelectedRow)}
        >
          {t("approve_materials.cancelRequest")}
        </button>
      );
    }
    return <></>;
  };
  const handleCancelRequest = () => {
    let rowData = {};
    if (shippingSelectedRow.tableData.id === 0) {
      rowData = firstRowData;
    }
    if (shippingSelectedRow.tableData.id !== 0) {
      rowData = shippingSelectedRow;
    }
    setActionName(CANCEL_REQUEST);
    setShippingDetail(rowData);
    actions.saveTableData(rowData);
  }
  const validateRevokebutton = () => {
    return get(shippingSelectedRow, "id", 0) === 0 ||
      [STATUS.INPUT, STATUS.CANCELLED].includes(shippingSelectedRow?.status)
  }
  const revertApproal = () => {
    if (location.pathname === "/shippingRequests") {
      return (
        <button
          className="btn btn-primary btn-inner-close new_button mr-2"
          disabled={validateRevokebutton()
            ? true
            : false
          }
          onClick={async () => {
            try {
              setIsLoading(true);
              await actions.revertApproval(shippingSelectedRow?.id,shippingSelectedRow?.status,userId);
                actions.clearshippingRequest();
                actions.setShippingSearchData(shippingSearchData);
                actions.setTableOptions(tableOptions);
                actions.setAdminShippingTableOptions(tableAdminShippingOptions);
              await actions.getShippings().then((res) => {
                let upDateRow = res.data.find(x => x.id === shippingSelectedRow?.id);
                res.data.sort((obj1, obj2) => obj1.id - obj2.id).reverse();
                let upDateRowNew = {
                  ...shippingSelectedRow,
                  status: upDateRow.status,
                };
                setIsLoading(false);
                setShippingSelectedRow(upDateRowNew);
                toast.success(t("commonlabels.savedSuccessfullyMsg"));
                
              });
            } catch (e) {
              setIsLoading(false);
              toast.success("something went wrong");

            }
          }}
        >
          {t("regulatoryReview.revoke_approval")}
        </button>
      );
    }
  };
  const miniSpinner = () => {
    return (
        <div>
            <ReactLoading
                type="spinningBubbles"
                color="#0072ce"
                display="inline"
                width="34px"
                height="34px"
            />
        </div>
    );
}
  const getStatusFlow = () => {
    if (actionName === INSPECT) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={INSPECT}
          roleName={PROCESS_MANAGER}
          goBack={location.pathname}
        />
      );
    }

    if (actionName === SUBMIT) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={SUBMIT}
          roleName={PROCESS_MANAGER}
          goBack={location.pathname}
        />
      );
    }
    if (actionName === CANCEL_REQUEST) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={CANCEL_REQUEST}
          roleName={PROCESS_MANAGER}
          goBack={location.pathname}
        />
      );
    }
  };

  const handleStackClose = () => {
    setStackOpen(false);
  };

  const stackMessage = () => {
    if (stackOpen) {
      return (
        <CustomizedSnackbar
          stackOpen={stackOpen}
          handleStackClose={handleStackClose}
          setStackOpen={setStackOpen}
          messageType="success"
          message={alertMessage}
        />
      );
    }
  };

  const deleteShippingRow = () => {
    if (confirmationOpen) {
      return (
        <Model
          showheader={confirmationOpen}
          modalHeader={t("phase.modaltitle")}
          modalbody={
            "Do you really want to delete the selected shipping request?"
          }
          modalfooter={modalFooterEditMethod()}
        ></Model>
        // <Confirmation
        //   confirmationOpen={confirmationOpen}
        //   handleCancelConfirmation={handleCancelConfirmation}
        //   handleYesConfirmation={handleYesConfirmation}
        //   title=""
        //   message={
        //     "Do you really want to delete the selected shipping request?"
        //   }
        // />
      );
    }
  };

  const getMyShippingList = () => {
    return myShippings.sort((obj1, obj2) => obj1.id - obj2.id).reverse();
  };

  const getShippingList = () => {
    return shippingList.sort((obj1, obj2) => obj1.id - obj2.id).reverse();
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      {Object.keys(shippingDetail).length > 0 &&
        shippingDetail.id !== 0 &&
        getStatusFlow()}

      <Box sx={{ flexGrow: 1 }} m={1} pt={1} className="p-0 mb0">
        <Stack direction="row" spacing={1}>
          {location.pathname === "/myShippings" ? (
            <label className="title_main ml-3 ">
              {t("shipping_request.my_shipping_request")}
            </label>
          ) : (
            <label className="title_main ml-3">
              {t("shipping_request.shipping_request")}
            </label>
          )}
        </Stack>
      </Box>

      <Container maxWidth="xl">
        <Card variant="outlined">
          <CardHeader title={t("phase.SearchPlaceholder")} />
          <Divider />
          <CardContent>
            {location.pathname === "/myShippings" ?
              <ShippingData
                onSearch={searchFilterData}
                searchKey={"shippingRequest"}
                minimalSearch={window.location.pathname === "/myShippings"}
              /> :
              <AdminShippingData
                onSearch={searchAdminFilterData}
                searchKey={"adminShippingRequest"}
                minimalSearch={window.location.pathname === "/myShippings"}
              />
            }
          </CardContent>
        </Card>

        <div className="row m0 no_padding container-fluid mt-3 mb-1 ">
          <div className="no_padding col-12 mb-2 d-flex justify-content-end">
            <DropdownButton
              title={t("export.export")}
              className="mr-1 exportdropdown"
            >
              <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
              <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
            </DropdownButton>
            {/* {ExportBtn()} */}

            {inspectHideAndShow()}
            {newShippingBtn()}
            {submitHideAndShow()}
            {copyBtn()}
            {/* { deleteHideAndShow() } */}
            {logBtn()}
            {ativeAndDeactive()}
            {stackMessage()}
            {deleteShippingRow()}
            {revertApproal()}
            {cancelRequest()}
            {/* <TextField
              className="search_area"
              size="small"
              placeholder="Search"
            /> */}
          </div>
        </div>

        <Card variant="outlined">
          <Divider />
          <CardContent>
            {location.pathname === "/myShippings" ? !isLoading && (

              <ViewData
                list={getMyShippingList()}
                handleShippingEdit={handleShippingEdit}
                handleShippingDelete={handleShippingDelete}
                getFirstRowData={getFirstRowData}
                handlerShippingSelectedRow={handlerShippingSelectedRow}
                shippingSelectedRow={shippingSelectedRow}
                searchFilter={searchFilter}
                myList={true}
                allMaterials={allMaterials}
                myshipping={true}
                tableOptions={tableOptions}
                actions={actions}
              />

            ) : (
              !isLoading &&
              <ViewData
                list={getShippingList()}
                handleShippingEdit={handleShippingEdit}
                handleShippingDelete={handleShippingDelete}
                getFirstRowData={getFirstRowData}
                handlerShippingSelectedRow={handlerShippingSelectedRow}
                shippingSelectedRow={shippingSelectedRow}
                searchFilter={searchAdminFilter}
                allMaterials={allMaterials}
                dataSteward={true}
                tableAdminShippingOptions={tableAdminShippingOptions}
                actions={actions}
              />
            )}
          </CardContent>
        </Card>
        <br />
      </Container>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </LoadingOverlay>
  );
};

export default ShippingRequests;
