import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import shipping from "../../redux/shipping";
import ShippingRequestCompment from "./ShippingRequests";
import materials from "../../redux/materials";
import regulatory from "../../redux/regulatory";
import tableListing from "../../redux/tableListing";

const { getAllShippingData } = regulatory.actions;
const {
  getShippings,
  getMyShippings,
  getShippingRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  getShippingActionType,
  clearShippingMaterialSelectedValue,
  addShippingMaterialSelect,
  deleteShipping,
  createShipping,
  updateShipping,
  clearshippingRequest,
  updateShippingRequestValid,
  redirectAction,
  getShippingAttachment,
  getShipperDetails,
  exportExcel,
  exportSelectedExcel,
  getShippingType,
  setTableOptions,
  setAdminShippingTableOptions,
  setShippingSearchData,
  updateAssignedReviewer,
  revertApproval
} = shipping.actions;

const { saveTableData } = tableListing.actions;

const mapStateToProps = (state) => ({
  shippingList: state[shipping.name].shippingList,
  myShippings: state[shipping.name].myShippings,
  deleteShipping: state[shipping.name].deleteShipping,
  shippingRequestValid: state[shipping.name].shippingRequestValid,
  updateShipping: state[shipping.name].updateShipping,
  allMaterials: state[materials.name].allMaterials,
  shippingType: state[shipping.name].shippingType,
  tableOptions: state[shipping.name].tableOptions,
  tableAdminShippingOptions: state[shipping.name].tableAdminShippingOptions,
  shippingSearchData: state[shipping.name].shippingSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setShippingSearchData,
      setAdminShippingTableOptions,
      updateAssignedReviewer,
      getAllShippingData,
      getShippings,
      getMyShippings,
      getShippingRequestDetails,
      getDeputyShippingDetails,
      getMaterialShippingDetails,
      getShippingActionType,
      clearShippingMaterialSelectedValue,
      addShippingMaterialSelect,
      deleteShipping,
      createShipping,
      updateShipping,
      clearshippingRequest,
      updateShippingRequestValid,
      redirectAction,
      getShippingAttachment,
      getShipperDetails,
      exportExcel,
      exportSelectedExcel,
      saveTableData,
      getShippingType,
      setTableOptions,
      revertApproval,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingRequestCompment);
