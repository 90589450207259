import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ConfirmPickup from "./ConfirmPickup";
import shipping from "../../../redux/shipping";

const { getProcessShippmentList } = shipping.actions;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getProcessShippmentList,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  processShippmentList: state[shipping.name].processShippmentList,
});

export default {
  ConfirmPickup: connect(mapStateToProps, mapDispatchToProps)(ConfirmPickup),
};
