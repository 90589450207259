import React, { useMemo, useState } from "react";
import { Alert } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import { SHIPPER_TYPES, STATUS, USERROLES } from "../../constants";
import DropDownSearch from "../common/DropDownSearch";
import { Link } from "react-router-dom";
import AddressUtil from "../common/utils/AddressUtil";
import UserUtil from "../common/utils/UserUtil";
import { useEffect } from "react";
import { wordBreakDownSearch } from "../common/utils/CustomFilter";
import { stringSortFunction } from "../../Services/util";
import _ from "lodash";
import { useDispatch } from "react-redux";

const styles = {
  header: {
    width: "100%",
    backgroundColor: "#dfdfdf",
  },
  Addbtn: {
    float: "right",
  },
};

const ConsignorComponent = ({
  shipperData = [],
  setChange,
  IsPreview = false,
  allRecipients,
  shippingRequestDetails,
  actions,
  destinationAddrSelect
}) => {
  const {setShipperCountry} = actions
  const dispatch = useDispatch();
  const status = _.get(shippingRequestDetails, ["0", "status"], undefined);
  const [t] = useTranslation();
  const userType = JSON.parse(localStorage.getItem("userType"));
  const [cardclass,setcardclass] = useState("")

  useEffect(()=>{},[cardclass])
  useEffect(() => {
    if (shipperData.length === 0) {
      setChange([
        {
          consignor_type: SHIPPER_TYPES.shipper,
        },
      ]);
    }
  }, [shipperData]);

  const recipientsList = useMemo(() => {
    return allRecipients.map((item) => ({
      ...item,
      key: item.id,
      label: `${new UserUtil(item, true).toString()} ${new AddressUtil(
        item
      ).toString()}`
    })).sort(stringSortFunction('label'));
  }, [allRecipients]);

  const handleShipperSelection = (data, selectedIndex) => {
    setShipperCountry(data.name_english)
    let newData = shipperData.map((item, index) => {
      return index === selectedIndex
        ? { ...item, recipient_id: data?.id }
        : item;
    });

    setChange(newData);
  };

  return (
    <Card variant="outlined" className="pb-0" style={{border:cardclass}}> 
      <CardHeader
        title={
          <Typography className="heading_box">
            {t("consignor_Shipper.Consignor_and_Shipper_Information")}<span className="req">*</span>{" "}
          </Typography>
        }
        action={ status === STATUS.IN_DELIVERY ? <div  className="link_finalrecipct mt-3"> {t("shipping_request.consignor_link")}</div> :
          (userType.includes(USERROLES.PROCESSMANAGER) ||
          userType.includes(USERROLES.APPLICANT) ||
          userType.includes(USERROLES.ADMIN)) && !IsPreview ? (
            <Link
              to={{
                pathname: "/addRecipients",
                state: {
                  isFromConsignorCreate: true,
                },
              }}
              className="link_finalrecipct mt-3"
              title={t("shipping_request.consignor_link")}
            >
             {t("shipping_request.consignor_link")}
            </Link>
          ) : null
        }
      />
      <CardContent>
        {(status === undefined || status === STATUS.INPUT) &&
          <Card variant="outlined" className="alert-margin">
            <CardContent className="p-2 notice">
              <Alert severity="warning">
                {t("shipping_request.addess_warning")}
                <br></br>
              </Alert>
            </CardContent>
        </Card>}
        <div className="row col-md-12 pl-2 pr-0 shipperconsignerdropdown">
          <div className="row col-12">
            <div className="col-lg-2 col-sm-12 pr-0 mt-2 pl-4"></div>
          </div>
          <div className="col-lg-12 col-sm-12 pr-0 mt-2">
            {shipperData?.map((item, index) => {
              return (
                <ConsignorCard
                  shipper={item}
                  recipientsList={recipientsList}
                  handleChange={(data) => {
                    handleShipperSelection(data, index);
                  }}
                  IsPreview={IsPreview}
                  status={status}
                  setcardclass={setcardclass}
                  destinationAddrSelect={destinationAddrSelect}
                />
              );
            })}
          </div>
        </div>
        {/* </Then>
        </If> */}
        <br />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ConsignorComponent);

const ConsignorCard = ({
  shipper,
  recipientsList,
  handleChange,
  IsPreview,
  status,
  setcardclass,
  destinationAddrSelect
}) => {
  const [t] = useTranslation();
  const userType = JSON.parse(localStorage.getItem("userType"));

  let recipient = recipientsList.find(
    (recipient) => recipient.id == shipper.recipient_id
  );
  let cardclass = ""
  if(recipient != undefined && Object.keys(recipient).length > 0 && (recipient?.addressvalid != 1 || recipient?.countryvalid == false)){
    cardclass = "3px solid orange"
  }
  setcardclass(cardclass)
  if(shipper.shipper_locked_data){
    recipient = shipper.shipper_locked_data;
  }
  const isRecipValid = (obj) =>{
    if(typeof obj == 'undefined'){
      return true
    }
    if(obj == null){
      return true
    }
    if(Object.keys(obj).length == 0){
      return true
    }
    if(obj.receipvalid == 1){
      return true
    }
    return false
  }
 
  const iscountryvalid = (obj) => {
    if(obj?.countryvalid == null || Object.keys(obj).length == 0) return true
    if(obj.countryvalid == true) return true 
    return false
  }

  return (
    <div className="row col-md-12 pl-0 pr-0 shipperconsignerdropdown">
      <div className="col-lg-3 col-sm-12 pr-0 mt-2" style={{border:(isRecipValid(recipient))?"":"3px solid orange"}}>
        <label className="form-label">Name </label>
        <DropDownSearch
          list={recipientsList.filter((obj) => {return obj?.address_id != destinationAddrSelect?.id && obj.countryvalid == true && obj.addressvalid == 1 && obj.receipvalid == 1})}
          name="shipper_consignor"
          value={recipient|| ''}
          style={{ width: "100%" }}
          disabled={
            !(
              userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.APPLICANT) ||
              userType.includes(USERROLES.ADMIN)
            ) || ( IsPreview ||
              status === STATUS.IN_DELIVERY)
          }
          renderOption={(params, recipient) => {
            return (
              <li {...params} key={recipient.id}>
                {" "}
                {new UserUtil(recipient, true).toString()}
                <br />
                {new AddressUtil(recipient).toString()}
              </li>
            );
          }}
          getOptionLabel={(recipient) => {
            return new UserUtil(recipient, true).toString();
          }}
          handleChange={handleChange}
          filterOptions={wordBreakDownSearch("label")}
        />
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2" style={{color:(iscountryvalid(recipient))?"":"orange"}}>
        <label className="form-label">{t("address.Country")} </label>
        {recipient?.name_english}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.City")}</label>
        {recipient?.city}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.State")}</label>
        {recipient?.state}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.Company/University")}</label>
        {recipient?.entity}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.Department")}</label>
        {recipient?.department}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.building")}</label>
        {recipient?.building}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.Street")}</label>
        {recipient?.street}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("address.Postal Code")}</label>
        {recipient?.postcode}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("recipient.Phone")}</label>
        {recipient?.phone}
      </div>
      <div className="col-lg-2 col-sm-12 pr-0 mt-2">
        <label className="form-label">{t("recipient.Email")}</label>
        {recipient?.email}
      </div>
    </div>
  );
};
