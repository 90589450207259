import React from "react";
import { get } from "lodash";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";

const styles = {
  header: {
    width: "100%",
    backgroundColor: "#dfdfdf",
  },
  Addbtn: {
    float: "right",
  },
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#98AFC7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ApplicantComponent = ({ classes, shippingRequestDetails }) => {
  const { t } = useTranslation();
  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography className="heading_box">
            {" "}
            {t("shipping_request.applicant")}{" "}
          </Typography>
        }
        className={classes.header}
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> {t("recipient.Email")}</StyledTableCell>
                <StyledTableCell>{t("recipient.name")}</StyledTableCell>
                <StyledTableCell>{t("recipient.Phone")}</StyledTableCell>
                <StyledTableCell>{t("RegisteredUser.mobile")}</StyledTableCell>
                <StyledTableCell>
                  {t("RegisteredUser.department")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                key={get(shippingRequestDetails[0], "requster_email", " ")}
              >
                <StyledTableCell component="th" scope="row">
                  {get(shippingRequestDetails[0], "requster_email", " ")}
                </StyledTableCell>
                <StyledTableCell>
                  {get(shippingRequestDetails[0], "requster_name", " ")}
                </StyledTableCell>
                <StyledTableCell>
                  {get(shippingRequestDetails[0], "requster_ph_no", " ")}
                </StyledTableCell>
                <StyledTableCell>
                  {get(shippingRequestDetails[0], "requster_mob", " ")}
                </StyledTableCell>
                <StyledTableCell>
                  {get(shippingRequestDetails[0], "requster_department", " ")}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ApplicantComponent);
