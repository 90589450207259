import React from "react";
import { useState,useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import { useStyles } from "../common/ThemeStye";
import Select from "../common/CustomSelect";
import { IMPORTER_OF_RECORDS, STATUS, USERROLES } from "../../constants";
import _ from "lodash";
import { ifRequestCompletedApprovals } from "../../Services/util";

const ImporterOfRecord = (props) => {
  const { actions, extra, readOnly, impoter, shippingRequestDetails } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const userType = JSON.parse(localStorage.getItem("userType"));

  const inprogressstatus = [
    null,
    undefined,
    STATUS.INPUT,
    STATUS.AWAITING_FOR_APPROVAL,
  ]

  const impot=impoter.find((obj)=>obj.id === extra.importer_of_records)
  const isimpvalid = (obj) =>{
    if(obj?.isactive == null || Object.keys(obj).length == 0) return true
      if(obj?.isactive == true){
        return true
      }
      else{
        return false;
      }
  }
  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t("shipping_request.importer_of_record")}{" "}
            </Typography>
          }
          className={classes.header}
        />
        <CardContent>
          <div className="row  mt-2 mr-0 no-marrgin">
            <div className="col-lg-2  col-12 form-outline form-margin" style={{border:(isimpvalid(impot))? "":"3px solid orange"}}>
              <label className="form-label">
                {t("shipping_request.importer_of_record")}:
              </label>
              <Select
                className="form-control form-textbox"
                name={"importer_of_records"}
                list={
                  inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
                    ? impoter.filter(item => item.isactive === true)
                    : impoter
                }
                value={extra?.importer_of_records || ""}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
                isDisabled={
                  readOnly ||
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  ) ||
                  shippingRequestDetails[0]?.status === STATUS.IN_DELIVERY
                }
              />
            </div>

            <div className="col-lg-2  col-12 form-outline form-margin">
              <label className="form-label">
                {t("shipping_request.entity_name")}:
              </label>
              <input
                disabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  ) 
                }
                type="text"
                className="form-control form-textbox"
                name={"entity_name"}
                value={extra?.entity_name || ""}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
                readOnly={readOnly ||
                  shippingRequestDetails[0]?.status === STATUS.IN_DELIVERY}
              />
            </div>

            <div className="col-lg-6  col-12 form-outline form-margin">
              <label className="form-label">{t("recipient.Address")}:</label>
              <input
                disabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  )
                }
                type="text"
                className="form-control form-textbox"
                name={"importer_records_address"}
                value={extra?.importer_records_address || ""}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
                readOnly={readOnly||
                  shippingRequestDetails[0]?.status === STATUS.IN_DELIVERY}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ImporterOfRecord;
