import React from "react";
import { useState,useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import StudyNumberComponent from "../StudyNumberComponent";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import TextField from "@material-ui/core/TextField";
import { STATUS, TYPE_OF_SHIPMENT, USERROLES } from "../../../constants";
import CustomDate from "../../common/CustomDate";
import {
  CANCEL_REQUEST,
  CLOSE,
  HAND_BACK,
  INCOTERMS,
} from "../../../constants";
import { DateFormat } from "../../common/utils/DateUtil";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ifRequestCompletedApprovals } from "../../../Services/util";
import Select from "../../common/CustomSelect"
const styles = {
  header: {
    width: "100%",
    height: 30,
    backgroundColor: "#ECECEC",
  },
  Addbtn: {
    float: "right",
  },
};
const userType = JSON.parse(localStorage.getItem("userType"));
const GeneralInformationCard = (props) => {
  const { t, i18n } = useTranslation();

  const {
    classes,
    value,
    internalOrderIdValue,
    shippingActionType,
    shippingRequestDetails,
    sapDeliveryNumValue,
    handleSapDeliveryNum,
    handleDispatchMON,
    dispatchMONValue,
    handleDispatchLEV,
    dispatchLEVValue,
    handleDateOfReceipt,
    dateOfReceiptValue,
    distributionTemperature,
    extra,
    actions,
    shippingType,
    incoterms,
    handleEntry,
    status
  } = props;

  const isdistributiontempvalid = (obj) =>{
    if(obj?.tempvalid == null || Object.keys(obj).length == 0) return true
      if(obj?.tempvalid == 1){
        return true
      }
      else{
        return false;
      }
  }

  const isreviewervalid = (obj) =>{
    if(obj?.revieweractive == null || Object.keys(obj).length == 0) return true
      if(obj?.revieweractive == true){
        return true
      }
      else{
        return false;
      }
  }


const [incotermClass,setIncotermClass] = useState("")
useEffect(()=>{
  let Incoclass = ""
  if(extra.inco_term != ""){
    for(let logpat of incoterms){
      if(logpat.id === extra.inco_term){
          if(!logpat.isactive){
            Incoclass = "3px solid orange"
          }
      }
    }
  }
  setIncotermClass(Incoclass)
},[])

  const inprogressstatus = [
    null,
    undefined,
    STATUS.INPUT,
    STATUS.AWAITING_FOR_APPROVAL,
  ]

  if (
    shippingActionType === HAND_BACK ||
    shippingActionType === CANCEL_REQUEST
  ) {
    return (
      <>
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography className="heading_box">
                {" "}
                {t("shipping_request.general_information")}{" "}
              </Typography>
            }
            className={classes.header}
          />
          <CardContent>
            <div className="row col-12">
              <div className="col-lg-6  col-12 form-outline form-margin">
                <label className="form-label">
                  {t("shippingrequest.Shipment_Request")} #:
                </label>
                {internalOrderIdValue}
              </div>

              <div
                className="col-lg-6  col-12 form-outline form-margin"
                style={{ display: "none" }}
              >
                <label className="form-label">
                  {t("shippingrequest.sapnumber")}:
                </label>{" "}
                {shippingRequestDetails.length &&
                  shippingRequestDetails[0].sapnumber}
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }

  if (shippingActionType === CLOSE) {
    return (
      <>
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography className="heading_box">
                {" "}
                {t("shipping_request.general_information")}{" "}
              </Typography>
            }
            className={classes.header}
          />
          <CardContent>
            <div className="row col-12">
              <div className="col-lg-4 col-12 form-outline form-margin">
                <label className="form-label">
                  {t("shippingrequest.Shipment_Request")}#:
                </label>
                {internalOrderIdValue}
              </div>

              <div
                className="col-lg-4  col-12 form-outline form-margin"
                style={{ display: "none" }}
              >
                <label className="form-label">
                  {" "}
                  {t("shippingrequest.sapnumber")}:{" "}
                </label>
                <TextField
                  id="internal order id"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={sapDeliveryNumValue}
                  onChange={handleSapDeliveryNum}
                />
              </div>

              <div className="col-lg-4  col-12 form-outline form-margin">
                <label className="form-label">
                  {t("shippingrequest.dispatchdate(mon)")}:{" "}
                </label>
                <CustomDate
                  handleDateChange={handleDispatchMON}
                  dateValue={dispatchMONValue}
                  classes={classes}
                  disabled={true}
                />
              </div>

              <div className="col-lg-4  col-12 form-outline form-margin">
                <label className="form-label">
                  {" "}
                  {t("shippingrequest.dispatchdate(lev)")}:
                </label>
                <CustomDate
                  handleDateChange={handleDispatchLEV}
                  dateValue={dispatchLEVValue}
                  classes={classes}
                  disabled={true}
                />
              </div>

              <div className="col-lg-4  col-12 form-outline form-margin">
                <label className="form-label">
                  {" "}
                  {t("shippingrequest.dateofrecipient")}:
                </label>
                <CustomDate
                  handleDateChange={handleDateOfReceipt}
                  dateValue={dateOfReceiptValue}
                  classes={classes}
                  disabled={true}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t("shipping_request.general_information")}{" "}
            </Typography>
          }
          className={classes.header}
        />
        <CardContent>
          <div className="row col-12 ">
            <div className="col-lg-3  col-12 mb10">
              <label className="form-label">
                {" "}
                {t("shippingrequest.Shipment_Request")}#:
              </label>
              {internalOrderIdValue}
            </div>

            <div className="col-lg-3  col-12">
              <FormControl>
                <FormLabel id="specification" className="form-label">
                  {t("shipping_request.date_material_available_to_ship")}:
                </FormLabel>
                {DateFormat(
                  extra?.date_material_available_to_ship,
                  i18n.resolvedLanguage
                )}
              </FormControl>
            </div>

            <div className="col-lg-3  col-12">
              <FormControl>
                <FormLabel id="specification" className="form-label">
                  {t("shipping_request.date_material_needed_on_site")}:
                </FormLabel>
                {DateFormat(
                  extra?.date_material_needed_on_site,
                  i18n.resolvedLanguage
                )}
              </FormControl>
            </div>

            <div className="col-lg-3  col-12">
              <div className="form-label">
                {t("shipping_request.study_number")}:
              </div>
              <StudyNumberComponent
                actions={actions}
                extra={extra}
                status={status}
                shippingActionType={shippingActionType}
              />
            </div>

            <div className="col-lg-3  col-12">
              <div className="form-label">
              {t("shipping_request.cost_center_WBS_for_shipments")}:
              </div>
              {extra?.cost_center_shipment}
            </div>

            <div className="col-lg-3  col-12" style={{border:incotermClass}}>
              <div className="form-label">
                {t("shipping_request.incoterms")}:
              </div>
              {inprogressstatus.includes(
                _.get(shippingRequestDetails, ["0", "status"], undefined)
              )  ? incoterms
              .filter((item) => item.isactive === true)
              .find((item) => item.id === extra?.inco_term)?.name
                : incoterms.find((item) => item.id === extra?.inco_term)?.name}
               
            </div>

            <div className="col-lg-3  col-12"  style={{border:(isdistributiontempvalid(shippingRequestDetails[0]))? "":"3px solid orange"}}>
              <div className="form-label">
                {t("shipping_request.distribution_temperature")}
              </div>
                 {inprogressstatus.includes(
                _.get(shippingRequestDetails, ["0", "status"], undefined)
              )
                ? 
                  distributionTemperature
                    .filter((x) => x.valid === 1)
                    .find((item) => item.id == value)?.temperature_description_english
                :distributionTemperature?.find((item) => item.id == value)
                    ?.temperature_description_english }
            </div>
 
            <div className="col-lg-3  col-12">
              <div className="form-label">
                {t("shipping_request.Monitor_Temperature")}
              </div>
              {extra?.handling_type == 1 ?  "Yes" : extra?.handling_type == 2 ? "No" :""}
            </div>

            <div className="col-lg-3  col-12" style={{border:(isreviewervalid(shippingRequestDetails[0]))? "":"3px solid orange"}}>
              <div className="form-label">
                {t("shipping_request.prefered_reviewer")}
              </div>
              {extra?.prefered_reviewer}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default withStyles(styles)(GeneralInformationCard);
