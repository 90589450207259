import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import RecipientsListingComponent from "./RecipientsListingComponent";
import RecipientsSerarch from "./RecipientsSearchComponent";
import { useTranslation } from "react-i18next";
import { addressContainerStyles } from "./addressStyles";
import PageLayout from "../../common/PageLayout";
import AddressUtil from "../../common/utils/AddressUtil";
import { stringSortFunction } from "../../../Services/util";

const Recipients = (props) => {
  const { actions, allRecipients, countries,tableRecipientOptions  } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = addressContainerStyles();
  const [t, i8ln] = useTranslation();
  useEffect(() => {
    actions.getAllrecipients().then((ress) => {
      const recipients = ress.data;
      const existingAddressIds = recipients.map(
        (recipient) => recipient.address_id
      );
     
      actions.getAllAddress().then((res) => {
        const data = res.data
          .filter((item) => existingAddressIds.includes(item.id))
          .map((item) => ({
            id: item.id,
            name: new AddressUtil(item).toString(),
          }))
          .sort(stringSortFunction("name"));
        const key = 'name';

        const arrayUniqueByKey = [...new Map(data.map(item =>
          [item[key], item])).values()];
        setAddressList(arrayUniqueByKey);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <>
      <PageLayout
        title={t("recipient.recipient")}
        isLoading={isLoading}
        searchComponent={ <RecipientsSerarch
          onSearch={(data) => setSearchFilter(data)}
          addressList={addressList}
          searchKey={"AdminList"}
        />}

        mainComponent={!isLoading && <RecipientsListingComponent
          listData={allRecipients}
          actions={actions}
          searchFilter={searchFilter}
          setIsLoading={setIsLoading}
          tableRecipientOptions={tableRecipientOptions}
        />}
        />
    </>
  );
};

export default Recipients;
