import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";

const ShippingMaterialMoareDetails = (props) => {
  const { t } = useTranslation();

  const { shippingMaterialSelectedValue } = props;

  const getMaterialNameifBlank = () => {
    if (
      Object.keys(shippingMaterialSelectedValue).length !== 0 &&
      Object.getPrototypeOf(shippingMaterialSelectedValue.materailName) ===
        Object.prototype
    ) {
      return shippingMaterialSelectedValue.materailName.description;
    } else {
      return shippingMaterialSelectedValue.materailName;
    }
  };

  return (
    <>
      <div className="row col-12 ">
        <div className="col-4">
          <Card variant="outlined">
            <CardContent>
              <label className="col-form-label">
                {t("material.materialnumber")}:
              </label>{" "}
              <label>{shippingMaterialSelectedValue.material_number} </label>
              <label className="col-form-label">
                {t("material.realsubno")}:{" "}
              </label>{" "}
              <label>
                {" "}
                shippingMaterialSelectedValue.real_material_number{" "}
              </label>
              <label className="col-form-label">
                {t("material.hazardclass")}:{" "}
              </label>{" "}
              <label> {shippingMaterialSelectedValue.hazard_clas}</label>
              <label className="col-form-label"></label>{" "}
              <label>
                {" "}
                {t("material.hazardinducers")}:{" "}
                {shippingMaterialSelectedValue.hazard_phrase}{" "}
              </label>
              <label className="col-form-label"></label>{" "}
              <label>
                {" "}
                {t("material.tariffclassificationno")}:{" "}
                {shippingMaterialSelectedValue.statistical_material_number}{" "}
              </label>
              <label className="col-form-label"></label>{" "}
              <label>
                {" "}
                {t("material.state_mate_number") + t("material.country")}:{" "}
                {shippingMaterialSelectedValue.statistical_material_number}{" "}
              </label>
            </CardContent>
          </Card>
        </div>

        <div className="col-4">
          <Card variant="outlined">
            <CardContent>
              <label className="col-form-label">
                {" "}
                {t("shippingrequest.material(name)")}:
              </label>{" "}
              <label> {getMaterialNameifBlank()}</label>
              <label className="col-form-label">
                {" "}
                {t("shipping_request.material_not_found_url")}{" "}
              </label>
              <label className="col-form-label">
                {" "}
                {t("process_shipping_request.amount_and_unit")}:
              </label>{" "}
              <label> {shippingMaterialSelectedValue.amountAndUnit} </label>
              <label className="col-form-label">
                {" "}
                {t("process_shipping_request.batch_no")}:{" "}
                {shippingMaterialSelectedValue.batchNo}{" "}
              </label>
              <label className="col-form-label">
                {" "}
                {t("process_shipping_request.aggregation_state")}:
              </label>{" "}
              <label> {shippingMaterialSelectedValue.aggregation} </label>
              <label className="col-form-label">
                {" "}
                {t("process_shipping_request.country_of_origin")}:
              </label>{" "}
              <label> {shippingMaterialSelectedValue.countryOrigin}</label>
            </CardContent>
          </Card>
        </div>

        <div className="col-4">
          <Card variant="outlined">
            <CardContent>
              <p>
                {" "}
                {t("process_shipping_request.comment")}:{" "}
                {shippingMaterialSelectedValue.comment}{" "}
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
      <br />
    </>
  );
};

export default ShippingMaterialMoareDetails;
