import axios from '../api.js';
import * as config from '../config';

export default {
    getMaterialDetails: async () => {
        const response = await axios.get(config.baseURL + '/api/material/list');
        return response.data;
    },
    saveMaterial: async(data)=>{
        const response = await axios.post(config.baseURL + '/api/material/create', data)
        return response.data;
    },
    getMaterialAttachments:async(id)=>{
        const response = await axios.get(config.baseURL + `/api/getMaterialAttachments/${id}`);
      
        return response.data;
    },
    deleteMaterialAttachments:async(id)=>{
        const response = await axios.get(config.baseURL + `/api/deleteMaterialAttachments/${id}`);
      
        return response.data;
    },
    updateMaterial: async(id, data)=>{
        const response = await axios.post(config.baseURL + `/api/material/${id}`, data)
        return response.data;
    },
    copyMaterial: async(id, data)=>{
        const response = await axios.post(config.baseURL + `/api/material/copy/${id}`, data)
        return response.data;
    },
    materialApprovalAndValidUpdate: async(id, data)=>{
        const response = await axios.post(config.baseURL + `/api/material/status/${id}`, data)
        return response.data;
    },
    materialApprovalUpdate: async(id, data)=>{
        const response = await axios.post(config.baseURL + `/api/material/approve/${id}`, data)
        return response.data;
    },
    deleteMaterial: async(id)=>{
        const response = await axios.get(config.baseURL + `/delete/material/${id}`)
        return response.data;
    },
    updateApproveMaterialReviewStatus : async (payload) => {
        const response = await axios.post(config.baseURL + `/api/updateMaterialReviewStatus`, payload);
        return response.data;
    },
    getMaterialSelectedData: async (id) => {
        const response = await axios.get(config.baseURL + `/api/getMaterialSelectedData/${id}`);
        return response.data;
    }
}
