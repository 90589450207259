import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InvoiceComponent from "./InvoiceComponent";
import shipping from "../../redux/shipping";
import address from "../../redux/address";
import invoice from "../../redux/invoice";
import recipients from "../../redux/recipients";
import materials from "../../redux/materials";

const mapStateToProps = (state) => ({
  shippingRequestDetails: state[shipping.name].shippingRequestDetails,
  materialShippingDetails: state[shipping.name].materialShippingDetails,
  shipper: state[shipping.name].shipper,
  extra: state[shipping.name].extra,
  allCountry: state[address.name].allCountry,
  invoiceData: state[invoice.name].invoiceData,
  allRecipients: state[recipients.name].allRecipients,
  allUnits: state[shipping.name].allUnits,
  brokers: state[shipping.name].brokers,
  allMaterials: state[materials.name].allMaterials,
  allContainer: state[materials.name].allContainerList,
  incoterms: state[shipping.name].incoterms,
  impoter: state[shipping.name].impoter,
});

const { getAllCountry } = address.actions;
const { saveInvoiceData, getInvoiceData } = invoice.actions;
const {
  getShippingRequestDetails,
  getMaterialShippingDetails,
  getIncoterm,
  getImpoter,
  getAllBrokers,
} = shipping.actions;
const {
  getAllContainer
} = materials.actions;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllCountry,
      saveInvoiceData,
      getInvoiceData,
      getShippingRequestDetails,
      getMaterialShippingDetails,
      getIncoterm,
      getImpoter,
      getAllBrokers,
      getAllContainer
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceComponent);
