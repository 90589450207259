import React, { useReducer } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Select from "../../common/CustomSelect";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "./addressStyles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import DropDownSearch from "../../common/DropDownSearch";

import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";


const DateField = (props) => {
  registerLocale("de", de);
  const { i18n } = useTranslation();
  return (
    <Grid item xs={12}>
      <DatePicker
    locale={i18n.resolvedLanguage}
        className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box datePicker"
        margin="normal"
        variant="outlined"
        size="small"
        dateFormat="dd-MMM-yyyy"
        popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
        selected={
          props.state[props.name]
           
        }
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        {...props}
      />
    </Grid>
  );
};

// const CheckboxField = (props) => {
//   return (
//     <Grid item xs={12}>
//       <Checkbox
//         margin="normal"
//         variant="outlined"
//         size="small"
//         checked={props.state[props.name]}
//         {...props}
//       />
//       <label>{props.label}</label>
//     </Grid>
//   );
// };
const MyAddressSerarch = ({ onSearch, countries }) => {
  const classes = addressSearchStyles();
  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return {
        id: "",
        entity: "",
        department: "",
        street: "",
        postcode: "",
        city: "",
        sapnumber: "",
        approved: false,
        created_by: "",
        country_id: "",
        valid: false,
        submitted: false,
        startDate: "",
        endDate: "",
        state:""
      };
    }

    if (event.target.type === "checkbox") {
      return { ...state, [event.target.name]: event.target.checked };
    }
    return { ...state, [event.target.name]: event.target.value };
  };
  const [state, dispatch] = useReducer(reducer, {
    id: "",
    entity: "",
    department: "",
    street: "",
    postcode: "",
    city: "",
    sapnumber: "",
    approved: "",
    country_id: "",
    created_by: "",
    valid: "",
    submitted: false,
    startDate: "",
    endDate: "",
    state:""
  });

  const [t] = useTranslation();

  return (
    <>
      <Card variant="outlined" className={classes.container}>
        <CardHeader title={t("address.address")} />
        <Divider />
        <CardContent>
          <Grid container spacing={1} className="p10">
            <Grid item xs={2}>
              <TextField
                id="entity"
                name="entity"
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.Company/University")}
                onChange={dispatch}
                value={state["entity"]}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                id="street"
                name="street"
                onChange={dispatch}
                value={state["street"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.Street")}
              />
            </Grid>

           
            <Grid item xs={2}>
              <TextField
                id="city"
                name="city"
                onChange={dispatch}
                value={state["city"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.City")}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="state"
                name="state"
                onChange={dispatch}
                value={state["state"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.State")}
              />
           </Grid>
            <Grid item xs={2}>
              <TextField
                id="postcode"
                name="postcode"
                onChange={dispatch}
                value={state["postcode"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.Postal Code")}
              />
            </Grid>
            <Grid item xs={2} className="myapplicationaddress pt10" >
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt  + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} className="form_item ">
            <Grid item xs={2}>
              {/* <Select
                list={countries}
                labelName={t("address.Country")}
                value={state.country_id}
                name="country_id"
                onChange={dispatch}
                style={{ width: "100%" }}
              /> */}

<DropDownSearch
                    id="country_id"
                    name="country_id"
                    value={
                      countries.find(
                        (countryItem) => countryItem.id === state["country_id"]
                      )?.name || ""
                    }
                    list={[
                      ...(countries || []).map((country) => ({
                        label: country.name,
                        id: country.id,
                      })),
                    ]}
                    handleChange={(e) => {
                      dispatch({
                        target: { name: "country_id", value: e?.id },
                      });
                    }}
                    label={t("address.Country")}
                  />
            </Grid>

            <Grid item xs={2} className="customDatePickerWidth">
              <DateField
                placeholderText={t("phase.createdatefrom")}
                name="startDate"
                id="startDate"
                onChange={(date) =>
                  dispatch({ target: { name: "startDate", value: date } })
                }
                state={state}
              />
            </Grid>
            <Grid item xs={2} className="customDatePickerWidth">
              <DateField
                placeholderText={t("phase.createtilldate")}
                name="endDate"
                id="endDate"
                onChange={(date) =>
                  dispatch({ target: { name: "endDate", value: date } })
                }
                state={state}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default MyAddressSerarch;
