import React from 'react';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useState,useEffect } from 'react';
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { useStyles } from '../../common/ThemeStye';
import {DateFormat} from "../../common/utils/DateUtil";


const StatusInformationCard = (props) => {
    const { t, i18n } = useTranslation();

    const classes = useStyles()

    const { 
        shippingRequestDetails: [{
          status,
          sapnumber,
          created_by,
          created_on,
          updated_by,
          updated_on,
        }],

        dispatchMONValue,
        dispatchLEVValue,
        dateOfReceiptValue,
        trackingNumValue,
        extra ,
        internalOrderIdValue,
        reviewBy,
        logisticPartnerDictionaryData,
        shippingRequestDetails
    } = props
    const [logisticsPartnerClass,setLogisticsPartnerClass] = useState("")
    useEffect(()=>{
      let logclass = ""
      if(extra.logistics_partner != ""){
        for(let logpat of logisticPartnerDictionaryData){
          if(logpat.logistics_partner === extra.logistics_partner){
              if(!logpat.is_active){
                logclass = "3px solid orange"
              }
          }
        }
      }
      setLogisticsPartnerClass(logclass)
    },[])
   
    return(
        <>
        <div className="row mb10">
            <div className="col-8" >
                <Card variant="outlined">
                    <CardHeader 
                        title= {
                            <Typography className='heading_box'> {t('shippingrequest.status_information')} </Typography>
                        }
                        className={classes.cardHeader}
                    />
                    <CardContent>
                     
                        <div className=" row col-12"> 

                            <div className="col-4 mb10">
                            <label className='form-label'>   {t('shippingrequest.status')}:</label> {t("status." + status)}
                            </div>
                            <div className="col-4 mb10" style={{ display: "none"}}>
                            <label className='form-label'>   {t('shippingrequest.sapnumber')}:</label> {sapnumber} 
                            </div >

                            <div className="col-4 mb10">
                            <label className='form-label'> {t('shippingrequest.dispatchdate(mon)')}: </label>{ DateFormat(dispatchMONValue, i18n.resolvedLanguage) }
                            </div >

                            <div className="col-4 mb10">
                            <label className='form-label'>{t('shippingrequest.dispatchdate(lev)')}:</label> { DateFormat(dispatchLEVValue, i18n.resolvedLanguage) }
                            </div>

                            <div className="col-4 mb10">
                            <label className='form-label'>{t('shippingrequest.dateofrecipient')}:</label> { DateFormat(dateOfReceiptValue, i18n.resolvedLanguage) }
                            </div>

                        

                      
                            <div className='col-4 mb10'>
                            <label className='form-label'>   {t('shipping_request.tracking_number')}: </label> { trackingNumValue }
                            </div>
                            {/* <div className="col-4 mb10">
                            <label className='form-label'>Shipment reviewer comments:</label> { statusQuoValue }
                            </div> */}
                            <div className="col-4 mb10" style={{border:logisticsPartnerClass}}>
                           
                            <label className='form-label'>{t('shippingrequest.logistics_partner')}: </label>{extra?.logistics_partner}
                            </div>
                            <div className="col-4 mb10">
                            <label className='form-label'>{t('shippingrequest.internalorderid')}:</label> { internalOrderIdValue }
                            </div>
                            <div className="col-4 mb10">
                            <label className='form-label'>{t("shipping_request.assigned_shipping_review")}:</label> { reviewBy }
                            </div>
                            </div>
                       
                    </CardContent>    
                </Card>
            </div>
            <div className="col-4">
                <Card variant="outlined">
                    <CardHeader 
                        title= {
                            <Typography className='heading_box'> {t('shippingrequest.Meta_information')} </Typography>
                        }
                        className={classes.cardHeader}
                        />
                        <CardContent>
                        <div className="row col-12 mb10">
                            <div className="col-12 mb10" style={{border:(shippingRequestDetails[0].createuseractive == false)?"3px solid orange":''}}>
                            <label className='form-label'>   {t('phase.createuser')}:</label>
                                { created_by }
                            </div>
                            <div className="col-12 mb10" style={{border:(shippingRequestDetails[0].edituseractive == false)?"3px solid orange":''}}>
                            <label className='form-label' > {t('shipping_log_fields.editUser')}:</label>
                                { updated_by }
                            </div>
                            <div className="col-6 mb10">
                            <label className='form-label'>{t('shipping_log_fields.createDate')}:</label>
                                
                                { DateFormat(created_on,i18n.resolvedLanguage)}
                            </div>
                           
                            <div className="col-6 mb10">
                            <label className='form-label'>{t('shipping_log_fields.editDate')}:</label>
           
                                { DateFormat(updated_on,i18n.resolvedLanguage)}
                            </div></div>
                        </CardContent>    
                </Card>
            </div>
        </div>
      </>  
    )
}

export default StatusInformationCard;