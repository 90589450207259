export default {
  GET_ALL_SHIPPINGDATA: "GET_ALL_SHIPPINGDATA",
  SAVE_REVIEWER_DATA:"SAVE_REVIEWER_DATA",
  GET_ALL_REGULATORYDATA:"GET_ALL_REGULATORYDATA",
  GET_REG1_DETAILS:"GET_REG1_DETAILS",
  GET_REG_CONDITION:"GET_REG_CONDITION",
  SAVE_REVIEWER2_DATA:"SAVE_REVIEWER2_DATA",
  GET_REGULATORY_REVIEWER1_LIST: "GET_REGULATORY_REVIEWER1_LIST",
  SET_REGULATORY_TABLE_OPTIONS:"SET_REGULATORY_TABLE_OPTIONS",
  SET_PROCESS_SHIPPING_TABLE_OPTIONS:"SET_PROCESS_SHIPPING_TABLE_OPTIONS",
  SET_REGULATORY_SEARCH_DATA:"SET_REGULATORY_SEARCH_DATA",
  SET_DELEGATE_SEARCH_DATA:"SET_DELEGATE_SEARCH_DATA",
  SET_DELEGATE_TABLE_OPTIONS: "SET_DELEGATE_TABLE_OPTIONS",
};
