import React, { useState, useEffect,useCallback } from "react";
import { useTranslation } from "react-i18next";
import editIcon from "../../../assests/images/editIcon.svg";
import materials from "../../../redux/materials";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import MaterialTable from "@material-table/core";
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { DateWithDefaultTime, DateWithEndTime } from "../../common/utils/DateUtil";

import {
  dateSortFunction,
  sortFunction,
  SortFunctionNew,
} from "../../../Services/util";
import { tableRowFormatter, tableRowOrderManager } from "../../common/utils/TableUtil";
import tableListing from "../../../redux/tableListing";
import "./common.css";
toast.configure();
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const MaterialViewData = ({
  searchFilter,
  onMaterialSelected,
  tableData,
  actions,
  tableMaterialOptions
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [filteredData, setfilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(false);
  // const [tableConfig, setTableConfig] = useState({ page: 0, itemsPerPage: 10 });

  //To filter the data when search data changes.
  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey",
    );

    const filteredData = tableData.filter((item) => {
      for (let searchParam of validSearchKeys) {
        if (["submitted", "approved", "valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (searchParam === "startDate") {
            const createDate = moment(item.created_on);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.created_on);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          !item[searchParam]
            ?.toLowerCase()
            .includes(searchFilter[searchParam].toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });

    // const materialId = Number(
    //   sessionStorage.getItem("selectedMaterialAdminRow"),
    // );

    // const previouslySelectedItem = filteredData.find(
    //   (item) => Number(item.id) === materialId,
    // );

    // if (Number.isSafeInteger(materialId) && previouslySelectedItem) {
    //   setSelectedRow(previouslySelectedItem);
    // } else {
    //   const rowSelected = filteredData.length > 0 ? filteredData[0] : null;
    //   setSelectedRow(rowSelected);
    // }

    setfilteredData(filteredData);
  }, [searchFilter, tableData]);

  //To update parent component when material selected changes.
  useEffect(() => {
    onMaterialSelected(selectedRow);
  }, [selectedRow]);

  history.listen((location, action) => {
    if (!location.pathname.includes("material")) {
      return actions.setMaterialTableOptions({});
    }
  });

  const dispatchMaterialOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setMaterialTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableMaterialOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setMaterialTableOptions({
        pageNumber: 0,
        pageSize: tableMaterialOptions.pageSize,
        orderBy: value
      });
    }
  }
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };
  const onHandleChange = useCallback(
    (rowData) => {

      setSelectedRow(rowData);
      sessionStorage.setItem("selectedMaterialAdminRow", rowData.id);
    },
    [selectedRow]
  );

  const getTableData = () => {
    return (
      <div style={{ paddingBottom: "2%" }}>
        {filteredData?.length>0 &&
          <MaterialTable
            onPageChange={(page, itemsPerPage) => {
              // sessionStorage.setItem("selectedMaterialAdminPage", page);
              dispatchMaterialOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
            }}
            onOrderCollectionChange={(orderBy) => {
              dispatchMaterialOptions({ target: "sort" }, orderBy);
            }}
            columns={[
              {
                width: "10%",
                render: (rowData) => (
                  <input
                    type="radio"
                    name="radiovalues"
                    checked={rowData.id === selectedRow?.id}
                    onChange={() => {
                      onHandleChange(rowData)
                    }}
                  />
                ),
                sorting: false,
              },
              {
                title: <span>{t("material.materialName")}</span>,
                field: "description",
                customSort: SortFunctionNew("description"),
              },
              {
                title: <span>{t("materials.material_number")}</span>,
                field: "material_number",
                customSort: SortFunctionNew("material_number"),
              },
              {
                title: <span>{t("materials.hazard_class")}</span>,
                field: "hazard_class",
                customSort: SortFunctionNew("hazard_class"),
              },
              {
                title: <span>{t("materials.hazard_inducers")}</span>,
                field: "hazard_phrase",
                customSort: SortFunctionNew("hazard_phrase"),
              },
              {
                title: <span>{t("materials.create_date")}</span>,
                customSort: dateSortFunction("created_on"),
                render: (rowData) => dateLocalization(rowData.created_on),
              },
              {
                title: <span>{t("materials.create_user")}</span>,
                field: "created_by",
                customSort: SortFunctionNew("created_by"),
              },
              {
                title: <span>{t("materials.approved")}</span>,
                customSort: (a, b) => {
                  if (a.approved > b.approved) {
                    return -1;
                  } else if (a.approved < b.approved) {
                    return 1;
                  } else if (a.approved === b.approved) {
                    return 0;
                  } else {
                    return 0;
                  }
                },
                render: (rowData) => (
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        checked={!!rowData.approved}
                        disabled
                      />
                    </div>
                  </span>
                ),
              },
              {
                title: <span>{t("materials.valid")}</span>,
                customSort: (a, b) => {
                  if (a.valid > b.valid) {
                    return -1;
                  } else if (a.valid < b.valid) {
                    return 1;
                  } else if (a.valid === b.valid) {
                    return 0;
                  } else {
                    return 0;
                  }
                },
                render: (rowData) => (
                  <span>
                    <div>
                      <input type="checkbox" checked={!!rowData.valid} disabled />
                    </div>
                  </span>
                ),
              },
              {
                title: <span></span>,
                sorting: false,
                render: (rowData) => (
                  <span>
                    <Link to={`material/edit/${rowData.id}`} onClick={() => actions.saveTableData(rowData)}>
                      <img alt="t" className="" src={editIcon}></img>{" "}
                    </Link>
                  </span>
                ),
              },
            ]}
            data={filteredData}
            title="Render Image Preview"
            icons={tableIcons}
            localization={{
              pagination: {
                labelRowsSelect: t("commonlabels.labelRowsSelect"),
                firstAriaLabel: t("commonlabels.firstAriaLabel"),
                firstTooltip: t("commonlabels.firstTooltip"),
                previousAriaLabel: t("commonlabels.previousAriaLabel"),
                previousTooltip: t("commonlabels.previousTooltip"),
                nextAriaLabel: t("commonlabels.nextAriaLabel"),
                nextTooltip: t("commonlabels.nextTooltip"),
                lastAriaLabel: t("commonlabels.lastAriaLabel"),
                lastTooltip: t("commonlabels.lastTooltip"),
              },
              body: {
                emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
              },
            }}
            options={{
              draggable: false,
              thirdSortClick: false,
              headerStyle: {
                backgroundColor: "lightgray",
                fontWeight: "bold",
                height: "10px",
              },
              rowStyle: tableRowFormatter({
                height: "30px",
                padding: "0px",
              }),
              //  initialPage: parseInt(initialPage),
              initialPage: parseInt(tableMaterialOptions?.pageNumber ? tableMaterialOptions?.pageNumber : 0),
              maxBodyHeight: "90%",
              tableLayout: "auto",
              sorting: true,
              search: false,
              selection: false,
              showTitle: false,
              toolbar: false,
              paging: true,
              paginationType: "stepped",
              pageSize: parseInt(tableMaterialOptions?.pageSize ? tableMaterialOptions?.pageSize : 10),
              defaultOrderByCollection: tableMaterialOptions?.orderBy ? tableMaterialOptions?.orderBy : "",
              pageSizeOptions: [10, 20, 30, 40,50],
              emptyRowsWhenPaging: false,
              showSelectAllCheckbox: false,
            }}
          />
        }
        {!filteredData?.length  &&
          <div>
            <MaterialTable
            onPageChange={(page, itemsPerPage) => {
              // sessionStorage.setItem("selectedMaterialAdminPage", page);
              dispatchMaterialOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
            }}
            onOrderCollectionChange={(orderBy) => {
              dispatchMaterialOptions({ target: "sort" }, orderBy);
            }}
            columns={[
              {
                width: "10%",
                render: (rowData) => (
                  <input
                    type="radio"
                    name="radiovalues"
                    checked={rowData.id === selectedRow?.id}
                    onChange={() => {
                      onHandleChange(rowData)
                    }}
                  />
                ),
                sorting: false,
              },
              {
                title: <span>{t("material.materialName")}</span>,
               
              },
              {
                title: <span>{t("materials.material_number")}</span>,
              
              },
              {
                title: <span>{t("materials.hazard_class")}</span>,
             
              },
              {
                title: <span>{t("materials.hazard_inducers")}</span>,
              
              },
              {
                title: <span>{t("materials.create_date")}</span>,
              
              },
              {
                title: <span>{t("materials.create_user")}</span>,
               
              },
              {
                title: <span>{t("materials.approved")}</span>,
               
              },
              {
                title: <span>{t("materials.valid")}</span>,
               
              }
             
            ]}
            data={filteredData}
            title="Render Image Preview"
            icons={tableIcons}
            localization={{
              pagination: {
                labelRowsSelect: t("commonlabels.labelRowsSelect"),
                firstAriaLabel: t("commonlabels.firstAriaLabel"),
                firstTooltip: t("commonlabels.firstTooltip"),
                previousAriaLabel: t("commonlabels.previousAriaLabel"),
                previousTooltip: t("commonlabels.previousTooltip"),
                nextAriaLabel: t("commonlabels.nextAriaLabel"),
                nextTooltip: t("commonlabels.nextTooltip"),
                lastAriaLabel: t("commonlabels.lastAriaLabel"),
                lastTooltip: t("commonlabels.lastTooltip"),
              },
              body: {
                emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
              },
            }}
            options={{
              draggable: false,
              thirdSortClick: false,
              headerStyle: {
                backgroundColor: "lightgray",
                fontWeight: "bold",
                height: "10px",
              },
              rowStyle: tableRowFormatter({
                height: "30px",
                padding: "0px",
              }),
              //  initialPage: parseInt(initialPage),
              initialPage: parseInt(tableMaterialOptions?.pageNumber ? tableMaterialOptions?.pageNumber : 0),
              maxBodyHeight: "90%",
              tableLayout: "auto",
              sorting: true,
              search: false,
              selection: false,
              showTitle: false,
              toolbar: false,
              paging: true,
              paginationType: "stepped",
              pageSize: parseInt(tableMaterialOptions?.pageSize ? tableMaterialOptions?.pageSize : 10),
              defaultOrderByCollection: tableMaterialOptions?.orderBy ? tableMaterialOptions?.orderBy : "",
              pageSizeOptions: [10, 20, 30, 40,50],
              emptyRowsWhenPaging: false,
              showSelectAllCheckbox: false,
            }}
          />
          </div>
        }
      </div>
    );
  };

  return (
    <div className="col-12 pl0 pr-0 ">
      <div className="row m0">
        <div className="col-12 pr-0 pl0">{getTableData()}</div>
      </div>
    </div>
  );
};

const SelectColumn = ({ rowData, setSelectedRow, selected }) => (
  <input
    type="radio"
    name="radiovalues"
    checked={selected}
    onChange={() => {
      setSelectedRow(rowData);
      sessionStorage.setItem("selectedMaterialAdminRow", rowData.id);
    }}
  />
);

const { saveTableData } = tableListing.actions;
const { setMaterialTableOptions } = materials.actions;

const mapStateToProps = (state) => ({
  tableData: state.materials.allMaterials,
  tableMaterialOptions: state.materials.tableMaterialOptions
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ saveTableData, setMaterialTableOptions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialViewData);
