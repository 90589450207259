import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import AddressSerarch from "./AddressSearchComponent";
import AddressListingComponent from "./AddressListingComponent";
import { useTranslation } from "react-i18next";
import { addressContainerStyles } from "./addressStyles";

const Address = (props) => {
  const { actions, allAddress, tableAdressOptions,countries,countrie,state } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const classes = addressContainerStyles();
  const [t, i8ln] = useTranslation();
  useEffect(() => {
    if(!Object.keys(searchFilter).length){
      setSearchFilter(state)
    }
  }, [])
  useEffect(() => {
    actions.getCountriesList().then(() => {
      actions.getCountries().then(() => {
        actions.getAllAddress().then(() => {
          setIsLoading(false);
        });
      });
    });
  }, []);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Divider />
        <Container maxWidth="xxxl" className={classes.container}>
          <Grid container spacing={2}>
            <span className="title_main mt-1 ml-3">{t("address.address")}</span>
            <Grid item xs={12}>
              <AddressSerarch
                onSearch={(data) => setSearchFilter(data)}
                countries={countrie}
              />
            </Grid>
            <Grid item xs={12}>
           {!isLoading &&
              <AddressListingComponent
                listData={allAddress}
                actions={actions}
                searchFilter={searchFilter}
                setIsLoading={setIsLoading}
                tableAdressOptions={tableAdressOptions}
                
              />
           }
            </Grid>
          </Grid>
        </Container>
      </LoadingOverlay>
    </>
  );
};

export default Address;
