import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import IntermsComponent from "./Incoterms";

const { getIncotermList, saveIncoterm, updateIncotermAccess } = title.actions;

const mapStateToProps = (state) => ({
  isLoader: state[title.name].isLoader,
  incotermsData: state[title.name].incotermsData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getIncotermList,
      saveIncoterm,
      updateIncotermAccess,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntermsComponent);
