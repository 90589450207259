import navbar from "../../../redux/navbar";
import tableListing from "../../../redux/tableListing";

export const tableRowFormatter =
  (styles = {}) =>
  (rowData) => {
    if (!window.STORE) {
      return styles;
    }

    const navBarKey = window.STORE.getState()[navbar.name].navKey;
    const tableListingData = window.STORE.getState()[tableListing.name];

    if (
      navBarKey === tableListingData.pageIdentifier &&
      tableListingData.selectedRow?.id === rowData.id
    ) {
      return {
        ...styles,
        backgroundColor: "#e9dfe8",
      };
    }

    return styles;
  };

export const tableRowOrderManager = (data) => {
  if (!window.STORE) {
    return data;
  }

  const navBarKey = window.STORE.getState()[navbar.name].navKey;
  const tableListingData = window.STORE.getState()[tableListing.name];
  const selectedRow = data?.find(
    (rowData) => rowData.id === tableListingData.selectedRow?.id
  );
  if (navBarKey === tableListingData.pageIdentifier && selectedRow) {
    return [
      selectedRow,
      ...data.filter(
        (rowData) => rowData.id !== tableListingData.selectedRow?.id
      ),
    ];
  }

  return data;
};
