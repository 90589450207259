import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import editIcon from "../../../assests/images/infoReq.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Model from "../../shared/model";
import { bindActionCreators } from "redux";
import MaterialTable from "@material-table/core";
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import {
  dateSortFunction,
  SortFunctionNew,
} from "../../../Services/util";
import deleteIcon from "../../../assests/images/deleteIcon.svg";
import gray_deleteIcon from "../../../assests/images/grey_delete.svg";
import ActionConfirmModal from "../../common/ActionConfirmModal";
import materialService from "../../../Services/materialService";
import materialsRedux from "../../../redux/materials";

import { DateFormat, DateWithDefaultTime, DateWithEndTime} from "../../common/utils/DateUtil";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import * as config from "../../../config";
import { useHistory } from "react-router";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { tableRowFormatter, tableRowOrderManager } from "../../common/utils/TableUtil";
import tableListing from "../../../redux/tableListing";
import excelExport from "../../shared/excelExport.js"
import dateFormatter from "../../shared/dateFormatter.js";
toast.configure();
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const ApproveMaterialListing = ({
  searchFilter,
  onMaterialSelected,
  tableData,
  loader,
  actions,
  filter,
  hideDeleteButton,
  hideCheckBox,
  mymaterial,
  tableApproveMaterialOptions
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [filteredData, setfilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(false);
  const [tableConfig, setTableConfig] = useState({ page: 0, itemsPerPage: 10 });
  const [selectedForDelete, setSelectedForDelete] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  //To filter the data when search data changes.
  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey",
    );

    //To custom filter search data based on context.
    let filterFn = (a) => true;
    if (typeof filter === "function") {
      filterFn = filter;
    }

    const filteredData = tableData.filter(filterFn).filter((item) => {
      for (let searchParam of validSearchKeys) {
        if (
          ["submitted", "approved", "valid", "review_by"].includes(searchParam)
        ) {
          if (searchParam === "review_by" && item[searchParam] !== null) {
            continue;
          }
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (searchParam === "startDate") {
            const createDate = moment(item.created_on);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.created_on);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }

        if (
          !item[searchParam]
            ?.toLowerCase()
            .includes(searchFilter[searchParam].toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });

    // const materialId = Number(
    //   sessionStorage.getItem("selectedApproveMaterialItem")
    // );
    // const itemIndex = tableConfig.page * tableConfig.itemsPerPage;
    // const itemRange = filteredData.slice(
    //   itemIndex,
    //   itemIndex + tableConfig.itemsPerPage
    // );

    // const previouslySelectedItem = itemRange.find(
    //   (item) => Number(item.id) === materialId
    // );

    // if (Number.isSafeInteger(materialId) && previouslySelectedItem) {
    //   setSelectedRow(previouslySelectedItem);
    // } else {
    //   const rowSelected =
    //     filteredData.length >= itemIndex ? filteredData[itemIndex] : null;
    //   setSelectedRow(rowSelected);
    // }

    setfilteredData(filteredData);
  }, [searchFilter, tableData, tableConfig, filter]);

  //To update parent component when material selected changes.
  useEffect(() => {
    onMaterialSelected(selectedRow);
    if (selectedRow) {
      sessionStorage.setItem("selectedApproveMaterialItem", selectedRow?.id);
    }
  }, [selectedRow]);

  history.listen((location, action) => {
    
    if (!location.pathname.includes("material")) {
     
      return actions.setApproveMaterialTableOptions({});
    }
  });
  const dispatchMaterialApprovalOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setApproveMaterialTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableApproveMaterialOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setApproveMaterialTableOptions({
        pageNumber: 0,
        pageSize: tableApproveMaterialOptions.pageSize,
        orderBy: value
      });
    }
  }
  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();

    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Material_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on, i18n.resolvedLanguage);
      }
      if (item.date_of_recipient) {
        item.date_of_recipient = DateFormat(
          item.date_of_recipient,
          i18n.resolvedLanguage,
        );
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate, i18n.resolvedLanguage);
      }
      if (item.shipment_date_mon) {
        item.shipment_date_mon = DateFormat(
          item.shipment_date_mon,
          i18n.resolvedLanguage,
        );
      }

      item["Create Date"] = item["created_on"];

      item["Create User"] = item["created_by"];
      // item[t("navbar.material")] = item["description"];
      item["Material Name "] = item["description"];

      delete item["statistical_material_number_grmny"];
      delete item["statistical_material_number_moscow"];
      delete item["eid"];
      delete item["is_hazardous"];
      delete item["un_no"];
      delete item["transport_class"];
      delete item["statistical_material_number_india"];
      delete item["statistical_material_number_aust"];

      delete item["created_on"];
      delete item["material_type"];

      delete item["created_by"];

      delete item["description"];

      delete item["material_number"];

      delete item["real_material_number"];

      delete item["statistical_material_number"];

      delete item["hazard_class"];

      delete item["hazard_phrase"];
      item["Approved"] = item["approved"];
      item["Submitted"] = item["submitted"];
      item["Valid"] = item["valid"];
      if(!mymaterial) {
        item[t("material.review")] = item["review_by"];
      }      

      delete item["id"];
      delete item["approved"];
      delete item["submitted"];
      delete item["valid"];
      delete item["updated_by"];
      delete item["is_active"];
      delete item["updated_on"];
      delete item["statistical_material_number_cnd"];
      delete item["statistical_material_number_china"];
      delete item["statistical_material_number_sthfrc"];
      delete item["statistical_material_number_trky"];
      delete item["statistical_material_number_usa"];
      delete item["pack_group"];
      delete item["Hazard Class"];
      delete item["Hazard Inducers"];
      delete item["Real Sub No."];
      delete item["Tariff Classification No."];
      delete item["Hazard Class"];
      delete item["Hazard Inducers"];
      delete item["contains_der_biologic"];
      delete item["contains_animal_byproduct"];
      delete item["contains_non_domestic_animal_byproduct"];
      delete item["hazardous_sds"];
      delete item["contains_infect_substance"];
      delete item["contains_antibiotic"];
      delete item["contains_pesticide"];
      delete item["contains_parasiticide"];
      delete item["full_description"];
      delete item["fda_product_code"];
      delete item["fda_processing_program"];
      delete item["sli"];
      delete item["Material"];
      delete item["Number"];
      delete item["Real Sub No."];
      delete item["Tariff"];
      delete item["Classification No."];
      delete item["hazard_class"];
      delete item["hazard_phrase"];
      delete item["material_number"];
      delete item["real_material_number"];
      delete item["statistical_material_number"];
      delete item["review_by"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };

  const onDeleteConfirm = async () => {
    try {
      await materialService.deleteMaterial(selectedForDelete.id);
      toast.success(t("commonlabels.deleteSuccessfullyMsg"));
      actions.getAllMaterials();
    } catch (e) {
      console.error(e);
      toast.error("Operation failed");
    }
    setSelectedForDelete(false);
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };

  const getTableData = () => {
    return (
      <>
        <div style={{ paddingBottom: "2%" }}>
          {filteredData?.length>0 &&
          <MaterialTable
           onPageChange={(page, itemsPerPage) => {
            dispatchMaterialApprovalOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
          }}
          onOrderCollectionChange={(orderBy) => {
            dispatchMaterialApprovalOptions({ target: "sort" }, orderBy);
          }}
            columns={[
              {
                width: "3%",
                render: (rowData) => (
                  <input
                    type="radio"
                    name="radiovalues"
                    checked={rowData.id === selectedRow?.id}
                    onChange={() => setSelectedRow(rowData)}
                  />
                ),
                sorting: false,
                //hidden: hideCheckBox,
              },
              {
                title: <span>{t("materials.create_date")}</span>,
                customSort: dateSortFunction("created_on"),
                render: (rowData) => dateLocalization(rowData.created_on),
              },
              {
                title: <span>{t("materials.create_user")}</span>,
                field: "created_by",
                customSort: SortFunctionNew("created_by"),
              },
              {
                title: <span>{t("material.materialName")}</span>,
                field: "description",
                customSort: SortFunctionNew("description"),
              },
              {
                title: <span>{t("materials.approved")}</span>,
                customSort: (a, b) => {
                  if (a.approved > b.approved) {
                    return -1;
                  } else if (a.approved < b.approved) {
                    return 1;
                  } else if (a.approved === b.approved) {
                    return 0;
                  } else {
                    return 0;
                  }
                },
                render: (rowData) => (
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        checked={!!rowData.approved}
                        disabled
                      />
                    </div>
                  </span>
                ),
              },
              {
                title: <span>{t("materials.submitted")}</span>,
                customSort: (a, b) => {
                  if (a.submitted > b.submitted) {
                    return -1;
                  } else if (a.submitted < b.submitted) {
                    return 1;
                  } else if (a.submitted === b.submitted) {
                    return 0;
                  } else {
                    return 0;
                  }
                },
                render: (rowData) => (
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        checked={!!rowData.submitted}
                        disabled
                      />
                    </div>
                  </span>
                ),
              },
              {
                title: <span>{t("materials.valid")}</span>,
                customSort: (a, b) => {
                  if (a.valid > b.valid) {
                    return -1;
                  } else if (a.valid < b.valid) {
                    return 1;
                  } else if (a.valid === b.valid) {
                    return 0;
                  } else {
                    return 0;
                  }
                },
                render: (rowData) => (
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        checked={!!rowData.valid}
                        disabled
                      />
                    </div>
                  </span>
                ),
                sorting: true,
              },
              {
                title: <span>{t("address.review")}</span>,
                field: "review_by",
                hidden: mymaterial ? true: false,
                customSort: SortFunctionNew("review_by"),
              },
              {
                title: (
                  <span>{mymaterial ? t("commonlabels.delete") : ""} </span>
                ),
                align: "center",
                cellStyle: {
                  padding: "0px",
                },
                sorting: false,
                render: (rowData) =>
                  mymaterial ? (
                    rowData.submitted !== 1 ? (
                      <a
                        id="openModelForDelete"
                        onClick={(e) => {
                          setSelectedForDelete(rowData);
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          alt="t"
                          className="dlt_icon"
                          src={deleteIcon}
                        ></img>{" "}
                      </a>
                    ) : (
                      <a id="openModelForDelete">
                        <img
                          alt="t"
                          className="dlt_icon"
                          src={gray_deleteIcon}
                        ></img>{" "}
                      </a>
                    )
                  ) : (
                    <Link
                      to={{
                        pathname: `/view/approve/material/${rowData.id}`,
                      }}
                      onClick={()=>actions?.saveTableData(rowData)}
                    >
                      <img alt="t" className="iconsize" src={editIcon}></img>
                    </Link>
                  ),
              },
            ]}
            data={(filteredData)}
            title="Render Image Preview"
            icons={tableIcons}
            localization={{
              pagination: {
                labelRowsSelect: t("commonlabels.labelRowsSelect"),
                firstAriaLabel: t("commonlabels.firstAriaLabel"),
                firstTooltip: t("commonlabels.firstTooltip"),
                previousAriaLabel: t("commonlabels.previousAriaLabel"),
                previousTooltip: t("commonlabels.previousTooltip"),
                nextAriaLabel: t("commonlabels.nextAriaLabel"),
                nextTooltip: t("commonlabels.nextTooltip"),
                lastAriaLabel: t("commonlabels.lastAriaLabel"),
                lastTooltip: t("commonlabels.lastTooltip"),
              },
              body: {
                emptyDataSourceMessage: t(
                  "commonlabels.emptyDataSourceMessage",
                ),
              },
            }}
            options={{
              draggable: false,
              thirdSortClick: false,
              headerStyle: {
                backgroundColor: "lightgray",
                fontWeight: "bold",
                height: "10px",
              },
              rowStyle: tableRowFormatter({
                height: "30px",
                padding: "0px",
              }),
              initialPage: parseInt(tableApproveMaterialOptions?.pageNumber ? tableApproveMaterialOptions?.pageNumber : 0),
              maxBodyHeight: "90%",
              tableLayout: "auto",
              sorting: true,
              search: false,
              selection: false,
              showTitle: false,
              toolbar: false,
              paging: true,
              paginationType: "stepped",
              pageSize: parseInt(tableApproveMaterialOptions?.pageSize ? tableApproveMaterialOptions?.pageSize : 10),
              defaultOrderByCollection: tableApproveMaterialOptions?.orderBy ? tableApproveMaterialOptions?.orderBy : "",
              pageSizeOptions: [10, 20, 30, 40,50],
              emptyRowsWhenPaging: false,
              showSelectAllCheckbox: false,
            }}
          />
  }
  {!filteredData?.length  &&
       <MaterialTable
       onPageChange={(page, itemsPerPage) => {
        dispatchMaterialApprovalOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
      }}
      onOrderCollectionChange={(orderBy) => {
        dispatchMaterialApprovalOptions({ target: "sort" }, orderBy);
      }}
        columns={[
          {
            width: "3%",
            render: (rowData) => (
              <input
                type="radio"
                name="radiovalues"
                checked={rowData.id === selectedRow?.id}
                onChange={() => setSelectedRow(rowData)}
              />
            ),
            sorting: false,
            //hidden: hideCheckBox,
          },
          {
            title: <span>{t("materials.create_date")}</span>,
          },
          {
            title: <span>{t("materials.create_user")}</span>,
          
          },
          {
            title: <span>{t("material.materialName")}</span>,
          },
          {
            title: <span>{t("materials.approved")}</span>,
          
          },
          {
            title: <span>{t("materials.submitted")}</span>,
          
          },
          {
            title: <span>{t("materials.valid")}</span>,
          
            sorting: true,
          },
          {
            title: <span>{t("address.review")}</span>,
            
          },
          {
            title: (
              <span>{mymaterial ? t("commonlabels.delete") : ""} </span>
            ),
            align: "center",
            cellStyle: {
              padding: "0px",
            },
            
          },
        ]}
        data={(filteredData)}
        title="Render Image Preview"
        icons={tableIcons}
        localization={{
          pagination: {
            labelRowsSelect: t("commonlabels.labelRowsSelect"),
            firstAriaLabel: t("commonlabels.firstAriaLabel"),
            firstTooltip: t("commonlabels.firstTooltip"),
            previousAriaLabel: t("commonlabels.previousAriaLabel"),
            previousTooltip: t("commonlabels.previousTooltip"),
            nextAriaLabel: t("commonlabels.nextAriaLabel"),
            nextTooltip: t("commonlabels.nextTooltip"),
            lastAriaLabel: t("commonlabels.lastAriaLabel"),
            lastTooltip: t("commonlabels.lastTooltip"),
          },
          body: {
            emptyDataSourceMessage: t(
              "commonlabels.emptyDataSourceMessage",
            ),
          },
        }}
        options={{
          draggable: false,
          thirdSortClick: false,
          headerStyle: {
            backgroundColor: "lightgray",
            fontWeight: "bold",
            height: "10px",
          },
          rowStyle: tableRowFormatter({
            height: "30px",
            padding: "0px",
          }),
          initialPage: parseInt(tableApproveMaterialOptions?.pageNumber ? tableApproveMaterialOptions?.pageNumber : 0),
          maxBodyHeight: "90%",
          tableLayout: "auto",
          sorting: true,
          search: false,
          selection: false,
          showTitle: false,
          toolbar: false,
          paging: true,
          paginationType: "stepped",
          pageSize: parseInt(tableApproveMaterialOptions?.pageSize ? tableApproveMaterialOptions?.pageSize : 10),
          defaultOrderByCollection: tableApproveMaterialOptions?.orderBy ? tableApproveMaterialOptions?.orderBy :"",
          pageSizeOptions: [10, 20, 30, 40,50],
          emptyRowsWhenPaging: false,
          showSelectAllCheckbox: false,
        }}
      />
     }
        </div>
      </>
    );
  };
  const ExportBtn = () => {
    return (
      <button
        onClick={(e) => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };
  const ExportSelectedBtn = () => {
    let excelDataProperty = [
      { "displayName": "Material Name", "dbColumn": "description", "width": 10 },
      { "displayName": "Hazard Class", "dbColumn": "hazard_class", "width": 10 },
      { "displayName": "Hazard Inducers", "dbColumn": "hazard_phrase", "width": 10 },
      { "displayName": "Material Number", "dbColumn": "material_number", "width": 10 },
      { "displayName": "Tariff Classification Number HS-(Germany)", "dbColumn": "statistical_material_number_grmny", "width": 10 },
      { "displayName": "Tariff Classification Number (Foreign Traiff Code)", "dbColumn": "statistical_material_number_china", "width": 10 },
      { "displayName": "Tariff Classification Number (HTSUS)", "dbColumn": "statistical_material_number_usa", "width": 10 },
      { "displayName": "Hazardous according to chapter 14 SDS", "dbColumn": "is_hazardous", "width": 10 },
      { "displayName": "UN/ID", "dbColumn": "un_no", "width": 10 },
      { "displayName": "Class / Subsidiary hazard", "dbColumn": "transport_class", "width": 10 },
      { "displayName": "Packaging Group", "dbColumn": "pack_group", "width": 10 },
      { "displayName": "EID", "dbColumn": "eid", "width": 10 },
      { "displayName": "Contains or Derived from a Biologic", "dbColumn": "contains_der_biologic", "width": 10 },
      { "displayName": "Contains Animal byproduct", "dbColumn": "contains_animal_byproduct", "width": 10 },
      { "displayName": "Contains Non-Domestic Animal byproduct", "dbColumn": "contains_non_domestic_animal_byproduct", "width": 10 },
      { "displayName": "Hazardous according to chapter 2 SDS", "dbColumn": "hazardous_sds", "width": 10 },
      { "displayName": "Contains an Infectious Substance", "dbColumn": "contains_infect_substance", "width": 10 },
      { "displayName": "Contains an Antibiotic", "dbColumn": "contains_antibiotic", "width": 10 },
      { "displayName": "Contains a Pesticide", "dbColumn": "contains_pesticide", "width": 10 },
      { "displayName": "Contains a Parasiticide", "dbColumn": "contains_parasiticide", "width": 10 },
      { "displayName": "Full Description of Material", "dbColumn": "full_description", "width": 10 },
      { "displayName": "FDA Product Code", "dbColumn": "fda_product_code", "width": 10 },
      { "displayName": "FDA Processing Program", "dbColumn": "fda_processing_program", "width": 10 },
      { "displayName": "SPI", "dbColumn": "sli", "width": 10 },
      { "displayName": "Material Attachment Comments", "dbColumn": "comments", "width": 10 },
      { "displayName": "Created By", "dbColumn": "created_by", "width": 50 },
      { "displayName": "Created On", "dbColumn": "created_on", "width": 15 },
      { "displayName": "Updated By", "dbColumn": "updated_by", "width": 50 },
      { "displayName": "Updated On", "dbColumn": "updated_on", "width": 15 },
      { "displayName": "Valid", "dbColumn": "valid", "width": 10 },
    ];

    if(!mymaterial)
    {
      excelDataProperty.push({ "displayName": t("address.review"), "dbColumn": "review_by", "width": 50 });
    }
    materialService.getMaterialSelectedData(selectedRow.id).then(response => {
      let dataForExport = [];
      dataForExport.push(response[0]);
      dataForExport = dateFormatter.formateDateField(dataForExport);
      excelExport.exportAllData(excelDataProperty, dataForExport, "Material_Data", requiredFileName);
    });
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={selectedRow === false}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <>
      <div className="no_padding col-12 mb-2 d-flex justify-content-end ">
        <div className="buttonsec_animalmanagment">
          <DropdownButton
            title={t("export.export")}
            className="mr-1 exportdropdown"
          >
            <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
            <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row m0 no_padding container-fluid mt-3 mb-1"></div>
      <div>
        <div
          style={{ margin: "2px" }}
          className=" row w-100 MuiCardHeader-title"
        >
          {t("materials.search_results")}
        </div>

        <div className="row m0">
          <div className="w-100">
            <div className="">{getTableData()}</div>
          </div>
        </div>
        <ActionConfirmModal
          show={!!selectedForDelete}
          headerText={t("approve_recipient.delete_confirm_header")}
          bodyText={t("approve_materials.disapprove_confirm_body")}
          confirmButtonText={t("approve_recipient.yes")}
          cancelButtonText={t("approve_recipient.no")}
          onConfirm={() => {
            onDeleteConfirm();
          }}
          onCancel={() => {
            setSelectedForDelete(false);
          }}
        />
      </div>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </>
  );
};

const mapStateToProps = (state) => ({
  tableData: state.materials.allMaterials,
  loader: state.materials.loader,
  tableApproveMaterialOptions: state.materials.tableApproveMaterialOptions

});

const {saveTableData} = tableListing.actions;
const { setApproveMaterialTableOptions } = materialsRedux.actions;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllMaterials: materialsRedux.actions.getAllMaterials,
      saveTableData,
      setApproveMaterialTableOptions
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApproveMaterialListing);
