import React from "react";

// import CountrySearch from '../common/CountrySearch';
// import CityDicard from '../common/CityDicard';

import DestinationAddrSearch from "./DestinationAddrSearch";
import DestinationAddrCard from "./preview/DestinationAddrCard";
import {
  INSPECT,
  SUBMIT,
  HAND_BACK,
  STATUS,
  CANCEL_REQUEST,
  CLOSE,
} from "../../constants";

const DestinationComponent = (props) => {
  const {
    destinationAddrSelect,
    shippingActionType,
    shippingRequestDetails,
    redirectAction,
    redirectTo,
    extra,
    allAddressList,
    actions,
    shippingType,
    allRecipients,
    shipper
  } = props;

  const status =
    shippingRequestDetails.length > 0 && shippingRequestDetails[0].status;
  const isAddressApproved = shippingRequestDetails.length > 0 && shippingRequestDetails[0].address_approved;

  if (
    shippingActionType === INSPECT ||
    (shippingActionType === SUBMIT && status === STATUS.INPUT) ||
    shippingActionType === HAND_BACK ||
    shippingActionType === CANCEL_REQUEST ||
    shippingActionType === CLOSE
  ) {
    return (
      <DestinationAddrCard
        destinationAddrSelect={destinationAddrSelect}
        extra={extra}
        shippingType={shippingType}
        isAddressApproved={isAddressApproved}
        redirectTo={redirectTo}
      />
    );
  }

  return (
    <>
      <DestinationAddrSearch
        destinationAddrSelect={destinationAddrSelect}
        shippingRequestDetails={shippingRequestDetails}
        redirectAction={redirectAction}
        redirectTo={redirectTo}
        extra={extra}
        actions={actions}
        allAddressList={allAddressList}
        shippingType={shippingType}
        allRecipients={allRecipients}
        shipper={shipper}
      />
    </>
  );
};

export default DestinationComponent;
