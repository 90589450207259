import { React, useState, forwardRef, useEffect } from "react";
import { Link,useHistory } from "react-router-dom";
import * as config from "../../../config";
import { useLocation } from "react-router-dom";
import Model from "../../shared/model";
import { get, remove } from "lodash";
import moment from "moment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../../assests/images/infoReq.svg";
import deleteIcon from "../../../assests/images/deleteIcon.svg";
import gray_deleteIcon from "../../../assests/images/grey_delete.svg";
import { SortFunctionNew, dateSortFunction } from "../../../Services/util";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "./addressStyles";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import excelExport from "../../shared/excelExport.js"
import dateFormatter from "../../shared/dateFormatter.js";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { DateFormat,DateWithEndTime,DateWithDefaultTime } from "../../common/utils/DateUtil";
import {
  tableRowFormatter,
  tableRowOrderManager,
} from "../../common/utils/TableUtil";

const MyAddressListingComponent = (props) => {
  const pathApprovalAddressURLs = [
    "/process/shipping",
    "/approve/materials",
    "/approve/recipients",
    "/materials",
    "/recipients",
    "/address",
    "/shippingRequests",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/myShippings",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/requestByDelegate",
    "/logistics",
  ];
  const [addressSelected, setAddressSelected] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  const { tableAddressAprovalOptions,listData, actions, searchFilter, setIsLoading, myAddress } = props;
  const [filteredData, setfilteredData] = useState(listData);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const classes = addressSearchStyles();

useEffect(() => {
  return () => {
    if (pathApprovalAddressURLs.includes(window.location.pathname)) {
     
      actions.setApprovalTableOptions({});
    }
  };
}, []);
  const dispatAddressApprovalOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setApprovalTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableAddressAprovalOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setApprovalTableOptions({
        pageNumber: 0,
        pageSize: tableAddressAprovalOptions.pageSize,
        orderBy: value
      });
    }
  }
  const handleDenyApprove = (data) => {
    setAddressSelected(data);
    setConfirmationOpen(true);
  };
  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
  };
  const handleYesConfirmation = () => {
    setIsLoading(true);
    setConfirmationOpen(false);
    let addressData = {};
    addressData = {
      id: addressSelected.id,
      isReject: false,
    };
    actions.deleteAddress(addressData).then(() => {
      if (myAddress) {
        actions.getAllAddress().then(() => {
          remove(filteredData, {
            id: addressSelected.id,
          });
          toast.success(t("commonlabels.deleteSuccessfullyMsg"));
          setIsLoading(false);
        });
      } else {
        actions.getAllAddressNotApproved().then(() => {
          setIsLoading(false);
        });
      }
    });
  };
  const modalFooterEditMethod = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-inner-close"
          onClick={(e) => handleYesConfirmation(e)}
        >
          {t("commonlabels.yesButton")}
        </button>
        <button
          type="button"
          className="btn btn-black btn-inner-close"
          onClick={(e) => handleCancelConfirmation(e)}
        >
          {t("commonlabels.noButton")}
        </button>
      </>
    );
  };
  const deleteShippingRow = () => {
    if (confirmationOpen) {
      return (
        <Model
          showheader={confirmationOpen}
          modalHeader={t("phase.modaltitle")}
          modalbody={t("invoice.address-popup")}
          modalfooter={modalFooterEditMethod()}
        ></Model>
        // <Confirmation
        //   confirmationOpen={confirmationOpen}
        //   handleCancelConfirmation={handleCancelConfirmation}
        //   handleYesConfirmation={handleYesConfirmation}
        //   title={t("phase.modaltitle")}
        //   CancelText={"No"}
        //   message={"Do you really want to delete the selected Address?"}
        // />
      );
    }
  };
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };
  // useEffect(() => {
  //   setfilteredData(listData);
  // }, [listData]);

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key],
    );

    const filteredData =
      listData &&
      listData.filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (
            ["submitted", "approved", "valid", "review_by"].includes(
              searchParam
            )
          ) {
            if (searchParam === "review_by" && item[searchParam] !== null) {
              continue;
            }
            if (item[searchParam] !== 1) {
              return false;
            } else {
              continue;
            }
          }

          if (["startDate", "endDate"].includes(searchParam)) {
            if (searchParam === "startDate") {
              const createDate = moment(item.created_on);
              const startDate = moment(searchFilter[searchParam]);
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on);
              const endDate = moment(searchFilter[searchParam]);
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }
          // if (
          //   [
          //     "id",
          //     "entity",
          //     "department",
          //     "street",
          //     "postcode",
          //     "city",
          //     "sapnumber",
          //   ].includes(searchParam)
          // ) {
          //   if (
          //     (get(item, searchParam, " ") == null
          //       ? " "
          //       : get(item, searchParam, " ")
          //     ).match(get(searchFilter, searchParam, " ")) === null
          //   ) {
          //     return false;
          //   } else {
          //     continue;
          //   }
          // }

          if (
            !item[searchParam] ||
            !item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
        }
        return true;
      });

    setfilteredData(filteredData);
  }, [searchFilter, listData]);
  const { t, i18n } = useTranslation();

  const handleRadiobuttonChange = (data) => {
    setAddressSelected(data);
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Address_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };
  const handleCSVExport1 = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Address_Data");
    writeFile(wb, requiredFileName + ".xlsx");
    // setIsExportModalVisible(false); setrequiredFileName("");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      copyOfFilteredData &&
        copyOfFilteredData.forEach((element) => {
          if (element.created_on) {
            element.created_on = DateFormat(
              element.created_on,
              i18n.resolvedLanguage,
            );
          }
          if (element.shipment_date_mon) {
            element.shipment_date_mon = DateFormat(
              element.shipment_date_mon,
              i18n.resolvedLanguage,
            );
          }
          if (element.shipment_date_lev) {
            element.shipment_date_lev = DateFormat(
              element.shipment_date_lev,
              i18n.resolvedLanguage,
            );
          }
          if (element.first_name) {
            element.first_name = element.first_name + " " + element.last_name;
          }
        });
      item["Company/University"] = item["entity"];
      // item[t("address.Department")] = item["department"];
      //  item["Building"] = item["building"];
      item["State"] = item["state"];

      item["Street"] = item["street"];
      item["Postal Code"] = item["postcode"];
      item["City"] = item["city"];
      // item[t("address.SAP Number Country")] = item["sapnumber"];
      item["Create Date"] = item["created_on"];
      item["Created User"] = item["created_by"];
      item["Country"] = item["name_english"];
      item["Valid"] = item["valid"];
      item["Approved "] = item["approved"];
      item["Submitted "] = item["submitted"];
      if(!myAddress) {
        item[t("address.review")] = item["review_by"];
      }      

      delete item["id"];
      delete item["state"];
      delete item["country_id"];
      delete item["approved"];
      delete item["building"];
      delete item["city"];
      delete item["department"];
      delete item["entity"];
      delete item["postcode"];
      delete item["sapnumber"];
      delete item["street"];
      delete item["submitted"];
      delete item["valid"];
      delete item["created_on"];
      delete item["created_by"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["is_active"];
      delete item["name"];
      delete item["name_english"];
      delete item["code"];
      delete item["review_by"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  }; 
  const ExportSelectedBtn = () => {
    let excelDataProperty = [
      { "displayName": "Company/University", "dbColumn": "entity", "width": 25 },
      { "displayName": "Department", "dbColumn": "department", "width": 25 },
      { "displayName": "Building", "dbColumn": "building", "width": 10 },
      { "displayName": "Street", "dbColumn": "street", "width": 10 },
      { "displayName": "Postal Code", "dbColumn": "postcode", "width": 10 },
      { "displayName": "City", "dbColumn": "city", "width": 10 },
      { "displayName": "State", "dbColumn": "state", "width": 20 },
      { "displayName": "Country", "dbColumn": "name_english", "width": 10 },
      { "displayName": "Created By", "dbColumn": "created_by", "width": 50 },
      { "displayName": "Create On", "dbColumn": "created_on", "width": 15 },
      { "displayName": "Updated By", "dbColumn": "updated_by", "width": 50 },
      { "displayName": "Updated On", "dbColumn": "updated_on", "width": 15 },
      { "displayName": "Valid", "dbColumn": "valid", "width": 10 },
    ];

    if(!myAddress)
    {
      excelDataProperty.push({ "displayName": t("address.review"), "dbColumn": "review_by", "width": 50 })
    }

    actions.getSelectedAddressData(addressSelected.id).then((response) => {
        let dataForExport = [];
        dataForExport.push(response.data[0]);
        dataForExport = dateFormatter.formateDateField(dataForExport);
        excelExport.exportAllData(excelDataProperty, dataForExport, "Address_Data", requiredFileName);
    });
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={addressSelected === "" ? true : false}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        {location.pathname === "/myAddress" ? (
          <button
            type="button"
            id="Yes"
            disabled={requiredFileName.trim() !== "" ? false : true}
            className="btn btn-primary btn-inner-close"
            onClick={(e) => {
              handleCSVExport1();
              setIsExportModalVisible(false);
              setrequiredFileName("");
            }}
          >
            Export
          </button>
        ) : (
          <button
            type="button"
            id="Yes"
            disabled={requiredFileName !== "" ? false : true}
            className="btn btn-primary btn-inner-close"
            onClick={(e) => {
              handleCSVExport();
              setIsExportModalVisible(false);
              setrequiredFileName("");
            }}
          >
            {t("export.export")}
          </button>
        )}
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const ExportBtn = () => {
    return (
      <button
        onClick={(e) => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  }; 
  return (
    <>
      <div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
            <DropdownButton
              title={t("export.export")}
              className="mr-1 exportdropdown"
            >
              <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
              <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
            </DropdownButton>

            {/* <button
              className="btn btn-primary btn-inner-close new_button mr-1"
               disabled={get(addressSelected, "id", 0) === 0 ? true : false}
              onClick={() => {
                handleCSVExport2();
             
         
              }}
            >
              {t("shippingrequest.export")+ " " + "Selected Data"}
            </button> */}
          </div>
        </div>
      </div>
      {deleteShippingRow()}
      <Card variant="outlined">
        <CardHeader
          title={t("phase.searchresults")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
        
            <MaterialTable
          
              columns={[
                {
                  width: "0%",
                  sorting: false,
                  //hidden: { myAddress },
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(addressSelected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                },
                {
                  title: <span>{t("address.Create Date")}</span>,

                  customSort: dateSortFunction("created_on"),
                  render: (rowData) => dateLocalization(rowData.created_on),
                },
                {
                  title: <span>{t("address.Created User")}</span>,
                  field: "created_by",
                  customSort: SortFunctionNew("created_by"),
                },
                {
                  title: <span>{t("address.Company/University")}</span>,
                  field: "entity",
                  customSort: SortFunctionNew("entity"),
                },

                // {
                //   title: <span>{t("address.Department")}</span>,
                //   field: "department",
                //   customSort: SortFunctionNew("department"),
                // },
                {
                  title: <span>{t("address.Street")}</span>,
                  field: "street",
                  customSort: SortFunctionNew("street"),
                },
                {
                  title: <span>{t("address.City")}</span>,
                  field: "city",
                  customSort: SortFunctionNew("city"),
                },
                {
                  title: <span>{t("address.State")}</span>,
                  field: "state",
                  customSort: SortFunctionNew("state"),
                },
                {
                  title: <span>{t("address.Postal Code")}</span>,
                  field: "postcode",
                  customSort: SortFunctionNew("postcode"),
                },

                {
                  title: <span>{t("address.Country")}</span>,
                  field: "name_english",
                  customSort: (a, b) => {
                    if (
                      (a.name_english + a.code).toString().toLowerCase() >
                      (b.name_english + b.code).toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      (a.name_english + a.code).toString().toLowerCase() <
                      (b.name_english + b.code).toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    `${rowData.name_english} (${rowData.code})`,
                },
                {
                  title: <span>{t("address.Approved")}</span>,
                  customSort: (a, b) => {
                    if (a.approved > b.approved) {
                      return -1;
                    } else if (a.approved < b.approved) {
                      return 1;
                    } else if (a.approved === b.approved) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.approved === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("recipient.submitted")}</span>,
                  customSort: (a, b) => {
                    if (a.submitted > b.submitted) {
                      return -1;
                    } else if (a.submitted < b.submitted) {
                      return 1;
                    } else if (a.submitted === b.submitted) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.submitted === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("address.Valid")}</span>,
                  customSort: (a, b) => {
                    if (a.valid > b.valid) {
                      return -1;
                    } else if (a.valid < b.valid) {
                      return 1;
                    } else if (a.valid === b.valid) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.valid === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("address.review")}</span>,
                  field: "review_by",
                  hidden: myAddress ? true: false,
                  customSort: SortFunctionNew("review_by"),
                },
                {
                  title: <span></span>,
                  sorting: false,
                  render: (rowData) =>
                    myAddress ? (
                      rowData.submitted === 1 ? (
                        <Link>
                          <img alt="t" className="" src={gray_deleteIcon}></img>
                        </Link>
                      ) : (
                        <Link
                          className={classes.accordionLink}
                          onClick={() => handleDenyApprove(rowData)}
                        >
                          <img
                            alt="t"
                            style={{ cursor: "pointer" }}
                            className=""
                            src={deleteIcon}
                          ></img>
                        </Link>
                      )
                    ) : (
                      <Link
                        to={{
                          pathname: `/approvelInspectAddress/${rowData.id}`,
                          state: { id: rowData.id },
                        }}
                        onClick={() => actions.saveTableData(rowData)}
                        className={classes.accordionLink}
                      >
                        <img alt="t" className="iconsize" src={editIcon}></img>
                      </Link>
                    ),
                },
              ]}
              data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                sortIcon: true,
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 40],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
         
         
       </div>
    
         
        </CardContent>
      </Card>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </>
  );
};

export default MyAddressListingComponent;
