import React from "react";
import createRoot from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { authContext } from "./adalConfig";
import * as config from "./config";

import axios from "./api.js";

export async function loadUserDetails(accessToken, currentAccount) {
  let { name, preferred_username, oid } = currentAccount.idTokenClaims;
  authContext.setActiveAccount(currentAccount);
  localStorage.setItem("userName", name);
  localStorage.setItem("userId", preferred_username);
  sessionStorage.setItem("userName", preferred_username);
  const userGroupUrl =
    config.baseURL + "/api/getUserRole/";

  let conf = {
    Authorization: "Bearer " + accessToken,
    "Access-Control-Allow-Origin": "*",
  };
  const data = { oid, username: preferred_username };

  const userGroupResponse = await axios.post(
    userGroupUrl,
    data,
    { headers: conf }
  );
  localStorage.setItem(
    "userType",
    JSON.stringify(userGroupResponse.data.roles),
  );
  createRoot.render(
    <App
      pca={authContext}
      accessToken={accessToken}
      currentAccount={currentAccount}
    />,
    document.getElementById("root"),
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
