import types from "./types";

const initialState = {
  allregulatory: [],
  regulatoryData: [],
  regReviewData: [],
  regulatoryReviewer1List: [],
  tableRegOptions: {},
  tableDelegateOptions: {},
  tableProcessShippingOptions:{},
  regulatorySearchData: {
    id: "",
    id_request_old: "",
    id_old: "",
    status: "",
    entity: "",
    city: "",
    internal_distribution_number: "",
    created_by: "",
    address_id: "",
    address: "",
    final_receipt: "",
    description: "",
    created_on: "",
    tracking_number: "",
    sap_delivery_number: "",
    approved: false,
    country_id: "",
    valid: false,
    submitted: false,
    startDate: "",
    endDate: "",
    shipmentDateLevStart: "",
    shipmentDateLevEnd: "",
    shipmentDateMomStart: "",
    shipmentDateMomEnd: "",
    dateOfRecipientStart: "",
    dateOfRecipientEnd: "",
    name_english: "",
    country_of_shipper: "",
    type_of_shipment: "",
    prefered_reviewer: "",
  },
  delegateSearchData: {
    id: "",
    id_request_old: "",
    id_old: "",
    status: "",
    entity: "",
    city: "",
    internal_distribution_number: "",
    created_by: "",
    address_id: "",
    address: "",
    final_receipt: "",
    description: "",
    created_on: "",
    tracking_number: "",
    sap_delivery_number: "",
    approved: false,
    country_id: "",
    valid: false,
    submitted: false,
    startDate: "",
    endDate: "",
    shipmentDateLevStart: "",
    shipmentDateLevEnd: "",
    shipmentDateMomStart: "",
    shipmentDateMomEnd: "",
    dateOfRecipientStart: "",
    dateOfRecipientEnd: "",
    name_english: "",
    country_of_shipper: "",
    type_of_shipment: "",
    prefered_reviewer: "",
  },
};
const regulatoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_SHIPPINGDATA:
      return {
        ...state,
        allregulatory: action.payload,
      };

    case types.GET_ALL_REGULATORYDATA:
      return {
        ...state,
        regulatoryData: action.payload,
      };
    case types.GET_REG_CONDITION:
      return {
        ...state,
        regReviewData: action.payload,
      };

    case types.GET_REGULATORY_REVIEWER1_LIST:{
      return {
        ...state,
        regulatoryReviewer1List: action.payload,
      }
    }
    case types.SET_REGULATORY_TABLE_OPTIONS: {
      return {
        ...state,
        tableRegOptions: action.payload,
      };
    }
    case types.SET_PROCESS_SHIPPING_TABLE_OPTIONS: {
      return {
        ...state,
        tableProcessShippingOptions: action.payload,
      };
    }
    case types.SET_REGULATORY_SEARCH_DATA: {
      return {
        ...state,
        regulatorySearchData: action.payload,
      };
    }
    case types.SET_DELEGATE_SEARCH_DATA: {
      return {
        ...state,
        delegateSearchData: action.payload,
      };
    }
    case types.SET_DELEGATE_TABLE_OPTIONS: {
      return {
        ...state,
        tableDelegateOptions: action.payload,
      };
    }

    default:
      return state;
  }
};

export default regulatoryReducer;
