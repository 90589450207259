import React, { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import { HelpDialog } from "../common/Confirmation";
import { useTranslation } from "react-i18next";
import FileManager from "../common/FileManager/FileManager";

const AttachDocuments = (props) => {
  const { attachements, setAttachments, readOnly ,tableName} = props;

  //const classes = useStyles();
  const { t } = useTranslation();
  
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
  };

  const openHelp = () => {
    setConfirmationOpen(true);
  };

  // const readOnly = attachements.find(item=>item.created_by !== sessionStorage.getItem("userName"))

  return (
    <div className="col-lg-6 p-0 col-12">
      <span className="AttachmentSectionHeader">
        {t("shipping_request.attchement_section_header")}
        <span title={t("shipping_request.helpTitle")}>
            <HelpIcon onClick={openHelp} />
          </span>
      </span>
      <div className="row col-12 pr-0">
        <div className="col-lg-12  col-12 form-outline form-margin">
          <FileManager
          tableName={tableName}
            files={attachements}
            onChange={setAttachments}
            multiple={true}
            readOnly={readOnly}
          />
           <HelpDialog
              title={t("shipping_request.helpTitle")}
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("shipping_request.helpTextDocuments"),
                  }}
                />
              }
              confirmationOpen={confirmationOpen}
              handleCancelConfirmation={handleCancelConfirmation}
            />
        </div>
      </div>
    </div>
  );
};

export default AttachDocuments;
