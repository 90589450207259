import types from "./types";

const initialState = {
  logisticsPartnerData: [], 
};

const LogisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_LOGISTICS_PARTNER_DETAIL:
      return {
        ...state,
        logisticsPartnerData: action.payload,
      };
    default:
      return state;
  }
};

export default LogisticsReducer;
