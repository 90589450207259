/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { useCommentsModalStyles } from "./CommentsStyles";
import { useMsal } from "@azure/msal-react";
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LoadingOverlay from "react-loading-overlay";
import { DateFormat } from "../common/utils/DateUtil";
import{useDispatch} from 'react-redux'
import { toast } from "react-toastify";
import { useEffect } from "react";
import Api from '../../redux/util/api'
const archivedTagsTable = (props) =>{
    const { AlertCommentsList, openReplyModal, fromNavbar,actions,setIsLoading,fetchData } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    const commentsList = AlertCommentsList.filter(c => (c?.parent_comment_id == null || c?.parent_comment_id == 0) && c?.archive == true);
    const childRepliesList = AlertCommentsList.filter(c => c?.parent_comment_id != null);
    const parentCommentsList = commentsList?.sort(
      (objA, objB) => new Date(objB.created_on) - new Date(objA.created_on),
    );
    
    const classes = useCommentsModalStyles();
  
    /*Alert Grid Section Start*/
  
    const StyledTableRow = withStyles((theme) => ({
      root: {
        height: 20,
      },
    }))(TableRow);
  
    const StyledTableCell = withStyles((theme) => ({
      root: {
        padding: "1px 16px",
        border: "1px solid #e5e5e5",
  
      },
    }))(TableCell);
    useEffect(()=>{},[AlertCommentsList])
    return (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                height: "30%",
              }}
            >
              <TableRow
                sx={{
                  borderRight: "1px solid #e5e5e5",
                  backgroundColor: "#98AFC7",
                }}
              >
                <StyledTableCell className={classes.tablearrowcls} />
                {fromNavbar ? <StyledTableCell className={classes.tablefromcls}>{t("comments_alert.shipping_request")}</StyledTableCell> : ''}
                <StyledTableCell className={classes.tablefromcls} >{t("comments_alert.from")}</StyledTableCell>
                <StyledTableCell className={classes.tablecommentscls}>{t("comments_alert.comments")}</StyledTableCell>
                <StyledTableCell className={classes.tabletaggerUsercls}>{t("comments_alert.tagged_users")}</StyledTableCell>
                <StyledTableCell className={classes.tableDatecls}>{t("comments_alert.created_on")}</StyledTableCell>
                <StyledTableCell className={classes.tableDatecls}>{t("comments_alert.updated_on")}</StyledTableCell>
                <StyledTableCell className={classes.tablereplybtncls}></StyledTableCell>
                <StyledTableCell className={classes.tablereplybtncls}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(parentCommentsList && parentCommentsList.length > 0 )?
              parentCommentsList.map((row) => (
                <Row key={row.name} row={row}
                  openReplyModal={openReplyModal}
                  childRepliesList={childRepliesList}
                  StyledTableCell={StyledTableCell}
                  StyledTableRow={StyledTableRow}
                  setIsLoading={setIsLoading}
                  fromNavbar={fromNavbar}
                  {...props}
                />
              )):<StyledTableRow sx={{ borderRight: "1px solid #e5e5e5", "& > *": { borderBottom: "unset" } }} >
                <StyledTableCell  align="center" colSpan={8}><p>No Archived Tags</p></StyledTableCell>
                </StyledTableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      );
}
function Row(props) {
    const { row, childRepliesList, openReplyModal, StyledTableCell, StyledTableRow, fromNavbar, actions,setIsLoading,fetchData} = props;
    const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const rowRepliesList = childRepliesList.filter(c => c.parent_comment_id === row.id);
    const { instance } = useMsal();
    const userId = instance.getActiveAccount().username;
    const classes = useCommentsModalStyles();
  
    const handleRedirect = () => {
      actions.clearshippingRequest();
      actions.redirectAction("/alerts");
    }
  
    const openCommentsReplyModal = (e, row) => {
      openReplyModal(e, row);
      if (fromNavbar && !row.is_viewed) {
        //updating the count.
        updateAlertCount(row.id);
      }
    }
  
    const updateAlertCount = (commentId) => {
      actions.updateAlertCountByUserID({ userId, commentId }).then((response) => {
        fetchNotificationCount();
      });
    }
  
    const expandChild = (selectedRow) => {
      if (fromNavbar) {
        if (!open) {
          updateAlertCount(selectedRow.id);
        }
        if (open) {
          //when section is closed enter this block.
          if (!row.is_viewed) {
            row.is_viewed = true;
            childRepliesList.map(function (x) {
              if (x.parent_comment_id === selectedRow.id) {
                x.is_viewed = true;
              }
              return x
            })
          }
        }
      }
      setOpen(!open);
    }
    
    const unArchiveTag = async(e,row={})=>{
      try{
        setIsLoading(true);
        const data = await Api.unarchiveTag(row?.id,row?.archive)
        if(data.statusText != "OK" || data.status == null){
          throw new Error("Failed to Un-archive ")
        }
        fetchData();
        setIsLoading(false);
        toast.success("Un - Archived Successfully")
      }
      catch(e){
        setIsLoading(false);
        toast.error("Failed to Un-Archive")
      }
    }
    const fetchNotificationCount = () => {
      actions.getAlertNotificationsByuserId({ userId }).then((response) => {
      });
    };
  
    return (
      <React.Fragment>
        <StyledTableRow sx={{ borderRight: "1px solid #e5e5e5", "& > *": { borderBottom: "unset" } }} key={row.id}>
          <StyledTableCell className={classes.tablearrowcls} style={{ borderLeft: '1px solid #e5e5e5' }}>
            <div className="d-flex">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => expandChild(row)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {
                fromNavbar ? (row.is_viewed ? "" : <Stack direction="row" spacing={6}>
                  <Avatar sx={{ fontSize: "small", bgcolor: "transparent", color: red[500], marginTop: "-15px", marginLeft: "-15px" }}>
                    <NotificationsIcon />
                  </Avatar>
                </Stack>)
                  : ""}
            </div>
          </StyledTableCell>
  
          {fromNavbar ? <StyledTableCell className={classes.tablefromcls} >
            <div className="d-flex">
              <Link to={{ pathname: `/previewShipping/${row.shipping_id}` }} onClick={handleRedirect}>
                {row.shipment_no}
              </Link>
            </div>
          </StyledTableCell> : ''}
          <StyledTableCell align="left" component="th" scope="row" className={classes.tablefromcls} >
            <div className="form-group">
              <div className="d-flex">
                {row.first_name + ' ' + row.last_name}
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell className={classes.tablecommentscls}>{row.comment}</StyledTableCell>
          <StyledTableCell className={classes.tabletaggerUsercls}>{row.taggedusers.join(", ")}</StyledTableCell>
          <StyledTableCell className={classes.tableDatecls}>{DateFormat(row.created_on,i18n.resolvedLanguage)}</StyledTableCell>
          <StyledTableCell className={classes.tableDatecls}>{DateFormat(row.updated_on,i18n.resolvedLanguage)}</StyledTableCell>
          <StyledTableCell className={classes.tablereplybtncls}>
            <button
              type="button"
              id="AddTag"
              className="btn btn-secondary"
              onClick={(e) => openCommentsReplyModal(e, row)}
            >
              {t("comments_alert.reply_btn")}
            </button>
          </StyledTableCell>
          <StyledTableCell className={classes.tablereplybtncls}>
          <button
              type="button"
              id="AddTag"
              className="btn btn-outline-success"
              onClick={(e) => unArchiveTag(e, row)}
            >
              {t("comments_alert.unarchive_btn")}
            </button>
          </StyledTableCell>
        </StyledTableRow>
        <TableRow key={row.shipment_no}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {t("comments_alert.replies")}
                </Typography>
                <Table size="small" aria-label="alerts">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className={classes.tablechildfromcls}>{t("comments_alert.from")}</StyledTableCell>
                      <StyledTableCell className={classes.tableChildcommentscls}>{t("comments_alert.comments")}</StyledTableCell>
                      <StyledTableCell className={classes.tableChildTaggerUsercls}>{t("comments_alert.tagged_users")}</StyledTableCell>
                      <StyledTableCell className={classes.tableDatecls}>{t("comments_alert.created_on")}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowRepliesList.map((childRow) => (
                      <TableRow key={childRow.id}>
                        <TableCell component="th" scope="row" className={classes.tablechildfromcls}>
                          <div className="form-group">
                            <div className="d-flex">
                              {childRow.first_name + ' ' + childRow.last_name}
                              {
                                fromNavbar ? (childRow.is_viewed ? "" : <Stack direction="row" spacing={6}>
                                  <Avatar sx={{ fontSize: "small", bgcolor: "transparent", color: red[500], marginTop: "-15px", marginLeft: "-10px" }}>
                                    <NotificationsIcon />
                                  </Avatar>
                                </Stack>) : ""}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableChildcommentscls}>{childRow.comment}</TableCell>
                        <TableCell className={classes.tableChildTaggerUsercls}>{childRow.taggedusers.join(", ")}</TableCell>
                        <TableCell className={classes.tableDatecls}>{DateFormat(childRow.created_on,i18n.resolvedLanguage)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
export default archivedTagsTable