import { React, useState, forwardRef, useEffect, Fragment } from "react";
import { useMsal } from "@azure/msal-react";
import ReactLoading from "react-loading";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import Model from "../shared/model";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { authContext } from "../../adalConfig";
import Divider from "@mui/material/Divider";
import editIcon from "../../assests/images/editIcon.svg";
import MaterialTable from "@material-table/core";
import { useTranslation } from "react-i18next";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { SortFunctionNew } from "../../Services/util";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { DateFormat } from "../common/utils/DateUtil";
import { USERROLES } from "../../constants";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

const RegisteredUsersListingComponent = (props) => {
  const { actions, listData, searchFilter } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const [filteredData, setfilteredData] = useState(listData);
  const [selectedemail, setemail] = useState("");
  const [selectedMailID, setMailId] = useState("");
  const [selectedFirstName, setFirstName] = useState("");
  const [selectedLastName, setLastName] = useState("");
  const [selectedName, setName] = useState("");
  const [selectedMobileNo, setMobileNo] = useState("");
  const [selectedPhoneNo, setPhoneNo] = useState("");
  const [selectedDepartment, setDepartment] = useState("");
  const [isNewModelVisible, setIsNewModelVisible] = useState(false);
  const [isEditModelVisible, setIsEditModelVisible] = useState(false);
  const [isModalSaveRunning, setIsModalSaveRunning] = useState(false);
  const [addOrEditFlag, setaddOrEditFlag] = useState("");
  const [userID, setUserID] = useState("");
  const [activeFlag, setactiveFlag] = useState(true);
  const [userRoleList, setUserRoleList] = useState([]);

  const [dispalyOptions, setdispalyOptions] = useState([]);
  const [dispalyMasterRoleOptions, setDispalyMasterRoleOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const [masterUserRoles, setMasterUserRoles] = useState([])
  var userType = JSON.parse(localStorage.getItem("userType"));
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };
  useEffect(() => {
    if (addOrEditFlag === "Edit") {
      userRoleList.forEach((element) => {
        return (document.getElementById(element.roleid).checked = true);
      });
    }
  }, [document, addOrEditFlag, userRoleList]);

  useEffect(() => {
    actions.getMasterDataRoles().then((response) => {
      setDispalyMasterRoleOptions(response.data.data);
    });
  }, []);
  useEffect(() => {
    actions.getMasterDataRoles().then((response) => {
      setMasterUserRoles(response.data.data);
    });
  }, []);


  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key],
    );

    const filteredData = listData.filter((item) => {

      for (let searchParam of validSearchKeys) {
          if (
            item[searchParam] !== null && typeof item[searchParam] !== 'undefined'&&
            !item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toString().toLowerCase())
          ) {
            return false;
          } else if (item[searchParam] === null) {
            return false;
          }
        
      }
      return true;
    });
    setfilteredData(filteredData);
  }, [searchFilter, listData]);
  const { t, i18n } = useTranslation();

  const openmodalForNew = () => {
    setaddOrEditFlag("New");
    setUserRoleList([]);
    setIsNewModelVisible(true);
  };
  const openmodalForEdit = (data) => {
    setaddOrEditFlag("Edit");
    setUserRoleList([]);
    let dataroles;
    listData
      .filter((item) => item.user_id == data.user_id)
      .filter((item) => {
        dataroles = item.role_ids;
      });
    const arr = dataroles.split(",").map((element) => {
      return Number(element);
    });
    let dataroleId = [];
    for (let index = 0; index < arr.length; index++) {
      let element = arr[index];
      dataroleId.push({
        roleid: parseInt(element),
      });
    }
    setUserRoleList(dataroleId);
    setIsEditModelVisible(true);
    setMailId(data.email);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setName(data.full_name);
    setMobileNo(data.mobile_no);
    setPhoneNo(data.phone_no);
    setDepartment(data.department);
    setactiveFlag(data.is_active);
    setUserID(data.user_id);
  };

  const closemodal = () => {
    setIsNewModelVisible(false);
    setIsEditModelVisible(false);
    setemail("");
    setaddOrEditFlag("");
    setMailId("");
    setFirstName("");
    setLastName("");
    setName("");
    setMobileNo("");
    setPhoneNo("");
    setDepartment("");
    setactiveFlag(true);
  };
  //To handle event change for the roomname and the species
  const handleChangeValue = (event) => {
    if (event.target.name === "email") {
      setemail(event.target.value);
    } else if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    } else if (event.target.name === "lastName") {
      setLastName(event.target.value);
    } else if (event.target.name === "name") {
      setName(event.target.value);
    } else if (event.target.name === "mobileNo") {
      setMobileNo(event.target.value);
    } else if (event.target.name === "PhoneNo") {
      setPhoneNo(event.target.value);
    } else if (event.target.name === "department") {
      setDepartment(event.target.value);
    } else if (event.target.name === "active") {
      // let value = event.target.checked;

      if (event.target.checked) {
        //   value = 1;
        setactiveFlag(true);
      } else {
        //  value = 2;
        setactiveFlag(false);
      }
    }
  };
  // const validateEmail = (email) => {
  //   // eslint-disable-next-line
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(email);
  // };
  // const phonenumber = (inputtxt) => {
  //   var phoneno = /^([0-9\#\+\(\)\s]*)$/;
  //   return phoneno.test(inputtxt);
  // };
  // const phonenumber1 = (inputtxt) => {
  //   var phoneno = /^([0-9\#\+\(\)\s]*)$/;
  //   return phoneno.test(inputtxt);
  // };
  // search the user with email
  // const searchWithEmail = () => {
  //   setaddOrEditFlag("New")
  //   let valid = true;
  //   // var isValidEmail = validateEmail(selectedemail);

  //   // if (!isValidEmail) {
  //   //   toast.error("Please enter valid  email address");
  //   //   //  this.setState({ isHidden: true });
  //   //   return valid = false;
  //   // }
  //   if (selectedemail !== "") {
  //     listData.filter(item => {
  //       if (item.email.includes(selectedemail)) {
  //         toast.error("User already added");
  //         return valid = false;
  //       }
  //     })
  //   }

  //   if (valid) {

  //     // get SAP CDC details based on email id
  //     actions.getUserDetails(selectedemail).then((response) => {
  //       // handle success

  //       if (response.data === '' || response.data === null || response.data === undefined || response.data.value.length == 0) {
  //         toast.error('User does not exists');
  //         setresponseData(false)
  //         return;
  //       }
  //       else {
  //         setresponseData(true);
  //         setMailId(response.data.value[0].mail);
  //         setFirstName(response.data.value[0].givenName);
  //         setLastName(response.data.value[0].surname);
  //         setName(response.data.value[0].displayName);
  //         setMobileNo(response.data.value[0].mobilePhone);
  //         setPhoneNo(response.data.value[0].businessPhones[0]);
  //         setDepartment(response.data.value[0].department);

  //         //insert query

  //       }

  //     })
  //       .catch((error) => {
  //         // handle error

  //       });
  //   }

  // }
  // autocomplete on focus event
  const onFocusToLoadSearchOptions = (e) => {
    if (e.target.value !== "") {
      onSearchLoadOptions(e.target.value);
    }
  };
  // auotcomplete on search event
  const onSearchLoadOptions = (query) => {
  
    actions.getUserDetails(query).then((response) => {

      const options = response.data.value.map((i) => ({
        email: i.mail,
        givenName: i.givenName,
        surname: i.surname,
        displayName: i.displayName,
        mobilePhone: i.mobilePhone,
        businessPhones: i.businessPhones,
        department: i.department,
      }));
      setdispalyOptions(options);
    });
  };

  const handleSearchUser = (e) => {
    if (e.length !== 0) {
      let valid = true;
      listData.filter((item) => {
        if (item.email.toLowerCase() === e[0].email.toLowerCase()) {
          toast.error("User already added");
          return (valid = false);
        }
      });

      if (valid) {
        setMailId(e[0].email);
        setFirstName(e[0].givenName);
        setLastName(e[0].surname);
        setName(e[0].displayName);
        setMobileNo(e[0].mobilePhone);
        setPhoneNo(e[0].businessPhones[0]);
        setDepartment(e[0].department);
      }
    } else {
      setMailId("");
      setFirstName("");
      setLastName("");
      setName("");
      setMobileNo("");
      setPhoneNo("");
      setDepartment("");
    }
  };
  const miniSpinner = () => {
    return (
      <div>
        <ReactLoading
          type="spinningBubbles"
          color="#0072ce"
          display="inline"
          width="34px"
          height="34px"
        />
      </div>
    );
  };
  const saveMethod = () => {
    try {
      let error = "";

      if (!selectedMailID) {
        error = t("recipient.Email") + " ,";
      }

      if (userRoleList.length === 0) {
        error = error + t("RegisteredUser.User_Roles") + " ,";
      }

      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req3") +
            " " +
            `${error}` +
            " " +
            t("commonlabels.is_req4"),
        );
      }

      let valid = true;

      // if (selectedMobileNo) {
      //   var isValidEmail = phonenumber(selectedMobileNo);
      //   if (!isValidEmail) {
      //     toast.error(t("RegisteredUser.validMobile"));
      //     //  this.setState({ isHidden: true });
      //     return valid = false;
      //   }
      // }
      // if (selectedPhoneNo) {
      //   var isValidEmail1 = phonenumber1(selectedPhoneNo);
      //   if (!isValidEmail1) {
      //     toast.error(t("RegisteredUser.validPhone"));
      //     //  this.setState({ isHidden: true });
      //     return valid = false;
      //   }
      // }
      if (selectedMobileNo && selectedMobileNo.length > 40) {
        toast.error(t("RegisteredUser.validMobilelength"));
        return (valid = false);
      }
      if (selectedPhoneNo && selectedPhoneNo.length > 40) {
        toast.error(t("RegisteredUser.validPhonelength"));
        return (valid = false);
      }
      const req = {
        userid: 0,
        createdby: userId,
        departmentname: selectedDepartment,
        firstname: selectedFirstName,
        isactive: activeFlag,
        lastname: selectedLastName,
        mail: selectedMailID,
        mobilePhone: selectedMobileNo,
        name: selectedName,
        phone: selectedPhoneNo,
        updatedBy: userId,
        rolejson: userRoleList,
      };
      if (selectedFirstName.length !== 0) {
        setIsModalSaveRunning(true);
        actions.saveUser(req).then((res) => {
          // setIsLoading(false);
          closemodal();
          toast.success(t("commonlabels.savedSuccessfullyMsg"));
          setIsModalSaveRunning((prevBool) => !prevBool);
          // handleRedirectTo(res.data);
          //history.push(`/address`);

          actions.getAllUsers().then(() => {
            setIsLoading(false);
          });
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const UpdateMethod = () => {
    try {
      let error = "";

      if (userRoleList.length === 0) {
        error = error + t("RegisteredUser.User_Roles");
      }
      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req1") +
            " " +
            `${error}` +
            " " +
            t("commonlabels.is_req2"),
        );
      }
      let valid = true;

      // if (selectedMobileNo) {
      //   var isValidEmail = phonenumber(selectedMobileNo);
      //   if (!isValidEmail) {
      //     toast.error(t("RegisteredUser.validMobile"));
      //     //  this.setState({ isHidden: true });
      //     return valid = false;
      //   }
      // }
      // if (selectedPhoneNo) {
      //   var isValidEmail1 = phonenumber1(selectedPhoneNo);
      //   if (!isValidEmail1) {
      //     toast.error(t("RegisteredUser.validPhone"));

      //     //  this.setState({ isHidden: true });
      //     return valid = false;
      //   }
      // }
      if (selectedMobileNo && selectedMobileNo.length > 40) {
        toast.error(t("RegisteredUser.validMobilelength"));
        return (valid = false);
      }
      if (selectedPhoneNo && selectedPhoneNo.length > 40) {
        toast.error(t("RegisteredUser.validPhonelength"));
        return (valid = false);
      }
      const req = {
        userid: userID,
        createdby: userId,
        departmentname: selectedDepartment,
        firstname: selectedFirstName,
        isactive: activeFlag,
        lastname: selectedLastName,
        mail: selectedMailID,
        mobilePhone: selectedMobileNo,
        name: selectedName,
        phone: selectedPhoneNo,
        updatedBy: userId,
        rolejson: userRoleList,
      };
      if (selectedFirstName.length !== 0) {
        setIsModalSaveRunning(true);
        actions.editUser(req).then((res) => {
          // setIsLoading(false);
          closemodal();
          toast.success(t("commonlabels.editedSuccessfullyMsg"));
          setIsModalSaveRunning((prevBool) => !prevBool);
          // handleRedirectTo(res.data);
          //history.push(`/address`);

          actions.getAllUsers().then(() => {
            setIsLoading(false);
          });
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const userStatus =(status)=>{
    return (status)?"Active":"In-Active"
  }
  const handleUserRoleCheckbox = (e, id1) => {
    const value = e.target.checked;

    if (e.target.checked) {
      userRoleList.push({
        roleid: parseInt(id1),
      });
    }

    // Removing the unchecked value in the array.
    if (!e.target.checked) {
      for (var i = 0; i < userRoleList.length; i++) {
        var obj = userRoleList[i];

        if (obj.roleid === parseInt(id1)) {
          userRoleList.splice(i, 1);
        }
      }
    }

    userRoleList.forEach((element) => {

      document.getElementById(element.roleid).checked = true;
    });

  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Registered_User");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on, i18n.resolvedLanguage);
      }

      if (item.dateofrecipient) {
        item.dateofrecipient = DateFormat(
          item.dateofrecipient,
          i18n.resolvedLanguage,
        );
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate, i18n.resolvedLanguage);
      }

      item["Email "] = item["email"];

      item["First Name"] = item["first_name"];
      item["Last Name"] = item["last_name"];
      item["User Roles "] = roleIdsForExport(item["role_ids"]);
      item["User Status"] = userStatus(item["is_active"])
      item["Mobile"] = item["mobile_no"];
      item["Phone"] = item["phone_no"];
      item["Department "] = item["department"];
      delete item["id"];
      delete item["recievingcountry"];
      delete item["orgincountry"];

      delete item["email"];
      delete item["first_name"];
      delete item["full_name"];
      delete item["mobile_no"];
      delete item["phone_no"];
      delete item["department"];
      delete item["role_ids"];
      delete item["last_name"];
      delete item["is_active"];
      delete item["user_id"];

      // item[t("Roles_id")] = item["role_ids"];
      // item[t("User_Id")] = item["user_id"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const roleIdsForExport = (rowRoles)=>{
    let filterRoles = rowRoles.split(', ')
    let string = ""
    for(let role of filterRoles){
      let user = masterUserRoles.find((item)=>{return item.role_id.toString() === role})
      string = string  +  user?.role_name + " , "
    }
    if(string){
      return string
    }
    else{
      return ""
    }
  }
  const roleIds = (rowData)=>{
    let roles = rowData.role_ids.split(", ")
    let string = ""
    for(let role of roles){
      let user = masterUserRoles.find((item)=>{return item.role_id.toString() === role})
      string = string  +  user?.role_name + " , "
    }
    if(string){
      return string
    }
    else{
      return ""
    }
  }
  return (
    <>
      <div className="buttonright mt-20">
        <button
          className="btn btn-primary btn-inner-close new_button mr-1"
          // disabled={get(addressSelected, "id", 0) === 0 ? true : false}
          onClick={() => {
            setIsExportModalVisible(true);
          }}
        >
          {t("shippingrequest.export")}
        </button>{" "}
        {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
          <button
            className="btn btn-primary btn-inner-close new_button mr-2"
            onClick={(e) => {
              openmodalForNew();
            }}
          >
            {t("address.new")}
          </button>
        ) : (
          <></>
        )}
      </div>
      <Card variant="outlined">
        <CardHeader
          title={t("approve_recipient.search_text")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />

        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
            <MaterialTable
              columns={[
                {
                  title: <span>{t("recipient.Email")}</span>,
                  field: "email",
                  customSort: SortFunctionNew("email"),
                },
                {
                  title: <span>{t("RegisteredUser.firstname")}</span>,
                  field: "first_name",
                  customSort: SortFunctionNew("first_name"),
                },
                {
                  title: <span>{t("RegisteredUser.lastname")}</span>,
                  field: "last_name",
                  customSort: SortFunctionNew("last_name"),
                },
                {
                  title: <span>{t("RegisteredUser.name")}</span>,
                  field: "full_name",
                  customSort: SortFunctionNew("full_name"),
                },
                {
                  title: <span>{t("RegisteredUser.User_Roles")}</span>,
                  field: "role_ids",
                  render:(rowdata)=>(roleIds(rowdata)),
                  customSort: SortFunctionNew("role_ids"),
                },
                {
                  title: <span>{t("shipping_request.status")}</span>,
                  render:(rowdata)=>((rowdata.is_active)?"Active":"In-Active" ),
                  customSort: SortFunctionNew("is_active"),
                },
                {
                  title: <span>{t("RegisteredUser.mobile")}</span>,
                  field: "mobile_no",
                  customSort: SortFunctionNew("mobile_no"),
                },
                {
                  title: <span>{t("RegisteredUser.phone")}</span>,
                  field: "phone_no",
                  customSort: SortFunctionNew("phone_no"),
                },
                {
                  title: <span>{t("RegisteredUser.department")}</span>,
                  field: "department",
                  customSort: SortFunctionNew("department"),
                },
                {
                  title: <span></span>,
                  sorting: false,

                  render: (rowData) =>
                    userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
                      <span>
                        <a
                          id="openmodalForEdit"
                          onClick={(e) => {
                            openmodalForEdit(rowData);
                          }}
                        >
                          <img alt="t" className="" src={editIcon}></img>{" "}
                        </a>
                      </span>
                    ) : (
                      <></>
                    ),
                },
              ]}
              data={filteredData}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: {
                  height: "30px",
                  padding: "0px",
                },

                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 40],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
          </div>
        </CardContent>
      </Card>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Modal
        dialogClassName="modal-90w"
        show={addOrEditFlag === "New" ? isNewModelVisible : isEditModelVisible}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h5>
            {" "}
            {(addOrEditFlag === "New"
              ? t("commonlabels.newButton")
              : t("commonlabels.edit")) +
              " " +
              t("commonlabels.user")}{" "}
          </h5>

          <a id="openmodalForEdit" onClick={(e) => closemodal(e)}></a>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4 m0">
            <div className="col-12 pr-0">
              <div className="row align-items-center">
                <div className=" col-lg-12 pl0 pr-0">
                  {addOrEditFlag == "New" ? (
                    <div className="col-md-12 pl0 pr0">
                      <AsyncTypeahead
                        id="userData"
                        labelKey="displayName"
                        minLength={3}
                        //clearButton
                        onChange={(e) => handleSearchUser(e)}
                        onSearch={onSearchLoadOptions}
                        options={dispalyOptions}
                        placeholder={t("commonlabels.register_user_comp_place_holder")}
                        renderMenuItemChildren={(option, props) => (
                          <Fragment>
                            <span>{option.displayName}</span>
                          </Fragment>
                        )}
                      />{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-md-12 pl-0">
                    <label className="mt-2" for="email">
                      <b>{t("recipient.Email")}</b>&nbsp;:&nbsp;{" "}
                    </label>
                    <input
                      type="text"
                      placeholder={t("recipient.Email")}
                      disabled
                      className="form-control "
                      id="Email"
                      name="Email"
                      value={selectedMailID}
                    />
                  </div>
                  <div className="row col-md-12 pl-0  pr-0">
                    <div className="col-md-6 ">
                      <label className="mt-2" for="firstName">
                        <b>{t("RegisteredUser.firstname")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <input
                        type="text"
                        placeholder={t("RegisteredUser.firstname")}
                        disabled
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        onChange={(e) => handleChangeValue(e)}
                        value={selectedFirstName}
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0">
                      <label className="mt-2" for="lastName">
                        <b>{t("RegisteredUser.lastname")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <input
                        type="text"
                        placeholder={t("RegisteredUser.lastname")}
                        disabled
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        onChange={(e) => handleChangeValue(e)}
                        value={selectedLastName}
                      />
                    </div>
                  </div>

                  <div className="row col-md-12 pl-0  pr-0">
                    <div className="col-md-6">
                      <label className="mt-2" for="name">
                        <b>{t("RegisteredUser.name")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <input
                        type="text"
                        placeholder={t("RegisteredUser.name")}
                        disabled
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={(e) => handleChangeValue(e)}
                        value={selectedName}
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0">
                      <label className="mt-2" for="mobileNo">
                        <b>{t("RegisteredUser.mobile")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <input
                        type="text"
                        placeholder={t("RegisteredUser.mobile")}
                        // disabled
                        className="form-control"
                        id="mobileNo"
                        name="mobileNo"
                        onChange={(e) => handleChangeValue(e)}
                        value={selectedMobileNo}
                      />
                    </div>
                  </div>
                  <div className="row col-md-12 pl-0  pr-0">
                    <div className="col-md-6">
                      <label className="mt-2" for="PhoneNo">
                        <b>{t("RegisteredUser.phone")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <input
                        type="text"
                        placeholder={t("RegisteredUser.phone")}
                        // disabled
                        className="form-control"
                        id="PhoneNo"
                        name="PhoneNo"
                        onChange={(e) => handleChangeValue(e)}
                        value={selectedPhoneNo}
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0">
                      <label className="mt-2" for="department">
                        <b>{t("RegisteredUser.department")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <input
                        type="text"
                        placeholder={t("RegisteredUser.department")}
                        disabled
                        className="form-control"
                        id="department"
                        name="department"
                        onChange={(e) => handleChangeValue(e)}
                        value={selectedDepartment}
                      />
                    </div>
                  </div>

                  <div className="row col-md-12  pr-0">
                    <label className="mt-2" for="userRole">
                      <b>{t("RegisteredUser.User_Roles")}</b>&nbsp;:&nbsp;
                      <span className="req">*</span>{" "}
                    </label>
                    <div className="checkBox">
                      {dispalyMasterRoleOptions &&
                        dispalyMasterRoleOptions.map((el1, i) => (
                          <>
                            <input
                              type="checkbox"
                              id={el1.role_id}
                              value={el1.role_id}
                              onChange={(e) =>
                                handleUserRoleCheckbox(e, el1.role_id)
                              }
                            />
                            &nbsp;
                            <label className="">{el1.role_name}</label>&nbsp;
                          </>
                        ))}
                    </div>
                  </div>
                  {addOrEditFlag === "New" ? (
                    <></>
                  ) : (
                    <div>
                      <label className="mt-2" for="userRole">
                        <b>{t("RegisteredUser.Active_User")}</b>&nbsp;:&nbsp;{" "}
                      </label>
                      <div className="checkBox">
                        <input
                          type="checkbox"
                          name="active"
                          id="active"
                          onChange={(e) => handleChangeValue(e)}
                          checked={activeFlag === true}
                        />
                        &nbsp;&nbsp;
                        <label className="">{t("RegisteredUser.Active")}</label>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            {isModalSaveRunning && miniSpinner()}
            <button
              type="button"
              id="updateMethodButton"
              disabled={isModalSaveRunning}
              className="btn btn-primary btn-inner-close"
              onClick={
                addOrEditFlag === "New"
                  ? (e) => saveMethod(e)
                  : (e) => UpdateMethod(e)
              }
            >
              {t("commonlabels.saveButton")}
            </button>
            <button
              type="button"
              id="closeUpdateMethodButton"
              disabled={isModalSaveRunning}
              className="btn btn-black btn-inner-close"
              onClick={(e) => closemodal(e)}
            >
              {t("commonlabels.cancelbutton")}
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegisteredUsersListingComponent;
