import $ from "jquery";

const querySelector = "input, select, textarea, .MuiFormControl-root, button";

export function disableInputFieldsBySelector(selector = 'body') {
    $(selector).attr("disabled", "disabled");
    $(selector).find(querySelector).attr("disabled", "disabled");
    $(selector).find(querySelector).css("pointer-events", "none");
}

export function enableInputFieldsBySelector(selector = 'body') {
    $(selector).removeAttr("disabled");
    $(selector).css("pointer-events", "");
    $(selector).find(querySelector).removeAttr("disabled");
    $(selector).find(querySelector).css("pointer-events", "");
}