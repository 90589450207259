import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "../../common/PageLayout";
import ConfirmPickupMainPage from "./ConfirmPickupMainPage";

const ConfirmPickup = (props) => {
  const { actions, processShippmentList } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(async () => {
    actions.getProcessShippmentList();
  }, [actions]);

  return (
    <>
      <PageLayout className="title_main mt-1"
        title={t("process_shipping_request.process_shipping_request")}
        searchComponent={false}
        mainComponent={
          <ConfirmPickupMainPage processShippmentList={processShippmentList} />
        }
        isLoading={false}
      />
    </>
  );
};

export default ConfirmPickup;
