import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import "./autoComplete.css";
export default function AutocompleteText(props) {
  const { options, label, onChange, name } = props;
  return (
    // <Stack spacing={1} sx={{ width: 450 }}>
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      forcePopupIcon={true}
      getOptionLabel={(option) => option}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" />
      )}
      onInputChange={(event, value) => {
        if (onChange) {
          onChange({ target: { name, value } });
        }
      }}
      {...props}
      onChange={() => {}}
    />
    // </Stack>
  );
}
