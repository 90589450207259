import types from "./types";
import fetchData from "../util";

export const saveInvoiceData = (...params) => {
  return fetchData(types.SAVE_INVOICE_DATA, "saveInvoiceData", params);
};

export const getInvoiceData = (...params) => {
  return fetchData(types.GET_INVOICE_DATA, "getInvoiceData", params);
};
export const clearInvoiceData = (payload = "") => {
  return {
    type: types.CLEAR_INVOICE_DATA,
    payload,
  };
};

export default {
  saveInvoiceData,
  getInvoiceData,
  clearInvoiceData,
};
