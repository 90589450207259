import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import TitleComponent from "./Title";



const { 
    getTitleData,
    saveTitleData,
    updateTitleAccess,
} = title.actions
const mapStateToProps = (state) => ({
  titleData: state[title.name].titleData,
  isLoader: state[title.name].isLoader,

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getTitleData,
            saveTitleData,
            updateTitleAccess,

        },
        dispatch
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(TitleComponent);
