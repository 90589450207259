import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import BasicTabs from "../common/TabMenu";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import shippingActions from "../../redux/shipping/actions"
import { lowerCase } from "lodash";

export default function NewRequestCommentsComponent(props) {
  const { actions, newShippingRequestCommentsList, allUsers } = props;
  const [value, setValue] = useState([]);
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [allusersList, setAllUsersList] = useState([]);
  const { t } = useTranslation();
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    actions.getAllusers();
    if(newShippingRequestCommentsList){
    setComments(newShippingRequestCommentsList.comments);
    setValue(newShippingRequestCommentsList.taggedusers);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let selectedUsers = [{'email': userId}];//Add here to remove current user from the list    
    const filteredResult = allUsers.filter(
      (x) => !selectedUsers.filter((y) => lowerCase(y.email) === lowerCase(x.email)).length
    );
    setAllUsersList(filteredResult);    
  }, [allUsers, userId]); 

  const handleTagInput = (e, newValue) => {  
    setValue(newValue);
};

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  };

  const saveComments = (e) => {
    try {
      let valid = true;

      if (comments === "") {
        toast.error(t("comments_alert.validComment"));
        return (valid = false);
      }
      
      const req = {
        shippingid: 0, 
        comments: comments,
        parentcommentid: null,
        createdon: null,
        createdby: userId,
        taggedusers: value,
        archive:false
      };
      dispatch(shippingActions.setNewShippingRequestCommentsList(req));
      history.push("/shipping");
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  }

  return (
    <LoadingOverlay active={isLoading} spinner>
      <BasicTabs />
      <div className="container-fluid">
        <div className="row mlr15 border ">
          <div className="col-12">
            <div className="form-group row">
              <label class="col-sm-10 col-form-label">
                {t("comments_alert.alert/tag")}
              </label>
            </div>
          </div>
          <div className="col-12">
          <div className="row mb-4 m0">
            <div className="col-12 align-items-center p-0">
                <div className="form-group">
                  <label htmlFor="" className="col-form-label">
                    {t("comments_alert.taguser")}
                  </label>
                  <Autocomplete
                  multiple
                  id="tag-users"
                  options={allusersList}
                  value={value}
                  onChange={handleTagInput}
                  getOptionLabel={(option) => option.email}
                  renderInput={(params) => (
                    <TextField {...params}  placeholder="Select one or more users to tag" />
                  )}
                  sx={{ width: '100%' }}
                />
                </div>
            </div>
            <div className="col-lg-12 p-0">
              <div className="form-group">
                <label className="">
                  {" "}
                  <b>{t("comments_alert.comments")}</b>{" "}
                </label>
                <TextareaAutosize
                  id="full_description"
                  name="full_description"
                  aria-label="full_description"
                  margin="normal"
                  variant="outlined"
                  size="large"
                  minRows={6}
                  style={{ width: "100%" }}
                  value={comments === null ? "" : comments}
                  onChange={(e) => handleChangeComments(e)}
                />
              </div>
            </div>
            <div className="col-lg-12 form-group text-right p-0">
                <button
                  type="button"
                  id="savemethod"
                  className="btn btn-primary btn-inner-close pull right"
                  onClick={(e) => saveComments(e)}
                >
                  {t("comments_alert.save_Comment")}
                </button>
            </div>            
          </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}