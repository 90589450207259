import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import address from "../../redux/address";
import shipping from "../../redux/shipping";
import materials from "../../redux/materials";
import RequestDeputyComponent from "./RequestDeputyComponent";
import tableListing from "../../redux/tableListing";
import regulatory from "../../redux/regulatory";

const {
  getAllAddress,
  saveAddress,
  getAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getAllCountry,
  
} = address.actions;

const {
  getCountries,
  getCities,
  getRequestByDeputy,
  getProcessShippmentList,
  getShippingRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  getShippingActionType,
  clearShippingMaterialSelectedValue,
  addShippingMaterialSelect,
  getShippingAttachment,
  getShipperDetails,
  redirectAction,
  exportSelectedExcel
} = shipping.actions;

const {saveTableData} = tableListing.actions;

const {setDelegateTableOptions,setDelegateSearchData} = regulatory.actions;

const mapStateToProps = (state) => ({
  allAddress: state[address.name].allAddress,
  isLoader: state[address.name].isLoader,
  countries: state[address.name].allCountry,
  cities: state[shipping.name].cities,
  requestDeputy: state[shipping.name].requestDeputy,
  allMaterials: state[materials.name].allMaterials,
  tableDelegateOptions: state[regulatory.name].tableDelegateOptions,
  delegateSearchData:state[regulatory.name].delegateSearchData
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setDelegateSearchData,
      getAllAddress,
      getAllCountry,
      getAddress,
      getCountries,
      getCities,
      saveAddress,
      editAddress,
      updateAddressAccess,
      updateAddressApproval,
      getAddressRecipient,
      getRequestByDeputy,
      getProcessShippmentList,
      getShippingRequestDetails,
      getDeputyShippingDetails,
      getMaterialShippingDetails,
      getShippingActionType,
      clearShippingMaterialSelectedValue,
      addShippingMaterialSelect,
      getShippingAttachment,
      getShipperDetails,
      redirectAction,
      exportSelectedExcel,
      saveTableData,
      setDelegateTableOptions,
    },
    dispatch,
  ),
});

export default {
  RequestDeputyComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RequestDeputyComponent),
};
