import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import shipping from "../../../redux/shipping";
import ProcessShippingRequestLog from "./ProcessShippingRequestLog";

const { setShippingLogSearchData,  setAdminShippingTableOptions,getShippingHistory,setShippingSearchData, } = shipping.actions;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setShippingLogSearchData,
      getShippingHistory,
      setShippingSearchData,
      setAdminShippingTableOptions,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  shippmentHistoryLogs: state[shipping.name].shippmentHistoryLogs,
  shippingLogSearchData: state[shipping.name].shippingLogSearchData,
  shippingSearchData: state[shipping.name].shippingSearchData,
  tableAdminShippingOptions: state[shipping.name].tableAdminShippingOptions,

});

export default {
  ProcessShippingRequestLog: connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProcessShippingRequestLog),
};
