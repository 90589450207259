import { makeStyles } from "@material-ui/core/styles";

export const filterStyles = makeStyles((theme) => ({
    actionButtonsTop: {
      marginTop: "7px",
      marginBottom: "2px",
      float: "right",
      marginRight: "unset",
    },
    textLable: {
      width: "235px !important",
      marginTop: "25px",
      marginRight: "10px",
      fontWeight: "600",
    },
    textLableAsterisk: {
      color: "#ff0000",
    },
    textLableSAP: {
      width: "160px !important",
      marginTop: "25px",
      marginRight: "10px",
      fontWeight: "600",
    },
    textLables: {
      width: "173px !important",
      marginRight: "10px",
      fontWeight: "600",
    },
    cardActionButtons: {
      marginBottom: "45px",
      float: "right",
    },
    container: {
      marginTop: "15px",
    },
    searchCard: {
      minHeight: "400px",
    },
    searchBar: {},
    tableRadio: {
      "-webkitTransform": "scale(1.5) !important",
    },
    searchTextField: {
      backgroundColor: "#ffff",
      height: "100%",
      fontSize: "1rem",
      width: "96%",
      float: "right",
    },
    searchTitle: {
      color: "#EE2E5D",
      margin: "30px 0px 15px 30px",
      fontSize: "1rem",
      fontWeight: "600",
    },
  
    searchBt: {
      marginTop: "16px !important",
      marginRight: "10px !important",
    },
    reloadBt: {
      backgroundColor: "#424242 !important",
      marginTop: "16px !important",
    },
    cancelBt: {
      "&:hover": {
        backgroundColor: "#4e4e4e",
      },
      backgroundColor: "#4e4e4e",
      textTransform: "none",
      marginRight: "10px",
      color: "#ffff",
      borderRadius: 0,
      fontSize: "1rem",
    },
    selectOrder: {
      marginLeft: "10px",
    },
    wildCard: {
      marginLeft: "4px",
      marginRight: "4px",
    },
    errorMsg: {
      display: "flex",
      justifyContent: "center",
    },
  }));