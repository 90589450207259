import React from 'react'
import Modal from './Modal'

export default function ActionConfirmModal(props) {
    const { show, headerText, bodyText, confirmButtonText, cancelButtonText } = props;

    return (
        <Modal
            onCancel={props.onCancel}
            show={show}
            header={headerText}
            body={bodyText}
            footer={(
                <>
                    <button
                        className='btn btn-primary'
                        onClick={props.onConfirm}>
                        {confirmButtonText}
                    </button>
                    <button
                        className='btn btn-secondary'
                        onClick={props.onCancel}>
                        {cancelButtonText}
                    </button>
                </>
            )}
        />
    )
}
