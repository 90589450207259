import React, { useState, useEffect } from "react";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import { authContext } from "../../adalConfig";
import axios from "axios";
import * as config from "../../config";
import logo from "../../logo_blue.svg";
import "../HeaderComponent/header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import globeIcon from "../../assests/images/globe.png";
import { USERROLES } from "../../constants";
import { useMsal } from "@azure/msal-react";
function Header({ currentAccount }) {
  const { instance } = useMsal();
  const [image, setImage] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [displayRole, setDisplayRole] = useState("");

  const userType = JSON.parse(localStorage.getItem("userType"));
  useEffect(() => {
    getPhoto();
    getName();
    userRole();
  }, []);
  const { t, i18n } = useTranslation();

  localStorage.setItem("lang", i18n);
  let language = i18n.resolvedLanguage;

  const changeLanguage = (event) => {
    language = i18n.changeLanguage(event.target.value);
  };

  const getName = () => {
    const name =
      instance.getActiveAccount() !== null
        ? instance.getActiveAccount().name.split(" ")
        : "";

    let displayName = "";

    if (name !== "") {
      displayName =
        name.length > 1
          ? name[0].substring(0, 1) + name[1].substring(0, 1)
          : name[0].substring(0, 1);
    }
    setDisplayName(displayName);

    return displayName;
  };

  const userRole = () => {
    let roles = "";
    if (userType.length > 0) {
      if (userType.includes(USERROLES.PROCESSMANAGER)) {
        roles = "Shipping Reviewer,";
      }
      if (userType.includes(USERROLES.ADMIN)) {
        roles = roles + "Approval Administrator,";
      }
      if (userType.includes(USERROLES.APPLICANT)) {
        roles = roles + "Requestor,";
      }
      if (userType.includes(USERROLES.REGULATORYREVIEWER1)) {
        roles = roles + "Regulatory Reviewer 1,";
      }
      if (userType.includes(USERROLES.REGULATORYREVIEWER2)) {
        roles = roles + "Regulatory Reviewer 2,";
      }
      if (userType.includes(USERROLES.SYSTEMADMINISTRATOR)) {
        roles = roles + "System Administrator,";
      }
      if (userType.includes(USERROLES.READONLY)) {
        roles = roles + "Read Only,";
      }
      setDisplayRole( roles.slice(0, -1));
    } else {
      return "";
    }
  };

  const getPhoto = () => {
    let userId = currentAccount.idTokenClaims
      ? currentAccount.idTokenClaims.oid
      : null;
    var ImageURL;

    axios.get(config.baseURL + "/api/getPhoto/" + userId).then((response) => {
      if (response.data === "Image not found") {
        ImageURL = "";
        setImage(ImageURL);
        return ImageURL;
      } else {
        let arrayBufferResponse = response.data;
        ImageURL = `data:image/gif;base64,${arrayBufferResponse}`;
        setImage(ImageURL);
        return ImageURL;
      }
    });
  };

  return (
    <div className="header-topmargin">
      <Nav className="navbar fixed-top bg-white" activeKey="/">
        <div>
          <Nav.Item className="color-black">
            <Nav.Link
              className="navbar-brand nav-link-black pt-0 pb-0"
              href="/"
            >
              <img
                className="logo"
                alt="Elanco Global R&D Study Material Logistics Hub"
                src={logo}
              />
              <span class="divider-head"></span>
              &nbsp;&nbsp;| &nbsp;
              <span className="color-black">{t("dashboard.title")}</span>
            </Nav.Link>
          </Nav.Item>
        </div>
        <div className="ml-auto language_section">
          <label>
            <img src={globeIcon} alt="" className="imglobe"></img> &nbsp;&nbsp;
          </label>
          <select value={language} onChange={changeLanguage}>
            <option value="de">German - Deutsch </option>
            <option value="en">English</option>
            {/*  <option value="sp">Spanish</option> */}
          </select>

          <ul className="profilename">
            <li>
              {instance.getActiveAccount() !== null
                ? instance.getActiveAccount().name
                : ""}
            </li>
            <li>
              <span title={displayRole} id="roles_display">
                {"[" + displayRole.split(",")[0] + (userType.length > 1 ? "...":"") + "]"}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <Nav.Item className="ml-auto" style={{ display: "flex" }}>
            <NavDropdown
              title={
                image ? (
                  <span className="profileImage">
                    <img src={image} />
                  </span>
                ) : (
                  <div className="" style={{ color: "#fff" }}>
                    {displayName}
                  </div>
                )
              }
              id="basic-nav-dropdown"
              className="justify-content-end profile-icon"
            >
              <NavDropdown.Item href="javascsript:void(0)">
                {instance.getActiveAccount() !== null
                  ? instance.getActiveAccount().username
                  : ""}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Button
                  className="btn btn-primary btn-inner w-100"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    instance.logoutRedirect();
                  }}
                >
                  Log out
                </Button>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </div>
      </Nav>
    </div>
  );
}
export default Header;
