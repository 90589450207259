import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ModalCloseIcon from "../../assests/images/ModalCloseIcon.png";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCommentsModalStyles } from "./CommentsStyles";

export default function AddAlertModalComponent(props) {
  const {
    showmodalAlert,
    isModalSaveRunning,
    saveMethod,
    closeReplyModal,
    handleChangeComments,
    miniSpinner,
    handleChange,
    allUsersList
  } = props;
  const { t } = useTranslation();

  const [replyComments, setReplyComments] = useState("");
  const [tagvalue, setTagValue] = useState([]);

  const classes = useCommentsModalStyles();
  
  const handleTagInput = (e, newValue) => {  
      handleChange(e, newValue);
      setTagValue(newValue);
  };

  const handlereplyChangeComments = (e) => {
    setReplyComments(e.target.value);
    handleChangeComments(e);
  };

  const closeCommentsModal = (e) => {
    setReplyComments("");
    setTagValue([]);
    closeReplyModal();
  };

  const saveReplyMethod = (e) => {
    saveMethod(e);
    setReplyComments("");
    setTagValue([]);
  };

  return (
    <div>
      {/* Model for reply to the commnet  */}
      <Modal dialogClassName="my-modal" centered show={showmodalAlert}>
        <Modal.Header centered="true" className="bg-theme-blue btn-primary">
          <h5 className="modal-title">{t("comments_alert.addTag")}</h5>
          <a id="closemodal" onClick={(e) => closeCommentsModal(e)}>
            <img alt="closeModal" style={{ width: "15px" }} className="" src={ModalCloseIcon}></img>{" "}
          </a>
        </Modal.Header>
        <Modal.Body className={classes.modalHeight}>
          <div className="row mb-4 m0">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="" className="col-form-label">
                  {t("comments_alert.taguser")}
                </label>
                <Autocomplete
                  multiple
                  id="tag-users"
                  options={allUsersList}
                  value={tagvalue}
                  onChange={handleTagInput}
                  getOptionLabel={(option) => option.email}
                  renderInput={(params) => (
                    <TextField {...params}  placeholder="Select one or more users to tag" />
                  )}
                  sx={{ width: '100%' }}
                />
              </div>
              <div className="form-group">
                <label className="">
                  {" "}
                  <b>{t("comments_alert.comments")}</b>{" "}
                </label>
                <TextareaAutosize
                  className={classes.textAreaScroll}
                  id="full_description"
                  name="full_description"
                  aria-label="full_description"
                  margin="normal"
                  variant="outlined"
                  size="large"
                  minRows={6}
                  style={{ width: "100%" }}
                  value={replyComments === null ? "" : replyComments}
                  onChange={(e) => handlereplyChangeComments(e)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {isModalSaveRunning && miniSpinner()}
          <button
            type="button"
            id="savemethod"
            disabled={isModalSaveRunning}
            className="btn btn-primary btn-inner-close"
            onClick={(e) => saveReplyMethod(e)}
          >
            {t("comments_alert.submit")}
          </button>
          <button
            id="closeButtonForSave"
            type="button"
            disabled={isModalSaveRunning}
            className="btn btn-black btn-inner-close"
            onClick={(e) => closeCommentsModal(e)}
          >
            {t("comments_alert.cancel")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}