import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory, useLocation } from "react-router-dom";
import { STATUS, INSPECT, SUBMIT } from "../../../src/constants";
//import ShippingRequest from "../shippingRequest";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import shipping from "../../redux/shipping";
import { USERROLES } from "../../../src/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs({ shippingDetails, shippingActionType }) {
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { errorTab } = useSelector((state) => state.errors);
  const { navKey } = useSelector((state) => state.navbar);
  var userType = JSON.parse(localStorage.getItem("userType"));

  useEffect(() => {
    if (location.pathname === "/material") {
      setValue(1);
    } else if (location.pathname === "/reviewerList") {
      setValue(2);
    } else if (location.pathname === "/invoice") {
      setValue(3);
    } else if (location.pathname === "/reports") {
      setValue(4);
    } else if (location.pathname === "/comments") {
      setValue(5);
    } else if (location.pathname === "/newShippingComments") {
      setValue(5);
    } else if (location.pathname === "/shipmentdocumentation") {
      setValue(6);
    } else if (location.pathname === "/processingdocumentation") {
      setValue(7);
    }
  }, []);

  const handleShippingRequestClick = async () => {
    history.push("/shipping");
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {(shippingActionType !== INSPECT  && shippingActionType !== SUBMIT  )  && (
            <Tabs value={value} aria-label="tabs">
              <Tab
                label={t("shipping_request.shipping_request")}
                style={
                  errorTab === 1 || errorTab === 3
                    ? { textTransform: "none", border: "1px solid red" }
                    : { textTransform: "none" }
                }
                index={0}
                value={0}
                onClick={handleShippingRequestClick}
              />
              <Tab
                label={t("navbar.material")}
                style={
                  errorTab === 2 || errorTab === 3
                    ? { textTransform: "none", border: "1px solid red" }
                    : { textTransform: "none" }
                }
                value={1}
                index={1}
                onClick={() => history.push("/material")}
              />

              {shippingDetails?.length &&
                ![
                  STATUS.INPUT,
                  STATUS.REGULATORY_REVIEWER_1,
                  STATUS.REGULATORY_REVIEWER_2,
                ].includes(shippingDetails[0]?.status) && (
                  <Tab
                    label={t("navbar.invoice")}
                    style={{ textTransform: "none" }}
                    value={3}
                    index={3}
                    onClick={() => history.push("/invoice")}
                  />
                )}

              <Tab
                label={t("navbar.Reviewer")}
                style={{ textTransform: "none" }}
                value={2}
                index={2}
                onClick={() => history.push("/reviewerList")}
              />
              {shippingDetails?.length &&
                ![
                  STATUS.INPUT,
                  STATUS.REGULATORY_REVIEWER_1,
                  STATUS.REGULATORY_REVIEWER_2,
                ].includes(shippingDetails[0]?.status) && (
                  <Tab
                    label="Reports"
                    style={{ textTransform: "none" }}
                    value={4}
                    index={4}
                    onClick={() => history.push("/reports")}
                  />
                )}
              {shippingDetails?.length && (
                <Tab
                  label={t("comments_alert.comments")}
                  style={{ textTransform: "none" }}
                  value={5}
                  index={5}
                  onClick={() => history.push("/comments")}
                />
              )}
              {shippingDetails?.length === 0 && (
                <Tab
                  label={t("comments_alert.comments")}
                  style={{ textTransform: "none" }}
                  value={5}
                  index={5}
                  onClick={() => history.push("/newShippingComments")}
                />
              )}
              {(shippingDetails?.length > 0 && navKey === 'PROCESS_SHIPPING_REQUESTS') && (
                <Tab
                  label={t("reports.shipping_documentation_nav_header")}
                  style={{textTransform:"none"}}
                  value={6}
                  index={6}
                  onClick={() => history.push("/shipmentdocumentation")}
                />
              )}
              {(shippingDetails?.length > 0 && navKey === 'PROCESS_SHIPPING_REQUESTS') && 
                (userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true || userType.includes(USERROLES.PROCESSMANAGER) === true) && (
                <Tab
                  label={t("reports.processing_documentation_nav_header")}
                  style={{ textTransform: "none" }}
                  value={7}
                  index={7}
                  onClick={() => history.push("/processingdocumentation")}
                />
              )}
            </Tabs>
          )}
          {(shippingActionType === INSPECT || shippingActionType === SUBMIT) && (
            <Tabs value={value} aria-label="tabs">
              <Tab
                label={t("shipping_request.shipping_request")}
                style={
                  errorTab === 1 || errorTab === 3
                    ? { textTransform: "none", border: "1px solid red" }
                    : { textTransform: "none" }
                }
                index={0}
                value={0}
                onClick={handleShippingRequestClick}
              />
               <Tab
                label={t("navbar.material")}
                style={
                  errorTab === 2 || errorTab === 3
                    ? { textTransform: "none", border: "1px solid red" }
                    : { textTransform: "none" }
                }
                value={1}
                index={1}
                onClick={() => history.push("/material")}
              />
              {shippingDetails?.length && (
                <Tab
                  label={t("comments_alert.comments")}
                  style={{ textTransform: "none" }}
                  value={5}
                  index={5}
                  onClick={() => history.push("/comments")}
                />
              )}
              {shippingDetails?.length === 0 && (
                <Tab
                  label={t("comments_alert.comments")}
                  style={{ textTransform: "none" }}
                  value={5}
                  index={5}
                  onClick={() => history.push("/newShippingComments")}
                />
              )}
              {shippingDetails?.length > 0 && (shippingDetails[0].status === STATUS.READY_FOR_DELIVERY ||
                shippingDetails[0].status === STATUS.IN_DELIVERY) && (
                  <Tab
                    label={t("reports.shipping_documentation_nav_header")}
                    style={{textTransform:"none"}}
                    value={6}
                    index={6}
                    onClick={() => history.push("/shipmentdocumentation")}
                  />
                )}
              {shippingDetails?.length > 0 && (navKey === 'PROCESS_SHIPPING_REQUESTS' || navKey === 'ADMINISTRATION_SHIPPING_REQUEST') &&
                (userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true || userType.includes(USERROLES.PROCESSMANAGER) === true) &&
                (shippingDetails[0].status === STATUS.READY_FOR_DELIVERY ||
                shippingDetails[0].status === STATUS.IN_DELIVERY ||
                shippingDetails[0].status === STATUS.CANCELLED ||
                shippingDetails[0].status === STATUS.INBOX ||
                shippingDetails[0].status === STATUS.INPUT ||
                shippingDetails[0].status === STATUS.REGULATORY_REVIEWER_1 ||
                shippingDetails[0].status === STATUS.REGULATORY_REVIEWER_2 ||
                shippingDetails[0].status === STATUS.AWAITING_FOR_APPROVAL) && (
                  <Tab
                    label={t("reports.processing_documentation_nav_header")}
                    style={{ textTransform: "none" }}
                    value={7}
                    index={7}
                    onClick={() => history.push("/processingdocumentation")}
                  />
                )}
            </Tabs>
          )}
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  redirectTo: state[shipping.name].redirectTo,
  shippingDetails: state[shipping.name].shippingRequestDetails,
  shippingActionType: state[shipping.name].shippingActionType,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTabs);
