import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { addressSearchStyles } from "../../Admin/Address/addressStyles";

const fields = {
  operation: "",
  class_name: "",
  id: "",
  field_name: "",
};

const ProcessShippingRequestLogSearch = (props) => {
  const { onSearch, actions, searchKey, state } = props;
  const classes = addressSearchStyles();

  //Reset search data on key change.
  useEffect(() => {
    if (state.searchKey !== searchKey) {
      dispatch({ type: "RESET" });
      onSearch({});
    } else {
      onSearch({ ...state });
    }
  }, []);

  const dispatch = (event) => {
    if (event.type === "RESET") {
      onSearch({});
      return actions.setShippingLogSearchData({ ...fields });
    }

    if (event.target.type === "checkbox") {
      return actions.setShippingLogSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }

    return actions.setShippingLogSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };

  const { t } = useTranslation();

  // const handleFieldChange = useCallback(
  //     (data) => {
  //       const lang1 = t("shipping_log_fields", { returnObjects: true });
  //       let value;
  //       Object.values(lang1).forEach((item, index) => {
  //         if (item === data.target.value) {
  //           value = Object.keys(lang1)[index];
  //         }
  //       });

  //       if (!value) {
  //         value = "";
  //       }
  //       data.target.value = value;

  //       dispatch(data);
  //     },
  //   []
  // );

  return (
    <>
      <div className="tablebg_box">
        <div className="row">
        <div className="col-11 pr-0">
        <div className="row col-12 ">
        <div className="col-lg-3  pr-0 mt-2">
            <TextField
              id="operation"
              name="operation"
              value={state["operation"]}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={t("shippingrequest.Transaction")}
              onChange={dispatch}
            />
         </div>
         <div className="col-lg-3  pr-0 mt-2">
            <TextField
              id="class_name"
              name="class_name"
              value={state["class_name"]}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={t("shippingrequest.type_of_bo")}
              onChange={dispatch}
            />
          </div>
          <div className="col-lg-3  pr-0 mt-2">
            <TextField
              id="id"
              name="id"
              value={state["id"]}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={t("shippingrequest.Object_ID")}
              onChange={dispatch}
            />
           
           </div>
           <div className="col-lg-3  pr-0 mt-2">
            <TextField
              id="field_name"
              name="field_name"
              value={state["field_name"]}
              margin="normal"
              variant="outlined"
              size="small"
              placeholder={`Field`}
              onChange={dispatch}
            />
            {/* <AutocompleteText
              id="field_name"
              name="field_name"
              value={state["field_name"]}
              options={_(t("shipping_log_fields", { returnObjects: true })).filter(item=>!item.includes(':')).uniq().sort().value()}
              label={t("shippingrequest.Field")}
              onChange={handleFieldChange}
            /> */}
          </div>
          </div>
          </div>
          <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
            <Button
              variant="contained"
              className={classes.searchBt  + " search_buttontwo"}
              onClick={() => onSearch({ ...state })}
            >
              <SearchIcon />
            </Button>

            <Button
              variant="contained"
              className={classes.reloadBt + " refresh_button"}
              onClick={() => dispatch({ type: "RESET" })}
            >
              <ReplayIcon />
            </Button>
            </div>
        </div>
       
      </div>
      <br></br>
    </>
  );
};

// const ItemContainer = ({ children }) => {
//   return <div className="col-12 col-sm-2">{children}</div>;
// };

export default ProcessShippingRequestLogSearch;
