import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import address from "../../../redux/address";
import shipping from "../../../redux/shipping";
import CountryCatalogueComponent from "./CountryCatalogueComponent";

const {
  getAllAddress,
  saveAddress,
  getAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getAllCountry,
} = address.actions;
const { getCountries, getCities } = shipping.actions;

const mapStateToProps = (state) => ({
  allAddress: state[address.name].allAddress,
  isLoader: state[address.name].isLoader,
  countries: state[address.name].allCountry,
  cities: state[shipping.name].cities,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllAddress,
      getAllCountry,
      getAddress,
      getCountries,
      getCities,
      saveAddress,
      editAddress,
      updateAddressAccess,
      updateAddressApproval,
      getAddressRecipient,
    },
    dispatch,
  ),
});

export default {
  CountryCatalogueComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CountryCatalogueComponent),
};
