import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import Add from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useStyles } from "../common/ThemeStye";
import { connect } from "react-redux";
import shippingService from "../../redux/shipping/actions";
import { bindActionCreators } from "redux";
import shippingRedux from "../../redux/shipping";
import DynamicTableComponent from "./DynamicTableComponent";

const tableColumns = [{
    name: "navbar.Reviewer",
    style: { width: "30%" }
}, {
    name: "commonlabels.Created_Date",
    style: { width: "20%" }
}, {
    name: "shipping_request.Comments",
    style: { width: "45%" }
}, {
    name: "",
    style: { width: "10%" }
}];

const OverAllComments = (props) => {
    const [comment, setComment] = useState("");
    const [editId, setEditId] = useState(undefined);
    const { actions, shippingRequestId, overAllComments, shippingRequestStatus, readOnly,cb} = props;
    const { t, i18n } = useTranslation();
    const { instance } = useMsal();
    const classes = useStyles();

    const userId = instance.getActiveAccount().username;

    useEffect(() => {
        if (shippingRequestId) {
            actions.getReviewerCommentsByShippingRequestId({
                shippingRequestId
            });
        }
    }, [shippingRequestId]);

    // const handleCommentChange = (e)=>{
        
    //     cb(e.target.value,)

    // }
    const handleAddComment = async () => {
        if (comment) {
            actions.addReviewerComment({
                shippingRequestId,
                comment: comment,
                created_by: userId
            });
            cb(comment)
            setComment("");
        }
        else{
            cb(comment)
        }
    }

    const handleUpdateComment = () => {
        if (comment) {
            actions.updateReviewerComment({
                shippingRequestId,
                commentId: editId,
                comment: comment,
            });
            cb(comment)
            handleCancel();
        }
        else{
            cb(comment)
        }
    }

    const handleEditComment = (commentId) => {
        setEditId(commentId);
        const selectedComment = overAllComments.find(item => item.id === commentId)?.comment;
        setComment(selectedComment);
    }

    const handleCancel = () => {
        setEditId(undefined);
        setComment("");
    }

    return (
        <>
            <br></br>
            <Card variant="outlined" id="overall_comments">
                <CardHeader
                    title={
                        <Typography className="heading_box">
                            {" "}
                            {t("navbar.Overall_Comments")}{" "}
                        </Typography>
                    }
                    className={classes.header}
                />
                    <CardContent>
                        <div className="row mt-2 mr-0 no-marrgin" data-name="overall-comments">
                            <div className="col-lg-10 col-md-10 col-sm-10 col-12 form-outline form-margin">
                                <label className="form-label">{ t("shipping_request.Comments")}:</label>
                                <textarea
                                    className={editId ? "error-border" : ""}
                                    style={{ width: "100%" }}
                                    name="overall_comments"
                                    rows={2}
                                    cols={70}
                                    maxLength="1000"
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-12 form-outline form-margin">
                                {!editId && !readOnly && <Button
                                    style={{ top: "35px" }}
                                    size="small"
                                    variant="contained"
                                    className="btn btn-primary new_button mr-1"
                                    disabled={!comment}
                                    onClick={handleAddComment}
                                >
                                    <Add />
                                </Button>}
                                {editId && !readOnly && <><Button
                                    style={{ top: "35px" }}
                                    size="small"
                                    variant="contained"
                                    className="btn btn-primary new_button mr-1"
                                    onClick={handleUpdateComment}
                                >
                                    {t("commonlabels.updateButton")}
                                </Button>
                                <Button
                                    style={{ top: "35px" }}
                                    size="small"
                                    variant="contained"
                                    className="cancelbutton"
                                    onClick={handleCancel}
                                >
                                    {t("commonlabels.cancelbutton")}
                                </Button></>}
                            </div>
                        </div>
                        {overAllComments.length ? <div className="mt-2 ml-2 mr-2 mb-3" data-name="overall-comments">
                            <DynamicTableComponent
                                tableColumns={tableColumns}
                                tableRows={overAllComments}
                                handleEditRow={handleEditComment}
                                shippingRequestStatus={shippingRequestStatus}
                                readOnly={readOnly}
                            />
                        </div> : null}
                    </CardContent>
            </Card>
        </>
    );
}

const mapStateToProps = (state) => ({
    overAllComments: state[shippingRedux.name].overallComments
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        getReviewerCommentsByShippingRequestId: shippingService.getReviewerCommentsByShippingRequestId,
        addReviewerComment: shippingService.addReviewerComment,
        updateReviewerComment: shippingService.updateReviewerComment
    }, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(OverAllComments);