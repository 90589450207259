import React from "react";
import  MaterialDataLoader  from "../Admin/materials/MaterialDataLoader";
function Footer() {
  // get present year 
  const getYear = () => {
    var d = new Date();
    var n = d.getFullYear();
    return n;
  };
  return (

    <footer className="footer mt-4 d-flex">

      <div className="foot-text">Global R&D Study Material Logistics Hub © {getYear()} Elanco </div>
      <MaterialDataLoader/>
    </footer>
  );
}
export default Footer;
