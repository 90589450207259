import types from "./types";
import fetchData from "../util";

export const setNavKey = (data) => {
  return { type: types.SET_NAV_KEY, payload: data };
};
const getAlertNotificationsByuserId = (...params) => {
  return fetchData(types.GET_ALERT_NOTIFICATION_BY_USERID, "getAlertNotificationsByuserId", params);
};

export default {
  setNavKey,
  getAlertNotificationsByuserId
};
