import baseAPI from "./baseAPI";
const url = window.env.__baseAPIUrl;

class Api extends baseAPI {
  static getAllCountries = () => Api.get(`${url}/api/country/all`)
  static updateCountry = (countryData) => Api.post(`${url}/api/updateCountry`, countryData)
  static saveCountry = (countryData) => Api.post(`${url}/api/saveCountry`, countryData)
  static getCountries = () => Api.get(`${url}/api/countries`);
  static getCountriesList = () => Api.get(`${url}/api/countries/list`);
  static getUserDetails = (emailID) =>
    Api.get(`${url}/api/getUserDetails/${emailID}`);

  static getApproveRegDetails = (shippingid) =>
    Api.get(`${url}/api/reviewer/${shippingid}`);

  static getApproveReg1condition = (shippingid) =>
    Api.get(`${url}/api/getApproveReg1condition/${shippingid}`);

  static saveUser = (userData = {}) =>
    Api.post(`${url}/api/saveUserDetails`, userData);

  static editUser = (userData = {}) =>
    Api.post(`${url}/api/editUserDetails`, userData);

  static saveRegulatoryReveiwerDetails = (reviewerData = {}) =>
    Api.post(`${url}/api/saveRegulatoryReveiwerDetails`, reviewerData);

  static saveRegulatoryReveiwer2Details = (reviewerData = {}) =>
    Api.post(`${url}/api/saveRegulatoryReveiwer2Details`, reviewerData);

  static getCities = (countryId) =>
    Api.get(`${url}/api/cities?countryId=${countryId}`);

  static getDistributionTemperature = () =>
    Api.get(`${url}/api/distributionTemperature`);

  static getMasterDataRoles = () => Api.get(`${url}/api/masterUserRoles`);

  static getAllAddress = () => Api.get(`${url}/api/allAddress`);
  static getAllAddressList = () => Api.get(`${url}/api/allAddressList`);

  static getAddressRecipient = (id) =>
    Api.get(`${url}/api/addressRecipient?id=${id}`);
  static getAllTitle = () => Api.get(`${url}/api/recipients/allTitle`);
  static getAllCountry = () => Api.get(`${url}/api/allCountry`);
  static saveAddress = (addressData = {}) =>
    Api.post(`${url}/api/saveAddress`, addressData);
  static getAddress = (id) => Api.get(`${url}/api/address?id=${id}`);
  static getMyAddress = (id) => Api.get(`${url}/api/myAddress?id='${id}'`);
  static editAddress = (addressData = {}) =>
    Api.post(`${url}/api/editAddress`, addressData);
  static deleteAddress = (addressData = {}) =>
    Api.post(`${url}/api/deleteAddress`, addressData);
  static updateAddressAccess = (addressData = {}) =>
    Api.post(`${url}/api/address/updateAccessStatus`, addressData);
  static updateAddressApproval = (addressData = {}) =>
    Api.post(`${url}/api/address/updateApprovalStatus`, addressData);
  static getAllAddressNotApproved = () =>
    Api.get(`${url}/api/allAddressApprovel`);
  static getAddressShipping = (id) =>
    Api.get(`${url}/api/addressShipping?id=${id}`);
  static getDestinationAddress = (
    countryId = "",
    city = "",
    state = "",
    entity = ""
  ) => {
    return Api.get(
      `${url}/api/destinationAddress?countryId=${countryId ? encodeURIComponent(countryId) : ""
      }&city=${city ? encodeURIComponent(city) : ""}&state=${state ? encodeURIComponent(state) : ""
      }&entity=${entity ? encodeURIComponent(entity) : ""}`
    );
  };
  static getDestinationAddressSearchOptions = (
    countryId = "",
    city = "",
    state = "",
    entity = ""
  ) => {
    return Api.get(
      `${url}/api/search/address/options?countryId=${countryId ? encodeURIComponent(countryId) : ""
      }&city=${city ? encodeURIComponent(city) : ""}&state=${state ? encodeURIComponent(state) : ""
      }&entity=${entity ? encodeURIComponent(entity) : ""}`
    );
  };
  static getFinalRecipients = (addressId) =>
    Api.get(`${url}/api/finalRecipients?addressId=${addressId}`);
  static getmyFinalRecipients = (addressId) =>
    Api.get(`${url}/api/myfinalRecipients?id=${addressId}`);

  static getDeputies = () => Api.get(`${url}/api/shippingDeputies`);

  static createShipping = (jsonData) =>
    Api.post(`${url}/api/shipping`, jsonData);
  static SubmitUpdate = (jsonData) =>
    Api.post(`${url}/api/newshippingSubmitUpdate`, jsonData);
  static getShippings = () => Api.get(`${url}/api/shippings`);
  static exportExcel = () => Api.get(`${url}/api/exportData`);

  static getMyShippings = (userName) =>
    Api.get(`${url}/api/myshippings?userName=${userName}`);

  static getDestinationAddrList = () =>
    Api.get(`${url}/api/destinationAddrList`);


  static getShippingMeterials = () => Api.get(`${url}/api/shippingMaterials`);

  static addMaterialShippingRequest = (jsonData) =>
    Api.post(`${url}/api/materialShippingRequest`, jsonData);
  static getAllrecipients = () => Api.get(`${url}/api/allRecipients`);
  static saveRecipients = (jsonData) =>
    Api.post(`${url}/api/saveRecipients`, jsonData);
  static updateRecipients = (jsonData, id) =>
    Api.post(`${url}/api/recipients/${id}`, jsonData);

  static updateRecipientsAccess = (recipientsData = {}) =>
    Api.post(`${url}/api/updateRecipientsAccess`, recipientsData);
  static updateRecipientsApproval = (recipientsData = {}) =>
    Api.post(`${url}/api/updateRecipientsApproval`, recipientsData);

  static getAllMaterials = () => Api.get(`${url}/api/material/list`);
  static addDeputiesShippingRequest = (jsonData) =>
    Api.post(`${url}/api/deputiesShippingRequest`, jsonData);

  static deleteDeputiesShippingRequest = (jsonData) =>
    Api.post(`${url}/api/deleteDeputiesShippingRequest`, jsonData);

  static getShippingRequestDetails = (jsonData) =>
    Api.post(`${url}/api/shippingRequestDetails`, jsonData);
    static shippingUnderReviewRequestDetails = () =>
    Api.get(`${url}/api/shippingUnderReviewRequestDetails`);
  static getDeputyShippingDetails = (jsonData) =>
    Api.post(`${url}/api/deputyShippingDetails`, jsonData);

  static getMaterialShippingDetails = (jsonData) =>
    Api.post(`${url}/api/materialShippingList`, jsonData);

  static updateShipping = (jsonData) =>
    Api.put(`${url}/api/updateShipping`, jsonData);

  static saveDistribution = (distributionData = {}) =>
    Api.post(`${url}/api/saveDistribution`, distributionData);
  static updateDistributionAccess = (distributionData = {}) =>
    Api.post(`${url}/api/updateDistributionAccess`, distributionData);
  static getphase = () => Api.get(`${url}/api/PhaseData`);
  static getTitleData = () => Api.get(`${url}/api/titleData`);
  static saveTitleData = (titleData = {}) =>
    Api.post(`${url}/api/saveTitledata`, titleData);
  static getPhases = () => Api.get(`${url}/api/phases`);
  static savePhase = (phaseData = {}) =>
    Api.post(`${url}/api/savePhase`, phaseData);
  static updatePhaseAccess = (phaseData = {}) =>
    Api.post(`${url}/api/updatePhaseAccess`, phaseData);
  static updateTitleAccess = (titleData = {}) =>
    Api.post(`${url}/api/updateTitleAccess`, titleData);

  static updateMaterial = (jsonData) =>
    Api.put(`${url}/api/updateMaterial`, jsonData);

  static getProcessShippmentList = () =>
    Api.get(`${url}/api/process/shippment/list`);

  static getShippingHistory = (params) =>
    Api.post(`${url}/api/process/shippment/logs`, params);

  static deleteShipping = (jsonData) =>
    Api.post(`${url}/api/deleteShipping`, jsonData);

  static getShippingSearchAutocompleteData = () =>
    Api.get(`${url}/api/process/shippment/search/data`);

  static requestByDeputy = (id) =>
    Api.get(`${url}/api/requestDeutyList?cwid=${id}`);

  static shippingHistory = (jsonData) =>
    Api.post(`${url}/api/shippingHistory`, jsonData);

  static updateShippingRequestValid = (jsonData) =>
    Api.post(`${url}/api/updateShippingRequestValid`, jsonData);

  static getShippingsCountry = (id) =>
    Api.get(`${url}/api/shippingCountry?id=${id}`);
  static getShippingsTwoWeeks = () => Api.get(`${url}/api/shippingTwoWeeks`);
  static getAllUsers = () => Api.get(`${url}/api/regUsers`);
  static getShippingAttachment = (param) => {
    return Api.get(`${url}/api/shipping/attachments/${param.id}`);
  };
  static getShipperDetails = (param) => {
    return Api.get(`${url}/api/shipping/shipper/${param.id}`);
  };
  static getAllCurrency = () => {
    return Api.get(`${url}/api/currency/list`);
  };
  static getAllUnits = () => {
    return Api.get(`${url}/api/unit/list`);
  };
  static getAllContainer = () => {
    return Api.get(`${url}/api/container/list`);
  };
  static getAllContainerList = () => {
    return Api.get(`${url}/api/container/all/list`);
  };
  static getRegulatoryData = () => {
    return Api.get(`${url}/api/regulatorydata`);
  };
  static getReviewerMasterData = () => {
    return Api.get(`${url}/api/reviewerMasterData`);
  };
  static getAllBrokers = () => Api.get(`${url}/api/broker/list`);
  static saveInvoiceData = (jsonData) =>
    Api.post(`${url}/api/invoice/save`, jsonData);
  static getInvoiceData = (jsonData) =>
    Api.get(`${url}/api/invoice/details/` + jsonData);

  static saveReportsAttachments = (attachmentData = {}) =>
    Api.post(`${url}/api/saveReportsAttachments`, attachmentData);

  static getReportsAttachments = (jsonData) => {
    return Api.get(`${url}/api/getReportsAttachments/` + jsonData.id + `/` + jsonData.reportType);
  };

  static deleteReportsAttachments = (jsonData) => {
    return Api.get(`${url}/api/deleteReportsAttachments/` + jsonData.id + `/` + jsonData.reportType);
  };
  static getContainer = () => {
    return Api.get(`${url}/api/container/all/list`);
  };
  static saveContainer = (data = {}) => {
    return Api.post(`${url}/api/container/create`, data);
  };
  static statusUpdateContainer = (data = {}) => {
    return Api.post(`${url}/api/container/statusUpdate`, data);
  };
  static searchByStudyNumber = (studyNumber) =>
    Api.get(`${url}/api/studyNumber/${studyNumber}`);

  static getUnits = () => Api.get(`${url}/api/unit/all`);
  static saveUnitData = (titleData = {}) =>
    Api.post(`${url}/api/saveUnit`, titleData);
  static updateUnitAccess = (titleData = {}) =>
    Api.post(`${url}/api/udateUnit`, titleData);
  static getCurrencyList = () => {
    return Api.get(`${url}/api/currency/all`);
  };
  static saveCurrency = (titleData = {}) => {
    return Api.post(`${url}/api/saveCurrency`, titleData);
  };
  static updateCurrencyAccess = (titleData = {}) => {
    return Api.post(`${url}/api/updateCurrency`, titleData);
  };
  static getBrokerList = () => {
    return Api.get(`${url}/api/broker/all`);
  };
  static saveBroker = (titleData = {}) => {
    return Api.post(`${url}/api/saveBroker`, titleData);
  };
  static updateBrokerAccess = (titleData = {}) => {
    return Api.post(`${url}/api/updateBroker`, titleData);
  };

  static getShippingTypeList = () => {
    return Api.get(`${url}/api/shippingType/all`);
  };
  static saveShippingType = (titleData = {}) => {
    return Api.post(`${url}/api/saveShippingType`, titleData);
  };
  static updateShippingTypeAccess = (titleData = {}) => {
    return Api.post(`${url}/api/updateShippingType`, titleData);
  };
  static getShippingType = () => {
    return Api.get(`${url}/api/shippingType`);
  };

  static getIncotermList = () => {
    return Api.get(`${url}/api/incoterms/all`);
  };
  static saveIncoterm = (titleData = {}) => {
    return Api.post(`${url}/api/saveIncoterms`, titleData);
  };
  static updateIncotermAccess = (titleData = {}) => {
    return Api.post(`${url}/api/updateIncoterms`, titleData);
  };
  static getIncoterm = () => {
    return Api.get(`${url}/api/incoterms`);
  };

  static archiveTag  = (id,archive)  =>{
    return Api.post(`${url}/api/archiveTag`,{alertID:id,alertArchive:archive});  }


    static unarchiveTag  = (id,archive)  =>{
      return Api.post(`${url}/api/unarchiveTag`,{alertID:id,alertArchive:archive});  }

  static getAllImporter = () => {
    return Api.get(`${url}/api/importer/all`);
  };
  static saveImporter = (titleData = {}) => {
    return Api.post(`${url}/api/saveImporter`, titleData);
  };
  static updateImporterAccess = (titleData = {}) => {
    return Api.post(`${url}/api/updateImporter`, titleData);
  };

  static getImpoter = () => {
    return Api.get(`${url}/api/importer`);
  };
  static getReviewerCommentsByShippingRequestId = (param = {}) => {
    return Api.get(`${url}/api/getReviewerComments/${param.shippingRequestId}`);
  };
  static addReviewerComment = (paylod = {}) => {
    return Api.post(`${url}/api/addReviewerComment`, paylod);
  };
  static updateReviewerComment = (paylod = {}) => {
    return Api.post(`${url}/api/updateReviewerComment`, paylod);
  };
  static getRegulatoryReviewer1List = () => {
    return Api.get(`${url}/api/getRegulatoryReviewer1List`);
  };
  static getShippingStatus = (id) => {
    return Api.get(`${url}/api/shippingStatus?id=${id}`);
  };
  static revertApproval = (id, status,userId) => {
    return Api.get(`${url}/api/revertApproval/${id}/${status}/${userId}`);
  }
  static saveAlertDetails = (paylod = {}) =>
    Api.post(`${url}/api/saveAlertDetails`, paylod);

  static getAlertCommentsByShippingRequestID = (param = {}) => {
    return Api.get(`${url}/api/getAlertCommentsByShippingRequestID/${param.shippingRequestId}`);
  };

  static getAlertByuserId = (param = {}) => {
    return Api.get(`${url}/api/getAlertByuserId/${param.userId}/${param.isOpened}`);
  };

  static getAlertNotificationsByuserId = (param = {}) => {
    return Api.get(`${url}/api/getAlertNotificationsByuserId/${param.userId}`);
  };

  static updateAlertCountByUserID = (payload = {}) => {
    return Api.post(`${url}/api/updateAlertCountByUserID`, payload);
  };

  static updateApproveAddressReviewStatus = (payload = {}) => {
    return Api.post(`${url}/api/address/updateReviewStatus`, payload);
  };
  static getAllusers = () => {
    return Api.get(`${url}/api/getAllusers`);
  };

  static getSelectedAddressData = (id) => {
    return Api.get(`${url}/api/getSelectedAddressData/${id}`);
  };

  static getSelectedRecipientData = (id) => {
    return Api.get(`${url}/api/getSelectedRecipientData/${id}`);
  };

  static getUnreadAlertsList = (param = {}) => {
    return Api.get(`${url}/api/getUnreadAlertsListByUserId/${param.userId}`);
  };

  static updateAssignedReviewer = (payload = {}) => {
    return Api.post(`${url}/api/reviewBy`, payload);
  };

  static getLogisticsParterDictionaryData = () => {
    return Api.get(`${url}/api/logisticsPartner/all`);
  };

  static saveLogisticsPartner = (logisticsPartnerData = {}) =>
    Api.post(`${url}/api/saveLogisticsPartner`, logisticsPartnerData);

  static updateLogisticsPartner = (logisticsPartnerData = {}) => {
    return Api.post(`${url}/api/updateLogisticsPartner`, logisticsPartnerData);
  };
}

export default Api;
