import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { InteractionType } from "@azure/msal-browser";
import {
  MsalProvider,
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import Routs from "./Routs";

function App({ pca, currentAccount, accessToken }) {
  const authRequest = {
    scopes: ["User.Read", "openid", "profile"],
  };
  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <AuthenticatedTemplate>
          <Routs accessToken={accessToken} currentAccount={currentAccount} />
        </AuthenticatedTemplate>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
