import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { Link } from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from "react-i18next";
import { Link as BrowserLink } from "react-router-dom";

import FinalRecipientDetails from "./FinalRecipientDetails";
import { useStyles } from "../common/ThemeStye";
import {
  INSPECT,
  SUBMIT,
  HAND_BACK,
  STATUS,
  CANCEL_REQUEST,
  CLOSE,
  USERROLES,
} from "../../constants";
import FinalRecipientCard from "./preview/FinalRecipientCard";
import { HelpDialog } from "../common/Confirmation";
import { Alert } from "@mui/material";
import UserUtil from "../common/utils/UserUtil";
import { wordBreakDownSearch } from "../common/utils/CustomFilter";
import DropDownSearch from "../common/DropDownSearch";
import _ from "lodash";
import { ifRequestCompletedApprovals } from "../../Services/util";

const FinalRecipient = (props) => {
  const {
    finalRecipients,
    finalRecipientSelect,
    shippingRequestDetails,
    shippingActionType,
    redirectAction,
    actions,
  } = props;
  const userType = JSON.parse(localStorage.getItem("userType"));

  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const status =
    shippingRequestDetails.length > 0 && shippingRequestDetails[0].status;

  const isRecipientApproved = shippingRequestDetails.length > 0 && shippingRequestDetails[0].recipient_approved;

  if (
    (shippingActionType === INSPECT &&
      (status === STATUS.PROCESSING || status === STATUS.IN_PROGRESS)) ||
    (shippingActionType === SUBMIT && status === STATUS.INPUT) ||
    shippingActionType === INSPECT ||
    shippingActionType === HAND_BACK ||
    shippingActionType === CANCEL_REQUEST ||
    shippingActionType === CLOSE
  ) {
    return <FinalRecipientCard finalRecipient={finalRecipientSelect} isRecipientApproved={isRecipientApproved} redirectTo={props.redirectTo} />;
  }

  const handleFinalRecipientSelection = (val) => {
    actions.finalRecipientSelect(val || {});
    if (val?.address_id) {
      actions.destinationAddrSelect({
        ...val,
        id: val.address_id,
        tableData: {
          id: val.address_id,
        },
      });
    }
  };

  const openHelp = () => {
    setConfirmationOpen(true);
  };

  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
  };
  const isreceipvalid = (obj) =>{
    if(obj?.receipvalid == null || obj?.title==null|| Object.keys(obj).length == 0) return true
    if(obj?.receipvalid == 1 && obj?.titlevalid) return true
    return false;
  }
  return (
    <>
      <Card variant="outlined" className="pb-0 finalrecipientlink" style={{border:(isreceipvalid(finalRecipientSelect))?"":"3px solid orange"}}>
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t("shipping_request.finalrecipient")}<span className="req">*</span>{" "}
            </Typography>
          }
          className={classes.cardHeader}
          action={
            (userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.APPLICANT) ||
            userType.includes(USERROLES.ADMIN)) &&
            status !== STATUS.IN_DELIVERY
            ? (
              <Link
                href="javascript:void(0)"
                className="link_finalrecipct mt-3"
                title={t("shipping_request.finalrecipient_link_over")}
                onClick={redirectAction}
              >
                {t("shipping_request.finalrecipient_link")}
              </Link>
            ) : (
              <Link to="" className="link_finalrecipct mt-3" style={{ pointerEvents: "none", color: "black" }}>
                {" "}
                {t("shipping_request.finalrecipient_link")}
              </Link>
            )
          }
        />
        <CardContent>
          {
          shippingRequestDetails?.length > 0 &&
          ![
            STATUS.INPUT,
            STATUS.INBOX,
            STATUS.REGULATORY_REVIEWER_1,
            STATUS.REGULATORY_REVIEWER_2,
            ].includes(shippingRequestDetails[0]?.status) &&
          shippingRequestDetails[0]?.recipient_id &&
          !shippingRequestDetails[0]?.recipient_approved &&
          !ifRequestCompletedApprovals(shippingRequestDetails[0]?.status) ? (
            <Alert severity="warning">
              <BrowserLink
                to={{
                  pathname: `/view/approve/recipient/${shippingRequestDetails[0].recipient_id}`,
                  state: { shippingRequest: shippingRequestDetails[0] },
                }}
              >
                {t("recipient.not_approved")}.
              </BrowserLink>
            </Alert>
          ) : null}
          <div className="row col-12 pr-0 mb-2">
            <div className="col-lg-3  col-12 form-outline form-margin">
              <label className="col-form-label">
                {t("shippingrequest.finalrecipient")}
                <span title={t("shipping_request.helpTitle")}>
                  <HelpIcon onClick={openHelp} />
                </span>
              </label>

              <DropDownSearch
                list={_(finalRecipients)
                  .filter((obj) => {return obj.receipvalid == 1})
                  .map((item) => {
                    item.label = new UserUtil(item, true).toString();
                    return item;
                  })
                  .uniqBy("label")
                  .sortBy("label")
                  .value()}
                label=" "
                handleChange={handleFinalRecipientSelection}
                value={
                  finalRecipientSelect?.id
                    ? new UserUtil(finalRecipientSelect, true).toString()
                    : ""
                }
                filterOptions={wordBreakDownSearch("label")}
                disabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  )
                  || status === STATUS.IN_DELIVERY
                }
              />
            </div>
            <HelpDialog
              title={t("shipping_request.helpTitle")}
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("shipping_request.helpText"),
                  }}
                />
              }
              confirmationOpen={confirmationOpen}
              handleCancelConfirmation={handleCancelConfirmation}
            />
          </div>
          <br />
          <FinalRecipientDetails finalRecipient={finalRecipientSelect} />
        </CardContent>
      </Card>
    </>
  );
};

export default FinalRecipient;
