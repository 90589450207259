import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { useTranslation } from "react-i18next";
import '../../Admin/materials/materialData.css'
import { addressSearchStyles } from "../../approvals/Address/addressStyles";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import materialsRedux from "../../../redux/materials";
import { useHistory } from "react-router";

const MaterialSearchComponent = (props) => {
  const { onSearch, state, actions, searchKey } = props;
  const classes = addressSearchStyles();
  const history = useHistory();
  useEffect(() => {
    if (state.searchKey !== searchKey) {
      dispatch({ type: "RESET" });
      localStorage.setItem("selectedMaterialAdminRow", "");
      onSearch({});
    } else {
      onSearch({ ...state });
    }
  }, []);

  history.listen((location, action) => {
    if (!location.pathname.includes("material")) {
      dispatch({ type: "RESET" });
      localStorage.setItem("selectedMaterialAdminRow", "");
    }
  });

  const dispatch = (event) => {
    if (event.type === "RESET") {
      return actions.setSearchData({
        description: "",
        hazard_class: "",
        hazard_phrase: "",
        material_number: "",
        real_material_number: "",
        statistical_material_number: "",
        statistical_material_number_china: "",
        statistical_material_number_cnd: "",
        statistical_material_number_sthfrc: "",
        statistical_material_number_trky: "",
        statistical_material_number_usa: "",
        valid: false,
        submitted: false,
        approved: false,
        startDate: "",
        endDate: "",
        id: "",
        created_by: "",
        searchKey,
      });
    }

    if (event.target.type === "checkbox") {
      return actions.setSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }

    return actions.setSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };

  const [t] = useTranslation();

  return (
    <>
      <div className="container-fluid materialSearchComponentContainer">
        <div className="row">
          <InputTextFields
            id="description"
            name="description"
            placeholder={t("material.materialName")}
            onChange={dispatch}
            state={state}
          />

          {/* <InputTextFields
            id="id"
            name="id"
            placeholder={t("materials.id")}
            onChange={dispatch}
            state={state}
          /> */}

          <DateField
            placeholderText={t("phase.createdatefrom")}
            name="startDate"
            id="startDate"
            onChange={(date) =>
              dispatch({ target: { name: "startDate", value: date } })
            }
            state={state}
          />

          <DateField
            placeholderText={t("phase.createtilldate")}
            name="endDate"
            id="endDate"
            onChange={(date) =>
              dispatch({ target: { name: "endDate", value: date } })
            }
            state={state}
          />
          <div className="col-12 col-sm-3 materials-but">
            <Button
              variant="contained"
             
              className={classes.searchBt + " search_buttontwo"}
              onClick={() => onSearch({ ...state })}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.reloadBt  + " refresh_button"}
              onClick={() => {
                onSearch({});
                dispatch({ type: "RESET" });
              }}
            >
              <ReplayIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state.materials.searchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setSearchData: materialsRedux.actions.setSearchData,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialSearchComponent);

const InputTextFields = (props) => {
  return (
    <div className="col-12 col-sm-2 form_item">
      <TextField
        margin="normal"
        variant="outlined"
        size="small"
        value={props.state[props.name]}
        {...props}
      />
    </div>
  );
};

// const CheckboxField = (props) => {
//   return (
//     <div className="col-12 col-sm-2 form_item">
//       <Checkbox
//         margin="normal"
//         variant="outlined"
//         size="small"
//         checked={props.state[props.name]}
//         {...props}
//       />
//       <label>{props.label}</label>
//     </div>
//   );
// };

const DateField = (props) => {
  registerLocale("de", de);
  const { i18n } = useTranslation();
  return (
    <div className="col-12 col-sm-2 mymaterials">
      <DatePicker
    locale={i18n.resolvedLanguage}
        portalId="root-portal"
        className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box datePicker"
        margin="normal"
        variant="outlined"
        size="small"
        dateFormat="dd-MMM-yyyy"
        popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
        selected={
          props.state[props.name] ? new Date(props.state[props.name]) : ""
        }
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        {...props}
      />
    </div>
  );
};
