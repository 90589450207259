import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from "@material-ui/core/Typography";
import CardContent from '@mui/material/CardContent'

import FinalRecipientDetails from '../FinalRecipientDetails';
import { useStyles } from '../../common/ThemeStye';

import { useTranslation } from 'react-i18next';
import { Alert } from "@mui/material";

const FinalRecipientCard = (props) => {
    const { t } = useTranslation();

    const { 
        finalRecipient,
        isRecipientApproved,
        redirectTo,
    } = props

    const classes = useStyles()

    return(
        <>
        <div className={redirectTo === "/process/shipping" && isRecipientApproved === 0 ? "solid-red-border" : null} style={{border:(finalRecipient?.receipvalid == false || finalRecipient?.titlevalid == false)?"3px solid orange" :""}}>
            <Card variant="outlined">
                <CardHeader 
                    title= {
                        <Typography className='heading_box'>  {t('shipping_request.finalrecipient')}<span className="req">*</span> </Typography>
                    }
                    className={classes.cardHeader}
                    />
                    <CardContent>
                    {redirectTo === "/process/shipping" && isRecipientApproved === 0 ? (
                        <Card variant="outlined">
                                <CardContent className="p-2 notice transparantbg">
                            <Alert severity="warning">
                                {t("recipient.not_approved")}
                            </Alert>
                        </CardContent>
                        </Card>
                        ) : ("")
                    }
                    <br/>
                    <FinalRecipientDetails  finalRecipient = { finalRecipient } />
                </CardContent>
            </Card>
        </div>
        </>
    )
}

export default FinalRecipientCard;