import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Model from "../../shared/model";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import MaterialData from "./MaterialData";
import MaterialViewData from "./MaterilaTableData";
import { Link, useHistory } from "react-router-dom";
import materialService from "../../../Services/materialService";
import { authContext } from "../../../adalConfig";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import materialsRedux from "../../../redux/materials";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import * as config from "../../../config";
import tableListing from "../../../redux/tableListing";
import LoadingOverlay from "react-loading-overlay";
import excelExport from "../../shared/excelExport.js"
import dateFormatter from "../../shared/dateFormatter.js";

const Materials = ({ actions, tableData, loader }) => {
  const [searchFilter, setSearchFilter] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(false);
  const { instance } = useMsal();

  const history = useHistory();
  const userId = instance.getActiveAccount().username;
  const { t, i18n } = useTranslation();
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  const handleMaterialSelected = async (selectedMaterial) => {
    setSelectedMaterial(selectedMaterial);
  };

  const ExportSelectedBtn = () => {
    let excelDataProperty = [
      { "displayName": "Material Name", "dbColumn": "description", "width": 10 },
      { "displayName": "Hazard Class", "dbColumn": "hazard_class", "width": 10 },
      { "displayName": "Hazard Inducers", "dbColumn": "hazard_phrase", "width": 10 },
      { "displayName": "Material Number", "dbColumn": "material_number", "width": 10 },
      { "displayName": "Tariff Classification Number HS-(Germany)", "dbColumn": "statistical_material_number_grmny", "width": 10 },
      { "displayName": "Tariff Classification Number (Foreign Traiff Code)", "dbColumn": "statistical_material_number_china", "width": 10 },
      { "displayName": "Tariff Classification Number (HTSUS)", "dbColumn": "statistical_material_number_usa", "width": 10 },
      { "displayName": "Hazardous according to chapter 14 SDS", "dbColumn": "is_hazardous", "width": 10 },
      { "displayName": "UN/ID", "dbColumn": "un_no", "width": 10 },
      { "displayName": "Class / Subsidiary hazard", "dbColumn": "transport_class", "width": 10 },
      { "displayName": "Packaging Group", "dbColumn": "pack_group", "width": 10 },
      { "displayName": "EID", "dbColumn": "eid", "width": 10 },
      { "displayName": "Contains or Derived from a Biologic", "dbColumn": "contains_der_biologic", "width": 10 },
      { "displayName": "Contains Animal byproduct", "dbColumn": "contains_animal_byproduct", "width": 10 },
      { "displayName": "Contains Non-Domestic Animal byproduct", "dbColumn": "contains_non_domestic_animal_byproduct", "width": 10 },
      { "displayName": " Hazardous according to chapter 2 SDS", "dbColumn": "hazardous_sds", "width": 10 },
      { "displayName": "Contains an Infectious Substance", "dbColumn": "contains_infect_substance", "width": 10 },
      { "displayName": "Contains an Antibiotic", "dbColumn": "contains_antibiotic", "width": 10 },
      { "displayName": "Contains a Pesticide", "dbColumn": "contains_pesticide", "width": 10 },
      { "displayName": "Contains a Parasiticide", "dbColumn": "contains_parasiticide", "width": 10 },
      { "displayName": "Full Description of Material", "dbColumn": "full_description", "width": 10 },
      { "displayName": "FDA Product Code", "dbColumn": "fda_product_code", "width": 10 },
      { "displayName": "FDA Processing Program", "dbColumn": "fda_processing_program", "width": 10 },
      { "displayName": "SPI", "dbColumn": "sli", "width": 10 },
      { "displayName": "Material Attachment Comments", "dbColumn": "comments", "width": 10 },
      { "displayName": "Created By", "dbColumn": "created_by", "width": 50 },
      { "displayName": "Created On", "dbColumn": "created_on", "width": 15 },
      { "displayName": "Updated By", "dbColumn": "updated_by", "width": 50 },
      { "displayName": "Updated On", "dbColumn": "updated_on", "width": 15 },
      { "displayName": "Valid", "dbColumn": "valid", "width": 10 },
    ];

    materialService.getMaterialSelectedData(selectedMaterial.id).then(response => {
      let dataForExport = [];
      dataForExport.push(response[0]);
      dataForExport = dateFormatter.formateDateField(dataForExport);
      excelExport.exportAllData(excelDataProperty, dataForExport, "Material_Data", requiredFileName);
    });
  };
  const ExportBtn = () => {
    return (
      <button
        className="mr-1"
        onClick={() => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };
  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();

    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, t("navbar.material"));
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    //  let filteredData = tableData;

    // if (searchFilter.length === 0) {
    copyOfFilteredData = _.cloneDeep(tableData);
    // }
    // else if (searchFilter.length > 0) {
    //     copyOfFilteredData = _.cloneDeep(tableData)
    // }
    copyOfFilteredData &&
      copyOfFilteredData.forEach((item) => {
        //changing column names
        //   copyOfFilteredData && copyOfFilteredData.forEach(element => {
        if (item.created_on) {
          item.created_on = moment(item.created_on).format("DD-MMM-YYYY");
        }

        // });
        item["Material Name "] = item["description"];
        delete item["description"];

        item["Material Number "] = item["material_number"];
        delete item["material_number"];

        //    item[t("materials.real_sub_no")] = item["real_material_number"];
        delete item["real_material_number"];

        // item[t("materials.tariff_classification_no")] =
        //item["statistical_material_number"];
        delete item["statistical_material_number"];

        item["Hazard Class "] = item["hazard_class"];
        delete item["hazard_class"];

        item["Hazard Inducers "] = item["hazard_phrase"];
        delete item["hazard_phrase"];

        item["Create Date "] = item["created_on"];
        delete item["created_on"];

        item["Create User "] = item["created_by"];

        item["Approved "] = item["approved"];
        item["Valid "] = item["valid"];

        delete item["created_by"];
        delete item["material_type"];
        delete item["is_hazardous"];
        delete item["un_no"];
        delete item["transport_class"];
        delete item["statistical_material_number_india"];
        delete item["statistical_material_number_aust"];
        delete item["pack_group"];
        delete item["id"];
        delete item["approved"];
        delete item["submitted"];
        delete item["valid"];
        delete item["updated_by"];
        delete item["is_active"];
        delete item["updated_on"];
        delete item["statistical_material_number_cnd"];
        delete item["statistical_material_number_china"];
        delete item["statistical_material_number_sthfrc"];
        delete item["statistical_material_number_trky"];
        delete item["statistical_material_number_usa"];
        delete item["contains_der_biologic"];
        delete item["contains_animal_byproduct"];
        delete item["contains_non_domestic_animal_byproduct"];
        delete item["hazardous_sds"];
        delete item["contains_infect_substance"];
        delete item["contains_antibiotic"];
        delete item["contains_pesticide"];
        delete item["contains_parasiticide"];
        delete item["full_description"];
        delete item["fda_product_code"];
        delete item["fda_processing_program"];
        delete item["sli"];
        delete item["statistical_material_number_grmny"];
        delete item["statistical_material_number_moscow"];
        delete item["eid"];
        delete item["Number"];

        delete item["material_number"];
        delete item["real_material_number"];
        delete item["statistical_material_number"];
        delete item["review_by"];

        for (let key in item) {
          if (item[key] === null) {
            item[key] = "";
          }
        }
      });
    return copyOfFilteredData;
  };
  const handleMaterialCopy = async () => {
    try {
      if (selectedMaterial?.id) {
        await materialService.copyMaterial(selectedMaterial.id, { userId });
        toast.success(t("materials.copy_success"));
        await loadMaterialsList();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };

  const loadMaterialsList = async () => {
    try {
      actions.getAllMaterials();
      setSearchFilter({ ...searchFilter });
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };

  const materialApprovalAndValidUpdate = async (data) => {
    try {
      if (selectedMaterial?.id) {
        await materialService.materialApprovalAndValidUpdate(
          selectedMaterial.id,
          { ...data, userId },
        );
        toast.success(t("commonlabels.savedSuccessfullyMsg"));
        await loadMaterialsList();
        setSelectedMaterial({ ...selectedMaterial, ...data });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={!selectedMaterial}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() != "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() != "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <>
      <LoadingOverlay active={loader} spinner>
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" spacing={1}>
              <span className="title_main mt-1">{t("navbar.materials")}</span>
            </Stack>
          </Box>
          <Card variant="outlined">
            <CardHeader title={t("phase.SearchPlaceholder")} />
            <Divider />
            <CardContent>
              <MaterialData
                onSearch={(data) => setSearchFilter(data)}
                searchKey={"admin"}
              />
            </CardContent>
          </Card>

          <div className="row m0 no_padding container-fluid mt-3 mb-1">
            <div className="no_padding col-12 mb-2 d-flex justify-content-end ">
              <div className="buttonsec_animalmanagment">
                <DropdownButton
                  title={t("shippingrequest.export")}
                  className="mr-1 exportdropdown"
                >
                  <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
                  <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
                </DropdownButton>

                <button
                  className="btn btn-primary btn-inner-close new_button mr-1"
                  disabled={!selectedMaterial}
                  onClick={() => {
                    history.push(`/view/material/${selectedMaterial?.id}`, {
                      selectedMaterial,
                    });
                    actions.saveTableData(selectedMaterial);
                  }}
                >
                  {t("materials.inspect")}
                </button>
                <Link to="/new/material">
                  <button className="btn btn-primary btn-inner-close new_button mr-1">
                    {t("materials.new")}
                  </button>
                </Link>
                <button
                  class="btn btn-primary btn-inner-close new_button mr-1"
                  disabled={!selectedMaterial}
                  onClick={handleMaterialCopy}
                >
                  {t("materials.copy")}
                </button>
                <button
                  className="btn btn-inner-approve   new_button mr-1"
                  disabled={!selectedMaterial || selectedMaterial?.approved}
                  onClick={() =>
                    materialApprovalAndValidUpdate({
                      approved: 1,
                    })
                  }
                >
                  {t("materials.approve")}
                </button>
                <button
                  className="btn btn-inner-approve   new_button"
                  disabled={!selectedMaterial}
                  onClick={() =>
                    materialApprovalAndValidUpdate({
                      valid: selectedMaterial?.valid ? 0 : 1,
                    })
                  }
                >
                  {selectedMaterial?.valid
                    ? t("materials.deactivate")
                    : t("materials.activate")}
                </button>
              </div>
            </div>
          </div>
          <Card variant="outlined">
            <CardHeader
              style={cardTheme.cardHeaderStylePref}
              title={t("materials.search_results")}
            />
            <CardContent>
            {!loader &&
              <MaterialViewData
                searchFilter={searchFilter}
                onMaterialSelected={handleMaterialSelected}
              />
            }
            </CardContent>
          </Card>
          <Model
            showheader={isExportModalVisible}
            modalHeader={t("exportLabel.title")}
            modalbody={exportModalBody()}
            modalfooter={exportModalFooter()}
          ></Model>
          <Model
            showheader={isExportModalVisibleSingle}
            modalHeader={t("exportLabel.title")}
            modalbody={exportModalBodySingleData()}
            modalfooter={exportModalFooterSingleData()}
          ></Model>
        </Container>
      </LoadingOverlay>
    </>
  );
};

const { saveTableData } = tableListing.actions;

const mapStateToProps = (state) => ({
  tableData: state.materials.allMaterials,
  loader: state.materials.loader,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllMaterials: materialsRedux.actions.getAllMaterials,
      saveTableData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
