import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import address from "../../../redux/address";
import shipping from "../../../redux/shipping";
import AddressComponent from "./AddressComponent";
import tableListing from "../../../redux/tableListing";
const {
  getAllAddress,
  saveAddress,
  getAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getAllAddressNotApproved,
  getAddressShipping,
  deleteAddress,
  getMyAddress,
  updateApproveAddressReviewStatus,
  getSelectedAddressData,
  setApprovalTableOptions,
  setApprovalAddressSearchData,
} = address.actions;
const {
  getCountries,
  getCities,
  getShippingRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  getShippingActionType,
  clearShippingMaterialSelectedValue,
  addShippingMaterialSelect,
  getShippingAttachment,
  getShipperDetails,
} = shipping.actions;

const {saveTableData} = tableListing.actions;

const mapStateToProps = (state) => ({
  allAddress: state[address.name].allAddress,
  allMyAddress: state[address.name].allMyAddress,
  addressShipping: state[address.name].addressShipping,
  allAddressNotApproved: state[address.name].allAddressNotApproved,
  isLoader: state[address.name].isLoader,
  countries: state[shipping.name].countries,
  cities: state[shipping.name].cities,
  tableAddressAprovalOptions: state[address.name].tableAddressAprovalOptions,
  state: state[address.name].addressApprovalSearchData,

});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setApprovalAddressSearchData,
      setApprovalTableOptions,
      getAllAddress,
      getAddress,
      getCountries,
      getCities,
      saveAddress,
      editAddress,
      updateAddressAccess,
      updateAddressApproval,
      getAddressRecipient,
      getAllAddressNotApproved,
      getAddressShipping,
      deleteAddress,
      getMyAddress,
      getShippingRequestDetails,
      getDeputyShippingDetails,
      getMaterialShippingDetails,
      getShippingActionType,
      clearShippingMaterialSelectedValue,
      addShippingMaterialSelect,
      getShippingAttachment,
      getShipperDetails,
      saveTableData,
      updateApproveAddressReviewStatus,
      getSelectedAddressData,
    },
    dispatch,
  ),
});

export default {
  AddressComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddressComponent)
};
