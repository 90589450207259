import React, { useReducer } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Select from "../../common/CustomSelect";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "../../Admin/Address/addressStyles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import AutocompleteText from "../../common/AutocompleteText";
import DropDownSearch from "../../common/DropDownSearch";
import _, { throttle } from "lodash";
import { useCallback } from "react";
import { STATUS } from "../../../constants";

const DateField = (props) => {
  registerLocale("de", de);
  const { i18n } = useTranslation();
  return (
    <Grid item xs={12}>
      <DatePicker
     locale={i18n.resolvedLanguage}
        portalId="root-portal"
        className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box datePicker"
        margin="normal"
        variant="outlined"
        size="small"
        dateFormat="dd-MMM-yyyy"
        popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
        selected={
          props.state[props.name]
        }
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        {...props}
      />
    </Grid>
  );
};

const TwoWeeksSearch = ({ onSearch, countries }) => {
  const classes = addressSearchStyles();
  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return {
        tracking_number: "",
        shipment_date_mon: "",
        date_of_recipient: "",
        country_id: "",
        updated_by: "",
        final_receipt: "",
        status: "",
      };
    }

    if (event.target.type === "checkbox") {
      return { ...state, [event.target.name]: event.target.checked };
    }
    return { ...state, [event.target.name]: event.target.value };
  };
  const [state, dispatch] = useReducer(reducer, {
    tracking_number: "",
    shipment_date_mon: "",
    final_receipt: "",
    date_of_recipient: "",
    country_id: "",
    updated_by: "",
    status: "",
  });

  const [t] = useTranslation();

  const handleStatusChange = useCallback(
    throttle(
      (data) => {
        const lang1 = t("status", { returnObjects: true });
        let value;
        Object.values(lang1).forEach((item, index) => {
          if (item === data.target.value) {
            value = Object.keys(lang1)[index];
          }
        });

        if (!value) {
          value = "";
        }
        data.target.value = value;

        dispatch(data);
      },
      1000,
      { trailing: false },
    ),
    [dispatch],
  );

  return (
    <>
     <label className='title_main mt-1' 
                     >
                        {t("quries.querytitleTab2")}
                </label>    
      <Card>
      
        <Divider />
        <CardContent className="tablebg_box">
          <div className="row">
            <div className="col-11 pr-0">
              <div className="row col-12">
                <div className="col-lg-4  pr-0 mt-2">
                  <TextField
                    id="tracking_number"
                    name="tracking_number"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("shipping_request.tracking_number")}
                    onChange={dispatch}
                    value={state["tracking_number"]}
                  />
                </div>
                <div className="col-lg-4 col-sm-12 pr-0 mt-2 date_addreserch">
                  <DateField
                    placeholderText={t("shipping_request.dispatch_date_mon")}
                    name="shipment_date_mon"
                    id="shipment_date_mon"
                    onChange={(date) =>
                      dispatch({
                        target: { name: "shipment_date_mon", value: date },
                      })
                    }
                    state={state}
                  />
                </div>
                <div className="col-lg-4 col-sm-12 pr-0 mt-2 date_addreserch">
                  <DateField
                    placeholderText={t("shipping_request.date_of_receipt")}
                    name="date_of_recipient"
                    id="date_of_recipient"
                    onChange={(date) =>
                      dispatch({
                        target: { name: "date_of_recipient", value: date },
                      })
                    }
                    state={state}
                  />
                </div>
              </div>
              <div className="row col-12">
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  {/* <Select
                    list={countries.sort((a, b) => {
                      a = a.name_english.toLowerCase();
                      b = b.name_english.toLowerCase();
                      if (a === b) return 0;
                      return a < b ? -1 : 1;
                    })}
                    keyname={"name_english"}
                    labelName={t("shippingrequest.addreesedcountry")}
                    value={state.country_id}
                    onChange={dispatch}
                    name="country_id"
                    style={{ width: "100%" }}
                  /> */}
                  <DropDownSearch
                    id="country_id"
                    name="country_id"
                    value={
                      countries.find(
                        (countryItem) => countryItem.id === state["country_id"]
                      )?.name || ""
                    }
                    list={[
                      ...(countries || []).map((country) => ({
                        label: country.name,
                        id: country.id,
                      })),
                    ]}
                    handleChange={(e) => {
                      dispatch({
                        target: { name: "country_id", value: e?.id },
                      });
                    }}
                    label={t("shippingrequest.addreesedcountry")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="final_receipt"
                    name="final_receipt"
                    onChange={dispatch}
                    value={state["final_receipt"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("shippingrequest.finalrecipient")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="updated_by"
                    name="updated_by"
                    onChange={dispatch}
                    value={state["updated_by"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("process_shipping_request.edit_user")}
                  />
                </div>
                <div className="col-lg-3  pr-0 mt-2 shippindata">
                  <AutocompleteText
                    id="status"
                    name="status"
                    value={
                      state["status"] ? t("status." + state["status"]) : ""
                    }
                    label={t("shippingrequest.status")}
                    onChange={handleStatusChange}
                    options={Object.values(STATUS)
                      .map((status) => t("status." + status))
                      .sort((a, b) => a.localeCompare(b))}
                  />
                </div>
                {/* <div className="col-lg-3  pr-0 mt-2 shippindata">
                  <AutocompleteText
                    id="status"
                    name="status"
                    
                    label={t("shippingrequest.status")}
                   
                    options={[
                      t("status." + "CANCELLED"),
                      t("status." + "IN_DELIVERY"),
                      t("status." + "INPUT"),
                      t("status." + "INBOX"),
                      t("status." + "READY_FOR_DELIVERY"),
                      t("status." + "Regulatory Reviewer 1"),
                      t("status." + "Regulatory Reviewer 2"),
                      t("status." + "AWAITING_FOR_APPROVAL"),
                    ]}
                  
                    value={state.status}
                   
                    onChange={dispatch}
                    style={{ width: "100%" }}
                  />
                </div> */}

                {/* <div className="col-lg-3 pr-0 mt-1">
                  <Select
                    list={[
                      { id: "INPUT", name: "INPUT" },
                      { id: "NEW", name: "NEW" },
                      { id: "INBOX", name: "INBOX" },
                      { id: "IN_PROGRESS", name: "IN PROGRESS" },
                      { id: "PROCESSING", name: "PROCESSING" },
                      { id: "READY_FOR_PICKUP", name: "READY FOR PICKUP" },
                      { id: "READY_FOR_DELIVERY", name: "READY FOR DELIVERY" },
                      { id: "DELIVERY", name: "DELIVERY" },
                      { id: "CANCELLED", name: "CANCELLED" },
                      { id: "CLOSE", name: "CLOSE" },
                    ]}
                    labelName={t("address.Status")}
                    value={state.status}
                    name="status"
                    onChange={dispatch}
                    style={{ width: "100%" }}
                  /> */}
               {/* // </div> */}
              </div>
            </div>

            <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Divider />
    </>
  );
};

export default TwoWeeksSearch;
