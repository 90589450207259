
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AlertConfirmation( { 
    alertConfirmation, 
    handleCloseAlertConfirmation,
    handleShippingRequestSaveAndClose,
    handleCancleAlertConfirmation,
    title = 'Title',
    message = 'Message',
 }) {

  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={alertConfirmation}
        onClose={handleCloseAlertConfirmation}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          { title }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShippingRequestSaveAndClose}>{t('commonlabels.yesButton')}</Button>
          <Button autoFocus onClick={handleCloseAlertConfirmation}>
          {t('commonlabels.noButton')}
          </Button>
          <Button autoFocus onClick={handleCancleAlertConfirmation}>
          {t('commonlabels.cancelbutton')}
          </Button>  
        </DialogActions>
      
      </Dialog>
    </div>
  );
}
