import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// import shipping from '../../../redux/shipping';
import shipping from '../../redux/shipping'
import DistributionComponent from './DistributionTemperature'


const { 
    getDistributionTemperature,
    saveDistribution,
    updateDistributionAccess,
} = shipping.actions
const {  isLoader } = shipping.actions;


const mapStateToProps = (state) => ({
    distributionTemperature: state[shipping.name].distributionTemperature,
})


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getDistributionTemperature,
            saveDistribution,   
            updateDistributionAccess,

        },
        dispatch
    )
})

export default connect(
mapStateToProps,
mapDispatchToProps
)(DistributionComponent)