import types from "./types";
import fetchData from "../util";


const getTitleData = () => {
  return fetchData(types.GET_TITLE, "getTitleData");
};
const saveTitleData = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveTitleData", params);
};
const updateTitleAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateTitleAccess", params);
};

const isLoader = (payload = {}) => {
  return {
    type: types.IS_LOADER,
    payload,
  };
};
const getUnits = () => {
  return fetchData(types.GET_UNITS, "getUnits");
};
const saveUnitData = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveUnitData", params);
};
const updateUnitAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateUnitAccess", params);
};

const getAllCountries = ()=>{

    return fetchData(types.GET_ALL_COUNTRIES,"getAllCountries")
}
const saveCountry = (...params)=>{
  return fetchData(types.SAVE_COUNTRY,"saveCountry",params)
}
const updateCountryStatus = (...params)=>{
    return fetchData(types.UPDATE_COUNTRY,"updateCountry",params)

}


const getCurrencyList = () => {
  return fetchData(types.GET_CURRENCY, "getCurrencyList");
};
const saveCurrency = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveCurrency", params);
};
const updateCurrencyAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateCurrencyAccess", params);
};

const getBrokerList = () => {
  return fetchData(types.GET_BROKER, "getBrokerList");
};
const saveBroker = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveBroker", params);
};
const updateBrokerAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateBrokerAccess", params);
};
const getShippingTypeList = () => {
  return fetchData(types.GET_SHIPPING_TYPE, "getShippingTypeList");
};
const saveShippingType = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveShippingType", params);
};
const updateShippingTypeAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateShippingTypeAccess", params);
};

const getIncotermList = () => {
  return fetchData(types.GET_INCOTERMS, "getIncotermList");
};
const saveIncoterm = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveIncoterm", params);
};
const updateIncotermAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateIncotermAccess", params);
};

const getImpoterList = () => {
  return fetchData(types.GET_IMPORTER_LIST, "getAllImporter");
};
const saveImpoter = (...params) => {
  return fetchData(types.SAVE_TITLE, "saveImporter", params);
};
const updateImpoterAccess = (...params) => {
  return fetchData(types.SAVE_TITLE, "updateImporterAccess", params);
};
export default {
  getTitleData,
  saveTitleData,
  updateTitleAccess,
  isLoader,
  getUnits,
  saveUnitData,
  updateUnitAccess,
  getCurrencyList,
  saveCurrency,
  updateCurrencyAccess,
  getBrokerList,
  saveBroker,
  updateBrokerAccess,
  getShippingTypeList,
  saveShippingType,
  updateShippingTypeAccess,
  getIncotermList,
  saveIncoterm,
  updateIncotermAccess,
  getImpoterList,
  saveImpoter,
  updateImpoterAccess,
  getAllCountries,
  updateCountryStatus,
  saveCountry
};
