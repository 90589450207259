import types from "./types";
import fetchData from "../util";

const getphase = () => {
  return fetchData(types.GET_ALL_PHASE, "getphase");
};
const savePhase = (...params) => {
  return fetchData(types.SAVE_PHASE, "savePhase", params);
};

const updatePhaseAccess = (...params) => {
  return fetchData(types.SAVE_PHASE, "updatePhaseAccess", params);
};
export default {
  getphase,
  savePhase,
  updatePhaseAccess
};
