import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from '@mui/material/CardContent';

import { useTranslation } from 'react-i18next';

import CustomizedTables from '../../common/CustomizedTables';


const styles = {
    header: {
      width: "100%",
      height: 30,
      backgroundColor: '#dfdfdf',
    },
    Addbtn: {
        float: 'right',
    },
    addBtnPlus: {
        textAlign: 'right',
        padding: '0px',
        marginTop: '-16px',
        postion: 'absolute'
    }
  };

const DeputiesComponentCard = ({
    classes, 
    shippingDeputySelect,
    deputyShippingDetails
}) => {

    const { t } = useTranslation();
    const isreviewervalid = (obj) =>{
        if(obj?.is_active == null || Object.keys(obj).length == 0) return true
          if(obj?.is_active == true){
            return true
          }
          else{
            return false;
          }
      }

    return(
        <Card variant="outlined" style={{border:(isreviewervalid(deputyShippingDetails[0]))? "":"3px solid orange"}}>
            <CardHeader 
                title= {
                    <Typography className='heading_box'>  {t('shipping_request.deputies')}<span className="req">*</span> </Typography>
                }
                className={classes.header}
                />
                <CardContent>
                    <CustomizedTables list = {shippingDeputySelect}/>
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(DeputiesComponentCard);