import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import { INVOICE_TYPES } from "../../constants";
import { InvoiceItemSize12, InvoiceItemSize4 } from "./Common";
import { If, Then, Else } from "react-if";

import "./invoice.css";
import InvoiceMaterialEditTable from "./InvoiceMaterialEditTable";
import { TextareaAutosize } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "../../logo_blue.svg";

export default function InvoiceUSA({
  materialShippingDetails,
  editMode,
  onChange,
  invoiceGeneralData,
}) {
  const { t } = useTranslation();
  let totalValue = materialShippingDetails?.reduce((a, item) => {
    const price = Number.isSafeInteger(Number(item.cost))
      ? Number(item.cost)
      : 0;
    return Number(a) + price;
  }, 0);

  totalValue = totalValue + Number(invoiceGeneralData.shipping_cost);
  
  return (
    <div className="invoice_body container-fluid">
      <div className="row">
        <div className="col-12 image-container">
          <img
            className="invoicelogo"
            alt="Elanco Global R&D Study Material Logistics Hub"
            src={logo}
          />
        </div>
      </div>
      <div className="row">
        <InvoiceItemSize4 title={t("invoice.invoiceShipperAddress")}>
          <TextareaAutosize
                name="shipper_details"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.shipper_details}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize4>
        <InvoiceItemSize4 title={t("invoice.invoiceShipmentDetails")}>
              <TextareaAutosize
                name="shipment_details"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.shipment_details}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize4>
      </div>
      <div className="row">
        <InvoiceItemSize4 title={t("invoice.reciver")}>
           <TextareaAutosize
                name="recipient_details"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.recipient_details}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize4>
        <InvoiceItemSize4 title={t("invoice.Broker")}>
              <TextareaAutosize
                name="broker_information"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.broker_information}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize4>
      </div>
      <div className="row">
        <InvoiceItemSize4 title={t("invoice.importer_of_record")}>
          <TextareaAutosize
                name="importer_of_record"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.importer_of_record}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize4>
        <InvoiceItemSize4 title={""}> </InvoiceItemSize4>
      </div>
      <div className="row">
        <InvoiceItemSize12 title={t("invoice.SPECIAL_INSTRUCTIONS")}>         
              <TextareaAutosize
                name="special_instructions"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.special_instructions}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize12>
      </div>
      <div className="row">
        <InvoiceItemSize12 title={t("invoice.material")}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="customized table" className="override-mui-letter-spacing">
              <TableHead>
                <TableRow>
                  <TableCell className="columnnetquantity">
                    {t("invoice.NET_QUANTITY")}
                  </TableCell>
                  <TableCell>
                    {t("invoice.ITEM DESCRIPTION AND ELANCO ITEM CODE")}{" "}
                  </TableCell>
                  <TableCell className="columnnetcoo">
                    {t("invoice.COO")}
                  </TableCell>
                  <TableCell className="columnnetvalue">
                    {t("invoice.CUSTOMS VALUE")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <If condition={editMode}>
                  <Then>
                    <InvoiceMaterialEditTable
                      materialShippingDetails={materialShippingDetails}
                      onChange={onChange}
                      invoiceType={INVOICE_TYPES.USA}
                    />
                    <TableRow key={"shipping_cost"}>
                      <TableCell colSpan={3}>                        
                        <TextField
                          name="shippingcost_text"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          className="total_value pull-right"
                          onChange={onChange}
                          value={invoiceGeneralData.shippingcost_text}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="shipping_cost"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          onChange={onChange}
                          value={invoiceGeneralData.shipping_cost}
                        />
                      </TableCell>
                    </TableRow>
                  </Then>
                  <Else>
                    {materialShippingDetails?.map((row) => (
                      <TableRow key={row.material_id}>
                        <TableCell className="columnnetquantity">
                          {row.quantity}
                        </TableCell>
                        <TableCell>
                          <TextareaAutosize
                            variant="plain"
                            className="descriptiontextarea"
                            disabled
                          >
                            {row.description}
                          </TextareaAutosize>{" "}
                        </TableCell>
                        <TableCell className="columnnetcoo">
                          {row.country_of_origin}
                        </TableCell>
                        <TableCell className="columnnetvalue">
                          {row.cost}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={"shipping_cost"}>
                      <TableCell colSpan={3}>
                        <span className="total_value pull-right">
                          {invoiceGeneralData?.shippingcost_text}
                        </span>
                      </TableCell>
                      <TableCell>{invoiceGeneralData.shipping_cost}</TableCell>
                    </TableRow>
                    <TableRow key={"total"}>
                      <TableCell colSpan={3}>
                        <span className="total_value pull-right">
                          {t("invoice.totalValue")} (
                          {invoiceGeneralData.currency_code})
                        </span>
                      </TableCell>

                      <TableCell>{totalValue}</TableCell>
                    </TableRow>
                  </Else>
                </If>
              </TableBody>
            </Table>
          </TableContainer>
        </InvoiceItemSize12>
        <InvoiceItemSize12 title={t("process_shipping_request.comment")}>
          <TextareaAutosize
            aria-label="minimum height"
            name="reviewer_comment"
            minRows={3}
            className="borderOutlineedit"
            style={{ width: "100%" }}
            value={invoiceGeneralData.reviewer_comment}
            onChange={onChange}
          />
        </InvoiceItemSize12>
        <InvoiceItemSize12>
          <TextareaAutosize
                name="footer_information"
                margin="normal"
                variant="plain"
                className={editMode? "borderOutlineedit": "shippingDetailstextarea"}
                style={{ width: "100%" }}
                value={invoiceGeneralData.footer_information}
                onChange={onChange}
                disabled={editMode? false: true}
              />
        </InvoiceItemSize12>
      </div>
    </div>
  );
}
