import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutoCompleteReadonlyText(props) {
  const {
    label,
    onChange,
    name,
    is_disabled = false,
    popupIcon = true,
    value,
    placeholderText,
    is_ReadOnlyText = true
  } = props;
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
        <Autocomplete
            id="read-only-auto-complete"
            disableClearable = {is_disabled}
            disabled = {is_disabled}
            forcePopupIcon = {popupIcon}
            freeSolo
            value = {value}
            getOptionLabel = {(option) => option}
            filterOptions = {(options, state) => options}
            renderInput = {({ inputProps, ...props }) =>    
                <TextField {...props} 
                    placeholder = {placeholderText}
                    label = {label} 
                    inputProps = {{ ...inputProps, readOnly: {is_ReadOnlyText} }}
                />
            }
            {...props}
            onInputChange={(event, value) => {
            if (onChange) {
                onChange({ target: { name, value } });
            }
            }}
            onChange={() => {}}
        />
    </Stack>
  );
}
