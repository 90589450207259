import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { useTranslation } from "react-i18next";
import { useReducer } from "react";
import "./materialData.css";
import { addressSearchStyles } from "../Address/addressStyles";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import materialsRedux from "../../../redux/materials";
import { useHistory } from "react-router";

const MaterialData = (props) => {
  const { onSearch, state, actions, searchKey } = props;
  const classes = addressSearchStyles();
  const history = useHistory();

  useEffect(() => {
    if (state.searchKey !== searchKey) {
      dispatch({ type: "RESET" });
      localStorage.setItem("selectedMaterialAdminRow", "");
      onSearch({});
    } else {
      onSearch({ ...state });
    }
  }, []);

  history.listen((location, action) => {
    if (!location.pathname.includes("material")) {
      dispatch({ type: "RESET" });
      localStorage.setItem("selectedMaterialAdminRow", "");
    }
  });

  const dispatch = (event) => {
    if (event.type === "RESET") {
      return actions.setSearchData({
        description: "",
        hazard_class: "",
        hazard_phrase: "",
        material_number: "",
        real_material_number: "",
        statistical_material_number: "",
        statistical_material_number_china: "",
        statistical_material_number_cnd: "",
        statistical_material_number_sthfrc: "",
        statistical_material_number_trky: "",
        statistical_material_number_usa: "",
        valid: false,
        submitted: false,
        approved: false,
        startDate: "",
        endDate: "",
        id: "",
        created_by: "",
        searchKey,
        review_by: false,
      });
    }

    if (event.target.type === "checkbox") {
      return actions.setSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }

    return actions.setSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };

  const [t, i8ln] = useTranslation();

  return (
    <div className="tablebg_box">
      <div className="row">
        <div className="col-11 pr-0">
          <div className="row col-12">
            <div className="col-lg-3  pr-0 mt-2">
              <InputTextFields
                id="description"
                name="description"
                placeholder={t("material.materialName")}
                onChange={dispatch}
                state={state}
              />
            </div>
            <div className="col-lg-3  pr-0 mt-2">
              <InputTextFields
                id="hazard_phrase"
                name="hazard_phrase"
                placeholder={t("materials.hazard_inducers")}
                onChange={dispatch}
                state={state}
              />
            </div>
            <div className="col-lg-3  pr-0 mt-2">
              <InputTextFields
                id="hazard_class"
                name="hazard_class"
                placeholder={t("materials.hazard_class")}
                onChange={dispatch}
                state={state}
              />
            </div>
            <div className="col-lg-3  pr-0 mt-2">
              <InputTextFields
                id="material_number"
                name="material_number"
                placeholder={t("materials.material_number")}
                onChange={dispatch}
                state={state}
              />
            </div>
          </div>
          <div className="row col-12">
            {/* <div className="col-lg-3  pr-0 mt-2">
              <InputTextFields
                id="statistical_material_number"
                name="statistical_material_number"
                placeholder={t("materials.tariff_classification_no")}
                onChange={dispatch}
                state={state}
              />
            </div> */}
            <div className="col-lg-3  pr-0 mt-2">
              <InputTextFields
                id="created_by"
                name="created_by"
                placeholder={t("materials.create_user")}
                onChange={dispatch}
                state={state}
              />
            </div>
            <div className="col-lg-3 col-sm-12 pr-0 mt-1 approveaddcheckbox">
              <div className="row">
                <div className="col-lg-4 pr-0 ">
                  <CheckboxField
                    id="submitted"
                    name="submitted"
                    label={t("materials.submitted")}
                    onChange={dispatch}
                    state={state}
                  />
                </div>
                <div className="col-lg-4 pr-0 ">
                  <CheckboxField
                    id="approved"
                    name="approved"
                    label={t("materials.approved")}
                    onChange={dispatch}
                    state={state}
                  />
                </div>
                <div className="col-lg-4 pr-0 ">
                  <CheckboxField
                    id="valid"
                    name="valid"
                    label={t("materials.valid")}
                    onChange={dispatch}
                    state={state}
                  />
                </div>
              </div>
              {searchKey === "approve" && (
                <div className="row">
                  <div className="col-lg-6 pr-0 ">
                    <CheckboxField
                      id="rveiew_by"
                      name="review_by"
                      label={t("material.review")}
                      onChange={dispatch}
                      state={state}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-3  pr-0 mt-2 ">
              <DateField
                placeholderText={t("phase.createdatefrom")}
                name="startDate"
                id="startDate"
                onChange={(date) =>
                  dispatch({ target: { name: "startDate", value: date } })
                }
                state={state}
              />
            </div>
            <div className="col-lg-3  pr-0 mt-2">
              <DateField
                placeholderText={t("phase.createtilldate")}
                name="endDate"
                id="endDate"
                onChange={(date) =>
                  dispatch({ target: { name: "endDate", value: date } })
                }
                state={state}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
          <Button
            variant="contained"
            className={classes.searchBt + " search_buttontwo"}
            onClick={() => onSearch({ ...state })}
          >
            <SearchIcon />
          </Button>
          <Button
            variant="contained"
            className={classes.reloadBt + " refresh_button"}
            onClick={() => {
              onSearch({});
              dispatch({ type: "RESET" });
            }}
          >
            <ReplayIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state.materials.searchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setSearchData: materialsRedux.actions.setSearchData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialData);

const InputTextFields = (props) => {
  return (
    <div className="">
      <TextField
        margin="normal"
        variant="outlined"
        size="small"
        value={props.state[props.name]}
        {...props}
      />
    </div>
  );
};

const CheckboxField = (props) => {
  return (
    <div className="">
      <Checkbox
        margin="normal"
        variant="outlined"
        size="small"
        checked={props.state[props.name]?props.state[props.name]:false}
        {...props}
      />
      <span>{props.label}</span>
    </div>
  );
};

const DateField = (props) => {
  const { t, i18n } = useTranslation();
  registerLocale("de", de);
  return (
    <DatePicker
      locale={i18n.resolvedLanguage}
      portalId="root-portal"
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 mt-0 inputtext_box datePicker"
      margin="normal"
      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
      selected={
        props.state[props.name] ? new Date(props.state[props.name]) : ""
      }
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />
  );
};
