import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Autocomplete, Stack, Chip, ListItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import { CheckBox } from "@material-ui/icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, status, theme) {
  return {
    fontWeight:
      status.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
        display:"block",
        paddingTop:"2px",
        paddingBottom:"6px",
        paddingLeft:"15px",
  };
}

 export default function MultipleSelect(props) {
const { options, label, onChange, name,value,propFrom} = props;
const theme = useTheme();
const [multiselect, setMultiselect] = useState([]);

const MultiselecthandleChange = (event) => {
const statusset=event.target.value;
setMultiselect(statusset)
onChange(event)
};

useEffect(()=>{
 if(!value){
setMultiselect([])
 }
},[value])

useEffect(()=>{
  if(propFrom === "admin_material"){
 setMultiselect(value)
  }
 },[value])

return (
  <div>
    <FormControl sx={{ m: 1, width: "100%" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        labelId="demo-multiple-name-label border-box"
        id="demo-multiple-name"
        name={name}
        multiple
        value={multiselect}
        onChange={MultiselecthandleChange}
        input={<OutlinedInput label="multiselect" />}
        renderValue={(selected)=>selected.join(',')}
        isMulti={true}
        MenuProps={MenuProps}
      >
        {options.map((a) => (
          <MenuItem
            key={a.value}
            value={a}
            style={getStyles(a, multiselect, theme)}
           
          >
            {a}
            
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);
} 
