import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CANCEL_REQUEST, CLOSE, HAND_BACK } from '../../../constants';
import { useTranslation } from 'react-i18next';

const ContactInfoCard = (props) => {
    const { t } = useTranslation();

    const {
        // shippingRequestDetails: [{
        //     created_by,
        //     created_on,
        //     updated_by,
        //     updated_on,
        // }],
        shippingActionType = ''
    } = props

  //  const classes = useStyles()

    // const getUserCreatedDetails = () => {
    //     return(
    //         <>
    //         <div className="row">
    //             <div className="col-12" >
    //                 <Card variant="outlined">
    //                     <CardContent>
    //                         <div className='row'>
    //                             <div className="col-12"> 
    //                                 <span> Create User: { created_by } </span>
    //                                 <span>Create Date:{  moment(created_on).format("DD/MM/YYY") }</span>
    //                                 <span> Edit User: { updated_by }</span>
    //                                 <span>Edit Date:{ moment(updated_on).format("DD/MM/YYY") }</span>
    //                             </div>
    //                         </div> 
    //                     </CardContent>    
    //                 </Card>
    //             </div>
    //         </div>
    //         </>
    //     )
    // }

    if (shippingActionType === HAND_BACK) {
        return (
            <>
                <div className="row">
                    <div className="col-12" >
                        <Card variant="outlined">
                            <CardContent>
                                <div className='row'>
                                    <div className="col-12">
                                        <div>
                                            {t('shipping_request.handBack_notice1')}
                                        </div>
                                        <div>
                                            <b>  {t('shipping_request.handBack_notice2')}</b>
                                        </div>
                                        <div>
                                            {t('shipping_request.handBack_notice3')}
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>

                {/* { getUserCreatedDetails()} */}

            </>
        )
    }

    if (shippingActionType === CANCEL_REQUEST) {
        return (
            <>
                <div className="row">
                    <div className="col-12" >
                        <Card variant="outlined"> 
                        {/* removed the innert content as per the US: 4767,4588 */}
                        </Card>
                    </div>
                </div>

                {/* { getUserCreatedDetails()} */}

            </>
        )
    }

    if (shippingActionType === CLOSE) {
        return (
            <>
                <div className="row">
                    <div className="col-12" >
                        <Card variant="outlined">
                            <CardContent>
                                <div className='row'>
                                    <div className="col-12">
                                        <div>
                                            {t('shipping_request.notice4')}
                                        </div>
                                        {/* <div>
                                            {t('shipping_request.notice5')}
                                        </div> */}
                                        <div>
                                            {t('shipping_request.notice6')}                                    </div>
                                        <div>
                                            {t('shipping_request.notice7')}                                    </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>

                {/* { getUserCreatedDetails()} */}

            </>
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-8" >
                    <Card variant="outlined">
                        <CardContent>
                            <div className='row'>
                                <div className="col-12">

                                    <div>
                                        {t('shipping_request.notice8')}</div>
                                    <div>
                                        <b>{t('shipping_request.notice9')}</b>
                                    </div>
                                    <div>
                                        {t('shipping_request.notice10')}</div>
                                    <div>
                                        {t('shipping_request.notice11')}</div>

                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* { getUserCreatedDetails()} */}

        </>
    )
}

export default ContactInfoCard;