import { PublicClientApplication } from "@azure/msal-browser";
import * as config from "./config";

// export const msalConfig = {
//   auth: {
//     tenant: "bdcfaa46-3f69-4dfd-b3f7-c582bdfbb820",
//     clientId: "052c1e9f-9fe6-4d1c-bf45-f19d692eff14",
//     authority: `https://login.microsoftonline.com/bdcfaa46-3f69-4dfd-b3f7-c582bdfbb820`,
//     redirectUri: window.location.origin,
//     endpoints: {
//       api: "052c1e9f-9fe6-4d1c-bf45-f19d692eff14",
//     },
//     origin: window.location.origin,
//     postLogoutRedirectUri: window.location.origin,
//   },
//   cache: {
//     cacheLocation: "localStorage", // "sessionStorage"
//   },
// };

export const msalConfig = {
  auth: {
    tenant: config.tenant,
    clientId: config.clientId,
    authority: `https://login.microsoftonline.com/${config.tenant}`,
    redirectUri: window.location.origin,
    endpoints: {
      api: config.clientId,
    },
    origin: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // "sessionStorage"
  },
};

export const loginRequest = {
  scopes: ["User.Read", "openid", "profile"],
};
export const authContext = new PublicClientApplication(msalConfig);
