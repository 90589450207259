import _ from "lodash";
import React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import RadioInput from "../common/RadioInput";
import BasicTabs from "../common/TabMenu";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./invoice.css";
import InvoiceUSA from "./InvoiceUSA";
import { useRef } from "react";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { INVOICE_TYPES, STATUS } from "../../constants";
import UserUtil from "../common/utils/UserUtil";
import AddressUtil from "../common/utils/AddressUtil";
import { useMsal } from "@azure/msal-react";
import { INVOICE_FOOTER_INFO } from "../../constants";

export default function InvoiceComponent(props) {
  const {
    shippingRequestDetails,
    materialShippingDetails,
    shipper,
    allCountry,
    actions,
    extra,
    invoiceData,
    allRecipients,
    allUnits,
    brokers,
    incoterms,
    impoter,
    allContainer,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conttype, setContType] = useState("");
  const { instance } = useMsal();

  const [
    processedMaterialShippingDetails,
    setProcessedMaterialShippingDetails,
  ] = useState([]);

  const [invoiceGeneralData, setInvoiceGeneralData] = useState({
    invoice_type: INVOICE_TYPES.USA,
  });

  const showWarningMessage = materialShippingDetails?.find(
    (item) => item.is_hazardous
  );

  const currentShipmentRequestData = _.get(shippingRequestDetails, "[0]");
  const printRef = useRef(null);

  const isEditable =
    currentShipmentRequestData &&
    [STATUS.INBOX].includes(currentShipmentRequestData?.status);

  const { t } = useTranslation();

  useEffect(() => {
    actions.getAllCountry();
    actions.getIncoterm();
    actions.getImpoter();
    actions.getAllBrokers();
    actions.getAllContainer();
  }, []);

  useEffect(() => {
    const shippingRequestId = _.get(shippingRequestDetails, "[0].id", false);
    if (shippingRequestId) {
      actions.getInvoiceData(shippingRequestId);
    }
  }, [shippingRequestDetails]);

  const getContainer = (id) => {
    if (id) {
      for (let cont of allContainer) {
        if (cont.id === id) {
          setContType(cont.container_type);
        }
      }
    }
  };

  const getNetQuantity = (item) => {
    getContainer(item.container_type);
    return `${item.unit} ${item.unit_name ? item.unit_name : ""} X ${
      item.quantity
    } ${conttype} `;
  };
  const currencyCode = materialShippingDetails[0]?.currency_code;
  useEffect(() => {
    //To load Data for Invoice based on the Invoice id.
    if (invoiceData?.id) {
      //Enters this section when record is present in the shipping_request_invoice table.
      setProcessedMaterialShippingDetails(invoiceData.materials);
      setInvoiceGeneralData({
        shipping_cost: invoiceData.shipping_cost,
        reviewer_comment: invoiceData.reviewer_comment,
        invoice_type: invoiceData.invoice_type,
        special_instructions: invoiceData.special_instructions
          ? invoiceData.special_instructions
          : getShippingInstructions(),
        broker_information: invoiceData.broker_information
          ? invoiceData.broker_information
          : brokerInfo(),
        footer_information: invoiceData.footer_information
          ? invoiceData.footer_information
          : INVOICE_FOOTER_INFO.Invoice_Footer_Information,
        shipment_details: invoiceData.shipment_details
          ? invoiceData.shipment_details
          : getShipmentDetails(),
        shipper_details: invoiceData.shipper_details
          ? invoiceData.shipper_details
          : getShipperConsignor(),
        recipient_details: invoiceData.recipient_details
          ? invoiceData.recipient_details
          : getRecipientDetails(),
        importer_of_record: invoiceData.importer_of_record
          ? invoiceData.importer_of_record
          : getImporterDetails(),
        shippingcost_text: invoiceData.shippingcost_text
          ? invoiceData.shippingcost_text
          : "",
        currency_code: currencyCode,
      });
    } else {
      const materialDetails = materialShippingDetails.map((item) => {
        const currItemQuant = getNetQuantity(item);
        const currItemDescription = getDescription(item);
        const unit =
          allUnits?.find((unit) => unit.unit_id == item.unit_type)?.unit_name ||
          "";
        return {
          material_id: item.id,
          description: currItemDescription,
          quantity: currItemQuant,
          country_of_origin: allCountry.find(
            (country) => country.id === item.country_id
          )?.name_english,
          manufacturer: item.manufacture_country,
          htsus: item.hts_code,
          cost: item.amount,
          sli: item.sli,
          fda_product_code: item.fda_product_code,
          fda_processing_program: item.fda_processing_program,
          hts_code_germany: item.hts_code_germany,
          tariff_Code: item.hts_code_china,
        };
      });
      setProcessedMaterialShippingDetails(materialDetails);

      setInvoiceGeneralData({
        shipping_cost: 0,
        reviewer_comment: extra.reviewer_comment,
        invoice_type: INVOICE_TYPES.USA,
        special_instructions: getShippingInstructions(),
        broker_information: brokerInfo(),
        footer_information: INVOICE_FOOTER_INFO.Invoice_Footer_Information,
        shipment_details: getShipmentDetails(),
        shipper_details: getShipperConsignor(),
        recipient_details: getRecipientDetails(),
        importer_of_record: getImporterDetails(),
        shippingcost_text: "",
        currency_code: currencyCode,
      });
    }
  }, [
    materialShippingDetails,
    allCountry,
    extra,
    invoiceData,
    allUnits,
    allContainer,
    conttype,
    brokers,
  ]);

  const onCheckChange = (e) => {
    if (e.target.checked) {
      setInvoiceGeneralData({
        ...invoiceGeneralData,
        invoice_type: e.target.value,
      });
    }
  };

  const onChange = useCallback(
    (e) => {
      if (e.type === "materialData") {
        setProcessedMaterialShippingDetails(e.data);
      } else {
        setInvoiceGeneralData({
          ...invoiceGeneralData,
          [e.target.name]: e.target.value,
        });
      }
    },
    [invoiceGeneralData]
  );

  const handleSave = async (
    invoiceGeneralData,
    processedMaterialShippingDetails
  ) => {
    setLoading(true);

    const data = {
      ...invoiceGeneralData,
      processedMaterialShippingDetails,
      shipping_cost: invoiceGeneralData.shipping_cost,
      shipping_req_id: currentShipmentRequestData.id,
      created_by: instance.getActiveAccount().username,
    };
    try {
      setEditMode(false);
      await actions.saveInvoiceData(data);
      toast.success("Invoice saved");
    } catch (error) {
      console.error(error);
      toast.error("Operation failed");
    }
    setLoading(false);
  };

  const shipperConsignorComponent = shipper.map((item) => {
    let user = allRecipients.find(
      (recipient) => recipient.id === item.recipient_id
    );

    if (item.shipper_locked_data) {
      user = item.shipper_locked_data;
    }
    return (
      <div>
        {new UserUtil(user).toString()}, {new AddressUtil(user).toString()},{" "}
        {user?.phone}, {user?.email}
      </div>
    );
  });

  // Invoice Shipment Details section Start.

  // Getting ItemDescription to display in the Grid.
  const getDescription = (item) => {
    getContainer(item.container_type);
    return `Material (Name): ${item.description}
Aggregate State: ${item.phase_english}
Batch Number: ${item.batch_number}
Country of Manufacturer: ${item.manufacture_country}
${item.hts_code_germany ? "HS-Germany:" + item.hts_code_germany : ""}
${item.hts_code_china ? "Foreign Tariff Code:" + item.hts_code_china : ""}`;
  };

  // Getting Shipping Instruction details to display
  const getShippingInstructions = () => {
    return `${showWarningMessage ? t("invoice.errormsg") : ""}
Distribution Temperature: ${
      currentShipmentRequestData?.distributiontemp_description
    }`;
  };

  // Getting Shipment Details details to display
  const getShipmentDetails = () => {
    return `${
      t("shipping_log_fields.shipmentConstraintDateLabel") +
      moment(new Date()).format("DD-MMM-YYYY")
    },${"\n"}Shipment Request #: ${
      currentShipmentRequestData?.shipment_no
    },${"\n"}Incoterms: ${
      incoterms
        ? incoterms.find(
            (item) => item.id === currentShipmentRequestData?.inco_term
          )?.name
        : ""
    }`;
  };

  const shipperConsigner = shipper?.map((item) => {
    let userShipper = allRecipients.find(
      (recipient) => recipient.id === item.recipient_id
    );

    if (item.shipper_locked_data) {
      userShipper = item.shipper_locked_data;
    }
    return userShipper;
  });

  // Getting Shipper/consignor details to display
  const getShipperConsignor = () => {
    let user = shipperConsigner[0];
    return `${user ? user?.entity : ""}
${getValueBycheckingNull(user?.title) ? user.title + ", " : ""}${
      getValueBycheckingNull(user?.first_name) ? user.first_name + ", " : ""
    }${getValueBycheckingNull(user?.last_name) ? user.last_name : ""}
${getValueBycheckingNull(user?.department)}
${getValueBycheckingNull(user?.street)}${
      getValueBycheckingNull(user?.building) ? ", " + user.building : ""
    }
${getValueBycheckingNull(user?.city)}${
      getValueBycheckingNull(user?.state) ? ", " + user.state : ""
    }${getValueBycheckingNull(user?.postcode) ? ", " + user.postcode : ""}
${getValueBycheckingNull(user?.name_english)}
${getValueBycheckingNull(user?.email)}
${getValueBycheckingNull(user?.phone)}`;
  };

  // Getting Broker details to display
  const broker = brokers?.find((broker) => broker.id === extra?.broker_id);
  const brokerInfo = () => {
return `${getValueBycheckingNull(broker?.broker_name)?  broker.broker_name + ", " : ""}
${broker ? broker?.broker_address.replaceAll("<br/>", " ") : ""}`;
  };

  // Getting Recipient details to display
  const getRecipientDetails = () => {
    const user = currentShipmentRequestData;

    return `${user ? user?.entity : ""}
${getValueBycheckingNull(user?.title) ? user.title + ", " : ""}${
      getValueBycheckingNull(user?.first_name) ? user.first_name + ", " : ""
    }${getValueBycheckingNull(user?.last_name) ? user.last_name : ""}
${getValueBycheckingNull(user?.department)}
${getValueBycheckingNull(user?.street)}${
      getValueBycheckingNull(user?.building) ? ", " + user.building : ""
    }
${getValueBycheckingNull(user?.city)}${
      getValueBycheckingNull(user?.state) ? ", " + user.state : ""
    }${getValueBycheckingNull(user?.postcode) ? ", " + user.postcode : ""}
${getValueBycheckingNull(user?.name_english)}
${getValueBycheckingNull(user?.email)}
${getValueBycheckingNull(user?.phone)}`;
  };

  // Getting Importer details to display
  const getImporterDetails = () => {
    return `${currentShipmentRequestData.importer_of_records? 
      impoter?.find(
        (item) => item.id === currentShipmentRequestData?.importer_of_records
      )?.name: ""
    }
${currentShipmentRequestData?.entity_name}
${currentShipmentRequestData?.importer_records_address}`;
  };

  // common function to check for undefined and null
  const getValueBycheckingNull = (item) => {
    if (item === null || item === undefined) {
      return "";
    } else {
      return item;
    }
  };

  // Invoice Shipment Details section End

  return (
    <LoadingOverlay active={loading} spinner>
      <BasicTabs />
      <div className="container-fluid  ">
        <div className="row mlr15 border ">
          <div className="col-12">
            <div>
              <div className="pull-right">
                <button
                disabled = {currentShipmentRequestData?.status === STATUS.IN_DELIVERY}
                  type="button"
                  id="savemethod"
                  className="btn btn-primary btn-inner-close"
                  onClick={() => {
                    if (editMode) {
                      if (currentShipmentRequestData) {
                        actions.getInvoiceData(currentShipmentRequestData.id);
                      }

                      actions.getMaterialShippingDetails(
                        currentShipmentRequestData
                      );
                    }
                    setEditMode(!editMode);
                  }}
                >
                  {editMode
                    ? t("commonlabels.cancelbutton")
                    : t("commonlabels.edit")}
                </button>{" "}
                <button
                disabled = {currentShipmentRequestData?.status === STATUS.IN_DELIVERY}
                  type="button"
                  id="savemethod"
                  className="btn btn-primary btn-inner-close"
                  onClick={() =>
                    handleSave(
                      invoiceGeneralData,
                      processedMaterialShippingDetails
                    )
                  }
                >
                  {t("commonlabels.saveButton")}
                </button>{" "}
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      id="savemethod"
                      className="btn btn-primary btn-inner-close"
                    >
                      {t("invoice.Print")}
                    </button>
                  )}
                  content={() => printRef.current}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="col-12 invoice_main" ref={printRef}>
            <br />
            <div className="invoice_header">
              <div className="invoice_title"> {t("invoice.invoice")}</div>
            </div>
            <InvoiceUSA
              materialShippingDetails={processedMaterialShippingDetails}
              invoiceGeneralData={invoiceGeneralData}
              editMode={editMode}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px" }}
      ></iframe>
    </LoadingOverlay>
  );
}
