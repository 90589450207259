import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { useState,useEffect } from "react";
import { Link } from "@material-ui/core";
import { Link as BrowserLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "../common/CustomSelect";
import DestinationAddressDetails from "./DestinationAddressDetails";
import { useStyles } from "../common/ThemeStye";
import { Alert } from "@mui/material";
import { STATUS, TYPE_OF_SHIPMENT, USERROLES } from "../../constants";
import _ from "lodash";
import DropDownSearch from "../common/DropDownSearch";
import { wordBreakDownSearch } from "../common/utils/CustomFilter";
import AddressUtil from "../common/utils/AddressUtil";
import { ifRequestCompletedApprovals } from "../../Services/util";

const DestinationAddrSearch = ({
  destinationAddrSelect,
  shippingRequestDetails,
  redirectAction,
  redirectTo,
  extra,
  actions,
  allAddressList,
  shippingType,
  shipper,
  allRecipients
}) => {
  const status = _.get(shippingRequestDetails, ["0", "status"], undefined);
  const classes = useStyles();
  const { t } = useTranslation();
  const userType = JSON.parse(localStorage.getItem("userType"));
  const [shipperAddress,setShipperAddress] = useState({})
  useEffect(()=>{
    setShipperAddress(allRecipients.find(
      (recipient) => recipient.id == shipper[0]?.recipient_id
    ))
  },[shipper])
  const SameShipperAdd = (addObj)=>{
    if(addObj.id == shipperAddress?.address_id) return true 
    return false
  }
  const handleFinalDestSelect = async (val) => {
    actions.destinationAddrSelect(val || {});
    if (val) {
      actions.getFinalRecipients(val.id).then((finalRecipient) => {
        const recipientSelected = finalRecipient.data.find(item=>item.valid);
        
        if (recipientSelected) {

          actions.finalRecipientSelect(recipientSelected);
        } else {
          actions.clearFinalRecipientSelect();
        }
      });
    } else {
      actions.clearFinalRecipientSelect();
    }
  };
  const shipType=shippingType.find((obj)=>obj?.id===extra?.type_of_shipment)

  const isaddressvalid = (obj) =>{
    if(obj == null || obj?.postcode==null || typeof obj == 'undefined' ||  Object.keys(obj).length == 0) return true
    if(obj?.countryvalid == true){
      if(obj?.addressvalid == 1){
        if(shipType==null || shipType?.isactive==true){
          return true
        }else{
          return false
        }
        // return true
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }

  return (
    <>
      <Card variant="outlined" className="destinationlink" style={{border:(isaddressvalid(destinationAddrSelect))? "":"3px solid orange"}}>
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t("shipping_request.destination_address")}
              <span className="req">*</span>{" "}
            </Typography>
          }
          className={classes.cardHeader}
          action={
            (userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.APPLICANT) ||
            userType.includes(USERROLES.ADMIN)) && (status !== STATUS.IN_DELIVERY)? (
              <Link
                href="javascript:void(0)"
                className="link_finalrecipct"
                title={t("shipping_request.destinationaddress_link_over")}
                onClick={redirectAction}
              >
                {t("shipping_request.destinationaddress_link")}
              </Link>
            ) : (
              <Link to="" className="link_finalrecipct" style={{ pointerEvents: "none", color: "black" }}>
                {" "}
                {t("shipping_request.destinationaddress_link")}
              </Link>
            )
          }
        />

        <CardContent>
          {(status === undefined || status === STATUS.INPUT) &&
            <Card variant="outlined" className="alert-margin">
              <CardContent className="p-2 notice">
                <Alert severity="warning">
                  {t("shipping_request.destination_address_warning")}
                  <br></br>
                </Alert>
              </CardContent>
          </Card>}

          {shippingRequestDetails?.length > 0 &&
          ![
            STATUS.INPUT,
            STATUS.INBOX,
            STATUS.REGULATORY_REVIEWER_1,
            STATUS.REGULATORY_REVIEWER_2,
          ].includes(shippingRequestDetails[0]?.status) &&
          shippingRequestDetails[0]?.address_id &&
          !shippingRequestDetails[0]?.address_approved &&
          !ifRequestCompletedApprovals(shippingRequestDetails[0]?.status) ? (
            <Alert severity="warning">
              <BrowserLink
                to={{
                  pathname: `/approvelInspectAddress/${shippingRequestDetails[0].address_id}`,
                  state: {
                    id: shippingRequestDetails[0].address_id,
                    shippingRequest: shippingRequestDetails[0],
                  },
                }}
              >
                {t("address.address_not_approved")}
              </BrowserLink>
            </Alert>
          ) : null}
          <div className="row col-12 pr-0">
            <div className="row col-12 pr-0">
              <div className="col-lg-3  col-12 form-outline form-margin">
                <label className="col-form-label">{t("address.address")}</label>

                <DropDownSearch
                  list={_(allAddressList.filter((x) => {return !SameShipperAdd(x) && x.addressvalid === 1 && x.countryvalid === true}))
                    .map((item) => {
                      item.label = new AddressUtil(item).toString();
                      return item;
                    })
                    .uniqBy("label")
                    .sortBy("label")
                    .value()}
                  label=" "
                  handleChange={handleFinalDestSelect}
                  value={
                    destinationAddrSelect?.id
                      ? new AddressUtil(destinationAddrSelect).toString()
                      : ""
                  }
                  filterOptions={wordBreakDownSearch("label")}
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN) 
                    )||
                    status === STATUS.IN_DELIVERY
                  }
                />
              </div>
            </div>
          </div>

          <DestinationAddressDetails
            shippingRequestDetails={shippingRequestDetails}
            destinationAddrSelect={destinationAddrSelect}
            extra={extra}
            shippingType={shippingType}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default DestinationAddrSearch;
