import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import shippingTypeComponent from "./ShippingType";

const { getShippingTypeList, saveShippingType, updateShippingTypeAccess } =
  title.actions;
const mapStateToProps = (state) => ({
  isLoader: state[title.name].isLoader,
  shippingTypeData: state[title.name].shippingTypeData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getShippingTypeList,
      saveShippingType,
      updateShippingTypeAccess,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(shippingTypeComponent);
