import types from "./types";

const initialState = {
  pageIdentifier: null,
  selectedRow: null,
  processShippingSearchData: {
    id: "",
    id_request_old: "",
    id_old: "",
    status: "",
    entity: "",
    city: "",
    internal_distribution_number: "",
    created_by: "",
    address_id: "",
    address: "",
    final_receipt: "",
    description: "",
    created_on: "",
    tracking_number: "",
    sap_delivery_number: "",
    approved: false,
    country_id: "",
    valid: false,
    submitted: false,
    startDate: "",
    endDate: "",
    shipmentDateLevStart: "",
    shipmentDateLevEnd: "",
    shipmentDateMomStart: "",
    shipmentDateMomEnd: "",
    dateOfRecipientStart: "",
    dateOfRecipientEnd: "",
    name_english: "",
    country_of_shipper: "",
    type_of_shipment: "",
    prefered_reviewer: "",
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_TABLE_DATA: {
      return {
        ...state,
        ...(action.payload)
      };
    }
    case types.SET_PROCESS_SHIPPING_SEARCH_DATA: {
      return {
        ...state,
        processShippingSearchData: action.payload,
      }
    }
    default:
      return state;
  }
};

export default reducer;
