import React, { useEffect, useState } from "react";

import PageLayout from "../../common/PageLayout";
import ProcessShippingRequestLogTable from "./ProcessShippingRequestLogTable";
import ProcessShippingRequestLogSearch from "./ProcessShippingRequestLogSearch";
import { useParams } from "react-router";
import shippingService from "../../../Services/shippingService";
import { useDispatch } from 'react-redux'
import types from "../../../redux/shipping/types";

const ProcessShippingRequestLog = (props) => {
  const { actions, allMaterials, shippmentHistoryLogs, shippingLogSearchData } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(async () => {
    if(id){
      // await actions.getShippingHistory([id]);
      const data = await shippingService.getShippingHistory([id])
      dispatch({
        type:types.GET_SHIPPMENT_HISTORY,
        payload: data
      })

    }
  }, [id]);

  return (
    <>
      <PageLayout className="title_main mt-1"
        // title={t("process_shipping_request.process_shipping_request")}
        searchComponent={
          <ProcessShippingRequestLogSearch
            onSearch={(data) => setSearchFilter(data)}
            actions={actions}
            searchKey="processShippingRequestLog"
            state={shippingLogSearchData}
            shippmentHistoryLogs={shippmentHistoryLogs}
          />
        }
        mainComponent={
          <ProcessShippingRequestLogTable
            shippmentHistoryLogs={shippmentHistoryLogs}
            actions={actions}
            searchFilter={searchFilter}
            setIsLoading={setIsLoading}
            allMaterials={allMaterials}
          />
        }
        isLoading={false}
      />
    </>
  );
};

export default ProcessShippingRequestLog;
