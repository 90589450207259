import types from "./types";

const initialState = {
  allMaterials: [],
  loader: true,
  allContainerList: [],
  searchData: {},
  tableMaterialOptions: {},
  tableApproveMaterialOptions:{}
};
const materialReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_MATERIALS:
      return {
        ...state,
        allMaterials: action.payload,
        loader: false,
      };
    case types.GET_ALL_CONTAINER:
      return {
        ...state,
        allContainerList: action.payload,
        loader: false,
      };
    case types.SET_MATERIAL_SEARCH_DATA: {
      return {
        ...state,
        searchData: action.payload,
      };
    }
    case types.SET_MATERIAL_TABLE_OPTIONS: {
      return {
        ...state,
        tableMaterialOptions: action.payload,
      };
    }
    case types.SET_MATERIAL_APPROVE_TABLE_OPTIONS: {
      return {
        ...state,
        tableApproveMaterialOptions: action.payload,
      };
    }
    default:
      return state;
  }
};

export default materialReducer;
