import Modal from 'react-bootstrap/Modal';


export const Model = (props) => {

    return (
        <div>
            <Modal
                dialogClassName="my-modal"
                centered
                show={props.showheader}
            >
                <Modal.Header centered="true" className="bg-theme-blue header-model">
                    <h5 className="modal-title"> {props.modalHeader} </h5>
                </Modal.Header>

                <Modal.Body>
                    {props.modalbody}
                </Modal.Body>

                <Modal.Footer>
                    {props.modalfooter}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Model;

