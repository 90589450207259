import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import { authContext } from "../../../adalConfig";
import AddressSerarch from "./AddressSearchComponent";
import AddressListingComponent from "./AddressListingComponent";


import { addressContainerStyles } from "./addressStyles";

const Address = (props) => {
  const { actions,tableAddressAprovalOptions, allAddressNotApproved, countries,state, allMyAddress } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const [searchFilter, setSearchFilter] = useState({});
  const [searchMyAddressFilter, setSearchMyAddressFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const classes = addressContainerStyles();
  const location = useLocation();
  useEffect(() => {
    actions.getCountries().then(() => {
      actions.getAllAddressNotApproved().then(() => {
        actions.getMyAddress(userId).then(() => {
          setIsLoading(false);
        });
      });
    });
  }, []);
  useEffect(() => {
    if(!Object.keys(searchFilter).length){
      setSearchFilter(state)
    }
  }, [])
  useEffect(() => {
    if (location.pathname === "/myAddress") {
      actions.setApprovalAddressSearchData({})
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [location.pathname]);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Divider />
        <Container maxWidth="xxxl" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
             
                
                <AddressSerarch
                  onSearch={(data) => setSearchFilter(data)}
                  countries={countries}
                  actions={actions}
                />
              
            </Grid>
            <Grid item xs={12}>
          {!isLoading &&
         
              <AddressListingComponent
              listData={flag ? allMyAddress : allAddressNotApproved}
                actions={actions}
                searchFilter={searchFilter}
                setIsLoading={setIsLoading}
                myAddress={flag}
                tableAddressAprovalOptions={tableAddressAprovalOptions}
              /> }
            </Grid>
          </Grid>
        </Container>
      </LoadingOverlay>
    </>
  );
};

export default Address;
