import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutocompleteText(props) {
  const {
    //options,
    label,
    onChange,
    name,
    //required,
    is_disabled = false,
    popupIcon = true,
  } = props;

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Autocomplete
        id="free-solo-demo"
        disableClearable={is_disabled}
        disabled={is_disabled}
        forcePopupIcon={popupIcon}
        freeSolo
        getOptionLabel={(option) => option}
        renderInput={(params) => <TextField {...params} label={label} />}
        {...props}
        onInputChange={(event, value) => {
          if (onChange) {
            onChange({ target: { name, value } });
          }
        }}
        onChange={() => {}}
      />
    </Stack>
  );
}
