import React from "react";
import { styled } from "@mui/material/styles";
import { get } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#98AFC7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomizedTables = (props) => {
  const { t } = useTranslation();

  const { list = [], message = "", handleDeputyRemove } = props;
  const propsCount = React.Children.count(props.children) || 0;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t("recipient.Email")}</StyledTableCell>
            <StyledTableCell>{t("recipient.name")}</StyledTableCell>
            <StyledTableCell>{t("recipient.Phone")}</StyledTableCell>
            <StyledTableCell>{t("RegisteredUser.mobile")}</StyledTableCell>
            <StyledTableCell>{t("RegisteredUser.department")}</StyledTableCell>
            {propsCount === 1 && <StyledTableCell></StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row.cwid}
              </StyledTableCell>
              <StyledTableCell>{get(row, "full_name", null)}</StyledTableCell>
              <StyledTableCell>{get(row, "phone_no", null)}</StyledTableCell>
              <StyledTableCell>{get(row, "mobile_no", null)}</StyledTableCell>
              <StyledTableCell>{get(row, "department", null)}</StyledTableCell>
              {propsCount === 1 && (
                <StyledTableCell onClick={() => handleDeputyRemove(row)}>
                  {t("commonlabels.remove")}
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          {!list.length && message}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomizedTables;
