import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import shipping from "../../redux/shipping";
import ShippingMaterialsComponent from "./ShippingMaterialsComponent";
import navbar from "../../redux/navbar";

const {
  getShippingMaterials,
  addShippingMaterialSelect,
  shippingMaterialSelectedValue,
  clearShippingMaterialSelectedValue,
  getCountries,
  getCountriesList,
  materailIdSelect,
  getPhases,
  phaseSelectValue,
  getMaterialShippingDetails,
  updateMaterial,
  getShippingRequestDetails,
  shippingUnderReviewRequestDetails,
  updateShipping,
  createShipping,
  shippingHistory,
  getShippingAttachment,
  setExtraData,
  getAllCurrency,
  getAllUnits,
  deleteDeputiesShippingRequest,
  addDeputiesShippingRequest,
  getAllContainer,
  SubmitUpdate,
  getShippingStatus,
  clearshippingRequest,
  setShippingSearchData,
  setTableOptions,
  setAdminShippingTableOptions,
} = shipping.actions;

const { setNavKey } = navbar.actions;

const mapStateToProps = (state) => ({
  shippingMaterials: state[shipping.name].shippingMaterials,
  countries: state[shipping.name].countriesList,
  phases: state[shipping.name].phases,
  shippingMaterialSelect: state[shipping.name].shippingMaterialSelect,
  shippingMaterialSelectedValue:
    state[shipping.name].shippingMaterialSelectedValue,
  materialShippingDetails: state[shipping.name].materialShippingDetails,
  materailIdSelect: state[shipping.name].materailIdSelect,
  phaseSelectValue: state[shipping.name].phaseSelectValue,
  getShippingResponse: state[shipping.name].getShippingResponse,
  shippingRequestDetails: state[shipping.name].shippingRequestDetails,
  shippingUnderReviewRequestDetails: state[shipping.name].shippingUnderReviewRequestDetails,
  internalOrderIdValue: state[shipping.name].internalOrderIdValue,
  distributionTempValue: state[shipping.name].distributionTempValue,
  shippingCreateDateValue: state[shipping.name].shippingCreateDateValue,
  shippingCreateCommentValue: state[shipping.name].shippingCreateCommentValue,
  dispatchMONValue: state[shipping.name].dispatchMONValue,
  dispatchLEVValue: state[shipping.name].dispatchLEVValue,
  dateOfReceiptValue: state[shipping.name].dateOfReceiptValue,
  trackingNumValue: state[shipping.name].trackingNumValue,
  statusQuoValue: state[shipping.name].statusQuoValue,
  updateShipping: state[shipping.name].updateShipping,
  shippingActionType: state[shipping.name].shippingActionType,
  redirectTo: state[shipping.name].redirectTo,

  destinationAddrList: state[shipping.name].destinationAddrList,
  destinationAddrSelect: state[shipping.name].destinationAddrSelect,
  finalRecipients: state[shipping.name].finalRecipients,
  finalRecipientSelect: state[shipping.name].finalRecipientSelect,
  shippingDeputySelect: state[shipping.name].shippingDeputySelect,

  shipmentSpecificValue: state[shipping.name].shipmentSpecificValue,
  sapDeliveryNumValue: state[shipping.name].sapDeliveryNumValue,

  attachements: state[shipping.name].attachements,
  extra: state[shipping.name].extra,
  allCurrency: state[shipping.name].allCurrency,
  allUnits: state[shipping.name].allUnits,
  shipper: state[shipping.name].shipper,
  allContainer: state[shipping.name].allContainer,
  mailLinkData: state[shipping.name].mailLinkData,
  distributionTemperature: state[shipping.name].distributionTemperature,
  shippingType: state[shipping.name].shippingType,
  newShippingRequestCommentsList: state[shipping.name].newShippingRequestCommentsList,
  shippingSearchData:state[shipping.name].shippingSearchData,
  tableOptions: state[shipping.name].tableOptions,
  tableAdminShippingOptions: state[shipping.name].tableAdminShippingOptions,
  clientTimeZone:state[shipping.name].clientTimeZone

});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setTableOptions,
      setAdminShippingTableOptions,
      setShippingSearchData,
      getShippingMaterials,
      addShippingMaterialSelect,
      shippingMaterialSelectedValue,
      clearShippingMaterialSelectedValue,
      getCountries,
      materailIdSelect,
      getPhases,
      phaseSelectValue,
      getMaterialShippingDetails,
      updateMaterial,
      getShippingRequestDetails,
      updateShipping,
      createShipping,
      shippingHistory,
      shippingUnderReviewRequestDetails,
      getShippingAttachment,
      setExtraData,
      getAllCurrency,
      getAllUnits,
      deleteDeputiesShippingRequest,
      addDeputiesShippingRequest,
      setNavKey,
      getAllContainer,
      SubmitUpdate,
      getShippingStatus,
      getCountriesList,
      clearshippingRequest
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingMaterialsComponent);
