import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory, useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../adalConfig";
import { addressSearchStyles } from "./addressStyles";
import recipientService from "../../../Services/recipientService";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Model from "../../shared/model";

const CheckRecipientsComponent = (props) => {
  const ItemContainer = ({ children, sm = "3" }) => {
    return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
  };
  const { actions } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;

  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [confirmActionPopup, setConfirmActionPopup] = useState(false);

  const { recipientId } = useParams();

  const [reviewBy, setReviewBy] = useState('');
  const [checkboxState, setCheckBoxState] = useState(false);
  const [rejectComments, setRejectComments] = useState("");

  const classes = addressSearchStyles();
  useEffect(() => {
    if (recipientId) {
      actions.getAllrecipients().then((res) => {
        const selectedRecipients = res.data.find(
          (item) => item.id === recipientId,
        );
        setSelected(selectedRecipients);
        setIsLoading(false);
        setReviewBy(selectedRecipients.review_by);
        setCheckBoxState(selectedRecipients.review_by ? true : false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      if (props.location.state?.shippingRequest) {
        actions.getShippingRequestDetails(
          props.location.state?.shippingRequest,
        );
      }
    };
  }, [recipientId]);

  const handleClose = () => {
    history.goBack();
  };

  const recipientApprovalUpdate = async (data) => {
    if(data.approved || rejectComments.length > 0) {
      setConfirmActionPopup(false);
      setIsLoading(true);
      const recipientData = {
        id: recipientId,
        isReject: data.approved === 0 ? true : false,
        createdBy: selected.created_by,
        comment: rejectComments,
        rejectingUser: userId,
        approved: data.approved,
        recipientName: (selected.first_name + ' ' + selected.last_name)
      };
      try {
        await recipientService.recipientApprovalUpdate(recipientId, recipientData).then(() => {
          setIsLoading(false);
          if(data.approved)
            toast.success(t("approve_recipient.recipient_approved"));
          else
            toast.success(t("approve_recipient.recipient_rejected"));
          history.goBack();
        });
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
        history.goBack();
      }
    }
  };

  const handleUnderReviewCheckBox = async (event) => {
    let addressData = {
      id: selected?.id,
      name: ''
    };
    let underReviewMessage = t("recipient.reviewReset");
    
    if(event.target.checked) {
      addressData.name = userId
      underReviewMessage = t("recipient.reviewSet");
    }

    setCheckBoxState(event.target.checked);
    setReviewBy(addressData.name);
    await recipientService.updateApproveRecipientReviewStatus(addressData).then((response) => {
      if(response.IsSuccess) {
          toast.success(underReviewMessage);
      }
    });
  }

  const CheckboxField = (props) => {
    return (
      <div className="d-flex">
       <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={checkboxState}
           {...props}
        />
        <label className={classes.checkBoxLabel}>{props.label}</label>
      </div>
    );
  };

  const modalBody = () => {
    return (
      <>
        <div className="row align-items-center">
          <div className="col-lg-12">
              <div className="form-group">
                  <label>{t("approve_recipient.disapprove_confirm_body")}</label>
              </div>
              <div className="col-lg-12 p-0">
                  <div className="form-group">
                      <label className="">
                          {" "}
                          <b>{t("comments_alert.comments")}<span className="req">*</span></b>{" "}
                      </label>
                      <TextareaAutosize
                          id="rejectComments"
                          name="rejectComments"
                          aria-label="rejectComments"
                          margin="normal"
                          variant="outlined"
                          size="large"
                          minRows={5}
                          style={{ width: "100%" }}
                          value={rejectComments.length === 0 ? "" : rejectComments}
                          onChange={(e) => { 
                              e.preventDefault()
                              setRejectComments(e.target.value)
                          }}
                      />
                  </div>
              </div>
          </div>
        </div>
      </>
    );
  }

  const modalFooterEditMethod = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            recipientApprovalUpdate({ approved: 0, userId });
          }}
        >
          {t("commonlabels.yesButton")}
        </button>
        <button
          type="button"
          className="btn btn-black btn-inner-close"
          onClick={(e) => setConfirmActionPopup(false)}
        >
          {t("commonlabels.noButton")}
        </button>
      </>
    );
  }

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <div className="title_main ml-3 pt10">
          {t("inspectRecipient.title")}{" "}
        </div>
        <Card variant="outlined" className={classes.container}>
          <div className="col-12">
            <CardContent>
              <div className="row form-padding  ">
                <ItemContainer sm="6">
                  <Card variant="outlined" className={classes.container}>
                    <Divider />
                    <CardContent className="form-padding">
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLable}>
                            {t("recipient.Title")}:
                          </span>
                          <span>{selected?.title}</span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLable}>
                            {t("search_fields.first_name")}:
                          </span>
                          <span>{selected?.first_name}</span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLable}>
                            {t("recipient.Last name")}:
                          </span>
                          <span>{selected?.last_name}</span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLable}>
                            {t("recipient.Email")}:
                          </span>
                          <span>{selected?.email}</span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLable}>
                            {t("recipient.Phone")}:
                          </span>
                          <span>{selected?.phone}</span>
                        </ItemContainer>
                      </div>

                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLable}>
                            {t("recipient.review")}:
                          </span>
                          <CheckboxField
                            id="review_by"
                            name="review_by"
                            className="check_box"
                            label={reviewBy ? reviewBy : ''}
                            onChange={(e) => handleUnderReviewCheckBox(e)}
                          />
                        </ItemContainer>
                      </div>
                    </CardContent>
                  </Card>
                </ItemContainer>
                <ItemContainer sm="6">
                  <Card variant="outlined" className={classes.container}>
                    <CardContent>
                      <div>
                        <span>
                          {t("approve_recipient.recipient_linked_msg")}
                        </span>
                        <br />
                        <span>{t("approve_recipient.list_empty_msg")}</span>
                      </div>
                    </CardContent>
                  </Card>
                  <br />
                  <Card
                    variant="outlined"
                    className={classes.container}
                    style={{ display: "none" }}
                  >
                    <CardContent>
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("recipient.sap_number")}:
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.sapnumber}
                        </span>
                      </ItemContainer>
                    </CardContent>
                  </Card>
                  <br />
                  <Card variant="outlined" className={classes.container}>
                    <Divider />

                    <CardContent className="form-padding">
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.Company/University")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.entity}
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.Department")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.department}
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.building")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.building}
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.Street")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.street}
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.Postal Code")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.postcode}
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.City")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.city}
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="4">
                          <span className={classes.textLable}>
                            {t("address.Country")}:
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="8">
                          <span className={classes.textLable}>
                            {selected?.name_english}
                          </span>
                        </ItemContainer>
                      </div>
                    </CardContent>
                  </Card>
                </ItemContainer>
              </div>
            </CardContent>
          </div>
          <CardActions>
            <div className="col-12 pull-right text-right ">
              <button
                className="btn btn-secondary new_button mr-2"
                onClick={handleClose}
              >
                {t("approve_recipient.previous")}
              </button>
              &nbsp;
              <Link
                to={{
                  pathname: `/editRecipients`,
                  state: { id: selected?.id },
                }}
              >
                <button className="btn btn-primary new_button mr-2">
                  {t("materials.edit")}
                </button>
                &nbsp;
              </Link>
              <button
                className="btn btn-success new_button mr-2"
                onClick={() => recipientApprovalUpdate({ approved: 1, userId })}
              >
                {t("materials.approve")}
              </button>
              &nbsp;
              <button
                className="btn btn-primary new_button mr-2"
                onClick={() => setConfirmActionPopup(true)}
              >
                {t("approve_recipient.not_approve")}
              </button>
            </div>
          </CardActions>
        </Card>

        <Model
          showheader={confirmActionPopup}
          modalHeader={t("approve_recipient.disapprove_confirm_header")}
          modalbody={modalBody()}
          modalfooter={modalFooterEditMethod()}
        ></Model>
      </LoadingOverlay>
    </>
  );
};

export default CheckRecipientsComponent;
