import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Select from "../../common/CustomSelect";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "./addressStyles";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "react-datepicker/dist/react-datepicker.css";
import recipients from "../../../redux/recipients";
import "./recipientsSearchComponent.css";
import { useHistory } from "react-router";
import Autocomplete from "../../common/AutocompleteText";
import DropDownSearch from "../../common/DropDownSearch";
import { wordBreakDownSearch } from "../../common/utils/CustomFilter";

const DateField = (props) => {
  registerLocale("de", de);
  const { t, i18n } = useTranslation();
  return (
    <DatePicker
      locale={i18n.resolvedLanguage}
      portalId="root-portal"
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
      margin="normal"
      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
      selected={
        props.state[props.name] ? new Date(props.state[props.name]) : ""
      }
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />
  );
};

const RecipientsSerarch = (props) => {
  const { onSearch, addressList, state, actions, searchKey, hiddenFields } =
    props;
  const classes = addressSearchStyles();
  const history = useHistory();

  useEffect(() => {
    if (state.searchKey !== searchKey) {
      dispatch({ type: "RESET" });
      onSearch({});
    } else {
      onSearch({ ...state, searchKey: "" });
    }
  }, []);

  const dispatch = (event) => {
    if (event.type === "RESET") {
      onSearch({});
      return actions.setRecipientSearchData({
        id: "",
        address_id: "",
        full_address: "",
        title_id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        valid: false,
        submitted: false,
        approved: false,
        created_by: "",
        startDate: "",
        endDate: "",
        searchKey,
        review_by: false,
      });
    }

    if (event.target.type === "checkbox") {
      return actions.setRecipientSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }
    if (event?.target?.value?.label !== undefined) {
      return actions.setRecipientSearchData({
        ...state,
        [event.target.name]: event?.target?.value?.label,
        searchKey,
      });
    }
    return actions.setRecipientSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };

  // history.listen((location, action) => {
  //   if (!location.pathname.toLowerCase().includes("recipient")) {
  //     dispatch({ type: "RESET" });
  //   }
  // });

  const [t, i8ln] = useTranslation();

  return (
    <>
      <div className="tablebg_box">
        <div className="row">
          <div className="col-11 pr-0">
            <div className="row col-12 pt10">
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="first_name"
                  name="first_name"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("recipient.Pre-name")}
                  onChange={dispatch}
                  value={state["first_name"]}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="last_name"
                  name="last_name"
                  onChange={dispatch}
                  value={state["last_name"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("recipient.Last name")}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="email"
                  name="email"
                  onChange={dispatch}
                  value={state["email"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("recipient.Email")}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2 approvesearchcountry">
                <DropDownSearch
                  id="full_address"
                  name="full_address"
                  value={addressList.find(addressItem => ((addressItem.name) === state["full_address"]))?.name || ''}
                  list={[...(addressList || []).map((address) => ({ label: address.name, id: address.id }))]}
                  handleChange={(e) => {
                    dispatch({ target: { name: "full_address", value: e } })
                  }}
                  label={t("recipient.Address")}
                  filterOptions={wordBreakDownSearch('label')}
                />
              </div>
            </div>
            <div className="row col-12 pt-10">
              <div className="col-lg-3 col-sm-12 pr-0 mt-0 approveaddcheckbox">
                <div className="row">
                  {(!hiddenFields || !hiddenFields.includes("submitted")) && (
                    <div className="col-lg-4 pr-0 pl-1">
                      <CheckboxField
                        id="submitted"
                        name="submitted"
                        label={t("recipient.submitted")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>
                  )}

                  {(!hiddenFields || !hiddenFields.includes("approved")) && (
                    <div class="col-lg-4 pr-0 pl-0">
                      <CheckboxField
                        id="approved"
                        name="approved"
                        label={t("recipient.approved")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>
                  )}

                  {(!hiddenFields || !hiddenFields.includes("valid")) && (
                    <div class="col-lg-4 pr-0 pl-0">
                      <CheckboxField
                        id="valid"
                        name="valid"
                        label={t("recipient.Valid")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>
                  )}
                </div>
                {searchKey === "recipientApprove" && (
                  <div className="row">
                    <div className="col-lg-6 pr-0 pl-0 review-check-box-margin">
                      <CheckboxField
                        id="rveiew_by"
                        name="review_by"
                        label={t("recipient.review")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* <div class="col-lg-3  pr-0 mt-2">
                <TextField
                  id="id"
                  name="id"
                  onChange={dispatch}
                  value={state["id"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={`Id`}
                />
              </div> */}

              <div class="col-lg-3  pr-0 mt-2">
                <TextField
                  id="created_by"
                  name="created_by"
                  onChange={dispatch}
                  value={state["created_by"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("materials.create_user")}
                />
              </div>

              <div class="col-lg-3  pr-0 mt-2">
                <DateField
                  placeholderText={t("phase.createdatefrom")}
                  name="startDate"
                  id="startDate"
                  onChange={(date) =>
                    dispatch({ target: { name: "startDate", value: date } })
                  }
                  state={state}
                />
              </div>
              <div class="col-lg-3  pr-0 mt-2">
                <DateField
                  placeholderText={t("phase.createtilldate")}
                  name="endDate"
                  id="endDate"
                  onChange={(date) =>
                    dispatch({ target: { name: "endDate", value: date } })
                  }
                  state={state}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-1 pt10 mt-2 d-flex justify-content-end button_addreserach">
            <Button
              variant="contained"
              className={classes.searchBt + " search_buttontwo"}
              onClick={() => onSearch({ ...state })}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.reloadBt + " refresh_button"}
              onClick={() => dispatch({ type: "RESET" })}
            >
              <ReplayIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state[recipients.name].recipientSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setRecipientSearchData: recipients.actions.setRecipientSearchData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipientsSerarch);
const CheckboxField = (props) => {
  return (
    <>
      <Checkbox
        margin="normal"
        variant="outlined"
        size="small"
        checked={props.state[props.name]?props.state[props.name]:false}
        {...props}
      />
      <span>{props.label}</span>
    </>
  );
};
