import { React, useState, forwardRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as config from "../../../config";
import { get } from "lodash";
import Model from "../../shared/model";
import moment from "moment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../../assests/images/infoReq.svg";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "./addressStyles";
import deleteIcon from "../../../assests/images/deleteIcon.svg";
import gray_deleteIcon from "../../../assests/images/grey_delete.svg";
import ActionConfirmModal from "../../common/ActionConfirmModal";
import { toast } from "react-toastify";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { DateFormat,DateWithDefaultTime, DateWithEndTime} from "../../common/utils/DateUtil";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import recipientService from "../../../Services/recipientService";
import { dateSortFunction, SortFunctionNew } from "../../../Services/util";
import AddressUtil from "../../common/utils/AddressUtil";
import { tableRowFormatter, tableRowOrderManager } from "../../common/utils/TableUtil";
import excelExport from "../../shared/excelExport.js"
import dateFormatter from "../../shared/dateFormatter.js";

const RecipientsListingComponent = (props) => {
  const pathApprovalRecipientURLs = [
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/address",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/myShippings",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
  ]
  const [selected, setSelected] = useState("");
  const [selectedForDelete, setSelectedForDelete] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] = useState(false);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const history = useHistory();
  const {
    listData,
    actions,
    searchFilter,
    myrecipient,
    tableApproveRecipientOptions,
  } = props;
  const [filteredData, setfilteredData] = useState(listData);
  const classes = addressSearchStyles();

  const [tableConfig, setTableConfig] = useState({ page: 0, itemsPerPage: 10 });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey",
    );

    const filteredData =
      listData &&
      listData.filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (
            ["submitted", "approved", "valid", "review_by"].includes(
              searchParam
            )
          ) {
            if (searchParam === "review_by" && item[searchParam] !== null) {
              continue;
            }
            if (item[searchParam] !== 1) {
              return false;
            } else {
              continue;
            }
          }

          if (["startDate", "endDate"].includes(searchParam)) {
            if (searchParam === "startDate") {
              const createDate = moment(item.created_on);
              const startDate = moment(searchFilter[searchParam]);
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on);
              const endDate = moment(searchFilter[searchParam]);
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }
          if (["full_address"].includes(searchParam)) {
            if (
              !((new AddressUtil(item)).toString()) ||
              ((new AddressUtil(item)).toString()) !== searchFilter[searchParam]
            ) {
              return false;
            }
            continue;
          }


          if (
            !item[searchParam] ||
            !item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
        }
        return true;
      });
    // const materialId = Number(
    //    sessionStorage.getItem("selectedApproveRecipientRow"),
    //  );
    // const itemIndex = tableConfig.page * tableConfig.itemsPerPage;
    // const itemRange = filteredData.slice(
    //   itemIndex,
    //   itemIndex + tableConfig.itemsPerPage,
    // );

    // const previouslySelectedItem = itemRange.find(
    //   (item) => Number(item.id) === materialId,
    // );

    // if (Number.isSafeInteger(materialId) && previouslySelectedItem) {
    //   setSelectedRow(previouslySelectedItem);
    // } else {
    //   const rowSelected =
    //     filteredData.length >= itemIndex ? filteredData[itemIndex] : null;
    //   setSelectedRow(rowSelected);
    // }

    setfilteredData(filteredData);

    setfilteredData(filteredData);
  }, [searchFilter, listData, tableConfig]);
 
  useEffect(() => {
    return () => {
      if (pathApprovalRecipientURLs.includes(window.location.pathname)) {
        actions.setApproveRecipientTableOptions({});
      }
    };
  }, []);
  const dispatchMaterialOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setApproveRecipientTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableApproveRecipientOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setApproveRecipientTableOptions({
        pageNumber: 0,
        pageSize: tableApproveRecipientOptions.pageSize,
        orderBy: value
      });
    }
  }
  const onDeleteConfirm = async () => {
    try {
      await recipientService.deleteRecipient(selectedForDelete.id);
      toast.success(t("commonlabels.deleteSuccessfullyMsg"));
      actions.getAllrecipients();
    } catch (e) {
      console.error(e);
      toast.error("Operation failed");
    }
    setSelectedForDelete(false);
  };

  const calculateLengthOfSelectedRows = () => {
    if (selected) setSelectedRowsLength(1);
  };
  useEffect(() => {
    calculateLengthOfSelectedRows();
  }, [selected]);

  // const exportDataBuilder1 = () => {
  //   let copyOfFilteredData;

  //   if (selectedRowsLength === 0) {
  //     copyOfFilteredData = _.cloneDeep(filteredData);
  //     copyOfFilteredData.forEach((item) => {
  //       // changing column names
  //       copyOfFilteredData &&
  //         copyOfFilteredData.forEach((element) => {
  //           if (element.created_on) {
  //             element.created_on =  DateFormat(element.created_on,i18n.resolvedLanguage);
  //           }
  //         });

  //       item[t("recipient.name")] =
  //         item["form_of_address"] +
  //         " " +
  //         item["title"] +
  //         "" +
  //         item["first_name"] +
  //         " " +
  //         item["last_name"];
  //       item[t("recipient.Email")] = item["email"];
  //       item[t("recipient.Phone")] = item["phone"];
  //       item[t("recipient.Address")] = item["entity"];
  //       item[t("recipient.Create Date")] = item["created_on"];
  //       item[t("address.Created User")] = item["created_by"];
  //       // item[t("address.Department")] =  item["department"];

  //       delete item["id"];
  //       delete item["address_id"];
  //       delete item["title_id"];
  //       delete item["approved"];
  //       delete item["email"];
  //       delete item["first_name"];
  //       delete item["form_of_address"];
  //       delete item["last_name"];
  //       delete item["phone"];
  //       delete item["submitted"];
  //       delete item["valid"];
  //       delete item["created_on"];
  //       delete item["created_by"];
  //       delete item["updated_on"];
  //       delete item["updated_by"];
  //       delete item["is_active"];
  //       delete item["add_id"];
  //       delete item["country_id"];
  //       delete item["building"];
  //       delete item["city"];
  //       delete item["department"];
  //       delete item["entity"];
  //       delete item["postcode"];
  //       delete item["street"];
  //       delete item["sapnumber"];
  //       delete item["name_english"];
  //       delete item["sapnumber"];
  //       delete item["title"];
  //       delete item["name"];

  //       for (let key in item) {
  //         if (item[key] === null) {
  //           item[key] = "";
  //         }
  //       }
  //     });
  //   } else if (selectedRowsLength > 0) {
  //     copyOfFilteredData = _.cloneDeep(selected);
  //     copyOfFilteredData[t("recipient.name")] =
  //       copyOfFilteredData["form_of_address"] +
  //       " " +
  //       copyOfFilteredData["title"] +
  //       "" +
  //       copyOfFilteredData["first_name"] +
  //       " " +
  //       copyOfFilteredData["last_name"];
  //     copyOfFilteredData[t("recipient.Email")] = copyOfFilteredData["email"];
  //     copyOfFilteredData[t("recipient.Phone")] = copyOfFilteredData["phone"];
  //     copyOfFilteredData[t("recipient.Address")] = copyOfFilteredData["entity"];
  //     copyOfFilteredData[t("recipient.Create Date")] =
  //       copyOfFilteredData["created_on"];
  //     copyOfFilteredData[t("address.Created User")] =
  //       copyOfFilteredData["created_by"];
  //   }


  //   return _.cloneDeep(copyOfFilteredData);
  // };

  const handleCSVExport = async () => {

    let dataForExport = exportDataBuilder();

    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Recipient_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData)
    }
    else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData)
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on, i18n.resolvedLanguage);
      }
      if (item.date_of_recipient) {
        item.date_of_recipient = DateFormat(item.date_of_recipient, i18n.resolvedLanguage);
      } if (item.editdate) {
        item.editdate = DateFormat(item.editdate, i18n.resolvedLanguage);
      } if (item.shipment_date_mon) {
        item.shipment_date_mon = DateFormat(item.shipment_date_mon, i18n.resolvedLanguage);
      }
      if (item.first_name) {
        item.first_name = (item.first_name ? item.first_name : '') + " " + (item.last_name ? item.last_name : '')
      }

      // });
      item[t("materials.create_date")] = item["created_on"];

      item[t("address.Created User")] = item["created_by"];
      item[t("recipient.name")] =
        item["first_name"];
      item[t("recipient.Email")] = item["email"];

      item[t("recipient.Address")] = item["entity"];
      item["Approved"] = item["approved"];
      item["Submitted"] = item["submitted"];

      item[t("phase.Valid")] = item["valid"];
      if(!myrecipient) {
        item[t("recipient.review")] = item["review_by"];
      }
      
      // item[t("address.Department")] =  item["department"];

      delete item["id"];
      delete item["state"];
      delete item["address_id"];
      delete item["title_id"];
      delete item["approved"];
      delete item["email"];
      delete item["first_name"];
      delete item["form_of_address"];
      delete item["last_name"];
      delete item["phone"];
      delete item["submitted"];
      delete item["valid"];
      delete item["created_on"];
      delete item["created_by"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["is_active"];
      delete item["add_id"];
      delete item["country_id"];
      delete item["building"];
      delete item["city"];
      delete item["department"];
      delete item["entity"];
      delete item["postcode"];
      delete item["street"];
      delete item["sapnumber"];
      delete item["name_english"];
      delete item["sapnumber"];
      delete item["title"];
      delete item["name"];
      delete item["email"];
      delete item["review_by"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };

  const setSelectedRow = (data) => {
    setSelected(data);
    if (data) {
      sessionStorage.setItem("selectedApproveRecipientRow", data?.id);
    }
  };

  const { t, i18n } = useTranslation();

  // const handleInspectRecipient = () => {
  //   if (get(selected, "id", false)) {
  //     history.push(`/view/approve/recipient/${get(selected, "id")}`);
  //   }
  // };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };
  const ExportBtn = () => {
    return (
      <button
        className="mr-1"
        onClick={() => {

          setIsExportModalVisible(true);

        }}
      >
        {t("export.exportall")}
      </button>
    );
  };


  const ExportSelectedBtn = () => {
    let excelDataProperty = [
      { "displayName": "Title", "dbColumn": "title", "width": 20 },
      { "displayName": "First Name", "dbColumn": "first_name", "width": 20 },
      { "displayName": "Last Name", "dbColumn": "last_name", "width": 20 },
      { "displayName": "Email", "dbColumn": "email", "width": 50 },
      { "displayName": "Phone", "dbColumn": "phone", "width": 20 },
      { "displayName": "Company/University", "dbColumn": "entity", "width": 20 },
      { "displayName": "Department", "dbColumn": "department", "width": 20 },
      { "displayName": "Building", "dbColumn": "building", "width": 10 },
      { "displayName": "Street", "dbColumn": "street", "width": 10 },
      { "displayName": "Postal Code", "dbColumn": "postcode", "width": 10 },
      { "displayName": "City", "dbColumn": "city", "width": 10 },
      { "displayName": "State", "dbColumn": "state", "width": 20 },
      { "displayName": "Country", "dbColumn": "name_english", "width": 10 },
      { "displayName": "Created By", "dbColumn": "created_by", "width": 50 },
      { "displayName": "Create On", "dbColumn": "created_on", "width": 15 },
      { "displayName": "Updated By", "dbColumn": "updated_by", "width": 50 },
      { "displayName": "Updated On", "dbColumn": "updated_on", "width": 15 },
      { "displayName": "Valid", "dbColumn": "valid", "width": 10 },
    ];

    if (!myrecipient) {
      excelDataProperty.push({ "displayName": t("address.review"), "dbColumn": "review_by", "width": 50 })
    }

    actions.getSelectedRecipientData(selected.id).then((response) => {
      let dataForExport = [];
      dataForExport.push(response.data[0]);
      dataForExport = dateFormatter.formateDateField(dataForExport);
      excelExport.exportAllData(excelDataProperty, dataForExport, "Recipient_Data", requiredFileName);
    });
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={(selected === "")}
        onClick={(e) => {

          setIsExportModalVisibleSingle(true)


        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>



            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>


        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>



            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>


        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn()
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <>
      <div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
            <DropdownButton title={t("export.export")} className="mr-1 exportdropdown">
              <Dropdown.Item >{ExportBtn()}</Dropdown.Item>
              <Dropdown.Item >{ExportSelectedBtnForSingle()}</Dropdown.Item>

            </DropdownButton>

            {/* <TextField size="small" placeholder="Search" /> */}
          </div>
        </div>
      </div>
      <Card variant="outlined">
        <CardHeader
          title={t("approve_recipient.search_text")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
{!myrecipient ?
            <MaterialTable
              onPageChange={(page, itemsPerPage) => {
                // sessionStorage.setItem("selectedMaterialAdminPage", page);
                dispatchMaterialOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
              }}
              onOrderCollectionChange={(orderBy) => {
                dispatchMaterialOptions({ target: "sort" }, orderBy);
              }}
              columns={[
                {
                  width: "4%",
                  sorting: false,
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      //checked={rowData.id === selected?.id}
                      onChange={() => setSelectedRow(rowData)}
                    />
                  ),
                  // hidden: hideCheckBox,
                },
                {
                  title: <span>{t("materials.create_date")}</span>,
                  render: (rowData) => (
                    <span>{dateLocalization(rowData.created_on)}</span>
                  ),
                  customSort: dateSortFunction("created_on"),
                },
                {
                  title: <span>{t("materials.create_user")}</span>,
                  field: "created_by",
                  customSort: SortFunctionNew("created_by"),
                },
                {
                  title: <span>{t("recipient.name")}</span>,
                  render: (rowData) => (
                    <span>{rowData.first_name + " " + rowData.last_name}</span>
                  ),
                  customSort: (a, b) => {
                    if (
                      (a.first_name + a.last_name).toString().toLowerCase() >
                      (b.first_name + b.last_name).toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      (a.first_name + a.last_name).toString().toLowerCase() <
                      (b.first_name + b.last_name).toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                },

                {
                  title: <span>{t("recipient.Email")}</span>,
                  field: "email",
                  customSort: SortFunctionNew("email"),
                },
                {
                  title: <span>{t("recipient.Address")}</span>,
                  render: (rowData) => <>{new AddressUtil(rowData).toString()}</>,
                  customSort: (a, b) =>
                    new AddressUtil(a).compare(new AddressUtil(b)),
                },
                {
                  title: <span>{t("address.Approved")}</span>,
                  customSort: (a, b) => {
                    if (a.approved > b.approved) {
                      return -1;
                    } else if (a.approved < b.approved) {
                      return 1;
                    } else if (a.approved === b.approved) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.approved === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("materials.submitted")}</span>,
                  customSort: (a, b) => {
                    if (a.submitted > b.submitted) {
                      return -1;
                    } else if (a.submitted < b.submitted) {
                      return 1;
                    } else if (a.submitted === b.submitted) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.submitted === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("address.Valid")}</span>,
                  customSort: (a, b) => {
                    if (a.valid > b.valid) {
                      return -1;
                    } else if (a.valid < b.valid) {
                      return 1;
                    } else if (a.valid === b.valid) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.valid === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("recipient.review")}</span>,
                  field: "review_by",
                  hidden: myrecipient ? true : false,
                  customSort: SortFunctionNew("review_by"),
                },
                {
                  title: (
                    <span> {myrecipient ? t("commonlabels.delete") : ""} </span>
                  ),
                  align: "center",
                  cellStyle: {
                    padding: "0px",
                  },
                  sorting: false,
                  render: (rowData) =>
                    myrecipient ? (
                      rowData.submitted !== 1 ? (
                        <a
                          id="openModelForDelete"
                          onClick={(e) => {
                            setSelectedForDelete(rowData);
                          }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            alt="t"
                            className="dlt_icon"
                            src={deleteIcon}
                          ></img>{" "}
                        </a>
                      ) : (
                        <a id="openModelForDelete">
                          <img
                            alt="t"
                            className="dlt_icon"
                            src={gray_deleteIcon}
                          ></img>{" "}
                        </a>
                      )
                    ) : (
                      <Link
                        to={{
                          pathname: `/view/approve/recipient/${rowData.id}`,
                        }}
                        onClick={() => actions.saveTableData(rowData)}
                      >
                        <img alt="t" className="iconsize" src={editIcon}></img>
                      </Link>
                    ),
                },
              ]}
              data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                initialPage: parseInt(tableApproveRecipientOptions?.pageNumber ? tableApproveRecipientOptions?.pageNumber : 0),

                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: parseInt(tableApproveRecipientOptions?.pageSize ? tableApproveRecipientOptions?.pageSize : 10),
                defaultOrderByCollection: tableApproveRecipientOptions?.orderBy ? tableApproveRecipientOptions?.orderBy : "",

                pageSizeOptions: [10, 20, 30, 40,50],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
:
        <MaterialTable
        
        columns={[
          {
            width: "4%",
            sorting: false,
            render: (rowData) => (
              <input
                type="radio"
                value={get(selected, "id", "")}
                name="radiovalues"
                className={classes.tableRadio}
                //checked={rowData.id === selected?.id}
                onChange={() => setSelectedRow(rowData)}
              />
            ),
            // hidden: hideCheckBox,
          },
          {
            title: <span>{t("materials.create_date")}</span>,
            render: (rowData) => (
              <span>{dateLocalization(rowData.created_on)}</span>
            ),
            customSort: dateSortFunction("created_on"),
          },
          {
            title: <span>{t("materials.create_user")}</span>,
            field: "created_by",
            customSort: SortFunctionNew("created_by"),
          },
          {
            title: <span>{t("recipient.name")}</span>,
            render: (rowData) => (
              <span>{rowData.first_name + " " + rowData.last_name}</span>
            ),
            customSort: (a, b) => {
              if (
                (a.first_name + a.last_name).toString().toLowerCase() >
                (b.first_name + b.last_name).toString().toLowerCase()
              ) {
                return -1;
              } else if (
                (a.first_name + a.last_name).toString().toLowerCase() <
                (b.first_name + b.last_name).toString().toLowerCase()
              ) {
                return 1;
              } else {
                return 0;
              }
            },
          },

          {
            title: <span>{t("recipient.Email")}</span>,
            field: "email",
            customSort: SortFunctionNew("email"),
          },
          {
            title: <span>{t("recipient.Address")}</span>,
            render: (rowData) => <>{new AddressUtil(rowData).toString()}</>,
            customSort: (a, b) =>
              new AddressUtil(a).compare(new AddressUtil(b)),
          },
          {
            title: <span>{t("address.Approved")}</span>,
            customSort: (a, b) => {
              if (a.approved > b.approved) {
                return -1;
              } else if (a.approved < b.approved) {
                return 1;
              } else if (a.approved === b.approved) {
                return 0;
              } else {
                return 0;
              }
            },
            render: (rowData) =>
              rowData.approved === 1 ? (
                <span>
                  <div>
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      disabled
                    />
                  </div>
                </span>
              ) : (
                <div>
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        defaultChecked={false}
                        disabled
                      />
                    </div>
                  </span>
                </div>
              ),
          },
          {
            title: <span>{t("materials.submitted")}</span>,
            customSort: (a, b) => {
              if (a.submitted > b.submitted) {
                return -1;
              } else if (a.submitted < b.submitted) {
                return 1;
              } else if (a.submitted === b.submitted) {
                return 0;
              } else {
                return 0;
              }
            },
            render: (rowData) =>
              rowData.submitted === 1 ? (
                <span>
                  <div>
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      disabled
                    />
                  </div>
                </span>
              ) : (
                <div>
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        defaultChecked={false}
                        disabled
                      />
                    </div>
                  </span>
                </div>
              ),
          },
          {
            title: <span>{t("address.Valid")}</span>,
            customSort: (a, b) => {
              if (a.valid > b.valid) {
                return -1;
              } else if (a.valid < b.valid) {
                return 1;
              } else if (a.valid === b.valid) {
                return 0;
              } else {
                return 0;
              }
            },
            render: (rowData) =>
              rowData.valid === 1 ? (
                <span>
                  <div>
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      disabled
                    />
                  </div>
                </span>
              ) : (
                <div>
                  <span>
                    <div>
                      <input
                        type="checkbox"
                        defaultChecked={false}
                        disabled
                      />
                    </div>
                  </span>
                </div>
              ),
          },
          {
            title: <span>{t("recipient.review")}</span>,
            field: "review_by",
            hidden: myrecipient ? true : false,
            customSort: SortFunctionNew("review_by"),
          },
          {
            title: (
              <span> {myrecipient ? t("commonlabels.delete") : ""} </span>
            ),
            align: "center",
            cellStyle: {
              padding: "0px",
            },
            sorting: false,
            render: (rowData) =>
              myrecipient ? (
                rowData.submitted !== 1 ? (
                  <a
                    id="openModelForDelete"
                    onClick={(e) => {
                      setSelectedForDelete(rowData);
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      alt="t"
                      className="dlt_icon"
                      src={deleteIcon}
                    ></img>{" "}
                  </a>
                ) : (
                  <a id="openModelForDelete">
                    <img
                      alt="t"
                      className="dlt_icon"
                      src={gray_deleteIcon}
                    ></img>{" "}
                  </a>
                )
              ) : (
                <Link
                  to={{
                    pathname: `/view/approve/recipient/${rowData.id}`,
                  }}
                  onClick={() => actions.saveTableData(rowData)}
                >
                  <img alt="t" className="iconsize" src={editIcon}></img>
                </Link>
              ),
          },
        ]}
        data={(filteredData)}
        title="Render Image Preview"
        icons={tableIcons}
        localization={{
          pagination: {
            labelRowsSelect: t("commonlabels.labelRowsSelect"),
            firstAriaLabel: t("commonlabels.firstAriaLabel"),
            firstTooltip: t("commonlabels.firstTooltip"),
            previousAriaLabel: t("commonlabels.previousAriaLabel"),
            previousTooltip: t("commonlabels.previousTooltip"),
            nextAriaLabel: t("commonlabels.nextAriaLabel"),
            nextTooltip: t("commonlabels.nextTooltip"),
            lastAriaLabel: t("commonlabels.lastAriaLabel"),
            lastTooltip: t("commonlabels.lastTooltip"),
          },
          body: {
            emptyDataSourceMessage: t(
              "commonlabels.emptyDataSourceMessage",
            ),
          },
        }}
        options={{
          draggable: false,
          thirdSortClick: false,
          //  actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#cce3f5",
            fontWeight: "bold",
            height: "10px",
          },
          rowStyle: tableRowFormatter({
            height: "30px",
            padding: "0px",
          }),
          sortIcon: true,
          maxBodyHeight: "90%",
          tableLayout: "auto",
          sorting: true,
          search: false,
          selection: false,
          showTitle: false,
          toolbar: false,
          paging: true,
          paginationType: "stepped",
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 30, 40],
          emptyRowsWhenPaging: false,
          showSelectAllCheckbox: false,
        }}
      />
}
     
          </div>
        </CardContent>
      </Card>

      <ActionConfirmModal
        show={!!selectedForDelete}
        headerText={t("approve_recipient.delete_confirm_header")}
        bodyText={t("approve_recipient.disapprove_confirm_body")}
        confirmButtonText={t("approve_recipient.yes")}
        cancelButtonText={t("approve_recipient.no")}
        onConfirm={() => {
          onDeleteConfirm();
        }}
        onCancel={() => {
          setSelectedForDelete(false);
        }}
      />
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </>
  );
};

export default RecipientsListingComponent;
