import React from "react";

import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

const CustomSelect = (props) => {

  const {
    labelName = " ",
    list = [],
    value = "",
    onChange,
    className,
    isDisabled = false,
    name = "select",
    keyname = "name",
    classes = "",
    SearchFor
  } = props;
  return (
    <>
      <TextField
        size="small"
        label={labelName}
        sx={(SearchFor && SearchFor === 'regulatory_reviewer')?{width:'50%'}:{width:'100%'}}
        select
        value={value}
        placeholder={labelName}
        onChange={onChange}
        disabled={isDisabled}
        name={name}
        SelectProps={{
          MenuProps : {PaperProps: {sx: {maxHeight: 300}}}
        }}
      >
        {list.map((obj) => (
          <MenuItem  className={className} key={obj.id} value={obj.id}>
            {obj[keyname]}
          </MenuItem>
        ))}

      </TextField>
    </>
  );
};

export default CustomSelect;
