import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import currencyComponent from "./Currency";


const {
  getTitleData,
  saveTitleData,
  updateTitleAccess,
  getUnits,
  saveUnitData,
  updateUnitAccess,
  getCurrencyList,
  updateCurrencyAccess,
  saveCurrency,
} = title.actions;

const mapStateToProps = (state) => ({
  titleData: state[title.name].titleData,
  isLoader: state[title.name].isLoader,
  unitData: state[title.name].unitData,
  currenctData: state[title.name].currenctData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getTitleData,
      saveTitleData,
      updateTitleAccess,
      getUnits,
      saveUnitData,
      updateUnitAccess,
      getCurrencyList,
      updateCurrencyAccess,
      saveCurrency,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(currencyComponent);
