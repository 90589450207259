import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Link, useHistory, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ReactTooltip from "react-tooltip";
import infoIcon from "../../../assests/images/infoIcon.svg";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useTranslation } from "react-i18next";
import AutocompleteText from "../../common/AutocompleteText";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from "@mui/material/Checkbox";

import materialsRedux from "../../../redux/materials";
import materialService from "../../../Services/materialService";
import { authContext } from "../../../adalConfig";
import * as config from "../../../config";
import { materialComponentStyles } from "./materialStyles";
import LoadingOverlay from "react-loading-overlay";
import Model from "../../shared/model";

export function ViewApprovalMaterial(props) {
  const { materialDetails, actions } = props;
  const [state, setState] = useState({});
  const { t } = useTranslation();
  const history = useHistory();
  const { materialId } = useParams();
  const [confirmActionPopup, setConfirmActionPopup] = useState(false);
  const { instance } = useMsal();
  const [existingdocuments, setExistingDocuments] = useState([]);
  const userId = instance.getActiveAccount().username;
  const [reviewBy, setReviewBy] = useState("");
  const [checkboxState, setCheckBoxState] = useState(false);
  const classes = materialComponentStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [rejectComments, setRejectComments] = useState("");

  useEffect(() => {
    if (materialId) {      
      if(materialDetails !== undefined && materialDetails.length > 0 ){
      actions.getAllMaterials().then((res) => {
        const selectedMaterial = res?.data.find(
        (item) => item.id === materialId,
      );     
      setState(selectedMaterial);
      setReviewBy(selectedMaterial?.review_by);
      setCheckBoxState(selectedMaterial?.review_by ? true : false);
      setIsLoading(false);
        })
      }else{      
        getAllMaterials();
     }

     async function getMaterialAttachments(){
     let data = await materialService.getMaterialAttachments(materialId);
      setExistingDocuments(data);
     }

     async function getAllMaterials(){
      await actions.getAllMaterials().then((res) => {
        const response = res.data.filter(
          (x) => x.id === materialId,
        );
        let selectedMaterial = response[0];
      setState(selectedMaterial);
      setReviewBy(selectedMaterial?.review_by);
      setCheckBoxState(selectedMaterial?.review_by ? true : false);
      setIsLoading(false);
       });
      }
     getMaterialAttachments();
    }   
  }, []);

  const cancelAction = async () => {
    actions.getAllMaterials();
    history.push("/approve/materials");
  };
  // useEffect(async () => {
  //   if (materialId) {
  //     let data = await materialService.getMaterialAttachments(materialId);
  //     setExistingDocuments(data);
  //   }
  // }, []);
  
  const materialApprovalAndValidUpdate = async (data) => {
    if(data.approved || rejectComments.length > 0) {
      setConfirmActionPopup(false);
      setIsLoading(true);
      try {
        if (state?.id) {
          const materialData = {
            id: state?.id,
            isReject: data.approved === 0 ? true : false,
            createdBy: state.created_by,
            comment: rejectComments,
            rejectingUser: userId,
            approved: data.approved,
            materialName: state.description
          };
          await materialService.materialApprovalUpdate(state.id, materialData).then(() => {
            actions.getAllMaterials().then(() => {
              if(data.approved)
                toast.success(t("approve_materials.material_approved"));
              else
                toast.success(t("approve_materials.material_rejected"));
              setIsLoading(false);
              history.push("/approve/materials");
            });            
          });
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
        history.push("/approve/materials");
      }
    }
  };

  const handleUnderReviewCheckBox = async (event) => {
    let materialData = {
      id: materialId,
      name: "",
    };
    let underReviewMessage = t("material.reviewReset");

    if (event.target.checked) {
      materialData.name = userId;
      underReviewMessage = t("material.reviewSet");
    }

    setCheckBoxState(event.target.checked);
    setReviewBy(materialData.name);
    await materialService
      .updateApproveMaterialReviewStatus(materialData)
      .then((response) => {
        if (response.IsSuccess) {
          toast.success(underReviewMessage);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const modalBody = () => {
    return (
      <>
        <div className="row align-items-center">
          <div className="col-lg-12">
              <div className="form-group">
                  <label>{t("approve_materials.disapprove_confirm_body")}</label>
              </div>
              <div className="col-lg-12 p-0">
                  <div className="form-group">
                      <label className="">
                          {" "}
                          <b>{t("comments_alert.comments")}<span className="req">*</span></b>{" "}
                      </label>
                      <TextareaAutosize
                          id="rejectComments"
                          name="rejectComments"
                          aria-label="rejectComments"
                          margin="normal"
                          variant="outlined"
                          size="large"
                          minRows={5}
                          style={{ width: "100%" }}
                          value={rejectComments.length === 0 ? "" : rejectComments}
                          onChange={(e) => { 
                              e.preventDefault()
                              setRejectComments(e.target.value)
                          }}
                      />
                  </div>
              </div>
          </div>
        </div>
      </>
    );
  }

  const modalFooterEditMethod = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            materialApprovalAndValidUpdate({ approved: 0 });
          }}
        >
          {t("commonlabels.yesButton")}
        </button>
        <button
          type="button"
          className="btn btn-black btn-inner-close"
          onClick={(e) => setConfirmActionPopup(false)}
        >
          {t("commonlabels.noButton")}
        </button>
      </>
    );
  }

  const CheckboxField = (props) => {
    return (
      <div className="d-flex">
        <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={checkboxState}
          {...props}
        />
        <label className={classes.checkBoxLabel}>{props.label}</label>
      </div>
    );
  };

  return (
    <>
    <LoadingOverlay active={isLoading} spinner>
      <div className="title_main ml-3 pt10">{t("Country.view_material")}</div>
      <div className="col-12 p10">
        <Card variant="outlined">
          <CardContent>
            <div className="container-fluid ">
              <div className="row col-12 mt-2">
                <ItemContainer>
                  <div>
                    <AutocompleteText
                      label={t("material.materialName")}
                      name="description"
                      options={[]}
                      value={state.description || " "}
                      readOnly={true}
                    />
                  </div>
                </ItemContainer>
                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.hazard_class")}
                    name="hazard_class"
                    options={[]}
                    value={state.hazard_class || " "}
                    readOnly={true}
                  />
                </ItemContainer>

                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.hazard_inducers")}
                    name="hazard_phrase"
                    options={[]}
                    value={state.hazard_phrase || " "}
                    readOnly={true}
                  />
                </ItemContainer>

                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.material_number")}
                    name="material_number"
                    options={[]}
                    value={state.material_number || " "}
                    readOnly={true}
                  />
                </ItemContainer>
              </div>
              <div className="row col-12">
                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.tariff_classification_no_germany")}
                    name="statistical_material_number_grmny"
                    options={[]}
                    value={state.statistical_material_number_grmny || " "}
                    readOnly={true}
                  />
                </ItemContainer>

                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.tariff_classification_no_china")}
                    name="statistical_material_number_china"
                    options={[]}
                    value={state.statistical_material_number_china || " "}
                    readOnly={true}
                  />
                </ItemContainer>
                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.tariff_classification_no_usa")}
                    name="statistical_material_number_usa"
                    options={[]}
                    value={state.statistical_material_number_usa || " "}
                    readOnly={true}
                  />
                </ItemContainer>
                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.eid")}
                    name="eid"
                    options={[]}
                    value={state.eid || " "}
                    readOnly={true}
                  />
                </ItemContainer>
              </div>

              <div className="row col-12">

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("shipping_log_fields.is_hazardous")}</b>{" "}
                      </label>{" "}
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="is_hazardous"
                        value={true}
                        disabled
                        checked={state.is_hazardous == true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="is_hazardous"
                        value={false}
                        disabled
                        checked={state.is_hazardous == false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>
                <ItemContainer>
                  <AutocompleteText
                    label={t("materials.unNo")}
                    name="un_no"
                    options={[]}
                    value={state.un_no || " "}
                    readOnly={true}
                  />
                </ItemContainer>
                <ItemContainer>
                  <AutocompleteText
                    label={t("material.Class_Sub")}
                    name="transport_class"
                    options={[]}
                    value={state.transport_class || " "}
                    readOnly={true}
                  />
                </ItemContainer>
                <ItemContainer>
                  <AutocompleteText
                    label={t("material.pck_grp")}
                    name="pack_group"
                    options={[]}
                    value={state.pack_group || " "}
                    readOnly={true}
                  />
                </ItemContainer>
              </div> 
              <div className="row col-12">
              <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("shipping_log_fields.hazardous_sds")}</b>{" "}
                      </label>{" "}
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="hazardous_sds"
                        value={true}
                        disabled
                        checked={state.hazardous_sds == true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="hazardous_sds"
                        value={false}
                        disabled
                        checked={state.hazardous_sds == false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("material.contains_der_biologic")}</b>{" "}
                        <img
                          alt=""
                          style={{ width: "15px" }}
                          src={infoIcon}
                          className=""
                          data-for="1"
                          data-tip={t("material.contains_der_biologic_tooltip")}
                          data-html={true}
                          data-place="bottom"
                        />
                        <ReactTooltip
                          id="1"
                          className=""
                          data-html={true}
                        ></ReactTooltip>
                      </label>{" "}
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_der_biologic"
                        value={true}
                        disabled
                        checked={state.contains_der_biologic == true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_der_biologic"
                        value={false}
                        disabled
                        checked={state.contains_der_biologic == false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label>
                        <b>{t("material.contains_animal_byproduct")}</b>{" "}
                        <img
                          alt=""
                          style={{ width: "15px" }}
                          src={infoIcon}
                          className=""
                          data-for="1"
                          data-tip={t("material.contains_animal_byproduct_tooltip")}
                          data-html={true}
                          data-place="bottom"
                        />
                        <ReactTooltip
                          id="1"
                          className=""
                          data-html={true}
                        ></ReactTooltip>
                      </label>
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_animal_byproduct"
                        value={true}
                        disabled
                        checked={state.contains_animal_byproduct == true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_animal_byproduct"
                        value={false}
                        disabled
                        checked={state.contains_animal_byproduct == false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label>
                        <b>
                          {t("material.contains_non_domestic_animal_byproduct")}
                        </b>{" "}
                        <img
                          alt=""
                          style={{ width: "15px" }}
                          src={infoIcon}
                          className=""
                          data-for="1"
                          data-tip={t(
                            "material.contains_non_domestic_animal_byproduct_tooltip",
                          )}
                          data-html={true}
                          data-place="bottom"
                        />
                        <ReactTooltip
                          id="1"
                          className=""
                          data-html={true}
                        ></ReactTooltip>
                      </label>
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_non_domestic_animal_byproduct"
                        value={true}
                        disabled
                        checked={
                          state.contains_non_domestic_animal_byproduct === true
                        }
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_non_domestic_animal_byproduct"
                        value={false}
                        disabled
                        checked={
                          state.contains_non_domestic_animal_byproduct === false
                        }
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>

                
              </div>
              <div className="row col-12">
              <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label>
                        <b>{t("material.contains_infect_substance")}</b>{" "}
                        <img
                          alt=""
                          style={{ width: "15px" }}
                          src={infoIcon}
                          className=""
                          data-for="1"
                          data-tip={t(
                            "material.contains_infect_substance_tooltip",
                          )}
                          data-html={true}
                          data-place="bottom"
                        />
                        <ReactTooltip
                          id="1"
                          className=""
                          data-html={true}
                        ></ReactTooltip>
                      </label>
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_infect_substance"
                        value={true}
                        disabled
                        checked={state.contains_infect_substance === true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_infect_substance"
                        value={false}
                        disabled
                        checked={state.contains_infect_substance === false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label>
                        <b>{t("material.contains_antibiotic")}</b>
                      </label>
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_antibiotic"
                        value={true}
                        disabled
                        checked={state.contains_antibiotic === true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_antibiotic"
                        value={false}
                        disabled
                        checked={state.contains_antibiotic === false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label>
                        <b>{t("material.contains_pesticide")}</b>
                      </label>
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_pesticide"
                        value={true}
                        disabled
                        checked={state.contains_pesticide === true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_pesticide"
                        value={false}
                        disabled
                        checked={state.contains_pesticide === false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label>
                        <b>{t("material.contains_parasiticide")}</b>
                      </label>
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="contains_parasiticide"
                        value={true}
                        disabled
                        checked={state.contains_parasiticide === true}
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="contains_parasiticide"
                        value={false}
                        disabled
                        checked={state.contains_parasiticide === false}
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                </ItemContainer>
              </div>
              <div className="row col-12">
                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.full_description")}</b>{" "}
                      <img
                        alt=""
                        style={{ width: "15px" }}
                        src={infoIcon}
                        className=""
                        data-for="1"
                        data-tip={t("material.full_description_tooltip")}
                        data-html={true}
                        data-place="bottom"
                      />
                      <ReactTooltip
                        id="1"
                        className=""
                        data-html={true}
                      ></ReactTooltip>
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="full_description"
                    name="full_description"
                    aria-label="full_description"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={
                      state.full_description === null ? "" : state.full_description
                    }
                    disabled
                  />
                </ItemContainer>

                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.fda_product_code")}</b>{" "}
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="fda_product_code"
                    name="fda_product_code"
                    aria-label="fda_product_code"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={
                      state.fda_product_code === null ? "" : state.fda_product_code
                    }
                    disabled
                  />
                </ItemContainer>

                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.fda_processing_program")}</b>{" "}
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="fda_processing_program"
                    name="fda_processing_program"
                    aria-label="fda_processing_program"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={
                      state.fda_processing_program === null
                        ? ""
                        : state.fda_processing_program
                    }
                    disabled
                  />
                </ItemContainer>

                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>SPI</b>{" "}
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="sli"
                    name="sli"
                    aria-label="sli"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={state.sli === null ? "" : state.sli}
                    disabled
                  />
                </ItemContainer>
              </div>
              <div className="row col-12">
                <ItemContainer sm="3">
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.review")}</b>{" "}
                    </label>{" "}
                  </div>
                  <CheckboxField
                    id="review_by"
                    name="review_by"
                    className="check_box"
                    label={reviewBy ? reviewBy : ""}
                    onChange={(e) => handleUnderReviewCheckBox(e)}
                  />
                </ItemContainer>
              </div>
            </div>
            <div className="row ml15 mr15 mlr15">
              <div className="col-12   heading_box">
                {t("material.View_Documents")}
              </div>

              {existingdocuments.length === 0 ? (
                <table id="" className="editmaterialtable">
                  {" "}
                  <tr>
                    <th>{t("material.file_name")}</th>
                    <th>{t("shipping_request.Comments")}</th>
                  </tr>
                  <tbody>
                    <tr>{t("commonlabels.emptyDataSourceMessage")}</tr>
                  </tbody>
                </table>
              ) : (
                <div>
                  {/* {documents.length > 0 ? ( */}
                  <div>
                    <table id="">
                      <tr>
                        <th>{t("material.file_name")}</th>
                        <th>{t("shipping_request.Comments")}</th>
                      </tr>
                      {existingdocuments.map((val, key) => {
                        return (
                          <>
                            <tbody>
                              <tr key={key}>
                                <td>
                                  {
                                    <a
                                      href={
                                        config.baseURL +
                                        "/api/downloadFile/" +
                                        val.id +
                                        "/Material_MasterTable"
                                      }
                                    >
                                      {val.file_name}
                                    </a>
                                  }
                                </td>
                                <td>{val.comments}</td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })}
                    </table>
                  </div>

                  {/* ) : ("")} */}
                </div>
              )}
            </div>
          </CardContent>
          <CardActions>
            <div className=" col-12 pr-0 text-right ">
              <button
                className="btn btn-secondary new_button mr-2"
                onClick={cancelAction}
              >
                {t("materials.back")}
              </button>
              <Link to={`/material/edit/${state.id}`}>
                <button className="btn btn-primary new_button mr-2">
                  {t("materials.edit")}
                </button>
              </Link>

              <button
                className="btn btn-success new_button mr-2"
                onClick={() =>
                  materialApprovalAndValidUpdate({ approved: 1, userId })
                }
              >
                {t("materials.approve")}
              </button>

              <button
                className="btn btn-primary new_button mr-2"
                onClick={() => setConfirmActionPopup(true)}
              >
                {t("materials.deny_approval")}
              </button>
            </div>
          </CardActions>
        </Card>
      </div>
      <Model
        showheader={confirmActionPopup}
        modalHeader={t("approve_materials.disapprove_confirm_header")}
        modalbody={modalBody()}
        modalfooter={modalFooterEditMethod()}
      ></Model>
      </LoadingOverlay>
    </>
  );
}

const mapStateToProps = (state) => ({
  materialDetails: state.materials.allMaterials,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllMaterials: materialsRedux.actions.getAllMaterials,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewApprovalMaterial);

const ItemContainer = ({ children }) => {
  return <div className="col-12 col-sm-3 form_item">{children}</div>;
};
