import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";

import Grid from "@mui/material/Grid";

const DateField = (props) => {
  const { t, i18n } = useTranslation();
  registerLocale("de", de);

  return (
    <DatePicker
      locale={i18n.resolvedLanguage}
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
      margin="normal"
      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      portalId="111"
      selected={
        props.state[props.name] ? new Date(props.state[props.name]) : ""
      }
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />
  );
};

const AccordionData = ({ state, dispatch }) => {
  const { t, i18n } = useTranslation();

  const CheckboxField = (props) => {
    return (
      <Grid item xs={12}>
        <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={props.state[props.name]}
          {...props}
        />
        <label>{props.label}</label>
      </Grid>
    );
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("shippingrequest.periodqueries")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <ItemContainerDate>
              <DateField
                placeholderText={t("shippingrequest.shipment(mohnhiem)")}
                name="shipmentDateMomStart"
                id="shipmentDateMomStart"
                onChange={(date) =>
                  dispatch({
                    target: { name: "shipmentDateMomStart", value: date },
                  })
                }
                state={state}
              />
            </ItemContainerDate>
            <ItemContainerDate>
              <DateField
                placeholderText={t("shippingrequest.shipmenttill(mohnhiem)")}
                name="shipmentDateMomEnd"
                id="shipmentDateMomEnd"
                onChange={(date) =>
                  dispatch({
                    target: { name: "shipmentDateMomEnd", value: date },
                  })
                }
                state={state}
              />
            </ItemContainerDate>
            {/* shipment date lev */}
            <ItemContainerDate>
              <DateField
                placeholderText={t("shippingrequest.shipmentfrom(Leverkusen)")}
                name="shipmentDateLevStart"
                id="shipmentDateLevStart"
                onChange={(date) =>
                  dispatch({
                    target: { name: "shipmentDateLevStart", value: date },
                  })
                }
                state={state}
              />
            </ItemContainerDate>
            <ItemContainerDate>
              <DateField
                placeholderText={t("shippingrequest.Shipment(Leverkusen)till")}
                name="shipmentDateLevEnd"
                id="shipmentDateLevEnd"
                onChange={(date) =>
                  dispatch({
                    target: { name: "shipmentDateLevEnd", value: date },
                  })
                }
                state={state}
              />
            </ItemContainerDate>
          </div>
          <div className="row">
            {/* shipment date mom */}
            <ItemContainerDate>
              <DateField
                placeholderText={t("shippingrequest.deliveredfrom")}
                name="dateOfRecipientStart"
                id="dateOfRecipientStart"
                onChange={(date) =>
                  dispatch({
                    target: { name: "dateOfRecipientStart", value: date },
                  })
                }
                state={state}
              />
            </ItemContainerDate>
            <ItemContainerDate>
              <DateField
                placeholderText={t("shippingrequest.delveredtill")}
                name="dateOfRecipientEnd"
                id="dateOfRecipientEnd"
                onChange={(date) =>
                  dispatch({
                    target: { name: "dateOfRecipientEnd", value: date },
                  })
                }
                state={state}
              />
            </ItemContainerDate>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>{t("shippingrequest.metaqueries")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            {/* <ItemContainer>
              <TextField
                id="id"
                name="id"
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("phase.id")}
                onChange={dispatch}
                value={state["id"]}
              />
            </ItemContainer> */}
            {/* <ItemContainer>
              <TextField
                name="id_old"
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("shippingrequest.id(old)")}
                onChange={dispatch}
                value={state["id_old"]}
              />
            </ItemContainer>
            <ItemContainer>
              <TextField
                margin="normal"
                variant="outlined"
                name="id_request_old"
                id="id_request_old"
                size="small"
                placeholder={t("shippingrequest.request(old)")}
                onChange={dispatch}
                value={state["id_request_old"]}
              />
            </ItemContainer> */}
            <ItemContainer>
              <TextField
                id="created_by"
                name="created_by"
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("phase.createuser")}
                value={state["created_by"]}
                onChange={dispatch}
              />
            </ItemContainer>

            <div className="row col-12 col-sm-2 pr-0 ">
              <div>
                {" "}
                <CheckboxField
                  id="valid"
                  name="valid"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  onChange={dispatch}
                  state={state}
                />
              </div>
              <div className=""> {t("phase.Valid")}</div>
            </div>
            <ItemContainer>
              <DateField
                placeholderText={t("shippingrequest.create_date_from")}
                name="startDate"
                id="startDate"
                onChange={(date) =>
                  dispatch({
                    target: { name: "startDate", value: date },
                  })
                }
                state={state}
              />
            </ItemContainer>
            <ItemContainer>
              <DateField
                placeholderText={t("shippingrequest.create_date_to")}
                name="endDate"
                id="endDate"
                onChange={(date) =>
                  dispatch({
                    target: { name: "endDate", value: date },
                  })
                }
                state={state}
              />
            </ItemContainer>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const ItemContainer = ({ children }) => {
  return <div className="col-12 col-sm-2">{children}</div>;
};

const ItemContainerDate = ({ children }) => {
  return <div className="col-12 col-sm-3">{children}</div>;
};

export default AccordionData;
