import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEng from "../src/Translations/english.json";
import translationGer from "../src/Translations/german.json";
import translationSpn from "../src/Translations/spanish.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: translationEng
            },
            de: {
                translations: translationGer
            },
            sp: {
                translations: translationSpn
            }
        },
        fallbackLng: "en",
        debug: true,
        preload: "en",

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

       // keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        },

    });

export default i18n;
