import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import importerOfRecordComponent from "./ImporterOfRecord";

const { getImpoterList, saveImpoter, updateImpoterAccess } = title.actions;

const mapStateToProps = (state) => ({
  isLoader: state[title.name].isLoader,
  importer: state[title.name].importer,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getImpoterList,
      saveImpoter,
      updateImpoterAccess,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(importerOfRecordComponent);
