import React, { useEffect } from "react";
import { Checkbox } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { NEW, USERROLES } from "../../constants"
import { useStyles } from "../common/ThemeStye";
import CustomDate from "../common/CustomDate";
import {
  INSPECT,
  EDIT,
  HAND_BACK,
  STATUS,
  SUBMIT,
  CANCEL_REQUEST,
  CLOSE,
} from "../../constants";
import StatusInformationCard from "./preview/StatusInformationCard";
import ContactInfoCard from "./preview/ContactInfoCard";
import { useTranslation } from "react-i18next";
import {DateFormat} from "../common/utils/DateUtil";
import _ from "lodash";
import RequiredAstrick from "../common/RequiredAstrick/RequiredAstrick";
import { useHistory } from "react-router-dom";
import AutoCompleteReadonlyText from "../common/AutoCompleteReadonlyText";
import { toast } from "react-toastify";


const StatusInformation = (props) => {
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const history = useHistory();

  
  const {
    shippingRequestDetails: [
      { status, created_by, created_on, updated_by, updated_on },
    ],

    handleDispatchMON,
    handleDispatchLEV,
    handleDateOfReceipt,
    handleTrackingNum,
    handleSapDeliveryNum,
    dispatchMONValue,
    dispatchLEVValue,
    dateOfReceiptValue,
    trackingNumValue,
    sapDeliveryNumValue,
    shippingActionType,
    actions,
    extra,
    getShippingResponse,
    shippingRequestDetails,
    handleUnderReviewCheckBox,
    reviewBy,
    checkboxState,
    logisticPartnerDictionaryData,
    clientTimeZone
  } = props;

  useEffect(() => {
    if(shippingActionType === EDIT && status === STATUS.READY_FOR_DELIVERY) {
        if(extra.logistics_partner && extra.logistics_partner.length > 0) {
          const isFound = logisticPartnerDictionaryData.some(element => {
            if (element.logistics_partner === extra.logistics_partner) {
              return true;
            }

            return false;
          });

          if (!isFound) {
            toast.error(t("logisticsPartner.notMatchToDictionaryValue"));
          }
        }
      }
  }, [extra.logistics_partner]);

const logpart=logisticPartnerDictionaryData.find((obj)=>obj.logistics_partner === extra.logistics_partner)

const islogpartvalid = (obj) =>{
  if(obj?.is_active == null) return true
  if(obj?.is_active == true) return true
  return false;
}

  const userType = JSON.parse(localStorage.getItem("userType"));
  const requiredFields = {
    logistics_partner: (status === STATUS.READY_FOR_DELIVERY),
    tracking_number: (status === STATUS.READY_FOR_DELIVERY),
    dispatch_date_lev: (status === STATUS.READY_FOR_DELIVERY),
    dispatch_date_mon: (status === STATUS.READY_FOR_DELIVERY),
    date_of_receipt: (status === STATUS.READY_FOR_DELIVERY)
  };

  if (shippingActionType === INSPECT) {
    return <StatusInformationCard {...props} />;
  }

  if (shippingActionType === SUBMIT && status === STATUS.INPUT) {
    return <ContactInfoCard {...props} />;
  }

  if (
    (shippingActionType === EDIT || shippingActionType === NEW) &&
    ((status === STATUS.INPUT))
  ) {
    return <></>;
  }

  if (
    shippingActionType === HAND_BACK ||
    shippingActionType === CANCEL_REQUEST ||
    shippingActionType === CLOSE
  ) {
    return (
      <>
      <div className="row">
        <div className="col-12 statusinfo">
      <ContactInfoCard shippingActionType={shippingActionType} {...props} />
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography className="heading_box">
                  {" "}
                  {t("shippingrequest.status_information")}{" "}
                </Typography>
              }
              className={classes.cardHeader}
            />
            <CardContent>
              <div className="row">
                <div className="col-3">
                  <label className="col-form-label">
                    {t("shippingrequest.status")}:
                  </label>{" "}
                  {t("status." + status)}
                </div>

                <div className="col-3" style={{ display: "none" }}>
                  <label className="col-form-label">
                    {t("shippingrequest.sapnumber")}:
                  </label>
                  <TextField
                    disabled={
                      !(
                        userType.includes(USERROLES.PROCESSMANAGER) ||
                        userType.includes(USERROLES.APPLICANT) ||
                        userType.includes(USERROLES.ADMIN)
                      )
                    }
                    id="internal order id"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    value={sapDeliveryNumValue}
                    onChange={handleSapDeliveryNum}
                  />
                </div>

                <div className="col-3">
                  <label className="col-form-label">
                    {t("shippingrequest.internalorderid")}:
                  </label>
                  <TextField
                    id="internal_order_id"
                    name="internal_order_id"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    value={
                      getShippingResponse[0]?.internal_distribution_number ||
                      shippingRequestDetails[0]?.internal_distribution_number ||
                      ""
                    }
                    disabled={true}
                  />
                </div>

                <div className={"col-3 logisticsname " + (dateOfReceiptValue? !extra?.logistics_partner ? "importantSection":'': '')}>
                  <label className="col-form-label">
                    {t("shippingrequest.logistics_partner")}
                    <RequiredAstrick required={dateOfReceiptValue? !extra?.logistics_partner ? true:false: false } />
                    :
                  </label>

                  <AutoCompleteReadonlyText 
                    id="logistics_partner"
                    name="logistics_partner"
                    value={extra?.logistics_partner || ""}
                    label={""}
                      readOnly={true}
                    options={_([
                      ...logisticPartnerDictionaryData.filter((item)=>{return item.is_active}).map(
                        (item) => item.logistics_partner
                      ),
                    ])
                    .uniq()
                    .filter()
                    .sort()
                    .value()
                  }
                    placeholderText = {""}
                    is_ReadOnlyText = {true}
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>

                <div className={"col-3 "+(dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ?"importantSection":'':'')}>
                  <label className="col-form-label">
                    {t("shippingrequest.dispatchdate(mon)")}
                    <RequiredAstrick required={dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ? true:false: false } />
                  </label>
                  <CustomDate
                    disabled={
                        true
                      }
                      readOnly={true}
                    handleDateChange={(date) => {
                      actions.setClientTimeZone({
                        ...clientTimeZone,["dispatchMONValue_string"]:new Date(date).toString()
                      })
                      handleDispatchMON(date)
                    }}
                    dateValue={dispatchMONValue}
                    classes={classes}
                    id="dispatch_date_mon"
                  />
                </div>

                <div className={"col-3 "+(dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ?"importantSection":'':'')}>
                  <label className="col-form-label">
                    {t("shippingrequest.dispatchdate(lev)")}
                    <RequiredAstrick required={dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ? true:false: false } />
                  </label>
                  <CustomDate
                    disabled={
                        true
                    }
                    handleDateChange={(date) => {
                      actions.setClientTimeZone({
                        ...clientTimeZone,["dispatchLEVValue_string"]:new Date(date).toString()
                      })
                      handleDispatchLEV(date)
                    }}
                    dateValue={dispatchLEVValue}
                    classes={classes}
                    id="dispatch_date_lev"
                      readOnly={true}
                  />
                </div>

                <div className={"col-3 "}>
                  <label className="col-form-label">
                    {t("shipping_request.date_of_receipt")}
                    
                  </label>
                  <CustomDate
                    disabled={
                        true
                    }
                    handleDateChange={(date) => {
                      actions.setClientTimeZone({
                        ...clientTimeZone,["dateOfReceiptValue_string"]:new Date(date).toString()
                      })
                      handleDateOfReceipt(date)
                    }}
                    dateValue={dateOfReceiptValue}
                    classes={classes}
                    id="date_of_receipt"
                    readOnly={true}
                  />
                </div>

                <div className="col-3">
                  <label className="col-form-label">
                    {t("shipping_request.tracking_number")}
                  </label>
                  <TextField
                    disabled={
                       true
                    }
                    id="tracking_number"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    value={trackingNumValue || ""}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="row pt10">
        <div className="col-12 statusinfo">
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography className="heading_box">
                  {" "}
                  {t("shippingrequest.Meta_information")}{" "}
                </Typography>
              }
              className={classes.cardHeader}
            />
            <CardContent>
              <div className="row col-12" >
                <div className="col-3" style={{border:(shippingRequestDetails[0].createuseractive == false)?"3px solid orange":''}}>
                  <label className="col-form-label">
                    &nbsp;{t("phase.createuser")}:&nbsp;
                  </label>
                  <label style={{overflowWrap:'break-word'}}>{created_by}</label>                
                </div>
                <div className="col-3">
                  <label className="col-form-label">
                    &nbsp;{t("phase.createdate")}:&nbsp;
                  </label>

                  {DateFormat(
                    created_on,
                    i18n.resolvedLanguage,
                    i18n.resolvedLanguage
                  )}
                </div>
                <div className="col-3" style={{border:(shippingRequestDetails[0].edituseractive == false)?"3px solid orange":''}}>
                  <label className="col-form-label">
                    &nbsp;{t("process_shipping_request.edit_user")}:&nbsp;
                  </label>
                  <label style={{overflowWrap:'break-word'}}>{updated_by}</label>
                </div>
                <div className="col-3">
                  <label className="col-form-label">
                    &nbsp;{t("process_shipping_request.edit_date")}:&nbsp;
                  </label>

                  {DateFormat(
                    updated_on,
                    i18n.resolvedLanguage,
                    i18n.resolvedLanguage
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <br></br>
    </>
    );
  }

  const CheckboxField = (props) => {
    return (
      <div className="d-flex">
       <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={checkboxState}
           {...props}
        />
        <label>{props.label}</label>
      </div>
    );
  };
  return(
    <>
    <div className="row">
      <div className="col-12 statusinfo">
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography className="heading_box">
                {" "}
                {t("shippingrequest.status_information")}{" "}
              </Typography>
            }
            className={classes.cardHeader}
          />
          <CardContent>
            <div className="row">
              <div className="col-3">
                <label className="col-form-label">
                  {t("shippingrequest.status")}:
                </label>{" "}
                {t("status." + status)}
              </div>

              <div className="col-3" style={{ display: "none" }}>
                <label className="col-form-label">
                  {t("shippingrequest.sapnumber")}:
                </label>
                <TextField
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )
                  }
                  id="internal order id"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={sapDeliveryNumValue}
                  onChange={handleSapDeliveryNum}
                />
              </div>

              <div className="col-3">
                <label className="col-form-label">
                  {t("shippingrequest.internalorderid")}:
                </label>
                <TextField
                  id="internal_order_id"
                  name="internal_order_id"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={
                    getShippingResponse[0]?.internal_distribution_number ||
                    shippingRequestDetails[0]?.internal_distribution_number ||
                    ""
                  }
                  disabled={true}
                />
              </div>

                 <div className={"col-3 logisticsname " + (dateOfReceiptValue? !extra?.logistics_partner ? "importantSection":'': '')}style={{border:(islogpartvalid(logpart))?"":"3px solid orange"}}>
                <label className="col-form-label">
                  {t("shippingrequest.logistics_partner")}
                    <RequiredAstrick required={dateOfReceiptValue? !extra?.logistics_partner ? true:false: false } />
                  :
                </label>

                <AutoCompleteReadonlyText 
                  id="logistics_partner"
                  name="logistics_partner"
                  value={extra?.logistics_partner || ""}
                  label={""}
                  readOnly={status === STATUS.IN_DELIVERY}
                  
                  options={_([
                      ...logisticPartnerDictionaryData.filter((item)=>{return item.is_active}).map(
                        (item) => item.logistics_partner
                      ),
                    ])
                    .uniq()
                    .filter()
                    .sort()
                    .value()
                  }
                  placeholderText = {""}
                  is_ReadOnlyText = {true}
                  onChange={(e) =>
                    actions.setExtraData({
                      ...extra,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>

                <div className={"col-3 "+(dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ?"importantSection":'':'')}>
                <label className="col-form-label">
                  {t("shippingrequest.dispatchdate(mon)")}
                    <RequiredAstrick required={dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ? true:false: false } />
                </label>
                <CustomDate
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )
                  }
                  readOnly={status === STATUS.IN_DELIVERY}
                  handleDateChange={handleDispatchMON}
                  dateValue={dispatchMONValue}
                  classes={classes}
                  id="dispatch_date_mon"
                />
              </div>

                <div className={"col-3 "+(dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ?"importantSection":'':'')}>
                <label className="col-form-label">
                  {t("shippingrequest.dispatchdate(lev)")}
                    <RequiredAstrick required={dateOfReceiptValue? (!dispatchMONValue && !dispatchLEVValue) ? true:false: false } />
                </label>
                <CustomDate
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )
                  }
                  readOnly={status === STATUS.IN_DELIVERY}
                  handleDateChange={handleDispatchLEV}
                  dateValue={dispatchLEVValue}
                  classes={classes}
                  id="dispatch_date_lev"
                />
              </div>

                <div className="col-3 ">
                <label className="col-form-label">
                  {t("shipping_request.date_of_receipt")}

                </label>
                <CustomDate
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )
                  }
                  readOnly={status === STATUS.IN_DELIVERY}
                  handleDateChange={handleDateOfReceipt}
                  dateValue={dateOfReceiptValue}
                  classes={classes}
                  id="date_of_receipt"
                />
              </div>

                <div className={"col-3 "+(dateOfReceiptValue? !trackingNumValue ?"importantSection":'':'')} data-name="tracking_number">
                <label className="col-form-label">
                  {t("shipping_request.tracking_number")} 
                    <RequiredAstrick required={dateOfReceiptValue? !trackingNumValue ? true:false: false } />
                  :
                </label>
                <TextField
                  id="tracking_number"
                  name="tracking_number"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={trackingNumValue}
                  onChange={status === STATUS.IN_DELIVERY ? '': handleTrackingNum}
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )||
                    status === STATUS.IN_DELIVERY
                  }
                />
              </div>
              <div>
                <label className="col-form-label">
                    <a
                      href="javascript:void(0)"
                      target="_self"
                      onClick={() => {
                        history.push("/comments");
                        return false;
                      }}
                    >
                      {t("commonlabels.Add_comments")}
                    </a>
                </label>
              </div>
              {([STATUS.READY_FOR_DELIVERY, STATUS.INBOX].includes(
                status
              )  &&
              shippingActionType === EDIT) &&
              <div className={"col-3 "}>
                <label className="col-form-label">
                  {t("shipping_request.assigned_shipping_review")} 
                  :
                </label>
                <CheckboxField
                            id="review_by"
                            name="review_by"
                            className="check_box"
                            label={reviewBy ? reviewBy : ''}
                            onChange={handleUnderReviewCheckBox}
                          />
              </div>
              }
			  
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
    <div className="row pt10">
      <div className="col-12 statusinfo">
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography className="heading_box">
                {" "}
                {t("shippingrequest.Meta_information")}{" "}
              </Typography>
            }
            className={classes.cardHeader}
          />
          <CardContent>
            <div className="row col-12">
              <div className="col-3" style={{border:(shippingRequestDetails[0].createuseractive == false)?"3px solid orange":''}}>
                <label className="col-form-label">
                  &nbsp;{t("phase.createuser")}:&nbsp;
                </label>
                <label style={{overflowWrap:'break-word'}}>{created_by}</label>                
              </div>
              <div className="col-3">
                <label className="col-form-label">
                  &nbsp;{t("phase.createdate")}:&nbsp;
                </label>

                {DateFormat(
                  created_on,
                  i18n.resolvedLanguage,
                  i18n.resolvedLanguage
                )}
              </div>
              <div className="col-3" style={{border:(shippingRequestDetails[0].edituseractive == false)?"3px solid orange":''}}>
                <label className="col-form-label">
                  &nbsp;{t("process_shipping_request.edit_user")}:&nbsp;
                </label>
                <label style={{overflowWrap:'break-word'}}>{updated_by}</label>
              </div>
              <div className="col-3">
                <label className="col-form-label">
                  &nbsp;{t("process_shipping_request.edit_date")}:&nbsp;
                </label>

                {DateFormat(
                  updated_on,
                  i18n.resolvedLanguage,
                  i18n.resolvedLanguage
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
    <br></br>
  </>
  )
};

export default StatusInformation;
