import { React, useState, forwardRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import moment from "moment";
import Model from "../shared/model";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../assests/images/editIcon.svg";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "../Admin/Address/addressStyles";
import StatusFlow from "../shippingRequest/statusFlow";
import disableEditIcon from "../../assests/images/disableEditIcon.svg";
import { INSPECT, SUBMIT, PROCESS_MANAGER, STATUS } from "../../constants";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import * as config from "../../config";
import { DateFormat, DateWithDefaultTime, DateWithEndTime } from "../common/utils/DateUtil";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { SortFunctionNew, dateSortFunction, SortStatusFunctionNew } from "../../Services/util";
import AddressUtil from "../common/utils/AddressUtil";
import {
  tableRowFormatter,
  tableRowOrderManager,
} from "../common/utils/TableUtil";
import UserUtil from "../common/utils/UserUtil";

const RequestByDeputyListingComponent = (props) => {
  const pathDelegateURLs = [
    "/process/shipping",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/address",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/myShippings",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
   ];
  const [selected, setSelected] = useState("");
  const history = useHistory();
  const { listData, actions, searchFilter, setIsLoading, allMaterials,tableDelegateOptions } = props;
  const [filteredData, setfilteredData] = useState(listData);
  const [actionName, setActionName] = useState("");
  const [shippingDetail, setShippingDetail] = useState({});
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const classes = addressSearchStyles();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey",
    );

    let matchedShippmentIds = false;
    if (validSearchKeys.includes("description")) {
      matchedShippmentIds = allMaterials
        .filter((item) =>
          item.description
            .toLowerCase()
            .includes(searchFilter.description.toLowerCase()),
        )
        .map((item) => item.shipping_request_id);
    }
    const filteredData = listData.filter((item) => {
      if (matchedShippmentIds && !matchedShippmentIds.includes(item.id)) {
        return false;
      }

      for (let searchParam of validSearchKeys) {
        if (["submitted", "approved", "valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (!item.created_on) {
            return false;
          }
          const createDate = moment(item.created_on);
          const date = moment(searchFilter[searchParam]);
          if (searchParam === "startDate") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateLevStart", "shipmentDateLevEnd"].includes(searchParam)
        ) {
          if (!item.shipment_date_lev) {
            return false;
          }
          const createDate = moment(item.shipment_date_lev);
          const date = moment(searchFilter[searchParam]);
          if (searchParam === "shipmentDateLevStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
        ) {
          if (!item.close_date) {
            return false;
          }
          const createDate = moment(item.close_date);
          const date = moment(searchFilter[searchParam]);
          if (searchParam === "shipmentDateMomStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["dateOfRecipientStart", "dateOfRecipientEnd"].includes(searchParam)
        ) {
          const createDate = moment(item.created_on);
          const date = moment(searchFilter[searchParam]);
          if (searchParam === "dateOfRecipientStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (["final_receipt"].includes(searchParam)) {
          if (
            !(item["first_name"] + " " + item["last_name"])
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
          continue;
        }

        if (["name_english"].includes(searchParam)) {
          const countryName = _.isString(item["name_english"])
            ? item["name_english"].toLowerCase()
            : "";
          const fileterCountryName = _.isString(searchFilter[searchParam])
            ? searchFilter[searchParam].toLowerCase()
            : "";
          if (!countryName || !countryName.includes(fileterCountryName)) {
            return false;
          }
          continue;
        }

        if (
          !(
            item[searchParam] &&
            item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          )
        ) {
          return false;
        }
      }
      return true;
    });
   
    setfilteredData(filteredData.map(item=>({
      ...item,
      ...(item.address_recipient_locked_data)
    })));
  }, [searchFilter, allMaterials, listData]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {
      if (pathDelegateURLs.includes(window.location.pathname)) {
        localStorage.removeItem("requestSortAndPagination");
       actions.setDelegateTableOptions({});
       actions.setDelegateSearchData({});
      }
    };
  }, []);
  const dispatchDelegateOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setDelegateTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableDelegateOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setDelegateTableOptions({
        pageNumber: 0,
        pageSize: tableDelegateOptions.pageSize,
        orderBy: value
      });
    }
    
  }
  const handleRadiobuttonChange = (data) => {
    setSelected(data);
  };

  const getStatusFlow = () => {
    if (actionName === INSPECT) {
      return (
        <StatusFlow
          row={selected}
          actionType={INSPECT}
          roleName={PROCESS_MANAGER}
          goBack="/requestByDelegate"
        />
      );
    }

    if (actionName === SUBMIT) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={SUBMIT}
          roleName={PROCESS_MANAGER}
          goBack="/requestByDelegate"
        />
      );
    }
  };
  const handlerShippingInspect = () => {
    // let rowData = {};
    // if (selectedRow.tableData.id === 0) {
    //   rowData = firstRowData;
    // }
    // if (shippingSelectedRow.tableData.id !== 0) {
    //   rowData = shippingSelectedRow;
    // }
    setActionName(INSPECT);
    setShippingDetail(selected);
    actions.saveTableData(selected);
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Request_By_Delegate_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      let count = 0;
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on, i18n.resolvedLanguage);
      }

      if (item.date_of_recipient) {
        item.date_of_recipient = DateFormat(
          item.date_of_recipient,
          i18n.resolvedLanguage,
        );
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate, i18n.resolvedLanguage);
      }
      if (item.first_name) {
        item.first_name =
          (item.first_name ? item.first_name : "") +
          " " +
          (item.last_name ? item.last_name : "");
      }
      if (item.distributiontemperature_id) {
        item.distributiontemperature_id =
          (item.entity ? item.entity : "") +
          " " +
          (item.building ? item.building : "") +
          " " +
          (item.street ? item.street : "") +
          " " +
          (item.city ? item.city : "") +
          " " +
          (item.name ? item.name : "");
      }

      if (count === 0) {
        if (
          item.entity ||
          item.building ||
          item.street ||
          item.postcode ||
          item.city ||
          item.name_english
        ) {
          count = count + 1;
          item.entity =
            (item.entity === null ? "" : item.entity + ", ") +
            (item.building === null ? "" : item.building + ", ") +
            (item.street === null ? "" : item.street + ", ") +
            (item.postcode === null ? "" : item.postcode + ", ") +
            (item.city === null ? "" : item.city + ", ") +
            (item.name_english.trim() === "()" ? "" : item.name_english);
        }
      }

      item["Create Date"] = item["created_on"];
      item["Shipping Request#"] = item["internal_distribution_number"];

      item["Recipient "] = item["first_name"];
      item["Address "] = item["distributiontemperature_id"];

      item["Date Of Receipt"] = item["date_of_recipient"];

      //  item[t("address.Status")] = item["status"];

      item["Status"] = t("status." + item["status"]);

      delete item["id_old"];
      delete item["importer_records_address"];

      delete item["id"];
      delete item["recievingcountry"];
      delete item["handling_type"];
      delete item["is_shippment_hazardous"];
      delete item["shippment_hazardous_comments"];

      delete item["shipment_no"];
      delete item["is_reg_review_req"];

      delete item["recievingcountry"];

      delete item["date_of_recipient"];
      delete item["is_reg_review_req"];

      delete item["orgincountry"];
      delete item["study_number"];
      delete item["distributiontemperature_id"];
      delete item["recipient_id"];
      delete item["close_date"];
      delete item["shipper_id"];

      delete item["c_comment"];
      delete item["recievingcountry"];
      delete item["id_old_request"];
      delete item["internal_distribution_number"];
      delete item["sap_delivery_number"];
      delete item["shipment_constraint_date"];
      delete item["shipment_constraint_type"];
      delete item["shipment_date_lev"];
      delete item["shipment_date_mon"];

      delete item["tracking_number"];
      delete item["status_comment"];
      delete item["status"];

      delete item["id"];
      delete item["recievingcountry"];
      delete item["orgincountry"];

      delete item["address_id"];
      delete item["shipment_no	is_reg_review_req	"];
      delete item["logistics_partner"];

      delete item["importer_records_address	"];
      delete item["entity_name"];
      delete item["importer_of_records"];
      delete item["cost_center_shipment"];
      delete item["type_of_shipment"];
      delete item["inco_term"];
      delete item["attachment_comment"];

      delete item["is_active"];
      delete item["updated_by"];
      delete item["updated_on"];

      delete item["created_by"];
      delete item["created_on"];
      delete item["valid"];

      delete item["submitted"];
      delete item["phone"];
      delete item["last_name"];

      delete item["form_of_address"];
      delete item["first_name"];
      delete item["email"];
      delete item["approved"];

      delete item["title_id"];
      delete item["date_material_needed_on_site"];
      delete item["date_material_available_to_ship"];
      delete item["is_mat_food_produced_animal"];
      delete item["is_mat_client_used_animal"];
      delete item["pack_length"];
      delete item["pack_width"];
      delete item["pack_height"];
      delete item["pack_tot_weight"];
      delete item["prp_use_of_material"];

      delete item["country_id"];
      delete item["building"];
      delete item["city"];

      delete item["department"];
      delete item["entity"];
      delete item["postcode"];
      delete item["sapnumber"];

      delete item["street"];
      delete item["state"];
      delete item["code"];
      delete item["name"];
      delete item["name_english"];
      delete item["Shipping Request #"];
      delete item["Material"];
      delete item["Final Recipient"];
      delete item["Address"];
      delete item["Date of Receipt"];
      delete item["Edit Date"];
      delete item["review_by"];
      delete item["address_recipient_locked_data"];
      delete item["prefered_reviewer"];
      delete item["title"];
      delete item["handling_type"];
      
      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };
  const ExportBtn = () => {
    return (
      <button
        className="mr-1"
        onClick={() => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };

  const ExportSelectedBtn = () => {
    window.location.href =
      config.baseURL +
      `/api/exportSelectedData/` +
      selected.id +
      "/" +
      requiredFileName +
      "/Request_By_Delegate_Data";
  };
  const InspectBtn = ({ btnStatus, handleClick = {} }) => {
    return (
      <button
        disabled={btnStatus}
        className="btn btn-primary  new_button mr-1"
        onClick={handleClick}
      >
        {t("shipping_request.inspect")}
      </button>
    );
  };
  const inspectHideAndShow = () => {
    localStorage.setItem("requestSortAndPagination",JSON.stringify(tableDelegateOptions))
    // if (
    //   firstRowData.status === STATUS.INPUT &&
    //   shippingSelectedRow.tableData.id === 0
    // ) {
    //   return <InspectBtn btnStatus={true} />;
    // }

    if (get(selected, "status", "INPUT") === STATUS.INPUT) {
      return <InspectBtn btnStatus={true} />;
    }

    return (
      <InspectBtn btnStatus={false} handleClick={handlerShippingInspect} />
    );
  };
  const SubmitBtn = ({ btnStatus, handleClick = {} }) => {
    return (
      <button
        disabled={btnStatus}
        className="btn btn-primary new_button "
        onClick={handleClick}
      >
        {t("shipping_request.submit")}
      </button>
    );
  };
  const handlerShippingSubmit = () => {
    localStorage.setItem("requestSortAndPagination",JSON.stringify(tableDelegateOptions))
    setActionName(SUBMIT);
    setShippingDetail(selected);
    actions.saveTableData(selected);
  };
  const submitHideAndShow = () => {
    return (
      <SubmitBtn
        btnStatus={get(selected, "status", "") !== STATUS.INPUT}
        handleClick={handlerShippingSubmit}
      />
    );
  };

  const editShippment = async (row) => {
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    actions.getShippingActionType("EDIT");
    actions.clearShippingMaterialSelectedValue();
    actions.addShippingMaterialSelect([]);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
    actions.saveTableData(row);
    localStorage.setItem("requestSortAndPagination",JSON.stringify(tableDelegateOptions))
    actions.redirectAction("/requestByDelegate");
    history.push("/requestByDelegate/edit");
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={selected === ""}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <>
      {Object.keys(shippingDetail).length > 0 &&
        shippingDetail.id !== 0 &&
        getStatusFlow()}
      <div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
            <DropdownButton
              title={t("export.export")}
              className="mr-1 exportdropdown"
            >
              <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
              <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
            </DropdownButton>
            {/* {ExportBtn()} */}
            {inspectHideAndShow()}
            {submitHideAndShow()}
          </div>
        </div>
      </div>
      <Card variant="outlined">
        <CardHeader
          title={t("phase.searchresults")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
            
              <MaterialTable
                onPageChange={(page, itemsPerPage) => {
                  dispatchDelegateOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
                }}
                onOrderCollectionChange={(orderBy) => {
                  dispatchDelegateOptions({ target: "sort" }, orderBy);
                }}
                columns={[
                  {
                    width: "8%",
                    sorting: false,
                    render: (rowData) => (
                      <input
                        type="radio"
                        value={get(selected, "id", "")}
                        name="radiovalues"
                        className={classes.tableRadio}
                        onChange={(e) => handleRadiobuttonChange(rowData)}
                      />
                    ),
                  },
                  {
                    title: <span>{t("address.Create Date")}</span>,
                    customSort: dateSortFunction("created_on"),
                    render: (rowData) => dateLocalization(rowData.created_on),
                  },
                  {
                    title: <span>{t("shipping_request.internal_order_id")}</span>,
                    field: "internal_distribution_number",
                    customSort: SortFunctionNew("internal_distribution_number"),
                  },

                  {
                    title: <span>{t("shippingrequest.finalrecipient")}</span>,
                    customSort: (a, b) => new UserUtil(a).compare(new UserUtil(b)),
                    render: (rowData) => {
                      return (
                        new UserUtil(rowData).toString()
                      );
                    },
                  },
                  {
                    title: <span>{t("recipient.Address")}</span>,
                    render: (rowData) => (
                      <>{new AddressUtil(rowData).toString()}</>
                    ),
                    customSort: (a, b) =>
                      new AddressUtil(a).compare(new AddressUtil(b)),
                  },
                  {
                    title: <span>{t("shipping_request.date_of_receipt")}</span>,
                    customSort: dateSortFunction("date_of_recipient"),
                    render: (rowData) =>
                      dateLocalization(rowData.date_of_recipient),
                  },
                  {
                    title: <span>{t("address.Status")}</span>,
                    render: (rowData) => t("status." + rowData.status),
                    customSort: SortStatusFunctionNew("status"),
                  },
                  // {
                  //   title: <span></span>,
                  //   sorting: false,
                  //   render: (rowData) => (
                  //     <span>
                  //       <Link onClick={() => editShippment(rowData)}>
                  //         <img alt="t" className="" src={editIcon}></img>{" "}
                  //       </Link>
                  //     </span>
                  //   ),
                  // },
                  {
                    title: <span></span>,
                    sorting: false,
                    render: (rowData) => {
                      if (
                        rowData.status === "IN_PROGRESS" ||
                        rowData.status === "PROCESSING" ||
                        rowData.status === "AWAITING_FOR_APPROVAL" ||
                        rowData.status === "INBOX" ||
                        rowData.status === "CANCELLED" ||
                        rowData.status === "CLOSE" ||
                        rowData.status === "READY_FOR_PICKUP" ||
                        rowData.status === "IN_DELIVERY" ||
                        rowData.status === "READY_FOR_DELIVERY" ||
                        rowData.status === "Regulatory Reviewer 1" ||
                        rowData.status === "Regulatory Reviewer 2" ||
                        rowData.status === STATUS.SHIPER_REVIEW_DONE
                      ) {
                        return (
                          <span>
                            <a
                              href="javascript:void(0)"
                              style={{
                                color: "red",
                                paddingLeft: "10px",
                                textDecoration: "none",
                              }}
                              id="openmodalForEdit"
                            >
                              <img
                                alt="t"
                                className=""
                                src={disableEditIcon}
                              ></img>{" "}
                            </a>
                          </span>
                        );
                      } else {
                        return (
                          <span>
                            <a
                              href="#"
                              style={{
                                color: "red",
                                paddingLeft: "10px",
                                textDecoration: "none",
                              }}
                              id="openmodalForEdit"
                              onClick={(e) => {
                                editShippment(rowData);
                              }}
                            >
                              <img alt="t" className="" src={editIcon}></img>{" "}
                            </a>
                          </span>
                        );
                      }
                    },
                  },
                ]}
                data={filteredData}
                title="Render Image Preview"
                icons={tableIcons}
                localization={{
                  pagination: {
                    labelRowsSelect: t("commonlabels.labelRowsSelect"),
                    firstAriaLabel: t("commonlabels.firstAriaLabel"),
                    firstTooltip: t("commonlabels.firstTooltip"),
                    previousAriaLabel: t("commonlabels.previousAriaLabel"),
                    previousTooltip: t("commonlabels.previousTooltip"),
                    nextAriaLabel: t("commonlabels.nextAriaLabel"),
                    nextTooltip: t("commonlabels.nextTooltip"),
                    lastAriaLabel: t("commonlabels.lastAriaLabel"),
                    lastTooltip: t("commonlabels.lastTooltip"),
                  },
                  body: {
                    emptyDataSourceMessage: t(
                      "commonlabels.emptyDataSourceMessage",
                    ),
                  },
                }}
                options={{
                  draggable: false,
                  thirdSortClick: false,
                  //  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: "#cce3f5",
                    fontWeight: "bold",
                    height: "10px",
                  },
                  rowStyle: tableRowFormatter({
                    height: "30px",
                    padding: "0px",
                  }),
                  initialPage: parseInt(tableDelegateOptions?.pageNumber ? tableDelegateOptions?.pageNumber : 0),
                  sortIcon: true,
                  maxBodyHeight: "90%",
                  tableLayout: "auto",
                  sorting: true,
                  search: false,
                  selection: false,
                  showTitle: false,
                  toolbar: false,
                  paging: true,
                  paginationType: "stepped",
                  pageSize: parseInt(tableDelegateOptions?.pageSize ? tableDelegateOptions?.pageSize : 10),
                  defaultOrderByCollection: tableDelegateOptions?.orderBy ? tableDelegateOptions?.orderBy : "",
                  pageSizeOptions: [10, 20, 30, 40,50],
                  emptyRowsWhenPaging: false,
                  showSelectAllCheckbox: false,
                }}
              />
           
          </div>
        </CardContent>
      </Card>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </>
  );
};

export default RequestByDeputyListingComponent;
