import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { trim, get } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Select from "../../common/CustomSelect";
import AutocompleteText from "../../common/AutocompleteText";
import { authContext } from "../../../adalConfig";
import { addressSearchStyles } from "./addressStyles";

const InspectRecipientsComponent = (props) => {
  const ItemContainer = ({ children, sm = "3" }) => {
    return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
  };
  const { actions, allRecipients, allTitle } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;

  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({});

  const classes = addressSearchStyles();
  useEffect(() => {
    if (get(props, "location.state.id", false)) {
      if (allRecipients.length === 0) {
        actions.getAllrecipients().then((res) => {
          const selectedRecipients = allRecipients.find(
            (item) => item.id === get(props, "location.state.id", 0),
          );
          setSelected(selectedRecipients);
          setIsLoading(false);
        });
      } else {
        const selectedRecipients = allRecipients.find(
          (item) => item.id === get(props, "location.state.id", 0),
        );
        setSelected(selectedRecipients);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    history.push(`/recipients`);
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Card variant="outlined" className={classes.container}>
          <CardHeader title={t("inspectRecipient.title")} />
          <Divider />
          <CardContent>
            <div className="container-fluid">
              <div className="row">
                <ItemContainer sm="6">
                  {/* <Card variant="outlined" className={classes.container}> */}
                  {/* <CardContent>
                      <div>
                       
                      </div>
                    </CardContent> */}
                  {/* </Card> */}
                </ItemContainer>
                <ItemContainer sm="6">
                  <Card variant="outlined" className={classes.container}>
                    <CardContent>
                      <div>{t("inspectRecipient.notice")}</div>
                    </CardContent>
                  </Card>
                  <Card
                    variant="outlined"
                    className={classes.container}
                    style={{ display: "none" }}
                  >
                    <CardContent>
                      <div>
                        {t("inspectRecipient.sapNotice")}:{" "}
                        <span>{selected.sapnumber}</span>
                      </div>
                    </CardContent>
                  </Card>
                </ItemContainer>
              </div>
            </div>
            <Divider />
            <div className="row form-padding">
              <ItemContainer sm="6">
                <Card variant="outlined" className={classes.container}>
                  <Divider />
                  <CardContent className="form-padding">
                    <div className="row m-0 p-0">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Title")}:
                        </span>
                        <span>{selected.title}</span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Pre-name")}:
                        </span>
                        <span>{selected.first_name}</span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Last name")}:
                        </span>
                        <span>{selected.last_name}</span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Email")}:
                        </span>
                        <span>{selected.email}</span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Phone")}:
                        </span>
                        <span>{selected.phone}</span>
                      </ItemContainer>
                    </div>
                  </CardContent>
                </Card>
              </ItemContainer>
              <ItemContainer sm="6">
                <Card variant="outlined" className={classes.container}>
                  <Divider />
                  <CardContent className="form-padding">
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Company/University")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.entity}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Department")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.department}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.building")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.building}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Street")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.street}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Postal Code")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.postcode}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.City")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.city}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row m-0 p-0">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Country")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected.name_english}
                        </span>
                      </ItemContainer>
                    </div>
                  </CardContent>
                </Card>
              </ItemContainer>
            </div>
          </CardContent>
          <CardActions className={classes.cardActionButtons}>
            <button className="btn btn-black new_button" onClick={handleClose}>
              {t("commonlabels.previousLabel")}
            </button>
          </CardActions>
        </Card>
      </LoadingOverlay>
    </>
  );
};

export default InspectRecipientsComponent;
