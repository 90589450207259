import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { addressSearchStyles } from "./addressStyles";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "@material-table/core";
import {
  dateSortFunction,
  sortFunction,
  SortFunctionNew,
} from "../../../Services/util";

const InspectAddress = (props) => {
  const { actions } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = addressSearchStyles();
  const [sapNumber, setSapNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [department, setDepartment] = useState(null);
  const [bulliging, setBulliging] = useState(null);
  const [street, setStreet] = useState(null);
  const [postal, setPostal] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState(null);
  const [recipient, setRecipient] = useState([]);
  const handleClose = () => {
    history.push(`/address`);
  };
  useEffect(() => {
    if (props.location.state) {
      actions.getAddress(props.location.state.id).then((res) => {
        const addressData = res.data;
        setSapNumber(addressData[0].sapnumber);
        setCompany(addressData[0].entity);
        setDepartment(addressData[0].department);
        setBulliging(addressData[0].building);
        setStreet(addressData[0].street);
        setPostal(addressData[0].postcode);
        setCity(addressData[0].city);
        setCountry(addressData[0].name_english);
        setState(addressData[0].state);
        actions.getAddressRecipient(props.location.state.id).then((res) => {
          if (res.data.length > 0) {
            setRecipient(res.data);
          }
          setIsLoading(false);
        });
      });
    }
  }, []);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Card variant="outlined" className={classes.container}>
          <CardHeader title={t("address.inspectaddressTitle")} />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "none", marginBottom: "20px" }}
                >
                  <span className={classes.textLables}>
                    {t("address.SAP Number Country")}:
                  </span>
                  {sapNumber}
                </Grid>
                <Grid>
                  <Card variant="outlined" className={classes.container}>
                    <CardHeader />
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {t("address.Company/University")}:
                        </span>
                        <span>{company}</span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {" "}
                          {t("address.Department")}:
                        </span>
                        {department}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {t("address.building")}:{" "}
                        </span>
                        {bulliging}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {t("address.Street")}:{" "}
                        </span>
                        {street}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {t("address.Postal Code")}:{" "}
                        </span>
                        {postal}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {" "}
                          {t("address.City")}:{" "}
                        </span>
                        {city}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {" "}
                          {t("address.State")}:{" "}
                        </span>
                        {state}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginBottom: "20px" }}
                      >
                        <span className={classes.textLables}>
                          {t("address.Country")}:{" "}
                        </span>
                        <div>{country}</div>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <MaterialTable
                  columns={[
                    {
                      title: <span>{t("recipient.name")}</span>,
                      field: "first_name",
                      customSort: (a, b) => {
                        if (
                          (a.first_name + a.last_name)
                            .toString()
                            .toLowerCase() >
                          (b.first_name + b.last_name).toString().toLowerCase()
                        ) {
                          return -1;
                        } else if (
                          (a.first_name + a.last_name)
                            .toString()
                            .toLowerCase() <
                          (b.first_name + b.last_name).toString().toLowerCase()
                        ) {
                          return 1;
                        } else {
                          return 0;
                        }
                      },
                      render: (rowData) => (
                        <span>
                          {rowData.first_name} {rowData.last_name}
                        </span>
                      ),
                    },

                    {
                      title: <span>{t("registration.Email")}</span>,
                      field: "email",
                      customSort: SortFunctionNew("email"),
                    },
                    {
                      title: <span>{t("registration.Phone")}</span>,
                      field: "phone",
                      customSort: SortFunctionNew("phone"),
                    },

                    {
                      title: <span>{t("address.Created User")}</span>,
                      field: "created_by",
                      customSort: SortFunctionNew("created_by"),
                    },
                    {
                      title: <span>{t("address.Approved")}</span>,
                      customSort: (a, b) => {
                        if (a.approved > b.approved) {
                          return -1;
                        } else if (a.approved < b.approved) {
                          return 1;
                        } else if (a.approved === b.approved) {
                          return 0;
                        } else {
                          return 0;
                        }
                      },
                      render: (rowData) =>
                        rowData.approved === 1 ? (
                          <span>
                            <div>
                              <input
                                type="checkbox"
                                defaultChecked={true}
                                disabled
                              />
                            </div>
                          </span>
                        ) : (
                          <div>
                            <span>
                              <div>
                                <input
                                  type="checkbox"
                                  defaultChecked={false}
                                  disabled
                                />
                              </div>
                            </span>
                          </div>
                        ),
                    },
                    {
                      title: <span>{t("address.Valid")}</span>,
                      customSort: (a, b) => {
                        if (a.valid > b.valid) {
                          return -1;
                        } else if (a.valid < b.valid) {
                          return 1;
                        } else if (a.valid === b.valid) {
                          return 0;
                        } else {
                          return 0;
                        }
                      },
                      render: (rowData) =>
                        rowData.valid === 1 ? (
                          <span>
                            <div>
                              <input
                                type="checkbox"
                                defaultChecked={true}
                                disabled
                              />
                            </div>
                          </span>
                        ) : (
                          <div>
                            <span>
                              <div>
                                <input
                                  type="checkbox"
                                  defaultChecked={false}
                                  disabled
                                />
                              </div>
                            </span>
                          </div>
                        ),
                    },
                  ]}
                  data={recipient}
                  title="Render Image Preview"
                  localization={{
                    pagination: {
                      labelRowsSelect: t("commonlabels.labelRowsSelect"),
                      firstAriaLabel: t("commonlabels.firstAriaLabel"),
                      firstTooltip: t("commonlabels.firstTooltip"),
                      previousAriaLabel: t("commonlabels.previousAriaLabel"),
                      previousTooltip: t("commonlabels.previousTooltip"),
                      nextAriaLabel: t("commonlabels.nextAriaLabel"),
                      nextTooltip: t("commonlabels.nextTooltip"),
                      lastAriaLabel: t("commonlabels.lastAriaLabel"),
                      lastTooltip: t("commonlabels.lastTooltip"),
                    },
                    body: {
                      emptyDataSourceMessage: t(
                        "commonlabels.emptyDataSourceMessage",
                      ),
                    },
                  }}
                  options={{
                    draggable: false,
                    thirdSortClick: false,
                    headerStyle: {
                      backgroundColor: "#cce3f5",
                      fontWeight: "bold",
                      height: "10px",
                    },
                    rowStyle: {
                      height: "30px",
                      padding: "0px",
                    },

                    maxBodyHeight: "90%",
                    tableLayout: "auto",
                    sorting: false,
                    search: false,
                    selection: false,

                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    emptyRowsWhenPaging: false,
                    showSelectAllCheckbox: false,
                  }}
                />
                <Card variant="outlined" className={classes.container}>
                  <CardContent>
                    <div>{t("address.addNewAddressFooter")}</div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.cardActionButtons}>
            <button
              className="btn btn-secondary new_button"
              onClick={handleClose}
            >
              {t("materials.back")}
            </button>
          </CardActions>
        </Card>
      </LoadingOverlay>
    </>
  );
};

export default InspectAddress;
