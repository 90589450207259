const baseAction = (action, payload = {}) => ({ type: action, payload });

/**
 * Base function
 * @param {function} actionType type of action you want to dispatch
 * @param {function} method API method you want to hit
 * @param {string} apiURL URL OF API
 * @param {Object} data PAYLOAD
 * @returns {Promise}
 */
const fetchData =
  (actionType, method, params) => async (dispatch, getState, services) => {
    const { api } = services;
    try {
      let response = await api[method].apply(this, params);
      if (response.status === 200 || response.statusText === "OK") {
        const payload = response.data;
        dispatch(baseAction(actionType, payload));
        dispatch(baseAction("ERROR_MESSAGE", null));
        return response;
      } else {
        throw response;
      }
    } catch (err) {
      dispatch(baseAction("ERROR_MESSAGE", err && err.message));
    }
  };

export default fetchData;
