import React from "react";
import { useState,useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import "./shippingrequest.css";
import Select from "../common/CustomSelect";
import CustomDate from "../common/CustomDate";
import { USERROLES } from "../../constants";
import HelpIcon from "@mui/icons-material/Help";
import { HelpDialog } from "../common/Confirmation";
import {
  INSPECT,
  SUBMIT,
  HAND_BACK,
  STATUS,
  CANCEL_REQUEST,
  CLOSE,
  EDIT,
} from "../../constants";
import GeneralInformationCard from "./preview/GeneralInformationCard";
import { useTranslation } from "react-i18next";
import {
  ifRequestCompletedApprovals,
  stringSortFunction,
} from "../../Services/util";
import StudyNumberComponent from "./StudyNumberComponent";
import _, { endsWith } from "lodash";
import moment from "moment";
import { Input } from "@material-ui/core";
import {
  disableInputFieldsBySelector,
  enableInputFieldsBySelector,
} from "../common/utils/DomUtil";

const styles = {
  header: {
    width: "100%",
    height: 30,
    backgroundColor: "#ECECEC",
  },
  Addbtn: {
    float: "right",
  },
  asterisk: {
    color: "red",
  },
};

const GeneralInformation = (props) => {
  const {
    list = [],
    classes,
    handleChange,
    value,
    internalOrderIdValue,
    shippingRequestDetails,
    shippingActionType,
    handleStatusQuo,
    handleSapDeliveryNum,
    extra,
    actions,
    Actions,
    getShippingResponse,
    dispatchMONValue,
    dispatchLEVValue,
    dateOfReceiptValue,
    incoterms,
    regulatoryReviewer1List,
    shipperCountry,
    shippingType,
    ExcludeDates,
    handleSpecialMethod,
    handling_type,
    clientTimeZone
  } = props;
  let shippingRequestData;

  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOpenMatNeedDate, setConfirmationOpenMatNeedDate] =
    useState(false);
  const handleOpenHelpDateMaterial = ()=>{
    setConfirmationOpenMatNeedDate(true);
  }
  const handleCloseHelpDateMaterial = ()=>{
    setConfirmationOpenMatNeedDate(false);
  }

  const [confirmationOpenMattemp, setConfirmationOpenMattemp] =
  useState(false);
const handleOpenHelpDatetemp = ()=>{
  setConfirmationOpenMattemp(true);
}
const handleCloseHelpDatetemp = ()=>{
  setConfirmationOpenMattemp(false);
}

const [confirmationOpenDistributiontemp, setConfirmationOpenDistributiontemp] =
useState(false);
const handleOpenHelpDistributiontemp = ()=>{
  setConfirmationOpenDistributiontemp(true);
}
const handleCloseHelpDistributiontemp = ()=>{
  setConfirmationOpenDistributiontemp(false);
}

  //Handle new create scenarios.
  if (Array.isArray(getShippingResponse) && getShippingResponse.length > 0) {
    shippingRequestData = getShippingResponse[0];
  } else if (
    Array.isArray(shippingRequestDetails) &&
    shippingRequestDetails.length > 0
  ) {
    //Handles update scenaios.
    shippingRequestData = shippingRequestDetails[0];
  } else {
    shippingRequestData = null;
  }
  const isdistributiontempvalid = (obj) =>{
    if(obj?.tempvalid == null || Object.keys(obj).length == 0) return true
      if(obj?.tempvalid == 1){
        return true
      }
      else{
        return false;
      }
  }

  const isreviewervalid = (obj) =>{
    if(obj?.revieweractive == null || Object.keys(obj).length == 0) return true
      if(obj?.revieweractive == true){
        return true
      }
      else{
        return false;
      }
  }

  const inprogressstatus = [
    null,
    undefined,
    STATUS.INPUT,
    STATUS.AWAITING_FOR_APPROVAL,
  ]
  const userType = JSON.parse(localStorage.getItem("userType"));
  const status = _.get(shippingRequestDetails, "[0].status", "");

  const isEditable = ([
    STATUS.INBOX,
    STATUS.READY_FOR_DELIVERY,
    STATUS.IN_DELIVERY,
    STATUS.REGULATORY_REVIEWER_1,
    STATUS.REGULATORY_REVIEWER_2,
  ].includes(status) || (shippingActionType === INSPECT || shippingActionType === SUBMIT || shippingActionType===EDIT))
  
  useEffect(() => {
    if (isEditable && [STATUS.REGULATORY_REVIEWER_1,
      STATUS.REGULATORY_REVIEWER_2,STATUS.INBOX,STATUS.READY_FOR_DELIVERY].includes(status) ) {
      enableInputFieldsBySelector(
        `[data-name="package_details"]`,
      );
    } 
  
  });

  const shipType=shippingType.find((obj)=>obj.id === extra.type_of_shipment)
  const isshipvalid = (obj) =>{
    if(obj?.isactive == null || Object.keys(obj).length == 0) return true
      if(obj?.isactive == true){
        return true
      }
      else{
        return false;
      }
  }

  const listdata = (inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined)))
    ? list.filter((x) => x.valid==1).sort(stringSortFunction("name"))
    : list.sort(stringSortFunction("name"));

    const incoTerms=incoterms.find((obj)=>obj.id === extra.inco_term)
    const isincovalid = (obj) =>{
      if(obj?.isactive == null || Object.keys(obj).length == 0) return true
        if(obj?.isactive == true){
          return true
        }
        else{
          return false;
        }
    }

  if (shippingActionType === INSPECT) {
    return <GeneralInformationCard {...props} />;
  }

  if (shippingActionType === SUBMIT && status === STATUS.INPUT) {
    return <GeneralInformationCard {...props} />;
  }

  if (
    shippingActionType === HAND_BACK ||
    shippingActionType === CANCEL_REQUEST
  ) {
    return (
      <div className="row col-12 p-0 mb-4  mt-4 no-marrgin">
        <div className="col-lg-6 p-0 col-12">
          <GeneralInformationCard
            internalOrderIdValue={internalOrderIdValue}
            handleStatusQuo={handleStatusQuo}
            shippingRequestDetails={shippingRequestDetails}
            actions
            {...props}
          />
        </div>
      </div>
    );
  }
  const handleDispatchMON = (value) => {
    actions.getDispatchMONValue(value);
  };

  const handleDispatchLEV = (value) => {
    actions.getDispatchLEVValue(value);
  };

  const handleDateOfReceipt = (value) => {
    actions.getDateOfReceiptValue(value);
  };

  if (shippingActionType === CLOSE) {
    return (
      <div className="row col-12 p-0 mb-4  mt-4 no-marrgin">
        <div className="col-lg-6 p-0 col-12">
          <GeneralInformationCard
            internalOrderIdValue={internalOrderIdValue}
            handleStatusQuo={handleStatusQuo}
            handleSapDeliveryNum={handleSapDeliveryNum}
            shippingRequestDetails={shippingRequestDetails}
            handleDispatchMON={handleDispatchMON}
            handleDispatchLEV={handleDispatchLEV}
            handleDateOfReceipt={handleDateOfReceipt}
            dispatchMONValue={dispatchMONValue}
            dispatchLEVValue={dispatchLEVValue}
            dateOfReceiptValue={dateOfReceiptValue}
            shippingActionType={shippingActionType}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t("shipping_request.general_information")}{" "}
            </Typography>
          }
          className={classes.header}
        />
        <CardContent>
          <div className="row  mt-2 mr-0 no-marrgin generalinfofieldset">
            <div className="col-lg-2  col-12 form-outline form-margin">
              <label className="form-label">
                {t("shipping_request.internal_order_id")}
              </label>
              <TextField
                disabled={true}
                className="form-control form-textbox"
                id="internal_distribution_number"
                margin="normal"
                variant="outlined"
                size="small"
                value={shippingRequestData?.internal_distribution_number || ""}
              />
            </div>
            <div className="col-lg-2  col-12 form-outline form-margin">
            <label className="form-label">
            {t("shipping_request.cost_center_WBS_for_shipments")}
              </label>
              <TextField
              data-name="package_details"
                 isDisabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  )
                  ||
                  (status === STATUS.IN_DELIVERY )
                }
                type="text"
                className="form-control form-textbox"
                id="cost_center_shipment"
                name="cost_center_shipment"
                margin="normal"
                variant="outlined"
                size="small"
                value={extra?.cost_center_shipment || "" }
                onChange={(e) =>
                  actions.setExtraData({
                     ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-3  col-12 form-outline form-margin"  style={{border:(isshipvalid(shipType))? "":"3px solid orange"}}>
              <label className="col-form-label">
                  {t("shipping_request.type_of_shipment")}
                  <span className="req">*</span>
                </label>
                <Select
                  isDisabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    ) ||
                    (status === STATUS.IN_DELIVERY)
                  }
                  className="form-control form-textbox"
                  name={"type_of_shipment"}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  list={
                    inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
                      ? shippingType.filter(item => item.isactive === true)
                      : shippingType
                  }
                  value={extra?.type_of_shipment || ""}
                  onChange={(e) =>
                    actions.setExtraData({
                      ...extra,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
            </div>
            <div className="col-lg-5  col-12 form-outline form-margin">
              <label className="form-label">
                {t("shipping_request.material_availability")}<span className="req">*</span>
              <HelpIcon onClick={handleOpenHelpDateMaterial} />
              </label>
              <HelpDialog
            title={t("shipping_request.helpMatTitle")}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: t("shipping_request.helpMatNeedDate"),
                }}
              />
            }
            confirmationOpen={confirmationOpenMatNeedDate}
            handleCancelConfirmation={handleCloseHelpDateMaterial}
          />
              <div className="d-flex materialavail">
                <CustomDate
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )
                  }
                  readOnly={status === STATUS.IN_DELIVERY}
                  dateValue={extra?.date_material_available_to_ship || ""}
                  handleDateChange={(date) => {
                      actions.setClientTimeZone({...clientTimeZone, ["date_material_available_to_ship_string"]:new Date(date).toString()})
                    actions.setExtraData({
                      ...extra,
                      "date_material_available_to_ship": moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(true).format('YYYY-MM-DDTHH:mm:ss'),
                    })
                  }
                }
                  classes={classes}
                  calenderFor ={"date_material_available_to_ship"}
                  placeholderText={t(
                    "shipping_request.date_material_available_to_ship",
                  )}
                  Extra = {extra}
                  ShipActions = {Actions}
                />
                <CustomDate
                  disabled={
                    !(
                      userType.includes(USERROLES.PROCESSMANAGER) ||
                      userType.includes(USERROLES.APPLICANT) ||
                      userType.includes(USERROLES.ADMIN)
                    )
                  }
                  readOnly={status === STATUS.IN_DELIVERY}
                  dateValue={extra?.date_material_needed_on_site || ""}
                  handleDateChange={(date) => {
                    actions.setClientTimeZone({...clientTimeZone, ["date_material_needed_on_site_string"]:new Date(date).toString()})
                    actions.setExtraData({
                      ...extra,
                      "date_material_needed_on_site": moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc(true).format('YYYY-MM-DDTHH:mm:ss'),
                    })
                  }
                }
                  classes={classes}
                  placeholderText={t(
                    "shipping_request.date_material_needed_on_site",
                  )}
                  calenderFor = {'date_material_needed_on_site'}
                  shipperCountry = {shipperCountry}
                  shippmentType  = {extra.type_of_shipment} 
                  material_avail_date = {extra.date_material_available_to_ship}
                  material_needed_date = {extra.date_material_needed_on_site}
                  Extra={extra}
                  shipActions = {Actions}
                  excludeDates = {ExcludeDates}
                />
              </div>
            </div>

            <div className="col-lg-3  col-12 form-outline form-margin">
              <label className="form-label">
                {t("shipping_request.study_number")}  ( 
                <a href={window.env.__veevaVaultUrl} target="_new">
                   Veeva Vault
                </a>
                ) ({t("shipping_request.study_number_helper")})<span className="req">*</span>
              </label>
              <StudyNumberComponent
                actions={actions}
                extra={extra}
                status={status}
              />
            </div>
            <div className="col-lg-2  col-12 form-outline form-margin" style={{border:(isincovalid(incoTerms))? "":"3px solid orange"}}>
              <label className="form-label">
                {t("shipping_request.incoterms")}
              </label>
              <Select
                isDisabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  )||
                  (status === STATUS.IN_DELIVERY)
                }
                className="form-control form-textbox"
                name={"inco_term"}
                list={
                  inprogressstatus.includes(
                    _.get(shippingRequestDetails, ["0", "status"], undefined)
                  )
                  ? [{ id: "", name: "-- Select --" },
                    ...incoterms.filter((item) => item.isactive === true),
                    ]
                  : [{ id: "", name: "-- Select --" }, ...incoterms]
                }
                value={extra?.inco_term || ""}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-lg-2  col-12 form-outline form-margin" style={{border:(isdistributiontempvalid(shippingRequestDetails[0]))? "":"3px solid orange"}}>
              <label className="form-label">
                {t("shipping_request.distribution_temperature")}
                <span className={classes.asterisk}>*</span>
                <HelpIcon onClick={handleOpenHelpDistributiontemp} />
              </label>
              <HelpDialog
               title={t("shipping_request.helpMatTitle")}
                message={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("shipping_request.helpdistemperature"),
                    }}
                  />
                }
                confirmationOpen={confirmationOpenDistributiontemp}
                handleCancelConfirmation={handleCloseHelpDistributiontemp}
              />
              <Select
                isDisabled={
                  !(
                    userType.includes(USERROLES.PROCESSMANAGER) ||
                    userType.includes(USERROLES.APPLICANT) ||
                    userType.includes(USERROLES.ADMIN)
                  )||
                  (status=== STATUS.IN_DELIVERY)
                }
                className="form-control form-textbox"
                list={listdata}
                value={value}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-2 col-12 form-outline form-margin">
              <label className="form-label">
                {t("shipping_request.Monitor_Temperature")}<span className="req">*</span>
                <HelpIcon onClick={handleOpenHelpDatetemp} />
              </label>
              <HelpDialog
                title={t("shipping_request.helpMatTitle")}
                message={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("shipping_request.helptemperature"),
                    }}
                  />
                }
                confirmationOpen={confirmationOpenMattemp}
                handleCancelConfirmation={handleCloseHelpDatetemp}
              />
              

              <div className="radio-container">
                <div className="radio-item">
                 <input
                      type="radio"
                    value={extra.handling_type}
                    name="handling_type"
                    checked={extra?.handling_type === 1}
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["handling_type"]: 1,
                      })
                    }
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>
                <div className="radio-item">
                  <input
                      type="radio"
                    value={extra.handling_type}
                    name="handling_type"
                    checked={extra?.handling_type === 2}
                    onChange={(e) =>
                      actions.setExtraData({
                        ...extra,
                        ["handling_type"]: 2,
                      })
                    }
                  />
                  <label htmlFor="No">No</label>
                </div>
              </div>
            </div>

            <div className="col-lg-2  col-12 form-outline form-margin" style={{border:(isreviewervalid(shippingRequestDetails[0]))? "":"3px solid orange"}}>
              <label className="form-label">
                {t("shipping_request.prefered_reviewer")}
              </label>
              <Select
              isDisabled={status === STATUS.IN_DELIVERY}
                className="form-control form-textbox"
                // list={[
                //   { id: "", name: "-- Select --" },
                //   ...regulatoryReviewer1List?.sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((item) => ({
                //     id: item,
                //     name: item,
                //   })),
                // ]}
            list={ inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
                 ?   [ { id: "", name: "-- Select --" },
                   ...regulatoryReviewer1List?.filter(obj=>obj.is_active=='true')
                   ?.sort((a,b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1)
                   .map((item) => ({
                     id: item.email,
                     name: item.email,
                   })
                 )]
                 : [ { id: "", name: "-- Select --" },
                 ...regulatoryReviewer1List?.sort((a,b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1)
                 .map((item) => ({
                   id: item.email,
                   name: item.email,
                 })
               )]

            }
                name={"prefered_reviewer"}
                value={extra?.prefered_reviewer || ""}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <br />
        </CardContent>
      </Card>
    </>
  );
};

export default withStyles(styles)(GeneralInformation);
