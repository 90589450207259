import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import UnitComponent from "./Unit";

const {
  getTitleData,
  saveTitleData,
  updateTitleAccess,
  getUnits,
  saveUnitData,
  updateUnitAccess,
} = title.actions;
const mapStateToProps = (state) => ({
  titleData: state[title.name].titleData,
  isLoader: state[title.name].isLoader,
  unitData: state[title.name].unitData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getTitleData,
      saveTitleData,
      updateTitleAccess,
      getUnits,
      saveUnitData,
      updateUnitAccess,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitComponent);
