//MyApplicationMaterialsComponent

import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApproveMaterialListing from "../../approvals/Materials/ApproveMaterialListing";
import { authContext } from "../../../adalConfig";
import MaterialSearchComponent from "./MaterialSearchComponent";
import { useLocation } from "react-router-dom";
import PageLayout from "../../common/PageLayout";

const MyApplicationMaterialsComponent = ({ loader }) => {
  const [searchFilter, setSearchFilter] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(false);

  const { instance } = useMsal();

  const userId = instance.getActiveAccount().username;
  const [flag, setFlag] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  // const cardTheme = {
  //   cardHeaderStylePref: {
  //     background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
  //     color: "#000000",
  //     height: 30,
  //     padding: 0,
  //   },
  // };
  useEffect(() => {
    if (location.pathname == "/approve/materials") {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [location.pathname]);
  const handleMaterialSelected = async (selectedMaterial) => {
    setSelectedMaterial(selectedMaterial);
  };

  return (
    <>
      <PageLayout
        title={t("my_materials.my_materials")}
        isLoading={loader}
        searchComponent={
          <MaterialSearchComponent
            onSearch={(data) => setSearchFilter(data)}
            searchKey="myApplicationMaterial"
          />
        }
        mainComponent={
          <div className="pt20">
            <ApproveMaterialListing
              searchFilter={searchFilter}
              onMaterialSelected={handleMaterialSelected}
              filter={(item) => item.created_by === userId}
              hideDeleteButton={false}
              hideCheckBox={true}
              mymaterial={flag}
            />
          </div>
        }
      />
      {/* <LoadingOverlay active={loader} spinner>
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }} m={1} pt={3} className="npmargin-padding">
            <Stack direction="row" spacing={1}>
              <label className="title_main mt-1">{t("my_materials.my_materials")}</label>
            </Stack>
          </Box>
         
          <Card variant="outlined">
            <CardHeader style={cardTheme.cardHeaderStylePref}>
              {t("materials.material")}
            </CardHeader>
            <Divider />
            <CardContent>
              <MaterialSearchComponent
                onSearch={(data) => setSearchFilter(data)}
                searchKey="myApplicationMaterial"
              />
            </CardContent>
          </Card>

          <br />
       

          <div className="row m0 container-fluid">
            <div className="no_padding col-12 ">
              <div className="buttonsec_animalmanagment">
              </div>
            </div>
          </div>
          <Card variant="outlined">
            <CardHeader style={cardTheme.cardHeaderStylePref} />
            <Divider />
            <CardContent>
              {/* Table here */}
      {/* <ApproveMaterialListing
                searchFilter={searchFilter}
                onMaterialSelected={handleMaterialSelected}
                filter={item=>item.created_by === userId}
                hideDeleteButton={false}
                hideCheckBox={true}
                mymaterial={flag}
              />
            </CardContent>
          </Card>

          <br />
        </Container>
      </LoadingOverlay> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.materials.loader,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyApplicationMaterialsComponent);
