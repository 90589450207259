import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import shipping from "../../redux/shipping";
import ConsignorComponent from "./ConsignorComponent";

const {setShipperCountry}  = shipping.actions;

const mapStateToProps = (state)=>({
    shipperCountry : state[shipping.shipperCountry]
})

const mapDispatchToProps = (dispatch)=>({
    actions:bindActionCreators({
        setShipperCountry
    },dispatch)
})

export default connect(mapStateToProps,mapDispatchToProps)(ConsignorComponent)