import { makeStyles } from "@material-ui/core/styles";

export const cardTheme = {
    cardHeaderStylePref:{
       background: 'linear-gradient(to right bottom, #D8D8D8, #E0E0E0)',
       color: '#000000',
       height: 30,
       padding: 0,
     }
 }

export const useStyles = makeStyles(theme => ({
  input: {
    "& .MuiOutlinedInput-input": {
     padding: "8px"
    }
  },
  searchBt: {
    "&:hover": {
      backgroundColor: "#1976d2",
    },
    backgroundColor: "#007FFF",
    textTransform: "none",
    marginRight: "10px",
    color: "#ffff",
    borderRadius: 0,
    fontSize: "1rem",
    marginLeft: "10px",
  },
  reloadBt: {
    backgroundColor: "#424242 !important",
  },
  cardHeader: {
    width: "100%",
    height: 30,
    backgroundColor: '#dfdfdf',
  },
  Addbtn: {
      float: 'right',
  }
}));


  export const addressSearchStyles = makeStyles((theme) => ({
    actionButtonsTop: {
      marginTop: "7px",
      marginBottom: "2px",
      float: "right",
      marginRight: "unset",
    },
    container: {
      marginTop: "15px",
    },
    searchCard: {
      minHeight: "400px",
    },
    searchBar: {},
    searchTextField: {
      backgroundColor: "#ffff",
      height: "100%",
      fontSize: "1rem",
      width: "96%",
      float: "right",
    },
    searchTitle: {
      color: "#EE2E5D",
      margin: "30px 0px 15px 30px",
      fontSize: "1rem",
      fontWeight: "600",
    },
  
    searchBt: {
      marginTop: "16px !important",
      marginRight: "10px !important",
    },
    reloadBt: {
      backgroundColor: "#424242 !important",
      marginTop: "16px !important",
    },
    cancelBt: {
      "&:hover": {
        backgroundColor: "#4e4e4e",
      },
      backgroundColor: "#4e4e4e",
      textTransform: "none",
      marginRight: "10px",
      color: "#ffff",
      borderRadius: 0,
      fontSize: "1rem",
    },
    selectOrder: {
      marginLeft: "10px",
    },
    wildCard: {
      marginLeft: "4px",
      marginRight: "4px",
    },
    errorMsg: {
      display: "flex",
      justifyContent: "center",
    },
  }));
