import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import shipping from '../../redux/shipping';
import RecipientsComponent from './RecipientsComponent'


const { 
    finalRecipientSelect,
    clearFinalRecipientSelect,
    redirectAction,
    destinationAddrSelect,
    clearDestinationAddrSelect
} = shipping.actions

const mapStateToProps = (state) => ({
    finalRecipients: state[shipping.name].finalRecipients,
    countries: state[shipping.name].countries,
})


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            finalRecipientSelect,
            clearFinalRecipientSelect,
            redirectAction,
            destinationAddrSelect,
            clearDestinationAddrSelect,
        },
        dispatch
    )
})

export default connect(
mapStateToProps,
mapDispatchToProps
)(RecipientsComponent)