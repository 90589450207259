import React, { useReducer,useEffect } from "react";
import address from "../../../redux/address";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router";
import Select from "../../common/CustomSelect";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "./addressStyles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import DropDownSearch from "../../common/DropDownSearch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const DateField = (props) => {
  registerLocale("de", de);
  const { t, i18n } = useTranslation();
  return (
    <Grid item xs={12}>
      <DatePicker
        locale={i18n.resolvedLanguage}
        portalId="root-portal"
        className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box datePicker"
        margin="normal"
        variant="outlined"
        size="small"
        dateFormat={"dd-MMM-yyyy"}
        selected={
          props.state[props.name]
        }
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
        {...props}
      />
    </Grid>
  );
};

const CheckboxField = (props) => {
  return (
    <Grid item xs={12}>
      <Checkbox
        margin="normal"
        variant="outlined"
        size="small"
        checked={props.state[props.name]?props.state[props.name]:false}
        {...props}
      />
      <span>{props.label}</span>
    </Grid>
  );
};
const AddressSerarch = (props) => {
  const { onSearch, addressList, state, actions, searchKey, hiddenFields,countries } =
  props;
  const pathAdminAddressURLs = [
    "/process/shipping",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/myShippings",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/requestByDelegate",
    "/logistics",
  ];
  const classes = addressSearchStyles();
  const history = useHistory();
  const dispatch = (event) => {
    if (event.type === "RESET") {
      onSearch({});
      return actions.setAddressSearchData({
        id: "",
        entity: "",
        department: "",
        street: "",
        postcode: "",
        city: "",
        state: "",
        sapnumber: "",
        approved: false,
        created_by: "",
        country_id: "",
        valid: false,
        submitted: false,
        startDate: "",
        endDate: "",
      });
    }

    if (event.target.type === "checkbox") {
      return actions.setAddressSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }
    if (event?.target?.value?.label !== undefined) {
      return actions.setAddressSearchData({
        ...state,
        [event.target.name]: event?.target?.value?.label,
        searchKey,
      });
    }
    return actions.setAddressSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };
  useEffect(() => {
    return () => {
      if (pathAdminAddressURLs.includes(window.location.pathname)) {
       
        actions.setTableOptions({});
      }
    };
  }, []);
  const [t, i8ln] = useTranslation();
  return (
    <>
      <Card variant="outlined" className={classes.container + "cad_header"}>
        <CardHeader title={t("phase.SearchPlaceholder")} />
        <Divider />
        <CardContent className="tablebg_box">
          <div className="row">
            <div className="col-11 pr-0">
              <div className="row col-12">
                <div className="col-lg-3  pr-0 mt-2">
                  <TextField
                    id="entity"
                    name="entity"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Company/University")}
                    onChange={dispatch}
                    value={state["entity"]}
                  />
                </div>

                {/* <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="department"
                    name="department"
                    onChange={dispatch}
                    value={state["department"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Department")}
                  />
                </div> */}

                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="street"
                    name="street"
                    onChange={dispatch}
                    value={state["street"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Street")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="city"
                    name="city"
                    onChange={dispatch}
                    value={state["city"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.City")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="state"
                    name="state"
                    onChange={dispatch}
                    value={state["state"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.State")}
                  />
                </div>
              </div>

              <div className="row col-12 p10">
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="postcode"
                    name="postcode"
                    onChange={dispatch}
                    value={state["postcode"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Postal Code")}
                  />
                </div>
                {/* <div className="col-lg-3 pr-0 mt-1">
                  <TextField
                    id="sapnumber"
                    name="sapnumber"
                    onChange={dispatch}
                    value={state["sapnumber"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.SAP Number Country")}
                  />
                </div> */}

                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <div className="row">
                    <div className="col-lg-4 pr-0">
                      <CheckboxField
                        id="approved"
                        name="approved"
                        className="check_box"
                        label={t("address.Approved")}
                        onChange={dispatch}
                        state={state}
                      />{" "}
                    </div>
                    <div className="col-lg-4 pr-0 pl-0">
                      <CheckboxField
                        id="submitted"
                        name="submitted"
                        className="check_box"
                        label={t("address.submitted")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>

                    <div className="col-lg-4 pr-0 pl-0 ">
                      <CheckboxField
                        id="valid"
                        name="valid"
                        className="check_box"
                        label={t("address.Valid")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-12 pr-0 mt-1 approvesearchcountry">
                  <DropDownSearch
                    id="country_id"
                    name="country_id"
                    value={
                      countries.find(
                        (countryItem) => countryItem.id === state["country_id"]
                      )?.name || ""
                    }
                    list={[
                      ...(countries || []).map((country) => ({
                        label: country.name,
                        id: country.id,
                      })),
                    ]}
                    handleChange={(e) => {
                      dispatch({
                        target: { name: "country_id", value: e?.id },
                      });
                    }}
                    label={t("address.Country")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="created_by"
                    name="created_by"
                    onChange={dispatch}
                    value={state["created_by"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Created User")}
                  />
                </div>
              </div>
              <div className="row col-12">
                {/* <div className="col-lg-3 pr-0 mt-1">
                  <TextField
                    id="id"
                    name="id"
                    onChange={dispatch}
                    value={state["id"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={`Id`}
                  />
                </div> */}

                <div className="col-lg-3 col-sm-12 pr-0 mt-1 date_addreserch">
                  <DateField
                    placeholderText={t("phase.createdatefrom")}
                    name="startDate"
                    id="startDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "startDate", value: date } })
                    }
                    state={state}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1 date_addreserch">
                  <DateField
                    placeholderText={t("phase.createtilldate")}
                    name="endDate"
                    id="endDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "endDate", value: date } })
                    }
                    state={state}
                  />
                </div>
              </div>
              {/* <div className="row col-12">
                
              </div> */}
            </div>
            <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => ({
  state: state[address.name].addressSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAddressSearchData: address.actions.setAddressSearchData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressSerarch);

