
import moment from "moment";
import {DateWithDefaultTime, DateWithEndTime} from "../../common/utils/DateUtil";


export function getFilterData(listData, searchFieldValues) {

    let validSearchKeys = Object.keys(searchFieldValues).filter(
        (key) => !!searchFieldValues[key],
      );
  
      const searchData = listData.filter((item) => {

        for (let searchParam of validSearchKeys) {
          if (["from_date", "to_date"].includes(searchParam)) {
            if (searchParam === "from_date") {
              const createDate = moment(item.created_on);
              const startDate = moment(searchFieldValues[searchParam]);
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on);
              const endDate = moment(searchFieldValues[searchParam]);
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          } else if (
            !item[searchParam] ||
            !item[searchParam]
              .toLowerCase()
              .includes(searchFieldValues[searchParam].toLowerCase())
          ) {
            return false;
          }
        }
          return true
      })

      return searchData;
}
