export default class AddressUtil {
  constructor(address) {
    this.address = address;
  }

  toString() {
    
    const addressItems = [
      "entity",
      "department",
      "building",
      "street",
      "postcode",
      "city",
      "state",
      "name_english",
    ];

    if(!this.address){
      return "";
    }

    let addressString = "";
    addressItems.forEach((key) => {
      if (this.address[key]) {
        if (key === "department") {
          addressString += ` (${this.address[key]})`;
          return;
        }

        if (addressString) {
          addressString += `, ${this.address[key]}`;
        } else {
          addressString = `${this.address[key]}`;
        }
      }
    });

    return addressString.trim() === "()" ? "" : addressString;
  }

  /**
   * Compare AddressUtil classes.
   *
   * @param {AddressUtil} address2
   */
  compare(address2) {
    return this.toString().localeCompare(address2.toString());
  }
}
