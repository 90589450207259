import React, { useEffect, useReducer, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import AccordionData from "../Admin/Accordian";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { addressSearchStyles } from "../Admin/Address/addressStyles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import shipping from "../../redux/shipping";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import materials from "../../redux/materials";
import Select from "../common/CustomSelect";
import { ActionTypes } from "@mui/base";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import AutocompleteText from "../common/AutocompleteText";
import "../Admin/shippingData.css";
import { STATUS, TYPE_OF_SHIPMENT } from "../../constants";
import _, { throttle } from "lodash";
import { useCallback } from "react";
import DropDownSearch from "../common/DropDownSearch";
import Checkbox from "@mui/material/Checkbox";
import tableListing from "../../redux/tableListing";
import MultipleSelect from "../common/MultipleSelect";

const fields = {
  id: "",
  id_request_old: "",
  id_old: "",
  status: "",
  entity: "",
  city: "",
  internal_distribution_number: "",
  created_by: "",
  address_id: "",
  address: "",
  final_receipt: "",
  description: "",
  created_on: "",
  tracking_number: "",
  sap_delivery_number: "",
  approved: false,
  country_id: "",
  valid: false,
  submitted: false,
  startDate: "",
  endDate: "",
  shipmentDateLevStart: "",
  shipmentDateLevEnd: "",
  shipmentDateMomStart: "",
  shipmentDateMomEnd: "",
  dateOfRecipientStart: "",
  dateOfRecipientEnd: "",
  name_english: "",
  country_of_shipper: "",
  prefered_reviewer: "",
  review_by_shipping: false
};

const DateField = (props) => {
  const { t, i18n } = useTranslation();
  registerLocale("de", de);
  return (
    <DatePicker
      locale={i18n.resolvedLanguage}
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
      margin="normal"
      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      portalId="111"
      selected={props.state[props.name]}
      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />
  );
};

const ProcessShippingSearchData = (props) => {
  const {
    onSearch,
    state,
    actions,
    searchKey,
    shippingSearchAutocompleteData,
    minimalSearch = false,
    shippingType,
    searchFor,
    Extra,
    RegulatoryReviewerList,
    Actions
  } = props;

  const classes = addressSearchStyles();
  const history = useHistory();
  const [searchAutocompleteData, setSearchAutocompleteData] = useState({});
  const location = useLocation();
  const sorter = (a, b) => {
    if (a && b) {
      return a.localeCompare(b);
    } else {
      return 0;
    }
  };

  const activeShippingTypes = shippingType.filter(item => item?.isactive).map(item => item?.name);

  useEffect(() => {
    actions.getAllMaterials();
    actions.getShippingSearchAutocompleteData();
    actions.getShippingType();
    if (searchFor && searchFor === "regulatory_reviewer") {
      Actions.getRegulatoryReviewer1List()
    }
  }, []);

  useEffect(() => {
    const data = {};
    if (shippingSearchAutocompleteData?.shippmentData) {
      data.allStatus = shippingSearchAutocompleteData.shippmentData
        .map((item) => item.status)
        .reduce((accumulator, item) => {
          if (!accumulator.includes(item)) {
            accumulator.push(item);
          }
          return accumulator;
        }, [])
        .sort(sorter);

      data.allFinalRecipients = shippingSearchAutocompleteData.shippmentData
        .map((item) => item.first_name + " " + item.last_name)
        .reduce((accumulator, item) => {
          if (!accumulator.includes(item)) {
            accumulator.push(item);
          }
          return accumulator;
        }, [])
        .sort(sorter);

      data.allCountry = shippingSearchAutocompleteData.shippmentData
        .map((item) => item.name_english)
        .filter((item) => item.trim() !== "()")
        .reduce((accumulator, item) => {
          if (!accumulator.includes(item)) {
            accumulator.push(item);
          }
          return accumulator;
        }, [])
        .sort(sorter);

      const listShippingIds = shippingSearchAutocompleteData.shippmentData.map(
        (item) => item.id,
      );

      data.allMaterialsSearch = shippingSearchAutocompleteData.materialData
        .filter((item) => listShippingIds.includes(item.shipping_request_id))
        .map((item) => item.description)
        .reduce((accumulator, item) => {
          if (!accumulator.includes(item)) {
            accumulator.push(item);
          }
          return accumulator;
        }, [])
        .sort(sorter);
    }
    setSearchAutocompleteData(data);
  }, [shippingSearchAutocompleteData]);

  //Reset search data on key change.
  useEffect(() => {
    if (state.searchKey !== searchKey) {
      dispatch({ type: "RESET" });
      onSearch({});
    } else {
      onSearch({ ...state, searchKey });
    }
  }, []);

  //clear data on page change. for preserving search data across
  //multiple page redirections.

  history.listen((location, action) => {
    if (!location.pathname.includes("shipping")) {
      // dispatch({ type: "RESET" });
    }
  });

  const dispatch = (event) => {

    if (event.type === "RESET") {
      onSearch({});
      return actions.setProcessShippingSearchData({ ...fields, searchKey });
    }

    if (event.target.type === "checkbox") {
      return actions.setProcessShippingSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }
    if (event.target.name === "type_of_shipment") {


      function convertToRequiredArray(inputArray) {
        return inputArray.map(item => {

          switch (item) {
            case "Within EU":
              return "INNER_EU";
            case "Domestic US":
              return "DOMESTIC";
            default:
              return item;
          }
        })
      }
      // data from fields for type of shipment
      const inputArray = event.target.value;

      const data = convertToRequiredArray(inputArray)
      event.target.value = data;
      return actions.setProcessShippingSearchData({
        ...state,
        [event.target.name]: event.target.value,
        searchKey,
      });
    }

    return actions.setProcessShippingSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };

  const { t, i18n } = useTranslation();
  const CheckboxField = (props) => {
    return (
      <div className="d-flex">
        <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={props.state[props.name] ? props.state[props.name] : false}
          {...props}
        />

        <label>{props.label}</label>
      </div>
    );
  };

  const handleStatusChange = useCallback(
    throttle(
      (data) => {
        const lang1 = t("status", { returnObjects: true });
        let value = [];
        const statusArray = data.target.value;
        Object.values(lang1).forEach((item, index) => {
          if ((statusArray).includes(item)) {
            value.push(Object.keys(lang1)[index]);
          }
        });

        if (!value) {
          value = [];
        }
        data = value;
        dispatch({ target: { name: "status", value: data } });
      },
      1000,
      { trailing: false },
    ),
    [dispatch],
  );

  if (minimalSearch) {
    return (
      <>
        <div className="tablebg_box">
          <div className="row">
            <div className="col-11 pr-0">
              <div className="row col-12 pt10">
               <h1>testing for pipeline</h1>
                <div className="col-lg-3  pr-0 mt-2">
                  <TextField
                    id="internal_distribution_number"
                    name="internal_distribution_number"
                    value={state["internal_distribution_number"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("shippingrequest.internalorderid")}
                    onChange={dispatch}
                  />
                </div>
                <div className="col-lg-3  pr-0 mt-2 shippindata">
                  <AutocompleteText
                    id="status"
                    name="status"
                    value={
                      state["status"] ? t("status." + state["status"]) : ""
                    }
                    label={t("shippingrequest.status")}
                    onChange={handleStatusChange}
                    options={Object.values(STATUS)
                      .map((status) => t("status." + status))
                      .sort((a, b) => a.localeCompare(b))}
                  />
                </div>

                <div className="col-lg-3  pr-0 mt-2 ">
                  <AutocompleteText
                    id="final_receipt"
                    name="final_receipt"
                    value={state["final_receipt"] ? state["final_receipt"] : ""}
                    options={searchAutocompleteData.allFinalRecipients || []}
                    onChange={dispatch}
                    className={ProcessShippingSearchData}
                    label={t("shippingrequest.finalrecipient")}
                  />
                </div>

                {/* <div className="col-lg-3  pr-0 mt-2">
                  <AutocompleteText
                    id="description"
                    name="description"
                    value={state["description"]}
                    onChange={dispatch}
                    options={searchAutocompleteData.allMaterialsSearch || []}
                    label={t("shippingrequest.material(name)")}
                  />
                </div> */}
                <div className="col-lg-3  pr-0 mt-2">
                  <DateField
                    placeholderText={t("shippingrequest.deliveredfrom")}
                    name="shipmentDateMomStart"
                    id="shipmentDateMomStart"
                    onChange={(date) =>
                      dispatch({
                        target: { name: "shipmentDateMomStart", value: date },
                      })
                    }
                    state={state}
                  />
                </div>
              </div>

              <div className="row col-12">
                <div className="col-lg-3  pr-0 mt-2">
                  <DateField
                    placeholderText={t("shippingrequest.delveredtill")}
                    name="shipmentDateMomEnd"
                    id="shipmentDateMomEnd"
                    onChange={(date) =>
                      dispatch({
                        target: { name: "shipmentDateMomEnd", value: date },
                      })
                    }
                    state={state}
                  />
                </div>

                <div className="col-lg-3  pr-0 mt-2">
                  <AutocompleteText
                    id="name_english"
                    name="name_english"
                    value={state["name_english"] ? state["name_english"] : ""}
                    options={searchAutocompleteData.allCountry || []}
                    onChange={dispatch}
                    label={t("shippingrequest.addreesedcountry")}
                  />
                </div>

                <div className="col-lg-3  pr-0 mt-2">
                  <TextField
                    id="city"
                    name="city"
                    value={state["city"]}
                    onChange={dispatch}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("shippingrequest.city")}
                  />

                </div>
                <div className="col-lg-3  pr-0 mt-2">
                  <DateField
                    placeholderText={t("shippingrequest.create_from")}
                    name="startDate"
                    id="startDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "startDate", value: date } })
                    }
                    state={state}
                  />
                </div>
              </div>

              <div className="row col-12">
                {/* <div className="col-lg-3  pr-0 mt-2">
                  <TextField
                    id="id"
                    name="id"
                    margin="normal"
                    variant="outlined"
                    value={state["id"]}
                    size="small"
                    placeholder={t("phase.id")}
                    onChange={dispatch}
                  />
                </div> */}

                <div className="col-lg-3  pr-0 mt-2">
                  <DateField
                    placeholderText={t("shippingrequest.create_till")}
                    name="endDate"
                    id="endDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "endDate", value: date } })
                    }
                    state={state}
                  />
                </div>
                {(searchKey === "adminShippingRequest" || searchKey === "shippingRequest" ?
                  <div className="col-lg-3  pr-0 mt-2">
                    <CheckboxField
                      id="review_by_shipping"
                      name="review_by_shipping"
                      className="check_box"
                      label={t("shipping_request.assigned_shipping_review")}
                      onChange={dispatch}
                      state={state}
                    />
                  </div>
                  : <></>)}
                {

                  (searchFor === "regulatory_reviewer") ?
                    <div className="col-lg-3  pr-0 mt-2">
                      <AutocompleteText
                        id="prefered_reviewer"
                        name={"prefered_reviewer"}
                        value={state["prefered_reviewer"] ? state["prefered_reviewer"] : ""}
                        options={[
                          ...RegulatoryReviewerList?.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
                        ]}
                        onChange={dispatch}
                        label={t("shipping_request.prefered_reviewer")}
                      />
                    </div> : <></>
                }
              </div>
            </div>
            <div className="col-lg-1 pt10 mt-2 d-flex justify-content-end button_addreserach">
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => {
                  onSearch({ ...state });
                }}
              >
                <SearchIcon />
              </Button>

              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="tablebg_box">
        <div className="row">
          <div className="col-11 pr-0">
            <div className="row col-12 pt10">
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="internal_distribution_number"
                  name="internal_distribution_number"
                  value={state["internal_distribution_number"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("shippingrequest.internalorderid")}
                  onChange={dispatch}
                />
              </div>
              {/* <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="sap_delivery_number"
                  name="sap_delivery_number"
                  value={state["sap_delivery_number"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("shippingrequest.sapnumber")}
                  onChange={dispatch}
                />
              </div> */}
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="tracking_number"
                  name="tracking_number"
                  value={state["tracking_number"]}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("shippingrequest.trackingnumber")}
                  onChange={dispatch}
                />
              </div>

              <div className="col-lg-3  pr-0 mt-2">
                <MultipleSelect
                  id="status"
                  name="status"
                  value={state["status"] ? t("status." + state["status"]) : ""}
                  label={t("shippingrequest.status")}
                  onChange={handleStatusChange}
                  options={Object.values(STATUS)
                    .filter((status) => (status !== "CANCELLED") && (status !== "IN_DELIVERY"))
                    .map((status) => t("status." + status))
                    .sort((a, b) => a.localeCompare(b))}
                />
              </div>

              {/* </div>


            <div className="row col-12"> */}
              <div className="col-lg-3  pr-0 mt-2">
                <MultipleSelect
                  id="final_receipt"
                  name="final_receipt"
                  value={state["final_receipt"] ? state["final_receipt"] : ""}
                  options={searchAutocompleteData.allFinalRecipients || []}
                  onChange={dispatch}
                  label={t("shippingrequest.finalrecipient")}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">
                <MultipleSelect
                  id="name_english"
                  name="name_english"
                  value={state["name_english"] ? state["name_english"] : ""}
                  options={searchAutocompleteData.allCountry || []}
                  onChange={dispatch}
                  label={t("shippingrequest.addreesedcountry")}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="city"
                  name="city"
                  value={state["city"]}
                  onChange={dispatch}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("shippingrequest.city")}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">
                <MultipleSelect
                  id="country_of_shipper"
                  name="country_of_shipper"
                  value={state["country_of_shipper"] ? state["country_of_shipper"] : ""}
                  onChange={dispatch}
                  options={searchAutocompleteData.allCountry || []}
                  label={t("shippingrequest.country_of_shipper")}
                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">
                <MultipleSelect
                  id="type_of_shipment"
                  name="type_of_shipment"
                  // value={state["type_of_shipment"] || ""}
                  value={state["type_of_shipment"] ? state["type_of_shipment"] : ""}
                  list={shippingType}
                  onChange={(e) => {
                    dispatch({
                      target: { name: "type_of_shipment", value: e.target.value },
                    });
                  }}
                  options={activeShippingTypes || []}
                  label={t("shipping_request.type_of_shipment")}

                // handleChange={(e) => {
                //   dispatch({
                //     target: { name: "type_of_shipment", value: e?.id },
                //   });
                // }}
                // getOptionLabel={(item) => {
                //   if (!item) {
                //     return "";
                //   }
                //   return item?.id
                //     ? item.id
                //     : shippingType.find((tos) => tos.id === item)?.name;
                // }}
                // renderOption={(params, item) => (
                //   <li {...params} key={item.id}>
                //     {item?.name || ""}
                //   </li>
                // )}

                // isOptionEqualToValue={(option, value) => {
                //   return option?.id === value;
                // }}
                />
              </div>

              <div className="col-lg-3  pr-0 mt-2">
                <CheckboxField
                  id="review_by_shipping"
                  name="review_by_shipping"
                  className="check_box"
                  label={t("shipping_request.assigned_shipping_review")}
                  onChange={dispatch}
                  state={state}
                />
              </div>

            </div>
          </div>
          <div className="col-lg-1 pt10 mt-2 d-flex justify-content-end button_addreserach">
            <Button
              variant="contained"
              className={classes.searchBt + " search_buttontwo"}
              onClick={() => {
                onSearch({ ...state });
              }}
            >
              <SearchIcon />
            </Button>

            <Button
              variant="contained"
              className={classes.reloadBt + " refresh_button"}
              onClick={() => dispatch({ type: "RESET" })}
            >
              <ReplayIcon />
            </Button>
          </div>
        </div>
        <div className="col-12 pl-0 pr-0">
          <div className="col-12 pl-0 pr-0">
            <AccordionData dispatch={dispatch} state={state} />
          </div>
        </div>
      </div>
    </>
  );
};

const ItemContainer = ({ children }) => {
  return <div className="col-12 col-sm-2">{children}</div>;
};

const {
  getShippingSearchAutocompleteData,
  getShippingType,
} = shipping.actions;
const { getAllMaterials } = materials.actions;
const { setProcessShippingSearchData } = tableListing.actions;

const mapStateToProps = (state) => ({
  state: state[tableListing.name].processShippingSearchData,
  allMaterials: state[materials.name].allMaterials,
  shippingSearchAutocompleteData:
    state[shipping.name].shippingSearchAutocompleteData,
  shippingType: state[shipping.name].shippingType,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setProcessShippingSearchData,
      getAllMaterials,
      getShippingType,
      getShippingSearchAutocompleteData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessShippingSearchData);
