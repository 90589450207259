import React, { useEffect } from "react";
import ShippingRequest from "../index";

import { useHistory } from "react-router-dom";

const StatusComponent = (props) => {
  const { actions, row, actionType, roleName, goBack, shippingRequestDetails } =
    props;

  const history = useHistory();

  useEffect(() => {
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    actions.getShippingActionType(actionType);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
  }, []);

  if (shippingRequestDetails.length > 0) {
    history.push({
      pathname:
         "/shipping" ,
      state: { roleName, goBack: goBack ? goBack : "/" },
    });
  }

  return <ShippingRequest {...props} />;
};

export default StatusComponent;
