import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../common/ThemeStye";

export default function AdditionalData({distributionTempValue, internalOrderIdValue, shippingCreateCommentValue}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Card variant="outlined" className="pb-0">
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t('shipping_request.additional_data')}{" "}
            </Typography>
          }
          className={classes.cardHeader}
        />
        <CardContent>
            <div className="row col-12" >
                <div className="col-lg-4 col-12 form-outline form-margin">
                    <label  className='form-label'>{t('distributiontemperature.distributiontemperature')}:</label>{distributionTempValue}
                </div>
                <div className="col-lg-4  col-12 form-outline form-margin">
                    <label  className='form-label'>{t('shipping_request.internal_order_id')}:</label>{internalOrderIdValue}
                </div>
                <div className="col-lg-4  col-12 form-outline form-margin">
                    <label  className='form-label'>{t('process_shipping_request.comment')}:</label>{shippingCreateCommentValue}
                </div>
            </div>
         
        </CardContent>
      </Card>
     
    </>
  );
}
