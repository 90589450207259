import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Card from "@mui/material/Card";
import { get } from "lodash";
import AutocompleteText from "./AutocompleteText";
import moment from "moment";
import Model from "../../shared/model";
import CardContent from "@mui/material/CardContent";
import editIcon from "../../../assests/images/editIcon.svg";
import { useHistory, useParams } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { addressSearchStyles } from "./addressStyles";
import MaterialTable from "@material-table/core";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const InspectAddress = (props) => {
  const { actions } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = addressSearchStyles();
  const [addressSelected, setAddressSelected] = useState("");
  const [sapNumber, setSapNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [department, setDepartment] = useState(null);
  const [bulliging, setBulliging] = useState(null);
  const [street, setStreet] = useState(null);
  const [postal, setPostal] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState("");
  const [addressShipping, setAddressShipping] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [reviewBy, setReviewBy] = useState('');
  const [checkboxState, setCheckBoxState] = useState(false);
  const { addressId } = useParams();
  const [rejectComment, setRejectComments] = useState("");
    
  const ItemContainer = ({ children, sm = "3" }) => {
    return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
  };

  const handleUnderReviewCheckBox = async (event) => {
    let addressData = {
      id: addressSelected.id,
      name: ''
    };
    let underReviewMessage = t("address.reviewReset");
    
    if(event.target.checked) {
      addressData.name = userId
      underReviewMessage = t("address.reviewSet");
    }

    setCheckBoxState(event.target.checked);
    setReviewBy(addressData.name);
    await actions.updateApproveAddressReviewStatus(addressData).then((response) => {
      if(response.data.IsSuccess) {
          toast.success(underReviewMessage);
      }
    });
  }

  const CheckboxField = (props) => {
    return (
      <div className="d-flex">
       <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={checkboxState}
           {...props}
        />
        <label className={classes.checkBoxLabel}>{props.label}</label>
      </div>
    );
  };

  useEffect(() => {
    if (addressId) {
      actions.getAllAddress().then((res) => {
        const addressData = res.data.filter(
          (x) => x.id === addressId,
        );
        setAddressSelected(addressData[0]);
        setSapNumber(addressData[0].sapnumber);
        setCompany(addressData[0].entity);
        setDepartment(addressData[0].department);
        setBulliging(addressData[0].building);
        setStreet(addressData[0].street);
        setPostal(addressData[0].postcode);
        setCity(addressData[0].city);
        setCountry(addressData[0].name_english);
        setState(addressData[0].state);
        setReviewBy(addressData[0].review_by);
        setCheckBoxState(addressData[0].review_by ? true : false);
        actions.getAddressShipping(addressId).then((res) => {
          if (res.data.length > 0) {
            setAddressShipping(res.data);
          }
          setIsLoading(false);
        });
      });
    }

    return () => {
      if (props.location.state?.shippingRequest) {
        actions.getShippingRequestDetails(
          props.location.state?.shippingRequest,
        );
      }
    };
  }, []);
  
  const handleClose = () => {
    history.goBack();
  };

  const handleApprove = () => {
    let addressData = {};
    if (get(addressSelected, "approved", 0) === 1) {
      addressData = {
        id: addressSelected.id,
        data: 0,
        createdBy: userId,
      };
    } else {
      addressData = {
        id: addressSelected.id,
        data: 1,
        createdBy: userId,
      };
    }
    setIsLoading(true);
    actions.updateAddressApproval(addressData).then(() => {
      actions.getAllAddressNotApproved().then(() => {
        toast.success(t("approve_address.address_approved"));
        setIsLoading(false);
        history.goBack();
      });
    });
  };
  const handleDenyApprove = () => {
    setConfirmationOpen(true);
  };
  const handleCancelConfirmation = (value) => {
    if (get(value, "target.nodeName", "BUTTON") === "DIV") {
    } else {
      setConfirmationOpen(false);
    }
  };
  const handleYesConfirmation = async () => {
    if(rejectComment.length > 0) {
      setConfirmationOpen(false);
      setIsLoading(true);
      let addressData = {};
      addressData = {
        id: addressSelected.id,
        isReject: true,
        createdBy: addressSelected.created_by,
        comment: rejectComment,
        rejectingUser: userId,
        company: addressSelected.entity
      };
      try {
        await actions.deleteAddress(addressData).then(() => {
          actions.getAllAddressNotApproved().then(() => {
            toast.success(t("approve_address.address_rejected"));
            setIsLoading(false);
            history.goBack();
          });
        });
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
        history.goBack();
      }
    }    
  };
  const handleEdit = () => {
    history.push(`/approvelEditAddress`, {
      id: get(addressSelected, "id", null),
    });
  };
  const modalBody = () => {
    return (
      <>
        <div className="row align-items-center">
          <div className="col-lg-12">
              <div className="form-group">
                  <label>{t("approve_address.disapprove_confirm_body")}</label>
              </div>
              <div className="col-lg-12 p-0">
                  <div className="form-group">
                      <label className="">
                          {" "}
                          <b>{t("comments_alert.comments")}<span className="req">*</span></b>{" "}
                      </label>
                      <TextareaAutosize
                          id="rejectComment"
                          name="rejectComment"
                          aria-label="rejectComment"
                          margin="normal"
                          variant="outlined"
                          size="large"
                          minRows={5}
                          style={{ width: "100%" }}
                          value={rejectComment.length === 0 ? "" : rejectComment}
                          onChange={(e) => { 
                              e.preventDefault()
                              setRejectComments(e.target.value)
                          }}
                      />
                  </div>
              </div>
          </div>
        </div>
      </>
    );
  }
  const modalFooterEditMethod = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary btn-inner-close"
          onClick={(e) => handleYesConfirmation(e)}
        >
          {t("commonlabels.yesButton")}
        </button>
        <button
          type="button"
          className="btn btn-black btn-inner-close"
          onClick={(e) => handleCancelConfirmation(e)}
        >
          {t("commonlabels.noButton")}
        </button>
      </>
    );
  }
  const deleteShippingRow = () => {
    if (confirmationOpen) {
      return (
        <Model
          showheader={confirmationOpen}
          modalHeader={t("approve_address.disapprove_confirm_header")}
          modalbody={modalBody()}
          modalfooter={modalFooterEditMethod()}
        ></Model>
        // <Confirmation
        //   CancelText={"No"}
        //   confirmationOpen={confirmationOpen}
        //   handleCancelConfirmation={handleCancelConfirmation}
        //   handleYesConfirmation={handleYesConfirmation}
        //   title={t("approve_materials.disapprove_confirm_header")}
        //   message={
        //     "The address will be deleted. Recipient will be retained. Caution: Any shipping request using this address will have no address afterwards!"
        //   }
        // />
      );
    }
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };

  const editShippment = async (row) => {
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    actions.getShippingActionType("EDIT");
    actions.clearShippingMaterialSelectedValue();
    actions.addShippingMaterialSelect([]);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
    history.push("/shipping");
  };
  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        {deleteShippingRow()}
        <div className="title_main ml-3 pt10">
          {t("address.inspectaddressTitle")}
        </div>
        <Card className={classes.container}>
          <CardContent>
            <div className="container-fluid pr-0 inspectaddress ">
              <div className="col-12 row pr-0">
                <ItemContainer sm="6">
                  <div className="row m-0 p-0" style={{ display: "none" }}>
                    <ItemContainer sm="3">
                      <span className={classes.textLables}>
                        {t("address.SAP Number Country")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="9" className="pl-0">
                      <AutocompleteText
                        name="Company"
                        options={[]}
                        value={sapNumber}
                        is_disabled={true}
                        label=" "
                        onChange={() => {}}
                        required={true}
                      />
                    </ItemContainer>
                  </div>
                  <Card variant="outlined" className={classes.container}>
                    <Divider />
                    <CardContent className="form-padding">
                      <div className="row m-0 p-0"></div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.Company/University")}:
                          </span>
                          <span>
                            <AutocompleteText
                              name="Company"
                              options={[]}
                              value={company}
                              is_disabled={true}
                              label=" "
                              onChange={() => {}}
                              required={true}
                            />
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.Department")}:
                          </span>
                          <span>
                            <AutocompleteText
                              name="Company"
                              options={[]}
                              value={department}
                              is_disabled={true}
                              label=" "
                              onChange={() => {}}
                              required={true}
                            />
                          </span>
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.building")}:{" "}
                          </span>
                          <AutocompleteText
                            name="Company"
                            options={[]}
                            value={bulliging}
                            is_disabled={true}
                            label=" "
                            onChange={() => {}}
                            required={true}
                          />
                        </ItemContainer>
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.Street")}:{" "}
                          </span>
                          <AutocompleteText
                            name="Company"
                            options={[]}
                            value={street}
                            is_disabled={true}
                            label=" "
                            onChange={() => {}}
                            required={true}
                          />
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.Postal Code")}:
                          </span>
                          <AutocompleteText
                            name="Company"
                            options={[]}
                            value={postal}
                            is_disabled={true}
                            label=" "
                            onChange={() => {}}
                            required={true}
                          />
                        </ItemContainer>
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.City")}:{" "}
                          </span>

                          <AutocompleteText
                            name="Company"
                            options={[]}
                            value={city}
                            is_disabled={true}
                            label=" "
                            onChange={() => {}}
                            required={true}
                          />
                        </ItemContainer>
                      </div>

                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.State")}:{" "}
                          </span>

                          <AutocompleteText
                            name="Company"
                            options={[]}
                            value={state}
                            is_disabled={true}
                            label=" "
                            onChange={() => {}}
                            required={true}
                          />
                        </ItemContainer>
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.Country")}:{" "}
                          </span>
                          <AutocompleteText
                            name="Company"
                            options={[]}
                            value={country}
                            is_disabled={true}
                            label=" "
                            onChange={() => {}}
                            required={true}
                          />
                        </ItemContainer>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="6">
                          <span className={classes.textLables}>
                            {t("address.review")}:{" "}
                          </span>
                          <CheckboxField
                            id="review_by"
                            name="review_by"
                            className="check_box"
                            label={reviewBy ? reviewBy : ''}
                            onChange={(e) => handleUnderReviewCheckBox(e)}
                          />
                        </ItemContainer>
                      </div>
                    </CardContent>
                  </Card>
                </ItemContainer>
                <ItemContainer sm="6">
                  <Card variant="outlined" className={classes.container}>
                    <CardContent>
                      <div className="ml-2 mt-2">
                        {t("commonlabels.noticeEmptyInspectAddress")}
                      </div>
                    </CardContent>
                  </Card>
                  <MaterialTable
                    columns={[
                      {
                        title: (
                          <span>{t("shippingrequest.internalorderid")}</span>
                        ),
                        field: "internal_distribution_number",
                        sorting: false,
                      },
                      {
                        title: <span>{t("address.Create Date")}</span>,
                        sorting: false,
                        render: (rowData) =>
                          dateLocalization(rowData.created_on),
                      },
                      {
                        title: <span>{t("address.Created User")}</span>,
                        sorting: false,
                        field: "created_by",
                      },
                      {
                        title: <span>{t("address.Status")}</span>,
                        sorting: false,
                        render: (rowData) => t("status." + rowData.status),
                      },
                      {
                        title: <span></span>,
                        sorting: false,
                        render: (rowData) => (
                          <span>
                            <a
                              href="#"
                              style={{
                                color: "red",
                                paddingLeft: "10px",
                                textDecoration: "none",
                              }}
                              id="openmodalForEdit"
                              onClick={(e) => {
                                editShippment(rowData);
                              }}
                            >
                              <img alt="t" className="" src={editIcon}></img>{" "}
                            </a>
                          </span>
                        ),
                      },
                    ]}
                    data={addressShipping}
                    title="Render Image Preview"
                    localization={{
                      pagination: {
                        labelRowsSelect: t("commonlabels.labelRowsSelect"),
                        firstAriaLabel: t("commonlabels.firstAriaLabel"),
                        firstTooltip: t("commonlabels.firstTooltip"),
                        previousAriaLabel: t("commonlabels.previousAriaLabel"),
                        previousTooltip: t("commonlabels.previousTooltip"),
                        nextAriaLabel: t("commonlabels.nextAriaLabel"),
                        nextTooltip: t("commonlabels.nextTooltip"),
                        lastAriaLabel: t("commonlabels.lastAriaLabel"),
                        lastTooltip: t("commonlabels.lastTooltip"),
                      },
                      body: {
                        emptyDataSourceMessage: t(
                          "commonlabels.emptyDataSourceMessage",
                        ),
                      },
                    }}
                    options={{
                      draggable: false,
                      thirdSortClick: false,
                      headerStyle: {
                        backgroundColor: "#cce3f5",
                        fontWeight: "bold",
                        height: "10px",
                      },
                      rowStyle: {
                        height: "30px",
                        padding: "0px",
                      },

                      maxBodyHeight: "90%",
                      tableLayout: "auto",
                      sorting: false,
                      search: false,
                      selection: false,

                      showTitle: false,
                      toolbar: false,
                      paging: false,
                      emptyRowsWhenPaging: false,
                      showSelectAllCheckbox: false,
                    }}
                  />
                </ItemContainer>
              </div>
            </div>
          </CardContent>
          <div className="container-fluid ">
            <div className="row pull-right ">
              <CardActions>
                <button
                  className="btn btn-secondary new_button"
                  onClick={handleClose}
                >
                  {t("commonlabels.previousLabel")}
                </button>
                <button
                  className="btn btn-primary new_button"
                  onClick={handleEdit}
                >
                  {t("commonlabels.edit")}
                </button>
                <button
                  className="btn btn-success new_button"
                  onClick={handleApprove}
                >
                  {t("address.approve")}
                </button>
                <button
                  className="btn btn-primary new_button"
                  onClick={handleDenyApprove}
                >
                  {t("address.denyApproval")}
                </button>
              </CardActions>
            </div>
          </div>
        </Card>
      </LoadingOverlay>
    </>
  );
};

export default InspectAddress;
