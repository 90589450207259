import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import regulatory from "../../redux/regulatory";
import address from "../../redux/address";
import shipping from "../../redux/shipping";
import materials from "../../redux/materials";
import RegulatoryReviewer from "./RegulatoryReviewer";
import ReviewerTab from "./ReviewerTab"
import tableListing from "../../redux/tableListing";
import RecipientList from "../../redux/recipients";

const {
    getAllShippingData,
    saveRegulatoryReveiwerDetails,
    saveRegulatoryReveiwer2Details,
    getAllReviewerMasterData,
    getApproveRegDetails,
    getApproveReg1condition,
    getRegulatoryReviewer1List,
    setRegulatoryTableOptions,
    setRegulatorySearchData
} = regulatory.actions


const {
    getAllAddress,
    saveAddress,
    getAddress,
    editAddress,
    updateAddressAccess,
    updateAddressApproval,
    getAddressRecipient,
    getAllCountry,
  } = address.actions;

  const {
    getCountries,
    isLoader,
    getCities,
    getRequestByDeputy,
    getProcessShippmentList,
    getShippingRequestDetails,
    getDeputyShippingDetails,
    getMaterialShippingDetails,
    getShippingActionType,
    clearShippingMaterialSelectedValue,
    addShippingMaterialSelect,
    getShippingAttachment,
    getShipperDetails,
    redirectAction,
    setExtraData
  } = shipping.actions;

  const {saveTableData} = tableListing.actions;
  
  const mapStateToProps = (state) => ({
    allAddress: state[address.name].allAddress,
    isLoader: state[address.name].isLoader,
    countries: state[address.name].allCountry,
    cities: state[shipping.name].cities,
    requestDeputy: state[shipping.name].requestDeputy,
    shipper
    :state[shipping.name].shipper,
    allRecipients:state[RecipientList.name].allRecipients,
    allMaterials: state[materials.name].allMaterials,
    allregulatory:state[regulatory.name].allregulatory,
    regulatoryData:state[regulatory.name].regulatoryData,
    shippingRequestDetails:state[shipping.name].shippingRequestDetails,
    regulatoryReviewer1List: state[regulatory.name].regulatoryReviewer1List,
    extra : state[shipping.name].extra,
    tableRegOptions: state[regulatory.name].tableRegOptions,
    regulatorySearchData:state[regulatory.name].regulatorySearchData,

  });
  
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        setRegulatorySearchData,
        setRegulatoryTableOptions,
        getAllReviewerMasterData,
        saveRegulatoryReveiwerDetails,
        saveRegulatoryReveiwer2Details,
        getAllAddress,
        getAllCountry,
        getAddress,
        getCountries,
        getCities,
        saveAddress,
        editAddress,
        updateAddressAccess,
        updateAddressApproval,
        getAddressRecipient,
        getRequestByDeputy,
        getProcessShippmentList,
        getShippingRequestDetails,
        getDeputyShippingDetails,
        getMaterialShippingDetails,
        getShippingActionType,
        clearShippingMaterialSelectedValue,
        addShippingMaterialSelect,
        getShippingAttachment,
        getShipperDetails,
        getAllShippingData,
        redirectAction,
        getApproveRegDetails,
        getApproveReg1condition,
        saveTableData,
        getRegulatoryReviewer1List,
        setExtraData
      },
      dispatch,
    ),
  });

  
  export default {
    RegulatoryReviewer: connect(
      mapStateToProps,
      mapDispatchToProps,
    )(RegulatoryReviewer),
    ReviewerTab: connect(
      mapStateToProps,
      mapDispatchToProps,
    )(ReviewerTab),
  };