import React from "react";

import { TableCell, TableRow } from "@mui/material";
import { TextField, TextareaAutosize } from "@material-ui/core";
import { useReducer } from "react";
import "./invoice.css";
import { INVOICE_TYPES } from "../../constants";

export default function InvoiceMaterialEditTable({
  materialShippingDetails,
  onChange,
  invoiceType,
}) {
  const reducer = (state, data) => {
    const editedMaterial = state.find(
      (material) => material.material_id === data.material_id
    );
    editedMaterial[data.event.target.name] = data.event.target.value;
    onChange({ type: "materialData", data: [...state] });
    return [...state];
  };

  const [state, dispatch] = useReducer(reducer, materialShippingDetails);

  return (
    <>
      {state?.map((row) => (
        <TableRow key={row.material_id}>
          <TableCell>
            <TextField
              name="quantity"
              margin="normal"
              variant="outlined"
              size="small"
              className="columnnetquantity borderOutlineedit"
              onChange={(event) =>
                dispatch({ event, material_id: row.material_id })
              }
              value={row.quantity}
            />
          </TableCell>
          <TableCell>
            <TextareaAutosize
              name="description"
              margin="normal"
              minRows={2}
              className="editdescriptiontextarea borderOutlineedit"
              variant="plain"
              onChange={(event) =>
                dispatch({ event, material_id: row.material_id })
              }
              value={row.description}
            />
          </TableCell>
          <TableCell>
            <TextField
              name="country_of_origin"
              margin="normal"
              variant="outlined"
              size="small"
              className="columnnetcoo borderOutlineedit"
              onChange={(event) =>
                dispatch({ event, material_id: row.material_id })
              }
              value={row.country_of_origin}
            />
          </TableCell>          
          <TableCell>
            <TextField
              name="cost"
              margin="normal"
              variant="outlined"
              size="small"
              className="columnnetvalue borderOutlineedit"
              onChange={(event) =>
                dispatch({ event, material_id: row.material_id })
              }
              value={row.cost}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
