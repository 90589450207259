import React, { useReducer,useEffect } from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "./addressStyles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import DropDownSearch from "../../common/DropDownSearch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import address from "../../../redux/address";

const DateField = (props) => {
  registerLocale("de", de);
  const { i18n } = useTranslation();
  return (
    <Grid item xs={12}>
      <DatePicker
      locale={i18n.resolvedLanguage}
        className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box datePicker"
        margin="normal"
        variant="outlined"
        size="small"
        portalId="1122"
        dateFormat="dd-MMM-yyyy"
        popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
        selected={
          props.state[props.name]
        }
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        autoComplete="false"
        {...props}
      />
    </Grid>
  );
};

const CheckboxField = (props) => {
  return (
    <div className="d-flex">
     <Checkbox
        margin="normal"
        variant="outlined"
        size="small"
        checked={props.state[props.name]?props.state[props.name]:false}
        {...props}
      />
     
      <label>{props.label}</label>
    </div>
  );
};
const AddressSerarch = (props) => {
  const { onSearch, addressList, state, actions, searchKey, hiddenFields,countries } =
  props;
  const classes = addressSearchStyles();
  const pathApprovalAddressURLs = [
    "/process/shipping",
    "/approve/materials",
    "/approve/recipients",
    "/materials",
    "/recipients",
    "/address",
    "/shippingRequests",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/myShippings",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/requestByDelegate",
    "/logistics",
  ];
  const dispatch = ( event) => {
    if (event.type === "RESET") {
      onSearch({});
      return  actions.setApprovalAddressSearchData({
        id: "",
        entity: "",
        department: "",
        street: "",
        postcode: "",
        city: "",
        sapnumber: "",
        approved: false,
        created_by: "",
        country_id: "",
        valid: false,
        submitted: false,
        startDate: "",
        endDate: "",
        state:"",
        review_by: false
      });
    }

    if (event.target.type === "checkbox") {
      return actions.setApprovalAddressSearchData({
        ...state,
        [event.target.name]: event?.target?.checked,
      });
    }
    return actions.setApprovalAddressSearchData({
      ...state,
      [event.target.name]: event?.target?.value,
    });
  };
  useEffect(() => {
    return () => {
      if (pathApprovalAddressURLs.includes(window.location.pathname)) {
        actions.setApprovalAddressSearchData({});
      }
    };
  }, []);

  const [t] = useTranslation();
  
  return (
    <>
      {" "}
      <label className="title_main mt-1">{t("address.Approve Address")}</label>
      <Card variant="outlined" className={classes.container}>
        <CardHeader title={t("phase.SearchPlaceholder")} />
        <Divider />
        <CardContent className="tablebg_box">
          <div className="row ">
            <div className="col-11 pr-0">
              <div className="row col-12 pt10">
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="entity"
                    name="entity"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Company/University")}
                    onChange={dispatch}
                    value={state["entity"]}
                  />
                </div>

                {/* <div className="col-lg-3 col-sm-12 pr-0 mt-1">
              <TextField
                id="department"
                name="department"
                onChange={dispatch}
                value={state["department"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.Department")}
              />
            </div> */}

                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="street"
                    name="street"
                    onChange={dispatch}
                    value={state["street"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Street")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="city"
                    name="city"
                    onChange={dispatch}
                    value={state["city"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.City")}
                  />
                </div>

                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="state"
                    name="state"
                    onChange={dispatch}
                    value={state["state"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.State")}
                  />
                </div>
              </div>

              <div className="row col-12 pt10">
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="postcode"
                    name="postcode"
                    onChange={dispatch}
                    value={state["postcode"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Postal Code")}
                  />
                </div>
                {/* <div className="col-lg-3 col-sm-12 pr-0 mt-1">
          
              <TextField
                id="sapnumber"
                name="sapnumber"
                onChange={dispatch}
                value={state["sapnumber"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={t("address.SAP Number Country")}
              />
            </div> */}

                <div className="col-lg-3 col-sm-12 pr-0 mt-1 approveaddcheckbox">
                  <div className="row">
                    <div className="col-lg-4 pr-0  ">
                      <CheckboxField
                        id="approved"
                        name="approved"
                        className="check_box"
                        label={t("address.Approved")}
                        onChange={dispatch}
                        state={state}
                      />{" "}
                    </div>
                    <div className="col-lg-4 pr-0 pl-0 pr-0 ">
                      <CheckboxField
                        id="submitted"
                        name="submitted"
                        className="check_box"
                        label={t("address.submitted")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>

                    <div className="col-lg-4 pl-0 pr-0 ">
                      <CheckboxField
                        id="valid"
                        name="valid"
                        className="check_box"
                        label={t("address.Valid")}
                        onChange={dispatch}
                        state={state}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                        <CheckboxField
                          id="review_by"
                          name="review_by"
                          className="check_box"
                          label={t("address.review")}
                          onChange={dispatch}
                          state={state}
                        />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-12 pr-0 mt-1 approvesearchcountry ">
                  <DropDownSearch
                    id="country_id"
                    name="country_id"
                    value={
                      countries.find(
                        (countryItem) => countryItem.id === state["country_id"]
                      )?.name || ""
                    }
                    list={[
                      ...(countries || []).map((country) => ({
                        label: country.name,
                        id: country.id,
                      })),
                    ]}
                    handleChange={(e) => {
                      dispatch({
                        target: { name: "country_id", value: e?.id },
                      });
                    }}
                    label={t("address.Country")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="created_by"
                    name="created_by"
                    onChange={dispatch}
                    value={state["created_by"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.Created User")}
                  />
                </div>
              </div>
              <div className="row col-12 pt-10">
                {/* <div className="col-lg-3 col-sm-12 pr-0 mt-1">
              <TextField
                id="id"
                name="id"
                onChange={dispatch}
                value={state["id"]}
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={`Id`}
              />
            </div> */}

                <div className="col-lg-3 col-sm-12 pr-0 mt-1 customDatePickerWidth date_addreserch">
                  <DateField
                    placeholderText={t("phase.createdatefrom")}
                    name="startDate"
                    id="startDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "startDate", value: date } })
                    }
                    state={state}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1 date_addreserch">
                  <DateField
                    placeholderText={t("phase.createtilldate")}
                    name="endDate"
                    id="endDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "endDate", value: date } })
                    }
                    state={state}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => ({
  state: state[address.name].addressApprovalSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setApprovalAddressSearchData: address.actions.setApprovalAddressSearchData,
    },
    dispatch,
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddressSerarch);
