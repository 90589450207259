import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import CountryListingComponent from "./CountryListingComponent";
import CountrySearch from "./CountrySearch";
import { addressContainerStyles } from "../Address/addressStyles";

const CountryCatalogueComponent = (props) => {
  const { actions, allAddress, countries } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const classes = addressContainerStyles();
  useEffect(() => {
    actions.getAllCountry().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Divider />
        <Container maxWidth="xxxl" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CountrySearch
                countries={countries}
                onSearch={(data) => setSearchFilter(data)}
                searchKey={"countrySearch"}
              />
            </Grid>
            <Grid item xs={12}>
              <CountryListingComponent
                listData={countries}
                actions={actions}
                searchFilter={searchFilter}
                setIsLoading={setIsLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </LoadingOverlay>
    </>
  );
};

export default CountryCatalogueComponent;
