import React, { useReducer } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Select from "../../common/CustomSelect";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "../../Admin/Address/addressStyles";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CountrySearch = ({ onSearch, countries }) => {
  const classes = addressSearchStyles();
  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return {
        code: "",
        name: "",
        name_english: "",
      };
    }
    return { ...state, [event.target.name]: event.target.value };
  };
  const [state, dispatch] = useReducer(reducer, {
    code: "",
    name: "",
    name_english: "",
  });

  const [t, i8ln] = useTranslation();

  return (
    <>
     <label className='title_main mt-1' 
                     >
                        {t("countrycatalogue.countrycatalogue")}
                </label>      

     
      <Card>
      <CardHeader title={t("phase.SearchPlaceholder")} />
        <Divider />
        <CardContent className="tablebg_box">
          <div className="row">
            <div className="col-11 pr-0">
              <div className="row col-12">
                <div className="col-lg-4  pr-0 mt-2">
                  <TextField
                    id="code"
                    name="code"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("countrycatalogue.Code")}
                    onChange={dispatch}
                    value={state["code"]}
                  />
                </div>
                <div className="col-lg-4  pr-0 mt-2">
                  <TextField
                    id="name"
                    name="name"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("recipient.name")}
                    onChange={dispatch}
                    value={state["name"]}
                  />
                </div>
                <div className="col-lg-4  pr-0 mt-2">
                  <TextField
                    id="name_english"
                    name="name_english"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("countrycatalogue.nameenglish")}
                    onChange={dispatch}
                    value={state["name_english"]}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Divider />
      <br></br>
    </>
  );
};

export default CountrySearch;
