import React, { useState, useEffect, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { TextField } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import { trim } from "lodash";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../adalConfig";
import { toast } from "react-toastify";
import Select from "../../common/CustomSelect";
import AutocompleteText from "../../Admin/Address/AutocompleteText";
import { addressSearchStyles } from "./addressStyles";
import { SortFunctionNew } from "../../../Services/util";
import ActionConfirmModal from "../../common/ActionConfirmModal";

const AddAddress = (props) => {
  const { actions, countries, allAddress } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const { t } = useTranslation();
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryList, setCountryList] = useState(countries);
  const classes = addressSearchStyles();
  const [sapNumber, setSapNumber] = useState();
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");
  const [bulliging, setBulliging] = useState("");
  const [street, setStreet] = useState("");
  const [postal, setPostal] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [createdby, setCreatedby] = useState(null);
  const [recipient, setRecipient] = useState([]);
  const [selectedAdd, setSelectAdd] = useState({});
  const [confirmDuplicateSave, setConfirmDuplicateSave] = useState(false);
  const [addressDuplicateDataToSave, setAddressDuplicateDataToSave] = useState({});
  const ItemContainer = ({ children, sm = "3" }) => {
    return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
  };
  useEffect(() => {
    if (props.location.state) {
      if (countries.length === 0) {
        actions.getCountries().then((res) => {
          setCountryList(res.data);
        });
      }
      if (allAddress.length === 0) {
        actions.getAllAddress().then((res) => {});
      }
      actions.getAddress(props.location.state.id).then((res) => {
        const addressData = res.data;
        setSelectAdd(addressData[0]);
        setSapNumber(addressData[0].sapnumber);
        setCompany(addressData[0].entity);
        setDepartment(addressData[0].department);
        setBulliging(addressData[0].building);
        setStreet(addressData[0].street);
        setPostal(addressData[0].postcode);
        setCity(addressData[0].city);
        setState(addressData[0].state);
        setCountry(addressData[0].country_id);
        setCreatedby(addressData[0].created_on);
        actions.getAddressRecipient(props.location.state.id).then((res) => {
          setIsLoading(false);
          if (res.data.length > 0) {
            setRecipient(res.data);
          }
        });
      });
      setEdit(true);
    }
  }, []);
  const processedAddresslData = useMemo(() => {
    const data = {
      address_id: [],
      Approved: [],
      building: [],
      material_number: [],
      city: [],
      state: [],
      department: [],
      entity: [],
      postcode: [],
      sapnumber: [],
      street: [],
      submmited: [],
      valid: [],
      Createdby: [],
      updatedBy: [],
    };

    const keys = Object.keys(data);

    for (let item of allAddress) {
      for (let key of keys) {
        if (item[key] && !data[key].includes(trim(item[key]))) {
          data[key].push(trim(item[key]));
        }
      }
    }
    return data;
  }, [allAddress]);

  const handleClose = () => {
    history.goBack();
  };
  const handleSave = () => {
    setIsLoading(true);
    try {
      let error = "";

      if (!company) {
        error = t("address.Company/University") + ", ";
      }
      if (!street) {
        error = error + t("address.Street") + ", ";
      }

      if (!city) {
        error = error + t("address.City") + ", ";
      }

      if (!country) {
        error = error + t("address.Country");
      }
      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req3") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req4"),
        );
      }
      if (edit) {
        const addressData = {
          address_id: props.location.state.id,
          country_Id: country,
          Approved: selectedAdd.approved,
          building: bulliging,
          city: city,
          state: state,
          department: department,
          entity: company,
          postcode: postal,
          sapnumber: sapNumber,
          street: street,
          submmited: selectedAdd.submitted,
          valid: selectedAdd.valid,
          Createdby: createdby,
          updatedBy: userId,
        };
        actions.editAddress(addressData).then((res) => {
          if(res.data.isDuplicateFound) {
            setAddressDuplicateDataToSave(addressData);
            setConfirmDuplicateSave(true);
          }
          if(res.data.isSuccessful) {
            setIsLoading(false);
            toast.success(t("commonlabels.editedSuccessfullyMsg"));
            actions.getAllAddress();
            history.goBack();
          }
        });
      }
      // history.push(`/approvelInspectAddress`, {
      //   id: get(props, "location.state.id", 2),
      // });
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "sap_number") {
      setSapNumber(value);
    } else if (name === "Company") {
      setCompany(value);
    } else if (name === "Department") {
      setDepartment(value);
    } else if (name === "Bulliging") {
      setBulliging(value);
    } else if (name === "Street") {
      setStreet(value);
    } else if (name === "Postal") {
      setPostal(value);
    } else if (name === "City") {
      setCity(value);
    } else if (name === "State") {
      setState(value);
    }
  };
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setCountry(value);
  };
  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <div className="col-12 title_main mt-1">
          {t("address.addressEditTitle")}
        </div>
        <div className="col-12 pt10">
          <Card variant="outlined" className={classes.container}>
            <CardContent>
              <div className="row col-12 ">
                <div className={`col-12 col-sm-6 form_item`}>
                  <div className="row" style={{ display: "none" }}>
                    <ItemContainer sm="2">
                      <span className={classes.textLableSAP}>
                        {t("address.SAP Number Country")}
                      </span>
                    </ItemContainer>
                    <div className={`col-12 col-sm-9 form_item`}>
                      <TextField
                        id="sap_number"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        name="sap_number"
                        value={sapNumber}
                        onChange={handleChange}
                        style={{ width: "60%" }}
                      />
                    </div>
                  </div>

                  <Card variant="outlined" className={classes.container}>
                    <CardHeader title={t("address.addNewAddressTitle")} />
                    <Divider />
                    <CardContent className="form-padding">
                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.Company/University")}
                            <span className={classes.textLableAsterisk}>*</span>
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="Company"
                            options={
                              processedAddresslData.entity.sort((a, b) => {
                                a = a == null ? "" : a.toLowerCase();
                                b = b == null ? "" : b.toLowerCase();
                                if (a === b) return 0;
                                return a < b ? -1 : 1;
                              }) || []
                            }
                            value={company}
                            label=" "
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.Department")}
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="Department"
                            options={
                              processedAddresslData.department.sort((a, b) => {
                                a = a == null ? "" : a.toLowerCase();
                                b = b == null ? "" : b.toLowerCase();
                                if (a === b) return 0;
                                return a < b ? -1 : 1;
                              }) || []
                            }
                            value={department}
                            label=" "
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.building")}
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="Bulliging"
                            options={
                              processedAddresslData.building.sort((a, b) => {
                                a = a == null ? "" : a.toLowerCase();
                                b = b == null ? "" : b.toLowerCase();
                                if (a === b) return 0;
                                return a < b ? -1 : 1;
                              }) || []
                            }
                            value={bulliging}
                            label=" "
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.Street")}
                            <span className={classes.textLableAsterisk}>*</span>
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="Street"
                            options={
                              processedAddresslData.street.sort((a, b) => {
                                a = a == null ? "" : a.toLowerCase();
                                b = b == null ? "" : b.toLowerCase();
                                if (a === b) return 0;
                                return a < b ? -1 : 1;
                              }) || []
                            }
                            value={street}
                            label=" "
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.Postal Code")}
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="Postal"
                            options={processedAddresslData.postal || []}
                            value={postal}
                            label=" "
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.City")}
                            <span className={classes.textLableAsterisk}>*</span>
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="City"
                            options={
                              processedAddresslData.city.sort((a, b) => {
                                a = a == null ? "" : a.toLowerCase();
                                b = b == null ? "" : b.toLowerCase();
                                if (a === b) return 0;
                                return a < b ? -1 : 1;
                              }) || []
                            }
                            value={city}
                            label=" "
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span className={classes.textLable}>
                            {t("address.State")}
                            <span className={classes.textLableAsterisk}>*</span>
                          </span>
                        </ItemContainer>
                        <div className={`col-12 col-sm-9 form_item`}>
                          <AutocompleteText
                            name="State"
                            options={
                              processedAddresslData.state.sort((a, b) => {
                                a = a == null ? "" : a.toLowerCase();
                                b = b == null ? "" : b.toLowerCase();
                                if (a === b) return 0;
                                return a < b ? -1 : 1;
                              }) || []
                            }
                            value={state}
                            label=" "
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row m-0 p-0">
                        <ItemContainer sm="3">
                          <span
                            className={classes.textLable}
                            style={{ marginTop: "8px" }}
                          >
                            {t("address.Country")}
                            <span className={classes.textLableAsterisk}>*</span>
                          </span>
                        </ItemContainer>
                        <ItemContainer sm="9">
                          <Select
                            list={countryList}
                            value={country}
                            name="Country"
                            onChange={handleCountryChange}
                            style={{ width: "100%" }}
                            labelName={" "}
                          />
                        </ItemContainer>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <ItemContainer sm="6">
                  <MaterialTable
                    columns={[
                      {
                        title: <span>{"Name"}</span>,
                        field: "first_name",
                        customSort: (a, b) => {
                          if (
                            (a.first_name + a.last_name)
                              .toString()
                              .toLowerCase() >
                            (b.first_name + b.last_name)
                              .toString()
                              .toLowerCase()
                          ) {
                            return -1;
                          } else if (
                            (a.first_name + a.last_name)
                              .toString()
                              .toLowerCase() <
                            (b.first_name + b.last_name)
                              .toString()
                              .toLowerCase()
                          ) {
                            return 1;
                          } else {
                            return 0;
                          }
                        },
                        render: (rowData) => (
                          <span>
                            {rowData.first_name} {rowData.last_name}
                          </span>
                        ),
                      },

                      {
                        title: <span>{t("registration.Email")}</span>,
                        field: "email",
                        customSort: SortFunctionNew("email"),
                      },
                      {
                        title: <span>{t("registration.Phone")}</span>,
                        field: "phone",
                        customSort: SortFunctionNew("phone"),
                      },

                      {
                        title: <span>{t("search_fields.created_by")}</span>,
                        field: "created_by",
                        customSort: SortFunctionNew("created_by"),
                      },
                      {
                        title: <span>{t("address.Approved")}</span>,
                        customSort: (a, b) => {
                          if (a.approved > b.approved) {
                            return -1;
                          } else if (a.approved < b.approved) {
                            return 1;
                          } else if (a.approved === b.approved) {
                            return 0;
                          } else {
                            return 0;
                          }
                        },
                        render: (rowData) =>
                          rowData.approved === 1 ? (
                            <span>
                              <div>
                                <input
                                  type="checkbox"
                                  defaultChecked={true}
                                  disabled
                                />
                              </div>
                            </span>
                          ) : (
                            <div>
                              <span>
                                <div>
                                  <input
                                    type="checkbox"
                                    defaultChecked={false}
                                    disabled
                                  />
                                </div>
                              </span>
                            </div>
                          ),
                      },
                      {
                        title: <span>{t("address.Valid")}</span>,
                        customSort: (a, b) => {
                          if (a.valid > b.valid) {
                            return -1;
                          } else if (a.valid < b.valid) {
                            return 1;
                          } else if (a.valid === b.valid) {
                            return 0;
                          } else {
                            return 0;
                          }
                        },
                        render: (rowData) =>
                          rowData.valid === 1 ? (
                            <span>
                              <div>
                                <input
                                  type="checkbox"
                                  defaultChecked={true}
                                  disabled
                                />
                              </div>
                            </span>
                          ) : (
                            <div>
                              <span>
                                <div>
                                  <input
                                    type="checkbox"
                                    defaultChecked={false}
                                    disabled
                                  />
                                </div>
                              </span>
                            </div>
                          ),
                      },
                    ]}
                    data={recipient}
                    title="Render Image Preview"
                    localization={{
                      pagination: {
                        labelRowsSelect: t("commonlabels.labelRowsSelect"),
                        firstAriaLabel: t("commonlabels.firstAriaLabel"),
                        firstTooltip: t("commonlabels.firstTooltip"),
                        previousAriaLabel: t("commonlabels.previousAriaLabel"),
                        previousTooltip: t("commonlabels.previousTooltip"),
                        nextAriaLabel: t("commonlabels.nextAriaLabel"),
                        nextTooltip: t("commonlabels.nextTooltip"),
                        lastAriaLabel: t("commonlabels.lastAriaLabel"),
                        lastTooltip: t("commonlabels.lastTooltip"),
                      },
                      body: {
                        emptyDataSourceMessage: t(
                          "commonlabels.emptyDataSourceMessage",
                        ),
                      },
                    }}
                    options={{
                      draggable: false,
                      thirdSortClick: false,
                      headerStyle: {
                        backgroundColor: "#cce3f5",
                        fontWeight: "bold",
                        height: "10px",
                      },
                      rowStyle: {
                        height: "30px",
                        padding: "0px",
                      },

                      maxBodyHeight: "90%",
                      tableLayout: "auto",
                      sorting: false,
                      search: false,
                      selection: false,

                      showTitle: false,
                      toolbar: false,
                      paging: false,
                      emptyRowsWhenPaging: false,
                      showSelectAllCheckbox: false,
                    }}
                  />
                  <Box
                    style={{
                      margiTop: "15px",
                      marginBottom: "30px",
                    }}
                  >
                    <div>{t("address.addNewAddressFooter")}</div>
                  </Box>
                </ItemContainer>
              </div>
            </CardContent>
            <CardActions className={classes.cardActionButtons}>
              <button
                className="btn btn-primary new_button"
                onClick={handleSave}
              >
                {t("commonlabels.saveButton")}
              </button>
              <button
                className="btn btn-black new_button"
                onClick={handleClose}
              >
                {t("commonlabels.cancelbutton")}
              </button>
            </CardActions>
          </Card>
        </div>
      </LoadingOverlay>
      <ActionConfirmModal
        show={confirmDuplicateSave}
        headerText={t("address.duplicateAddress")}
        bodyText={t("address.duplicateSave")}
        confirmButtonText={t("commonlabels.yesButton")}
        cancelButtonText={t("commonlabels.noButton")}
        onConfirm={() => {
          addressDuplicateDataToSave.isSaveDuplicate = true;
          if (edit) {
            actions.editAddress(addressDuplicateDataToSave).then((res) => {
              if(res.data.isSuccessful) {
                setIsLoading(false);
                toast.success(t("commonlabels.editedSuccessfullyMsg"));
                actions.getAllAddress();
                history.goBack();
              }          
            });
          }
          setConfirmDuplicateSave(false);
        }}
        onCancel={() => {
          setIsLoading(false);
          setConfirmDuplicateSave(false);
        }}
      />
    </>
  );
};

export default AddAddress;
