import navbar from "../navbar";
import types from "./types";

export const saveTableData = (payload) => {
  return (dispatch, getState)=>{
    const navBarState = getState()[navbar.name].navKey;
    return dispatch({
      type: types.SAVE_TABLE_DATA,
      payload:{
        selectedRow: payload,
        pageIdentifier: navBarState
      },
    });
  }
 
};
const setProcessShippingSearchData = (payload = "") => {
  return {
    type: types.SET_PROCESS_SHIPPING_SEARCH_DATA,
    payload,
  };
};

export default {
  saveTableData,
  setProcessShippingSearchData
};
