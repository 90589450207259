import React from "react";
import { useTranslation } from "react-i18next";
import {
  SortFunctionNew,
} from "../../Services/util";
import AddressUtil from "./utils/AddressUtil";

export function AddressColumns(
  selectedRow,
  handlerSelectedRow,
  getFirstRowData,
) {
  const { t } = useTranslation();

  const defaultSelectedRow = (row) => {
    if (row.tableData.id === 0 && selectedRow.tableData.id === 0) {
      getFirstRowData(row);
      return;
    }
  };

  const columns = [
    {
      width: "10%",
      sorting: false,
      render: (rowData) => {
        defaultSelectedRow(rowData);
        return (
          <input
            type="radio"
            checked={
              rowData.tableData.id === selectedRow.tableData.id ? true : false
            }
            value={selectedRow}
            name="radiovalues"
            onClick={(e) => handlerSelectedRow(rowData)}
          />
        );
      },
      // render: rowData =>
      //     <input type="radio"
      //         value = {rowData.id}
      //         name={rowData.city}
      //      onClick={e =>
      //         handleDestinationAddr(rowData)
      //     }
      //      />
    },

    {
      title: <span>{t("address.Company/University")}</span>,
      field: "entity",
      customSort: SortFunctionNew("entity"),
    },

    // {
    //   title: <span>{t("address.Department")}</span>,
    //   field: "department",
    //   customSort: SortFunctionNew("department"),
    // },
    {
      title: <span>{t("address.Street")}</span>,
      field: "street",
      customSort: SortFunctionNew("street"),
    },
    {
      title: <span>{t("address.City")}</span>,
      field: "city",
      customSort: SortFunctionNew("city"),
    },
    {
      title: <span>{t("address.State")}</span>,
      field: "state",
      customSort: SortFunctionNew("state"),
    },
    {
      title: <span>{t("address.Postal Code")}</span>,
      field: "postcode",
      customSort: SortFunctionNew("postcode"),
    },
  
    {
      title: <span>{t("address.Country")}</span>,
      field: "name_english",
      field_id: "country_id",
      type: "select",
      customSort: SortFunctionNew("name_english"),
      // render: (rowData) => {
      //     return `${rowData.name_english} (${rowData.code})`
      // }
    },
    {
      title: <span>{t("address.Approved")}</span>,
      customSort: (a, b) => {
        if (a.approved > b.approved) {
          return -1;
        } else if (a.approved < b.approved) {
          return 1;
        } else if (a.approved === b.approved) {
          return 0;
        } else {
          return 0;
        }
      },
      render: (rowData) =>
        rowData.approved === 1 ? (
          <span>
            <div>
              <input type="checkbox" defaultChecked={true} disabled />
            </div>
          </span>
        ) : (
          <div>
            <span>
              <div>
                <input type="checkbox" defaultChecked={false} disabled />
              </div>
            </span>
          </div>
        ),
    },
    {
      title: <span>{t("address.Valid")}</span>,
      customSort: (a, b) => {
        if (a.valid > b.valid) {
          return -1;
        } else if (a.valid < b.valid) {
          return 1;
        } else if (a.valid === b.valid) {
          return 0;
        } else {
          return 0;
        }
      },
      render: (rowData) =>
        rowData.valid === 1 ? (
          <span>
            <div>
              <input type="checkbox" defaultChecked={true} disabled />
            </div>
          </span>
        ) : (
          <div>
            <span>
              <div>
                <input type="checkbox" defaultChecked={false} disabled />
              </div>
            </span>
          </div>
        ),
    },
  ];

  return columns;
}

export function RecipientsColumns(
  selectedRow,
  handlerSelectedRow,
  getFirstRowData,
) {
  const { t } = useTranslation();

  const defaultSelectedRow = (row) => {
    if (row.tableData.id === 0 && selectedRow.tableData.id === 0) {
      getFirstRowData(row);
      return;
    }
  };

  const columns = [
    {
      width: "10%",
      sorting: false,
      render: (rowData) => {
        defaultSelectedRow(rowData);
        return (
          <input
            type="radio"
            checked={
              rowData.tableData.id === selectedRow.tableData.id ? true : false
            }
            value={selectedRow}
            name="radiovalues"
            onClick={(e) => handlerSelectedRow(rowData)}
          />
        );
      },

      // <input type="radio"
      //     value = {rowData.id}
      //     name={rowData.city}
      //  onClick={e =>
      //     handlefinalRecipients(rowData)
      // }
      // />
    },

    {
      title: <span>{t("search_fields.first_name")}</span>,
      field: "first_name",
      customSort: SortFunctionNew("first_name"),
    },

    {
      title: <span>{t("recipient.Last name")}</span>,
      field: "last_name",
      customSort: SortFunctionNew("last_name"),
    },
    {
      title: <span>{t("recipient.Email")}</span>,
      field: "email",
      customSort: SortFunctionNew("email"),
    },
    {
      title: <span>{t("recipient.Address")}</span>,
      render: (rowData) => new AddressUtil(rowData).toString(),
      customSort: (a, b) => {
        return new AddressUtil(a).compare(new AddressUtil(b));
      },
    },
    {
      title: <span>{t("search_fields.name_english")}</span>,
      render: (rowData) => {
        if (rowData.name === " ()") {
          return "";
        }
        else {
          return rowData.name;
        }
      },
      customSort: SortFunctionNew("name"),
    },
    // {
    //   title: <span>{t("search_fields.id")}</span>,
    //   field: "id",
    //   customSort: SortFunctionNew("id"),
    // },
    {
      title: <span>{t("search_fields.approved")}</span>,
      field: "approved",
      customSort: SortFunctionNew("approved"),
      render: (rowData) =>
        rowData.approved === 1 ? (
          <span>
            <div>
              <input type="checkbox" defaultChecked={true} disabled />
            </div>
          </span>
        ) : (
          <div>
            <span>
              <div>
                <input type="checkbox" defaultChecked={false} disabled />
              </div>
            </span>
          </div>
        ),
    },
    {
      title: <span>{t("search_fields.valid")}</span>,
      field: "valid",
      render: (rowData) =>
        rowData.valid === 1 ? (
          <span>
            <div>
              <input type="checkbox" defaultChecked={true} disabled />
            </div>
          </span>
        ) : (
          <div>
            <span>
              <div>
                <input type="checkbox" defaultChecked={false} disabled />
              </div>
            </span>
          </div>
        ),
      customSort: SortFunctionNew("valid"),
    },
    {
      title: <span>{t("search_fields.created_by")}</span>,
      field: "created_by",
      customSort: SortFunctionNew("created_by"),
    },
  ];

  return columns;
}
