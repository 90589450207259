import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { authContext } from "../../adalConfig";
import Model from "../shared/model";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import editIcon from "../../assests/images/editIcon.svg";
import moment from "moment";
import { get } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaterialTable from "@material-table/core";
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { addressSearchStyles } from "../Admin/Address/addressStyles";
import {
  dateSortFunction,
  sortFunction,
  SortFunctionNew,
} from "../../Services/util";
import crossicon from "../../assests/images/crossicon.png";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { DateFormat,DateWithEndTime,DateWithDefaultTime } from "../common/utils/DateUtil";
toast.configure();
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const DistributionTemperatureListing = (props) => {
  const { actions, distributionTemperature, searchFilter, setIsLoading } =
    props;
  const [filteredData, setfilteredData] = useState(distributionTemperature);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");

  const { t, i18n } = useTranslation();
  const classes = addressSearchStyles();
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const [userSelected, setUserSelected] = useState("");
  const [distributiontemp, setDistributiontemp] = useState("");
  const [distributiontempEnglsih, setDistributiontempEnglsih] = useState("");
  const [isEditModelVisible, setIsEditModelVisible] = useState(false);

  //Load on Mount

  const history = useHistory();

  const openmodalForEdit = () => {
    setDistributiontemp();

    setDistributiontempEnglsih();
    setIsEditModelVisible(true);
  };

  const closemodal = () => {
    setDistributiontemp("");

    setDistributiontempEnglsih("");
    setIsEditModelVisible(false);
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Distribution_Temperature_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      copyOfFilteredData &&
        copyOfFilteredData.forEach((element) => {
          if (element.created_on) {
            element.created_on = DateFormat(
              element.created_on,
              i18n.resolvedLanguage,
            );
          }
          if (element.shipment_date_mon) {
            element.shipment_date_mon = DateFormat(
              element.shipment_date_mon,
              i18n.resolvedLanguage,
            );
          }
          if (element.shipment_date_lev) {
            element.shipment_date_lev = DateFormat(
              element.shipment_date_lev,
              i18n.resolvedLanguage,
            );
          }
          if (element.first_name) {
            element.first_name = element.first_name + " " + element.last_name;
          }
        });

      item[t("distributiontemperature.temperaturediscription")] =
        item["temperature_description"];
      item[t("distributiontemperature.temperaturediscriptionenglish")] =
        item["temperature_description_english"];
      //  item[("ID")]=item["id"];

      item[t("address.Created User")] = item["created_by"];
      item[t("address.Create Date")] = item["created_on"];
      item[t("phase.Valid")] = item["valid"];

      delete item["temperature_description"];
      delete item["temperature_description_english"];
      delete item["id"];
      delete item["created_by"];
      delete item["created_on"];
      delete item["name"];
      delete item["tableData"];
      delete item["valid"];
      delete item["created_by"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["is_active"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };
  useEffect(() => {
    setfilteredData(distributionTemperature);
  }, [distributionTemperature]);

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key],
    );

    const filteredData = distributionTemperature.filter((item) => {
      for (let searchParam of validSearchKeys) {
        if (["valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["created_on", "updated_on"].includes(searchParam)) {
          if (searchParam === "created_on") {
            const createDate = moment(item.created_on);
            const created_on = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(created_on))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.created_on);
            const updated_on = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(updated_on))) {
              return false;
            }
            continue;
          }
        }

        if (
          !item[searchParam] ||
          !item[searchParam]
            .toLowerCase()
            .includes(searchFilter[searchParam].toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });

    setfilteredData(filteredData);
  }, [searchFilter, distributionTemperature]);

  const handleActivate = () => {
    let distributionData = {};
    if (get(userSelected, "valid", 0) === 1) {
      distributionData = {
        id: userSelected.id,
        data: 0,
      };
    } else {
      distributionData = {
        id: userSelected.id,
        data: 1,
      };
    }
    setIsLoading(true);
    actions.updateDistributionAccess(distributionData).then(() => {
      setfilteredData([]);
      setUserSelected("");
      actions.getDistributionTemperature().then(() => {
        setIsLoading(false);
      });
    });
  };
  const handleRadiobuttonChange = (data) => {
    setUserSelected(data);
  };

  const handleSave = () => {
    try {
      let error = "";

      if (!distributiontemp) {
        error = t("distributiontemperature.temperaturediscription") + ", ";
      }
      if (!distributiontempEnglsih) {
        error =
          error + t("distributiontemperature.temperaturediscriptionenglish");
      }

      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req3") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req4"),
        );
      }
      if (1) {
        const distributionTemperature = {
          distribution: distributiontemp,
          distribution_english: distributiontempEnglsih,
          updated_by: userId,
        };
        setIsLoading(true);
        actions.saveDistribution(distributionTemperature).then((res) => {
          if(res.data) {
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            // history.push(`/shipping`);
            actions.getDistributionTemperature().then(() => {
              setIsEditModelVisible(false);
              setIsLoading(false);
            });
          }
          else {
            setIsLoading(false);
            toast.error(t("distributiontemperature.temperaturediscriptionenglish") + ' ' + t("commonlabels.duplicate"))
          }  
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }    
  };

  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };

  const getTableData = () => {
    return (
      <div style={{ paddingBottom: "2%" }}>
        <MaterialTable
          columns={[
            {
              width: "15%",
              sorting: false,
              render: (rowData) => (
                <input
                  type="radio"
                  value={get(userSelected, "id", "")}
                  name="radiovalues"
                  className={classes.tableRadio}
                  onChange={(e) => handleRadiobuttonChange(rowData)}
                />
              ),
            },

            {
              title: (
                <span>
                  {t("distributiontemperature.temperaturediscription")}
                </span>
              ),
              field: "temperature_description",
              customSort: SortFunctionNew("temperature_description"),
            },

            {
              title: (
                <span>
                  {t("distributiontemperature.temperaturediscriptionenglish")}
                </span>
              ),
              field: "temperature_description_english",
              customSort: SortFunctionNew("temperature_description_english"),
            },
            // {
            //   title: <span>Id</span>,
            //   field: "id",
            //   customSort: (a, b) => {
            //     if (parseInt(a.id) > parseInt(b.id)) {
            //       return -1;
            //     } else if (parseInt(a.id) < parseInt(b.id)) {
            //       return 1;
            //     } else {
            //       return 0;
            //     }
            //   },
            // },
            {
              title: <span> {t("address.Create Date")}</span>,
              render: (rowData) => (
                <span>{dateLocalization(rowData.created_on)}</span>
              ),
              customSort: dateSortFunction("created_on"),
            },

            {
              title: <span>{t("address.Created User")}</span>,
              field: "created_by",
              customSort: SortFunctionNew("created_by"),
            },

            {
              title: <span>{t("phase.Valid")}</span>,
              customSort: (a, b) => {
                if (a.valid > b.valid) {
                  return -1;
                } else if (a.valid < b.valid) {
                  return 1;
                } else if (a.valid === b.valid) {
                  return 0;
                } else {
                  return 0;
                }
              },

              render: (data) =>
                data.valid === 1 ? (
                  <span>
                    <div>
                      <input type="checkbox" defaultChecked={true} disabled />
                    </div>
                  </span>
                ) : (
                  <div>
                    <span>
                      <div>
                        <input
                          type="checkbox"
                          defaultChecked={false}
                          disabled
                        />
                      </div>
                    </span>
                  </div>
                ),
            },
            // {
            //     title:
            //         <span>

            //         </span>
            //     ,
            //     sorting:false,

            //     render: rowData =>

            //             <span>
            //                 <a
            //     id="openmodalForEdit"
            //     onClick={(e) => {
            //       openmodalForEdit(rowData);
            //     }}
            //   >
            //                     <img alt="t" className="" src={editIcon} ></img>{" "}
            //                 </a>
            //             </span>

            // }
          ]}
          data={filteredData}
          title="Render Image Preview"
          icons={tableIcons}
          localization={{
            pagination: {
              labelRowsSelect: t("commonlabels.labelRowsSelect"),
              firstAriaLabel: t("commonlabels.firstAriaLabel"),
              firstTooltip: t("commonlabels.firstTooltip"),
              previousAriaLabel: t("commonlabels.previousAriaLabel"),
              previousTooltip: t("commonlabels.previousTooltip"),
              nextAriaLabel: t("commonlabels.nextAriaLabel"),
              nextTooltip: t("commonlabels.nextTooltip"),
              lastAriaLabel: t("commonlabels.lastAriaLabel"),
              lastTooltip: t("commonlabels.lastTooltip"),
            },
            body: {
              emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
            },
          }}
          options={{
            draggable: false,
            thirdSortClick: false,

            headerStyle: {
              backgroundColor: "lightgray",
              fontWeight: "bold",
              height: "10px",
            },
            rowStyle: {
              height: "30px",
              padding: "0px",
            },

            maxBodyHeight: "90%",
            tableLayout: "auto",
            sorting: true,
            search: false,
            selection: false,

            showTitle: false,
            toolbar: false,
            paging: true,
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 40],
            emptyRowsWhenPaging: false,
            showSelectAllCheckbox: false,
          }}
        />
      </div>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() != "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <div>
      <div className="row m0 container-fluid">
        <div className="no_padding col-12 ">
          <div className={classes.actionButtonsTop}>
            <button
              className="btn btn-primary btn-inner-close new_button mr-1"
              // disabled={get(addressSelected, "id", 0) === 0 ? true : false}
              onClick={() => {
                setIsExportModalVisible(true);
              }}
            >
              {t("shippingrequest.export")}
            </button>
            <button
              className="btn btn-primary btn-inner-close new_button mr-2"
              onClick={(e) => {
                openmodalForEdit();
              }}
            >
              {t("address.new")}
            </button>

            <button
              className="btn btn-inner-approve   new_button"
              onClick={() => handleActivate()}
              disabled={get(userSelected, "id", 0) === 0 ? true : false}
            >
              {get(userSelected, "valid", 0) === 1
                ? t("address.deactivate")
                : t("address.activate")}
            </button>
            {/* <TextField size="small" placeholder="Search" /> */}
          </div>
        </div>
      </div>

      <Card variant="outlined">
        <CardHeader
          style={cardTheme.cardHeaderStylePref}
          title={t("materials.search_results")}
        />
        <Divider />
        <CardContent>
          <div className="row m0">
            <div className="w-100">
              <div className="">{getTableData()}</div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      {/* Edit  */}
      <Modal
        dialogClassName="modal-90w"
        show={isEditModelVisible}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header

        // closeButton
        // oncloseButton={()=>{setIsEditModelVisible(false)}}
        >
          <h5 className="modal-title">
            {" "}
            {t("commonlabels.newButton") +
              " " +
              t("navbar.distributiontemperature")}{" "}
          </h5>

          <a id="openmodalForEdit" onClick={(e) => closemodal(e)}>
            <img alt="t" className="" src={crossicon}></img>{" "}
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4 m0">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t("distributiontemperature.temperaturediscription")}{" "}
                      <span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form-control"
                      id="Phase"
                      name="Phase"
                      value={distributiontemp}
                      onChange={(e) => setDistributiontemp(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t(
                        "distributiontemperature.temperaturediscriptionenglish",
                      )}{" "}
                      <span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form-control"
                      id="Phaseenglish"
                      name="Phaseenglish"
                      value={distributiontempEnglsih}
                      onChange={(e) =>
                        setDistributiontempEnglsih(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            id="updateMethodButton"
            className="btn btn-primary btn-inner-close"
            onClick={handleSave}
          >
            {t("commonlabels.saveButton")}
          </button>
          <button
            type="button"
            id="closeUpdateMethodButton"
            className="btn btn-black btn-inner-close"
            onClick={(e) => closemodal(e)}
          >
            {t("commonlabels.cancelbutton")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DistributionTemperatureListing;
