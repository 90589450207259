import React from "react";

import { TYPE_OF_SHIPMENT } from "../../constants";
import { useTranslation } from "react-i18next";

const DestinationAddressDetails = (props) => {
  const { destinationAddrSelect, extra, shippingType } = props;
  const { t } = useTranslation();

  const iscountryvalid = (obj) => {
    if(obj?.countryvalid == null || Object.keys(obj).length == 0) return true
    if(obj.countryvalid == true) return true 
    return false
  }
  const shipType=shippingType.find((obj)=>obj?.id===extra?.type_of_shipment)
  const isshipmentTypevalid = (obj) => {
    if(obj?.isactive == null || shipType == undefined || Object.keys(obj).length == 0) return true
    if(obj?.isactive == true) return true 
    return false
  }
  return (
    <>
      <div className="row col-12 " >
        <div className="col-lg-2  col-12 form-outline form-margin" style={{color:(isshipmentTypevalid(shipType))?"":"orange"}}>
          <label className="col-form-label">
            {t("shipping_request.type_of_shipment")}
          </label>
          {
            shippingType.find((item) => item.id === extra?.type_of_shipment)
              ?.name
          }
        </div>
        <div className="col-lg-2  col-12 form-outline form-margin">
          <label className="col-form-label">{t("search_fields.entity")}</label>
          {destinationAddrSelect.entity}
        </div>
        <div className="col-lg-2  col-12 form-outline form-margin">
          <label className="col-form-label">
            {t("search_fields.department")}
          </label>
          {destinationAddrSelect.department}
        </div>
        <div className="col-lg-2  col-12 form-outline form-margin">
          <label className="col-form-label"> {t("address.building")}</label>
          {destinationAddrSelect.building}
        </div>
        <div className="col-lg-2 col-12">
          <label className="col-form-label"> {t("address.Street")}</label>
          {destinationAddrSelect.street}
        </div>
        <div className="col-lg-2  col-12 form-outline form-margin">
          <label className="col-form-label"> {t("address.Postal Code")} </label>
          {destinationAddrSelect.postcode}
        </div>
      </div>
      <div className="row col-12 "></div>
      <div className="row col-12 "></div>
      <div className="row col-12 ">
        <div className="col-lg-2  col-12 form-outline form-margin">
          <label className="col-form-label">{t("address.State")}</label>
          {destinationAddrSelect.state}
        </div>
        <div className="col-lg-2  col-12 form-outline form-margin">
          <label className="col-form-label">{t("address.City")}</label>
          {
            destinationAddrSelect.city
            // ?  destinationAddrSelect.city : shippingRequestDetails.city
          }
        </div>
        <div className="col-lg-2  col-12 form-outline form-margin" style={{color:(iscountryvalid(destinationAddrSelect))?"":"orange"}} >
          <label className="col-form-label">{t("address.Country")}</label>
          {
            destinationAddrSelect.name_english
            // ?  destinationAddrSelect.name_english : shippingRequestDetails.name_english
          }
        </div>
      </div>
    </>
  );
};

export default DestinationAddressDetails;
