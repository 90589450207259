import { makeStyles } from "@material-ui/core/styles";

export const useCommentsModalStyles = makeStyles((theme) => ({
    modalHeight: {
        maxHeight: "calc(100vh - 140px)",
        overflowY: "auto"
    },
    textAreaScroll: {
       Height: "auto !important",
       overflow: "auto !important",
       maxHeight:"200px !important",
       minHeight:"100px !important"
    },
    modalCloseIcon: {
        fontSize: "2rem",
        textShadow:"none",
        color:"#ffffff",
        opacity:"1"
    },
    tablearrowcls: {
        width: "30px",
        minWidth: "30px",
        maxWidth: "30px"
   },
    tablefromcls: {
         width: "80px",
         minWidth: "80px",
         maxWidth: "80px"
    },
    tableshippingreqcls: {
     width: "60px",
     minWidth: "60px",
     maxWidth: "60px"
},
    tableisviewedcls: {
     width: "50px",
     minWidth: "50px",
     maxWidth: "50px"
},
    tablecommentscls: {
         width: "160px",
         minWidth: "160px",
         maxWidth: "160px"
    },
    tabletaggerUsercls: {
         width: "160px",
         minWidth: "160px",
         maxWidth: "160px"
    },
    tableDatecls: {
         width: "60px",
         minWidth: "60px",
         maxWidth: "60px"
    },
    tablereplybtncls: {
        width: "50px",
        minWidth: "50px",
        maxWidth: "50px"
   },
   tableChildcommentscls: {
    width: "180px",
    minWidth: "180px",
    maxWidth: "180px"
   },
   tablechildfromcls: {
    width: "60px",
    minWidth: "60px",
    maxWidth: "60px"
   },
   tableChildTaggerUsercls: {
     width: "160px",
     minWidth: "160px",
     maxWidth: "160px"
   },
   mt9:{
     marginTop: "9px"
   },
   alerttitle:{
     marginLeft: "5px",
     fontSize: "20px !important"
   }
  }));