import { React, useState, forwardRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { get } from "lodash";
import moment from "moment";
import Model from "../../shared/model"; 
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../../assests/images/editIcon.svg";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "../Address/addressStyles";
import { dateSortFunction, sortFunction, SortFunctionNew } from "../../../Services/util";
import { utils, writeFile } from 'xlsx';
import _ from 'lodash';
import {DateFormat,DateWithEndTime,DateWithDefaultTime} from "../../common/utils/DateUtil";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

const CountryListingComponent = (props) => {
  const [addressSelected, setAddressSelected] = useState("");

  const { listData, actions, searchFilter, setIsLoading } = props;
  const [filteredData, setfilteredData] = useState(listData);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const classes = addressSearchStyles();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };
  useEffect(() => {
    setfilteredData(listData);
  }, [listData]);

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key],
    );

    const filteredData = listData.filter((item) => {
      for (let searchParam of validSearchKeys) {
        if (["submitted", "approved", "valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (searchParam === "startDate") {
            const createDate = moment(item.created_on);
            const startDate = moment(searchFilter[searchParam]);
            if (createDate.isBefore(DateWithDefaultTime(startDate))) {
              return false;
            }
            continue;
          } else {
            const createDate = moment(item.created_on);
            const endDate = moment(searchFilter[searchParam]);
            if (createDate.isAfter(DateWithEndTime(endDate))) {
              return false;
            }
            continue;
          }
        }
        if (
          [
            "id",
            "entity",
            "department",
            "street",
            "postcode",
            "city",
            "sapnumber",
          ].includes(searchParam)
        ) {
          if (
            (get(item, searchParam, " ") == null
              ? " "
              : get(item, searchParam, " ")
            ).match(get(searchFilter, searchParam, " ")) === null
          ) {
            return false;
          } else {
            continue;
          }
        }

        if (
          !item[searchParam]
            .toLowerCase()
            .includes(searchFilter[searchParam].toLowerCase())
        ) {
          return false;
        }
      }
      return true;
    });

    setfilteredData(filteredData);
  }, [searchFilter]);
  const { t, i18n } = useTranslation();

  const handleRadiobuttonChange = (data) => {
    setAddressSelected(data);
  };

  const handleCSVExport = async () => {
  
    let dataForExport = exportDataBuilder()
    let wb = utils.book_new(), ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "CountryCatalogue");
    writeFile(wb, requiredFileName+".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData
    if (filteredData.length === 0) {
        copyOfFilteredData = _.cloneDeep(filteredData)
    }
    else if (filteredData.length > 0) {
        copyOfFilteredData = _.cloneDeep(filteredData)
    }
   
    copyOfFilteredData.forEach((item) => {
        //changing column names
          if (item.created_on) {
            item.created_on = DateFormat(item.created_on,i18n.resolvedLanguage);
          }
         
        if (item.dateofrecipient) {
          item.dateofrecipient = DateFormat(item.dateofrecipient,i18n.resolvedLanguage);
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate,i18n.resolvedLanguage);
    }
   


        item[t("Country.Code")] = item["code"];
        item[t("recipient.name")] =  item["name"];
        item[t("countrycatalogue.nameenglish")] = item["name_english"];
       
  
        delete item["code"];
        delete item["name"]; 
        delete item["name_english"]; 
        delete item["is_active"];
        delete item["valid"];
        delete item["updated_on"];
        delete item["updated_by"];
        delete item["id"]; 
        delete item["created_by"]; 
        delete item["created_on"];
     
      
        for (let key in item) {
            if (item[key] === null) {
                item[key] = ""
            }
        }
    })
    return _.cloneDeep(copyOfFilteredData);
}
const exportModalBody = () => {
  return (
    <div className="row  m0">
      <div className="col-12">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="form-group">
              <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
              <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
            </div>
            
           

          </div>
        </div>
      </div>
    </div>
  );
};
const exportModalFooter = () => {
  return (
    <>
 
   
      <button
        type="button"
        id="Yes"
        disabled={requiredFileName.trim() != "" ? false : true}
        className="btn btn-primary btn-inner-close"
        onClick={(e) => {
          handleCSVExport()
          setIsExportModalVisible(false);
          setrequiredFileName("");
        }}
      >
        {t("export.export")}
      </button>
      
      <button
        type="button"
        id="Close"
        //disabled={isModalSaveRunning}
        className="btn btn-black btn-inner-close cancel_button"
        onClick={(e) => {
          setIsExportModalVisible(false);
          setrequiredFileName("");
        }}
      >
        Cancel
      </button>
    </>
  );
};

  return (
    <>

<div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
            
            <div className={classes.actionButtonsTop}>
             <button
                className="btn btn-primary btn-inner-close new_button mr-1"
               // disabled={get(addressSelected, "id", 0) === 0 ? true : false}
                onClick={() => { setIsExportModalVisible(true); }}>
                {t("shippingrequest.export")}
              </button>
           
            {/* //  {inspectHideAndShow()} */}
            </div>
          </div>
       
      <Card variant="outlined">
        <CardHeader
          title={t("phase.searchresults")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
            <MaterialTable
              columns={[
                // {
                //   width: "15%",
                //   sorting: false,
                //   render: (rowData) => (
                //     <input
                //       type="radio"
                //       value={get(addressSelected, "id", "")}
                //       name="radiovalues"
                //       className={classes.tableRadio}
                //       onChange={(e) => handleRadiobuttonChange(rowData)}
                //     />
                //   ),
                // },
                {
                  title: <span>{t("Country.Code")}</span>,
                  field: "code",
                  customSort: SortFunctionNew("code"),
                },

                {
                  title: <span>{t("recipient.name")}</span>,
                  field: "name",
                  customSort: SortFunctionNew("name"),
                },
                {
                  title: <span>{t("countrycatalogue.nameenglish")}</span>,
                  field: "name_english",
                  customSort: SortFunctionNew("name_english"),
                },
              ]}
              data={filteredData}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                    labelRowsSelect: t("commonlabels.labelRowsSelect"),
                    firstAriaLabel: t("commonlabels.firstAriaLabel"),
                    firstTooltip: t("commonlabels.firstTooltip"),
                    previousAriaLabel: t("commonlabels.previousAriaLabel"),
                    previousTooltip: t("commonlabels.previousTooltip"),
                    nextAriaLabel: t("commonlabels.nextAriaLabel"),
                    nextTooltip: t("commonlabels.nextTooltip"),
                    lastAriaLabel: t("commonlabels.lastAriaLabel"),
                    lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                    emptyDataSourceMessage: t(
                        "commonlabels.emptyDataSourceMessage"
                    ),
                },
            }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: {
                  height: "30px",
                  padding: "0px",
                },
                sortIcon: true,
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 40],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
          </div>
        </CardContent>
      </Card>
      <Model
          showheader={isExportModalVisible}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBody()}
          modalfooter={exportModalFooter()}
        ></Model>
    </>
  );
};

export default CountryListingComponent;
