import React, { useState,useEffect } from "react";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialData from "../../Admin/materials/MaterialData";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoadingOverlay from "react-loading-overlay";
import ApproveMaterialListing from "./ApproveMaterialListing";

import { useLocation } from "react-router-dom";


const Materials = ({ loader,tableApproveMaterialOptions }) => {
  const [searchFilter, setSearchFilter] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(false);
 
 
  const [flag, setFlag] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  // const cardTheme = {
  //   cardHeaderStylePref: {
  //     background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
  //     color: "#000000",
  //     height: 30,
  //     padding: 0,
  //   },
  // };
  
useEffect(() => {

    if (location.pathname == "/approve/materials") {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [location.pathname]);

  const handleMaterialSelected = async (selectedMaterial) => {
    setSelectedMaterial(selectedMaterial);
  };

  return (
    <>
      <LoadingOverlay active={loader} spinner>
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }} m={0} pt={1}>
           
              <label className="title_main ">{t("approve_materials.approve_materials")}</label>
            
          </Box>
         
          <Card variant="outlined">
          <CardHeader title={t("phase.SearchPlaceholder")} />
            <Divider />
            <CardContent>
              <MaterialData
                onSearch={(data) => setSearchFilter(data)}
                searchKey="approve"
              />
            </CardContent>
          </Card>

      

          <div className="row m0 no_padding container-fluid">
            <div className="no_padding col-12 ">
              <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
                {/* <button
                  type="button"
                  name="inspect"
                  id="inspect"
                  className="btn btn-primary btn-inner-close new_button"
                  disabled={!selectedMaterial}
                  onClick={() => {
                    history.push(
                      `/view/approve/material/${selectedMaterial?.id}`,
                      { selectedMaterial }
                    );
                  }}
                >
                  {t("approve_materials.check")}
                </button> */}
              </div>
            </div>
          </div>
          <Card variant="outlined">
            
            <Divider />
            <CardContent>
              {/* Table here */}
              {!loader &&
              <ApproveMaterialListing
                searchFilter={searchFilter}
                onMaterialSelected={handleMaterialSelected}
                filter={item => item.submitted && !item.approved}
                hideDeleteButton={true}
                hideCheckBox={false}
                mymaterial={flag}
                tableApproveMaterialOptions={tableApproveMaterialOptions}
              />
}
            </CardContent>
          </Card>

         
        </Container>
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.materials.loader,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
