import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import shipping from "../../../redux/shipping";
import StatusComponent from "./StatusComponent";

const {
  getShippingRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  getShippingActionType,
  getShippingAttachment,
  getShipperDetails,
} = shipping.actions;

const mapStateToProps = (state) => ({
  shippingRequestDetails: state[shipping.name].shippingRequestDetails,
  shippingActionType: state[shipping.name].shippingActionType,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getShippingRequestDetails,
      getDeputyShippingDetails,
      getMaterialShippingDetails,
      getShippingActionType,
      getShippingAttachment,
      getShipperDetails,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusComponent);
