import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Select from "../../common/CustomSelect";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { addressSearchStyles } from "../../Admin/Recipient/addressStyles";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import recipients from "../../../redux/recipients";
import "../../Admin/Recipient/recipientsSearchComponent.css";
import { useHistory } from "react-router";
import AutocompleteText from "../../common/AutocompleteText";
import DropDownSearch from "../../common/DropDownSearch";
const DateField = (props) => {
  registerLocale("de", de);
  const { t, i18n } = useTranslation();
  return (
    <DatePicker
     locale={i18n.resolvedLanguage}
      portalId="root-portal"
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
      margin="normal"
      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
      selected={
        props.state[props.name] ? new Date(props.state[props.name]) : ""
      }
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />
  );
};

const RecipientsSerarch = (props) => {
  const { onSearch, addressList, state, actions, searchKey, countries } = props;
  const classes = addressSearchStyles();
  const history = useHistory();

  useEffect(() => {
    if (state.searchKey !== searchKey) {
      dispatch({ type: "RESET" });
      onSearch({});
    } else {
      onSearch({ ...state, searchKey: "" });
    }
  }, []);

  const dispatch = (event) => {

    if (event.type === "RESET") {
      onSearch({});
      return actions.setRecipientSearchData({
        id: "",
        address_id: "",
        title_id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        valid: false,
        submitted: false,
        approved: false,
        created_by: "",
        startDate: "",
        endDate: "",
        city: "",
        name_english: "",
        searchKey,
      });
    }

    if (event.target.type === "checkbox") {
      return actions.setRecipientSearchData({
        ...state,
        [event.target.name]: event.target.checked,
        searchKey,
      });
    }

    return actions.setRecipientSearchData({
      ...state,
      [event.target.name]: event.target.value,
      searchKey,
    });
  };

  history.listen((location, action) => {
    if (!location.pathname.toLowerCase().includes("recipient")) {
      dispatch({ type: "RESET" });
    }
  });

  const [t, i8ln] = useTranslation();

  return (
    <>
      <Card variant="outlined" className={classes.container + "cad_header"}>

        <CardContent className="tablebg_box">
          <div className="row">
            <div className="col-11 pr-0">
              <div className="row col-12">

                <div className="col-lg-3  pr-0 mt-2">
                  <TextField
                    id="first_name"
                    name="first_name"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("search_fields.first_name")}
                    onChange={dispatch}
                    value={state["first_name"]}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="last_name"
                    name="last_name"
                    onChange={dispatch}
                    value={state["last_name"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("recipient.Last name")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="email"
                    name="email"
                    onChange={dispatch}
                    value={state["email"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("recipient.Email")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">

                  <TextField
                    id="city"
                    name="city"
                    onChange={dispatch}
                    value={state["city"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("address.City")}
                  />
                </div>
              </div>

              <div className="row col-12 pt10">

                <div className="col-lg-3 col-sm-12 pr-0 mt-1">

                  {/* <AutocompleteText
                    id="name_english"
                    name="name_english"
                    value={state["name_english"]}
                    options={countries}
                    onChange={dispatch}
                    label={t("address.Country")}
                  /> */}

<DropDownSearch
                    id="name_english"
                    name="name_english"
                    value={
                      countries.find(
                        (countryItem) => countryItem.id === state["country_id"]
                      )?.name || ""
                    }
                    list={[
                      ...(countries || []).map((country) => ({
                        label: country.name,
                        id: country.id,
                      })),
                    ]}
                    handleChange={(e) => {
                      dispatch({
                        target: { name: "country_id", value: e?.id },
                      });
                    }}
                    label={t("address.Country")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <DateField
                    placeholderText={t("phase.createdatefrom")}
                    name="startDate"
                    id="startDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "startDate", value: date } })
                    }
                    state={state}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <DateField
                    placeholderText={t("phase.createtilldate")}
                    name="endDate"
                    id="endDate"
                    onChange={(date) =>
                      dispatch({ target: { name: "endDate", value: date } })
                    }
                    state={state}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">

              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state, searchKey: "" })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state[recipients.name].recipientSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setRecipientSearchData: recipients.actions.setRecipientSearchData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipientsSerarch);
const CheckboxField = (props) => {
  return (
    <>
      <Checkbox
        margin="normal"
        variant="outlined"
        size="small"
        checked={props.state[props.name]}
        {...props}
      />
      <label>{props.label}</label>
    </>
  );
};
