import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ErrorMessage = (props) => {

  const { 
        closeErrorMessage,
        errorMessages
     } = props
    
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
    
      <Alert
        severity="error"
        onClose={closeErrorMessage}
      >
        <ul>
            {errorMessages.map(error => <li>{error}</li>)}
        </ul>
      </Alert>
    </Stack>
  );
}

export default ErrorMessage