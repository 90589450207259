import axios from '../api.js';
import * as config from '../config';

export default {
    recipientApprovalUpdate: async(id, data)=>{
        const response = await axios.post(config.baseURL + `/api/recipient/approve/${id}`, data)
        return response.data;
    },
    deleteRecipient: async(id)=>{
        const response = await axios.get(config.baseURL + `/delete/recipient/${id}`)
        return response.data;
    },
    updateApproveRecipientReviewStatus: async (payload) => {
        const response = await axios.post(config.baseURL + `/api/updateRecipientReviewStatus`, payload);
        return response.data;
    }
}
