export default {
  GET_TITLE: "GET TITLE",
  SAVE_TITLE: "SAVE_TITLE",
  GET_UNITS: "GET_UNITS",
  GET_CURRENCY: "GET_CURRENCY",
  GET_ALL_COUNTRIES: "GET_ALL_COUNTRY",
  SAVE_COUNTRY:"SAVE_COUNTRY",
  UPDATE_COUNTRY:"UPDATE_COUNTRY",
  GET_BROKER: "GET_BROKER",
  GET_SHIPPING_TYPE: "GET_SHIPPING_TYPE",
  GET_INCOTERMS: "GET_INCOTERMS",
  GET_IMPORTER_LIST: "GET_IMPORTER_LIST",
};
