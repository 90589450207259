import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import materials from "../../../redux/materials";
import ContainerComponent from "./Container";

const { getAllContainer, saveContainer, statusUpdateContainer } =
  materials.actions;
const mapStateToProps = (state) => ({
  allContainerList: state[materials.name].allContainerList,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllContainer,
      saveContainer,
      statusUpdateContainer,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerComponent);
