import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function Confirmation({
  confirmationOpen,
  handleCancelConfirmation,
  handleYesConfirmation,
  title = "Title",
  message = "Message",
  CancelText = "Cancel",
}) {
  return (
    <div>
      <Dialog
        open={confirmationOpen}
        onClose={handleCancelConfirmation}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesConfirmation}>Yes</Button>
          <Button autoFocus onClick={handleCancelConfirmation}>
            {CancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function HelpDialog({
  confirmationOpen,
  handleCancelConfirmation,
  title = "Title",
  message = "Message",
}) {
  return (
    <div>
      <Dialog
        open={confirmationOpen}
        onClose={handleCancelConfirmation}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelConfirmation}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
