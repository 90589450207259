import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./navBar.css";
import { useTranslation } from "react-i18next";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "react-bootstrap-submenu/dist/index.css";
import { NAV_KEYS, USERROLES } from "../../constants";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useMsal } from "@azure/msal-react";
import shippingRedux from "../../redux/shipping";
import navbar from "../../redux/navbar";
import { setNavKey } from "../../redux/navbar/actions";
import shipping from "../../redux/shipping";
import addressRedux from "../../redux/address";
import recipientsRedux from "../../redux/recipients";
import regulatory from "../../redux/regulatory";
import tableListing from "../../redux/tableListing";
import invoice from "../../redux/invoice";

const NavBar = ({ isAdmin, actions, navKey, AlertNotificationCount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pathname, setPathname] = useState("");
  const history = useHistory();
  const userType = JSON.parse(localStorage.getItem("userType"));

  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;

  const pathKeyMapping = {
    "/shipping": NAV_KEYS.NEW_SHIPPING_REQUEST,
    "/myShippings": NAV_KEYS.MY_SHIPPING_REQUESTS,
    "/requestByDelegate": NAV_KEYS.REQUESTS_BY_DELEGATE,
    "/process/shipping": NAV_KEYS.PROCESS_SHIPPING_REQUESTS,

    "/approveAddress": NAV_KEYS.APPROVALS_ADDRESS,
    "/approve/materials": NAV_KEYS.APPROVALS_MATERIALS,
    "/approve/recipients": NAV_KEYS.APPROVALS_RECIPIENTS,

    "/address": NAV_KEYS.ADMINISTRATION_ADDRESS,
    "/materials": NAV_KEYS.ADMINISTRATION_MATERIAL,
    "/recipients": NAV_KEYS.ADMINISTRATION_RECIPIENT,
    "/shippingRequests": NAV_KEYS.ADMINISTRATION_SHIPPING_REQUEST,
    "/distributiontemperature":
      NAV_KEYS.ADMINISTRATION_DISTRIBUTION_TEMPERATURE,
    "/phase": NAV_KEYS.ADMINISTRATION_PHASE,
    "/title": NAV_KEYS.ADMINISTRATION_TITLE,
    "/container": NAV_KEYS.ADMINISTRATION_CONTAINER,
    "/units": NAV_KEYS.ADMINISTRATION_UNITS,
    "/currency": NAV_KEYS.ADMINISTRATION_CURRENCY,
    "/add-country": NAV_KEYS.ADMINISTRATION_ADD_COUNTRY,
    "/broker": NAV_KEYS.ADMINISTRATION_BROKER,
    "/shippingType": NAV_KEYS.ADMINISTRATION_SHIPPING_TYPE,
    "/incoterm": NAV_KEYS.ADMINISTRATION_INCOTERM,
    "/importerOfRecord": NAV_KEYS.ADMINISTRATION_IMPOTER,
    "/countrycatalouge": NAV_KEYS.ADMINISTRATION_CONTRY_CATALOGUE,
    "/registereduser": NAV_KEYS.ADMINISTRATION_REGISTURED_USER,
    "/logistics": NAV_KEYS.ADMINISTRATION_LOGISTICS_PARTNER,

    "/queriesCountry": NAV_KEYS.QUERIES_COUNTRY,
    "/queriesCountryTwoWeeks": NAV_KEYS.QUERIES_DELIVEY_COUNTRY,

    "/myAddress": NAV_KEYS.MY_APPLICATIONS_ADDRESS,
    "/my/application/materials": NAV_KEYS.MY_APPLICATIONS_MATERIALS,
    "/my/application/recipients": NAV_KEYS.MY_APPLICATIONS_RECIPIENTS,

    "/contact": NAV_KEYS.CONTACT,
    "/regulatoryReviewer": NAV_KEYS.REGULATORY_REVIEWER,
    "/regulatoryReviewer/edit": NAV_KEYS.REGULATORY_REVIEWER,
    "/alerts": NAV_KEYS.ALERTS_TAGS,
  };

  useEffect(() => {
    const key = pathKeyMapping[window.location.pathname] || "";
    dispatch(setNavKey(key));
  }, []);

  //To clear shippingData component when navbar navigation occurs.
  useEffect(() => {
    fetchNotificationCount();
    actions.setShippingSearchData({});
    actions.clearInvoiceData();
  }, [navKey]);

  history.listen(() => setPathname(window.location.pathname));

  const fetchNotificationCount = () => {
    actions.getAlertNotificationsByuserId({ userId }).then((response) => {
    });
  };

  const updateAlertCount = () => {
    actions.updateAlertCountByUserID({ userId }).then((response) => {
      fetchNotificationCount();
    });
  };

  if (userType.length > 0) {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className="menu-bar"
        >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                userType.includes(USERROLES.APPLICANT) === true ? (
                <Nav.Link
                  active={navKey === NAV_KEYS.NEW_SHIPPING_REQUEST}
                  onClick={() => {
                    actions.clearshippingRequest();
                    actions.setDelegateSearchData({});
                    actions.setRegulatorySearchData({});
                    actions.setProcessShippingSearchData({});
                    actions.setDelegateTableOptions({});
                    actions.setRegulatoryTableOptions({});
                    actions.setProcessShippingTableOptions({});
                    actions.redirectAction("/myShippings");
                    history.push("/shipping");
                    fetchNotificationCount();
                    dispatch(setNavKey(NAV_KEYS.NEW_SHIPPING_REQUEST));
                  }}
                  as={Link}
                  to="/shipping"
                >
                  {t("navbar.newShippingRequest")}
                </Nav.Link>
              ) : (
                ""
              )}
              {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                userType.includes(USERROLES.APPLICANT) === true ? (
                <Nav.Link
                  active={navKey === NAV_KEYS.MY_SHIPPING_REQUESTS}
                  onClick={() => {
                    fetchNotificationCount();
                    dispatch(setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS));
                  }}
                  as={Link}
                  to="/myShippings"
                >
                  {t("navbar.myShippingRequest")}
                </Nav.Link>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                userType.includes(USERROLES.APPLICANT) === true ? (
                <Nav.Link
                  active={navKey === NAV_KEYS.REQUESTS_BY_DELEGATE}
                  onClick={() => {
                    fetchNotificationCount();
                    dispatch(setNavKey(NAV_KEYS.REQUESTS_BY_DELEGATE));
                  }}
                  as={Link}
                  to="/requestByDelegate"
                >
                  {t("navbar.requestByDeputy")}
                </Nav.Link>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.REGULATORYREVIEWER1) === true ||
                userType.includes(USERROLES.REGULATORYREVIEWER2) === true ? (
                <Nav.Link
                  as={Link}
                  to="/regulatoryReviewer"
                  active={navKey === NAV_KEYS.REGULATORY_REVIEWER}
                  onClick={() => {
                    actions.clearshippingRequest();
                    fetchNotificationCount();
                    dispatch(setNavKey(NAV_KEYS.REGULATORY_REVIEWER));
                  }}
                >
                  {t("navbar.Regulatory_Reviewer")}
                </Nav.Link>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                userType.includes(USERROLES.READONLY) === true ? (
                <Nav.Link
                  as={Link}
                  to="/process/shipping"
                  active={navKey === NAV_KEYS.PROCESS_SHIPPING_REQUESTS}
                  onClick={() => {
                    actions.clearshippingRequest();
                    fetchNotificationCount();
                    dispatch(setNavKey(NAV_KEYS.PROCESS_SHIPPING_REQUESTS));
                  }}
                >
                  {t("navbar.processShippingRequests")}
                </Nav.Link>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.ADMIN) === true ? (
                <NavDropdownMenu
                  active={[
                    NAV_KEYS.APPROVALS_ADDRESS,
                    NAV_KEYS.APPROVALS_MATERIALS,
                    NAV_KEYS.APPROVALS_RECIPIENTS,
                  ].includes(navKey)}
                  title={t("navbar.approvals")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/approveAddress"
                    active={navKey === NAV_KEYS.APPROVALS_ADDRESS}
                    onClick={() => {
                      //  actions.setApprovalAddressSearchData({});
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.APPROVALS_ADDRESS));
                    }}
                  >
                    {t("navbar.Approve Address")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/approve/materials"
                    active={navKey === NAV_KEYS.APPROVALS_MATERIALS}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.APPROVALS_MATERIALS));
                    }}
                  >
                    {t("navbar.approve_material")}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/approve/recipients"
                    active={navKey === NAV_KEYS.APPROVALS_RECIPIENTS}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.APPROVALS_RECIPIENTS));
                    }}
                  >
                    {t("navbar.approve_recipient")}
                  </NavDropdown.Item>
                </NavDropdownMenu>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                <NavDropdownMenu
                  active={[
                    NAV_KEYS.ADMINISTRATION_ADDRESS,
                    NAV_KEYS.ADMINISTRATION_RECIPIENT,
                    NAV_KEYS.ADMINISTRATION_MATERIAL,
                    NAV_KEYS.ADMINISTRATION_SHIPPING_REQUEST,
                    NAV_KEYS.ADMINISTRATION_DISTRIBUTION_TEMPERATURE,
                    NAV_KEYS.ADMINISTRATION_PHASE,
                    NAV_KEYS.ADMINISTRATION_TITLE,
                    NAV_KEYS.ADMINISTRATION_CONTRY_CATALOGUE,
                    NAV_KEYS.ADMINISTRATION_REGISTURED_USER,
                    NAV_KEYS.ADMINISTRATION_UNITS,
                    NAV_KEYS.ADMINISTRATION_CONTAINER,
                    NAV_KEYS.ADMINISTRATION_CURRENCY,
                    NAV_KEYS.ADMINISTRATION_ADD_COUNTRY,
                    NAV_KEYS.ADMINISTRATION_BROKER,
                    NAV_KEYS.ADMINISTRATION_SHIPPING_TYPE,
                    NAV_KEYS.ADMINISTRATION_INCOTERM,
                    NAV_KEYS.ADMINISTRATION_IMPOTER,
                    NAV_KEYS.ADMINISTRATION_LOGISTICS_PARTNER,
                  ].includes(navKey)}
                  title={t("navbar.administration")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/address"
                    active={navKey === NAV_KEYS.ADMINISTRATION_ADDRESS}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_ADDRESS));
                    }}
                  >
                    {t("navbar.address")}
                  </NavDropdown.Item>
                  {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                    userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
                    <DropdownSubmenu title={t("navbar.externalsystemdata")}>
                      <NavDropdown.Item
                        as={Link}
                        to="/countrycatalouge"
                        onClick={() => {
                          fetchNotificationCount();
                          dispatch(
                            setNavKey(NAV_KEYS.ADMINISTRATION_CONTRY_CATALOGUE)
                          );
                        }}
                        active={
                          navKey === NAV_KEYS.ADMINISTRATION_CONTRY_CATALOGUE
                        }
                      >
                        {t("navbar.countrycatalogue")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/registereduser"
                        onClick={() => {
                          fetchNotificationCount();
                          dispatch(
                            setNavKey(NAV_KEYS.ADMINISTRATION_REGISTURED_USER)
                          );
                        }}
                        active={
                          navKey === NAV_KEYS.ADMINISTRATION_REGISTURED_USER
                        }
                      >
                        {t("navbar.RegisteredUser")}
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                  ) : (
                    ""
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to="/materials"
                    active={navKey === NAV_KEYS.ADMINISTRATION_MATERIAL}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_MATERIAL));
                    }}
                  >
                    {t("navbar.materials")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/recipients"
                    active={navKey === NAV_KEYS.ADMINISTRATION_RECIPIENT}
                    onClick={() => {
                      // actions.setRecipientSearchData({})
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_RECIPIENT));
                    }}
                  >
                    {t("navbar.recipients")}
                  </NavDropdown.Item>
                  {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                    userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
                    <DropdownSubmenu title={t("navbar.referencedata")}>
                      <NavDropdown.Item
                        as={Link}
                        to="/phase"
                        onClick={() => {
                          fetchNotificationCount();
                          dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_PHASE));
                        }}
                        active={navKey === NAV_KEYS.ADMINISTRATION_PHASE}
                      >
                        {t("navbar.phasetitle")}
                      </NavDropdown.Item>
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/broker"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_BROKER));
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_BROKER}
                        >
                          {t("title.broker")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/container"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(
                              setNavKey(NAV_KEYS.ADMINISTRATION_CONTAINER)
                            );
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_CONTAINER}
                        >
                          {t("shippingrequest.container")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/add-country"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(
                              setNavKey(NAV_KEYS.ADMINISTRATION_ADD_COUNTRY)
                            );
                          }}
                          active={
                            navKey === NAV_KEYS.AADMINISTRATION_ADD_COUNTRY
                          }
                        >
                          {t("title.add_country")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/currency"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(
                              setNavKey(NAV_KEYS.ADMINISTRATION_CURRENCY)
                            );
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_CURRENCY}
                        >
                          {t("title.currency")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                      <NavDropdown.Item
                        as={Link}
                        to="/distributiontemperature"
                        onClick={() => {
                          fetchNotificationCount();
                          dispatch(
                            setNavKey(
                              NAV_KEYS.ADMINISTRATION_DISTRIBUTION_TEMPERATURE
                            )
                          );
                        }}
                        active={
                          navKey ===
                          NAV_KEYS.ADMINISTRATION_DISTRIBUTION_TEMPERATURE
                        }
                      >
                        {t("navbar.distributiontemperature")}
                      </NavDropdown.Item>
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/importerOfRecord"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(
                              setNavKey(NAV_KEYS.ADMINISTRATION_IMPOTER)
                            );
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_IMPOTER}
                        >
                          {t("shipping_request.importer_of_record")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/incoterm"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(
                              setNavKey(NAV_KEYS.ADMINISTRATION_INCOTERM)
                            );
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_INCOTERM}
                        >
                          {t("shipping_request.incoterms")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}                      
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/logistics"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_LOGISTICS_PARTNER));
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_LOGISTICS_PARTNER}
                        >
                          {t("logisticsPartner.name")}
                        </NavDropdown.Item>
                      ): (
                        <></>
                      )}
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/shippingType"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(
                              setNavKey(NAV_KEYS.ADMINISTRATION_SHIPPING_TYPE)
                            );
                          }}
                          active={
                            navKey === NAV_KEYS.ADMINISTRATION_SHIPPING_TYPE
                          }
                        >
                          {t("title.ShipmentType")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                      <NavDropdown.Item
                        as={Link}
                        to="/title"
                        onClick={() => {
                          fetchNotificationCount();
                          dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_TITLE));
                        }}
                        active={navKey === NAV_KEYS.ADMINISTRATION_TITLE}
                      >
                        {t("navbar.title")}
                      </NavDropdown.Item>
                      {userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/units"
                          onClick={() => {
                            fetchNotificationCount();
                            dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_UNITS));
                          }}
                          active={navKey === NAV_KEYS.ADMINISTRATION_UNITS}
                        >
                          {t("title.units")}
                        </NavDropdown.Item>
                      ) : (
                        <></>
                      )}
                    </DropdownSubmenu>
                  ) : (
                    ""
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to="/shippingRequests"
                    active={navKey === NAV_KEYS.ADMINISTRATION_SHIPPING_REQUEST}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(
                        setNavKey(NAV_KEYS.ADMINISTRATION_SHIPPING_REQUEST)
                      );
                    }}
                  >
                    {t("navbar.shippingList")}
                  </NavDropdown.Item>
                </NavDropdownMenu>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.PROCESSMANAGER) === true ||
                userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
                <NavDropdownMenu
                  active={[
                    NAV_KEYS.QUERIES_COUNTRY,
                    NAV_KEYS.QUERIES_DELIVEY_COUNTRY,
                  ].includes(navKey)}
                  title={t("navbar.queres")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/queriesCountry"
                    active={navKey === NAV_KEYS.QUERIES_COUNTRY}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.QUERIES_COUNTRY));
                    }}
                  >
                    {t("quries.DeliveryCountry")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/queriesCountryTwoWeeks"
                    active={navKey === NAV_KEYS.QUERIES_DELIVEY_COUNTRY}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.QUERIES_DELIVEY_COUNTRY));
                    }}
                  >
                    {t("quries.Delivery time more than 14 days")}
                  </NavDropdown.Item>
                </NavDropdownMenu>
              ) : (
                ""
              )}

              {userType.includes(USERROLES.APPLICANT) === true ? (
                <NavDropdownMenu
                  active={[
                    NAV_KEYS.MY_APPLICATIONS_ADDRESS,
                    NAV_KEYS.MY_APPLICATIONS_MATERIALS,
                    NAV_KEYS.MY_APPLICATIONS_RECIPIENTS,
                  ].includes(navKey)}
                  title={t("navbar.myApplication")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/myAddress"
                    active={navKey === NAV_KEYS.MY_APPLICATIONS_ADDRESS}
                    onClick={() => {
                      // actions.setApprovalAddressSearchData({});
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.MY_APPLICATIONS_ADDRESS));
                    }}
                  >
                    {t("navbar.address")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/my/application/materials"
                    active={navKey === NAV_KEYS.MY_APPLICATIONS_MATERIALS}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.MY_APPLICATIONS_MATERIALS));
                    }}
                  >
                    {t("navbar.materials")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/my/application/recipients"
                    active={navKey === NAV_KEYS.MY_APPLICATIONS_RECIPIENTS}
                    onClick={() => {
                      fetchNotificationCount();
                      dispatch(setNavKey(NAV_KEYS.MY_APPLICATIONS_RECIPIENTS));
                    }}
                  >
                    {t("navbar.recipients")}
                  </NavDropdown.Item>
                </NavDropdownMenu>
              ) : (
                ""
              )}

              <Nav.Link
                as={Link}
                to="/contact"
                active={navKey === NAV_KEYS.CONTACT}
                onClick={() => {
                  fetchNotificationCount();
                  dispatch(setNavKey(NAV_KEYS.CONTACT));
                }}
              >
                {t("contactPage.title")}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/alerts"
                active={navKey === NAV_KEYS.ALERTS_TAGS}
                onClick={() => {
                  dispatch(setNavKey(NAV_KEYS.ALERTS_TAGS));
                }}
              >
                {t("navbar.alert_tags")}{" "}
                <span class="badge badge-light">{AlertNotificationCount}</span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => ({
  navKey: state[navbar.name].navKey,
  AlertNotificationCount: state[navbar.name].AlertNotificationCount,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      clearshippingRequest: shippingRedux.actions.clearshippingRequest,
      setShippingSearchData: shippingRedux.actions.setShippingSearchData,
      redirectAction: shippingRedux.actions.redirectAction,
      getAlertNotificationsByuserId:navbar.actions.getAlertNotificationsByuserId,
      updateAlertCountByUserID: shipping.actions.updateAlertCountByUserID,
      setDelegateSearchData:regulatory.actions.setDelegateSearchData,
      setRegulatorySearchData:regulatory.actions.setRegulatorySearchData,
      setProcessShippingSearchData:tableListing.actions.setProcessShippingSearchData,
      setRegulatoryTableOptions:regulatory.actions.setRegulatoryTableOptions,
      setDelegateTableOptions:regulatory.actions.setDelegateTableOptions,
      setProcessShippingTableOptions:regulatory.actions.setProcessShippingTableOptions,
      clearInvoiceData:invoice.actions.clearInvoiceData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);