import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function DropDownSearch(props) {
  const [inputValue, setInputValue] = React.useState("");

  const {
    list,
    label = "",
    handleChange,
    value,
    renderOption ,
    getOptionLabel ,
    isOptionEqualToValue ,
    disabled,
    filterOptions,
  } = props;
  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => handleChange(newValue)}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={list}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label={label} margin="normal" />
      )}
      {...{ renderOption, getOptionLabel, isOptionEqualToValue, disabled, filterOptions }}
    />
  );
}
