import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';

const TextField = (props) => {

    return(
        <MuiTextField
            margin="normal"
            variant="outlined"
            size="small"
            style={{ width: "60%" }}
            {...props}
      />
    )
}

export default TextField;