import types from "./types";

const initialState = {
  allAddress: [],
  allAddressList: [],
  allCountry: [],
  allAddressNotApproved: [],
  allUsers: [],
  loader: true,
  tableAdressOptions: {},
  tableAddressAprovalOptions:{},
  addressSearchData: {
    id: "",
    entity: "",
    department: "",
    street: "",
    postcode: "",
    city: "",
    state: "",
    sapnumber: "",
    approved: false,
    created_by: "",
    country_id: "",
    valid: false,
    submitted: false,
    startDate: "",
    endDate: "",
  },
  addressApprovalSearchData:{
    id: "",
    entity: "",
    department: "",
    street: "",
    postcode: "",
    city: "",
    sapnumber: "",
    approved: false,
    created_by: "",
    country_id: "",
    valid: false,
    submitted: false,
    startDate: "",
    endDate: "",
    state:""
  }
};
const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_ADDRESS:
      return {
        ...state,
        allAddress: action.payload,
      };
    case types.GET_ALL_ADDRESS_LIST:
      return {
        ...state,
        allAddressList: action.payload,
      };
    case types.GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case types.GET_ALL_COUNTRY:
      return {
        ...state,
        allCountry: action.payload,
      };
    case types.GET_ADDRESS_NOT_APPROVED:
      return {
        ...state,
        allAddressNotApproved: action.payload,
      };
    case types.GET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case types.GET_MY_ADDRESS:
      return {
        ...state,
        allMyAddress: action.payload,
      };
    case types.GET_ADDRESS_SHIPPING:
      return {
        ...state,
        addressShipping: action.payload,
      };
      case types.SET_ADDRESS_TABLE_OPTIONS: {
        return {
          ...state,
          tableAdressOptions: action.payload,
        };
      }
      case types.SET_ADDRESS_APPROVAL_TABLE_OPTIONS: {
        return {
          ...state,
          tableAddressAprovalOptions: action.payload,
        };
      }
      case types.SET_ADDRESS_SEARCH_DATA: {
        return {
          ...state,
          addressSearchData: action.payload,
        };
      }
      case types.SET_APPROVAL_ADDRESS_SEARCH_DATA: {
        return {
          ...state,
          addressApprovalSearchData: action.payload,
        };
      }
    default:
      return state;
  }
};

export default addressReducer;
