import axios from "axios";
import { authContext, loginRequest } from "./adalConfig";
import { get } from "lodash";
//Add a request interceptor

axios.interceptors.request.use(
  async (config) => {
    const accounts = await authContext.getAllAccounts();
    if (!get(config, "headers.Authorization", false) && accounts.length > 0) {
      const currentAccount = accounts[0];
      const silentAccessTokenRequest = {
        scopes: loginRequest.scopes,
        account: currentAccount,
        forceRefresh: false,
      };
      let accessToken =
        currentAccount &&
        (await authContext
          .acquireTokenSilent(silentAccessTokenRequest)
          .then((accessTokenResponse) => accessTokenResponse.accessToken));
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = "Bearer " + accessToken;
      return config;
      // await authContext.acquireTokenSilent(
      //   msalConfig.auth.endpoints.api,
      //   (message, accessToken, msg) => {
      //     console.log("accessToken", accessToken);
      //     if (!!accessToken) {
      //       config.headers["Content-Type"] = "application/json";
      //       config.headers["Authorization"] = "Bearer " + accessToken;
      //       //resolve(message)
      //       return config;
      //     } else {
      //       // Do something with error of acquiring the token
      //       // reject(message)
      //       return config;
      //     }
      //   },
      // );
    } else {
      config.headers["Content-Type"] = "application/json";
      return config;
    }
  },
  (error) => {
    Promise.reject(error);
  },
);
export default axios;
