import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useMsal } from "@azure/msal-react";
import _, { curry } from "lodash";
import validator from "validator";
import Box from "@mui/material/Box";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, Redirect, Link } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import { Alert } from "@mui/material";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { trim, get } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Select from "../../common/CustomSelect";
import DropDownSearch from "../../common/DropDownSearch";
import AutocompleteText from "../../common/AutocompleteText";
import { authContext } from "../../../adalConfig";
import { addressSearchStyles } from "./addressStyles";
import ReplayIcon from "@mui/icons-material/Replay";
import { SHIPPER_TYPES, USERROLES } from "../../../constants";
import { wordBreakDownSearch } from "../../common/utils/CustomFilter";
import { If, Then } from "react-if";
import AddressUtil from "../../common/utils/AddressUtil";

const AddRecipientsComponent = (props) => {
  const {
    actions,
    allRecipients,
    allTitle,
    allAddress,
    redirectTo,
    allAddressList,
  } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const userType = JSON.parse(localStorage.getItem("userType"));
  const selectedRecipientAddressId = localStorage.getItem("selectedRecipientAddressId");
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [titleList, setTitleList] = useState(allTitle);
  const [addressSelect, setAddressSelect] = useState([]);
  const [resSelected, setResSelected] = useState([]);
  const [data, setData] = useState({
    id: "",
    description: "",
    label: "",
    value: "",
    approved: "",
  });
  const classes = addressSearchStyles();
  const isValid = (addObj) =>{
    if(addObj?.countryValid){
      if(addObj?.addressValid){
        return true
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }

  }
  useEffect(() => {
    if (get(props, "location.state.id", false)) {
      if (allRecipients.length === 0) {
        actions.getAllrecipients().then((res) => {
          const selectedRecipients = allRecipients.find(
            (item) => item.id === get(props, "location.state.id", 0),
          );
          setResSelected(selectedRecipients);
          dispatch({ type: "RESET", data: selectedRecipients });
          actions.getAllAddressList().then((res) => {
            const selectedAddress = res.data.find(
              (item) => item.id === get(selectedRecipients, "address_id", -1),
            );
            if (selectedAddress) {
              setAddressSelect(selectedAddress);
              dispatch({
                type: "SETADDRESS",
                value: get(selectedAddress, "id", null),
              });
            }
          });
          setIsLoading(false);
        });
      } else {
        const selectedRecipients = allRecipients.find(
          (item) => item.id === get(props, "location.state.id", 0),
        );
        actions.getAllAddressList().then((res) => {
          const selectedAddress = res.data.find(
            (item) => item.id === get(selectedRecipients, "address_id", -1),
          );
          if (selectedAddress) {
            setAddressSelect(selectedAddress);
            dispatch({
              type: "SETADDRESS",
              value: get(selectedAddress, "id", null),
            });
          }
        });
        setResSelected(selectedRecipients);
        setIsLoading(false);
        dispatch({ type: "RESET", data: selectedRecipients });
      }
      actions.getAllTitle().then((res) => {
        setTitleList(res.data);
        setIsLoading(false);
      });

      if (titleList.length > 0 && allRecipients.length > 0) {
        setIsLoading(false);
      }
    } else {
      if (allRecipients.length === 0) {
        actions.getAllrecipients().then((res) => {
          setIsLoading(false);
        });
      }
      actions.getAllTitle().then((res) => {
        setTitleList(res.data);
        setIsLoading(false);
      });
      if (titleList.length > 0 && allRecipients.length > 0) {
        setIsLoading(false);
      }
      actions.getAllAddressList();
    }
  }, []);

  useEffect(() => {
    if (selectedRecipientAddressId && (allAddressList && allAddressList.length)) {
      actions.getAllAddressList().then((res) => {
      const newAddedAddress = res?.data.find(item => item.id === selectedRecipientAddressId);
      if (newAddedAddress) {
        setAddressSelect(newAddedAddress);
        dispatch({
          type: "SETADDRESS",
          value: get(newAddedAddress, "id", null),
        });
        localStorage.removeItem("selectedRecipientAddressId");
      }
       })
    }
  }, [selectedRecipientAddressId, allAddressList])

  const reducer = (state, event) => {
    if (event.type === "RESET") {
      return event.data;
    } else if (event.type === "SETADDRESS") {
      return {
        ...state,
        ["address_id"]: event.value,
      };
    } else if (event.type === "SETSAP") {
      return {
        ...state,
        ["address_id"]: null,
        ["sapnumber"]: "",
      };
    } else {
      return {
        ...state,
        [event.target.name]: event.target?.value?.trim() || "",
      };
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    address_id: null,
    approved:
      userType.includes(USERROLES.PROCESSMANAGER) ||
      userType.includes(USERROLES.SYSTEMADMINISTRATOR)
        ? 1
        : 0,
    title_id: null,
    email: "",
    first_name: "",
    form_of_address: "",
    last_name: "",
    phone: "",
    submmited:
      userType.includes(USERROLES.PROCESSMANAGER) ||
      userType.includes(USERROLES.SYSTEMADMINISTRATOR)
        ? 1
        : 0,
    entity: "",
    department: "",
    building: "",
    street: "",
    postcode: "",
    city: "",
    country: "",
    name_english: "",
    sapnumber: "",
    userId,
  });

  const processedRecipientData = useMemo(() => {
    const data = {
      address_id: [],
      title_id: [],
      email: [],
      first_name: [],
      form_of_address: [],
      last_name: [],
      sapnumber: [],
      phone: [],
    };

    const keys = Object.keys(data);

    for (let item of allRecipients) {
      for (let key of keys) {
        if (item[key] && !data[key].includes(trim(item[key]))) {
          data[key].push(trim(item[key]));
        }
      }
    }
    return data;
  }, [allRecipients]);

  const handleRedirectTo = (result = {}) => {
    if (get(props, "location.state.isFromConsignorCreate", false)) {
      actions.setShipper([
        {
          ...result.rows[0],
          id: null,
          recipient_id: result?.rows[0]?.id,
          consignor_type: SHIPPER_TYPES.shipper,
        },
      ]);
    }

    if (
      (!!redirectTo ||
        get(props, "location.state.redirect") ||
        get(props, "location.state.redirectShipping")) &&
      !get(props, "location.state.isFromConsignorCreate")
    ) {
      if (get(result, "rows[0].title_id", null) !== null) {
        const titleData = titleList.filter(
          (item) => item.id === get(result, "rows[0].title_id", null),
        );
        actions.finalRecipientSelect({
          ...result.rows[0],
          title: get(titleData, "[0].title", null),
        });
        actions.destinationAddrSelect(addressSelect || {});
      } else {
        actions.finalRecipientSelect(result.rows[0]);
        actions.destinationAddrSelect(addressSelect || {});
      }
      if (get(props, "location.state.redirectShipping")) {
        history.go(-2);
        // history.goBack();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const handleClose = () => {
    if (!!redirectTo) {
      //actions.clearFinalRecipientSelect();
      history.goBack();
    } else {
      history.goBack();
    }
  };
  const handleSave = () => {
    try {
      setIsLoading(true);
      let error = "";
      let emailError = "";
      if (!state.first_name) {
        if (error === "") {
          error = t("search_fields.first_name");
        } else {
          error = ", " + t("search_fields.first_name");
        }
      }
      if (!state.last_name) {
        if (error === "") {
          error = t("recipient.Last name");
        } else {
          error = error + ", " + t("recipient.Last name");
        }
      }

      if (!state.email) {
        if (error === "") {
          error = t("recipient.Email");
        } else {
          error = error + ", " + t("recipient.Email");
        }
      } else {
        if (!validator.isEmail(state.email)) {
          toast.warning(t("recipients.EmailValidationMsg"));
          emailError = t("recipients.EmailValidationMsg");
        }
      }

      if (!state.phone) {
        if (error === "") {
          error = t("recipient.Phone");
        } else {
          error = error + ", " + t("recipient.Phone");
        }
      }

      if (error !== "") {
        if (
          error === t("search_fields.first_name") ||
          error === t("recipient.Last name") ||
          error === t("recipient.Email") ||
          error === t("recipient.Phone")
        ) {
          setIsLoading(false);
          return toast.error(
            t("commonlabels.is_req1") +
              " " +
              ` ${error}.` +
              " " +
              t("commonlabels.is_req2"),
          );
        } else {
          setIsLoading(false);
          return toast.error(
            t("commonlabels.is_req3") +
              " " +
              ` ${error}.` +
              " " +
              t("commonlabels.is_req4"),
          );
        }
      } else {
        if (emailError !== "") {
          setIsLoading(false);
          return;
        }
      }
      if (get(props, "location.state.id", false)) {
        actions
          .updateRecipients(state, get(props, "location.state.id", null))
          .then((res) => {
            if(res.data && res.data.id && res.data.id > 0) {
              setIsLoading(false);
              toast.success(t("commonlabels.editedSuccessfullyMsg"));
              history.goBack();
            }
            if(res.data && res.data.isDuplicateFound && res.data.isDuplicateFound) {
              setIsLoading(false);
              toast.error(t("recipient.recipient") + ' ' + t("commonlabels.duplicateRecord"));
            } 
          });
      } else {
        actions.saveRecipients(state).then((res) => {
          if(res.data && res.data.rows && res.data.rows.length > 0 && res.data.rows[0].id > 0) {
            setIsLoading(false);
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            handleRedirectTo(res.data);
          }  
          if(res.data && res.data.isDuplicateFound && res.data.isDuplicateFound) {
            setIsLoading(false);
            toast.error(t("recipient.recipient") + ' ' + t("commonlabels.duplicateRecord"));
          }         
        });
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const handelSearch = (value) => {
    const selectedAddress = allAddressList.find(
      (item) => item.id === get(value, "id", -1),
    );
    if (selectedAddress) {
      setAddressSelect(selectedAddress);
      dispatch({
        type: "SETADDRESS",
        value: get(selectedAddress, "id", null),
      });
    } else {
      setAddressSelect([]);
      dispatch({ type: "SETADDRESS", value: null });
    }
    setData(value);
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <CardContent>
          <div className="container-fluid">
            <label className="title_main">
              {" "}
              {get(props, "location.state.id", false)
                ? t("recipient.Edit Recipients")
                : t("recipient.Add Recipients")}
              <Card variant="outlined">
                <CardContent className="p-2 notice">
                  <Alert severity="warning">
                    {t("shipping_request.save_shipping_request_alert")}
                    <br></br>
                  </Alert>
                </CardContent>
              </Card>
            </label>
            <div className="row outline-padding">
              <ItemContainer sm="6">
                {get(props, "location.state.id", false) ? (
                  <Card variant="outlined" className={classes.container}>
                    <CardContent>
                      <div>{t("recipient.addrecipentsNoticeForManager")}</div>
                    </CardContent>
                  </Card>
                ) : (
                  <Card variant="outlined" className={classes.container}>
                    <CardContent>
                      <div>
                        {!userType.includes(USERROLES.PROCESSMANAGER)
                          ? t("recipient.addrecipentsNoticeForManager")
                          : t("recipient.addrecipentsNotice")}
                      </div>
                    </CardContent>
                  </Card>
                )}
              </ItemContainer>
              <ItemContainer sm="6">
                <Card variant="outlined" className={classes.container}>
                  <CardContent className="warning-color">
                    {/* <div>{t("recipient.recipientsNotice2")}</div> */}
                    <If
                      condition={
                        Boolean(
                          props?.location?.state?.isFromConsignorCreate,
                        ) ||
                        Boolean(props?.location?.state?.redirectShipping) ||
                        Boolean(props?.location?.state?.redirect)
                      }
                    >
                      <Then></Then>
                    </If>
                    {t("shipping_request.recipient_address_link_message")}
                    <Link
                      to={{
                        pathname: `/addAddress`,
                        state: { redirectAddRecipient: true },
                      }}
                    >
                      {t("shipping_request.click_here_to_add_address")}
                    </Link>
                  </CardContent>
                </Card>

                <Card className={classes.container}>
                  <CardContent>
                    <div className="row col-12">
                      <DropDownSearch
                        list={_(allAddressList)
                          .filter(item => item.valid === 1 && item.countryvalid == true)
                          .map((item) => {
                            item.label = (new AddressUtil(item).toString());
                            return item;
                          })
                          .uniqBy("label")
                          .sortBy("label")
                          .value()}
                        label=" "
                        handleChange={(val) => {
                          handelSearch(val);
                          //    dispatch({ type: "SETADDRESSVAL", value: val });
                        }}
                        value={addressSelect? (new AddressUtil(addressSelect).toString()):""}
                        filterOptions={wordBreakDownSearch("label")}
                      />
                    </div>
                  </CardContent>
                </Card>
              </ItemContainer>
            </div>
          </div>
          <Divider />
          <div className="row form-padding col-12">
            <ItemContainer sm="6">
              <Card variant="outlined" className={classes.container}>
                <CardHeader title={t("address.addNewAddressTitle")} />
                <Divider />
                <CardContent className="form-padding">
                  <div className="row m-0 p-0">
                    <ItemContainer sm="6">
                      <span className={classes.textLable}>
                        {t("recipient.Title")}
                      </span>
                      <Select
                        list={[{ id: "", title: "-- Select --" }, ...titleList]}
                        value={get(state, "title_id", " ")}
                        name="title_id"
                        keyname="title"
                        onChange={dispatch}
                      />
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="6">
                      <span className={classes.textLable}>
                        {t("search_fields.first_name")}
                        <span className={classes.textLableAsterisk}>*</span>
                      </span>
                      <AutocompleteText
                        label={" "}
                        name="first_name"
                        options={
                          processedRecipientData.first_name.sort((a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a == b) return 0;
                            return a < b ? -1 : 1;
                          }) || []
                        }
                        value={get(state, "first_name", " ")}
                        onChange={dispatch}
                        required={true}
                      />
                    </ItemContainer>
                    <ItemContainer sm="6">
                      <span className={classes.textLable}>
                        {t("recipient.Last name")}
                        <span className={classes.textLableAsterisk}>*</span>
                      </span>
                      <AutocompleteText
                        name="last_name"
                        options={
                          processedRecipientData.last_name.sort((a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a == b) return 0;
                            return a < b ? -1 : 1;
                          }) || []
                        }
                        value={get(state, "last_name", " ")}
                        label=" "
                        onChange={dispatch}
                        required={true}
                      />
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="6">
                      <span className={classes.textLable}>
                        {t("recipient.Email")}
                        <span className={classes.textLableAsterisk}>*</span>
                      </span>
                      <AutocompleteText
                        name="email"
                        options={
                          processedRecipientData.email.sort((a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a == b) return 0;
                            return a < b ? -1 : 1;
                          }) || []
                        }
                        value={get(state, "email", " ")}
                        label=" "
                        onChange={dispatch}
                        required={true}
                      />
                    </ItemContainer>
                    <ItemContainer sm="6">
                      <span className={classes.textLable}>
                        {t("recipient.Phone")}
                        <span className={classes.textLableAsterisk}>*</span>
                      </span>
                      <AutocompleteText
                        name="phone"
                        options={
                          processedRecipientData.phone.sort((a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a == b) return 0;
                            return a < b ? -1 : 1;
                          }) || []
                        }
                        value={get(state, "phone", " ")}
                        label=" "
                        onChange={dispatch}
                        required={true}
                      />
                    </ItemContainer>
                  </div>
                </CardContent>
              </Card>
            </ItemContainer>
            <ItemContainer sm="6">
              <Card variant="outlined" className={classes.container}>
                <Divider />
                <CardContent className="form-padding">
                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.Company/University")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "entity", " ")
                          : get(addressSelect, "entity", " ")}
                      </span>
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.Department")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "department", " ")
                          : get(addressSelect, "department", " ")}
                      </span>
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.building")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "building", " ")
                          : get(addressSelect, "building", " ")}
                      </span>
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.Street")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "street", " ")
                          : get(addressSelect, "street", " ")}
                      </span>
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.Postal Code")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "postcode", " ")
                          : get(addressSelect, "postcode", " ")}
                      </span>
                    </ItemContainer>
                  </div>
                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.City")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "city", " ")
                          : get(addressSelect, "city", " ")}
                      </span>
                    </ItemContainer>
                  </div>

                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.State")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "state", " ")
                          : get(addressSelect, "state", " ")}
                      </span>
                    </ItemContainer>
                  </div>

                  <div className="row m-0 p-0">
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("address.Country")}:
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {get(props, "location.state.id", false) &&
                        addressSelect.length == 0
                          ? get(state, "name_english", " ")
                          : get(addressSelect, "name_english", " ")}
                      </span>
                    </ItemContainer>
                  </div>
                </CardContent>
              </Card>
            </ItemContainer>
          </div>
        </CardContent>
        <div className="col-12">
          <CardActions className={classes.cardActionButtons}>
            <button
              className="btn btn-primary new_button "
              onClick={handleSave}
            >
              {t("commonlabels.saveButton")}
            </button>
            <button
              className="btn cancelbutton new_button"
              onClick={handleClose}
            >
              {t("commonlabels.cancelbutton")}
            </button>
          </CardActions>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default AddRecipientsComponent;

const ItemContainer = ({ children, sm = "3" }) => {
  return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
};
