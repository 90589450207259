export const pathURLs = [
    "/process/shipping", 
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/address",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/approve/recipients"
   ]