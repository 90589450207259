import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import api from "../redux/util/api";
import shipping from "../redux/shipping";
import address from "../redux/address";
import recipients from "../redux/recipients";
import materials from "../redux/materials";
import phase from "../redux/phase";
import title from "../redux/title";
import logistics from "../redux/logistics";
import regulatory from "../redux/regulatory";
import navbar from "../redux/navbar";
import invoice from "../redux/invoice";
import tableListing from "../redux/tableListing";
// import distribution from "../redux/distribution";
import errors from "../redux/errors";

export const rootReducer = combineReducers({
  [shipping.name]: shipping.reducer,
  [address.name]: address.reducer,
  [recipients.name]: recipients.reducer,
  [materials.name]: materials.reducer,
  [phase.name]: phase.reducer,
  [title.name]: title.reducer,
  [regulatory.name]:regulatory.reducer,
  // [distribution.name]:distribution.reducer
  [navbar.name]: navbar.reducer,
  [invoice.name]: invoice.reducer,
  [tableListing.name]: tableListing.reducer,
  [logistics.name]: logistics.reducer,
  errors: errors.reducer
});

export const configureStore = () => {
  const composeEnhancers = composeWithDevTools({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
  });
  const store = 
   createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument({ api }))),
  );
  window.STORE = store;
  return store;
};
