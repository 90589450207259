import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import recipients from "../../../redux/recipients";
import shipping from "../../../redux/shipping";
import address from "../../../redux/address";
import MyApplicationRecipientsComponent from "./MyApplicationRecipientsComponent";

const {
  getAllrecipients,
  getAllTitle,
  saveRecipients,
  updateRecipients,
  updateRecipientsAccess,
  updateRecipientsApproval,
  getSelectedRecipientData,
} = recipients.actions;
const { getAddress, getAllAddress } = address.actions;
const { getCountries } = shipping.actions;
const mapStateToProps = (state) => ({
  allRecipients: state[recipients.name].allRecipients,
  countries: state[shipping.name].countries,
  allTitle: state[recipients.name].allTitle,
  allAddress: state[address.name].allAddress,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllrecipients,
      getAllAddress,
      getCountries,
      getAllTitle,
      getAddress,
      saveRecipients,
      updateRecipients,
      updateRecipientsAccess,
      updateRecipientsApproval,
      getSelectedRecipientData,
    },
    dispatch
  ),
});

export default {
  MyApplicationRecipientsComponent: connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyApplicationRecipientsComponent),
};
