import types from "./types";
import fetchData from "../util";

const getAllMaterials = () => {
  return fetchData(types.GET_ALL_MATERIALS, "getAllMaterials");
};

const setSearchData = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_MATERIAL_SEARCH_DATA, payload: data });
  };
};
const setMaterialTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_MATERIAL_TABLE_OPTIONS, payload: data });
  };
};
const setApproveMaterialTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_MATERIAL_APPROVE_TABLE_OPTIONS, payload: data });
  };
};
const getAllContainer = () => {
  return fetchData(types.GET_ALL_CONTAINER, "getContainer");
};
const saveContainer = (...data) => {
  return fetchData(types.SAVE_CONTAINER, "saveContainer", data);
};
const statusUpdateContainer = (...data) => {
  return fetchData(types.SAVE_CONTAINER, "statusUpdateContainer", data);
};
export default {
  getAllMaterials,
  setSearchData,
  getAllContainer,
  saveContainer,
  statusUpdateContainer,
  setMaterialTableOptions,
  setApproveMaterialTableOptions
};
