import React from "react";
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { ifRequestCompletedApprovals } from "../../Services/util";
import { INSPECT,SUBMIT } from "../../constants";

const styles = {
  header: {
    width: "100%",
    height: 30,
    backgroundColor: "#dfdfdf",
  },
  Addbtn: {
    float: "right",
  },
  TopBtn: {
    float: "left",
  },
};


const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 20,
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0px 16px",
    borderRight: "1px solid #e5e5e5",
  },
}))(TableCell);

const MaterialTable = (props) => {
  const {
    shippingMaterialSelect = [],
    materialId,
    handleChangeId,
    countries,
    shippingRequestDetails,
    allUnits,
    shippingActionType
  } = props;
  const status = _.get(shippingRequestDetails, ["0", "status"], undefined);
  const history = useHistory();
  const { t } = useTranslation();
  const getNotify = () => {
    if (!shippingMaterialSelect.length) {
      return (
        <>
          <Checkbox checked={false} />
          <span>{t("material.notifiy_deputies")} </span>
        </>
      );
    }
  };
 
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <TableHead
          sx={{
            height: "30%",
          }}
        >
          <TableRow
            sx={{
              borderRight: "1px solid #e5e5e5",
              backgroundColor: "#98AFC7",
              "& th": {
                color: "#FFFFFF",
              },
            }}
          >
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
              {t("shippingrequest.material(name)")}
            </StyledTableCell>
            <StyledTableCell>{t("shippingrequest.quantity")}</StyledTableCell>
            <StyledTableCell>{t("shippingrequest.unit")}</StyledTableCell>
            <StyledTableCell>
              {t("shippingrequest.total_value_of_material")}
            </StyledTableCell>
            <StyledTableCell>
              {t("process_shipping_request.country_of_origin")}
            </StyledTableCell>
            <StyledTableCell>{t("material.approved")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shippingMaterialSelect.map((row) => (
            <StyledTableRow
             key={row.id}
              sx={{
                borderRight: "1px solid #e5e5e5",
              }}
              className={row.approved === 0 && shippingActionType === INSPECT ? "solid-red-border-materials" : null}
            >
              <StyledTableCell>
                <FormControlLabel
                  value=""
                  checked={row.id === materialId}
                  control={<Radio />}
                  onClick={() => handleChangeId(row)}
                  label=""
                />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" style={{border:row?.valid==false ? '3px solid orange':''}}>
                {((status && status !== "INPUT") || (shippingActionType === INSPECT || shippingActionType === SUBMIT))
                  ? <a
                    href="javascript:void(0)"
                    target="_self"
                    onClick={() => {
                      history.push({pathname:`/view/material/${row.materialdescription_id}`,state: {data: row.material_backup}});
                      return false;
                    }}
                  >
                    {row.materailName.description}
                  </a>
                  : row.materailName.description}
              </StyledTableCell>
              <StyledTableCell>{row.quantity}</StyledTableCell>
              <StyledTableCell>
                {row.unit}
                {ifRequestCompletedApprovals(_.get(shippingRequestDetails, ["0", "status"], undefined))
                  ? row.unitType
                  : allUnits?.find(item =>
                    item.abbrevaton === row.unitType && item.isactive === true
                  )?.abbrevaton}
              </StyledTableCell>
              <StyledTableCell>{row.amountAndUnit}</StyledTableCell>
              <StyledTableCell>
                {(function () {
                  const countryName = row.countryName
                    ? row.countryName
                    : row.countryOrigin;
                  if (
                    countryName &&
                    !Number.isSafeInteger(Number(countryName))
                  ) {
                    return countryName;
                  } else if (
                    countryName &&
                    Number.isSafeInteger(Number(countryName))
                  ) {
                    const country = countries.find(
                      (item) => item.id == countryName,
                    );
                    return country?.name;
                  }
                  return null;
                })()}
              </StyledTableCell>
              <StyledTableCell>
                <input
                  type="checkbox"
                  checked={
                    _.get(row, "materailName.approved", 2) == 0?false:_.get(row, "materailName.approved", false) == 1 || row.approved
                  }
                  disabled
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {getNotify()}
    </TableContainer>
  );
};

export default withStyles(styles)(MaterialTable);
