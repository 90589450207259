import React from "react";

import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { USERROLES } from "../../constants";
import MaterialTable from "./MaterialTable";
import { useTranslation } from "react-i18next";

const ShippingMaterialList = (props) => {
  const { t } = useTranslation();

  const userType = JSON.parse(localStorage.getItem("userType"));
  const {
    shippingMaterialSelect,
    materialId,
    handleMaterialNew,
    handleMaterialDelete,
    handleMaterialCopy,
    handleChangeId,
    actionType,
    shippingRequestDetails,
    countries,
    isNotEditable,
    editButton,
    allUnits,
    shippingActionType
  } = props;
  
  function disableDeleteMaterialBtn() {
    if (
      userType.includes(USERROLES.PROCESSMANAGER) ||
      userType.includes(USERROLES.APPLICANT) ||
      userType.includes(USERROLES.ADMIN)
    ) {
      if (isNotEditable) {
        return (
          <Button
            disabled
            variant="contained"
            className="mb-3"
            size="small"
            onClick={() => handleMaterialDelete(materialId)}
          >
            {" "}
            {t("commonlabels.delete")}{" "}
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            className="cancelbutton mb-3"
            size="small"
            onClick={() => handleMaterialDelete(materialId)}
          >
            {" "}
            {t("commonlabels.delete")}{" "}
          </Button>
        );
      }
    } else {
      return (
        <Button
          disabled
          variant="contained"
          className="mb-3"
          size="small"
          onClick={() => handleMaterialDelete(materialId)}
        >
          {" "}
          {t("commonlabels.delete")}{" "}
        </Button>
      );
    }
  }

  function disableNewMaterialBtn() {
    if (
      userType.includes(USERROLES.PROCESSMANAGER) ||
      userType.includes(USERROLES.APPLICANT) ||
      userType.includes(USERROLES.ADMIN)
    ) {
      if (actionType === "New" || isNotEditable) {
        return (
          <Button
            disabled
            variant="contained"
            className="mb-3"
            size="small"
            onClick={handleMaterialNew}
          >
            {" "}
            {t("navbar.new")}{" "}
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            className="Savebutton mb-3"
            size="small"
            onClick={handleMaterialNew}
          >
            {" "}
            {t("navbar.new")}{" "}
          </Button>
        );
      }
    } else {
      return (
        <Button
          disabled
          variant="contained"
          className="mb-3"
          size="small"
          onClick={handleMaterialNew}
        >
          {" "}
          {t("navbar.new")}{" "}
        </Button>
      );
    }
  }

  const disableMaterialExistActions = !shippingMaterialSelect.find(
    (item) => item.id === materialId,
  )?.materailName?.id;

  return (
    <Grid item xs={6}>
      <Stack direction="row" spacing={1}>
        {editButton}
        {disableNewMaterialBtn()}
        {(userType.includes(USERROLES.PROCESSMANAGER) ||
          userType.includes(USERROLES.APPLICANT) ||
          userType.includes(USERROLES.ADMIN)) &&
        !isNotEditable ? (
          <Button
            variant="contained"
            className={disableMaterialExistActions ? "mb-3" : "Savebutton mb-3"}
            size="small"
            disabled={disableMaterialExistActions}
            onClick={handleMaterialCopy}
          >
            {t("material.copy")}
          </Button>
        ) : (
          <Button variant="contained" className=" mb-3" size="small" disabled>
            {t("material.copy")}
          </Button>
        )}
        {disableDeleteMaterialBtn()}
      </Stack>

      <Card variant="outlined">
        <CardContent>
          <MaterialTable
            shippingMaterialSelect={shippingMaterialSelect}
            materialId={materialId}
            handleChangeId={handleChangeId}
            shippingRequestDetails={shippingRequestDetails}
            countries={countries}
            isNotEditable={isNotEditable}
            allUnits={allUnits}
            shippingActionType={shippingActionType}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ShippingMaterialList;
