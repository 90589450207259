import React, { useEffect, useState } from "react";
import moment from "moment";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import Select from "../../common/CustomSelect";
import { useTranslation } from "react-i18next";
import { addressContainerStyles } from "../../Admin/Address/addressStyles";
import DropDownSearch from "../../common/DropDownSearch";
import {get} from "lodash"
const ItemContainer = ({ children, sm = "3" }) => {
  return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
};
const CountryComponent = (props) => {
  const { actions, countries } = props;
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [avg, setAvg] = useState("");
  const [length, setLength] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const classes = addressContainerStyles();
  const { t } = useTranslation();
  useEffect(() => {
    actions.getAllCountry().then((res) => {
      setIsLoading(false);
    });
  }, []);

  const handleCountryChange = (event) => {

    setIsLoading(true);
    const { value } = event.target;
    setCountry(value);
    if(value && value !== "")
    {
      actions.getShippingsCountry(value).then((res) => {
        let minLocal = 0;
        let maxLocal = 0;
        let avgLocal = 0;
        let total = 0;
        if (res.data.length > 0) {
          res.data.forEach((d) => {
            let startDate = moment(d.shipment_date_mon);
            let endDate = moment(d.close_date);
            let result = endDate.diff(startDate, "days");
  
            if (maxLocal < result) {
              maxLocal = result;
            }
            if (minLocal === 0 || (minLocal > result && result > 0)) {
              minLocal = result;
            }
            total = total + result;
          });
          avgLocal = total / res.data.length;
          setMax(maxLocal);
          setMin(minLocal);
          setAvg(avgLocal);
          setLength(res.data.length);
        } else {
          setMax("");
          setMin("");
          setAvg("");
          setLength("");
        }
        setIsLoading(false);
      });
    }
    else{
      setIsLoading(false);
    }
  
  };
  const Reset =()=>{
    setCountry("");
    setMax("");
    setMin("");
    setAvg("");
    setLength("");
  }

  return (
    <>
    <div className="countrytime" >
      <LoadingOverlay active={isLoading} spinner>
       
        <label className='title_main mt-1' 
                     >
                        {t("quries.titleDeliveryCountInCountry")}
                </label>      
        <Card variant="outlined">
        
          <CardContent>
            <Container maxWidth="xxxl"  className={classes.container}>
             <div className="mb10">
              <div className="row">
                <ItemContainer sm="2">
                  <span
                    className={classes.textLable}
                    style={{ marginTop: "8px" }}
                  >
                    {t("quries.quriesCountry")}
                  </span>
                </ItemContainer>
                <ItemContainer sm="5">
                  {/* <Select
                    list={countries.sort((a, b) => {
                      a = a.name_english.toLowerCase();
                      b = b.name_english.toLowerCase();
                      if (a === b) return 0;
                      return a < b ? -1 : 1;
                    })}
                    value={country}
                    name="Country"
                    keyname="name_english"
                    onChange={handleCountryChange}
                    style={{ width: "50%" }}
                    labelName=" "
                  /> */}
                                    <DropDownSearch
                    id="country_id"
                    name="country_id"
                    value={
                      countries.find(item => item.id === country)?.name
                    }
                    list={[
                      ...(countries || []).map((country) => ({
                        label: country.name_english,
                        id: country.id,
                      })),
                    ]}
                   // handleChange={handleCountryChange}
                    handleChange={(e) => {
                      handleCountryChange({
                        target: { name: e?.name_english, value: e?.id },
                      });}}
                    label={" "}
                  />
                </ItemContainer>
                <ItemContainer sm="2">
                <Button  variant="contained"
              className={classes.reloadBt  + " refresh_button"}
               onClick={(e)=>Reset()}> <ReplayIcon /></Button>
                </ItemContainer>
              </div>
              <Divider />
              <div className="row">
                <ItemContainer sm="4">
                  <span>{t("quries.maxOrder")}</span>
                </ItemContainer>
                <ItemContainer sm="6">{max ==="" ? "0": max}</ItemContainer>
              </div>
              <div className="row">
                <ItemContainer sm="4">
                  <span>{t("quries.minOrder")}</span>
                </ItemContainer>
                <ItemContainer sm="6">{min ==="" ? "0":min}</ItemContainer>
              </div>
              <div className="row">
                <ItemContainer sm="4">
                  <span>{t("quries.avgOrder")}</span>
                </ItemContainer>
                <ItemContainer sm="6">{avg ==="" ? "0.00":avg}</ItemContainer>
              </div>
              <div className="row">
                <ItemContainer sm="4">
                  <span>{t("quries.NoOrder")}</span>
                </ItemContainer>
                <ItemContainer sm="6">{length ==="" ? "0":length}</ItemContainer>
              </div></div>
            </Container>
          </CardContent>
        </Card>
      </LoadingOverlay></div>
    </>
  );
};

export default CountryComponent;
