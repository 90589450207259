import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { HelpDialog } from "../../common/Confirmation";
import HelpIcon from "@mui/icons-material/Help";
import * as config from "../../../config";
import { useHistory, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { USERROLES } from "../../../constants";
import deleteIcon from "../../../assests/images/deleteIcon.svg";
import { Alert } from "@mui/material";
import infoIcon from "../../../assests/images/infoIcon.svg";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AutocompleteText from "../../common/AutocompleteText";
import MultipleSelect from "../../common/MultipleSelect";
import materialService from "../../../Services/materialService";
import { authContext } from "../../../adalConfig";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import materialsRedux from "../../../redux/materials";

import "./common.css";
import { get,find } from "lodash";
import { Event } from "jquery";

export function EditMaterial({ materialDetails, actions }) {
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const { t } = useTranslation();
  const history = useHistory();
  const { materialId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [documents, setDocuments] = useState([]);
  const [uploadFlag, setuploadFlag] = useState("");
  const [documentComment, setdocumentComment] = useState("");
  const [existingdocuments, setExistingDocuments] = useState([]);
  const [data, setData] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const userType = JSON.parse(localStorage.getItem("userType"));
  const multipleOptions = [
    "Drug substance",
    "Drug product",
    "Excipient",
    "Reference standard",
    "Dietary supplement",
    "Cosmetic product",
    "Medicaments Not packaged for retail sale",
    "Medicaments Packaged for retail sale",
    "Other",
  ];
  const reducer = (state, event) => {
    if (event.type === "RESET") {
      return event.data;
    } else if (event.type === "MULTI") {
      return {
        ...state,
        ["material_type"]: event.data,
      };
    } else if (event.target.type === "radio") {
      return { ...state, [event.target.name]: JSON.parse(event.target.value) };
    } else if (
      event.target.name === "full_description" ||
      event.target.name === "fda_product_code" ||
      event.target.name === "fda_processing_program" ||
      event.target.name === "sli"
    ) {
      return {
        ...state,
        [event.target.name]: event.target?.value || "",
      };
    } else {
      return {
        ...state,
        [event.target.name]: event.target?.value.trim() || "",
      };
    }
  };
  useEffect(
    () => console.log(documents),

    [documents, documentComment, selectedFiles],
  );
  const [state, dispatch] = useReducer(reducer, {
    description: "",
    hazard_class: "",
    hazard_phrase: "",
    material_number: "",
    real_material_number: "",
    statistical_material_number: "",
    statistical_material_number_china: "",
    statistical_material_number_cnd: "",
    statistical_material_number_sthfrc: "",
    statistical_material_number_trky: "",
    statistical_material_number_usa: "",
    statistical_material_number_grmny: "",
    statistical_material_number_moscow: "",
    statistical_material_number_aust: "",
    statistical_material_number_india: "",
    eid: "",
    un_no: "",
    transport_class: "",
    pack_group: "",
    userId,
    full_description: "",
    sli: "",
    fda_product_code: "",
    fda_processing_program: "",
    is_hazardous:
      userType.includes(USERROLES.PROCESSMANAGER) ||
      userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
      userType.includes(USERROLES.ADMIN)
        ? null
        : false,
    contains_der_biologic: null,
    contains_animal_byproduct: null,
    contains_non_domestic_animal_byproduct: null,
    contains_infect_substance: null,
    contains_antibiotic: null,
    contains_pesticide: null,
    contains_parasiticide: null,
    material_type: [],
    hazardous_sds:
    userType.includes(USERROLES.PROCESSMANAGER) ||
      userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
      userType.includes(USERROLES.ADMIN)
        ? null
        : false,
  });

  useEffect(async () => {
    if (materialId) {
      let data = await materialService.getMaterialAttachments(materialId);
      setExistingDocuments(data);
    }
  }, [materialId]);

  const processedMaterialData = useMemo(() => {
    const data = {
      description: [],
      hazard_class: [],
      hazard_phrase: [],
      material_number: [],
      real_material_number: [],
      statistical_material_number: [],
      statistical_material_number_china: [],
      statistical_material_number_cnd: [],
      statistical_material_number_sthfrc: [],
      statistical_material_number_trky: [],
      statistical_material_number_usa: [],
      statistical_material_number_grmny: [],
      statistical_material_number_moscow: [],
      statistical_material_number_aust: [],
      statistical_material_number_india: [],
      eid: [],
      un_no: [],
      transport_class: [],
      pack_group: [],
    };

    const keys = Object.keys(data);

    for (let item of materialDetails) {
      for (let key of keys) {
        if (item[key] && !data[key].includes(item[key].trim())) {
          data[key].push(item[key].trim());
        }
      }
    }

    return data;
  }, [materialDetails]);

  useEffect(async () => {
    try {
      if (materialId) {
        const selectedMaterial = materialDetails.find(
          (item) => item.id === materialId,
        );

        if (!selectedMaterial.material_type) {
          selectedMaterial.material_type = [];
        }
        dispatch({ type: "RESET", data: selectedMaterial });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  }, [materialDetails]);

  const saveMaterial = async () => {
    try {
      let error = "";
      if (!state.description) {
        //return toast.error(t("materials.material_field_empty"));
        error = error + t("material.materialName") + ", ";
      }
      if (state.is_hazardous === null) {
        error = error + t("shipping_log_fields.is_hazardous") + ", ";
      }
      if (state.is_hazardous && (state.hazard_class === "" || state.hazard_class===null)) {
        error = error + t("materials.hazard_class") + ", ";
      }
      if (state.is_hazardous && (state.hazard_phrase === "" || state.hazard_phrase===null)) {
        error = error + t("materials.hazard_inducers") + ", ";
      }
      if (state.is_hazardous && (state.un_no === "" || state.un_no===null)) {
        error = error + t("materials.unNo") + ", ";
      }
      if (state.is_hazardous && (state.transport_class === "" || state.transport_class===null)) {
        error =
          error + t("shipping_log_fields.Class_Subsidiary_Hazard") + ", ";
      }
      if (state.is_hazardous && (state.pack_group === "" || state.pack_group===null)) {
        error =
          error + t("shipping_log_fields.pack_group") + ", ";
      }
      if (state.contains_der_biologic === null) {
        error = error + t("material.contains_der_biologic") + ", ";
      }
      if (state.contains_animal_byproduct === null) {
        error = error + t("material.contains_animal_byproduct") + ", ";
      }
      if (state.contains_non_domestic_animal_byproduct === null) {
        error =
          error + t("material.contains_non_domestic_animal_byproduct") + ", ";
      }
      if (state.hazardous_sds === null) {
        error =
          error + t("material.hazardous_sds") + ", ";
      }
      if (state.contains_infect_substance === null) {
        error = error + t("material.contains_infect_substance") + ", ";
      }
      if (state.contains_antibiotic === null) {
        error = error + t("material.contains_antibiotic") + ", ";
      }
      if (state.contains_pesticide === null) {
        error = error + t("material.contains_pesticide") + ", ";
      }
      if (state.contains_parasiticide === null) {
        error = error + t("material.contains_parasiticide") + ", ";
      }
      if (
        state.full_description === "" ||
        state.full_description === null ||
        state.full_description === undefined ||
        (state.full_description !== null &&
          state.full_description.trim().length === 0)
      ) {
        error = error + t("material.full_description") + ", ";
      }
      if (
        (userType.includes(USERROLES.PROCESSMANAGER) ||
          userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
          userType.includes(USERROLES.APPLICANT) ||
          userType.includes(USERROLES.ADMIN)) &&
        documents.length === 0 &&
        existingdocuments.length === 0
      ) {
        error = error + "Documents, ";
      }
      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req3") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req4"),
        );
      }
      const existingMaterial = materialDetails.find(
        (i) =>
          i.description.toLowerCase() === state.description.toLowerCase() &&
          i.id != materialId,
      );
      if (existingMaterial) {
        toast.error(t("commonlabels.alreadyExists"));
        return;
      }
      let data;
      let req = {};
      req.state = state;
      req.documents = documents;
      if (!get(req, "state.is_hazardous", true)) {
        req.state.hazard_class = "";
        req.state.hazard_phrase = "";
        req.state.transport_class = "";
        req.state.pack_group = "";
        req.state.un_no = "";
      }

      setIsLoading(true);
      if (materialId) {
        data = await materialService.updateMaterial(materialId, req);
        setIsLoading(false);
        toast.success(t("commonlabels.editedSuccessfullyMsg"));
      } else {
        req.isApprovedByAdmin =  userType.includes(USERROLES.SYSTEMADMINISTRATOR) ? 1 : 0;
        data = await materialService.saveMaterial(req);
        setIsLoading(false);
        toast.success(t("commonlabels.savedSuccessfullyMsg"));
      }

      sessionStorage.setItem("newAddedMaterial", JSON.stringify(data));
      actions.getAllMaterials();
      history.goBack();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      toast.error("Something went wrong.");
    }
  };

  const cancelAction = async () => {
    history.goBack();
  };

  const encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const miniSpinner = () => {
    return (
      <div>
        <ReactLoading
          type="spinningBubbles"
          color="#0072ce"
          display="inline"
          width="34px"
          height="34px"
        />
      </div>
    );
  };

  const handleFileSelect = async (e) => {
    setuploadFlag("");
    const newFiles = [];
    let fileData;
    for (const file of e.target.files) {
      fileData = {
        name: file.name,
        encodedData: await encodeFile(file),
        id: false,
        size: file.size,
      };
      newFiles.push(fileData);
    }
    if (fileData) {
      setuploadFlag("upload");
    }
    setSelectedFiles(fileData);
  };
  const handleChnageComments = (e) => {
    setdocumentComment(e.target.value);
  };
  const UploadAttachments = async () => {
    setuploadFlag("");
    try {
      const comments = documentComment;
      const userName = userId;
      let valid=true;
        if(find(documents, ['name', selectedFiles.name]))
        {
          valid=false;
        }
      if(valid)
      {
        setDocuments([...documents, { ...selectedFiles, comments, userName }]);
      }
      else{
        toast.error(t("exportLabel.filenameExists"));
      }
      setSelectedFiles({});
      document.getElementById("fileUpload").value = null;
      setdocumentComment("");
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const removeFile = async (index) => {
    const filteredFiles = documents.filter((file, index2) => index2 !== index);
    setDocuments(filteredFiles);
  };

  const removeFileForExistingAttachments = async (index, id) => {
    let data = await materialService.deleteMaterialAttachments(id);
    if (data.IsSuccess === true) {
      const filteredFiles = existingdocuments.filter(
        (file, index2) => index2 !== index,
      );
      setExistingDocuments(filteredFiles);
      toast.success(t("commonlabels.deleteSuccessfullyMsg"));
    } else {
      toast.error("Something went wrong.");
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    //setPersonName();
    // On autofill we get a stringified value.
    // typeof value === "string" ? value.split(",") : value,2
    // setData(value);
    dispatch({ type: "MULTI", data: value });
  };
  const handleCancelConfirmation = () => {
    setConfirmationOpen(false);
  };

  const openHelp = () => {
    setConfirmationOpen(true);
  };

  return (
    <>
      <div className=" col-12 title_main mt-1">
        {state?.id
          ? t("material.editmaterial")
          : t("material.newmaterialtitle")}
      </div>
      <Card variant="outlined">
        <CardContent>
          <Alert severity="warning">
            {t("shipping_request.save_shipping_request_alert")}
            <br></br>
          </Alert>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-3"></div>
            </div>
          </div>

          <div className="container-fluid">
            {userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
            userType.includes(USERROLES.ADMIN) ? (
              <div className="row col-12">
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("material.materialName")}</b>
                        <span className="req">*</span>{" "}
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="description"
                      options={
                        processedMaterialData.description.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.description}
                      onChange={dispatch}
                      required={true}
                    />
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.hazard_class")}</b>
                        {state.is_hazardous === true ? (
                          <span className="req">*</span>
                        ) : (
                          <span></span>
                        )}
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="hazard_class"
                      options={
                        processedMaterialData.hazard_class.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.hazard_class}
                      onChange={dispatch}
                      is_disabled={!state.is_hazardous}
                    />
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.hazard_inducers")}</b>
                        {state.is_hazardous === true ? (
                          <span className="req">*</span>
                        ) : (
                          <span></span>
                        )}
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="hazard_phrase"
                      options={
                        processedMaterialData.hazard_phrase.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.hazard_phrase}
                      onChange={dispatch}
                      is_disabled={!state.is_hazardous}
                    />
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.material_number")}</b>
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="material_number"
                      options={
                        processedMaterialData.material_number.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.material_number}
                      onChange={dispatch}
                    />
                  </div>
                </ItemContainer>
              </div>
            ) : (
              <></>
            )}

            {userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
            userType.includes(USERROLES.ADMIN) ? (
              <div className="row col-12">
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.tariff_classification_no_germany")}</b>
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="statistical_material_number_grmny"
                      options={
                        processedMaterialData.statistical_material_number_grmny.sort(
                          (a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a === b) return 0;
                            return a < b ? -1 : 1;
                          },
                        ) || []
                      }
                      value={state.statistical_material_number_grmny}
                      onChange={dispatch}
                    />
                  </div>
                </ItemContainer>
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.tariff_classification_no_usa")}</b>
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="statistical_material_number_usa"
                      options={
                        processedMaterialData.statistical_material_number_usa.sort(
                          (a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a === b) return 0;
                            return a < b ? -1 : 1;
                          },
                        ) || []
                      }
                      value={state.statistical_material_number_usa}
                      onChange={dispatch}
                    />
                  </div>
                </ItemContainer>

                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.tariff_classification_no_china")}</b>
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="statistical_material_number_china"
                      options={
                        processedMaterialData.statistical_material_number_china.sort(
                          (a, b) => {
                            a = a.toLowerCase();
                            b = b.toLowerCase();
                            if (a === b) return 0;
                            return a < b ? -1 : 1;
                          },
                        ) || []
                      }
                      value={state.statistical_material_number_china}
                      onChange={dispatch}
                    />
                  </div>
                </ItemContainer>
              </div>
            ) : (
              <></>
            )}

            <div className="row col-12">
              {!(
                userType.includes(USERROLES.PROCESSMANAGER) ||
                userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
                userType.includes(USERROLES.ADMIN)
              ) ? (
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("material.materialName")}</b>
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="description"
                      options={
                        processedMaterialData.description.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.description}
                      onChange={dispatch}
                      required={true}
                    />
                  </div>
                </ItemContainer>
              ) : (
                <></>
              )}
              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("materials.eid")}</b>
                    </label>{" "}
                  </div>
                  <AutocompleteText
                    label=" "
                    name="eid"
                    options={
                      processedMaterialData.eid.sort((a, b) => {
                        a = a.toLowerCase();
                        b = b.toLowerCase();
                        if (a === b) return 0;
                        return a < b ? -1 : 1;
                      }) || []
                    }
                    value={state.eid}
                    onChange={dispatch}
                  />
                </div>
              </ItemContainer>
              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("materials.material_type")}</b>
                    </label>{" "}
                  </div>
                  <MultipleSelect
                    name="material_type"
                    options={multipleOptions}
                    value={state.material_type}
                    onChange={handleChange}
                    propFrom={"admin_material"}
                    label={"  "}
                  />
                </div>
              </ItemContainer>
              {userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("shipping_log_fields.is_hazardous")}</b>
                        <span className="req">*</span>{" "}
                      </label>{" "}
                    </div>
                    <div class="radio-inline">
                      <input
                        type="radio"
                        id="radio"
                        name="is_hazardous"
                        value={true}
                        onChange={dispatch}
                        checked={state.is_hazardous === true}
                      ></input>
                      &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="radio"
                        name="is_hazardous"
                        value={false}
                        onChange={dispatch}
                        checked={state.is_hazardous === false}
                      ></input>
                      &nbsp; {t("commonlabels.noButton")}
                    </div>
                  </div>
                </ItemContainer>
              ) : (
                <></>
              )}
 {userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ? (
              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>
                        {t("material.hazardous_sds")}
                      </b>
                      <span className="req">*</span>{" "}
                      <ReactTooltip
                        id="radio"
                        className=""
                        data-html={true}
                      ></ReactTooltip>
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="hazardous_sds"
                      value={true}
                      onChange={dispatch}
                    checked={
                      state.hazardous_sds === true
                    }
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="hazardous_sds"
                      value={false}
                      onChange={dispatch}
                    checked={
                      state.hazardous_sds === false
                    }
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
                
              </ItemContainer>
              ) : (
                <></>
              )}

              {userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>{t("materials.unNo")}</b>
                        {state.is_hazardous === true ? (
                          <span className="req">*</span>
                        ) : (
                          <span></span>
                        )}
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="un_no"
                      options={
                        processedMaterialData.un_no.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.un_no}
                      onChange={dispatch}
                      is_disabled={!state.is_hazardous}
                    />
                  </div>
                </ItemContainer>
              ) : (
                <></>
              )}
              {userType.includes(USERROLES.PROCESSMANAGER) ||
                userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
                userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>
                          {t("shipping_log_fields.Class_Subsidiary_Hazard")}
                        </b>
                        {state.is_hazardous === true ? (
                          <span className="req">*</span>
                        ) : (
                          <span></span>
                        )}
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="transport_class"
                      options={
                        processedMaterialData.transport_class.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.transport_class}
                      onChange={dispatch}
                      is_disabled={!state.is_hazardous}
                    />
                  </div>
                </ItemContainer>
              ) : (
                <></>
              )}
              {userType.includes(USERROLES.PROCESSMANAGER) ||
                userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
                userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div class="">
                    <div class="">
                      {" "}
                      <label className="">
                        {" "}
                        <b>
                          {t("shipping_log_fields.pack_group")}
                        </b>
                        {state.is_hazardous === true ? (
                          <span className="req">*</span>
                        ) : (
                          <span></span>
                        )}
                      </label>{" "}
                    </div>
                    <AutocompleteText
                      label=" "
                      name="pack_group"
                      options={
                        processedMaterialData.pack_group?.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a === b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state.pack_group}
                      onChange={dispatch}
                      is_disabled={!state.is_hazardous}
                    />
                  </div>
                </ItemContainer>
              ) : (
                <></>
              )}
            </div>
            <div className="row col-12">
              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.contains_der_biologic")}</b>
                      <span className="req">*</span>{" "}
                      <img
                        alt=""
                        style={{ width: "15px" }}
                        src={infoIcon}
                        className=""
                        data-for="1"
                        data-tip={t("material.contains_der_biologic_tooltip")}
                        data-html={true}
                        data-place="bottom"
                      />
                      <ReactTooltip
                        id="1"
                        className=""
                        data-html={true}
                      ></ReactTooltip>
                    </label>{" "}
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_der_biologic"
                      value={true}
                      onChange={dispatch}
                      checked={state.contains_der_biologic === true}
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_der_biologic"
                      value={false}
                      onChange={dispatch}
                      checked={state.contains_der_biologic === false}
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>

              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>{t("material.contains_animal_byproduct")}</b>
                      <span className="req">*</span>{" "}
                      <img
                        alt=""
                        style={{ width: "15px" }}
                        src={infoIcon}
                        className=""
                        data-for="1"
                        data-tip={t(
                          "material.contains_animal_byproduct_tooltip",
                        )}
                        data-html={true}
                        data-place="bottom"
                      />
                      <ReactTooltip
                        id="1"
                        className=""
                        data-html={true}
                      ></ReactTooltip>
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_animal_byproduct"
                      value={true}
                      onChange={dispatch}
                      checked={state.contains_animal_byproduct === true}
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_animal_byproduct"
                      value={false}
                      onChange={dispatch}
                      checked={state.contains_animal_byproduct === false}
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>

              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>
                        {t("material.contains_non_domestic_animal_byproduct")}
                      </b>
                      <span className="req">*</span>{" "}
                      <img
                        alt=""
                        style={{ width: "15px" }}
                        src={infoIcon}
                        className=""
                        data-for="1"
                        data-tip={t(
                          "material.contains_non_domestic_animal_byproduct_tooltip",
                        )}
                        data-html={true}
                        data-place="bottom"
                      />
                      <ReactTooltip
                        id="1"
                        className=""
                        data-html={true}
                      ></ReactTooltip>
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_non_domestic_animal_byproduct"
                      value={true}
                      onChange={dispatch}
                      checked={
                        state.contains_non_domestic_animal_byproduct === true
                      }
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_non_domestic_animal_byproduct"
                      value={false}
                      onChange={dispatch}
                      checked={
                        state.contains_non_domestic_animal_byproduct === false
                      }
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>
              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>{t("material.contains_infect_substance")}</b>
                      <span className="req">*</span>{" "}
                      <img
                        alt=""
                        style={{ width: "15px" }}
                        src={infoIcon}
                        className=""
                        data-for="1"
                        data-tip={t(
                          "material.contains_infect_substance_tooltip",
                        )}
                        data-html={true}
                        data-place="bottom"
                      />
                      <ReactTooltip
                        id="1"
                        className=""
                        data-html={true}
                      ></ReactTooltip>
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_infect_substance"
                      value={true}
                      onChange={dispatch}
                      checked={state.contains_infect_substance === true}
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_infect_substance"
                      value={false}
                      onChange={dispatch}
                      checked={state.contains_infect_substance === false}
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>
            </div>
            <div className="row col-12">
              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>{t("material.contains_antibiotic")}</b>
                      <span className="req">*</span>{" "}
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_antibiotic"
                      value={true}
                      onChange={dispatch}
                      checked={state.contains_antibiotic === true}
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_antibiotic"
                      value={false}
                      onChange={dispatch}
                      checked={state.contains_antibiotic === false}
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>

              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>{t("material.contains_pesticide")}</b>
                      <span className="req">*</span>{" "}
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_pesticide"
                      value={true}
                      onChange={dispatch}
                      checked={state.contains_pesticide === true}
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_pesticide"
                      value={false}
                      onChange={dispatch}
                      checked={state.contains_pesticide === false}
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>

              <ItemContainer>
                <div class="">
                  <div class="">
                    {" "}
                    <label>
                      <b>{t("material.contains_parasiticide")}</b>
                      <span className="req">*</span>{" "}
                    </label>
                  </div>
                  <div class="radio-inline">
                    <input
                      type="radio"
                      id="radio"
                      name="contains_parasiticide"
                      value={true}
                      onChange={dispatch}
                      checked={state.contains_parasiticide === true}
                    ></input>
                    &nbsp; {t("commonlabels.yesButton")} &nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="radio"
                      name="contains_parasiticide"
                      value={false}
                      onChange={dispatch}
                      checked={state.contains_parasiticide === false}
                    ></input>
                    &nbsp; {t("commonlabels.noButton")}
                  </div>
                </div>
              </ItemContainer>
            </div>
            <div className="row col-12">
              <ItemContainer>
                <div className="">
                  {" "}
                  <label className="">
                    {" "}
                    <b>{t("material.full_description")}</b>
                    <span className="req">*</span>{" "}
                    <img
                      alt=""
                      style={{ width: "15px" }}
                      src={infoIcon}
                      className=""
                      data-for="1"
                      data-tip={t("material.full_description_tooltip")}
                      data-html={true}
                      data-place="bottom"
                    />
                    <ReactTooltip
                      id="1"
                      className=""
                      data-html={true}
                    ></ReactTooltip>
                  </label>{" "}
                </div>
                <TextareaAutosize
                  id="full_description"
                  name="full_description"
                  aria-label="full_description"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  minRows={2}
                  style={{ width: "100%" }}
                  value={
                    state.full_description === null
                      ? ""
                      : state.full_description
                  }
                  onChange={dispatch}
                />
              </ItemContainer>
              {userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.fda_product_code")}</b>{" "}
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="fda_product_code"
                    name="fda_product_code"
                    aria-label="fda_product_code"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={
                      state.fda_product_code === null
                        ? ""
                        : state.fda_product_code
                    }
                    onChange={dispatch}
                  />
                </ItemContainer>
              ) : (
                <></>
              )}
              {userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>{t("material.fda_processing_program")}</b>{" "}
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="fda_processing_program"
                    name="fda_processing_program"
                    aria-label="fda_processing_program"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={
                      state.fda_processing_program === null
                        ? ""
                        : state.fda_processing_program
                    }
                    onChange={dispatch}
                  />
                </ItemContainer>
              ) : (
                <></>
              )}
              {userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ? (
                <ItemContainer>
                  <div className="">
                    {" "}
                    <label className="">
                      {" "}
                      <b>SPI</b>{" "}
                    </label>{" "}
                  </div>
                  <TextareaAutosize
                    id="sli"
                    name="sli"
                    aria-label="sli"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={state.sli === null ? "" : state.sli}
                    onChange={dispatch}
                  />
                </ItemContainer>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row ml15 mr15 mlr15 border">
            <div className="col-12   heading_box">
              {t("material.Add/View_Documents")}{" "}
              <span style={{ color: "red" }}>
                {userType.includes(USERROLES.PROCESSMANAGER) ||
                userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
                userType.includes(USERROLES.APPLICANT) ||
                userType.includes(USERROLES.ADMIN)
                  ? "*"
                  : ""}
              </span>{" "}
              <span title={t("shipping_request.helpTitle")}>
                <HelpIcon onClick={openHelp} />
              </span>
            </div>
            <div className="row col-md-12 pl-0 pr-0">
              <div className="col-md-3">
                <ItemContainer>
                  <input
                    type="file"
                    id="fileUpload"
                    onChange={(e) => handleFileSelect(e)}
                  />
                </ItemContainer>
              </div>
              <ItemContainer>
                <div className="">
                  {" "}
                  <label className="">
                    {" "}
                    <b>{t("shipping_request.Comments")}</b>{" "}
                  </label>
                  <TextareaAutosize
                    id="full_description"
                    name="full_description"
                    aria-label="full_description"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    minRows={2}
                    style={{ width: "100%" }}
                    value={documentComment === null ? "" : documentComment}
                    onChange={(e) => handleChnageComments(e)}
                    disabled={uploadFlag === ""}
                  />
                </div>
              </ItemContainer>
              <div className="pt10">
                <label>&nbsp;</label>
                <button
                  className="btn btn-primary new_button"
                  onClick={UploadAttachments}
                  disabled={uploadFlag === ""}
                >
                  {t("dashboard.FileUploadtitle")}
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row pl-0">
                <>
                  {documents.length === 0 && existingdocuments.length === 0 ? (
                    <table id="" className="editmaterialtable">
                      {" "}
                      <tr>
                        <th>{t("material.file_name")}</th>
                        <th>{t("shipping_request.Comments")}</th>
                        <th>{t("shipping_request.delete")}</th>
                      </tr>
                      <tbody>
                        <tr>{t("commonlabels.emptyDataSourceMessage")}</tr>
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-md-12">
                      {/* {documents.length > 0 ? ( */}
                      <div className="row pl-0 mb10">
                        <table id="" className="editmaterialtable">
                          <tr>
                            <th>{t("material.file_name")}</th>
                            <th>{t("shipping_request.Comments")}</th>
                            <th>{t("shipping_request.delete")}</th>
                          </tr>
                          {existingdocuments.map((val, key) => {
                            return (
                              <>
                                <tbody>
                                  <tr key={key}>
                                    <td>
                                      {
                                        <a
                                          href={
                                            config.baseURL +
                                            "/api/downloadFile/" +
                                            val.id +
                                            "/Material_MasterTable"
                                          }
                                        >
                                          {val.file_name}
                                        </a>
                                      }
                                    </td>
                                    <td>{val.comments}</td>
                                    <td>
                                      <a
                                        id=""
                                        onClick={(e) => {
                                          removeFileForExistingAttachments(
                                            key,
                                            val.id,
                                          );
                                        }}
                                      >
                                        <img
                                          style={{ cursor: "pointer" }}
                                          alt="t"
                                          className="dlt_icon"
                                          src={deleteIcon}
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          })}
                          {documents.map((val, key) => {
                            return (
                              <>
                                <tbody>
                                  <tr key={key}>
                                    <td>{val.name}</td>
                                    <td>{val.comments}</td>
                                    <td>
                                      <a
                                        id=""
                                        onClick={(e) => {
                                          removeFile(key);
                                        }}
                                      >
                                        <img
                                          style={{ cursor: "pointer" }}
                                          alt="t"
                                          className="dlt_icon"
                                          src={deleteIcon}
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          })}
                        </table>
                      </div>

                      {/* ) : ("")} */}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </CardContent>
        <div className="mb50 pull-right">
          <div className="row col-12">
            <CardActions>
              {isLoading && miniSpinner()}
              <button
                className="btn btn-primary new_button"
                onClick={saveMaterial}
                disabled={isLoading}
              >
                {t("materials.save")}
              </button>
              <button
                className="btn btn-secondary cancelbutton"
                onClick={cancelAction}
              >
                {t("materials.cancel")}
              </button>
            </CardActions>
          </div>
        </div>
      </Card>
      <HelpDialog
        title={t("shipping_request.helpTitle")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                "shipping_material_validations.shipping_material_document_help",
              ),
            }}
          />
        }
        confirmationOpen={confirmationOpen}
        handleCancelConfirmation={handleCancelConfirmation}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  materialDetails: state.materials.allMaterials,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAllMaterials: materialsRedux.actions.getAllMaterials,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMaterial);

const ItemContainer = ({ children }) => {
  return <div className="col-12 col-sm-3 form_item">{children}</div>;
};
