import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";



import CustomTable from "../common/CustomTable";
import Search from "../common/search";

import {
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";

import { RecipientsColumns } from "../common/Columns";
import ButtonGroup from "../common/ButtonGroup";
import PageLayout from "../common/PageLayout";

const RecipientsComponent = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  //const [finalRecipientData, setFinalRecipientData] = useState({})
  const [searchFieldValues, setSearchFieldValues] = useState([]);
  const [selectedRow, setSelectedRow] = useState({ tableData: { id: 0 } });
  const [firstRowData, setFirstRowData] = useState({});

  const { actions, finalRecipients, countries } = props;
  
  const handlerSelectedRow = (row) => {
    setSelectedRow(row);
  };

  const handleNewRecipient = () => {
    //actions.redirectAction("/shipping");
    //history.push("/addRecipients");
    history.push("/addRecipients", {
      redirectShipping: true,
    });
  };

  const getFirstRowData = (row) => {
    setFirstRowData(row);
  };

  const handleAcceptRecipient = () => {
    let rowData = {};

    if (selectedRow.tableData.id === 0) {
      rowData = firstRowData;
    }
    if (selectedRow.tableData.id !== 0) {
      rowData = selectedRow;
    }
    actions.finalRecipientSelect(rowData);
    if (rowData.address_id) {
      actions.destinationAddrSelect({
        ...rowData,
        id: rowData.address_id,
        tableData: {
          id: rowData.address_id,
        },
      });
    }else {
      actions.clearDestinationAddrSelect();
    }
    history.push(`/shipping`);
  };

  const handleCancelRecipient = () => {
    actions.clearFinalRecipientSelect();
    history.push(`/shipping`);
  };
  const searchData = (data) => {
    setSearchFieldValues(data);
  };

  const columns = RecipientsColumns(
    selectedRow,
    handlerSelectedRow,
    getFirstRowData,
  );
  return (
    <>
      <PageLayout
        title={"Search Recipient"}
        searchComponent={
          <Search
            columns={[
              {
                title: <span>{t("search_fields.first_name")}</span>,
                field: "first_name",
              },

              {
                title: <span>{t("recipient.Last name")}</span>,
                field: "last_name",
              },
              {
                title: <span>{t("recipient.Email")}</span>,
                field: "email",
              },
              {
                title: <span>{t("registration.Address")}</span>,
                field: "address",
              },
              {
                title: <span>{t("address.Country")}</span>,
                field: "name",
                type: "select",
                list: countries,
              },
              // {
              //   title: <span>{"ID"}</span>,
              //   field: "id",
              // },
              {
                title: <span>{t("shippingrequest.create_from")}</span>,
                field: "from_date",
                type: "date",
              },
              {
                title: <span>{t("shippingrequest.create_till")}</span>,
                field: "to_date",
                type: "date",
              },
            ]}
            onSearch={(data) => searchData(data)}
          />
        }
        mainComponent={
          <CustomTable
            columns={columns}
            listData={finalRecipients}
            selectedData={selectedRow}
            searchFieldValues={{
              ...searchFieldValues,
              name: countries?.find(
                (country) => country.id === searchFieldValues.name,
              )?.name,
            }}
          >
            <ButtonGroup
              buttonNames={[
                {
                  name: "New",
                  Icon: AddIcon,
                  fill: "#3cb371",
                  onClick: handleNewRecipient,
                  listData: finalRecipients,
                },
                {
                  name: "Accept",
                  Icon: CheckCircleIcon,
                  fill: "#3cb371",
                  onClick: handleAcceptRecipient,
                  listData: finalRecipients,
                },
                {
                  name: "Cancel",
                  Icon: CancelIcon,
                  fill: "#ff0000",
                  onClick: handleCancelRecipient,
                  listData: finalRecipients,
                },
              ]}
            />
          </CustomTable>
        }
      />
    </>
  );
};

export default RecipientsComponent;
