import * as config from "./config";
import { loadUserDetails } from "./auth";
import "./index.css";
import "./assests/css/styles.css";
import "font-awesome/css/font-awesome.min.css";
import "./components/HeaderComponent/navBar.css";
import "./i18n";
import { authContext, loginRequest } from "./adalConfig";
import "core-js";

new Promise(async(resolve, reject) => {
  await authContext.handleRedirectPromise().then(
    async () => {
      const accounts = await authContext.getAllAccounts();
      const currentAccount = accounts[0];
      const silentAccessTokenRequest = {
        scopes: loginRequest.scopes,
        account: currentAccount,
        forceRefresh: false,
      };

      let accessToken =
        currentAccount &&
        (await authContext
          .acquireTokenSilent(silentAccessTokenRequest)
          .then((accessTokenResponse) => accessTokenResponse.accessToken));
      if (accounts.length === 0) {
        // No user signed in
        await authContext.loginRedirect(loginRequest).then(
          () => {
            loadUserDetails(accessToken, currentAccount);
            // require("./auth.js");
            resolve();
          },
          (err) => {
            reject(err);
            console.error("error in " + err);
          },
        );
      } else {
        loadUserDetails(accessToken, currentAccount);
        resolve();
      }
    },
    (err) => {
      reject(err);
      console.error("error " + err);
    },
  );
});
