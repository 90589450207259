import React, { useState, useEffect, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@material-ui/core/Grid";
import { TextField, Select, MenuItem, FormControl } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import { trim } from "lodash";
import { useTranslation } from "react-i18next";
import { authContext } from "../../../adalConfig";
import { toast } from "react-toastify";
// import Select from "../../common/CustomSelect";
import AutocompleteText from "./AutocompleteText";
import { addressSearchStyles } from "./addressStyles";
import { USERROLES } from "../../../constants";
import { SortFunctionNew } from "../../../Services/util";
import { useMsal } from "@azure/msal-react";
import ActionConfirmModal from "../../common/ActionConfirmModal";

const AddAddress = (props) => {
  const { actions, countries, allAddress, redirectTo } = props;
  const { instance } = useMsal();
  const userType = JSON.parse(localStorage.getItem("userType"));
  const userId = instance.getActiveAccount().username;
  const { t } = useTranslation();
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryList, setCountryList] = useState(countries);
  const classes = addressSearchStyles();
  const [sapNumber, setSapNumber] = useState(null);
  const [company, setCompany] = useState(null);
  const [department, setDepartment] = useState(null);
  const [bulliging, setBulliging] = useState(null);
  const [street, setStreet] = useState(null);
  const [postal, setPostal] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState("");
  const [createdby, setCreatedby] = useState(null);
  const [recipient, setRecipient] = useState([]);
  const [confirmDuplicateSave, setConfirmDuplicateSave] = useState(false);
  const [addressDuplicateDataToSave, setAddressDuplicateDataToSave] = useState({});
  
  useEffect(() => {
    if (props.location.state) {
      if (countries.length === 0) {
        actions.getCountriesList().then((res) => {
          setCountryList(res.data);
        });
      }
      if (allAddress.length === 0) {
        actions.getAllAddress().then((res) => {});
      }
      if (props.location.state?.id !== null && props.location.state?.id !== undefined) {
        actions.getAddress(props.location?.state?.id).then((res) => {
          const addressData = res.data;
          setSapNumber(addressData[0].sapnumber);
          setCompany(addressData[0].entity);
          setDepartment(addressData[0].department);
          setBulliging(addressData[0].building);
          setStreet(addressData[0].street);
          setState(addressData[0].state);
          setPostal(addressData[0].postcode);
          setCity(addressData[0].city);
          setCountry(addressData[0].country_id);
          setCreatedby(addressData[0].created_on);
          actions.getAddressRecipient(props.location.state.id).then((res) => {
            setIsLoading(false);
            if (res.data.length > 0) {
              setRecipient(res.data);
            }
          });
        });
        setEdit(true);
      } else {
        setIsLoading(false);
        setEdit(false);
      }
    } else {
      if (countries.length === 0) {
        actions.getCountriesList().then((res) => {
          setCountryList(res.data);
          setIsLoading(false);
        });
      }
      if (allAddress.length === 0) {
        actions.getAllAddress().then((res) => {
          setIsLoading(false);
        });
      }
      if (countries.length > 0 && allAddress.length > 0) {
        setIsLoading(false);
      }
    }
  }, []);
  const processedAddresslData = useMemo(() => {
    const data = {
      address_id: [],
      Approved: [],
      building: [],
      material_number: [],
      city: [],
      state: [],
      department: [],
      entity: [],
      postcode: [],
      sapnumber: [],
      street: [],
      submmited: [],
      valid: [],
      Createdby: null,
      updatedBy: null,
    };

    const keys = Object.keys(data);

    for (let item of allAddress) {
      for (let key of keys) {
        if (item[key] && !data[key].includes(trim(item[key]))) {
          data[key].push(trim(item[key]));
        }
      }
    }
    return data;
  }, [allAddress]);

  const handleRedirectTo = (result = {}) => {
    if (!!props.location?.state &&
      !!redirectTo || props.location?.state?.redirectAddRecipient) {
      const id = result.upsert_address && result.upsert_address;
      actions.getAddress(id).then((res) => {
        if (!!props.location?.state && props.location?.state?.redirectAddRecipient === true) {
          localStorage.setItem("selectedRecipientAddressId", res.data[0]?.id);
        } else {
          actions.destinationAddrSelect(res.data[0]);
        }
      });
      if ( !!props.location?.state &&
        props.location?.state?.redirectShipping === true ||
        props.location?.state?.redirectAddRecipient === true) {
        history.goBack();
      } else {
        history.push(redirectTo);
      }
    } else {
      history.push(`/address`);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  // const handleClose = () => {
  //   history.push(`/address`);
  // }
  const handleSave = () => {
    setIsLoading(true);
    try {
      let error = "";

      if (!company) {
        error = t("address.Company/University") + ", ";
      }
      if (!street) {
        error = error + t("address.Street") + ", ";
      }
      if (!postal) {
        error = error + t("address.Postal Code") + ", ";
      }

      if (!city) {
        error = error + t("address.City") + ", ";
      }
      // if (!state) {
      //   error = error + t("address.State")+ ", ";
      // }
      if (!country) {
        error = error + t("address.Country");
      }
      if (error !== "") {
        setIsLoading(false);
        return toast.error(
          t("commonlabels.is_req3") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req4"),
        );
      }
      if (edit) {
        const addressData = {
          address_id: props.location.state.id,
          country_Id: country,
          Approved:
            userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.SYSTEMADMINISTRATOR)
              ? 1
              : 0,
          building: bulliging,
          city: city,
          state: state,
          department: department,
          entity: company,
          postcode: postal,
          sapnumber: sapNumber,
          street: street,
          submmited: 1,
          valid: 1,
          Createdby: createdby,
          updatedBy: userId,
          isSaveDuplicate: false,
        };
        actions.editAddress(addressData).then((res) => {
          if(res.data.isDuplicateFound) {
            setAddressDuplicateDataToSave(addressData);
            setConfirmDuplicateSave(true);
          }

          if(res.data.isSuccessful) {
            actions.getAllAddress();
            setIsLoading(false);
            toast.success(t("commonlabels.editedSuccessfullyMsg"));
            history.push(`/address`);
          }          
        });
      } else {
        const addressData = {
          address_id: 0,
          country_Id: country,
          Approved:
            userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.SYSTEMADMINISTRATOR)
              ? 1
              : 0,
          building: bulliging,
          city: city,
          state: state,
          department: department,
          entity: company,
          postcode: postal,
          sapnumber: sapNumber,
          street: street,
          submmited:
            userType.includes(USERROLES.PROCESSMANAGER) ||
            userType.includes(USERROLES.SYSTEMADMINISTRATOR)
              ? 1
              : 0,
          valid: 1,
          Createdby: userId,
          updatedBy: userId,
          isSaveDuplicate: false,
        };
        actions.saveAddress(addressData).then((res) => {
          if(res.data.isDuplicateFound) {
            setAddressDuplicateDataToSave(addressData);
            setConfirmDuplicateSave(true);
          }

          if(res.data.isSuccessful) {
            actions.getAllAddress();
            setIsLoading(false);
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            handleRedirectTo(res.data);
            //history.push(`/address`);
          }          
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "sap_number") {
      setSapNumber(value);
    } else if (name === "Company") {
      setCompany(value);
    } else if (name === "Department") {
      setDepartment(value);
    } else if (name === "Bulliging") {
      setBulliging(value);
    } else if (name === "Street") {
      setStreet(value);
    } else if (name === "Postal") {
      setPostal(value);
    } else if (name === "City") {
      setCity(value);
    } else if (name === "State") {
      setState(value);
    }
  };
  const handleCountryChange = (event) => {
    const { value } = event.target;
    setCountry(value);
  };
  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        {edit ? (
          <>
            <Box sx={{ flexGrow: 1 }} m={2} pt={1}>
              <label className="title_main">
                {" "}
                {edit ? t("address.addressEditTitle") : t("address.AddAddress")}
              </label>
              <Card variant="outlined">
                <CardContent className="p-2 notice transparantbg">
                  <Alert severity="warning">
                    {t("shipping_request.save_shipping_request_alert")}
                    <br></br>
                  </Alert>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ flexGrow: 1 }} m={2} pt={1}>
              <Card variant="outlined">
                <CardContent className="p-2 notice">
                  {userType.includes(USERROLES.PROCESSMANAGER)
                    ? t("address.addressAddNoticeForProcessManager")
                    : t("address.addressAddNoticeForAdmin")}
                </CardContent>
              </Card>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ flexGrow: 1 }} m={2} pt={1}>
              <label className="title_main">
                {" "}
                {edit ? t("address.addressEditTitle") : t("address.AddAddress")}
              </label>
              <Card variant="outlined">
                <CardContent className="p-2 notice transparantbg">
                  <Alert severity="warning">
                    {t("shipping_request.save_shipping_request_alert")}
                    <br></br>
                  </Alert>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ flexGrow: 1 }} m={2} pt={1}>
              <Card variant="outlined">
                <CardContent className="p-2 notice">
                  {userType.includes(USERROLES.PROCESSMANAGER)
                    ? t("address.addressAddNoticeForProcessManager")
                    : t("address.addressAddNoticeForAdmin")}
                </CardContent>
              </Card>
            </Box>
          </>
        )}
        <div className="row col-12 no_padding no-marggin mt-2 editaddress">
          <div className="col-lg-5 col-12 ">
            <Grid item xs={12} style={{ display: "none" }}>
              <span className={classes.textLableSAP + " text_label"}>
                {t("address.SAP Number Country")}
              </span>
              <TextField
                id="sap_number"
                margin="normal"
                variant="outlined"
                size="small"
                name="sap_number"
                value={sapNumber}
                onChange={handleChange}
                style={{ width: "60%" }}
              />
            </Grid>
            <Grid>
              <Card variant="outlined" className={classes.container}>
                <CardHeader title={t("address.addNewAddressTitle")} />
                <Divider />
                <CardContent>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <span className={classes.textLable}>
                      {t("address.Company/University")}{" "}
                      <span className={classes.textLableAsterisk}>*</span>
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="Company"
                      options={
                        processedAddresslData.entity.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={company}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <span className={classes.textLable}>
                      {t("address.Department")}
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="Department"
                      options={
                        processedAddresslData.department.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={department}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <span className={classes.textLable}>
                      {t("address.building")}
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="Bulliging"
                      options={
                        processedAddresslData.building.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={bulliging}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <span className={classes.textLable}>
                      {t("address.Street")}
                      <span className={classes.textLableAsterisk}>*</span>
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="Street"
                      options={
                        processedAddresslData.street.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={street}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <span className={classes.textLable}>
                      {t("address.Postal Code")}
                      <span className={classes.textLableAsterisk}>*</span>
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="Postal"
                      options={
                        processedAddresslData.postcode.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={postal}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", marginBottom: "5px" }}
                  >
                    <span className={classes.textLable}>
                      {t("address.City")}
                      <span className={classes.textLableAsterisk}>*</span>
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="City"
                      options={
                        processedAddresslData.city.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={city}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", marginBottom: "5px" }}
                  >
                    <span className={classes.textLable}>
                      {t("address.State")}
                    </span>
                    <AutocompleteText
                      className="mt-2 mr-2"
                      name="State"
                      options={
                        processedAddresslData.state.sort((a, b) => {
                          a = a.toLowerCase();
                          b = b.toLowerCase();
                          if (a == b) return 0;
                          return a < b ? -1 : 1;
                        }) || []
                      }
                      value={state}
                      label=" "
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", marginBottom: "10px" }}
                  >
                    <span
                      className={classes.textLable}
                      style={{ marginTop: "8px" }}
                    >
                      {t("address.Country")}
                      <span className={classes.textLableAsterisk}>*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        value={country}
                        name="Country"
                        labelName=""
                        onChange={handleCountryChange}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        variant="outlined"
                      >
                        {countryList.map((obj) => (
                          <MenuItem key={obj.id} value={obj.id}>
                            {obj.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </div>
          <div className="col-lg-7 col-12 ">
            <MaterialTable
              columns={[
                {
                  title: <span>{t("recipient.name")}</span>,
                  field: "first_name",
                  customSort: (a, b) => {
                    if (
                      (a.first_name + a.last_name).toString().toLowerCase() >
                      (b.first_name + b.last_name).toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      (a.first_name + a.last_name).toString().toLowerCase() <
                      (b.first_name + b.last_name).toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) => (
                    <span>
                      {rowData.first_name} {rowData.last_name}
                    </span>
                  ),
                },

                {
                  title: <span>{t("registration.Email")}</span>,
                  field: "email",
                  customSort: SortFunctionNew("email"),
                },
                {
                  title: <span>{t("registration.Phone")}</span>,
                  field: "phone",
                  customSort: SortFunctionNew("phone"),
                },

                {
                  title: <span>{t("address.Created User")}</span>,
                  field: "created_by",
                  customSort: SortFunctionNew("created_by"),
                },
                {
                  title: <span>{t("address.Approved")}</span>,
                  customSort: (a, b) => {
                    if (a.approved > b.approved) {
                      return -1;
                    } else if (a.approved < b.approved) {
                      return 1;
                    } else if (a.approved === b.approved) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.approved === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("address.Valid")}</span>,
                  customSort: (a, b) => {
                    if (a.valid > b.valid) {
                      return -1;
                    } else if (a.valid < b.valid) {
                      return 1;
                    } else if (a.valid === b.valid) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.valid === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
              ]}
              data={recipient}
              title="Render Image Preview"
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: {
                  height: "30px",
                  padding: "0px",
                },

                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: false,
                search: false,
                selection: false,

                showTitle: false,
                toolbar: false,
                paging: false,
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
            <Box
              style={{
                margiTop: "15px",
                marginBottom: "30px",
              }}
            >
              <div>{t("address.addNewAddressFooter")}</div>
            </Box>
          </div>
        </div>

        <CardActions className={classes.cardActionButtons}>
          <button className="btn btn-primary new_button " onClick={handleSave}>
            {t("commonlabels.saveButton")}
          </button>
          <button className="btn btn-black new_button" onClick={handleClose}>
            {t("commonlabels.cancelbutton")}
          </button>
        </CardActions>
      </LoadingOverlay>
      <ActionConfirmModal
              show={confirmDuplicateSave}
              headerText={t("address.duplicateAddress")}
              bodyText={t("address.duplicateSave")}
              confirmButtonText={t("commonlabels.yesButton")}
              cancelButtonText={t("commonlabels.noButton")}
              onConfirm={() => {
                addressDuplicateDataToSave.isSaveDuplicate = true;
                if (edit) {
                  actions.editAddress(addressDuplicateDataToSave).then((res) => {
                    if(res.data.isSuccessful) {
                      actions.getAllAddress();
                      setIsLoading(false);
                      toast.success(t("commonlabels.editedSuccessfullyMsg"));
                      history.push(`/address`);
                    }          
                  });
                }
                else {
                  actions.saveAddress(addressDuplicateDataToSave).then((res) => {
                    if(res.data.isSuccessful) {
                      actions.getAllAddress();
                      setIsLoading(false);
                      toast.success(t("commonlabels.savedSuccessfullyMsg"));
                      handleRedirectTo(res.data);      
                      //history.push(`/address`);                
                    }
                  })
                }
                setConfirmDuplicateSave(false);
              }}
              onCancel={() => {
                setIsLoading(false);
                setConfirmDuplicateSave(false);
              }}
            />
    </>
  );
};

export default AddAddress;
