import React, { useEffect, useState } from "react";
import RecipientsListingComponent from "./RecipientsListingComponent";
import RecipientsSerarch from "../../Admin/Recipient/RecipientsSearchComponent";

import PageLayout from "../../common/PageLayout";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AddressUtil from "../../common/utils/AddressUtil";
import { stringSortFunction } from "../../../Services/util";

const RecipientsApproveComponent = (props) => {
  const { actions, allRecipients,tableApproveRecipientOptions } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [flag, setFlag] = useState(false);
  const location = useLocation();

 // const classes = addressContainerStyles();
  useEffect(() => {
    actions.getAllrecipients().then((ress) => {
      const recipients = ress.data;
      const existingAddressIds = recipients
        .filter((item) => item.submitted && !item.approved)
        .map((recipient) => recipient.address_id);
      actions.getAllAddress().then((res) => {
        const data = res.data
          .filter((item) => existingAddressIds.includes(item.id))
          .map((item) => ({
            id: item.id,
            name: new AddressUtil(item).toString(),
          }))
          .sort(stringSortFunction("name"));
          const key = 'name';

          const arrayUniqueByKey = [...new Map(data.map(item =>
            [item[key], item])).values()];
          setAddressList(arrayUniqueByKey);
        setIsLoading(false);
      });
    });
  }, []);

useEffect(() => {

    if (location.pathname == "/approve/recipients") {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [location.pathname]);
  return (
    <>
      <PageLayout className="title_main mt-1"
        title={t('approve_recipient.approve_recipient')}
        searchComponent={(<RecipientsSerarch
          onSearch={(data) => setSearchFilter(data)}
          addressList={addressList}
          searchKey={"recipientApprove"}
        />)}

        mainComponent={(
          !isLoading &&
        <RecipientsListingComponent
          listData={allRecipients.filter(item => item.submitted && !item.approved)}
          actions={actions}
          searchFilter={searchFilter}
          setIsLoading={setIsLoading}
          hideDeleteButton={true}
          hideCheckBox={false}
          myrecipient={flag}
          tableApproveRecipientOptions={tableApproveRecipientOptions}
        />)}
        isLoading={isLoading}
      />
    </>
  );
};

export default RecipientsApproveComponent;
