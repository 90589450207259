import React from "react";
import { useState, useEffect} from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import CommentsReplyComponent from "./CommentsReplyComponent";
import AlertCommentsTable from "./AlertCommentsTable";
import Checkbox from "@mui/material/Checkbox";
import ArchivedCommentsTable from './ArchivedCommentsTable'
import { useCommentsModalStyles } from "./CommentsStyles";

export default function AlertTagNavbarComponent(props) {
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const { actions, allUsers } = props;
  const { t } = useTranslation();
  const classes = useCommentsModalStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState("");
  const [allusersList, setAllUsersList] = useState([]);
  const [value, setValue] = useState([]);
  const [archived,setArchived] = useState(false)
  const [showModalForReply, setShowModalForReply] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isModalSaveRunning, setIsModalSaveRunning] = useState(false);

  const [alertsList, setAlertsList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [inprogressAlertsChecked, setinprogressAlertsChecked] = useState(false);

  const [refreshKey, setRefeshKey] = useState(0);

  useEffect(() => {
    actions.getAllusers();
    fetchData();
  }, []);

  useEffect(() => {
    let selectedUsers = [{ 'email': userId }];//Add here to remove current user from the list    
    const filteredResult = allUsers.filter(
      (x) => !selectedUsers.filter((y) => y.email === x.email).length
    );
    setAllUsersList(filteredResult);
  }, [allUsers, userId]);

  const fetchData = (e) => {
    let checkedValue = checked;
    let isOpened = inprogressAlertsChecked ? '1' : '0';
    if (e !== undefined) {
      checkedValue = e.target.checked;
    }
    if (checkedValue) {
      fetchUnreadAlertsList();
    }
    else {
      actions
        .getAlertByuserId({ userId, isOpened })
        .then((response) => {
          const alert = response.data;
          setAlertsList(alert);
          setIsLoading(false);
        });
    }
  }

  const fetchUnreadAlertsList = () => {
    actions
      .getUnreadAlertsList({ userId })
      .then((response) => {
        const unreadAlert = response.data;
        setAlertsList(unreadAlert);
        setIsLoading(false);
      });
  }

  //Fetch Unread Notification List when checkbox is selected if unchecked then get all.
  const checkboxChecked = (e) => {
    setChecked(e.target.checked);
    setRefeshKey(refreshKey => refreshKey + 1); //we are using this refeshkey to reRender the Alert table component.
    setIsLoading(true);
    if (e.target.checked) {
      setinprogressAlertsChecked(false);
      fetchUnreadAlertsList();
    }
    else {
      fetchData(e);
    }

  }

  //Fetch In progress Notification List when checkbox is selected if unchecked then get all.
  const inprogressCheckboxChecked = (e) => {
    setinprogressAlertsChecked(e.target.checked);
    setRefeshKey(refreshKey => refreshKey + 1); //we are using this refeshkey to reRender the Alert table component.
    setIsLoading(true);
    getInprogressAlerts(e);
  }

  const getInprogressAlerts = (e) => {
    let isOpened = e.target.checked ? '1' : '0';
    if(e.target.checked){
      setChecked(false);
    }
    actions
      .getAlertByuserId({ userId, isOpened })
      .then((response) => {
        const alert = response.data;
        setAlertsList(alert);
        setIsLoading(false);
      });
  }

  const openReplyModal = (e, rowData) => {
    setSelectedRowData(rowData);
    setShowModalForReply(true);
  };
  const handleArchivedTags = (e) =>{
    setArchived(!archived)
  }
  const closeReplyModal = (e, row) => {
    setValue([]);
    setComments("");
    setShowModalForReply(false);
    //removing notification icon in both parent and child.  
    removeAlert(row);
  };
  const handleChangeComments = (e) => {
    setComments(e.target.value);
  };
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const miniSpinner = () => {
    return (
      <div>
        <ReactLoading
          type="spinningBubbles"
          color="#0072ce"
          display="inline"
          width="34px"
          height="34px"
        />
      </div>
    );
  };

  const saveMethod = (e, row) => {
    try {
      let valid = true;

      if (comments === "") {
        toast.error(t("comments_alert.validComment"));
        return (valid = false);
      }

      const req = {
        shippingid: selectedRowData ? selectedRowData.shipping_id : 0,
        comments: comments,
        parentcommentid: selectedRowData ? selectedRowData.id : null,
        createdon: null,
        createdby: userId,
        taggedusers: value,
        archive:false
      };
      if (comments.length !== 0) {
        setIsModalSaveRunning(true);
        actions.saveAlertDetails(req).then((res) => {
          toast.success(t("commonlabels.savedSuccessfullyMsg"));
          setIsModalSaveRunning((prevBool) => !prevBool);
          closeReplyModal(e, row);
          fetchData();
          if (checked) {
            setRefeshKey(refreshKey => refreshKey + 1); //we are using this refeshkey to reRender the Alert table component.
          }
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };

  const removeAlert = (row) => {
    //removing notification icon in both parent and child.  
    alertsList.map(function (x) {
      if (x.id === row.id) {
        x.is_viewed = true;
      }
      if (x.parent_comment_id === row.id) {
        x.is_viewed = true;
      }
      return x
    })
  }

  return (
    <LoadingOverlay active={isLoading} spinner>
      <div className="container-fluid  ">
        <div className="row mlr15 border ">
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <div className="pt-1">
                <label className={classes.alerttitle} >
                  {(archived)?t("comments_alert.archived/tag"):t("comments_alert.active/tag")}
                </label>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end">

              <div className="d-flex">
              <Checkbox
                  margin="normal"
                  variant="outlined"
                  size="small"
                  checked={archived}
                  onChange={handleArchivedTags}
                />
                <label className={classes.mt9}>{t("comments_alert.archived_tags")}</label>
                <Checkbox
                  margin="normal"
                  variant="outlined"
                  size="small"
                  checked={checked}
                  onChange={checkboxChecked}
                />
                <label className={classes.mt9}>{t("comments_alert.unreadAlerts")}</label>
                <Checkbox
                  margin="normal"
                  variant="outlined"
                  size="small"
                  checked={inprogressAlertsChecked}
                  onChange={inprogressCheckboxChecked}
                />
                <label className={classes.mt9}>{t("comments_alert.inprogress_notifications")}</label>
              </div>
            </div>
          </div>
          <div className="col-12"> </div>
          <div className="col-12">
            {/*Alert Notification Table*/}
            {(archived)?<ArchivedCommentsTable
              key={refreshKey}
              fromNavbar={true}
              actions={actions}
              AlertCommentsList={alertsList}
              openReplyModal={openReplyModal}
              setIsLoading={setIsLoading}
              fetchData={fetchData}></ArchivedCommentsTable>
              :
              <AlertCommentsTable
              key={refreshKey}
              fromNavbar={true}
              actions={actions}
              AlertCommentsList={alertsList}
              openReplyModal={openReplyModal}
              setIsLoading={setIsLoading}
              fetchData={fetchData}
            ></AlertCommentsTable>}
            {/*Alert Notification Table*/}
          </div>
        </div>

        {/*Reply Modal Compnent */}
        <CommentsReplyComponent
          showModalForReply={showModalForReply}
          isModalSaveRunning={isModalSaveRunning}
          saveMethod={saveMethod}
          closeReplyModal={closeReplyModal}
          handleChangeComments={handleChangeComments}
          miniSpinner={miniSpinner}
          selectedRowData={selectedRowData}
          AlertCommentsList={alertsList}
          handleChange={handleChange}
          allUsersList={allusersList}
        ></CommentsReplyComponent>
        {/*Reply Modal Compnent */}
      </div>
    </LoadingOverlay>
  );
}