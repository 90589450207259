import React from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { Alert } from "@mui/material";

import DestinationAddressDetails from "../DestinationAddressDetails";
import { useStyles } from "../../common/ThemeStye";

import { useTranslation } from "react-i18next";

const DestinationAddrCard = (props) => {
  const { destinationAddrSelect, extra, shippingType, isAddressApproved, redirectTo } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const shipType=shippingType.find((obj)=>obj?.id===extra?.type_of_shipment)
  
  const isaddressvalid = (obj) =>{
    if(obj == null || typeof obj == 'undefined' ||  Object.keys(obj).length == 0) return true
    if(obj?.countryvalid == true){
      if(obj?.addressvalid == 1){
        if(shipType?.isactive==true){
          return true
        }else{
          return false
        }
        // return true
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }
  return (
    <>
      <div className={redirectTo === "/process/shipping" && isAddressApproved === 0 ? "solid-red-border" : null} style={{border:(isaddressvalid(destinationAddrSelect))? "":"3px solid orange"}}>
        <Card variant="outlined">
        <CardHeader
          title={
            <Typography className="heading_box">
              {" "}
              {t("shipping_request.destination_address")}<span className="req">*</span>{" "}
            </Typography>
          }
          className={classes.cardHeader}
        />
        {redirectTo === "/process/shipping" && isAddressApproved === 0 ? (
          <Card variant="outlined">
                <CardContent className="p-2 notice transparantbg">
            <Alert severity="warning">
                  {t("address.address_not_approved")}
            </Alert>
          </CardContent>
          </Card>
          ) : ("")
        }
        <br />
        <CardContent>
          <br />
          <DestinationAddressDetails
            destinationAddrSelect={destinationAddrSelect}
            extra={extra}
            shippingType={shippingType}
          />
        </CardContent>
      </Card>
      </div>
      <br />
    </>
  );
};

export default DestinationAddrCard;
