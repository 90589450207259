import { TextField, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import CommonModal from "../common/Modal";
import { If, Then, Else } from "react-if";
import { INSPECT, STATUS, SUBMIT } from "../../constants";
import InfoIcon from "@mui/icons-material/Info";

export default function StudyNumberComponent({ extra, actions, status, shippingActionType }) {
  const { t } = useTranslation();
  const [studyDetailsLoading, setStudyDetailsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const studyDetailsEditEnabled = ["", STATUS.INPUT,STATUS.REGULATORY_REVIEWER_1,STATUS.REGULATORY_REVIEWER_2].includes(status);

  const handleStudyNumberSearch = async () => {
    setStudyDetailsLoading(true);
    const returndata = await actions.searchByStudyNumber(extra?.study_number);
    if (get(returndata, "data.study_number", false)) {
      setStudyDetailsLoading(false);
      setShowPopup(true);
    } else {
      setStudyDetailsLoading(false);
      return toast.error(t("commonlabels.emptyDataSourceMessage"));
    }
  };

  const showStudyNumberDetails = async (e) => {
    e.preventDefault();
    if (extra?.studyNumberDetails?.study_number) {
      setShowPopup(true);
    }
  };

  const clearStudyNumber = () => {
    actions.setExtraData({
      ...extra,
      study_number: "",
      studyNumberDetails: null,
    });
    setShowPopup(false);
  };
  const EditModeFooter = () => {
    return (
      <div>
        <button
          className="btn btn-primary"
          onClick={() => {
            actions.setExtraData({
              ...extra,
              studyNumberDetails: {
                ...extra?.studyNumberDetails,
                id: "temp_id",
              },
            });
            setShowPopup(false);
          }}
        >
          {t("commonlabels.saveButton")}
        </button>{" "}
        <button
          className="btn btn-secondary"
          onClick={() => setShowPopup(false)}
        >
          {t("commonlabels.cancelbutton")}
        </button>
      </div>
    );
  };

  return (
    <div data-name="study_number_component">
       {(shippingActionType === INSPECT || shippingActionType === SUBMIT)? extra?.study_number === "null"? "": <div>  {extra?.study_number}
       <a href="" onClick={showStudyNumberDetails}>
           
           
          </a>
       
       </div>:
      <div className="d-flex">
        {" "}
        <TextField
          id="study_number"
          name="study_number"
          margin="normal"
          variant="outlined"
          size="small"
          value={extra?.study_number}
          disabled={!!extra?.studyNumberDetails?.study_number || status === STATUS.IN_DELIVERY}
          onChange={(e) =>
            actions.setExtraData({
              ...extra,
              [e.target.name]: e.target.value,
            })
          }
        />
        <If condition={extra?.studyNumberDetails?.study_number}>
          <Then>
            <button
              className="btn btn-primary"
              style={{ top: "-5px", position: "relative", height: "35px" }}
              onClick={clearStudyNumber}
            >
              <ClearIcon />
            </button>
          </Then>
          <Else>
            <button
              className="btn btn-primary"
              style={{ top: "-5px", position: "relative", height: "35px" }}
              onClick={handleStudyNumberSearch}
              disabled={
                studyDetailsLoading ||
                !extra?.study_number ||
                extra?.studyNumberDetails?.study_number ||
                status === STATUS.IN_DELIVERY
              }
            >
              <SearchIcon />
            </button>
          </Else>
        </If>
      </div>}
      {extra?.studyNumberDetails?.study_number && extra?.studyNumberDetails?.study_number !== "null" && (
        <>
          <a href="" onClick={showStudyNumberDetails}>
            {status === STATUS.IN_DELIVERY ? "": t("shippingrequest.study_details_label")}
          </a>
          <If condition={studyDetailsEditEnabled}>
            <Then>
              <Tooltip title={t("shippingrequest.study_details_hover_help")}>
                <InfoIcon />
              </Tooltip>
            </Then>
          </If>
        </>
      )}
      <CommonModal
        show={showPopup}
        header={t("shippingrequest.study_details")}
        body={
          <StudyNumberEditComponent
            actions={actions}
            extra={extra}
            studyDetailsEditEnabled={studyDetailsEditEnabled}
          />
        }
        footer={
          !studyDetailsEditEnabled ? (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setShowPopup(false);
              }}
            >
              {t("commonlabels.close")}
            </button>
          ) : (
            <EditModeFooter />
          )
        }
        onCancel={() => {
          setShowPopup(false);
        }}
      />
    </div>
  );
}

const StudyNumberEditComponent = ({
  extra,
  actions,
  studyDetailsEditEnabled,
}) => {
  const { t, i18n } = useTranslation();

  const [fieldConfig, setFieldConfig] = useState({
    study_number: {
      disabled: true,
    },
    study_title: {},
    person_responsible: {},
    intended_for_pivotal_evidence: {},
    study_species:{},
    regulatory_standard: {},
    project_name: {},
    activity_type: {},
  });

  return (
    <>
      <div
        className="row"
        style={{
          background: "white",
        }}
      >
        {Object.keys(fieldConfig).map((field) => {
          return (
            <div className="col-lg-3  col-12 form-outline form-margin">
              <label className="form-label">
                {t(`shipping_request.${field}`)}
              </label>
              <TextField
                id={field}
                name={field}
                disabled={
                  !!fieldConfig[field].disabled || !studyDetailsEditEnabled
                }
                margin="normal"
                variant="outlined"
                size="small"
                title={extra?.studyNumberDetails?.[field]}
                value={extra?.studyNumberDetails?.[field]}
                onChange={(e) =>
                  actions.setExtraData({
                    ...extra,
                    studyNumberDetails: {
                      ...extra?.studyNumberDetails,
                      [e.target.name]: e.target.value,
                    },
                  })
                }
              />
            </div>
          );
        })}

        <div className="col-lg-12  col-12 form-outline form-margin">
          <label className="form-label">
            {t(`process_shipping_request.comment`)}
          </label>
          <textarea
            // rows={10}
            // colums={100}
            id={"study_comment"}
            name={"study_comment"}
            margin="normal"
            variant="outlined"
            size="small"
            disabled={!studyDetailsEditEnabled}
            value={extra?.studyNumberDetails?.["study_comment"]}
            onChange={(e) =>
              actions.setExtraData({
                ...extra,
                studyNumberDetails: {
                  ...extra?.studyNumberDetails,
                  [e.target.name]: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
    </>
  );
};
