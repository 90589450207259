import React, { useReducer } from "react";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Button from "@mui/material/Button";
import { useState } from "react";
import Select from "../common/CustomSelect";
import { addressSearchStyles } from "../Admin/Address/addressStyles";
import AutocompleteText from "../common/AutocompleteText";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";


const RegisteredUsersSearch = ({ onSearch, countries,Actions }) => {
  const classes = addressSearchStyles();
  const [masterUserRoles, setMasterUserRoles] = useState([])
  const [roleNames ,setRolesName] = useState([])
 
  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return {
        first_name: "",
        last_name: "",
        role_ids: "",
        email: "",
        mobile_no: "",
        phone_no: "",
        department: "",
        role_name:"",
        full_name:""
      };
    }

    if (event.target.type === "checkbox") {
      return { ...state, [event.target.name]: event.target.checked };
    }
    return { ...state, [event.target.name]: event.target.value };
  };

  const [state, dispatch] = useReducer(reducer, {
    first_name: "",
    last_name: "",
    role_ids: "",
    email: "",
    mobile_no: "",
    phone_no: "",
    department: "",
    role_name:"",
    full_name:""
  });
  
  useEffect(()=>{
    for(let role of masterUserRoles){
      if(role.name === state["role_name"]){
        dispatch({
          target: { name: "role_ids", value: role.id },
        })
      }
    }
  },[state["role_name"]])
  useEffect(() => {
    Actions.getMasterDataRoles().then((response) => {
      let data =response.data.data
      let filterroles = []
      let roleNames = []
      for(let role of data){
        let obj = {}
        obj["id"] = role.role_id
        obj["name"] =role.role_name
        roleNames.push(role.role_name)
        filterroles.push(obj)
      }
      roleNames.sort()
      setRolesName(roleNames)
      setMasterUserRoles(filterroles)
    });
  }, []);



  const [t] = useTranslation();

  return (
    <>

       
        <Divider />
      
          <div className="row">
            <div className="col-11 pr-0">
              <div className="row col-12">
                <div className="col-lg-4  pr-0 mt-2">
                  <TextField
                    id="first_name"
                    name="first_name"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("RegisteredUser.firstname")}
                    onChange={dispatch}
                    value={state["first_name"]}
                  />
                </div>
                <div className="col-lg-4 col-sm-12 pr-0 mt-2 date_addreserch">
                  <TextField
                    id="last_name"
                    name="last_name"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("RegisteredUser.lastname")}
                    onChange={dispatch}
                    value={state["last_name"]}
                  />
                </div>
                <div className="col-lg-4 col-sm-12 pr-0 mt-2 date_addreserch">
                  <TextField
                    id="full_name"
                    name="full_name"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("RegisteredUser.name")}
                    onChange={dispatch}
                    value={state["full_name"]}
                  />
                </div>
              </div>
              <div className="row col-12">
                <div className="col-lg-3 col-sm-12 pr-0 mt-2">
                  <TextField
                    id="email"
                    name="email"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("recipient.Email")}
                    onChange={dispatch}
                    value={state["email"]}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                <AutocompleteText
                  id="role_name"
                  name={"role_name"}
                  value={state["role_name"]}
                  options={roleNames}
                  onChange={dispatch}
                  label={t("shipping_request.user_role")}
                />
                </div>
                <div className="col-lg-3 col-sm-12 pr-0 mt-1">
                  <TextField
                    id="mobile_no"
                    name="mobile_no"
                    onChange={dispatch}
                    value={state["mobile_no"]}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("RegisteredUser.mobile")}
                  />
                </div>
                <div className="col-lg-3 pr-0 mt-1">
                  <TextField
                    id="phone_no"
                    name="phone_no"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("RegisteredUser.phone")}
                    onChange={dispatch}
                    value={state["phone_no"]}
                  />
                </div>
                <div className="col-lg-3 pr-0 mt-1">
                  <TextField
                    id="department"
                    name="department"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    placeholder={t("RegisteredUser.department")}
                    onChange={dispatch}
                    value={state["department"]}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
              <Button
                variant="contained"
                className={classes.searchBt + " search_buttontwo"}
                onClick={() => onSearch({ ...state })}
              >
                <SearchIcon />
              </Button>
              <Button
                variant="contained"
                className={classes.reloadBt + " refresh_button"}
                onClick={() => dispatch({ type: "RESET" })}
              >
                <ReplayIcon />
              </Button>
            </div>
          </div>
      
    
      <Divider />
    </>
  );
};

export default RegisteredUsersSearch;
