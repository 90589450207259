import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import AddCountry from "./AddCountry";

const {
    getAllCountries,
    updateCountryStatus,
    saveCountry
 } = title.actions

 const mapStateToProps = (state)=>(
    {
        allCountries: state[title.name].allCountries
    }
 )

 const mapDispatchToProps = (dispatch) => (
    {
        actions: bindActionCreators(
            {
                getAllCountries,
                updateCountryStatus,
                saveCountry
            },dispatch
        )
    }
 )

 export default connect(mapStateToProps,mapDispatchToProps)(AddCountry)
