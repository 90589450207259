import moment from "moment";

export function shippingRequestEdit(shippingRequestDetails, props) {
  const {
    internal_distribution_number,
    shipment_constraint_type,
    created_on,
    distributiontemperature_id,
    c_comment,
    sap_delivery_number,
    shipment_date_mon,
    shipment_date_lev,
    shipment_constraint_date,
    tracking_number,
    status_comment,
    close_date,
  } = shippingRequestDetails[0];
  
  const {
    actions,
    distributionTempValue,
    shippingCreateCommentValue,
    internalOrderIdValue,
    sapDeliveryNumValue,
    dispatchMONValue,
    dispatchLEVValue,
    dateOfReceiptValue,
    trackingNumValue,
    statusQuoValue,
  } = props;

  const dateFormat = moment(created_on); //.format('YYYY-DD-MM')
  let constraint_type = "materialavailableDate";
  if (shipment_constraint_type === "READY_DATE") {
    constraint_type = "latestArrivalDate";
  }
  actions.getInternalOrderIdValue(
    internalOrderIdValue || internal_distribution_number,
  );
  actions.getShipmentSpecificValue(constraint_type);
  actions.getShippingCreateDateValue(shipment_constraint_date || dateFormat);
  actions.getDistributionTempValue(
    distributionTempValue || distributiontemperature_id,
  );
  actions.getShippingCreateCommentValue(
    shippingCreateCommentValue || c_comment,
  );
  actions.getSapDeliveryNumValue(sapDeliveryNumValue || sap_delivery_number);
  actions.getDispatchMONValue(dispatchMONValue || shipment_date_mon);
  actions.getDispatchLEVValue(dispatchLEVValue || shipment_date_lev);
  actions.getDateOfReceiptValue(dateOfReceiptValue || close_date);
  actions.getTrackingNumValue(trackingNumValue || tracking_number);
  actions.getStatusQuoValue(statusQuoValue || status_comment);
}
