import types from "./types";

const initialState = {
  errorTab: 0, //0-no error, 1-shipping tab, 2-materials tab, 3- 1&2 both
};
const materialReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ERROR_TAB: {
      return {
        ...state,
        errorTab: action.payload,
      };
    }
    default:
      return state;
  }
};

export default materialReducer;
