import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import infoIcon from "../../../assests/images/infoIcon.svg";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import Divider from "@mui/material/Divider";
import AutocompleteText from "../../common/AutocompleteText";
import { connect } from "react-redux";
import MaterialTable from "@material-table/core";
import materialService from "../../../Services/materialService";
import * as config from "../../../config";
import { USERROLES } from "../../../constants";

export function ViewMaterial({ materialDetails, location }) {
  const [state, setState] = useState({});
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { materialId } = useParams();
  const recievedData = location.state;
  const [existingdocuments, setExistingDocuments] = useState([]);
  const userType = JSON.parse(localStorage.getItem("userType"));

  useEffect(() => {
    if (materialId) {
      if (get(recievedData, "data", false)) {
        setState(recievedData.data);
      }
      else {
        const selectedMaterial = materialDetails.find(
          (item) => item.id === materialId,
        );
        setState(selectedMaterial);
      }
    }
  }, [materialDetails]);
  useEffect(async () => {
    if (materialId) {
      let data = await materialService.getMaterialAttachments(materialId);
      setExistingDocuments(data);
    }
  }, []);

  const cancelAction = async () => {
    history.goBack();
  };

  return (
    <>
      <div className="title_main ml-3 pt10">{t("Country.view_material")}</div>
      <Card>
        <CardContent>
          {
            userType.includes(USERROLES.PROCESSMANAGER) ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) ||
              userType.includes(USERROLES.ADMIN) ||
              userType.includes(USERROLES.REGULATORYREVIEWER1) ||
              userType.includes(USERROLES.REGULATORYREVIEWER2) ||
              userType.includes(USERROLES.READONLY)
              ? (
              <>
                <div className="container-fluid ">
                  <div className="row col-12 mt-2">
                    <ItemContainer>
                      <div>
                        <AutocompleteText
                          label={t("material.materialName")}
                          name="description"
                          options={[]}
                          value={state.description || " "}
                          readOnly={true}
                        />
                      </div>
                    </ItemContainer>
                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.hazard_class")}
                        name="hazard_class"
                        options={[]}
                        value={state.hazard_class || " "}
                        readOnly={true}
                      />
                    </ItemContainer>

                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.hazard_inducers")}
                        name="hazard_phrase"
                        options={[]}
                        value={state.hazard_phrase || " "}
                        readOnly={true}
                      />
                    </ItemContainer>

                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.material_number")}
                        name="material_number"
                        options={[]}
                        value={state.material_number || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                  </div>
                  <div className="row col-12">
                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.tariff_classification_no_germany")}
                        name="statistical_material_number_grmny"
                        options={[]}
                        value={state.statistical_material_number_grmny || " "}
                        readOnly={true}
                      />
                    </ItemContainer>

                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.tariff_classification_no_china")}
                        name="statistical_material_number_china"
                        options={[]}
                        value={state.statistical_material_number_china || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.tariff_classification_no_usa")}
                        name="statistical_material_number_usa"
                        options={[]}
                        value={state.statistical_material_number_usa || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.eid")}
                        name="eid"
                        options={[]}
                        value={state.eid || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                  </div>
                </div>
                <div className="container-fluid ">
                  <div className="row col-12">
                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label className="">
                            {" "}
                            <b>{t("shipping_log_fields.is_hazardous")}</b>{" "}
                          </label>{" "}
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="is_hazardous"
                            value={true}
                            disabled
                            checked={state.is_hazardous == true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="is_hazardous"
                            value={false}
                            disabled
                            checked={state.is_hazardous == false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.unNo")}
                        name="un_no"
                        options={[]}
                        value={state.un_no || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                    <ItemContainer>
                      <AutocompleteText
                        label={t("shipping_log_fields.Class_Subsidiary_Hazard")}
                        name="transport_class"
                        options={[]}
                        value={state.transport_class || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                    <ItemContainer>
                      <AutocompleteText
                        label={t("shipping_log_fields.pack_group")}
                        name="pack_group"
                        options={[]}
                        value={state.pack_group || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                  </div>
                </div>
                <div className="container-fluid ">
                  <div className="row col-12">
                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label className="">
                            {" "}
                            <b>{t("shipping_log_fields.hazardous_sds")}</b>{" "}
                          </label>{" "}
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="hazardous_sds"
                            value={true}
                            disabled
                            checked={state.hazardous_sds == true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="hazardous_sds"
                            value={false}
                            disabled
                            checked={state.hazardous_sds == false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label className="">
                            {" "}
                            <b>{t("material.contains_der_biologic")}</b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t("material.contains_der_biologic_tooltip")}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>{" "}
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_der_biologic"
                            value={true}
                            disabled
                            checked={state.contains_der_biologic == true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_der_biologic"
                            value={false}
                            disabled
                            checked={state.contains_der_biologic == false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_animal_byproduct")}</b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t("material.contains_animal_byproduct_tooltip")}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_animal_byproduct"
                            value={true}
                            disabled
                            checked={state.contains_animal_byproduct == true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_animal_byproduct"
                            value={false}
                            disabled
                            checked={state.contains_animal_byproduct == false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>
                              {t("material.contains_non_domestic_animal_byproduct")}
                            </b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t(
                                "material.contains_non_domestic_animal_byproduct_tooltip",
                              )}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_non_domestic_animal_byproduct"
                            value={true}
                            disabled
                            checked={
                              state.contains_non_domestic_animal_byproduct === true
                            }
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_non_domestic_animal_byproduct"
                            value={false}
                            disabled
                            checked={
                              state.contains_non_domestic_animal_byproduct === false
                            }
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>


                  </div>
                </div>
                <div className="container-fluid ">
                  <div className="row col-12">
                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_infect_substance")}</b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t(
                                "material.contains_infect_substance_tooltip",
                              )}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_infect_substance"
                            value={true}
                            disabled
                            checked={state.contains_infect_substance === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_infect_substance"
                            value={false}
                            disabled
                            checked={state.contains_infect_substance === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_antibiotic")}</b>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_antibiotic"
                            value={true}
                            disabled
                            checked={state.contains_antibiotic === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_antibiotic"
                            value={false}
                            disabled
                            checked={state.contains_antibiotic === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_pesticide")}</b>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_pesticide"
                            value={true}
                            disabled
                            checked={state.contains_pesticide === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_pesticide"
                            value={false}
                            disabled
                            checked={state.contains_pesticide === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_parasiticide")}</b>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_parasiticide"
                            value={true}
                            disabled
                            checked={state.contains_parasiticide === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_parasiticide"
                            value={false}
                            disabled
                            checked={state.contains_parasiticide === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                  </div>
                </div>
                <div className="container-fluid ">
                  <div className="row col-12">
                    <ItemContainer>
                      <div className="">
                        {" "}
                        <label className="">
                          {" "}
                          <b>{t("material.full_description")}</b>{" "}
                          <img
                            alt=""
                            style={{ width: "15px" }}
                            src={infoIcon}
                            className=""
                            data-for="1"
                            data-tip={t("material.full_description_tooltip")}
                            data-html={true}
                            data-place="bottom"
                          />
                          <ReactTooltip
                            id="1"
                            className=""
                            data-html={true}
                          ></ReactTooltip>
                        </label>{" "}
                      </div>
                      <TextareaAutosize
                        id="full_description"
                        name="full_description"
                        aria-label="full_description"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        minRows={2}
                        style={{ width: "100%" }}
                        value={
                          state.full_description === null ? "" : state.full_description
                        }
                        disabled
                      />
                    </ItemContainer>

                    <ItemContainer>
                      <div className="">
                        {" "}
                        <label className="">
                          {" "}
                          <b>{t("material.fda_product_code")}</b>{" "}
                        </label>{" "}
                      </div>
                      <TextareaAutosize
                        id="fda_product_code"
                        name="fda_product_code"
                        aria-label="fda_product_code"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        minRows={2}
                        style={{ width: "100%" }}
                        value={
                          state.fda_product_code === null ? "" : state.fda_product_code
                        }
                        disabled
                      />
                    </ItemContainer>

                    <ItemContainer>
                      <div className="">
                        {" "}
                        <label className="">
                          {" "}
                          <b>{t("material.fda_processing_program")}</b>{" "}
                        </label>{" "}
                      </div>
                      <TextareaAutosize
                        id="fda_processing_program"
                        name="fda_processing_program"
                        aria-label="fda_processing_program"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        minRows={2}
                        style={{ width: "100%" }}
                        value={
                          state.fda_processing_program === null
                            ? ""
                            : state.fda_processing_program
                        }
                        disabled
                      />
                    </ItemContainer>

                    <ItemContainer>
                      <div className="">
                        {" "}
                        <label className="">
                          {" "}
                          <b>SPI</b>{" "}
                        </label>{" "}
                      </div>
                      <TextareaAutosize
                        id="sli"
                        name="sli"
                        aria-label="sli"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        minRows={2}
                        style={{ width: "100%" }}
                        value={state.sli === null ? "" : state.sli}
                        disabled
                      />
                    </ItemContainer>
                  </div>
                </div>
              </>) :
              <>
                <div className="container-fluid ">
                  <div className="row col-12 mt-2">
                    <ItemContainer>
                      <div>
                        <AutocompleteText
                          label={t("material.materialName")}
                          name="description"
                          options={[]}
                          value={state.description || " "}
                          readOnly={true}
                        />
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <AutocompleteText
                        label={t("materials.eid")}
                        name="eid"
                        options={[]}
                        value={state.eid || " "}
                        readOnly={true}
                      />
                    </ItemContainer>
                  </div>
                </div>


                <div className="container-fluid ">
                  <div className="row col-12">

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label className="">
                            {" "}
                            <b>{t("material.contains_der_biologic")}</b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t("material.contains_der_biologic_tooltip")}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>{" "}
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_der_biologic"
                            value={true}
                            disabled
                            checked={state.contains_der_biologic == true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_der_biologic"
                            value={false}
                            disabled
                            checked={state.contains_der_biologic == false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_animal_byproduct")}</b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t("material.contains_animal_byproduct_tooltip")}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_animal_byproduct"
                            value={true}
                            disabled
                            checked={state.contains_animal_byproduct == true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_animal_byproduct"
                            value={false}
                            disabled
                            checked={state.contains_animal_byproduct == false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>
                              {t("material.contains_non_domestic_animal_byproduct")}
                            </b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t(
                                "material.contains_non_domestic_animal_byproduct_tooltip",
                              )}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_non_domestic_animal_byproduct"
                            value={true}
                            disabled
                            checked={
                              state.contains_non_domestic_animal_byproduct === true
                            }
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_non_domestic_animal_byproduct"
                            value={false}
                            disabled
                            checked={
                              state.contains_non_domestic_animal_byproduct === false
                            }
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_infect_substance")}</b>{" "}
                            <img
                              alt=""
                              style={{ width: "15px" }}
                              src={infoIcon}
                              className=""
                              data-for="1"
                              data-tip={t(
                                "material.contains_infect_substance_tooltip",
                              )}
                              data-html={true}
                              data-place="bottom"
                            />
                            <ReactTooltip
                              id="1"
                              className=""
                              data-html={true}
                            ></ReactTooltip>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_infect_substance"
                            value={true}
                            disabled
                            checked={state.contains_infect_substance === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_infect_substance"
                            value={false}
                            disabled
                            checked={state.contains_infect_substance === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                  </div>
                </div>
                <div className="container-fluid ">
                  <div className="row col-12">

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_antibiotic")}</b>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_antibiotic"
                            value={true}
                            disabled
                            checked={state.contains_antibiotic === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_antibiotic"
                            value={false}
                            disabled
                            checked={state.contains_antibiotic === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_pesticide")}</b>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_pesticide"
                            value={true}
                            disabled
                            checked={state.contains_pesticide === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_pesticide"
                            value={false}
                            disabled
                            checked={state.contains_pesticide === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>

                    <ItemContainer>
                      <div class="">
                        <div class="">
                          {" "}
                          <label>
                            <b>{t("material.contains_parasiticide")}</b>
                          </label>
                        </div>
                        <div class="radio-inline">
                          <input
                            type="radio"
                            id="radio"
                            name="contains_parasiticide"
                            value={true}
                            disabled
                            checked={state.contains_parasiticide === true}
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="radio"
                            name="contains_parasiticide"
                            value={false}
                            disabled
                            checked={state.contains_parasiticide === false}
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                    </ItemContainer>
                  </div>
                </div>
                <div className="container-fluid ">
                  <div className="row col-12">
                    <ItemContainer>
                      <div className="">
                        {" "}
                        <label className="">
                          {" "}
                          <b>{t("material.full_description")}</b>{" "}
                          <img
                            alt=""
                            style={{ width: "15px" }}
                            src={infoIcon}
                            className=""
                            data-for="1"
                            data-tip={t("material.full_description_tooltip")}
                            data-html={true}
                            data-place="bottom"
                          />
                          <ReactTooltip
                            id="1"
                            className=""
                            data-html={true}
                          ></ReactTooltip>
                        </label>{" "}
                      </div>
                      <TextareaAutosize
                        id="full_description"
                        name="full_description"
                        aria-label="full_description"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        minRows={2}
                        style={{ width: "100%" }}
                        value={
                          state.full_description === null ? "" : state.full_description
                        }
                        disabled
                      />
                    </ItemContainer>
                  </div>
                </div>
              </>
          }
          <div className="container-fluid ">
            <div className="row ml15 mr15 mlr15">
              <div className="col-12   heading_box">
                {t("material.View_Documents")}
              </div>

              {existingdocuments.length === 0 ? (
                <table id="" className="editmaterialtable">
                  {" "}
                  <tr>
                    <th>{t("material.file_name")}</th>
                    <th>{t("shipping_request.Comments")}</th>
                  </tr>
                  <tbody>
                    <tr>{t("commonlabels.emptyDataSourceMessage")}</tr>
                  </tbody>
                </table>
              ) : (
                <div>
                  {/* {documents.length > 0 ? ( */}
                  <div>
                    <table id="">
                      <tr>
                        <th>{t("material.file_name")}</th>
                        <th>{t("shipping_request.Comments")}</th>
                      </tr>
                      {existingdocuments.map((val, key) => {
                        return (
                          <>
                            <tbody>
                              <tr key={key}>
                                <td>
                                  {
                                    <a
                                      href={
                                        config.baseURL +
                                        "/api/downloadFile/" +
                                        val.id +
                                        "/Material_MasterTable"
                                      }
                                    >
                                      {val.file_name}
                                    </a>
                                  }
                                </td>
                                <td>{val.comments}</td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })}
                    </table>
                  </div>

                  {/* ) : ("")} */}
                </div>
              )}
            </div>
          </div>
        </CardContent>

        <div className=" col-12  text-right mb50">
          <CardActions className="text-right pull-right">
            <button
              className="btn btn-secondary new_button"
              onClick={cancelAction}
            >
              {t("materials.back")}
            </button>
          </CardActions>
          <br></br> <br></br> <br></br>
        </div>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({
  materialDetails: state.materials.allMaterials,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMaterial);

const ItemContainer = ({ children }) => {
  return <div className="col-12 col-sm-3 form_item">{children}</div>;
};
