export default {
  GET_ALL_RECIPIENTS: "GET_ALL_RECIPIENTS",
  GET_ALL_TITLE: "GET_ALL_TITLE",
  SAVE_RECIPIENTS: "SAVE_RECIPIENTS",
  IS_LOADER: "IS_LOADER",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  SET_RECIPIENT_SEARCH_DATA: "SET_RECIPIENT_SEARCH_DATA",
  GET_SELECTED_RECIPIENT_DATA: "GET_SELECTED_RECIPIENT_DATA",
  SET_RECIPIENT_TABLE_OPTIONS:"SET_RECIPIENT_TABLE_OPTIONS",
  SET_RECIPIENT_APPROVE_TABLE_OPTIONS:"SET_RECIPIENT_APPROVE_TABLE_OPTIONS"
};
