import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { If, Then, Else } from "react-if";
import { USERROLES } from "../../../constants"
import { find } from "lodash";

import "./fileManager.css";
import { useTranslation } from "react-i18next";
import * as config from '../../../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const FileManager = (props) => {
  const { t } = useTranslation();

  const {
    files = [],
    onChange,
    multiple,
    readOnly,
    isDisabled = false,
    tableName,
  } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const userType = JSON.parse(localStorage.getItem("userType"));

  useEffect(() => {
    setSelectedFiles(files);
  }, [files]);

  const encodeFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileSelect = async (e) => {
    const newFiles = [];
    for (const file of e.target.files) {
      const fileData = {
        name: file.name,
        encodedData: await encodeFile(file),
        id: false,
        size: file.size,
      };
      newFiles.push(fileData);
    }

    if (multiple) {
      let valid=true;
      newFiles.forEach((obj)=>{
        if(find(selectedFiles, ['name', obj.name]))
        {
          valid=false;
        }
      })
      if(valid)
      {
        setSelectedFiles([...newFiles, ...selectedFiles]);
        onChange([...newFiles, ...selectedFiles]);
        e.target.value = '';
      }
      else{
        toast.error(t("exportLabel.filenameExists"));
        e.target.value = '';
      }
    } else {
      setSelectedFiles([...newFiles]);
      onChange([...newFiles]);
    }

  };

  const removeFile = async (index) => {
    const filteredFiles = selectedFiles.filter(
      (file, index2) => index2 !== index,
    );
    setSelectedFiles(filteredFiles);
    onChange(filteredFiles);
  };

  return (
    <div className="fileManger">
      <If condition={!readOnly}>
        <Then>
          <input
            type="file"
            onChange={handleFileSelect}
            multiple={multiple}
            disabled={isDisabled || !(userType.includes(USERROLES.PROCESSMANAGER) || userType.includes(USERROLES.APPLICANT) || userType.includes(USERROLES.ADMIN))}
          />
        </Then>
      </If>

      <div className="fileDisplaySection">
        {selectedFiles.map((file, index) => {
          return (
            <span>
              <If condition={!!readOnly}>
                <Then>
                  <FileItem file={file} tableName={tableName} />
                </Then>
                <Else>
                  <FileItem file={file} tableName={tableName} />
                  <CloseIcon onClick={() => removeFile(index)} />
                </Else>
              </If>
            </span>
          );
        })}

        <If condition={selectedFiles.length === 0}>
          <Then>{t("commonlabels.nofiles")}.</Then>
        </If>
      </div>
    </div>
  );
};

const FileItem = ({ file, tableName }) => {
  return (
    <If condition={!!file.file_url}>
      <Then>
        {tableName === "Shipping_material" ?


          <a href={
            config.baseURL + "/api/downloadFile/" + file.id + "/Shipping_material"
          }>
            {file.file_name}
          </a>
          :
          tableName === "shipping_request" ?
            <a href={
              config.baseURL + "/api/downloadFile/" + file.id + "/shipping_request"
            }>
              {file.file_name}
            </a>
            :
            <a href={
              config.baseURL + "/api/downloadFile/" + file.id + "/reviewer"
            }>
              {file.file_name}
            </a>


        }
      </Then>
      <Else>{file.name} </Else>
    </If>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);
