import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadingOverlay from "react-loading-overlay";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

const PageLayout = ({ title, searchComponent, mainComponent, isLoading }) => {
  const { t, i18n } = useTranslation();

  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }} m={0} pt={0}>
            <Stack direction="row" spacing={1}>
              <label className="title_main mt-1">{title}</label>
            </Stack>
          </Box>

          {searchComponent && (
            <Card variant="outlined">
              <CardHeader style={cardTheme.cardHeaderStylePref} title={t("phase.SearchPlaceholder")}></CardHeader>
              <Divider />
              <CardContent>{searchComponent}</CardContent>
            </Card>
          )}

          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {mainComponent}
            </Grid>
          </Grid>
        </Container>
      </LoadingOverlay>
    </>
  );
};

export default PageLayout;
