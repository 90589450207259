import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LoadingOverlay from "react-loading-overlay";
import { authContext } from "../../adalConfig";
import { useTranslation } from "react-i18next";
import ShippingDataSearchComponent from "./RequestByDeputySearchComponent";
import RequestByDeputyListingComponent from "./RequestByDeputyListingComponent";

import { addressContainerStyles } from "../Admin/Address/addressStyles";

const RequestDeputyComponent = (props) => {
  const { instance } = useMsal();

  const userId = instance.getActiveAccount().username;
  const { actions, requestDeputy, allMaterials, tableDelegateOptions } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = addressContainerStyles();
  const [t] = useTranslation();
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };
  useEffect(() => {
    actions.redirectAction("/requestByDelegate");
    actions.getRequestByDeputy(userId).then(() => {
      actions.getAllAddress().then((res) => {
        let data = [];
        for (let item of res.data) {
          data.push({
            id: item.id,
            name: `${item.entity},${item.department},${item.street},${item.city},${item.name_english}`,
          });
        }
        setAddressList(
          data.sort((a, b) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            if (a === b) return 0;
            return a < b ? -1 : 1;
          }),
        );
        setIsLoading(false);
      });
    });
  }, []);
  const searchFilterData = (data) => {
    setSearchFilter(data);
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Divider />
        <Container maxWidth="xxxl" className={classes.container}>
          <Grid container spacing={2}>
            <Grid className="mt-1" item xs={12}>
              <span className="title_main mt-1">
                {t("navbar.requestByDeputy")}
              </span>
              <Card className="mt-2">
                <CardHeader
                  title={t("commonlabels.SearchPlaceholder")}
                  style={cardTheme.cardHeaderStylePref}
                ></CardHeader>
                <CardContent>
                  <ShippingDataSearchComponent
                    onSearch={searchFilterData}
                    searchKey="requestByDeputy"
                    minimalSearch={true}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {!isLoading &&
                <RequestByDeputyListingComponent
                  listData={requestDeputy}
                  allMaterials={allMaterials}
                  actions={actions}
                  searchFilter={searchFilter}
                  setIsLoading={setIsLoading}
                  tableDelegateOptions={tableDelegateOptions}
                />
              }
            </Grid>
          </Grid>
        </Container>
      </LoadingOverlay>
    </>
  );
};

export default RequestDeputyComponent;
