import MaterialTable from "@material-table/core";
import React from "react";
import { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router";
import shippingService from "../../../Services/shippingService";
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {DateFormat} from "../../common/utils/DateUtil";

import "./confirmPickupMainPage.css";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { SortFunctionNew } from "../../../Services/util";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ConfirmPickupMainPage(props) {
  const { processShippmentList } = props;
  const history = useHistory();
  const { id } = useParams();
  registerLocale("de", de);
  const [selected, setSelected] = useState();
  const [shippmentDetails, setShippmentDetails] = useState(null);
  const [allMaterials, setAllMaterials] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (id) {
      setSelected(processShippmentList.find((item) => item.id === id));
    }
  }, [processShippmentList]);

  useEffect(async () => {
    if (id) {
      const data = await shippingService.getShippingHistoryDeatails(id);
      setShippmentDetails(data.shippingData);
      setAllMaterials(data.materialData);
      setSelectedDate(
        data?.shippingData?.shipment_date_mon
          ? new Date(data?.shippingData.shipment_date_mon)
          : "",
      );
    }
  }, []);

  const onConfirmPickup = async () => {
    if (selectedDate) {
      await shippingService.setShippingStatus({
        id: selected.id,
        status: "READY_FOR_DELIVERY",
        date: selectedDate,
      });
      toast.success(t("commonlabels.savedSuccessfullyMsg"));
      history.goBack();
    } else {
      toast.error("Please select Dispatch Date - Elanco field.");
    }
  };

  return (
    <div className="container-fluid">
      <br></br>
      <div className="row col-12 border p8 pl-0">
        <div className="col-lg-3">
          <label className="col-form-label">
            {" "}
            {t("materials.create_user")}
          </label>{" "}
          {selected?.created_by}{" "}
        </div>

        <div className="col-lg-3">
          <label className="col-form-label">
            {" "}
            {t("materials.create_date")}
          </label>{" "}
         
          {DateFormat(selected?.created_on,i18n.resolvedLanguage)}
        </div>

        <div className="col-lg-3">
          <label className="col-form-label">
            {t("process_shipping_request.edit_user")}
          </label>{" "}
          {shippmentDetails?.updated_by}
        </div>

        <div className="col-lg-3">
          <label className="col-form-label">
            {t("process_shipping_request.edit_date")}
          </label>{" "}
          {shippmentDetails?.updated_on? DateFormat(shippmentDetails?.updated_on, i18n.resolvedLanguage):""}
          {selected?.updated_on && DateFormat(selected?.updated_on, i18n.resolvedLanguage)}
        
        </div>
      </div>
      <br></br>
      <div className="row col-lg-12 pr-0">
        <div className="row col-6 border pl-0 p8 ">
          <div className="col-6 " style={{ display: "none" }}>
            <label className="col-form-label">
              {t("shippingrequest.sapnumber")}:
            </label>{" "}
            {selected?.sapnumber}{" "}
          </div>
          <div className="col-5 pr-0 ">
            <label className="col-form-label">
              {" "}
              {t("shippingrequest.dispatchdate(mon)")}:
            </label>{" "}
            <DatePicker
             locale={i18n.resolvedLanguage}
              className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
              margin="normal"
              variant="outlined"
              size="small"
              dateFormat="dd-MMM-yyyy"
              portalId="111"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              selected={selectedDate}
              onChange={(val) => setSelectedDate(val)}
            />
          </div>
        </div>
        <div className="col-6 border p8 pr-0">
          {t("process_shipping_request.dispatch_date_pick_msg")}
        </div>
      </div>
      <br></br>

      <div className="row col-lg-12 p8 border pl-0">
        <div className="col-lg-3">
          <label className="col-form-label">
            {t("address.Company/University")}
          </label>{" "}
          {shippmentDetails?.street}
        </div>
        <div className="col-lg-3">
          <label className="col-form-label">{t("address.Department")}</label>{" "}
          {shippmentDetails?.department}
        </div>
        <div className="col-lg-3">
          <label className="col-form-label">{t("address.building")}</label>{" "}
          {shippmentDetails?.building}
        </div>
        <div className="col-lg-3">
          <label className="col-form-label">{t("address.Street")}</label>{" "}
          {shippmentDetails?.street}
        </div>
        <div className="col-lg-3 pt10">
          <label className="col-form-label">{t("address.Postal Code")}</label>{" "}
          {shippmentDetails?.postcode}
        </div>
        <div className="col-lg-3 pt10">
          <label className="col-form-label">{t("address.City")}</label>{" "}
          {shippmentDetails?.city}
        </div>
        <div className="col-lg-3 pt10">
          <label className="col-form-label">{t("address.Country")}</label>{" "}
          {shippmentDetails?.name_english}
        </div>
      </div>
      <br></br>
      <div className="row col-lg-12 p8 border pl-0">
        <div className="col-lg-3">
          <label className="col-form-label">{t("recipient.Title")}</label>{" "}
          {shippmentDetails?.title}
        </div>
        <div className="col-lg-3">
          <label className="col-form-label">
            {t("search_fields.first_name")}
          </label>{" "}
          {shippmentDetails?.first_name}
        </div>
        <div className="col-lg-3">
          <label className="col-form-label">{t("recipient.Last name")}</label>{" "}
          {shippmentDetails?.last_name}
        </div>
        <div className="col-lg-3 pt10">
          <label className="col-form-label">{t("recipient.Email")}</label>{" "}
          {shippmentDetails?.email}
        </div>
        <div className="col-lg-3 pt10">
          <label className="col-form-label">{t("recipient.Phone")}</label>{" "}
          {shippmentDetails?.phone}
        </div>
      </div>
      <br></br>
      <div className="pr-0 pl-0 border">
        <MaterialTable
          columns={[
            {
              title: <span>{t("shippingrequest.material(name)")}</span>,
              field: "description",
              customSort: SortFunctionNew("description"),
            },
            {
              title: (
                <span>{t("process_shipping_request.amount_and_unit")}</span>
              ),
              field: "amount",
              customSort: SortFunctionNew("amount"),
            },

            {
              title: (
                <span>{t("process_shipping_request.country_of_origin")}</span>
              ),
              field: "name_english",
              customSort: SortFunctionNew("name_english"),
            },
            {
              title: <span>{t("process_shipping_request.batch_no")}</span>,
              field: "batch_number",
              customSort: SortFunctionNew("batch_number"),
            },
            {
              title: (
                <span>{t("process_shipping_request.aggregation_state")}</span>
              ),
              field: "phase",
              customSort: SortFunctionNew("phase"),
            },
            {
              title: <span>{t("process_shipping_request.comment")}</span>,
              field: "c_comment",
              customSort: SortFunctionNew("c_comment"),
            },
          ]}
          data={allMaterials}
          title="Render Image Preview"
          icons={tableIcons}
          localization={{
            pagination: {
              labelRowsSelect: t("commonlabels.labelRowsSelect"),
              firstAriaLabel: t("commonlabels.firstAriaLabel"),
              firstTooltip: t("commonlabels.firstTooltip"),
              previousAriaLabel: t("commonlabels.previousAriaLabel"),
              previousTooltip: t("commonlabels.previousTooltip"),
              nextAriaLabel: t("commonlabels.nextAriaLabel"),
              nextTooltip: t("commonlabels.nextTooltip"),
              lastAriaLabel: t("commonlabels.lastAriaLabel"),
              lastTooltip: t("commonlabels.lastTooltip"),
            },
            body: {
              emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
            },
          }}
          options={{
            draggable: false,
            thirdSortClick: false,
            headerStyle: {
              backgroundColor: "#cce3f5",
              fontWeight: "bold",
              height: "10px",
            },
            rowStyle: {
              height: "30px",
              padding: "0px",
            },

            maxBodyHeight: "90%",
            tableLayout: "auto",
            sorting: true,
            search: false,
            selection: false,
            showTitle: false,
            toolbar: false,
            paging: true,
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 30, 40],
            emptyRowsWhenPaging: false,
            showSelectAllCheckbox: false,
          }}
        />
      </div>

      <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
        <button
          className="btn btn-primary new_button"
          onClick={onConfirmPickup}
          disabled={!id}
        >
          {t("shippingrequest.confirmpickup")}
        </button>
        &nbsp;
        <button
          className="btn btn-secondary new_button"
          onClick={() => history.goBack()}
        >
          {t("materials.back")}
        </button>
      </div>
    </div>
  );
}

// const Col10Container = ({ children }) => {
//   return (
//     <div className="row ">
//       <div className="col-12 col-sm-10 confirm-pickup-border">{children}</div>
//     </div>
//   );
// };
// const Col5Container = ({ children }) => {
//   return (
//     <div className="col-12 col-sm-5 confirm-pickup-border">{children}</div>
//   );
// };
