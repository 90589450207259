import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CustomTable from '../common/CustomTable';
import DestinationAddr from '../common/search'



import { 
    CheckCircle as CheckCircleIcon, 
    Add as AddIcon, 
    Cancel as CancelIcon
} from '@mui/icons-material';

import { AddressColumns } from '../common/Columns'
import ButtonGroup from "../common/ButtonGroup";
import PageLayout from '../common/PageLayout';

const DestinationAddressComponent = ( props ) => {

    const history = useHistory();


    //const [destinationAddrData, setDestinationAddrData] = useState()
    const [searchFieldValues, setSearchFieldValues] = useState([])
    const [selectedRow, setSelectedRow] = useState({tableData: { id: 0 }})
    const [ firstRowData, setFirstRowData ] = useState({})
   
    const {destinationAddrList, actions, countries } = props
    //const classes = addressContainerStyles();

    const handlerSelectedRow = (row) => {
       setSelectedRow(row)
  }

  const getFirstRowData = (row) => {
    setFirstRowData(row)
  }

    useEffect( () => {
      actions.getCountries()
    }, [])

    // const handleDestinationAddr = (data) => {
    //     setDestinationAddrData(data)
    // }

    const handleNewAddress = () => {
      history.push("/addAddress", {
        redirectShipping: true,
      });
       // actions.redirectAction("/shipping"); 
      //  history.push("/addAddress");
      };

      const handleAcceptAddress = () => {
        let rowData = {}
        if(selectedRow.tableData.id === 0) {
            rowData = firstRowData
        }
        if(selectedRow.tableData.id !== 0) {
            rowData = selectedRow
        }

        actions.destinationAddrSelect(rowData)
        history.push(`/shipping`);
      };
    
      const handleCancelAddress = () => {
         actions.clearDestinationAddrSelect()
         history.push(`/shipping`);
      };
      
     const searchData = (data) =>{
        setSearchFieldValues(data)
     } 

    const columns = AddressColumns(
        selectedRow,
        handlerSelectedRow,
        getFirstRowData
        ) 

  
      // const handleRedirectToAddress = () => {
      //     history.push("/addAddress");
      //     actions.redirectAction("/shipping"); 
      // }
     
    return(
       
        <PageLayout 
          title={'Search Address'}
          searchComponent={<DestinationAddr 
            columns = {columns }  
            onSearch={(data) => searchData(data)} 
            list={countries}
            />}

          mainComponent={
            <>
               <CustomTable 
                    listData={destinationAddrList} 
                    columns = {columns}
                    selectedData={selectedRow}
                    searchFieldValues = {searchFieldValues}
                >
                  
                <ButtonGroup  buttonNames = {[
                    {name: "New", Icon: AddIcon, fill: '#3cb371', onClick: handleNewAddress, listData: destinationAddrList }, 
                    {name: "Accept", Icon: CheckCircleIcon, fill: '#3cb371', onClick: handleAcceptAddress, listData: destinationAddrList},
                    {name: "Cancel", Icon: CancelIcon, fill: '#ff0000', onClick: handleCancelAddress, listData: destinationAddrList },
                    ]} />
                  </CustomTable>
            </>
          }
        />
       
    )
}

export default DestinationAddressComponent;