import types from "./types";

const initialState = {
  invoiceData: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_INVOICE_DATA: {
      return {
        ...state,
        invoiceData: action.payload,
      };
    }
    case types.GET_INVOICE_DATA: {
      return {
        ...state,
        invoiceData: action.payload,
      };
    }
    case types.CLEAR_INVOICE_DATA: {
     
      return {
        state,
        initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
