import React, { useState, forwardRef, useEffect } from "react"; 
import * as config from "../../config";
import Card from "@mui/material/Card";
import Model from "../shared/model";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import { useTranslation } from "react-i18next";
import {get } from "lodash";
import { useLocation } from "react-router-dom";

import { getFilterData } from "./search/filterData";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import {DateFormat} from "../common/utils/DateUtil";
import { cardTheme } from "../common/ThemeStye";

const CustomTable = (props) => {
  const { listData, columns, searchFieldValues,selectedData } = props;
  const [filteredData, setfilteredData] = useState(listData);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] = useState(false);
  const location = useLocation();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const { t, i18n } = useTranslation();

  //const classes = addressSearchStyles();

  // useEffect(() => {
  //   setfilteredData(listData);
  // }, [listData]);

  useEffect(() => {
    const searchData = getFilterData(listData, searchFieldValues);
    setfilteredData(searchData);
  }, [searchFieldValues, listData]);

  const handleCSVExport1 = async () => {
   
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Destination Address");
    writeFile(wb, requiredFileName+".xlsx");
    
  };
  const exportDataBuilder1 = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on,i18n.resolvedLanguage);

      }

      if (item.dateofrecipient) {
        item.dateofrecipient = DateFormat(item.dateofrecipient,i18n.resolvedLanguage);
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate,i18n.resolvedLanguage);
      }

      if (item.name === " ()") {
        item.name = "";
      }

      // item[("Company/University")] = item["entity"];
      //    item[("Department")] = item["department"];
      //    item[("Street")] = item["street"];
      //    item[("PostalCode")] = item["postcode"];

      //    item["City"] = item["city"];

      //    item["Country"] = item["name_english"];

      //    item[t("address.Approved")] = item["approved"];
      //   item[t("phase.Valid")] = item["valid"];

      item["First Name"] = item["first_name"];
      item[t("registration.lastname")] = item["last_name"];
      item[t("registration.Email")] = item["email"];
      item[t("registration.Address")] = item["form_of_address"];

      item["Country:"] = item["name"];
     // item[t("phase.id")] = item["id"];
      item[t("address.Approved")] = item["approved"];
      item[t("phase.Valid")] = item["valid"];
      item[t("Create User")] = item["created_by"];

      //    item["Country"] = item["name_english"];

      //    item[t("address.Approved")] = item["approved"];
      //   item[t("phase.Valid")] = item["valid"];

      // item[t("First Name")] = item["first_name"];
      // item[t("Last Name")] = item["last_name"];
      // item[t("Email")] = item["email"];
      // item[t("Address")] = item["form_of_address"];

      // item["country"] = item["Country"];
      // item["Id"] = item["id"];
      // item[t("address.Approved")] = item["approved"];
      // item[t("phase.Valid")] = item["valid"];
      delete item["title"];

      delete item["phone"];
      delete item["first_name"];
      delete item["last_name"];
      delete item["email"];
      delete item["form_of_address"];
      delete item["Country"];
      delete item["Postal Code"];
      delete item["City"];
      delete item["name_english"];

      delete item["address_id"];
      delete item["title_id"];
      delete item["Company/Universitys"];
      delete item["Department"];
      delete item["Street"];
      delete item["Postal Code"];
      delete item["City"];
      delete item["name_english"];

      delete item["country_id"];
      delete item["building"];
      delete item["sapnumber"];
      delete item["submitted"];
      delete item["is_active"];
      delete item["state"];
      delete item["name"];
      delete item["name_english"];
      delete item["valid"];
      delete item["entity"];
      delete item["code"];
      delete item["department"];
      delete item["street"];
      delete item["postcode"];
      delete item["city"];
      delete item["approved"];
      delete item["valid"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["id"];
      delete item["created_by"];
      delete item["created_on"];
      delete item["tableData"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };

  const handleCSVExport = async () => {
   
    let dataForExport = exportDataBuilder1();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "FinalRecipient");
    writeFile(wb, requiredFileName+".xlsx");
  
  };
  const ExportBtn = () => {
    return (
      <button
      
        onClick={(e) => {
        
             setIsExportModalVisible(true)
            
           
        }}
      >
         {t("export.exportall")}
      </button>
    );
  };
  const ExportSelectedBtnForSingle= () => {
    return (
      <button
      disabled={get(selectedData, "id", 0) === 0 ? true : false}
        onClick={(e) => {
        
             setIsExportModalVisibleSingle(true)
            
           
        }}
      >
           {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>
              
             

            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
      {location.pathname === "/finalRecipients" ?
     
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
           handleCSVExport()
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>
        :
        <button
        type="button"
        id="Yes"
        disabled={requiredFileName !== "" ? false : true}
        className="btn btn-primary btn-inner-close"
        onClick={(e) => {
          handleCSVExport1();
          setIsExportModalVisible(false);
          setrequiredFileName("");
        }}
      >
        Export
      </button>
        }
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>
              
             

            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
     
     
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn()
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names
      if (item.created_on) {
        item.created_on = DateFormat(item.created_on,i18n.resolvedLanguage);
      }

      if (item.dateofrecipient) {
        item.dateofrecipient = DateFormat(item.dateofrecipient,i18n.resolvedLanguage);
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate,i18n.resolvedLanguage);
      }

      item["Company/University"] = item["entity"];
     // item["Department:"] = item["department"];
      item["Street:"] = item["street"];

      item["City:"] = item["city"];
      item["State"]=item["state"];
      item["Postal code"] = item["postcode"];

      // item[t("address.Company/University")] = item["entity"];
      // item[t("address.Department")] = item["department"];
      // item[t("address.Street")] = item["street"];
      // item[t("address.Postal Code")] = item["postcode"];

    

      item["Country:"] = item["name_english"];

      item["Approved"] = item["approved"];
      item["Valid"] = item["valid"];

      delete item["phone"];
      delete item["first_name"];
      delete item["last_name"];
      delete item["email"];
      delete item["form_of_address"];
      delete item["Country"];
      delete item["Postal Code"];
      delete item["City"];
      delete item["name_english"];

      delete item["address_id"];
      delete item["title_id"];
      delete item["Company/Universitys"];
      delete item["Department"];
      delete item["Street"];
      delete item["Postal Code"];
      delete item["City"];
      delete item["name_english"];

      delete item["country_id"];
      delete item["building"];
      delete item["sapnumber"];
      delete item["submitted"];
      delete item["is_active"];
      delete item["state"];
      delete item["name"];
      delete item["name_english"];
      delete item["valid"];
      delete item["entity"];
      delete item["code"];
      delete item["department"];
      delete item["street"];
      delete item["postcode"];
      delete item["city"];
      delete item["approved"];
      delete item["valid"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["id"];
      delete item["created_by"];
      delete item["created_on"];
      delete item["tableData"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };

  const ExportSelectedBtn = () => {
    

          if(window.location.pathname.includes("/destinationAddr")){
            // This reference is removed in API with US#4620 
            // window.location.href = config.baseURL + `/api/exportAddressData/`+ selectedData.id+ '/' + requiredFileName ; ;
            }
            else if(window.location.pathname.includes("/finalRecipients")){
              // This reference is removed in API with US#4620 
              //window.location.href = config.baseURL + `/api/exportRecipientData/`+ selectedData.id+ '/' + requiredFileName ; ;
            }         
       
  };

  return (
    <>

<div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
          <DropdownButton  title={t("export.export")} className="mr-1 exportdropdown">
         <Dropdown.Item >{  ExportBtn()}</Dropdown.Item>
         <Dropdown.Item >{ExportSelectedBtnForSingle()}</Dropdown.Item>
         </DropdownButton>
     
          
          
            {/* //  {inspectHideAndShow()} */}
          </div>
        </div>
      </div>
      <br />
      <Card variant="outlined">
        <CardHeader
          title={t("phase.searchresults")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
            <MaterialTable
              columns={columns}
              data={filteredData}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: {
                  height: "30px",
                  padding: "0px",
                },

                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,

                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 40],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,

                rowStyle: (data, index) =>
                  index % 2 === 0 ? { background: "#f5f5f5" } : null,
              }}
            />
          </div>
          {props.children}
        </CardContent>
      </Card>
      <Model
          showheader={isExportModalVisible}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBody()}
          modalfooter={exportModalFooter()}
        ></Model>
          <Model
          showheader={isExportModalVisibleSingle}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBodySingleData()}
          modalfooter={exportModalFooterSingleData()}
        ></Model>
    </>
  );
};

export default CustomTable;
