import types from "./types";
import fetchData from "../util";

const getAllShippingData = () => {
 
  return fetchData(types.GET_ALL_SHIPPINGDATA, "getRegulatoryData");
};
const getAllReviewerMasterData  = () => {
 
  return fetchData(types.GET_ALL_REGULATORYDATA, "getReviewerMasterData");
};
const  saveRegulatoryReveiwerDetails = (...params) => {
  return fetchData(types.SAVE_REVIEWER_DATA, "saveRegulatoryReveiwerDetails", params);
};


const saveRegulatoryReveiwer2Details = (...params) => {
  return fetchData(types.SAVE_REVIEWER2_DATA, "saveRegulatoryReveiwer2Details", params);
};

const getApproveRegDetails = (...params) => {
  return fetchData(types.GET_REG1_DETAILS, "getApproveRegDetails", params);
};
const getApproveReg1condition = (...params) => {
  return fetchData(types.GET_REG_CONDITION, "getApproveReg1condition", params);
};

const getRegulatoryReviewer1List = ()=>{
  return fetchData(types.GET_REGULATORY_REVIEWER1_LIST, "getRegulatoryReviewer1List")
}
const setRegulatoryTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_REGULATORY_TABLE_OPTIONS, payload: data });
  };
 
};
const setDelegateTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_DELEGATE_TABLE_OPTIONS, payload: data });
  };
};
const setProcessShippingTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_PROCESS_SHIPPING_TABLE_OPTIONS, payload: data });
  };
}
const setRegulatorySearchData = (payload = "") => {
  return {
    type: types.SET_REGULATORY_SEARCH_DATA,
    payload,
  };
};
const setDelegateSearchData = (payload = "") => {
  return {
    type: types.SET_DELEGATE_SEARCH_DATA,
    payload,
  };
};
export default {
  setDelegateTableOptions,
  setProcessShippingTableOptions,
  setRegulatorySearchData,
  setRegulatoryTableOptions,
  getAllShippingData,
  saveRegulatoryReveiwerDetails,
  getAllReviewerMasterData,
  getApproveRegDetails,
  getApproveReg1condition,
  saveRegulatoryReveiwer2Details,
  getRegulatoryReviewer1List,
  setDelegateSearchData,
};
