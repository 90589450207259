import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import recipients from "../../../redux/recipients";
import shipping from "../../../redux/shipping";
import address from "../../../redux/address";
import RecipientsApproveComponent from "./RecipientsApproveComponent";
import CheckRecipientsComponent from "./CheckRecipientsComponent";
import tableListing from "../../../redux/tableListing";

const {
  getAllrecipients,
  getAllTitle,
  saveRecipients,
  updateRecipients,
  updateRecipientsAccess,
  updateRecipientsApproval,
  getSelectedRecipientData,
  setApproveRecipientTableOptions,
} = recipients.actions;

const { getAddress, getAllAddress } = address.actions;
const { getCountries, getShippingRequestDetails } = shipping.actions;
const { saveTableData } = tableListing.actions;

const mapStateToProps = (state) => ({
  allRecipients: state[recipients.name].allRecipients,
  countries: state[shipping.name].countries,
  allTitle: state[recipients.name].allTitle,
  allAddress: state[address.name].allAddress,
  tableApproveRecipientOptions: state[recipients.name].tableApproveRecipientOptions,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setApproveRecipientTableOptions,
      getAllrecipients,
      getAllAddress,
      getCountries,
      getAllTitle,
      getAddress,
      saveRecipients,
      updateRecipients,
      updateRecipientsAccess,
      updateRecipientsApproval,
      getShippingRequestDetails,
      saveTableData,
      getSelectedRecipientData,
    },
    dispatch,
  ),
});

export default {
  RecipientsApproveComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecipientsApproveComponent),
  CheckRecipientsComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CheckRecipientsComponent),
};
