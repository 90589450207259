import React from 'react'

export default function RadioInput(props) {
const {label, name} = props
  return (
    <div className='d-flex'>
        <input type='radio' name={name} {...props} />
        <label style={{padding:'3px', paddingTop:'8px'}} labelFor={name}>{label}</label>
    </div>
  )
}
