
import axios from '../../api'

export default class Api {
    
    static get(url) {
        return axios.get(url).then(res => {
            return res
        }).catch(err => {
            return err
        })
    }

    static post(url , jsonData = {}) {
        return axios.post(url, jsonData).then(res => {
            return res
        }).catch(err => {
            return err
        })
    }

    static put(url , jsonData = {}) {
        return axios.put(url, jsonData).then(res => {
            return res
        }).catch(err => {
            return err
        })
    }
}
