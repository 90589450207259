import { utils, writeFile } from "xlsx";

const exportAllData = (exportDataProperty = [], data = [], sheetName, fileName) => {
  let dataForExport = [];
  let colWidthProperties = [];
  data.forEach((record) => {
    let item = {};
    exportDataProperty.forEach(element => {
      item[element.displayName] = record[element.dbColumn];
    });
    dataForExport.push(item);
  });

  exportDataProperty.forEach(element => {
    colWidthProperties.push({ "width": element.width });
  });

  write_to_excel(fileName, sheetName, dataForExport, colWidthProperties);
}

const write_to_excel = (fileName, sheetName, dataForExport, colWidthProperties) => {
  let wb = utils.book_new();
  let ws = utils.json_to_sheet(dataForExport);
  ws["!cols"] = colWidthProperties;

  utils.book_append_sheet(wb, ws, sheetName);
  writeFile(wb, fileName + ".xlsx");
}
export default {
  exportAllData
};