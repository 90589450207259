import { React, useState, forwardRef, useEffect } from "react";
import { useHistory} from "react-router-dom";
import { get } from "lodash";
import moment from "moment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "../addressStyles";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Model from "../../shared/model";


import "./processShippingRequestLogTable.css";


import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

import {
  dateSortFunction,
  SortFunctionNew,
} from "../../../Services/util";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ProcessShippingRequestLogTable = (props) => {
  const [selected, setSelected] = useState("");
  const {
    shippmentHistoryLogs,
    
    searchFilter,
   
    allMaterials,
  } = props;
  const [filteredData, setfilteredData] = useState([]);

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);

  const classes = addressSearchStyles();
  const history = useHistory();
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey"
    );

    const filteredData = shippmentHistoryLogs
      .filter((item) => !!item.new_value || !!item.old_value)
      .filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (
            !(
              item[searchParam] &&
              item[searchParam]
                .toLowerCase()
                .includes(searchFilter[searchParam].toLowerCase())
            )
          ) {
            return false;
          }
        }
        return true;
      })
      .map((item, index)=>({...item, idGen: index}));

    setfilteredData(filteredData);
  }, [searchFilter, allMaterials, shippmentHistoryLogs]);
  

  const { t } = useTranslation();

  const handleRadiobuttonChange = (data) => {
    setSelected(data);
  };

  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setIsExportModalVisibleSingle(false)
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setIsExportModalVisibleSingle(false)
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataForExport);

    utils.book_append_sheet(wb, ws, "Process_Shipping_Requests_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let data = []
    if(isExportModalVisible){
      data = filteredData;
    } else {
      data = [selected];
    }
    const copyOfFilteredData = data?.map((item) => ({
      'Object ID': item.id || '',
      'Transaction': item.operation || '',
      'Type of BO': item.class_name || '',
      'Field': item.field_name || '',
      'Old Value': item.old_value || '',
      'New Value': item.new_value || '',
      'Transaction Time': item.transaction_time || '',
      'Editor': item.username || '',
    }));
    return _.cloneDeep(copyOfFilteredData);
  };

  return (
    <>
      <div className="row m0 container-fluid">
        <div className="no_padding col-12 ">
          <div className={classes.actionButtonsTop}></div>
        </div>
      </div>

      <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
        <div className="buttonsec_animalmanagment">
          <DropdownButton
            title={t("export.export")}
            className="mr-1 exportdropdown"
          >
            <Dropdown.Item>
              <button
                className="mr-1"
                onClick={() => {
                  setIsExportModalVisible(true);
                }}
              >
                {t("export.exportall")}
              </button>
            </Dropdown.Item>
            <Dropdown.Item>
              <button
                disabled={selected === ""}
                onClick={(e) => {
                  setIsExportModalVisibleSingle(true);
                }}
              >
                {t("export.exportselected")}
              </button>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      <Card variant="outlined">
        <CardHeader
          title={
            <div className="header-log-table">
              <span>{t("approve_recipient.search_text")}</span>
              <span className="info-section">
                <span className="item-section">
                  <div className="color-section insert-color"></div>
                  <div>{t("shippingrequest.insert")}</div>
                </span>
                <span className="item-section">
                  <div className="color-section update-color"></div>
                  <div>{t("materials.update")}</div>
                </span>
              </span>
            </div>
          }
          style={cardTheme.cardHeaderStylePref}
        ></CardHeader>
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
            <MaterialTable
              columns={[
                {
                  width: "4%",
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                  sorting: false,
                },
                {
                  title: <span>{t("shippingrequest.Transaction")}</span>,
                  customSort: (a, b) => {
                    if (
                      a.operation.toString().toLowerCase() >
                      b.operation.toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      a.operation.toString().toLowerCase() <
                      b.operation.toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) => (
                    <div
                      className={
                        "info-section color-section " +
                        (rowData.operation === "INSERT"
                          ? " insert-color"
                          : " update-color")
                      }
                    ></div>
                  ),
                },
                {
                  title: <span>{t("shippingrequest.type_of_bo")}</span>,
                  customSort: (a, b) => {
                    if (
                      a.class_name.split(".").pop().toString().toLowerCase() >
                      b.class_name.split(".").pop().toString().toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      a.class_name.split(".").pop().toString().toLowerCase() <
                      b.class_name.split(".").pop().toString().toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) => (
                    <span>{rowData.class_name.split(".").pop()}</span>
                  ),
                },
                {
                  title: <span>{t("shippingrequest.Object_ID")}</span>,
                  field: "id",
                  customSort: (a, b) => {
                    if (parseInt(a.id) > parseInt(b.id)) {
                      return -1;
                    } else if (parseInt(a.id) < parseInt(b.id)) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                },
                {
                  title: <span>{t("shippingrequest.Field")}</span>,
                  render: (rowData) => rowData.field_name,
                  // t("shipping_log_fields." + rowData.field_name),
                  customSort: (a, b) => {
                    if (
                      t("shipping_log_fields." + a.field_name)
                        .toString()
                        .toLowerCase() >
                      t("shipping_log_fields." + b.field_name)
                        .toString()
                        .toLowerCase()
                    ) {
                      return -1;
                    } else if (
                      t("shipping_log_fields." + a.field_name)
                        .toString()
                        .toLowerCase() <
                      t("shipping_log_fields." + b.field_name)
                        .toString()
                        .toLowerCase()
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  },
                },
                {
                  title: <span>{t("shippingrequest.new_value")}</span>,
                  field: "new_value",
                  customSort: SortFunctionNew("new_value"),
                },
                {
                  title: <span>{t("shippingrequest.old_value")}</span>,
                  field: "old_value",
                  customSort: SortFunctionNew("old_value"),
                },
                {
                  title: <span>{t("shippingrequest.Transaction_time")}</span>,
                  render: (rowData) => (
                    <span>
                      {rowData.transaction_time &&
                        moment(rowData.transaction_time).format(
                          "MMM DD, YYYY HH:mm:ss A"
                        )}
                    </span>
                  ),
                  customSort: dateSortFunction("transaction_time"),
                },
                {
                  title: <span>{t("shippingrequest.editor")}</span>,
                  field: "username",
                  customSort: SortFunctionNew("username"),
                },
              ]}
              data={filteredData}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage"
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                idSynonym:'idGen',
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: {
                  height: "30px",
                  padding: "0px",
                },

                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 40],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
            <div className="no_padding col-12 ">
              <div className={classes.actionButtonsTop}>
                <button
                  className="btn btn-secondary new_button"
                  onClick={() => history.goBack()}
                >
                  {t("materials.back")}
                </button>
              </div>
            </div>
          </div>
        </CardContent>

        <Model
          showheader={isExportModalVisible|| isExportModalVisibleSingle}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBody()}
          modalfooter={exportModalFooter()}
        ></Model>
      </Card>
    </>
  );
};

export default ProcessShippingRequestLogTable;
