import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useHistory, useParams } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { authContext } from "../../adalConfig";
import { addressSearchStyles } from "./addressStyles";
import ActionConfirmModal from "../common/ActionConfirmModal";
import recipientService from "../../Services/recipientService";
import { toast } from "react-toastify";

const CheckProcessShippingRequestComponent = (props) => {
  const ItemContainer = ({ children, sm = "3" }) => {
    return <div className={`col-12 col-sm-${sm} form_item`}>{children}</div>;
  };
  const { actions, allRecipients } = props;
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;

  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [confirmActionPopup, setConfirmActionPopup] = useState(false);

  const { recipientId } = useParams();

  const classes = addressSearchStyles();
  useEffect(() => {
    if (recipientId) {
      if (allRecipients.length === 0) {
        actions.getAllrecipients().then((res) => {
          const selectedRecipients = allRecipients.find(
            (item) => item.id === recipientId,
          );
          setSelected(selectedRecipients);
          setIsLoading(false);
        });
      } else {
        const selectedRecipients = allRecipients.find(
          (item) => item.id === recipientId,
        );
        setSelected(selectedRecipients);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [recipientId]);

  const handleClose = () => {
    history.goBack();
  };

  const recipientApprovalUpdate = async (data) => {
    try {
      await recipientService.recipientApprovalUpdate(recipientId, data);
      toast.success(t("commonlabels.savedSuccessfullyMsg"));
      history.goBack();
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
      history.goBack();
    }
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Card variant="outlined" className={classes.container}>
          <CardHeader title={t("inspectRecipient.title")} />
          <Divider />
          <CardContent>
            <div className="row form-padding">
              <ItemContainer sm="6">
                <Card variant="outlined" className={classes.container}>
                  <Divider />
                  <CardContent className="form-padding">
                    <div className="row">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Title")}:
                        </span>
                        <span>{selected?.title}</span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("search_fields.first_name")}:
                        </span>
                        <span>{selected?.first_name}</span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Last name")}:
                        </span>
                        <span>{selected?.last_name}</span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Email")}:
                        </span>
                        <span>{selected?.email}</span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="6">
                        <span className={classes.textLable}>
                          {t("recipient.Phone")}:
                        </span>
                        <span>{selected?.phone}</span>
                      </ItemContainer>
                    </div>
                  </CardContent>
                </Card>
              </ItemContainer>
              <ItemContainer sm="6">
                <Card variant="outlined" className={classes.container}>
                  <CardContent>
                    <div>
                      <span>{t("approve_recipient.recipient_linked_msg")}</span>
                      <br />
                      <span>{t("approve_recipient.list_empty_msg")}</span>
                    </div>
                  </CardContent>
                </Card>
                <br />
                <Card
                  variant="outlined"
                  className={classes.container}
                  style={{ display: "none" }}
                >
                  <CardContent>
                    <ItemContainer sm="4">
                      <span className={classes.textLable}>
                        {t("recipient.sap_number")}
                      </span>
                    </ItemContainer>
                    <ItemContainer sm="8">
                      <span className={classes.textLable}>
                        {selected?.sapnumber}
                      </span>
                    </ItemContainer>
                  </CardContent>
                </Card>
                <br />
                <Card variant="outlined" className={classes.container}>
                  <Divider />

                  <CardContent className="form-padding">
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Company/University")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.entity}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Department")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.department}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.building")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.building}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Street")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.street}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Postal Code")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.postcode}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.City")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.city}
                        </span>
                      </ItemContainer>
                    </div>
                    <div className="row">
                      <ItemContainer sm="4">
                        <span className={classes.textLable}>
                          {t("address.Country")}
                        </span>
                      </ItemContainer>
                      <ItemContainer sm="8">
                        <span className={classes.textLable}>
                          {selected?.name_english}
                        </span>
                      </ItemContainer>
                    </div>
                  </CardContent>
                </Card>
              </ItemContainer>
            </div>
          </CardContent>
          <CardActions>
            <button
              className="btn btn-secondary new_button"
              onClick={handleClose}
            >
              {t("approve_recipient.previous")}
            </button>
            <Link
              to={{
                pathname: `/editRecipients`,
                state: { id: selected?.id },
              }}
            >
              <button className="btn btn-primary new_button">
                {t("materials.edit")}
              </button>
            </Link>

            <button
              className="btn btn-success   new_button"
              onClick={() => recipientApprovalUpdate({ approved: 1, userId })}
            >
              {t("materials.approve")}
            </button>

            <button
              className="btn btn-primary   new_button"
              onClick={() => setConfirmActionPopup(true)}
            >
              {t("approve_recipient.not_approve")}
            </button>
          </CardActions>
        </Card>

        <ActionConfirmModal
          show={confirmActionPopup}
          headerText={t("approve_recipient.disapprove_confirm_header")}
          bodyText={t("approve_recipient.disapprove_confirm_body")}
          confirmButtonText={t("approve_recipient.yes")}
          cancelButtonText={t("approve_recipient.no")}
          onConfirm={() => {
            recipientApprovalUpdate({ approved: 0, userId });
            setConfirmActionPopup(false);
          }}
          onCancel={() => {
            setConfirmActionPopup(false);
          }}
        />
      </LoadingOverlay>
    </>
  );
};

export default CheckProcessShippingRequestComponent;
