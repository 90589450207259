import React from "react";
import Modal from "react-bootstrap/Modal";

export default function CommonModal(props) {
  const { show, header, body, footer, onCancel } = props;
  return (
    <Modal
      dialogClassName="modal-90w"
      size="lg"
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      onHide={onCancel}
      {...props}
    >
      <Modal.Header closeButton>{header}</Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
}
