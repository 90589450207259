import types from "./types";

const initialState = {
  navKey: '',
  AlertNotificationCount: 0,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NAV_KEY :{
      return {
        ...state,
        navKey: action.payload,
      };
    }
    case types.GET_ALERT_NOTIFICATION_BY_USERID: {
      return {
        ...state,
        AlertNotificationCount: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
