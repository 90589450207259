import React, { useReducer } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import Select from "../CustomSelect";

import TextField from "./TextField";
import { filterStyles } from "./filterStyle";
import { getColumnFields, getSearchFieldObject } from "./filterFields";
import ReactDatePicker from "react-datepicker";

const CheckboxField = (props) => {
  return (
    <div className="row col-12">
      <Checkbox
        margin="normal"
        variant="outlined"
        size="small"
        checked={props.state[props.name]}
        {...props}
      />
      <label>{props.label}</label>
    </div>
  );
};

export default (props) => {
  const { columns, onSearch } = props;

  const columnFields = getColumnFields(columns);
  const sfields = getSearchFieldObject(columnFields);

  
  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return sfields;
    }

    return { ...state, [event.target.name]: event.target.value };
  };

  const handleSearch = () => {
    if (state.name_english) {
      if (Array.isArray(props.list)) {
        const country = props.list.find(
          (item) => item.id == state.name_english
        );
        return onSearch({ ...state, name_english: country?.name });
      }
    }
    return onSearch(state);
  };

  const [state, dispatch] = useReducer(reducer, { ...sfields });
  const classes = filterStyles();

  const [t, i8ln] = useTranslation();

  const getFields = () => {
    {
      return columnFields.map((item) => {
       
        if (item.type === "select") {
          return (
            <div className="col-lg-3  pr-0 mt-1 pt10 approvesearchcountry">
              <Select
                labelName={t(`search_fields.${item.field}`)}
                value={state[item.field]}
                name={item.field}
                list={item.list || []}
                onChange={(e) => {
                  dispatch(e);
                }}
                {...props}
              />
            </div>
          );
        } else if (item.type === "checkbox") {
          return (
            <div className="col-lg-4 mt-1 pt10 approvesearchcountry">
              <CheckboxField
                id={item.field}
                name={item.field}
                onChange={dispatch}
                state={state}
              />
            </div>
          );
        } else if (item.type === "date") {
          return (
            <div className="col-lg-3 mb-1 pt10 approvesearchcountry">
              <ReactDatePicker
                locale={i8ln.resolvedLanguage}
                id={item.field}
                name={item.field}
                portalId="root-portal"
                className="form-control my-icon"
                margin="normal"
                variant="outlined"
                size="small"
                dateFormat="dd-MMM-yyyy"
                placeholderText={t(`search_fields.${item.field}`)}
                selected={state[item.field]}
                showYearDropdown
                showMonthDropdown
                onChange={(date)=>dispatch({target:{name:item.field, value: date}})}
                dropdownMode="select"
               
              />
            </div>
          );
        } else {
          return (
            <div className="col-lg-3  pr-0 mb-1 pt10 searchcountry">
              <TextField
                id={item.field}
                name={item.field}
                onChange={dispatch}
                value={state[item.field]}
                placeholder={t(`search_fields.${item.field}`)}
                {...props}
              />
            </div>
          );
        }
      });
    }
  };

  return (
    <>
      <Card className={classes.container + "cad_header"}>
        <CardContent className="tablebg_box">
          <div className="row">
            <div className="col-11 pr-0 ">
              <div className="row col-12 ">{getFields()}</div>
            </div>
          <div className="pt10">  <Button
              variant="contained"
              className={classes.searchBt + " search_buttontwo"}
              onClick={() => handleSearch()}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.reloadBt + " refresh_button"}
              onClick={() => dispatch({ type: "RESET" })}
            >
              <ReplayIcon />
            </Button></div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
