import React from "react";
import { useTranslation } from "react-i18next";

const FinalRecipientDetails = ({ finalRecipient }) => {
  const istitlevalid = (obj) =>{
    if(obj?.titlevalid == null || finalRecipient== undefined || Object.keys(obj).length == 0) return true
    if(obj?.titlevalid == 1) return true
    return false;
  }

  const { t } = useTranslation();
  return (
    <>
      <div className="row col-12 pr-0 mt-minus40" >
        <div className="col-lg-2  col-12 pl-2">
          <label className="col-form-label" >{t("recipient.Title")}</label>

          <label  className={(istitlevalid(finalRecipient))? " ":"titlecolor"}> {finalRecipient.title}</label>
        </div>
        <div className="col-lg-2  col-12 pl-0">
          <label className="col-form-label">
            {t("search_fields.first_name")}
          </label>

          <label> {finalRecipient.first_name}</label>
        </div>
        <div className="col-lg-2  col-12 pl-2">
          <label className="col-form-label"> {t("recipient.Last name")} </label>

          <label> {finalRecipient.last_name}</label>
        </div>
        <div className="col-lg-2  col-12 pl-2">
          <label className="col-form-label"> {t("recipient.Email")} </label>

          <label> {finalRecipient.email}</label>
        </div>
        <div className="col-lg-2  col-12 pl-2">
          <label className="col-form-label"> {t("recipient.Phone")} </label>

          <label> {finalRecipient.phone}</label>
        </div>
      </div>

     
    </>
  );
};

export default FinalRecipientDetails;
