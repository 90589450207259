import types from "./types";
import fetchData from "../util";
const saveReportsAttachments = (...params) => {
    return fetchData(types.SAVE_REPORTS_DATA, "saveReportsAttachments", params);
  };
  const getReportsAttachments = (...params) => {
    return fetchData(types.GET_REPORTS_DATA, "getReportsAttachments", params);
  };
  const deleteReportsAttachments = (...params) => {
    return fetchData(types.DELETE_REPORTS_DATA, "deleteReportsAttachments", params);
  };


  export default {
    saveReportsAttachments,
    deleteReportsAttachments,
    getReportsAttachments,
  };