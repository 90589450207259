
export function getColumnFields(columns) {

    const columnFields = columns.map(item => {
        if(item.field === undefined){
            return
        }
        if(item.field && item.type === 'select') {
            return { field: item.field, type: item.type, field_id: item.field_id, list: item.list } 

        } else if(item.field && item.type === 'checkbox') {

            return { field: item.field, type: item.type }

        } else if(item.field && item.type === 'date') {

            return { field: item.field, type: item.type }

        }
        
        else {
            return { field: item.field, type: 'text' }
        }
    }).filter(item => item && item.field !== undefined  && item.field !== null )

    return columnFields
}

export function getSearchFieldObject(columnFields) {

    const sfields = {}
    columnFields.forEach(item => {

        if(item.hasOwnProperty('field_id') && sfields[item.field_id]) {
            sfields[item.field_id] = ""
        } else {
            sfields[item.field] = ""
        }
       
    })
    return sfields;

}