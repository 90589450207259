import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./reducers";
import phase from "./components/Admin/Phase";
import Title from "./components/Admin/Title";
import Unit from "./components/Admin/Unit";
import Currency from "./components/Admin/Currency";
import AddCountry from "./components/Admin/AddCountry";
import Broker from "./components/Admin/Broker";
import ShippingType from "./components/Admin/ShippingType";
import Incoterms from "./components/Admin/Incoterms";
import ImporterOfRecord from "./components/Admin/ImporterOfRecord";
import Container from "./components/Admin/Container";
import Header from "./components/HeaderComponent/header";
import Footer from "./components/FooterComponent/footer";
import NotAuthorized from "./components/NotAuthorizedComponent/notauthorized";
import Logistics from "./components/Admin/LogisticsPartner";
import React from "react"

//import ViewRegisgtrations from "./components/RegistrationComponent/viewRegistrations";

import Navbar from "./components/HeaderComponent/navBar";
//import Home from "./components/HomeComponent/home";
import DestinationAddress from "./components/destinationAddress";
import FinalRecipients from "./components/recipients";
import Shippings from "./components/Admin";
import Recipients from "./components/Admin/Recipient";
import DistributionTemperature from "./components/AdminRestComponent";
//import Recipients from "./components/Admin/Recipients";
import CountryCatalogue from "./components/Admin/Country";
import RequestDeputy from "./components/RequestByDeputies";
import RegisteredUsers from "./components/RegisteredUsers";
import Home from "./components/shippingRequest/Home";
import Address from "./components/Admin/Address";
import ApprovelAddress from "./components/approvals/Address";
import ApprovelMyAddress from "./components/MyApplications/Address";
import AddDeputies from "./components/deputies";
import EditMaterial from "./components/Admin/materials/EditMaterial";
import ViewMaterial from "./components/Admin/materials/ViewMaterial";
import Materials from "./components/Admin/materials/Materials";
import ApproveMaterials from "./components/approvals/Materials/ApproveMaterials";
import ViewApprovalMaterial from "./components/approvals/Materials/ViewApprovalMaterial";
import ShippingMeterials from "./components/shippingMaterials";
import ShippingRequest from "./components/shippingRequest";
import RecipientsApproveComponent from "./components/approvals/Recipients/RecipientsApproveContainer";
import ProcessShippingRequestContainerContainer from "./components/processShippingRequest/ProcessShippingRequestContainerContainer";
import CommentsContainer from "./components/Comments/CommentsContainer";
import ProcessShippingRequestLogContainer from "./components/processShippingRequest/processShippingRequestLog/ProcessShippingRequestLogContainer";
import ConfirmPickupContainer from "./components/processShippingRequest/confirmPickup/ConfirmPickupContainer";
import MyApplicationRecipientsContainer from "./components/MyApplications/Recipients/MyApplicationRecipientsContainer";
import MyApplicationMaterialsComponent from "./components/MyApplications/Materials/MyApplicationMaterialsComponent";
import Quries from "./components/quries/countryBasedListing";
import Contact from "./components/contact";
import { USERROLES } from "./constants";
import ShipmentDocumentationFileContainer from './components/ShipmentDocumentation/ShipmentDocumentationContainer';
import ProcessingDocumentationFileContainer from "./components/ProcessingDocumentation/ProcessingDocumentationContainer";
import RegulatoryReviewer from "./components/RegulatoryReviewer/RegulatoryContainer";
import IdleTimer from "react-idle-timer";

import InvoiceContainer from "./components/Invoice/InvoiceContainer";
import { useMsal } from "@azure/msal-react";
const store = configureStore();

const Routs = ({ currentAccount }) => {
  const { instance } = useMsal();
  //"cb3bf7c7-1210-4156-a51c-f21ea5b2b185";//user's user id
  var userType = JSON.parse(localStorage.getItem("userType"));
  // eslint-disable-next-line no-unused-vars
  var idleTimer = null;
  //Timeout start
  const handleOnAction = () => {
    // console.log('user did something', event)
  };

  const handleOnActive = () => {
    // console.log('user is active', event)
    //console.log('time remaining', this.state.idleTimer.getRemainingTime())
  };

  const handleOnIdle = () => {
    //let formErrors = { ...this.state.formErrors };
    //let timer = this.state.idleTimer;
    // console.log('user is idle', event)
    instance.logoutRedirect();
    localStorage.clear();
    //console.log('last active', timer.getLastActiveTime())
  };
  if (userType.length > 0) {
    return (
      <Provider store={store}>
        <IdleTimer
          ref={(ref) => {
            idleTimer = ref;
          }}
          timeout={3600000}
          onActive={handleOnActive}
          onIdle={handleOnIdle}
          onAction={handleOnAction}
          debounce={250}
        />
        <Header currentAccount={currentAccount} />
        <Router>
          <Navbar isAdmin={1} />
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route
              path="/comments"
              component={CommentsContainer.CommentsComponent}
            ></Route>
            <Route
              path="/newShippingComments"
              component={CommentsContainer.NewRequestCommentsComponent}
            ></Route>
            <Route
              path="/alerts"
              component={CommentsContainer.AlertTagNavbarComponent}
            ></Route>
            <Route
              path="/reports"
              component={ProcessShippingRequestContainerContainer.ReportsFile}
            ></Route>
            <Route
              path="/shipmentdocumentation"
              component={ShipmentDocumentationFileContainer.ShipmentDocumentationFile}
            ></Route>
            <Route
              path="/processingdocumentation"
              component={ProcessingDocumentationFileContainer.ProcessingDocumentationFile}
            ></Route>
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/address"
                component={Address.AddressComponent}
              ></Route>
            ) : (
              <Route path="/address" component={NotAuthorized}></Route>
            )}
            {
              <Route
                path="/reviewerList"
                component={RegulatoryReviewer.ReviewerTab}
              ></Route>
            }
            {userType.includes(USERROLES.REGULATORYREVIEWER1) === true ||
              userType.includes(USERROLES.REGULATORYREVIEWER2) === true ? (
              <Route
                exact
                path="/regulatoryReviewer"
                component={RegulatoryReviewer.RegulatoryReviewer}
              ></Route>
            ) : (
              <Route
                exact
                path="/regulatoryReviewer"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.REGULATORYREVIEWER1) === true ||
              userType.includes(USERROLES.REGULATORYREVIEWER2) === true ? (
              <Route
                exact
                path="/regulatoryReviewer/edit"
                component={ShippingRequest}
              ></Route>
            ) : (
              <Route
                exact
                path="/regulatoryReviewer/edit"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.ADMIN) === true ? (
              <Route
                exact
                path="/approve/materials"
                component={ApproveMaterials}
              ></Route>
            ) : (
              <Route
                exact
                path="/approve/materials"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/recipients"
                component={Recipients.RecipientsComponent}
              ></Route>
            ) : (
              <Route path="/recipients" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/add/recipients"
                component={Recipients.AddRecipientsComponent}
              ></Route>
            ) : (
              <Route path="/add/recipients" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.READONLY) === true ? (
              <Route
                exact
                path="/process/shipping"
                component={
                  ProcessShippingRequestContainerContainer.ProcessShippingRequest
                }
              ></Route>
            ) : (
              <Route
                exact
                path="/process/shipping"
                component={NotAuthorized}
              ></Route>
            )}
            <Route
              exact
              path="/shippings/log/:id"
              component={
                ProcessShippingRequestLogContainer.ProcessShippingRequestLog
              }
            />
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/view/approve/material/:materialId"
                component={ViewApprovalMaterial}
              ></Route>
            ) : (
              <Route
                exact
                path="/view/approve/material/:materialId"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.ADMIN) === true ? (
              <Route
                exact
                path="/approveAddress"
                component={ApprovelAddress.AddressComponent}
              ></Route>
            ) : (
              <Route
                exact
                path="/approveAddress"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.ADMIN) === true ? (
              <Route
                exact
                path="/approve/recipients"
                component={
                  RecipientsApproveComponent.RecipientsApproveComponent
                }
              ></Route>
            ) : (
              <Route
                exact
                path="/approve/recipients"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/view/approve/recipient/:recipientId"
                component={RecipientsApproveComponent.CheckRecipientsComponent}
              ></Route>
            ) : (
              <Route
                exact
                path="/view/approve/recipient/:recipientId"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/approvelInspectAddress/:addressId"
                component={ApprovelAddress.InspectAddress}
              ></Route>
            ) : (
              <Route
                path="/approvelInspectAddress/:addressId"
                component={NotAuthorized}
              ></Route>
            )}
            <Route exact path="/shipping" component={ShippingRequest}></Route>;
            <Route
              path="/shipping/:shippingRequestIdCurrent"
              component={ShippingRequest}
            ></Route>
            ;
            <Route
              path="/previewShipping/:shippingRequestIdCurrent"
              component={ShippingRequest}
            ></Route>
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.APPLICANT) === true ? (
              <Route exact path="/myShippings" component={Shippings}></Route>
            ) : (
              <Route
                exact
                path="/myShippings"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.APPLICANT) === true ? (
              <Route
                exact
                path="/requestByDelegate"
                component={RequestDeputy.RequestDeputyComponent}
              ></Route>
            ) : (
              <Route
                exact
                path="/requestByDelegate"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.APPLICANT) === true ? (
              <Route
                exact
                path="/requestByDelegate/edit"
                component={ShippingRequest}
              ></Route>
            ) : (
              <Route
                exact
                path="/requestByDelegate/edit"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route exact path="/materials" component={Materials}></Route>
            ) : (
              <Route exact path="/materials" component={NotAuthorized}></Route>
            )}
            <Route exact path="/material" component={ShippingMeterials}></Route>
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/distributiontemperature"
                component={DistributionTemperature}
              ></Route>
            ) : (
              <Route
                path="/distributiontemperature"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/phase" component={phase}></Route>
            ) : (
              <Route path="/phase" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/container" component={Container}></Route>
            ) : (
              <Route path="/container" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/title" component={Title}></Route>
            ) : (
              <Route path="/title" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/incoterm" component={Incoterms}></Route>
            ) : (
              <Route path="/incoterm" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/units" component={Unit}></Route>
            ) : (
              <Route path="/units" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/logistics" component={Logistics}></Route>
            ) : (
              <Route path="/logistics" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/currency" component={Currency}></Route>
            ) : (
              <Route path="/currency" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/add-country" component={AddCountry}></Route>
            ) : (
              <Route path="/add-country" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/broker" component={Broker}></Route>
            ) : (
              <Route path="/broker" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route path="/shippingType" component={ShippingType}></Route>
            ) : (
              <Route path="/shippingType" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/importerOfRecord"
                component={ImporterOfRecord}
              ></Route>
            ) : (
              <Route path="/importerOfRecord" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/countrycatalouge"
                component={CountryCatalogue.CountryCatalogueComponent}
              ></Route>
            ) : (
              <Route path="/countrycatalouge" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/registereduser"
                component={RegisteredUsers.RegisteredUsersComponent}
              ></Route>
            ) : (
              <Route path="/registereduser" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.REGULATORYREVIEWER1) === true ||
              userType.includes(USERROLES.REGULATORYREVIEWER2) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ||
              userType.includes(USERROLES.READONLY) === true ||
              userType.includes(USERROLES.APPLICANT) === true  ? (
              <Route
                exact
                path="/view/material/:materialId"
                component={ViewMaterial}
              ></Route>
            ) : (
              <Route
                exact
                path="/view/material/:materialId"
                component={NotAuthorized}
              />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/material/edit/:materialId"
                component={EditMaterial}
              ></Route>
            ) : (
              <Route
                exact
                path="/material/edit/:materialId"
                component={NotAuthorized}
              />
            )}
            {userType.includes(USERROLES.APPLICANT) === true ||
              userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/new/material"
                component={EditMaterial}
              ></Route>
            ) : (
              <Route exact path="/new/material" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ? (
              <Route
                exact
                path="/shippingRequests/edit"
                component={ShippingRequest}
              ></Route>
            ) : (
              <Route
                exact
                path="/shippingRequests/edit"
                component={NotAuthorized}
              />
            )}
            <Route exact path="/shippingRequests" component={Shippings}></Route>
            {userType.includes(USERROLES.APPLICANT) === true ||
              userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/addAddress"
                component={Address.AddAddressComponent}
              ></Route>
            ) : (
              <Route exact path="/addAddress" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/inspectAddress"
                component={Address.InspectAddress}
              ></Route>
            ) : (
              <Route exact path="/inspectAddress" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/editRecipients"
                component={Recipients.AddRecipientsComponent}
              ></Route>
            ) : (
              <Route exact path="/editRecipients" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.APPLICANT) === true ||
              userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/addRecipients"
                component={Recipients.AddRecipientsComponent}
              ></Route>
            ) : (
              <Route exact path="/addRecipients" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/inspectRecipients"
                component={Recipients.InspectRecipientsComponent}
              ></Route>
            ) : (
              <Route
                exact
                path="/inspectRecipients"
                component={NotAuthorized}
              />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/queriesCountry"
                component={Quries.CountryComponent}
              />
            ) : (
              <Route exact path="/queriesCountry" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/queriesCountryTwoWeeks"
                component={Quries.CountryTwoWeeksComponent}
              />
            ) : (
              <Route
                exact
                path="/queriesCountryTwoWeeks"
                component={NotAuthorized}
              />
            )}
            {userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ||
              userType.includes(USERROLES.ADMIN) === true ? (
              <Route
                path="/approvelEditAddress"
                component={ApprovelAddress.AddAddressComponent}
              ></Route>
            ) : (
              <Route
                exact
                path="/approvelEditAddress"
                component={NotAuthorized}
              />
            )}
            {userType.includes(USERROLES.APPLICANT) === true ||
              userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ? (
              <Route path="/addDelegates" component={AddDeputies}></Route>
            ) : (
              <Route exact path="/addDelegates" component={NotAuthorized} />
            )}
            {userType.includes(USERROLES.APPLICANT) === true ||
              userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                path="/finalRecipients"
                component={FinalRecipients}
              ></Route>
            ) : (
              <Route exact path="/finalRecipients" component={NotAuthorized} />
            )}
            <Route exact path="/contact" component={Contact}></Route>
            {userType.includes(USERROLES.PROCESSMANAGER) === true ? (
              <Route
                exact
                path="/process/shipping/confirm/pickup/:id"
                component={ConfirmPickupContainer.ConfirmPickup}
              />
            ) : (
              <Route
                exact
                path="/process/shipping/confirm/pickup/:id"
                component={NotAuthorized}
              />
            )}
            {userType.includes(USERROLES.APPLICANT) === true ? (
              <Route
                exact
                path="/myAddress"
                component={ApprovelMyAddress.AddressComponent}
              ></Route>
            ) : (
              <Route exact path="/myAddress" component={NotAuthorized}></Route>
            )}
            {userType.includes(USERROLES.APPLICANT) === true ? (
              <Route
                exact
                path="/my/application/recipients"
                component={
                  MyApplicationRecipientsContainer.MyApplicationRecipientsComponent
                }
              />
            ) : (
              <Route
                exact
                path="/my/application/recipients"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.APPLICANT) === true ? (
              <Route
                exact
                path="/my/application/materials"
                component={MyApplicationMaterialsComponent}
              />
            ) : (
              <Route
                exact
                path="/my/application/materials"
                component={NotAuthorized}
              ></Route>
            )}
            {userType.includes(USERROLES.APPLICANT) === true ||
              userType.includes(USERROLES.PROCESSMANAGER) === true ||
              userType.includes(USERROLES.ADMIN) === true ||
              userType.includes(USERROLES.SYSTEMADMINISTRATOR) === true ? (
              <Route
                exact
                path="/destinationAddr"
                component={DestinationAddress}
              ></Route>
            ) : (
              <Route
                exact
                path="/destinationAddr"
                component={NotAuthorized}
              ></Route>
            )}
            <Route path="/invoice" component={InvoiceContainer}></Route>
          </Switch>
        </Router>
        <Footer />
      </Provider>
    );
  } else {
    return (
      <Provider store={store}>
        {/* <Header /> */}
        <Router>
          <Navbar isAdmin={1} />
          <Switch>
            <Route exact path="/" component={NotAuthorized}></Route>
            <Route path="/address" component={NotAuthorized}></Route>
            <Route
              exact
              path="/approve/materials"
              component={NotAuthorized}
            ></Route>
            <Route path="/reviewerList" component={NotAuthorized}></Route>
            <Route path="/regulatoryReviewer" component={NotAuthorized}></Route>
            <Route path="/recipients" component={NotAuthorized}></Route>
            <Route path="/add/recipients" component={NotAuthorized}></Route>
            <Route
              exact
              path="/process/shipping"
              component={NotAuthorized}
            ></Route>
            <Route exact path="/shippings/log/:id" component={NotAuthorized} />
            <Route
              exact
              path="/view/approve/material/:materialId"
              component={NotAuthorized}
            ></Route>
            <Route
              exact
              path="/approveAddress"
              component={NotAuthorized}
            ></Route>
            <Route
              exact
              path="/approve/recipients"
              component={NotAuthorized}
            ></Route>
            <Route
              exact
              path="/view/approve/recipient/:recipientId"
              component={NotAuthorized}
            ></Route>
            <Route
              path="/approvelInspectAddress/:addressId"
              component={NotAuthorized}
            ></Route>
            <Route path="/shipping" component={NotAuthorized}></Route>;
            <Route exact path="/myShippings" component={NotAuthorized}></Route>;
            <Route path="/requestByDelegate" component={NotAuthorized}></Route>;
            <Route exact path="/materials" component={NotAuthorized}></Route>
            <Route exact path="/material" component={NotAuthorized}></Route>
            <Route
              path="/distributiontemperature"
              component={NotAuthorized}
            ></Route>
            <Route path="/phase" component={NotAuthorized}></Route>
            <Route path="/title" component={NotAuthorized}></Route>
            <Route path="/countrycatalouge" component={NotAuthorized}></Route>
            <Route path="/registereduser" component={NotAuthorized}></Route>
            <Route
              exact
              path="/view/material/:materialId"
              component={NotAuthorized}
            ></Route>
            <Route
              exact
              path="/material/edit/:materialId"
              component={NotAuthorized}
            ></Route>
            <Route exact path="/new/material" component={NotAuthorized}></Route>
            <Route
              path="/shippingRequests/edit"
              component={NotAuthorized}
            ></Route>
            <Route
              exact
              path="/shippingRequests"
              component={NotAuthorized}
            ></Route>
            <Route path="/addAddress" component={NotAuthorized}></Route>
            <Route path="/inspectAddress" component={NotAuthorized}></Route>
            <Route path="/editRecipients" component={NotAuthorized}></Route>
            <Route path="/addRecipients" component={NotAuthorized}></Route>
            <Route path="/inspectRecipients" component={NotAuthorized}></Route>
            <Route exact path="/queriesCountry" component={NotAuthorized} />
            <Route
              exact
              path="/queriesCountryTwoWeeks"
              component={NotAuthorized}
            />
            <Route
              path="/approvelEditAddress"
              component={NotAuthorized}
            ></Route>
            <Route path="/addDelegates" component={NotAuthorized}></Route>
            <Route path="/destinationAddr" component={NotAuthorized}></Route>
            <Route path="/finalRecipients" component={NotAuthorized}></Route>
            <Route exact path="/contact" component={NotAuthorized}></Route>
            <Route
              exact
              path="/process/shipping/confirm/pickup/:id"
              component={NotAuthorized}
            />
            <Route path="/addAddress" component={NotAuthorized}></Route>
            <Route path="/addRecipients" component={NotAuthorized}></Route>
            <Route exact path="/myAddress" component={NotAuthorized}></Route>
            <Route
              exact
              path="/my/application/recipients"
              component={NotAuthorized}
            />
            <Route
              exact
              path="/my/application/materials"
              component={NotAuthorized}
            />
            <Route path="/destinationAddr" component={NotAuthorized}></Route>
          </Switch>
        </Router>
        <Footer />
      </Provider>
    );
  }
};
export default Routs;
