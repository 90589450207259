import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';

import CustomizedTables from '../common/CustomizedTables';
import { INSPECT, SUBMIT, HAND_BACK, STATUS, CANCEL_REQUEST, CLOSE }  from '../../constants';
import DeputiesCard from './preview/DeputiesCard';
import { USERROLES } from "../../constants"
import { useTranslation } from 'react-i18next';

const styles = {
    header: {
      width: "100%",
      height: 30,
      backgroundColor: '#dfdfdf',
    },
    Addbtn: {
        float: 'right',
    },
    addBtnPlus: {
        textAlign: 'right',
        padding: '0px',
        marginTop: '-16px',
        postion: 'absolute'
    }
  };

const DeputiesComponent = ( props ) => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    const { t } = useTranslation();
    const  {
        classes, 
        handleAddDiputies, 
        shippingDeputySelect,
        shippingActionType,
        shippingRequestDetails,
        deputyShippingDetails,
    } = props
    const status = shippingRequestDetails.length > 0 && shippingRequestDetails[0].status

    const isreviewervalid = (obj) =>{
        if(obj?.is_active == null || Object.keys(obj).length == 0) return true
          if(obj?.is_active == true){
            return true
          }
          else{
            return false;
          }
      }

    if(shippingActionType === INSPECT ) {
        return(
            <DeputiesCard {...props } />
        )
    }

    if(shippingActionType === SUBMIT && status === STATUS.INPUT ) {
        return(
            <DeputiesCard {...props } />
        )
    }

    if(
        shippingActionType === HAND_BACK 
        || shippingActionType === CANCEL_REQUEST
        || shippingActionType === CLOSE
    ) {

        return(
            <DeputiesCard {...props } />
        )
    }

    return(
        <Card variant="outlined" className='delatebutton' style={{border:(isreviewervalid(deputyShippingDetails[0]))? "":"3px solid orange"}}>
            <CardHeader 
                title= {
                    <Typography className='heading_box'> {t('shipping_request.deputies')}<span className="req">*</span></Typography>
                }
                className={classes.header}
                
                action={
                   
                    <Button data-name="add_delegates_button" disabled={!(userType.includes(USERROLES.PROCESSMANAGER) || userType.includes(USERROLES.APPLICANT) || userType.includes(USERROLES.ADMIN)) || status === STATUS.IN_DELIVERY} className='mt-1'   onClick={handleAddDiputies}>
                        <AddBoxIcon/>
                    </Button>
                  }
                  
                />
                <CardContent>
                    <CustomizedTables className='pl-2' 
                    list = {shippingDeputySelect}
                    deputyShippingDetails={deputyShippingDetails}
                    message = {t('shipping_request.deputy_selection_warning')}
                    />
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(DeputiesComponent);