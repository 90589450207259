import types from "./types";

const initialState = {
  allCountries:[],
  titleData: [],
  loader: true,
  unitData: [],
  currenctData: [],
  brokerData: [],
  shippingTypeData: [],
  incotermsData: [],
  importer: [],
};
const TitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_COUNTRIES:
      return{
        ...state,
        allCountries:action.payload
      }
    case types.GET_TITLE:
      return {
        ...state,
        titleData: action.payload,
      };
    case types.GET_UNITS:
      return {
        ...state,
        unitData: action.payload,
      };
    case types.GET_CURRENCY:
      return {
        ...state,
        currenctData: action.payload,
      };
    case types.GET_BROKER:
      return {
        ...state,
        brokerData: action.payload,
      };
    case types.GET_SHIPPING_TYPE:
      return {
        ...state,
        shippingTypeData: action.payload,
      };
    case types.GET_INCOTERMS:
      return {
        ...state,
        incotermsData: action.payload,
      };
    case types.GET_IMPORTER_LIST:
      return {
        ...state,
        importer: action.payload,
      };
    default:
      return state;
  }
};

export default TitleReducer;
