import { makeStyles } from "@material-ui/core/styles";

export const addressContainerStyles = makeStyles((theme) => ({
  container: {
    marginTop: "15px",
  },
  searchCard: {
    minHeight: "400px",
  },
  searchBar: {},
  searchTextField: {
    backgroundColor: "#ffff",
    height: "100%",
    fontSize: "1rem",
    width: "96%",
    float: "right",
  },
  searchTitle: {
    color: "#EE2E5D",
    margin: "30px 0px 15px 30px",
    fontSize: "1rem",
    fontWeight: "600",
  },

  searchBt: {
    "&:hover": {
      backgroundColor: "#ee2e5d",
    },
    backgroundColor: "#ee2e5d",
    textTransform: "none",
    marginRight: "10px",
    color: "#ffff",
    borderRadius: 0,
    fontSize: "1rem",
    marginLeft: "10px",
  },
  cancelBt: {
    "&:hover": {
      backgroundColor: "#4e4e4e",
    },
    backgroundColor: "#4e4e4e",
    textTransform: "none",
    marginRight: "10px",
    color: "#ffff",
    borderRadius: 0,
    fontSize: "1rem",
  },
  selectOrder: {
    marginLeft: "10px",
  },
  wildCard: {
    marginLeft: "4px",
    marginRight: "4px",
  },
  errorMsg: {
    display: "flex",
    justifyContent: "center",
  },
}));
export const addressSearchStyles = makeStyles((theme) => ({
  textLableAsterisk: { color: "#ff0000" },
  selectWidth: { maxWidth: "210px" },
  actionButtonsTop: {
    marginTop: "7px",
    marginBottom: "2px",
    float: "right",
    marginRight: "unset",
  },
  textLable: {
    width: "173px !important",
    marginTop: "25px",
    marginRight: "10px",
    fontWeight: "600",
  },
  textLables: {
    width: "173px !important",
    marginRight: "10px",
    fontWeight: "600",
  },
  cardActionButtons: {
    marginBottom: "45px",
    float: "right",
  },
  container: {
    marginTop: "15px",
  },
  searchCard: {
    minHeight: "400px",
  },
  searchBar: {},
  tableRadio: {
    "-webkitTransform": "scale(1.5) !important",
  },
  searchTextField: {
    backgroundColor: "#ffff",
    height: "100%",
    fontSize: "1rem",
    width: "96%",
    float: "right",
  },
  searchTitle: {
    color: "#EE2E5D",
    margin: "30px 0px 15px 30px",
    fontSize: "1rem",
    fontWeight: "600",
  },

  reloadBt: {
    backgroundColor: "#424242 !important",
    marginLeft: "15px !important",
  },
  cancelBt: {
    "&:hover": {
      backgroundColor: "#4e4e4e",
    },
    backgroundColor: "#4e4e4e",
    textTransform: "none",
    marginRight: "10px",
    color: "#ffff",
    borderRadius: 0,
    fontSize: "1rem",
  },
  selectOrder: {
    marginLeft: "10px",
  },
  wildCard: {
    marginLeft: "4px",
    marginRight: "4px",
  },
  errorMsg: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const useHeaderStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  header: {
    height: "80px",
    "@media (max-width: 850px)": {
      height: "96px",
    },
  },
  firstHeader: {
    height: "138px",
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
    top: 10,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#0072CE",
    position: "sticky",
  },
  avatar: {
    position: "static",
    marginTop: "10px",
    float: "right",
    "@media (max-width: 450px)": {
      display: "none",
    },
  },
  avatarMobile: {
    display: "none",
    position: "static",
    marginTop: "10px",
    float: "right",
    "@media (max-width: 450px)": {
      display: "flex",
      marginTop: "2px",
    },
  },
  nameMobile: {
    "@media (max-width: 450px)": {
      display: "none",
    },
  },
  homeHeading: {
    marginTop: "8px",
    fontWeight: "600",
    fontSize: "1.8rem",
    "@media (max-width: 450px)": {
      fontSize: "1rem",
    },
  },
  appMenu: {
    backgroundColor: "#FFFFFF",
    paddingLeft: "20px",
    paddingTop: "5px",
    "@media (max-width: 450px)": {
      paddingLeft: "0px",
    },
  },
  appMenuItem1: {
    marginRight: "25px",
    color: "#1769c1",
    padding: " 6px",
    textAlign: "start",
    fontSize: "1rem",
    textAlign: "center",
    "@media (max-width: 450px)": {
      marginLeft: "10px",
    },
  },
  appMenuLink: {
    textDecoration: "none",
  },
  appMenuItem2: {
    marginRight: "15px",
    color: "#FFFFFF",
    backgroundColor: "#1769c1",
    padding: "5px 8px",
    borderRadius: "6px",
    fontSize: "1rem",
    textAlign: "center",
    "@media (max-width: 450px)": {
      marginLeft: "10px",
    },
  },
  avatarBt: {
    marginTop: "10px",
    color: "#ffff",
    textTransform: "none",
    "@media (max-width: 450px)": {
      marginTop: "0px",
    },
  },
  avatarMenu: {
    display: "flex",
    justifyContent: "end",
  },
  previewBtContainer: {
    float: "right",
  },
  previewBt: {
    color: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 450px)": {
      paddingTop: "10px",
      display: "block",
    },
  },
  headerImage: {
    width: "100px",
    "@media (max-width: 450px)": {
      width: "80px",
    },
  },
}));

export const usePageContentStyles = makeStyles((theme) => ({
  sideMenu: {
    marginLeft: "5%",
    marginTop: "20px",
    marginBottom: "10px",
  },
  pageContent: {
    marginLeft: "5%",
    marginRight: "2%",
    marginTop: "20px",
    marginBottom: "10px",
  },
  sideMenuImage: {
    marginBottom: "20px",
  },
  sideMenuTitle: {
    marginBottom: "10px !important",
    fontWeight: "600",
    borderBottom: "1px solid black",
    paddingBottom: "6px",
    marginRight: "20%!important",
    "@media (max-width: 850px)": {
      marginTop: "0px !important",
    },
  },
  navLinks: {
    marginBottom: "10px !important",
    textDecoration: "none",
    color: "#000",
    marginRight: "8px !important",
    marginLeft: "20px !important",
    "&:hover": {
      color: "#0072CE !important",
    },
    "@media (max-width: 850px)": {
      marginTop: "0px !important",
      marginLeft: "18px !important",
    },
  },
  navLinksContainer: {
    marginBottom: "10px !important",
    textDecoration: "none",
    color: "#000",
    marginRight: "8px !important",
    "@media (max-width: 850px)": {
      marginTop: "0px !important",
    },
  },
}));

export const footerStyles = makeStyles((theme) => ({
  footerCard: {
    marginTop: "58px",
  },
}));
export const useScarchPageStyles = makeStyles((theme) => ({
  searchTopResult: {
    position: "absolute",
    left: 0,
    margin: "30px 30px 10px 60px",
    fontWeight: 600,
    color: "#EE2E5D",
    "@media (max-width: 450px)": {
      display: "contents",
    },
  },
  resultItem: {
    marginLeft: "30px",
    marginRight: "30px",
  },
  resultTitle: {
    fontSize: "1.2rem",
    color: "#0072CE",
  },
  resultIcon: {
    width: "15px",
    marginRight: "10px",
  },
  resultContent: {
    marginTop: "10px",
    textAlign: "justify",
  },
  resultDate: {
    color: "gray",
    marginTop: "8px",
    display: "flex",
    fontSize: ".8rem",
  },
  resultDateIcon: {
    marginRight: "8px",
  },
  searchTopResultContainer: {
    display: "flex",
  },
  searchResultSelect: {
    width: "150px",
    marginTop: "22px",
    marginRight: "45px",
    border: "solid 1px",
    borderBottom: "none",
    padding: "0px 5px",
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "solid 1px !important",
    },
  },
  searchResultSelectLabel: {
    marginTop: "28px",
    marginRight: "10px",
    fontWeight: 600,
    fontSize: ".9rem",
  },
  hitCount: {
    color: "#000000",
    marginTop: "18px !important",
    height: "35px",
    display: "flex",
    alignItems: "center",
    border: "solid 1.5px #d3c7c7",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "9px",
    "@media (max-width: 450px)": {
      padding: "0px",
      marginRight: "2px",
      marginLeft: "2px",
      fontSize: ".9rem",
      width: "100px",
    },
  },
  countNumber: {
    padding: "4px",
    color: "#ffff",
    backgroundColor: "#037605",
    borderRadius: "5px",
    marginLeft: "10px",
  },
}));

export const useAdvancedScarchPageStyles = makeStyles((theme) => ({
  searchCard: {
    minHeight: "400px",
  },
  searchBar: {},
  searchTextField: {
    backgroundColor: "#ffff",
    height: "100%",
    fontSize: "1rem",
    width: "96%",
    float: "right",
  },
  searchTitle: {
    color: "#EE2E5D",
    margin: "30px 0px 15px 30px",
    fontSize: "1rem",
    fontWeight: "600",
  },

  searchBt: {
    "&:hover": {
      backgroundColor: "#ee2e5d",
    },
    backgroundColor: "#ee2e5d",
    textTransform: "none",
    marginRight: "10px",
    color: "#ffff",
    borderRadius: 0,
    fontSize: "1rem",
    marginLeft: "10px",
  },
  cancelBt: {
    "&:hover": {
      backgroundColor: "#4e4e4e",
    },
    backgroundColor: "#4e4e4e",
    textTransform: "none",
    marginRight: "10px",
    color: "#ffff",
    borderRadius: 0,
    fontSize: "1rem",
  },
  selectOrder: {
    marginLeft: "10px",
  },
  wildCard: {
    marginLeft: "4px",
    marginRight: "4px",
  },
  errorMsg: {
    display: "flex",
    justifyContent: "center",
  },
}));
