import { React, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import LogisticsViewData from "./LogisticsViewData";
import LogisticsData from "./LogisticsData";

const Logistics = (props) => {
  const { actions, logisticsPartnerData } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    actions.getLogisticsParterDictionaryData();
    setIsLoading(false);
  }, []);
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={1}>
            <label className="title_main mt-1 ml-4">{t("logisticsPartner.name")}</label>
          </Stack>
        </Box>

        <Container maxWidth="xl">
          <Card variant="outlined">
            <CardHeader title={t("logisticsPartner.SearchPlaceholder")} />
            <Divider />
            <CardContent>
              <LogisticsData onSearch={(data) => setSearchFilter(data)} />
            </CardContent>
          </Card>
          <br />
          <LogisticsViewData
            logisticsPartnerData={logisticsPartnerData}
            actions={actions}
            searchFilter={searchFilter}
            setIsLoading={setIsLoading}
          />
          <br />
        </Container>
        <br />
        <br />
        <br />
      </LoadingOverlay>
    </>
  );
};

export default Logistics;
