import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "../common/PageLayout";
import RegisteredUsersListingComponent from "./RegisteredUsersListingComponent";
import RegisteredUsersSearch from "./RegisteredUsersSearch";
const RegisteredUsersComponent = (props) => {
  const { actions, ShippingsTwoWeeks, countries, allUsers } = props;
  const [searchFilter, setSearchFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    actions.getAllUsers().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <PageLayout
        title={ t("RegisteredUser.RegisteredUser")}
        searchComponent={
          <RegisteredUsersSearch
            countries={countries}
            onSearch={(data) => setSearchFilter(data)}
            searchKey={"regUserSearch"}
            Actions = {actions}
          />
        }
        mainComponent={
          <RegisteredUsersListingComponent
            listData={allUsers}
            actions={actions}
            searchFilter={searchFilter}
            setIsLoading={setIsLoading}
          />
        }
        isLoading={isLoading}
      />
    </>
  );
};

export default RegisteredUsersComponent;
