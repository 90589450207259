import types from "./types";

const initialState = {
  reportsData: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_REPORTS_DATA: {
      return {
        ...state,
        reportsData: action.payload,
      };
    }
    case types.GET_REPORTS_DATA: {
      return {
        ...state,
        reportsData: action.payload,
      };
    }
    case types.DELETE_REPORTS_DATA: {
        return {
          ...state,
          reportsData: action.payload,
        };
      }
    default:
      return state;
  }
};

export default reducer;
