import React from "react";
import { useTranslation } from "react-i18next";
function NotAuthorized() {
    const { t } = useTranslation();
    return (
        <div style={{width:'50%',margin:'0 auto',marginTop:'15%', textAlign:'center'}}>
            <div class="card">
                <div class="card-body">
                    <div class="mb-2">
                        <img src="" alt="" />
                    </div>
                    {t("notAuthorized.title")}
      </div>
            </div>
        </div>)
}
export default NotAuthorized;