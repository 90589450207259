

import React, {useEffect} from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from "redux";

import materialsRedux from "../../../redux/materials";

//Dummy component to load data.
function MaterialDataLoader({ actions }) {

    const history = useHistory();

    useEffect(() => {
        actions.getAllMaterials();
    }, [])

    return (
        <></>
    )
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getAllMaterials: materialsRedux.actions.getAllMaterials
        },
        dispatch
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDataLoader);