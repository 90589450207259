import types from "./types";

const initialState = {
  phasedata: [],
  
};
const PhaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_PHASE:
      return {
        ...state,
        phasedata: action.payload,
        
      };
    default:
      return state;
  }
};

export default PhaseReducer;
