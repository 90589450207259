import types from "./types";
import fetchData from "../util";

const getLogisticsParterDictionaryData = () => {
  return fetchData(types.GET_ALL_LOGISTICS_PARTNER_DETAIL, "getLogisticsParterDictionaryData");
};

const saveLogisticsPartner = (...params) => {
  return fetchData(types.SAVE_LOGISTICS_PARTNER_DETAIL, "saveLogisticsPartner", params);
};

const updateLogisticsPartner = (...params) => {
  return fetchData(types.SAVE_LOGISTICS_PARTNER_DETAIL, "updateLogisticsPartner", params);
};

export default {
  getLogisticsParterDictionaryData
  , saveLogisticsPartner
  , updateLogisticsPartner
};
