import { React, useState, useEffect, Fragment } from "react";
import { useMsal } from "@azure/msal-react";
import {get} from "lodash";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { authContext } from "../../adalConfig";
import TabMenu from "../common/TabMenu";
import { useHistory } from "react-router-dom";
import { USERROLES } from "../../constants";
import LoadingOverlay from "react-loading-overlay";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import { useStyles } from "../common/ThemeStye";

const ReviewerTab = (props) => {
  const classes = useStyles();
  const userType = JSON.parse(localStorage.getItem("userType"));

  const { actions, allregulatory, shippingRequestDetails,shipper,allRecipients } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [reviewRequired, setReviewRequired] = useState("");
  const [originatingCountry, setOriginatingCountry] = useState("");
  const [commentForOriginatingCountry, setCommentForOriginatingCountry] =
    useState("");
  const [receivingCountry, setReceivingCountry] = useState("");
  const [commentForReceivingCountry, setCommentForReceivingCountry] =
    useState("");
  const [specialLabel, setSpecialLabel] = useState("");
  const [selectedLabelValue, setselectedLabelValue] = useState("");
  const [additionalComments, setAdditionalComments] = useState("");
  const [originCountry, setoriginCountry] = useState("");
  const [recieverCountry, setrecieverCountry] = useState("");
  const [commentsForReg1, setCommentsForReg1] = useState("");
  const [commentsForReg2, setCommentsForReg2] = useState("");

  const [status, setStatus] = useState("");

  const [rowwDataShipping, setRowDataShipping] = useState(0);
  const { instance } = useMsal();

  const userId = instance.getActiveAccount().username;
  const [isLoading, setIsLoading] = useState(true);
  const [labeldropdown, setLabelDropDown] = useState([]);
  const [approveOrRejectFlag, setapproveOrRejectFlag] = useState("");
  const [regFlag, setregFlag] = useState("");
  const [regFlagVisible, setregFlagVisible] = useState(false);

  const [createdDatecheckBeforeSave, setCreatedDateCheckBeforeSave] =
    useState("");
  const [createdByBeforeSave, setCreatedByBeforeSave] = useState("");

  const [updatedDatecheckBeforeSave, setUpdatedDateCheckBeforeSave] =
    useState("");
  const [updatedByBeforeSave, setUpdatedByBeforeSave] = useState("");

  useEffect(() => {
    actions.getApproveRegDetails(parseInt(rowwDataShipping)).then((res) => {
      if (res.data.data.length !== 0) {
        setCreatedDateCheckBeforeSave(res.data.data[0].created_on);
        setCreatedByBeforeSave(res.data.data[0].created_by);
        setUpdatedDateCheckBeforeSave(res.data.data[0].updated_on);
        setUpdatedByBeforeSave(res.data.data[0].updated_by);
      }
    });
  }, [actions, rowwDataShipping, status]);
  useEffect(() => {
    setIsLoading(true);
    if (shippingRequestDetails.length !== 0) {
      actions.getAllShippingData().then((res) => {
        if (allregulatory.length !== 0) {
          let rowData =
            allregulatory &&
            allregulatory.filter(
              (x) => x.id == shippingRequestDetails[0].id.toString(),
            );
            let recipient = allRecipients.find(
              (recipient) => recipient.id == shipper[0].recipient_id.toString(),
            );
          setRowDataShipping(shippingRequestDetails[0].id);

          setoriginCountry(recipient?.name_english);

          setrecieverCountry(rowData[0].recievingcountry);

          setStatus(rowData[0].status);
        } else if (res.data.length > 0) {
          let rowData =
            res.data &&
            res.data.filter(
              (x) => x.id == shippingRequestDetails[0].id.toString(),
            );
            let recipient = allRecipients.find(
              (recipient) => recipient.id == shipper[0].recipient_id.toString(),
            );
          setRowDataShipping(shippingRequestDetails[0].id);

          setoriginCountry(recipient?.name_english);

          setrecieverCountry(rowData[0].recievingcountry);

          setStatus(rowData[0].status);
        }
      });
    }

    if (status === "Regulatory Reviewer 1") {
      actions.getApproveRegDetails(parseInt(rowwDataShipping)).then((res) => {
        setregFlagVisible(res.data.data.length !== 0 ? true : false);
      });
    }
    if (
      status === "Regulatory Reviewer 2" ||
      status === "New" ||
      status === "INPUT" ||
      status === "INBOX" ||
      status === "IN_DELIVERY" ||
      status === "Delivered" ||
      status === "PROCESSING" ||
      status === "Processing" ||
      status === "INBOX" ||
      status === "New" ||
      status === "IN_PROGRESS" ||
      status === "READY_FOR_PICKUP" ||
      status === "READY_FOR_DELIVERY" ||
      status === "SHIPER_REVIEW_DONE"
    ) {
      actions.getApproveRegDetails(parseInt(rowwDataShipping)).then((res) => {
        setregFlagVisible(res.data.data.length !== 0 ? true : false);
        if (res.data.data.length !== 0) {
          setReviewRequired(res.data.data[0].is_reg_review_req.toString());
          setoriginCountry(res.data.data[0].origin_country);
          setOriginatingCountry(
            res.data.data[0].reg_req_org_country_met.toString(),
          );
          setCommentForOriginatingCountry(
            res.data.data[0].org_country_comments,
          );
          setrecieverCountry(res.data.data[0].reciever_country);
          setReceivingCountry(
            res.data.data[0].reg_req_rec_country_met.toString(),
          );
          setCommentForReceivingCountry(res.data.data[0].rec_country_comments);
          setSpecialLabel(res.data.data[0].is_special_label_req.toString());
          setselectedLabelValue(res.data.data[0].label_text);
          setAdditionalComments(res.data.data[0].label_inst_comments);
          setCommentsForReg1(res.data.data[0].regulatory_reviewer1_comments);
          setCommentsForReg2(res.data.data[0].regulatory_reviewer2_comments);
        }
      });
    }
    actions.getApproveReg1condition(parseInt(rowwDataShipping)).then((res) => {
      setregFlag(res.data);
    });
    setIsLoading(false);
  }, [actions, rowwDataShipping, status]);

  useEffect(() => {
    setIsLoading(true);
  }, [actions, rowwDataShipping]);

  useEffect(() => {
    actions.getAllReviewerMasterData().then((res) => {
    let labels = res.data;
    labels.sort((obj1,obj2) => {
      let val1 = obj1.value;
      let val2 = obj2.value;
      if(val1 < val2){
        return -1;
      }
      else if (val1 > val2){
return 1;
      }
      else{
        return 0;
      }
    })
      setLabelDropDown(labels);

      setIsLoading(false);
    });
  }, [actions]);

  const handleLabelChange = (selectedLabelValue) => {
    setselectedLabelValue(selectedLabelValue);
  };

  const handleChangeValue = (event) => {
    if (event.target.name === "reviewRequired") {
      setReviewRequired(event.target.value);
    } else if (event.target.name === "originatingCountry") {
      setOriginatingCountry(event.target.value);
    } else if (event.target.name === "commentForOriginatingCountry") {
      setCommentForOriginatingCountry(event.target.value);
    } else if (event.target.name === "ReceivingCountry") {
      setReceivingCountry(event.target.value);
    } else if (event.target.name === "commentForReceivingCountry") {
      setCommentForReceivingCountry(event.target.value);
    } else if (event.target.name === "specialLabel") {
      setSpecialLabel(event.target.value);
    } else if (event.target.name === "additionalComments") {
      setAdditionalComments(event.target.value);
    } else if (event.target.name === "commentsForApprovalOrRejectReg1") {
      setCommentsForReg1(event.target.value);
    } else if (event.target.name === "commentsForApprovalOrRejectReg2") {
      setCommentsForReg2(event.target.value);
    }
  };
  const handleSaveForReg1 = (approveOrReject) => {

    const typShip = shippingRequestDetails.length>0? shippingRequestDetails[0].type_of_shipment : "";
    setapproveOrRejectFlag(approveOrReject);

    try {
      let error = "";
      if (reviewRequired === "true") {
        if (!originatingCountry) {
          error = error + t("regulatoryReview.origin_country_met_error") + ", ";
        }
        if (!commentForOriginatingCountry) {
          error =
            error + t("regulatoryReview.origin_country_comments_error") + ", ";
        }
        if (!receivingCountry) {
          error =
            error + t("regulatoryReview.receiving_country_met_error") + ", ";
        }
        if (!commentForReceivingCountry) {
          error =
            error +
            t("regulatoryReview.receiving_country_comments_error") +
            ", ";
        }
        if (!specialLabel) {
          error = error + t("regulatoryReview.special_label_error") + ", ";
        }
        if (specialLabel === "true" && !selectedLabelValue) {
          error = error + t("regulatoryReview.label_error") + ", ";
        }
        if (
          specialLabel === "true" &&
          selectedLabelValue.value === "Other (enter value in comments)" &&
          !additionalComments
        ) {
          error = error + t("regulatoryReview.additional_comment_error") + ", ";
        }
      }
      if (commentsForReg1.trim().length === 0 && approveOrReject === "reject") {
        error = error + t("regulatoryReview.comments_error") + ", ";
      }

      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req3") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req4"),
        );
      }

      let finalStatus = "";
      if (approveOrReject === "approve" && regFlag.data === true && typShip !== "INNER_EU") {
        finalStatus = "Regulatory Reviewer 2";
      } else if (approveOrReject === "approve" && (regFlag.data === false || typShip === "INNER_EU")) {
        finalStatus = "INBOX";
      } else if (approveOrReject === "reject") {
        finalStatus = "INPUT";
      }

      const req = {
        shippingID: parseInt(rowwDataShipping),
        originCountry: originCountry == null ? "" : originCountry,
        recieverCountry: recieverCountry == null ? "" : recieverCountry,
        reviewRequiredFlag: reviewRequired === "true" ? true : false,
        originatingCountryFlag: originatingCountry === "true" ? true : false,
        commentForOriginatingCountry: commentForOriginatingCountry,
        receivingCountryFlag: receivingCountry === "true" ? true : false,
        commentForReceivingCountry: commentForReceivingCountry,
        specialLabelFlag: specialLabel === "true" ? true : false,
        selectedLabelValue:
          selectedLabelValue.value === undefined
            ? ""
            : selectedLabelValue.value,
        additionalComments: additionalComments,
        reg1Comments: commentsForReg1,
        statusreview: finalStatus,
        createdby: userId,
        reviewer1_approved_By: userId,
      };
      setIsLoading(true);

      actions.getApproveRegDetails(parseInt(rowwDataShipping)).then((res) => {
        if (res.data.data.length === 0)
          actions.saveRegulatoryReveiwerDetails(req).then((res1) => {
            setIsLoading(false);
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            history.push("/regulatoryReviewer");
          });
        else if (
          res.data.data.length !== 0 &&
          (createdDatecheckBeforeSave === "" || createdByBeforeSave === "")
        ) {
          // actions.saveRegulatoryReveiwerDetails(req).then((res1) => {
          setIsLoading(false);
          toast.error(t("commonlabels.reviewer_error"));
          history.push("/regulatoryReviewer");
          // });
        } else if (
          res.data.data.length !== 0 &&
          (res.data.data[0].created_on !== createdDatecheckBeforeSave ||
            res.data.data[0].created_by !== createdByBeforeSave)
        ) {
          setIsLoading(false);
          toast.error(t("commonlabels.reviewer_error"));
          history.push("/regulatoryReviewer");
        } else if (
          res.data.data.length !== 0 &&
          (res.data.data[0].created_on === createdDatecheckBeforeSave ||
            res.data.data[0].created_by === createdByBeforeSave)
        ) {
          actions.saveRegulatoryReveiwerDetails(req).then((res1) => {
            setIsLoading(false);
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            history.push("/regulatoryReviewer");
          });
        }
      });
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };

  const handleSaveForReg2 = (approveOrReject) => {
    setapproveOrRejectFlag(approveOrReject);
    try {
      let error = "";

      if (commentsForReg2 === null && approveOrReject === "reject") {
        error = error + t("regulatoryReview.comments_error") + ", ";
      }

      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req1") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req2"),
        );
      }

      const req = {
        shippingID: parseInt(rowwDataShipping),
        additionalComments: commentsForReg2,
        statusreview: approveOrReject === "approve" ? "INBOX" : "INPUT",
        createdby: userId,
        reviewer2_approved_By: userId,
      };
      setIsLoading(true);

      actions.getApproveRegDetails(parseInt(rowwDataShipping)).then((res) => {
        if (
          res.data.data.length !== 0 &&
          (updatedDatecheckBeforeSave === "" || updatedByBeforeSave === "")
        ) {
          setIsLoading(false);
          toast.error(t("commonlabels.reviewer_error"));
          history.push("/regulatoryReviewer");
        } else if (
          res.data.data.length !== 0 &&
          (res.data.data[0].updated_on !== updatedDatecheckBeforeSave ||
            res.data.data[0].updated_by !== updatedByBeforeSave)
        ) {
          setIsLoading(false);
          toast.error(t("commonlabels.reviewer_error"));
          history.push("/regulatoryReviewer");
        } else if (
          res.data.data.length !== 0 &&
          (res.data.data[0].updated_on === updatedDatecheckBeforeSave ||
            res.data.data[0].updated_by === updatedByBeforeSave)
        ) {
          actions.saveRegulatoryReveiwer2Details(req).then((res) => {
            setIsLoading(false);
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            history.push("/regulatoryReviewer");
          });
        }
      });
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const handleClose = () => {
    history.push("/regulatoryReviewer");
  };
  return (
    <>
      <LoadingOverlay active={isLoading} spinner>
        <TabMenu />

        {status !== "INPUT" && status !== "" ? (
          <div class="container-fluid sellabeltxt">
            <Card variant="outlined">
              <CardHeader
                title={
                  <Typography className="heading_box">
                    {" "}
                    {t("regulatoryReview.label1")}{" "}
                  </Typography>
                }
                className={classes.header}
              />
              <CardContent>
                <div className="col-12 border">
                  <div class="row col-12 pt10">
                    <div class="col-sm-4">
                      {" "}
                      <label>
                        <b>{t("regulatoryReview.reviewReq")}</b>
                        <span className="req">*</span>{" "}
                      </label>
                    </div>
                    <div class="col-sm-4 radio-inline">
                      <input
                        type="radio"
                        id="reviewRequired"
                        name="reviewRequired"
                        value="true"
                        onChange={(e) => handleChangeValue(e)}
                        checked={reviewRequired === "true"}
                        disabled={
                          status === "Regulatory Reviewer 2" ||
                          status === "New" ||
                          status === "INBOX" ||
                          status === "IN_DELIVERY" ||
                          status === "Delivered" ||
                          status === "PROCESSING" ||
                          status === "Processing" ||
                          status === "INBOX" ||
                          status === "New" ||
                          status === "READY_FOR_PICKUP" ||
                          status === "CANCELLED" ||
                          status === "READY_FOR_DELIVERY" ||
                          status === "IN_PROGRESS" ||
                          status === "SHIPER_REVIEW_DONE"
                        }
                      ></input>
                      &nbsp;
                      <b>Yes</b>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="reviewRequired"
                        name="reviewRequired"
                        value="false"
                        onChange={(e) => handleChangeValue(e)}
                        checked={reviewRequired === "false"}
                        disabled={
                          status === "Regulatory Reviewer 2" ||
                          status === "New" ||
                          status === "INBOX" ||
                          status === "IN_DELIVERY" ||
                          status === "Delivered" ||
                          status === "PROCESSING" ||
                          status === "Processing" ||
                          status === "INBOX" ||
                          status === "New" ||
                          status === "READY_FOR_PICKUP" ||
                          status === "CANCELLED" ||
                          status === "READY_FOR_DELIVERY" ||
                          status === "IN_PROGRESS" ||
                          status === "SHIPER_REVIEW_DONE"
                        }
                      ></input>
                      &nbsp;
                      <b>No</b>
                    </div>
                  </div>
                  {reviewRequired === "true" ? (
                    <>
                      <div class="row  col-12 pt10">
                        <div class="col-sm-4">
                          <label>
                            <b>{t("regulatoryReview.origin_Country")}</b>
                          </label>
                        </div>
                        <div class="col-sm-4">
                          <span>{originCountry}</span>
                        </div>
                      </div>
                      <div class="row col-12 pt10">
                        <div class="col-sm-4">
                          {" "}
                          <label>
                            <b>{t("regulatoryReview.review_origin_country")}</b>
                            <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="radio"
                            id="originatingCountry"
                            name="originatingCountry"
                            value="true"
                            onChange={(e) => handleChangeValue(e)}
                            checked={originatingCountry === "true"}
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="originatingCountry"
                            name="originatingCountry"
                            value="false"
                            onChange={(e) => handleChangeValue(e)}
                            checked={originatingCountry === "false"}
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                      <div class="row col-12 pt10">
                        <div class="col-sm-4">
                          <label>
                            <b>
                              {t(
                                "regulatoryReview.review_origin_country_comments",
                              )}{" "}
                            </b>
                            <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div class="col-sm-4">
                          <textarea
                            value={commentForOriginatingCountry}
                            onChange={(e) => handleChangeValue(e)}
                            id="commentForOriginatingCountry"
                            name="commentForOriginatingCountry"
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                            readOnly={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class="row col-12 pt10">
                        <div class="col-sm-4">
                          <label>
                            <b>{t("regulatoryReview.Receiving_Country")}</b>
                          </label>
                        </div>
                        <div class="col-sm-4">
                          <label>{recieverCountry}</label>
                        </div>
                      </div>
                      <div class="row col-12 pt10">
                        <div class="col-sm-4">
                          <label>
                            <b>
                              {t("regulatoryReview.review_Receiving_country")}
                            </b>
                            <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="radio"
                            id="ReceivingCountry"
                            name="ReceivingCountry"
                            value="true"
                            onChange={(e) => handleChangeValue(e)}
                            checked={receivingCountry === "true"}
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="ReceivingCountry"
                            name="ReceivingCountry"
                            value="false"
                            onChange={(e) => handleChangeValue(e)}
                            checked={receivingCountry === "false"}
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                      <div class="row col-12 pt10">
                        <div class="col-sm-4">
                          <label>
                            <b>
                              {t(
                                "regulatoryReview.review_Receiving_country_comments",
                              )}
                            </b>
                            <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div class="col-sm-4">
                          {" "}
                          <textarea
                            value={commentForReceivingCountry}
                            onChange={(e) => handleChangeValue(e)}
                            id="commentForReceivingCountry"
                            name="commentForReceivingCountry"
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                            readOnly={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div class="row col-12 pt10">
                        <div class="col-sm-4">
                          {" "}
                          <label>
                            <b>{t("regulatoryReview.is_special_label")}</b>
                            <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="radio"
                            id="specialLabel"
                            name="specialLabel"
                            value="true"
                            onChange={(e) => handleChangeValue(e)}
                            checked={specialLabel === "true"}
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></input>
                          &nbsp;
                          <b>Yes</b>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="specialLabel"
                            name="specialLabel"
                            value="false"
                            onChange={(e) => handleChangeValue(e)}
                            checked={specialLabel === "false"}
                            disabled={
                              status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE"
                            }
                          ></input>
                          &nbsp;
                          <b>No</b>
                        </div>
                      </div>
                      {specialLabel === "true" ? (
                        <>
                          <div class="row col-12">
                            <div class="col-sm-4">
                              <label>
                                <b>
                                  {t("regulatoryReview.is_special_label_yes")}{" "}
                                </b>
                                <span className="req">*</span>{" "}
                              </label>
                            </div>

                            <div class="col-sm-4">
                              {" "}
                              {status === "Regulatory Reviewer 2" ||
                              status === "New" ||
                              status === "INBOX" ||
                              status === "IN_DELIVERY" ||
                              status === "Delivered" ||
                              status === "PROCESSING" ||
                              status === "Processing" ||
                              status === "INBOX" ||
                              status === "New" ||
                              status === "READY_FOR_PICKUP" ||
                              status === "CANCELLED" ||
                              status === "READY_FOR_DELIVERY" ||
                              status === "IN_PROGRESS" ||
                              status === "SHIPER_REVIEW_DONE" ? (
                                <label>{selectedLabelValue}</label>
                              ) : (
                                <Select
                                  name="specialLabelSelect"
                                  data-test="specialLabelSelect"
                                  id="specialLabelSelect"
                                  value={selectedLabelValue}
                                  onChange={handleLabelChange}
                                  options={labeldropdown}
                                  isDisabled={
                                    status === "Regulatory Reviewer 2" ||
                                    status === "New" ||
                                    status === "INBOX" ||
                                    status === "IN_DELIVERY" ||
                                    status === "Delivered" ||
                                    status === "PROCESSING" ||
                                    status === "Processing" ||
                                    status === "INBOX" ||
                                    status === "New" ||
                                    status === "READY_FOR_PICKUP" ||
                                    status === "CANCELLED" ||
                                    status === "READY_FOR_DELIVERY" ||
                                    status === "IN_PROGRESS" ||
                                    status === "SHIPER_REVIEW_DONE"
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div class="row col-12 pt10">
                            <div class="col-sm-4">
                              <label>
                                <b>{t("regulatoryReview.additional_data")}</b>
                                {selectedLabelValue.value ===
                                "Other (enter value in comments)" ? (
                                  <span className="req">*</span>
                                ) : (
                                  <span></span>
                                )}{" "}
                              </label>
                            </div>
                            <div class="col-sm-4">
                              <textarea
                                value={additionalComments}
                                onChange={(e) => handleChangeValue(e)}
                                id="additionalComments"
                                name="additionalComments"
                                disabled={
                                  status === "Regulatory Reviewer 2" ||
                                  status === "New" ||
                                  status === "INBOX" ||
                                  status === "IN_DELIVERY" ||
                                  status === "Delivered" ||
                                  status === "PROCESSING" ||
                                  status === "Processing" ||
                                  status === "INBOX" ||
                                  status === "New" ||
                                  status === "READY_FOR_PICKUP" ||
                                  status === "CANCELLED" ||
                                  status === "READY_FOR_DELIVERY" ||
                                  status === "IN_PROGRESS" ||
                                  status === "SHIPER_REVIEW_DONE"
                                }
                                readOnly={
                                  status === "Regulatory Reviewer 2" ||
                                  status === "New" ||
                                  status === "INBOX" ||
                                  status === "IN_DELIVERY" ||
                                  status === "Delivered" ||
                                  status === "PROCESSING" ||
                                  status === "Processing" ||
                                  status === "INBOX" ||
                                  status === "New" ||
                                  status === "READY_FOR_PICKUP" ||
                                  status === "CANCELLED" ||
                                  status === "READY_FOR_DELIVERY" ||
                                  status === "IN_PROGRESS" ||
                                  status === "SHIPER_REVIEW_DONE"
                                }
                              ></textarea>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <>
                    <div class="row col-12 pt10">
                      <div class="col-sm-4">
                        <label>
                          <b>
                            {t("regulatoryReview.comments_approval_reject")}
                          </b>
                          {approveOrRejectFlag === "reject" ? (
                            <span className="req">*</span>
                          ) : (
                            <span></span>
                          )}{" "}
                        </label>
                      </div>
                      <div class="col-sm-4">
                        {" "}
                        <textarea
                          value={commentsForReg1}
                          onChange={(e) => handleChangeValue(e)}
                          id="commentsForApprovalOrRejectReg1"
                          name="commentsForApprovalOrRejectReg1"
                          disabled={
                            status === "Regulatory Reviewer 2" ||
                            status === "New" ||
                            status === "INBOX" ||
                            status === "IN_DELIVERY" ||
                            status === "Delivered" ||
                            status === "PROCESSING" ||
                            status === "Processing" ||
                            status === "INBOX" ||
                            status === "New" ||
                            status === "READY_FOR_PICKUP" ||
                            status === "CANCELLED" ||
                            status === "READY_FOR_DELIVERY" ||
                            status === "IN_PROGRESS" ||
                            status === "SHIPER_REVIEW_DONE"
                          }
                          readOnly={
                            status === "Regulatory Reviewer 2" ||
                            status === "New" ||
                            status === "INBOX" ||
                            status === "IN_DELIVERY" ||
                            status === "Delivered" ||
                            status === "PROCESSING" ||
                            status === "Processing" ||
                            status === "INBOX" ||
                            status === "New" ||
                            status === "READY_FOR_PICKUP" ||
                            status === "CANCELLED" ||
                            status === "READY_FOR_DELIVERY" ||
                            status === "IN_PROGRESS" ||
                            status === "SHIPER_REVIEW_DONE"
                          }
                        ></textarea>
                      </div>
                    </div>
                  </>
                  {status === "Regulatory Reviewer 1" &&
                  userType.includes(USERROLES.REGULATORYREVIEWER1) ? (
                    <div className="float-right pt10">
                      <button
                        className="btn btn-primary new_button "
                        disabled={reviewRequired === ""}
                        onClick={(e) => handleSaveForReg1("approve")}
                      >
                        {t("material.approve")}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary new_button "
                        disabled={reviewRequired === ""}
                        onClick={(e) => handleSaveForReg1("reject")}
                      >
                        {t("regulatoryReview.reject")}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="btn cancelbutton"
                        onClick={(e) => handleClose()}
                      >
                        {t("commonlabels.cancelbutton")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </CardContent>
            </Card>
            <br />

            {
              <div>
                {status === "Regulatory Reviewer 2" &&
                userType.includes(USERROLES.REGULATORYREVIEWER2) ? (
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Typography className="heading_box">
                          {" "}
                          {t("regulatoryReview.label2")}{" "}
                        </Typography>
                      }
                      className={classes.header}
                    />
                    <CardContent>
                      <div>
                        <div class="row col-12 pt10">
                          <div class="col-sm-4">
                            <label>
                              <b>
                                {t("regulatoryReview.comments_approval_reject")}
                              </b>
                              {approveOrRejectFlag === "reject" ? (
                                <span className="req">*</span>
                              ) : (
                                <span></span>
                              )}{" "}
                            </label>
                          </div>
                          <div class="col-sm-4">
                            {" "}
                            <textarea
                              value={commentsForReg2}
                              onChange={(e) => handleChangeValue(e)}
                              id="commentsForApprovalOrRejectReg2"
                              name="commentsForApprovalOrRejectReg2"
                              //disabled={ status === "Regulatory Reviewer 1" || status === "New" || status === "INBOX" || status === "IN_DELIVERY" || status === "Delivered" || status === "PROCESSING"|| status === "Processing"|| status === "INBOX"|| status === "New"}
                              //readOnly={ status === "Regulatory Reviewer 1" || status === "New" || status === "INBOX" || status === "IN_DELIVERY" || status === "Delivered" || status === "PROCESSING"|| status === "Processing"|| status === "INBOX"|| status === "New"}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <div>
                    {status === "Regulatory Reviewer 1" &&
                    userType.includes(USERROLES.REGULATORYREVIEWER1) ? (
                      <div></div>
                    ) : (
                      <div>
                        {commentsForReg2 !== null && regFlagVisible ? (
                          <Card variant="outlined">
                            <CardHeader
                              title={
                                <Typography className="heading_box">
                                  {" "}
                                  {t("regulatoryReview.label2")}{" "}
                                </Typography>
                              }
                              className={classes.header}
                            />
                            <CardContent>
                              <div>
                                <div class="row col-12 pt10">
                                  <div class="col-sm-4">
                                    <label>
                                      <b>
                                        {t(
                                          "regulatoryReview.comments_approval_reject",
                                        )}
                                      </b>
                                      {approveOrRejectFlag === "reject" ? (
                                        <span className="req">*</span>
                                      ) : (
                                        <span></span>
                                      )}{" "}
                                    </label>
                                  </div>
                                  <div class="col-sm-4">
                                    {" "}
                                    <textarea
                                      value={commentsForReg2}
                                      onChange={(e) => handleChangeValue(e)}
                                      id="commentsForApprovalOrRejectReg2"
                                      name="commentsForApprovalOrRejectReg2"
                                      disabled={true}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>{" "}
                            </CardContent>
                          </Card>
                        ) : (
                          <>
                            {status == "INBOX" && regFlag.data === true ? (
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography className="heading_box">
                                      {" "}
                                      {t("regulatoryReview.label2")}{" "}
                                    </Typography>
                                  }
                                  className={classes.header}
                                />
                                <CardContent>
                                  <div>
                                    <div class="row col-12 pt10">
                                      <div class="col-sm-4">
                                        <label>
                                          <b>
                                            {t(
                                              "regulatoryReview.comments_approval_reject",
                                            )}
                                          </b>
                                          {approveOrRejectFlag === "reject" ? (
                                            <span className="req">*</span>
                                          ) : (
                                            <span></span>
                                          )}{" "}
                                        </label>
                                      </div>
                                      <div class="col-sm-4">
                                        {" "}
                                        <textarea
                                          value={commentsForReg2}
                                          onChange={(e) => handleChangeValue(e)}
                                          id="commentsForApprovalOrRejectReg2"
                                          name="commentsForApprovalOrRejectReg2"
                                          disabled={true}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </CardContent>
                              </Card>
                            ) : (
                              <div></div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {status === "Regulatory Reviewer 2" &&
                userType.includes(USERROLES.REGULATORYREVIEWER2) ? (
                  <div className="float-right pt10">
                    <button
                      className="btn btn-primary new_button "
                      disabled={reviewRequired === ""}
                      onClick={(e) => handleSaveForReg2("approve")}
                    >
                      {t("material.approve")}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary new_button "
                      disabled={reviewRequired === ""}
                      onClick={(e) => handleSaveForReg2("reject")}
                    >
                      {t("regulatoryReview.reject")}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn cancelbutton"
                      onClick={(e) => handleClose()}
                    >
                      {t("commonlabels.cancelbutton")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
          </div>
        ) : (
          <>
            {status === "INPUT" && regFlagVisible === true ? (
              <div>
                <div class="container-fluid ">
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Typography className="heading_box">
                          {" "}
                          {t("regulatoryReview.label1")}{" "}
                        </Typography>
                      }
                      className={classes.header}
                    />
                    <CardContent>
                      <div className="col-12 border">
                        <div class="row col-12 pt10">
                          <div class="col-sm-4">
                            {" "}
                            <label>
                              <b>{t("regulatoryReview.reviewReq")}</b>
                              <span className="req">*</span>{" "}
                            </label>
                          </div>
                          <div class="col-sm-4 radio-inline">
                            <input
                              type="radio"
                              id="reviewRequired"
                              name="reviewRequired"
                              value="true"
                              onChange={(e) => handleChangeValue(e)}
                              checked={reviewRequired === "true"}
                              disabled={true}
                            ></input>
                            &nbsp;
                            <b>Yes</b>
                            &nbsp;&nbsp;&nbsp;
                            <input
                              type="radio"
                              id="reviewRequired"
                              name="reviewRequired"
                              value="false"
                              onChange={(e) => handleChangeValue(e)}
                              checked={reviewRequired === "false"}
                              disabled={true}
                            ></input>
                            &nbsp;
                            <b>No</b>
                          </div>
                        </div>
                        {reviewRequired === "true" ? (
                          <>
                            <div class="row  col-12 pt10">
                              <div class="col-sm-4">
                                <label>
                                  <b>{t("regulatoryReview.origin_Country")}</b>
                                </label>
                              </div>
                              <div class="col-sm-4">
                                <span>{originCountry}</span>
                              </div>
                            </div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                {" "}
                                <label>
                                  <b>
                                    {t(
                                      "regulatoryReview.review_origin_country",
                                    )}
                                  </b>
                                  <span className="req">*</span>{" "}
                                </label>
                              </div>
                              <div class="col-sm-4">
                                <input
                                  type="radio"
                                  id="originatingCountry"
                                  name="originatingCountry"
                                  value="true"
                                  onChange={(e) => handleChangeValue(e)}
                                  checked={originatingCountry === "true"}
                                  disabled={true}
                                ></input>
                                &nbsp;
                                <b>Yes</b>
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  id="originatingCountry"
                                  name="originatingCountry"
                                  value="false"
                                  onChange={(e) => handleChangeValue(e)}
                                  checked={originatingCountry === "false"}
                                  disabled={true}
                                ></input>
                                &nbsp;
                                <b>No</b>
                              </div>
                            </div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                <label>
                                  <b>
                                    {t(
                                      "regulatoryReview.review_origin_country_comments",
                                    )}{" "}
                                  </b>
                                  <span className="req">*</span>{" "}
                                </label>
                              </div>
                              <div class="col-sm-4">
                                <textarea
                                  value={commentForOriginatingCountry}
                                  onChange={(e) => handleChangeValue(e)}
                                  id="commentForOriginatingCountry"
                                  name="commentForOriginatingCountry"
                                  disabled={true}
                                  readOnly={true}
                                ></textarea>
                              </div>
                            </div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                <label>
                                  <b>
                                    {t("regulatoryReview.Receiving_Country")}
                                  </b>
                                </label>
                              </div>
                              <div class="col-sm-4">
                                <label>{recieverCountry}</label>
                              </div>
                            </div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                <label>
                                  <b>
                                    {t(
                                      "regulatoryReview.review_Receiving_country",
                                    )}
                                  </b>
                                  <span className="req">*</span>{" "}
                                </label>
                              </div>
                              <div class="col-sm-4">
                                <input
                                  type="radio"
                                  id="ReceivingCountry"
                                  name="ReceivingCountry"
                                  value="true"
                                  onChange={(e) => handleChangeValue(e)}
                                  checked={receivingCountry === "true"}
                                  disabled={true}
                                ></input>
                                &nbsp;
                                <b>Yes</b>
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  id="ReceivingCountry"
                                  name="ReceivingCountry"
                                  value="false"
                                  onChange={(e) => handleChangeValue(e)}
                                  checked={receivingCountry === "false"}
                                  disabled={true}
                                ></input>
                                &nbsp;
                                <b>No</b>
                              </div>
                            </div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                <label>
                                  <b>
                                    {t(
                                      "regulatoryReview.review_Receiving_country_comments",
                                    )}
                                  </b>
                                  <span className="req">*</span>{" "}
                                </label>
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <textarea
                                  value={commentForReceivingCountry}
                                  onChange={(e) => handleChangeValue(e)}
                                  id="commentForReceivingCountry"
                                  name="commentForReceivingCountry"
                                  disabled={true}
                                  readOnly={true}
                                ></textarea>
                              </div>
                            </div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                {" "}
                                <label>
                                  <b>
                                    {t("regulatoryReview.is_special_label")}
                                  </b>
                                  <span className="req">*</span>{" "}
                                </label>
                              </div>
                              <div class="col-sm-4">
                                <input
                                  type="radio"
                                  id="specialLabel"
                                  name="specialLabel"
                                  value="true"
                                  onChange={(e) => handleChangeValue(e)}
                                  checked={specialLabel === "true"}
                                  disabled={true}
                                ></input>
                                &nbsp;
                                <b>Yes</b>
                                &nbsp;&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  id="specialLabel"
                                  name="specialLabel"
                                  value="false"
                                  onChange={(e) => handleChangeValue(e)}
                                  checked={specialLabel === "false"}
                                  disabled={true}
                                ></input>
                                &nbsp;
                                <b>No</b>
                              </div>
                            </div>
                            {specialLabel === "true" ? (
                              <>
                                <div class="row col-12">
                                  <div class="col-sm-4">
                                    <label>
                                      <b>
                                        {t(
                                          "regulatoryReview.is_special_label_yes",
                                        )}{" "}
                                      </b>
                                      <span className="req">*</span>{" "}
                                    </label>
                                  </div>

                                  <div class="col-sm-4">
                                    {" "}
                                    {status === "Regulatory Reviewer 2" ||
                                    status === "New" ||
                                    status === "INBOX" ||
                                    status === "IN_DELIVERY" ||
                                    status === "Delivered" ||
                                    status === "PROCESSING" ||
                                    status === "Processing" ||
                                    status === "INBOX" ||
                                    status === "New" ? (
                                      <label>{selectedLabelValue}</label>
                                    ) : (
                                      <Select
                                        name="specialLabelSelect"
                                        data-test="specialLabelSelect"
                                        id="specialLabelSelect"
                                        value={selectedLabelValue}
                                        onChange={handleLabelChange}
                                        options={labeldropdown}
                                        isDisabled={true}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div class="row col-12 pt10">
                                  <div class="col-sm-4">
                                    <label>
                                      <b>
                                        {t("regulatoryReview.additional_data")}{" "}
                                      </b>
                                      {selectedLabelValue.value ===
                                      "Other (enter value in comments)" ? (
                                        <span className="req">*</span>
                                      ) : (
                                        <span></span>
                                      )}{" "}
                                    </label>
                                  </div>
                                  <div class="col-sm-4">
                                    <textarea
                                      value={additionalComments}
                                      onChange={(e) => handleChangeValue(e)}
                                      id="additionalComments"
                                      name="additionalComments"
                                      disabled={true}
                                      readOnly={true}
                                    ></textarea>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <>
                          <div class="row col-12 pt10">
                            <div class="col-sm-4">
                              <label>
                                <b>
                                  {t(
                                    "regulatoryReview.comments_approval_reject",
                                  )}{" "}
                                </b>
                                {approveOrRejectFlag === "reject" ? (
                                  <span className="req">*</span>
                                ) : (
                                  <span></span>
                                )}{" "}
                              </label>
                            </div>
                            <div class="col-sm-4">
                              {" "}
                              <textarea
                                value={commentsForReg1}
                                onChange={(e) => handleChangeValue(e)}
                                id="commentsForApprovalOrRejectReg1"
                                name="commentsForApprovalOrRejectReg1"
                                disabled={true}
                                readOnly={true}
                              ></textarea>
                            </div>
                          </div>
                        </>
                      </div>
                    </CardContent>
                  </Card>
                  <br />
                  {commentsForReg2 === null ? (
                    ""
                  ) : (
                    <div>
                      <Card variant="outlined">
                        <CardHeader
                          title={
                            <Typography className="heading_box">
                              {" "}
                              {t("regulatoryReview.label2")}{" "}
                            </Typography>
                          }
                          className={classes.header}
                        />
                        <CardContent>
                          <div>
                            <div class="row col-12 pt10">
                              <div class="col-sm-4">
                                <label>
                                  <b>
                                    {t(
                                      "regulatoryReview.comments_approval_reject",
                                    )}
                                  </b>
                                  {approveOrRejectFlag === "reject" ? (
                                    <span className="req">*</span>
                                  ) : (
                                    <span></span>
                                  )}{" "}
                                </label>
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <textarea
                                  value={commentsForReg2}
                                  onChange={(e) => handleChangeValue(e)}
                                  id="commentsForApprovalOrRejectReg2"
                                  name="commentsForApprovalOrRejectReg2"
                                  disabled={true}
                                  readOnly={true}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {" "}
                {regFlagVisible ? (
                  <div className=" col-12 ">
                    <div className="col-12 border pt10">
                      {t("regulatoryReview.does_not_have_access_msg")}
                      <br></br>
                    </div>
                  </div>
                ) : (
                  <div className=" col-12 ">
                    <div className="col-12 border pt10">
                      {t("regulatoryReview.does_not_have_access_msg")}
                      <br></br>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </LoadingOverlay>
    </>
  );
};
export default ReviewerTab;
