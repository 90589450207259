import React, { useState, useReducer } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { TextField } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { useStyles } from '../common/ThemeStye';

const DateField = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <DatePicker
     locale={i18n.resolvedLanguage}
      portalId="root-portal"
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box datePicker"
      margin="normal"
      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
      selected={props.state[props.name]}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />

  )
}

const DistributionTemperatureData = ({ onSearch }) => {
  const { t, i18n } = useTranslation();
  registerLocale("de", de);
  const classes = useStyles()
  const CheckboxField = (props) => {

    return (
      <div className="d-flex col-12 col-sm-2 form_item">
        <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={props.state[props.name]}
          {...props}
        />
        <span>{props.label}</span>
      </div>
    );
  };


  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return {
        temperature_description: "",
        temperature_description_english: "",
        id: "",
        created_by: "",
        valid: false,
        created_on: "",
        updated_on: "",

      };
    }

    if (event.target.type === "checkbox") {
      return { ...state, [event.target.name]: event.target.checked };
    }
    return { ...state, [event.target.name]: event.target.value };
  };
  const [state, dispatch] = useReducer(reducer, {
    temperature_description: "",
    temperature_description_english: "",
    id: "",
    created_by: "",
    valid: "",
    created_on: "",
    updated_on: "",

  });


  return (
    <>
      <div className="tablebg_box">
        <div className='row'>
          <div className="col-11 pr-0">
            <div className="row col-12">

              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="temperature_description"
                  name="temperature_description"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("distributiontemperature.temperaturediscription")}
                  onChange={dispatch}
                  value={state["temperature_description"]}

                />
              </div>

              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="temperature_description_english"
                  name="temperature_description_english"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t("distributiontemperature.temperaturediscriptionenglish")}
                  onChange={dispatch}
                  value={state["temperature_description_english"]}

                />
              </div>
              {/* <div className="col-lg-3  pr-0 mt-2">
            <TextField
              id="id"
              name="id"
              margin="normal"
              variant="outlined"
              size="small"
              placeholder="Id"
              onChange={dispatch}
              value={state["id"]}


            />
            </div> */}
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="created_by"
                  name="created_by"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t('address.Created User')}
                  onChange={dispatch}
                  value={state["created_by"]}

                />
              </div>
              <div className="col-lg-3  pr-0 mt-0 distributioncheckbox">
                <CheckboxField
                  id="valid"
                  name="valid"
                  className="check_box"
                  label={t("address.Valid")}
                  onChange={dispatch}
                  state={state}
                />
              </div>

            </div>


            <div className="row col-12">
             
              <div className="col-lg-3  pr-0 mt-2">

                <DateField

                  className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box" margin="normal"
                  placeholderText={t('phase.createdatefrom')}


                  variant="outlined"
                  size="small"
                  dateFormat="dd-MMM-yyyy"
                  id="created_on"
                  name="created_on"

                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  onChange={(date) =>
                    dispatch({ target: { name: "created_on", value: date } })
                  }
                  state={state}

                />
              </div>
              <div className="col-lg-3  pr-0 mt-2">

                <DateField

                  className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box" margin="normal"
                  placeholderText={t('phase.createtilldate')}



                  variant="outlined"
                  size="small"
                  name='updated_on'
                  dateFormat="dd-MMM-yyyy"
                  id="updated_on"

                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  onChange={(date) =>
                    dispatch({ target: { name: "updated_on", value: date } })
                  }
                  state={state}

                />
              </div>
            </div>

          </div>

          <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
            <Button
              variant="contained"
              className={classes.searchBt + " search_buttontwo"}
              onClick={() => onSearch({ ...state })}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.reloadBt + " refresh_button"}
              onClick={() => dispatch({ type: "RESET" })}
            >
              <ReplayIcon />
            </Button>
          </div>



        </div>
      </div>



    </>
  )
}


export default DistributionTemperatureData;