import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import crossicon from "../../../assests/images/crossicon.png";
import moment from "moment";
import Model from "../../shared/model";
import { get } from "lodash";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaterialTable from "@material-table/core";
import { AddBox, ArrowDownward } from "@material-ui/icons";
import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { addressSearchStyles } from "../Address/addressStyles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import {
  dateSortFunction,
  SortFunctionNew,
} from "../../../Services/util";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { DateWithDefaultTime, DateWithEndTime } from "../../common/utils/DateUtil";


toast.configure();
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const LogisticsViewData = (props) => {
  const { actions, logisticsPartnerData, searchFilter, setIsLoading } = props;

  const { t, i18n } = useTranslation();
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };
  const [filteredData, setfilteredData] = useState(logisticsPartnerData);
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const [userSelected, setUserSelected] = useState("");
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");
  const classes = addressSearchStyles();
  const [title, setTitle] = useState("");
  const [isEditModelVisible, setIsEditModelVisible] = useState(false);
  const history = useHistory();

  const openmodalForEdit = () => {
    setTitle();
    setIsEditModelVisible(true);
  };

  const closemodal = () => {
    setTitle("");
    setIsEditModelVisible(false);
  };

  useEffect(() => {
    setfilteredData(logisticsPartnerData);
  }, [logisticsPartnerData]);

  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key],
    );

    const filteredData =
      logisticsPartnerData &&
      logisticsPartnerData.filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (["is_active"].includes(searchParam)) {
            if (!item[searchParam]) {
              return false;
            } else {
              continue;
            }
          }

          if (["created_on", "updated_on"].includes(searchParam)) {
            if (searchParam === "created_on") {
              const createDate = moment(item.created_on);
              const created_on = moment(searchFilter[searchParam]);
              if (createDate.isBefore(DateWithDefaultTime(created_on))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on);
              const updated_on = moment(searchFilter[searchParam]);
              if (createDate.isAfter(DateWithEndTime(updated_on))) {
                return false;
              }
              continue;
            }
          }
          if (
            !item[searchParam] ||
            !item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
        }
        return true;
      });

    setfilteredData(filteredData);
  }, [searchFilter, logisticsPartnerData]);

  const handleActivate = () => {
    let logisticsPartnerData = {};
    if (get(userSelected, "is_active", false)) {
      logisticsPartnerData = {
        id: userSelected.id,
        data: false,
      };
    } else {
      logisticsPartnerData = {
        id: userSelected.id,
        data: true,
      };
    }
    setIsLoading(true);
    actions.updateLogisticsPartner(logisticsPartnerData).then(() => {
      setfilteredData([]);
      setUserSelected("");
      actions.getLogisticsParterDictionaryData().then(() => {
        setIsLoading(false);
      });
    });
  };
  const handleRadiobuttonChange = (data) => {
    setUserSelected(data);
  };

  const handleSave = () => {
    try {
      let error = "";

      if (!title) {
        error = t("logisticsPartner.name") + ", ";
      }
      if (error !== "") {
        return toast.error(
          t("commonlabels.is_req1") +
            " " +
            ` ${error}` +
            " " +
            t("commonlabels.is_req2"),
        );
      }
      if (1) {
        const logisticsData = {
          logisticsPartnerName: title,
          updated_by: userId,
        };
        setIsLoading(true);
        actions.saveLogisticsPartner(logisticsData).then((res) => {
          if(res.data) {
            toast.success(t("commonlabels.savedSuccessfullyMsg"));
            history.push(`/logistics`);
            actions.getLogisticsParterDictionaryData().then(() => {
              setIsEditModelVisible(false);
              setIsLoading(false);
            });
          }
          else {
            setIsLoading(false);
            toast.error(t("logisticsPartner.name") + ' ' + t("commonlabels.duplicate"))
          }  
        });
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };

  const getTableData = () => {
    return (
      <div style={{ paddingBottom: "2%" }}>
        <MaterialTable
          columns={[
            {
              width: "15%",
              sorting: false,
              render: (rowData) => (
                <input
                  type="radio"
                  value={get(userSelected, "id", "")}
                  name="radiovalues"
                  className={classes.tableRadio}
                  onChange={(e) => handleRadiobuttonChange(rowData)}
                />
              ),
            },

            {
              title: <span>{t("logisticsPartner.name")}</span>,
              field: "logistics_partner",
              customSort: SortFunctionNew("logistics_partner"),
            },
            {
              title: <span> {t("logisticsPartner.CreateDate")}</span>,
              render: (rowData) => (
                <span>{dateLocalization(rowData.created_on)}</span>
              ),
              customSort: dateSortFunction("created_on"),
            },
            {
              title: <span>{t("logisticsPartner.CreatedUser")}</span>,
              field: "created_by",
              customSort: SortFunctionNew("created_by"),
            },

            {
              title: <span>{t("logisticsPartner.Valid")}</span>,
              customSort: (a, b) => {
                if (a.is_active > b.is_active) {
                  return -1;
                } else if (a.is_active < b.is_active) {
                  return 1;
                } else if (a.is_active === b.is_active) {
                  return 0;
                } else {
                  return 0;
                }
              },

              render: (data) =>
                data.is_active ? (
                  <span>
                    <div>
                      <input type="checkbox" defaultChecked={true} disabled />
                    </div>
                  </span>
                ) : (
                  <div>
                    <span>
                      <div>
                        <input
                          type="checkbox"
                          defaultChecked={false}
                          disabled
                        />
                      </div>
                    </span>
                  </div>
                ),
            },
          ]}
          data={filteredData}
          title="Render Image Preview"
          icons={tableIcons}
          localization={{
            pagination: {
              labelRowsSelect: t("commonlabels.labelRowsSelect"),
              firstAriaLabel: t("commonlabels.firstAriaLabel"),
              firstTooltip: t("commonlabels.firstTooltip"),
              previousAriaLabel: t("commonlabels.previousAriaLabel"),
              previousTooltip: t("commonlabels.previousTooltip"),
              nextAriaLabel: t("commonlabels.nextAriaLabel"),
              nextTooltip: t("commonlabels.nextTooltip"),
              lastAriaLabel: t("commonlabels.lastAriaLabel"),
              lastTooltip: t("commonlabels.lastTooltip"),
            },
            body: {
              emptyDataSourceMessage: t("commonlabels.emptyDataSourceMessage"),
            },
          }}
          options={{
            draggable: false,
            thirdSortClick: false,
            //  actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "lightgray",
              fontWeight: "bold",
              height: "10px",
            },
            rowStyle: {
              height: "30px",
              padding: "0px",
            },

            maxBodyHeight: "90%",
            tableLayout: "auto",
            sorting: true,
            search: false,
            selection: false,

            showTitle: false,
            toolbar: false,
            paging: true,
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 40],
            emptyRowsWhenPaging: false,
            showSelectAllCheckbox: false,
          }}
        />
      </div>
    );
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "LogisticsPartner_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() != "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    return copyOfFilteredData.map((item) => ({
      "Logistics Partner": item.logistics_partner,
      "Create Date": dateLocalization(item.created_on),
      "Create User": item.created_by,
      Valid: item.is_active,
    }));
  };

  return (
    <div>
      <div className="row m0 container-fluid">
        <div className="no_padding col-12 ">
          <div className={classes.actionButtonsTop}>
            <button
              className="btn btn-primary btn-inner-close new_button mr-1"
              onClick={() => {
                setIsExportModalVisible(true);
              }}
            >
              {t("logisticsPartner.export")}
            </button>
            <button
              className="btn btn-primary btn-inner-close new_button mr-2"
              onClick={(e) => {
                openmodalForEdit();
              }}
            >
              {t("logisticsPartner.new")}
            </button>

            <button
              className="btn btn-inner-approve   new_button"
              onClick={() => handleActivate()}
              disabled={get(userSelected, "id", 0) === 0 ? true : false}
            >
              {get(userSelected, "is_active", false)
                ? t("logisticsPartner.deactivate")
                : t("logisticsPartner.activate")}
            </button>
          </div>
        </div>
      </div>

      <Card variant="outlined">
        <CardHeader
          style={cardTheme.cardHeaderStylePref}
          title={t("logisticsPartner.search_results")}
        />
        <Divider />
        <CardContent>
          <div className="row m0">
            <div className="w-100">
              <div className="">{getTableData()}</div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Modal
        dialogClassName="modal-90w"
        show={isEditModelVisible}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h5> {t("commonlabels.newButton") + " " + t("logisticsPartner.name")} </h5>

          <a id="openmodalForEdit" onClick={(e) => closemodal(e)}>
            <img alt="t" className="" src={crossicon}></img>{" "}
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4 m0">
            <div className="col-12">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="" className="col-form-label">
                      {t("logisticsPartner.name")} <span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form-control"
                      id="logisticsPartnerName"
                      name="logisticsPartnerName"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            id="updateMethodButton"
            className="btn btn-primary btn-inner-close"
            onClick={(e) => handleSave(e)}
          >
            {t("commonlabels.saveButton")}
          </button>
          <button
            type="button"
            id="closeUpdateMethodButton"
            className="btn btn-black btn-inner-close"
            onClick={(e) => closemodal(e)}
          >
            {t("commonlabels.cancelbutton")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LogisticsViewData;
