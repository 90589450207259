
import moment from "moment";

export const FormatDate = (date) => {

    if(date === null) {
        return null
    }
    var d = new Date(date);

    if(d instanceof Date) {
        
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();
    
        if (month.length < 2)
            month = '0' + month;
    
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    } else {
        return null
    }

}

export const DateFormater = (date, format)=>{
    return date? moment(date).format(format): null;
}
export const DateFormat = (date,language)=>{
    if (!date) {
        return date;  
      }
      let newDate = date;
      if(new Date(date).getTimezoneOffset() < 0){
        let tmp = new Date(date)
        tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
        newDate = tmp
      }
        return moment(newDate).utc().format("DD-MMM-YYYY");
  };

export const DateWithDefaultTime = (date) => {
    const result = moment(date).format("YYYY-MM-DD");
    return moment(result + "T00:00:00").format("YYYY-MM-DD HH:mm:ss");
}

export const DateWithEndTime = (date) => {
    const result = moment(date).format("YYYY-MM-DD");
    return moment(result + "T23:59:59").format("YYYY-MM-DD HH:mm:ss");
}