import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import phase from "../../../redux/phase"
import PhaseComponent from "./Phase"


const {
  getphase,
  savePhase,
  updatePhaseAccess,
}=phase.actions;


const mapStateToProps = (state) => ({
  phasedata: state[phase.name].phasedata
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getphase,
      savePhase,
      updatePhaseAccess,
    },
    dispatch,
  ),
});

export default 
   connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PhaseComponent);
