
import React, { useReducer } from "react";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import { TextField } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { addressSearchStyles } from '../Address/addressStyles';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { useStyles } from '../../common/ThemeStye';

const DateField = (props) => {
  registerLocale("de", de);
  const { t, i18n } = useTranslation();
  return (

    <DatePicker
    locale={i18n.resolvedLanguage}
      portalId="root-portal"
      className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box" margin="normal"

      variant="outlined"
      size="small"
      dateFormat="dd-MMM-yyyy"
      popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: false }, hide: { enabled: false } }} 
      selected={props.state[props.name]}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...props}
    />

  )
}

const TitleData = ({ onSearch }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles()

  const CheckboxField = (props) => {

    return (
      <div className="d-flex col-12 col-sm-2 form_item">
        <Checkbox
          margin="normal"
          variant="outlined"
          size="small"
          checked={props.state[props.name]}
          {...props}
        />
        <span>{props.label}</span>
      </div>
    );
  };


  const reducer = (state, event) => {
    if (event.type === "RESET") {
      onSearch({});
      return {
        title: "",
        id: "",
        created_by: "",
        valid: false,
        created_on: "",
        updated_on: "",

      };
    }

    if (event.target.type === "checkbox") {
      return { ...state, [event.target.name]: event.target.checked };
    }
    return { ...state, [event.target.name]: event.target.value };
  };
  const [state, dispatch] = useReducer(reducer, {
    title: "",
    id: "",
    created_by: "",
    valid: "",
    created_on: "",
    updated_on: "",

  });

  return (
    <>
      <div className="tablebg_box">
        <div className='row'>
          <div className="col-11 pr-0">

            <div className="row col-12">


              <div className="col-lg-3  pr-0 mt-2">

                <TextField
                  id="title"
                  name="title"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder="Title"
                  onChange={dispatch}
                  value={state["title"]}


                />

              </div>
              {/* <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="id"
                  name="id"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={"Id"}
                  onChange={dispatch}
                  value={state["id"]}

                />

              </div> */}
              <div className="col-lg-3  pr-0 mt-2">
                <TextField
                  id="created_by"
                  name="created_by"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  placeholder={t('address.Created User')}
                  onChange={dispatch}
                  value={state["created_by"]}


                />
              </div>

              <div className="col-lg-2  pr-0 mt-2">


                <DateField
             
                  id="created_on"
                  name="created_on"
                  className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
                  placeholderText={t('phase.createdatefrom')}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  dateFormat="dd-MMM-yyyy"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  onChange={(date) =>
                    dispatch({ target: { name: "created_on", value: date } })
                  }
                  state={state}
                  classes={classes}

                />
              </div>
              <div className="col-lg-2  pr-0 mt-2">




                <DateField
              
                  id="updated_on"
                  name="updated_on"
                  className="form-control custom-form-control my-icon mb-2 mr-sm-4 inputtext_box"
                  placeholderText={t('phase.createtilldate')}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  dateFormat="dd-MMM-yyyy"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  onChange={(date) =>
                    dispatch({ target: { name: "updated_on", value: date } })
                  }
                  state={state}


                />
              </div>
              <div className="col-lg-2  pr-2 mt-0 pl-2">

                <CheckboxField
                  id="valid"
                  name="valid"
                  label={t("address.Valid")}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  onChange={dispatch}
                  state={state}


                />
              </div>

            </div>






            <div className="row col-12">
              


            </div>

          </div>

          <div className="col-lg-1  mt-2 d-flex justify-content-end button_addreserach">
            <Button
              variant="contained"
              className={classes.searchBt + " search_buttontwo"}
              onClick={() => onSearch({ ...state })}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              className={classes.reloadBt + " refresh_button"}
              onClick={() => dispatch({ type: "RESET" })}
            >
              <ReplayIcon />
            </Button>
          </div>




        </div>
      </div>







    </>
  )
}


export default TitleData;