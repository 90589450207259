import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import address from "../../../redux/address";
import shipping from "../../../redux/shipping";
import AddressComponent from "./AddressComponent";
import AddAddressComponent from "./AddAddressComponent";
import InspectAddress from "./InspectAddressComponent";
import tableListing from "../../../redux/tableListing";
const {
  getAllAddress,
  saveAddress,
  getAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getSelectedAddressData,
  setTableOptions,
  setAddressSearchData
} = address.actions;

const { 
    getCountries,
    getCountriesList,
    getCities, 
    destinationAddrSelect,
    clearDestinationAddrSelect,
    
   } = shipping.actions;
const {saveTableData} = tableListing.actions;

const mapStateToProps = (state) => ({
  allAddress: state[address.name].allAddress,
  tableAdressOptions: state[address.name].tableAdressOptions,
  isLoader: state[address.name].isLoader,
  countries: state[shipping.name].countriesList,
  countrie: state[shipping.name].countries,
  cities: state[shipping.name].cities,
  redirectTo: state[shipping.name].redirectTo,
  state: state[address.name].addressSearchData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAddressSearchData,
      getAllAddress,
      getAddress,
      getCountries,
      getCountriesList,
      getCities,
      saveAddress,
      editAddress,
      updateAddressAccess,
      updateAddressApproval,
      getAddressRecipient,
      destinationAddrSelect,
      clearDestinationAddrSelect,
      saveTableData,
      getSelectedAddressData,
      setTableOptions,
    },
    dispatch,
  ),
});

export default {
  AddressComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddressComponent),
  AddAddressComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddAddressComponent),
  InspectAddress: connect(mapStateToProps, mapDispatchToProps)(InspectAddress),
};
