import {React,useEffect,useState} from 'react';
import LoadingOverlay from "react-loading-overlay";

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";
import Stack from '@mui/material/Stack';
import TitleSearch from './TitleData';
import TitleViewData from './TitleViewData';
import Select from "react-select";


const Title = (props) => {

    const {actions,titleData} = props;
    const [searchFilter, setSearchFilter] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    const { t, i18n } = useTranslation();

   
    
    useEffect(() => {
        actions.getTitleData();
        setIsLoading(false);
       
      }, []);
    
    const cardTheme = {
        cardHeaderStylePref:{
           background: 'linear-gradient(to right bottom, #D8D8D8, #E0E0E0)',
           color: '#000000',
           height: 30,
           padding: 0,
         }
     }
  
     return(
        <>
              <LoadingOverlay active={isLoading} spinner>

        <Box sx={{ flexGrow: 1 }} >
         <Stack direction="row" spacing={1}> 
                <label className='title_main mt-1 ml-4' >
                {t("title.title")}
                </label>      

                           
                      
         </Stack>
         </Box>
    
        <Container maxWidth="xl">
           
            
            <Card variant="outlined"> 
            <CardHeader title={t("phase.SearchPlaceholder")} />
            <Divider />
            <CardContent>
                <TitleSearch   onSearch={(data) => setSearchFilter(data)}/>
            </CardContent>    
        </Card> 
  
        <br />
    
     
   
        
                
                <TitleViewData titleData={titleData} actions={actions}   searchFilter={searchFilter}   setIsLoading={setIsLoading}/>
         

        <br />
          
        </Container>
        
               
    <br/>
    <br/>
    <br />
    </LoadingOverlay>
    </>
    )

}

    
    
    
    
export default Title;