import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ShippingRequest from "./index";
import ShippingMeterials from "../../components/shippingMaterials";
import ReviewerTab from "../RegulatoryReviewer/ReviewerTab";
import { NAV_KEYS, USERROLES } from "../../constants";
import { useDispatch } from "react-redux";
import { setNavKey } from "../../redux/navbar/actions";
import Reports from "../Reports/reports";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));
    if (
      location.pathname === "/" &&
      userType.includes(USERROLES.PROCESSMANAGER)
    ) {
      history.push("/process/shipping");
      dispatch(setNavKey(NAV_KEYS.PROCESS_SHIPPING_REQUESTS));
    } else if (
      location.pathname === "/" &&
      userType.includes(USERROLES.ADMIN)
    ) {
      history.push("/approveAddress");
      dispatch(setNavKey(NAV_KEYS.APPROVALS_ADDRESS));
    } else if (
      location.pathname === "/" &&
      userType.includes(USERROLES.APPLICANT)
    ) {
      history.push("/myShippings");
      dispatch(setNavKey(NAV_KEYS.MY_SHIPPING_REQUESTS));
    } else if (
      location.pathname === "/" &&
      (userType.includes(USERROLES.REGULATORYREVIEWER1) ||
        userType.includes(USERROLES.REGULATORYREVIEWER2))
    ) {
      history.push("/regulatoryReviewer");
      dispatch(setNavKey(NAV_KEYS.REGULATORY_REVIEWER));
    } else if (
      location.pathname === "/" &&
      userType.includes(USERROLES.READONLY)
    ) {
      history.push("/process/shipping");
      dispatch(setNavKey(NAV_KEYS.PROCESS_SHIPPING_REQUESTS));
    } else if (
      location.pathname === "/" &&
      userType.includes(USERROLES.SYSTEMADMINISTRATOR)
    ) {
      history.push("/registereduser");
      dispatch(setNavKey(NAV_KEYS.ADMINISTRATION_REGISTURED_USER));
    }
  }, []);

  return (
    <>
      <div className="col-12 row">
        <Box className="row" sx={{}}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t("shipping_request.shipping_request")}
                {...a11yProps(0)}
              />
              <Tab label={t("navbar.material")} {...a11yProps(1)} />
              <Tab label={t("navbar.Reviewer")} {...a11yProps(2)} />
              <Tab label={"Reports"} {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ShippingRequest />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ShippingMeterials />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReviewerTab />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Reports />
          </TabPanel>
        </Box>
      </div>
    </>
  );
}
