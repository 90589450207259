import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import title from "../../../redux/title";
import brokerComponent from "./Broker";

const { getBrokerList, saveBroker, updateBrokerAccess } = title.actions;

const mapStateToProps = (state) => ({
  isLoader: state[title.name].isLoader,
  brokerData: state[title.name].brokerData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBrokerList,
      saveBroker,
      updateBrokerAccess,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(brokerComponent);
