import { React, useState, forwardRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Model from "../../shared/model";
import * as config from "../../../config";
import { get } from "lodash";
import moment from "moment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../../assests/images/editIcon.svg";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "./addressStyles";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { dateSortFunction, SortFunctionNew } from "../../../Services/util";
import { DateFormat,DateWithEndTime,DateWithDefaultTime } from "../../common/utils/DateUtil";
import AddressUtil from "../../common/utils/AddressUtil";
import {
  tableRowFormatter,
  tableRowOrderManager,
} from "../../common/utils/TableUtil";
import excelExport from "../../shared/excelExport.js"
import dateFormatter from "../../shared/dateFormatter.js";

const RecipientsListingComponent = (props) => {
  const pathRecipientURLs = [
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/address",
    "/materials",
    "/shippingRequests",
    "/myShippings",
    "/regulatoryReviewer",
    "/comments",
    "/alerts",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
  ]
  const [selected, setSelected] = useState("");
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  const { listData, actions, searchFilter, setIsLoading,tableRecipientOptions } = props;
  const [filteredData, setfilteredData] = useState([]);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] =
    useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");

  const classes = addressSearchStyles();
  const history = useHistory();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };

  useEffect(() => {
    calculateLengthOfSelectedRows();
  }, [selected]);
  const [tableConfig, setTableConfig] = useState({ page: 0, itemsPerPage: 10 });
  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey",
    );

    const filteredData =
      listData &&
      listData.filter((item) => {
        for (let searchParam of validSearchKeys) {
          if (["submitted", "approved", "valid"].includes(searchParam)) {
            if (item[searchParam] !== 1) {
              return false;
            } else {
              continue;
            }
          }

          if (["startDate", "endDate"].includes(searchParam)) {
            if (searchParam === "startDate") {
              const createDate = moment(item.created_on);
              const startDate = moment(searchFilter[searchParam]);
              if (createDate.isBefore(DateWithDefaultTime(startDate))) {
                return false;
              }
              continue;
            } else {
              const createDate = moment(item.created_on);
              const endDate = moment(searchFilter[searchParam]);
              if (createDate.isAfter(DateWithEndTime(endDate))) {
                return false;
              }
              continue;
            }
          }

          if (["full_address"].includes(searchParam)) {
            if (
              !new AddressUtil(item).toString() ||
              new AddressUtil(item).toString() !== searchFilter[searchParam]
            ) {
              return false;
            }
            continue;
          }

          if (
            !item[searchParam] ||
            !item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
        }
        return true;
      });

    setfilteredData(filteredData);
    setfilteredData(filteredData);
  }, [searchFilter, listData, tableConfig]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    return () => {
      if (pathRecipientURLs.includes(window.location.pathname)) {
        actions.setRecipentTableOptions({});
      }
    };
  }, []);
  
  const dispatRecipientAdminOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setRecipentTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableRecipientOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setRecipentTableOptions({
        pageNumber: 0,
        pageSize: tableRecipientOptions.pageSize,
        orderBy: value
      });
    }
  }
  const handleNewAddress = () => {
    history.push(`/addRecipients`);
  };
  const handleInspectAddress = () => {
    if (get(selected, "id", false)) {
      history.push(`inspectRecipients`, { id: get(selected, "id", null) });
      actions.saveTableData(selected);
    }
  };

  const calculateLengthOfSelectedRows = () => {
    if (selected) setSelectedRowsLength(1);
  };

  const handleCSVExport = async () => {
    let dataForExport = exportDataBuilder();
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Recipient_Data");
    writeFile(wb, requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData;
    if (filteredData.length === 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    } else if (filteredData.length > 0) {
      copyOfFilteredData = _.cloneDeep(filteredData);
    }

    copyOfFilteredData.forEach((item) => {
      //changing column names

      if (item.editdate) {
        item.editdate = DateFormat(item.editdate, i18n.resolvedLanguage);
      }
      if (item.shipment_date_mon) {
        item.shipment_date_mon = DateFormat(
          item.shipment_date_mon,
          i18n.resolvedLanguage,
        );
      }

      if (item.created_on) {
        item.created_on = DateFormat(item.created_on, i18n.resolvedLanguage);
      }
      if (item.date_of_recipient) {
        item.date_of_recipient = DateFormat(
          item.date_of_recipient,
          i18n.resolvedLanguage,
        );
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate, i18n.resolvedLanguage);
      }
      if (item.shipment_date_mon) {
        item.shipment_date_mon = DateFormat(
          item.shipment_date_mon,
          i18n.resolvedLanguage,
        );
      }

      if (
        item.form_of_address ||
        item.title ||
        item.first_name ||
        item.last_name
      ) {
        item.form_of_address =
          (item.form_of_address === null ? " " : item.form_of_address) +
          " " +
          (item.title === null ? " " : item.title) +
          " " +
          (item.first_name === null ? " " : item.first_name) +
          " " +
          (item.last_name === null ? " " : item.last_name);
      }

      //      if(item.title){
      //       item.title=(item.title === null ? " ": item.title
      //       );
      //       debugger
      //     }
      //     if(item.first_name){
      //       item.first_name=(item.first_name === null ? " " : item.first_name
      //       );
      // } if(item.last_name){
      //   item.last_name=(item.last_name === null ? " ": item.last_name
      //   );
      // }

      item[t("recipient.name")] = item["form_of_address"];
      // + item["title"]+ item["first_name"]+ item["last_name"]
      item[t("recipient.Email")] = item["email"];
      item[t("recipient.Phone")] = item["phone"];

      item[t("recipient.Address")] = new AddressUtil(item).toString();
      item[t("recipient.Create Date")] = moment(item["created_on"]).format("DD-MMM-YYYY");
      item[t("address.Created User")] = item["created_by"];
      item[t("address.Created User")] = item["created_by"];
      item[t("address.Approved")] = item["approved"];
      item[t("phase.Valid")] = item["valid"];

      delete item["state"];
      delete item["id"];
      delete item["address_id"];
      delete item["title_id"];
      delete item["approved"];
      delete item["email"];
      delete item["first_name"];
      delete item["form_of_address"];
      delete item["last_name"];
      delete item["phone"];
      delete item["submitted"];
      delete item["valid"];
      delete item["created_on"];
      delete item["created_by"];
      delete item["updated_on"];
      delete item["updated_by"];
      delete item["is_active"];
      delete item["add_id"];
      delete item["country_id"];
      delete item["building"];
      delete item["city"];
      delete item["department"];
      delete item["entity"];
      delete item["postcode"];
      delete item["street"];
      delete item["sapnumber"];
      delete item["name_english"];
      delete item["sapnumber"];
      delete item["title"];
      delete item["name"];
      delete item["review_by"];

      for (let key in item) {
        if (item[key] === null) {
          item[key] = "";
        }
      }
    });
    return _.cloneDeep(copyOfFilteredData);
  };

  const handleApprove = () => {
    let addressData = {};
    addressData = {
      id: selected.id,
      data: 1,
      createdBy: userId,
    };
    setIsLoading(true);
    actions.updateRecipientsApproval(addressData).then(() => {
      setfilteredData([]);
      setSelected("");
      actions.getAllrecipients().then(() => {
        setIsLoading(false);
      });
    });
  };

  const handleActivate = () => {
    let addressData = {};
    if (get(selected, "valid", 0) === 1) {
      addressData = {
        id: selected.id,
        data: 0,
      };
    } else {
      addressData = {
        id: selected.id,
        data: 1,
      };
    }
    setIsLoading(true);
    actions.updateRecipientsAccess(addressData).then(() => {
      setfilteredData([]);
      setSelected("");
      actions.getAllrecipients().then(() => {
        setIsLoading(false);
      });
    });
  };
  const handleRadiobuttonChange = (data) => {
    setSelected(data);
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };

  const ExportBtn = () => {
    return (
      <button
        className="mr-1"
        onClick={() => {
          setIsExportModalVisible(true);
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };

  const ExportSelectedBtn = () => {
    let excelDataProperty = [
      { "displayName": "Title", "dbColumn": "title", "width": 20 },
      { "displayName": "First Name", "dbColumn": "first_name", "width": 20 },
      { "displayName": "Last Name", "dbColumn": "last_name", "width": 20 },
      { "displayName": "Email", "dbColumn": "email", "width": 50 },
      { "displayName": "Phone", "dbColumn": "phone", "width": 20 },
      { "displayName": "Company/University", "dbColumn": "entity", "width": 20 },
      { "displayName": "Department", "dbColumn": "department", "width": 20 },
      { "displayName": "Building", "dbColumn": "building", "width": 10 },
      { "displayName": "Street", "dbColumn": "street", "width": 10 },
      { "displayName": "Postal Code", "dbColumn": "postcode", "width": 10 },
      { "displayName": "City", "dbColumn": "city", "width": 10 },
      { "displayName": "State", "dbColumn": "state", "width": 20 },
      { "displayName": "Country", "dbColumn": "name_english", "width": 10 },
      { "displayName": "Created By", "dbColumn": "created_by", "width": 50 },
      { "displayName": "Create On", "dbColumn": "created_on", "width": 15 },
      { "displayName": "Updated By", "dbColumn": "updated_by", "width": 50 },
      { "displayName": "Updated On", "dbColumn": "updated_on", "width": 15 },
      { "displayName": "Valid", "dbColumn": "valid", "width": 10 },
    ];

    actions.getSelectedRecipientData(selected.id).then((response) => {
      let dataForExport = [];
      dataForExport.push(response.data[0]);
      dataForExport = dateFormatter.formateDateField(dataForExport);
      excelExport.exportAllData(excelDataProperty, dataForExport, "Recipient_Data", requiredFileName);
    });
  };
  const ExportSelectedBtnForSingle = () => {
    return (
      <button
        disabled={get(selected, "id", 0) === 0 ? true : false}
        onClick={(e) => {
          setIsExportModalVisibleSingle(true);
        }}
      >
        {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>

        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  {t("exportLabel.fileName")}:
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control custom-form-control"
                  value={requiredFileName}
                  onChange={(e) => {
                    setrequiredFileName(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn();
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <>
      <div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
            <DropdownButton
              title={t("shippingrequest.export")}
              className="mr-1 exportdropdown"
            >
              <Dropdown.Item>{ExportBtn()}</Dropdown.Item>
              <Dropdown.Item>{ExportSelectedBtnForSingle()}</Dropdown.Item>
            </DropdownButton>

            <button
              className="btn btn-primary btn-inner-close new_button mr-1"
              disabled={get(selected, "id", 0) === 0 ? true : false}
              onClick={() => handleInspectAddress()}
            >
              {t("address.inspect")}
            </button>
            <button
              className="btn btn-primary btn-inner-close new_button mr-1"
              onClick={() => handleNewAddress()}
            >
              {t("address.new")}
            </button>
            <button
              className="btn btn-inner-approve   new_button mr-1"
              onClick={() => handleApprove()}
              disabled={get(selected, "id", 0) === 0 ? true : get(selected, "approved", 0) === 1 ? true : false}
            >
              {t("recipient.approve")}
            </button>
            <button
              className="btn btn-inner-approve   new_button "
              onClick={() => handleActivate()}
              disabled={get(selected, "id", 0) === 0 ? true : false}
            >
              {get(selected, "valid", 0) === 1
                ? t("address.deactivate")
                : t("address.activate")}
            </button>
            {/* <TextField size="small" placeholder="Search" /> */}
          </div>
        </div>
      </div>
      <Card variant="outlined">
     
        <CardHeader
          title={t("phase.searchresults")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
          {filteredData?.length>0 &&
            <MaterialTable
             onPageChange={(page, itemsPerPage) => {
              dispatRecipientAdminOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
            }}
            onOrderCollectionChange={(orderBy) => {
              dispatRecipientAdminOptions({ target: "sort" }, orderBy);
            }}
              columns={[
                {
                  width: "4%",
                  sorting: false,
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                },
                {
                  title: <span>{t("recipient.name")}</span>,
                  customSort: (a, b) => {
                    let title_a = a.title === null ? "" : a.title;
                    let title_b = b.title === null ? "" : b.title;
                    let form_of_address_a =
                      a.form_of_address === null ? "" : a.form_of_address;
                    let form_of_address_b =
                      b.form_of_address === null ? "" : b.form_of_address;
                    let first = `${form_of_address_a}, ${title_a}, ${a.first_name}, ${a.last_name}`;
                    let second = `${form_of_address_b}, ${title_b}, ${b.first_name}, ${b.last_name}`;
                    return first.localeCompare(second);
                  },
                  render: (rowData) => {
                    let form_of =
                      rowData.form_of_address === null
                        ? ""
                        : rowData.form_of_address;
                    let title = rowData.title === null ? "" : rowData.title;
                    return (
                      <span>
                        {form_of +
                          " " +
                          title +
                          " " +
                          rowData.first_name +
                          " " +
                          rowData.last_name}
                      </span>
                    );
                  },
                },

                {
                  title: <span>{t("recipient.Email")}</span>,
                  field: "email",
                  customSort: SortFunctionNew("email"),
                },
                {
                  title: <span>{t("recipient.Phone")}</span>,
                  field: "phone",
                  customSort: SortFunctionNew("phone"),
                },
                {
                  title: <span>{t("recipient.Address")}</span>,
                  customSort: (a, b) =>
                    new AddressUtil(a).compare(new AddressUtil(b)),
                  render: (rowData) => new AddressUtil(rowData).toString(),
                },
                {
                  title: <span>{t("recipient.Create Date")}</span>,
                  customSort: dateSortFunction("created_on"),
                  render: (rowData) => dateLocalization(rowData.created_on),
                },
                {
                  title: <span>{t("address.Created User")}</span>,
                  field: "created_by",
                  customSort: SortFunctionNew("created_by"),
                },
                {
                  title: <span>{t("address.Approved")}</span>,
                  customSort: (a, b) => {
                    if (a.approved > b.approved) {
                      return -1;
                    } else if (a.approved < b.approved) {
                      return 1;
                    } else if (a.approved === b.approved) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.approved === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span>{t("address.Valid")}</span>,
                  customSort: (a, b) => {
                    if (a.valid > b.valid) {
                      return -1;
                    } else if (a.valid < b.valid) {
                      return 1;
                    } else if (a.valid === b.valid) {
                      return 0;
                    } else {
                      return 0;
                    }
                  },
                  render: (rowData) =>
                    rowData.valid === 1 ? (
                      <span>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            disabled
                          />
                        </div>
                      </span>
                    ) : (
                      <div>
                        <span>
                          <div>
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              disabled
                            />
                          </div>
                        </span>
                      </div>
                    ),
                },
                {
                  title: <span></span>,
                  sorting: false,
                  render: (rowData) => (
                    <Link
                      to={{
                        pathname: `/editRecipients`,
                        state: { id: rowData.id },
                      }}
                      onClick={() => actions.saveTableData(rowData)}
                      className={classes.accordionLink}
                    >
                      <img alt="t" className="" src={editIcon}></img>
                    </Link>
                  ),
                },
              ]}
              data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                initialPage: parseInt(tableRecipientOptions?.pageNumber ? tableRecipientOptions?.pageNumber : 0),
               
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: parseInt(tableRecipientOptions?.pageSize ? tableRecipientOptions?.pageSize : 10),
                defaultOrderByCollection: tableRecipientOptions?.orderBy ? tableRecipientOptions?.orderBy : "",
                
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />}
             {!filteredData?.length  &&
          <div>
             <MaterialTable
             onPageChange={(page, itemsPerPage) => {
              dispatRecipientAdminOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
            }}
            onOrderCollectionChange={(orderBy) => {
              dispatRecipientAdminOptions({ target: "sort" }, orderBy);
            }}
              columns={[
                {
                  width: "4%",
                  sorting: false,
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                },
                {
                  title: <span>{t("recipient.name")}</span>,
                
                },

                {
                  title: <span>{t("recipient.Email")}</span>,
                
                },
                {
                  title: <span>{t("recipient.Phone")}</span>,
                 
                },
                {
                  title: <span>{t("recipient.Address")}</span>,
                  
                },
                {
                  title: <span>{t("recipient.Create Date")}</span>,
                
                },
                {
                  title: <span>{t("address.Created User")}</span>,
                
                },
                {
                  title: <span>{t("address.Approved")}</span>,
                 
                 
                },
                {
                  title: <span>{t("address.Valid")}</span>,
                
                },
              
              ]}
              data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                initialPage: parseInt(tableRecipientOptions?.pageNumber ? tableRecipientOptions?.pageNumber : 0),
               
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: parseInt(tableRecipientOptions?.pageSize ? tableRecipientOptions?.pageSize : 10),
                defaultOrderByCollection: tableRecipientOptions?.orderBy ? tableRecipientOptions?.orderBy : "",
                
                pageSizeOptions: [10, 20, 30, 40, 50],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
          </div>
        }
          </div>
        </CardContent>
      </Card>
      <Model
        showheader={isExportModalVisible}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBody()}
        modalfooter={exportModalFooter()}
      ></Model>
      <Model
        showheader={isExportModalVisibleSingle}
        modalHeader={t("exportLabel.title")}
        modalbody={exportModalBodySingleData()}
        modalfooter={exportModalFooterSingleData()}
      ></Model>
    </>
  );
};

export default RecipientsListingComponent;
