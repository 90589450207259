import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { reject, uniqBy, toLower as dashLower } from "lodash";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles, styled } from "@material-ui/core/styles";
import { authContext } from "../../adalConfig";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import DropDownSearch from "../common/DropDownSearch";
import CustomizedTables from "../common/CustomizedTables";

const styles = {
  header: {
    width: "100%",
    height: 30,
    backgroundColor: "#dfdfdf",
  },
  Addbtn: {
    float: "right",
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#98AFC7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AddDeputiesComponent = (props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [cwids, setCwids] = useState([]);
  const [shippingDeputieList, setShippingDeputieList] = useState([]);
  const { actions, shippingDeputies, classes, shippingDeputySelect } = props;

  const history = useHistory();
  const { instance } = useMsal();
  const userId = instance.getActiveAccount().username;
  useEffect(() => {
    actions.getDeputies();
    if (cwids.length === 0) {
      setCwids(shippingDeputySelect);
    }
  }, []);
  useEffect(() => {
    const data = reject(shippingDeputies, function (o) {
      return dashLower(o.label) === dashLower(userId);
    });
    const final = uniqBy(data, "label");
    setShippingDeputieList(
      final.sort((a, b) => {
        a = a.value.toLowerCase();
        b = b.value.toLowerCase();
        if (a === b) return 0;
        return a < b ? -1 : 1;
      }),
    );
  }, [shippingDeputies]);

  const handleDeputsValue = (value) => {
    setValue(value);
  };

  const handleAddDeputy = () => {
    if (value === "") {
      return true;
    }

    const findId = cwids.find((f) => f.id === value.id) || {};

    if (findId.hasOwnProperty("id")) {
      return true;
    }

    setCwids([...cwids, value]);
  };

  const handleDeputyRemove = (data) => {
    const removeDepute = cwids.filter((f) => f.id !== data.id);
    setCwids(removeDepute);
  };

  const handleDeputeSave = () => {
    if (cwids.length === 0) {
      actions.addShippingDeputiesDetails(cwids);
    }
    actions.addShippingDeputies(cwids);

    history.push("/shipping");
  };

  const handleDeputeCancel = () => {
    //actions.clearShippingDeputies();
    history.push("/shipping");
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} m={2} pt={1}>
        <Card variant="outlined">
          <CardContent className="p-2 notice">
            <Alert severity="warning">
              {t("shipping_request.save_shipping_request_alert")}
              <br></br>
            </Alert>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ flexGrow: 1 }} m={2} pt={1}>
        <Card variant="outlined">
          <CardContent className="p-2 notice">
            <p>{t("delegate.notice1")}</p>

            <p>{t("delegate.notice2")}</p>

            <p>{t("delegate.notice3")}</p>
          </CardContent>
        </Card>
      </Box>

      <div className="row col-12 no_padding no-marggin">
        <div className="col-lg-4 col-12 ">
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography className="ml-2">
                  {" "}
                  {t("delegate.insertDelegate")}{" "}
                </Typography>
              }
              className={classes.header}
            />
            <div className="p10">
              <CardContent>
                <DropDownSearch
                  list={shippingDeputieList}
                  label="Email"
                  handleChange={handleDeputsValue}
                  value={value}
                />
              </CardContent>
            </div>
          </Card>
          <Button
            className={classes.Addbtn + " Savebutton mt-2"}
            variant="contained"
            size="small"
            onClick={handleAddDeputy}
          >
            {" "}
            {t("delegate.addButton")}
          </Button>
        </div>
        <div className="col-lg-8  col-12 ">
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography className="ml-2">
                  {" "}
                  {t("delegate.heading")}{" "}
                </Typography>
              }
              className={classes.header}
            />
            <CardContent>
              <CustomizedTables
                list={cwids}
                handleDeputyRemove={handleDeputyRemove}
              >
                <StyledTableCell>{"remove"}</StyledTableCell>
              </CustomizedTables>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-end mt-3">
        <Stack direction="row" spacing={2} className={classes.Addbtn}>
          <Button
            variant="contained"
            size="small"
            onClick={handleDeputeSave}
            className="Savebutton"
          >
            {" "}
            {t("commonlabels.saveButton")}{" "}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleDeputeCancel}
            className="cancelbutton"
          >
            {" "}
            {t("commonlabels.cancelbutton")}{" "}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default withStyles(styles)(AddDeputiesComponent);
