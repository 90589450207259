import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import shipping from "../../redux/shipping";
import ShippingRequestComponent from "./ShippingRequestComponent";
import navbar from "../../redux/navbar";
import recipients from "../../redux/recipients";

import regulatory from "../../redux/regulatory";
import address from "../../redux/address";

const { getAllShippingData, getRegulatoryReviewer1List,setDelegateTableOptions } = regulatory.actions;

const {
  getCountries,
  isLoader,
  getCities,
  getShippingStatus,
  getDistributionTemperature,
  getDestinationAddress,
  getDestinationAddrList,
  destinationAddrSelect,
  clearDestinationAddrSelect,
  getFinalRecipients,
  getMyFinalRecipients,
  clearFinalRecipientSelect,
  getInternalOrderIdValue,
  getShipmentSpecificValue,
  getShippingCreateDateValue,
  getDistributionTempValue,
  getShippingCreateCommentValue,
  createShipping,
  SubmitUpdate,
  getShippings,
  addMaterialShippingRequest,
  addDeputiesShippingRequest,
  finalRecipientSelect,
  addShippingDeputies,
  updateShipping,
  clearShippingMaterialSelectedValue,
  addShippingMaterialSelect,
  getSapDeliveryNumValue,
  getDispatchMONValue,
  getDispatchLEVValue,
  getDateOfReceiptValue,
  getTrackingNumValue,
  getStatusQuoValue,
  getShippingRequestDetails,
  shippingUnderReviewRequestDetails,
  getDeputyShippingDetails,
  getMaterialShippingDetails,
  clearshippingRequest,
  shippingHistory,
  redirectAction,
  deleteDeputiesShippingRequest,
  setAttachments,
  getShippingAttachment,
  setShipper,
  getShipperDetails,
  setExtraData,
  getAllCurrency,
  getAllUnits,
  getAllBrokers,
  setAddressSearchFields,
  getPhases,
  getDestinationAddressSearchOptions,
  searchByStudyNumber,
  getShippingType,
  getIncoterm,
  getImpoter,
  setMailLinkData,
  getShippingActionType ,
  setValidMaterialNeedDate,
  setShippingSearchData,
  setTableOptions,
  setAdminShippingTableOptions,
  updateAssignedReviewer,
  getLogisticsParterDictionaryData,
  getAllContainerList,
  setClientTimeZone
} = shipping.actions;

const { setNavKey } = navbar.actions;

const { getAllrecipients } = recipients.actions;
const { getAllAddressList } = address.actions;

const mapStateToProps = (state) => ({
  countries: state[shipping.name].countries,
  cities: state[shipping.name].cities,
  distributionTemperature: state[shipping.name].distributionTemperature,
  destinationAddrList: state[shipping.name].destinationAddrList,
  destinationAddrSelect: state[shipping.name].destinationAddrSelect,
  finalRecipients: state[shipping.name].finalRecipients,
  finalRecipientSelect: state[shipping.name].finalRecipientSelect,
  shippingDeputySelect: state[shipping.name].shippingDeputySelect,
  internalOrderIdValue: state[shipping.name].internalOrderIdValue,
  shipmentSpecificValue: state[shipping.name].shipmentSpecificValue,
  shippingCreateDateValue: state[shipping.name].shippingCreateDateValue,
  distributionTempValue: state[shipping.name].distributionTempValue,
  shippingCreateCommentValue: state[shipping.name].shippingCreateCommentValue,
  getShippingResponse: state[shipping.name].getShippingResponse,
  shippingList: state[shipping.name].shippingList,
  shippingMaterialSelectedValue:
    state[shipping.name].shippingMaterialSelectedValue,
  materialShippingResponse: state[shipping.name].materialShippingResponse,
  deputiesShippingResponse: state[shipping.name].deputiesShippingResponse,
  shippingRequestDetails: state[shipping.name].shippingRequestDetails,
  shippingUnderReviewRequestDetails: state[shipping.name].shippingUnderReviewRequestDetails,
  deputyShippingDetails: state[shipping.name].deputyShippingDetails,
  materialShippingDetails: state[shipping.name].materialShippingDetails,
  shippingActionType: state[shipping.name].shippingActionType,
  updateShipping: state[shipping.name].updateShipping,
  sapDeliveryNumValue: state[shipping.name].sapDeliveryNumValue,
  dispatchMONValue: state[shipping.name].dispatchMONValue,
  dispatchLEVValue: state[shipping.name].dispatchLEVValue,
  dateOfReceiptValue: state[shipping.name].dateOfReceiptValue,
  trackingNumValue: state[shipping.name].trackingNumValue,
  statusQuoValue: state[shipping.name].statusQuoValue,
  shippingMaterialSelect: state[shipping.name].shippingMaterialSelect,
  redirectTo: state[shipping.name].redirectTo,
  errorMessage: state[shipping.name].errorMessage,
  isLoader: state[shipping.name].isLoader,
  attachements: state[shipping.name].attachements,
  shipper: state[shipping.name].shipper,
  extra: state[shipping.name].extra,
  allCurrency: state[shipping.name].allCurrency,
  allUnits: state[shipping.name].allUnits,
  brokers: state[shipping.name].brokers,
  addressSearchFields: state[shipping.name].addressSearchFields,
  destinationAddressSearchOptions:
    state[shipping.name].destinationAddressSearchOptions,
  allRecipients: state[recipients.name].allRecipients,
  allAddressList: state[address.name].allAddressList,
  shippingType: state[shipping.name].shippingType,
  incoterms: state[shipping.name].incoterms,
  impoter: state[shipping.name].impoter,
  regulatoryReviewer1List: state[regulatory.name].regulatoryReviewer1List,
  mailLinkData: state[shipping.name].mailLinkData,
  phases: state[shipping.name].phases,
  shipperCountry:state[shipping.name].shipperCountry,
  newShippingRequestCommentsList: state[shipping.name].newShippingRequestCommentsList,
  tableDelegateOptions: state[regulatory.name].tableDelegateOptions,
  shippingSearchData: state[shipping.name].shippingSearchData,
  tableOptions: state[shipping.name].tableOptions,
  tableAdminShippingOptions: state[shipping.name].tableAdminShippingOptions,
  logisticPartnerDictionaryData: state[shipping.name].logisticPartnerDictionaryData,
  allContainerList: state[shipping.name].allContainerList,
  clientTimeZone : state[shipping.name].clientTimeZone
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setTableOptions,
      setAdminShippingTableOptions,
      setShippingSearchData,
      getAllShippingData,
      getCountries,
      isLoader,
      getCities,
      getShippingStatus,
      getDistributionTemperature,
      getDestinationAddress,
      getDestinationAddrList,
      destinationAddrSelect,
      clearDestinationAddrSelect,
      getFinalRecipients,
      getMyFinalRecipients,
      clearFinalRecipientSelect,
      getInternalOrderIdValue,
      getShipmentSpecificValue,
      getShippingCreateDateValue,
      getDistributionTempValue,
      getShippingCreateCommentValue,
      createShipping,
      SubmitUpdate,
      getShippings,
      addMaterialShippingRequest,
      addDeputiesShippingRequest,
      finalRecipientSelect,
      addShippingDeputies,
      updateShipping,
      clearShippingMaterialSelectedValue,
      addShippingMaterialSelect,
      getSapDeliveryNumValue,
      getDispatchMONValue,
      getDispatchLEVValue,
      getDateOfReceiptValue,
      getTrackingNumValue,
      getStatusQuoValue,
      getShippingRequestDetails,
      shippingUnderReviewRequestDetails,
      getDeputyShippingDetails,
      getMaterialShippingDetails,
      clearshippingRequest,
      shippingHistory,
      redirectAction,
      deleteDeputiesShippingRequest,
      setAttachments,
      getShippingAttachment,
      setShipper,
      getShipperDetails,
      setExtraData,
      getAllCurrency,
      getAllUnits,
      getAllBrokers,
      setAddressSearchFields,
      getPhases,
      getDestinationAddressSearchOptions,
      setNavKey,
      getAllrecipients,
      getAllAddressList,
      searchByStudyNumber,
      getShippingType,
      getIncoterm,
      getImpoter,
      getRegulatoryReviewer1List,
      setMailLinkData,
      getShippingActionType ,
      setValidMaterialNeedDate,
      setDelegateTableOptions,
      updateAssignedReviewer,
      getLogisticsParterDictionaryData,
      getAllContainerList,
      setClientTimeZone
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingRequestComponent);
