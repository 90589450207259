import * as React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import holidays from "date-holidays";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import moment from "moment";

const CustomDate = (props) => {
  const {
    handleDateChange,
    dateValue,
    calenderFor,
    shippmentType,
    material_avail_date,
    Extra,
    setValidMatNeedDate,
    shipActions,
    excludeDates = []
  } = props;

  const [allowDates,setAllowDates] = useState({
    matavail:false,
    mateneed:false
  })

  registerLocale("de", de);

  const { t, i18n } = useTranslation();

  const getUtcTime = (date) => {
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
     const utcTime = moment(newDate).utc().format('YYYY-MM-DDTHH:mm:ss');
    return new Date(utcTime)
  }
 
  
  let selectedDate =
    dateValue && dateValue !== "Invalid date" ? getUtcTime(dateValue) : ""
  // if (dateValue.includes("Invalid date")) {
  //   selectedDate = "";
  // } else if (typeof dateValue === "string" && dateValue) {
  //   selectedDate = new Date(dateValue);
  // }

  const isWeekend = (date) => {
    if (new Date(date).getDay() == 0 || new Date(date).getDay() == 6) {
      return true;
    }
  };
  const filterDatesMatNeed = (date)=>{
    if(Extra.date_material_available_to_ship){
      if(new Date(date).getTime() < new Date(Extra.date_material_available_to_ship).getTime()){
        return true;
      }
    }
    if(!isWeekend(date)){
      return false;
    }
    else{
      return true;
    }
  }
  const filterDatesMatAvail  = (date) =>{
    if(new Date(date).getTime() < (new Date().getTime())-(24*60*60*1000)){
      return true;
    }
    else{
      return false;
    }
  }

  const handleCalenderOpen = () =>{
    if(calenderFor === 'date_material_available_to_ship'){
      if(Extra.type_of_shipment){
        setAllowDates({...allowDates,matavail:true})
      }
      else{
        toast.error("Please Choose the Type of Shipment to Continue")
        setAllowDates({...allowDates,matavail:false})
      }
    }
    else if(calenderFor === "date_material_needed_on_site"){
      if(Extra.type_of_shipment && Extra.date_material_available_to_ship){
        setAllowDates({...allowDates,['matneed']:true})
      }
      else{
        if(Extra.type_of_shipment == "" && Extra.date_material_available_to_ship == ""){
          toast.error("Please Choose Type of Shipment and Date Material Available to Ship to continue")
          setAllowDates({...allowDates,['matneed']:false})
        }
        else if(Extra.type_of_shipment == ""){
          toast.error("Please  Choose Type of Shipment to continue")
          setAllowDates({...allowDates,['matneed']:false})
        }
        else{
          toast.error("Please Choose Date Material Available to continue")
          setAllowDates({...allowDates,['matneed']:false})
        }
      }
    }
  }

  const handleChange = (date) =>{
 
    if(calenderFor === 'date_material_available_to_ship'){
      if(allowDates.matavail){
        handleDateChange(date)
      }
    }
    else if(calenderFor === 'date_material_needed_on_site'){
      if(allowDates.matneed){
        handleDateChange(date)
      }
    }
    else{
      handleDateChange(date)
    }
  }


  return (
    <>
      {/* <TextField
        id="date"
        variant="outlined"
        margin="normal"
        size="small"
        type="date"
        label="date"
        value = { dateValue }
        defaultValue={defaultDate}
        sx={{ width: 220 }}
        onChange = {handleDateChange}
        InputLabelProps={{
          shrink: true,
        }} 
      /> */}
      <DatePicker
        locale={i18n.resolvedLanguage}
        className="form-control custom-form-control my-icon mb-2 mr-sm-4"
        margin="normal"
        variant="outlined"
        size="small"
        dateFormat="dd-MMM-yyyy"
        popperModifiers={{
          flip: { behavior: ["bottom"] },
          preventOverflow: { enabled: false },
          hide: { enabled: false },
        }}
        selected={selectedDate}
        showYearDropdown
        showMonthDropdown
        onCalendarOpen={handleCalenderOpen}
        dropdownMode="select"
        placeholderText={t("commonlabels.date")}
        portalId="9999"
        excludeDates={
          calenderFor === "date_material_needed_on_site" ? excludeDates : []
        }
        filterDate={(date) => {
          return !(calenderFor === "date_material_needed_on_site"
            ? filterDatesMatNeed(date)
            : (calenderFor === 'date_material_available_to_ship')?filterDatesMatAvail(date):false);
        }}
        onChange={(date)=>{handleChange(date)}}
        {...props}
      />

    </>
  );
};

export default CustomDate;
