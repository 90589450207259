import types from "./types";
import fetchData from "../util";

const getAllrecipients = () => {
  return fetchData(types.GET_ALL_RECIPIENTS, "getAllrecipients");
};
const getAllTitle = () => {
  return fetchData(types.GET_ALL_TITLE, "getAllTitle");
};
const saveRecipients = (...params) => {
  return fetchData(types.SAVE_RECIPIENTS, "saveRecipients", params);
};
const updateRecipients = (...params) => {
  return fetchData(types.SAVE_RECIPIENTS, "updateRecipients", params);
};
const updateRecipientsAccess = (...params) => {
  return fetchData(types.SAVE_RECIPIENTS, "updateRecipientsAccess", params);
};
const updateRecipientsApproval = (...params) => {
  return fetchData(types.SAVE_RECIPIENTS, "updateRecipientsApproval", params);
};

const getSelectedRecipientData = (...params) => {
  return fetchData(types.GET_SELECTED_RECIPIENT_DATA, "getSelectedRecipientData", params);
}

const setRecipientSearchData = (data)=>{
  return {
    type: types.SET_RECIPIENT_SEARCH_DATA,
    payload: data
  }
}
const isLoader = (payload = {}) => {
  return {
    type: types.IS_LOADER,
    payload,
  };
};
const setRecipentTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_RECIPIENT_TABLE_OPTIONS, payload: data });
  };
};
const setApproveRecipientTableOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_RECIPIENT_APPROVE_TABLE_OPTIONS, payload: data });
  };
};

export default {
  getAllrecipients,
  getAllTitle,
  saveRecipients,
  updateRecipients,
  updateRecipientsAccess,
  updateRecipientsApproval,
  isLoader,
  setRecipientSearchData,
  getSelectedRecipientData,
  setRecipentTableOptions,
  setApproveRecipientTableOptions
};
