import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import address from "../../redux/address";
import shipping from "../../redux/shipping";
import RegisteredUsersComponent from "./RegisteredUsersComponent";
const {
  getMasterDataRoles,
  editUser,
  saveUser,
  getAllAddress,
  saveAddress,
  getAddress,
  editAddress,
  updateAddressAccess,
  updateAddressApproval,
  getAddressRecipient,
  getAllCountry,
  getAllUsers,
} = address.actions;
const {
  getUserDetails,
  getCountries,
  getCities,
  getShippingsCountry,
  getShippingsTwoWeeks,
} = shipping.actions;

const mapStateToProps = (state) => ({
  allAddress: state[address.name].allAddress,
  isLoader: state[address.name].isLoader,
  countries: state[address.name].allCountry,
  cities: state[shipping.name].cities,
  ShippingsCountry: state[shipping.name].ShippingsCountry,
  ShippingsTwoWeeks: state[shipping.name].ShippingsTwoWeeks,
  allUsers: state[address.name].allUsers
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getMasterDataRoles,
      editUser,
      saveUser,
      getUserDetails,
      getAllAddress,
      getAllCountry,
      getAddress,
      getCountries,
      getCities,
      saveAddress,
      editAddress,
      updateAddressAccess,
      updateAddressApproval,
      getAddressRecipient,
      getShippingsCountry,
      getShippingsTwoWeeks,
      getAllUsers,
    },
    dispatch,
  ),
});

export default {
  RegisteredUsersComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RegisteredUsersComponent),
};
