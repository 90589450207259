import { React, useState, forwardRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { get } from "lodash";
import Model from "../shared/model";
import moment from "moment";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import MaterialTable from "@material-table/core";
import editIcon from "../../assests/images/editIcon.svg";
import { useTranslation } from "react-i18next";
import { addressSearchStyles } from "../Admin/Address/addressStyles";
import StatusFlow from "../shippingRequest/statusFlow";

import { INSPECT, SUBMIT, PROCESS_MANAGER, STATUS } from "../../constants";
import { utils, writeFile } from 'xlsx';
import _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as config from "../../config";
import AddressUtil from "../common/utils/AddressUtil";

import {DateFormat, DateWithDefaultTime, DateWithEndTime} from "../common/utils/DateUtil";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { SortFunctionNew, dateSortFunction } from "../../Services/util";
import { USERROLES } from "../../constants";
import { tableRowFormatter, tableRowOrderManager } from "../common/utils/TableUtil";

const RequlatoryListing = (props) => {
  const pathRegulatoryURLs = [
    "/process/shipping",
    "/requestByDelegate",
    "/approveAddress",
    "/approve/materials",
    "/approve/recipients",
    "/address",
    "/materials",
    "/recipients",
    "/shippingRequests",
    "/myShippings",
    "/comments",
    "/alerts",
    "/countrycatalouge",
    "/registereduser",
    "/queriesCountry",
    "/queriesCountryTwoWeeks",
    "/myAddress",
    "/my/application/materials",
    "/my/application/recipients",
    "/contact",
    "/phase",
    "/broker",
    "/container",
    "/add-country",
    "/currency",
    "/distributiontemperature",
    "/importerOfRecord",
    "/incoterm",
    "/shippingType",
    "/title",
    "/units",
    "/logistics",
   ];
  const userType = JSON.parse(localStorage.getItem("userType"));
  const [selected, setSelected] = useState("");
  const history = useHistory();
  const [rowShippingData,setRowShippingData] = useState({});
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [requiredFileName, setrequiredFileName] = useState("");

  const [isExportModalVisibleSingle, setIsExportModalVisibleSingle] = useState(false);
  const { listData, actions,tableRegOptions, searchFilter, setIsLoading, allMaterials,regulatoryReviewer1List } = props;
  
  const [filteredData, setfilteredData] = useState(listData);
  const [actionName, setActionName] = useState("");
  const [shippingDetail, setShippingDetail] = useState({});
  const classes = addressSearchStyles();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const cardTheme = {
    cardHeaderStylePref: {
      background: "linear-gradient(to right bottom, #D8D8D8, #E0E0E0)",
      color: "#000000",
      height: 30,
      padding: 0,
    },
  };



  useEffect(() => {
    let validSearchKeys = Object.keys(searchFilter).filter(
      (key) => !!searchFilter[key] && key !== "searchKey",
    );

    let matchedShippmentIds = false;
    if (validSearchKeys.includes("material")) {
      matchedShippmentIds = allMaterials
        .filter((item) =>
          item.description
            .toLowerCase()
            .includes(searchFilter.description.toLowerCase()),
        )
        .map((item) => item.shipping_request_id);
    }

    let finalListData;

    if (
      userType.includes(USERROLES.REGULATORYREVIEWER1) &&
      userType.includes(USERROLES.REGULATORYREVIEWER2)
    ) {
      finalListData = listData.filter(
        (x) =>
          x.status === "Regulatory Reviewer 1" ||
          x.status === "Regulatory Reviewer 2",
      );
    } else if (userType.includes(USERROLES.REGULATORYREVIEWER1)) {
      finalListData = listData.filter(
        (x) => x.status === "Regulatory Reviewer 1",
      );
    } else if (userType.includes(USERROLES.REGULATORYREVIEWER2)) {
      finalListData = listData.filter(
        (x) => x.status === "Regulatory Reviewer 2",
      );
    }
    const filteredData = finalListData.filter((item) => {
      if (matchedShippmentIds && !matchedShippmentIds.includes(item.id)) {
        return false;
      }

      for (let searchParam of validSearchKeys) {
        if (["submitted", "approved", "valid"].includes(searchParam)) {
          if (item[searchParam] !== 1) {
            return false;
          } else {
            continue;
          }
        }

        if (["startDate", "endDate"].includes(searchParam)) {
          if (!item.created_date) {
            return false;
          }
          const createDate = moment(item.created_date);
          const date = moment(searchFilter[searchParam]);
          if (searchParam === "startDate") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        if (
          ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
        ) {
          if (!item.dateofrecipient) {
            return false;
          }
          const createDate = moment(item.dateofrecipient);
          const date = moment(searchFilter[searchParam]);
          if (searchParam === "shipmentDateMomStart") {
            if (createDate.isBefore(DateWithDefaultTime(date))) {
              return false;
            }
            continue;
          } else {
            if (createDate.isAfter(DateWithEndTime(date))) {
              return false;
            }
            continue;
          }
        }

        // if (
        //   ["shipmentDateMomStart", "shipmentDateMomEnd"].includes(searchParam)
        // ) {
        //   if (searchParam === "shipmentDateMomStart") {
        //     const createDate = moment(item.dateofrecipient);
        //     const startDate = moment(searchFilter[searchParam]);
        //     if (createDate.isBefore(startDate)) {
        //       return false;
        //     }
        //     continue;
        //   } else {
        //     const createDate = moment(item.dateofrecipient);
        //     const endDate = moment(searchFilter[searchParam]);
        //     if (createDate.isAfter(endDate)) {
        //       return false;
        //     }
        //     continue;
        //   }
        // }

        if (["final_receipt"].includes(searchParam)) {
          if (
            !item["finalrecipient"]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          ) {
            return false;
          }
          continue;
        }
        if (["address"].includes(searchParam)) {
          const countryName = _.isString(item["address"])
            ? item["address"].toLowerCase()
            : "";
          const fileterCountryName = _.isString(searchFilter[searchParam])
            ? searchFilter[searchParam].toLowerCase()
            : "";
          if (!countryName || !countryName.includes(fileterCountryName)) {
            return false;
          }
          continue;
        }
        if (["name_english"].includes(searchParam)) {
          const countryName = _.isString(item["name_english"])
            ? item["name_english"].toLowerCase()
            : "";
          const fileterCountryName = _.isString(searchFilter[searchParam])
            ? searchFilter[searchParam].toLowerCase()
            : "";
          if (!countryName || !countryName.includes(fileterCountryName)) {
            return false;
          }
          continue;
        }

        if (
          !(
            item[searchParam] &&
            item[searchParam]
              .toLowerCase()
              .includes(searchFilter[searchParam].toLowerCase())
          )
        ) {
          return false;
        }
      }
      return true;
    });

    setfilteredData(filteredData);
  }, [searchFilter, allMaterials, listData]);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {
      if (pathRegulatoryURLs.includes(window.location.pathname)) {
        actions.setRegulatoryTableOptions({});
        actions.setRegulatorySearchData({});
      }
    };
  }, []);
  const dispatchRegOptions = (event, value) => {
    if (event.target === "page") {
      return actions.setRegulatoryTableOptions({
        pageNumber: value.pageNumber,
        pageSize: value.pageSize,
        orderBy: tableRegOptions.orderBy
      });
    }
    if (event.target === "sort") {
      return actions.setRegulatoryTableOptions({
        pageNumber: 0,
        pageSize: tableRegOptions.pageSize,
        orderBy: value
      });
    }
  }
  const handleRadiobuttonChange = (data) => {
    setSelected(data);
  };
  const getStatusFlow = () => {
    if (actionName === INSPECT) {
      return (
        <StatusFlow
          row={selected}
          actionType={INSPECT}
          roleName={PROCESS_MANAGER}
          goBack="/requestByDelegate"
        />
      );
    }

    if (actionName === SUBMIT) {
      return (
        <StatusFlow
          row={shippingDetail}
          actionType={SUBMIT}
          roleName={PROCESS_MANAGER}
          goBack="/requestByDelegate"
        />
      );
    }
  };
  // const handlerShippingInspect = () => {
  //   // let rowData = {};
  //   // if (selectedRow.tableData.id === 0) {
  //   //   rowData = firstRowData;
  //   // }
  //   // if (shippingSelectedRow.tableData.id !== 0) {
  //   //   rowData = shippingSelectedRow;
  //   // }
  //   setActionName(INSPECT);
  //   setShippingDetail(selected);
  // };

  const handleCSVExport = async () => {
   
    let dataForExport = exportDataBuilder()
    let wb = utils.book_new(), ws = utils.json_to_sheet(dataForExport);
    utils.book_append_sheet(wb, ws, "Regulatory-Reveiwer-Data");
    writeFile(wb,  requiredFileName + ".xlsx");
  };

  const exportDataBuilder = () => {
    let copyOfFilteredData
    if (filteredData.length === 0) {
        copyOfFilteredData = _.cloneDeep(filteredData)
    }
    else if (filteredData.length > 0) {
        copyOfFilteredData = _.cloneDeep(filteredData)
    }
   
    copyOfFilteredData.forEach((item) => {
        //changing column names
          if (item.created_date) {
            item.created_date = DateFormat(item.created_date,i18n.resolvedLanguage);

          }
         
        if (item.dateofrecipient) {
          item.dateofrecipient = DateFormat(item.dateofrecipient,i18n.resolvedLanguage);
      }
      if (item.editdate) {
        item.editdate = DateFormat(item.editdate,i18n.resolvedLanguage);
    }
   

        item["Create Date"] = item["created_date"];
        item["Shipping Request #"] =  item["internal_distribution_number"];
        // item[t("Material")] = item["material"];
        item["Preferred Regulatory Reviewer"] = item ["prefered_reviewer"]
        item["Recipient "] = item["finalrecipient"];
        item["Address "] = item["address"];
        item["Date of Receipt"] = item["dateofrecipient"];
        item["Status"] = t("status."+item["status"]);
        item["Edit Date"] = item["editdate"];
        // item["Cost Center/WBS Code"] =item["code_center_shipment"]

        delete item["entity"];
        delete item["department"];
        delete item["postcode"];
        delete item["city"];
        delete item["nameenglish"];
        delete item["name_english"];
        delete item["code"];

        
        delete item["review1comments"];
        delete item["review2comments"];
        delete item["review1by"];
        delete item["review2by"];
        delete item["reviewer1recreatedate"];
        delete item["reviewer2recreatedate"];


        delete item["created_date"];
        delete item ["prefered_reviewer"]
        delete item["internal_distribution_number"];
        delete item["material"];

        
        delete item["finalrecipient"];
        delete item["address"];
        delete item["dateofrecipient"];
        
        delete item["status"];
        delete item["editdate"];
       
  

        delete item["id"];
        delete item["recievingcountry"];
        delete item["orgincountry"];
     
      
        for (let key in item) {
            if (item[key] === null) {
                item[key] = ""
            }
        }
    })
    return _.cloneDeep(copyOfFilteredData);
}
  const InspectBtn = ({ handleClick = {} }) => {

    return (
      <button
        
        onClick={() => { handleCSVExport(); setIsExportModalVisible(false); setrequiredFileName(""); }}>

      
{t("export.exportall")}
      </button>
    );
  };
  const ExportBtn = () => {
    return (
      <button
        className="mr-1"
        onClick={() => {
         
          setIsExportModalVisible(true);
          
        }}
      >
        {t("export.exportall")}
      </button>
    );
  };
  const inspectHideAndShow = () => {
    // if (
    //   firstRowData.status === STATUS.INPUT &&
    //   shippingSelectedRow.tableData.id === 0
    // ) {
    //   return <InspectBtn btnStatus={true} />;
    // }

    if (get(selected, "status", "INPUT") === STATUS.INPUT) {
      return <InspectBtn btnStatus={true} />;
    }

    return (
      <InspectBtn btnStatus={false}  />
    );
  };
  

  const ExportSelectedBtn = () => {
  
   
       window.location.href = config.baseURL + `/api/exportSelectedData/`+selected.id+'/' + requiredFileName + "/Regulatory_Reveiwer_Data";
         
     
    
  };
  // const inspectHideAndShow = () => {
  //   debugger;
  //   // if (
  //   //   firstRowData.status === STATUS.INPUT &&
  //   //   shippingSelectedRow.tableData.id === 0
  //   // ) {
  //   //   return <InspectBtn btnStatus={true} />;
  //   // }

  //   if (get(selected, "status", "INPUT") === STATUS.INPUT) {
  //     return <InspectBtn btnStatus={true} />;
  //   }

  //   return (
  //     <InspectBtn btnStatus={false}  />
  //   );
  // };
  

  const editShippment = async (row) => {
    setRowShippingData(row);
    actions.getShippingRequestDetails(row);
    actions.getDeputyShippingDetails(row);
    actions.getMaterialShippingDetails(row);
    actions.getShippingActionType("EDIT");
    actions.clearShippingMaterialSelectedValue();
    actions.addShippingMaterialSelect([]);
    actions.getShippingAttachment(row);
    actions.getShipperDetails(row);
    actions.saveTableData(row);
    actions.redirectAction('/regulatoryReviewer');
    history.push("/regulatoryReviewer/edit");
    
  };
  const dateLocalization = (date) => {
    if (!date) {
      return date;  
    }
    let newDate = date;
    if(new Date(date).getTimezoneOffset() < 0){
      let tmp = new Date(date)
      tmp.setMinutes(tmp.getMinutes() + Math.abs(tmp.getTimezoneOffset()))
      newDate = tmp
    }
      return moment(newDate).utc().format("DD-MMM-YYYY");
    
  };
  const ExportSelectedBtnForSingle= () => {
    return (
      <button
      disabled = {(selected === "")}
        onClick={(e) => {
        
             setIsExportModalVisibleSingle(true)
            
           
        }}
      >
           {t("export.exportselected")}
      </button>
    );
  };
  const exportModalBody = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>
              
             

            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooter = () => {
    return (
      <>
     
     
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            handleCSVExport();
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          {t("export.export")}
        </button>
       
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisible(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  const exportModalBodySingleData = () => {
    return (
      <div className="row  m0">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">{t("exportLabel.fileName")}:</label>&nbsp;
                <input type="text" className="form-control custom-form-control" value={requiredFileName} onChange={(e) => { setrequiredFileName(e.target.value) }}></input>
              </div>
              
             

            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportModalFooterSingleData = () => {
    return (
      <>
     
     
        <button
          type="button"
          id="Yes"
          disabled={requiredFileName.trim() !== "" ? false : true}
          className="btn btn-primary btn-inner-close"
          onClick={(e) => {
            ExportSelectedBtn()
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Export
        </button>
        <button
          type="button"
          id="Close"
          //disabled={isModalSaveRunning}
          className="btn btn-black btn-inner-close cancel_button"
          onClick={(e) => {
            setIsExportModalVisibleSingle(false);
            setrequiredFileName("");
          }}
        >
          Cancel
        </button>
      </>
    );
  };
  return (
    <>
      {Object.keys(shippingDetail).length > 0 &&
        shippingDetail.id !== 0 &&
        getStatusFlow()}
      <div className="row m0 no_padding container-fluid ">
        <div className="no_padding col-12 mt-3 mb-2 d-flex justify-content-end">
          <div className="buttonsec_animalmanagment">
          <DropdownButton  title={t("export.export")} className="mr-1 exportdropdown">
         <Dropdown.Item >{ExportBtn()}</Dropdown.Item>
         <Dropdown.Item >{ExportSelectedBtnForSingle()}</Dropdown.Item>
         </DropdownButton>
          </div>
        </div>
      </div>
      <Card variant="outlined">
        <CardHeader
          title={t("phase.searchresults")}
          style={cardTheme.cardHeaderStylePref}
        />
        <Divider />
        <CardContent>
          <div style={{ paddingBottom: "2%" }}>
         {filteredData?.length>0 && 
            <MaterialTable
            onPageChange={(page, itemsPerPage) => {
              dispatchRegOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
            }}
            onOrderCollectionChange={(orderBy) => {
              dispatchRegOptions({ target: "sort" }, orderBy);
            }}
              columns={[
                {
                  width: "8%",
                  sorting: false,
                  render: (rowData) => (
                    <input
                      type="radio"
                      value={get(selected, "id", "")}
                      name="radiovalues"
                      className={classes.tableRadio}
                      onChange={(e) => handleRadiobuttonChange(rowData)}
                    />
                  ),
                },
                {
                  
                  title: <span>{t("address.Create Date")}</span>,
                  customSort: dateSortFunction("created_date"),
                  render: (rowData) => 
                  <span>{rowData.created_date && dateLocalization(rowData.created_date)}</span>
                   
                },
                {
                  title: <span>{t("shipping_request.internal_order_id")}</span>,
                  field: "internal_distribution_number",
                  customSort: SortFunctionNew("internal_distribution_number"),
                },
                // {
                //   title: <span>{t("Material")}</span>,
                //   field: "material",
                //   customSort: SortFunctionNew("material"),
                  
                // },
                {
                  title: <span>{t("shipping_request.prefered_reviewer")}</span>,
                  field: "prefered_reviewer",
                  customSort: SortFunctionNew("prefered_reviewer"),
                },
                {
                  title: <span>{t("shippingrequest.finalrecipient")}</span>,
                  field: "finalrecipient",
                  customSort: SortFunctionNew("finalrecipient"),
                 
                  
                },
                {
                  title: <span>{t("recipient.Address")}</span>,
                  render: (rowData) =>
                    (
                      <>
                        {new AddressUtil(rowData).toString()}
                      </>
                    ),
                  customSort: (a, b) =>
                   (new AddressUtil(a)).compare(new AddressUtil(b)),
                },
                {
                  title: <span>{t("shipping_request.date_of_receipt")}</span>,
                  
                  customSort: dateSortFunction("dateofrecipient"),
                  render: (rowData) => 
                  <span>{rowData.dateofrecipient && dateLocalization(rowData.dateofrecipient)}</span>
                   
                },
               
                {
                  title: <span>{t("address.Status")}</span>,
                  // field: "status",
                  customSort: SortFunctionNew("status"),
                  render: (rowData) => t("status." + rowData.status),
                  // customSort: SortFunctionNew("status"),
                },
                {
                  title: <span>{t("process_shipping_request.edit_date")}</span>,
                   render: (rowData) => 
                  <span>{rowData.editdate && dateLocalization(rowData.editdate)}</span>,
                   customSort: dateSortFunction("editdate"),
                  

                },
                {
                  title: <span></span>,
                  sorting: false,
                  render: (rowData) => (
                    <span>
                      <Link onClick={() => editShippment(rowData)}>
                        <img alt="t" className="" src={editIcon}></img>{" "}
                      </Link>
                    </span>
                  ),
                },
              
              ]}
               data={(filteredData)}
              title="Render Image Preview"
              icons={tableIcons}
              localization={{
                pagination: {
                  labelRowsSelect: t("commonlabels.labelRowsSelect"),
                  firstAriaLabel: t("commonlabels.firstAriaLabel"),
                  firstTooltip: t("commonlabels.firstTooltip"),
                  previousAriaLabel: t("commonlabels.previousAriaLabel"),
                  previousTooltip: t("commonlabels.previousTooltip"),
                  nextAriaLabel: t("commonlabels.nextAriaLabel"),
                  nextTooltip: t("commonlabels.nextTooltip"),
                  lastAriaLabel: t("commonlabels.lastAriaLabel"),
                  lastTooltip: t("commonlabels.lastTooltip"),
                },
                body: {
                  emptyDataSourceMessage: t(
                    "commonlabels.emptyDataSourceMessage",
                  ),
                },
              }}
              options={{
                draggable: false,
                thirdSortClick: false,
                //  actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#cce3f5",
                  fontWeight: "bold",
                  height: "10px",
                },
                rowStyle: tableRowFormatter({
                  height: "30px",
                  padding: "0px",
                }),
                initialPage: parseInt(tableRegOptions?.pageNumber ? tableRegOptions?.pageNumber : 0),
                sortIcon: true,
                maxBodyHeight: "90%",
                tableLayout: "auto",
                sorting: true,
                search: false,
                selection: false,
                showTitle: false,
                toolbar: false,
                paging: true,
                paginationType: "stepped",
                pageSize: parseInt(tableRegOptions?.pageSize ? tableRegOptions?.pageSize : 10),
              defaultOrderByCollection: tableRegOptions?.orderBy ? tableRegOptions?.orderBy : "",
             
                pageSizeOptions: [10, 20, 30, 40,50],
                emptyRowsWhenPaging: false,
                showSelectAllCheckbox: false,
              }}
            />
}
{!filteredData?.length &&
 <MaterialTable
 onPageChange={(page, itemsPerPage) => {
   dispatchRegOptions({ target: "page" }, { "pageNumber": page, "pageSize": itemsPerPage });
 }}
 onOrderCollectionChange={(orderBy) => {
   dispatchRegOptions({ target: "sort" }, orderBy);
 }}
   columns={[
     {
       width: "8%",
       sorting: false,
       render: (rowData) => (
         <input
           type="radio"
           value={get(selected, "id", "")}
           name="radiovalues"
           className={classes.tableRadio}
           onChange={(e) => handleRadiobuttonChange(rowData)}
         />
       ),
     },
     {
       title: <span>{t("address.Create Date")}</span>,
     },
     {
       title: <span>{t("shipping_request.internal_order_id")}</span>,
     },
     {
       title: <span>{t("shipping_request.prefered_reviewer")}</span>,

     },
     {
       title: <span>{t("shippingrequest.finalrecipient")}</span>,
     },
     {
       title: <span>{t("recipient.Address")}</span>,
       
     },
     {
       title: <span>{t("shipping_request.date_of_receipt")}</span>,
       
     },
    
     {
       title: <span>{t("address.Status")}</span>,
      
     },
     {
       title: <span>{t("process_shipping_request.edit_date")}</span>,
     },
   
   ]}
    data={(filteredData)}
   title="Render Image Preview"
   icons={tableIcons}
   localization={{
     pagination: {
       labelRowsSelect: t("commonlabels.labelRowsSelect"),
       firstAriaLabel: t("commonlabels.firstAriaLabel"),
       firstTooltip: t("commonlabels.firstTooltip"),
       previousAriaLabel: t("commonlabels.previousAriaLabel"),
       previousTooltip: t("commonlabels.previousTooltip"),
       nextAriaLabel: t("commonlabels.nextAriaLabel"),
       nextTooltip: t("commonlabels.nextTooltip"),
       lastAriaLabel: t("commonlabels.lastAriaLabel"),
       lastTooltip: t("commonlabels.lastTooltip"),
     },
     body: {
       emptyDataSourceMessage: t(
         "commonlabels.emptyDataSourceMessage",
       ),
     },
   }}
   options={{
     draggable: false,
     thirdSortClick: false,
     //  actionsColumnIndex: -1,
     headerStyle: {
       backgroundColor: "#cce3f5",
       fontWeight: "bold",
       height: "10px",
     },
     rowStyle: tableRowFormatter({
       height: "30px",
       padding: "0px",
     }),
     initialPage: parseInt(tableRegOptions?.pageNumber ? tableRegOptions?.pageNumber : 0),
     sortIcon: true,
     maxBodyHeight: "90%",
     tableLayout: "auto",
     sorting: true,
     search: false,
     selection: false,
     showTitle: false,
     toolbar: false,
     paging: true,
     paginationType: "stepped",
     pageSize: parseInt(tableRegOptions?.pageSize ? tableRegOptions?.pageSize : 10),
   defaultOrderByCollection: tableRegOptions?.orderBy ? tableRegOptions?.orderBy : "",
  
     pageSizeOptions: [10, 20, 30, 40,50],
     emptyRowsWhenPaging: false,
     showSelectAllCheckbox: false,
   }}
 />

}
          </div>
        </CardContent>
      </Card>
      <Model
          showheader={isExportModalVisible}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBody()}
          modalfooter={exportModalFooter()}
        ></Model>
          <Model
          showheader={isExportModalVisibleSingle}
          modalHeader={t("exportLabel.title")}
          modalbody={exportModalBodySingleData()}
          modalfooter={exportModalFooterSingleData()}
        ></Model>
    </>
  );
};

export default RequlatoryListing;