import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Edit from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import moment from "moment";
import editIcon from "../../assests/images/editIcon.svg";

const styles = {
    header: {
        width: "100%",
        backgroundColor: "#dfdfdf",
    },
    Addbtn: {
        float: "right",
    },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#98AFC7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const DynamicTableComponent = ({
    tableColumns,
    tableRows = [],
    tableHeader = false,
    tableHeaderName,
    handleEditRow,
    shippingRequestStatus,
    readOnly
}) => {
    const { t } = useTranslation();

    return (
        <Card variant="outlined">
            {tableHeader && <CardHeader
                title={
                    <Typography className="heading_box">
                        {" "}
                        {t(tableHeaderName)}{" "}
                    </Typography>
                }
            />}
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map(item => (
                                        <StyledTableCell style={ item.style }>{t(item.name)}{" "}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows.map((item, key) => (
                                    <StyledTableRow key={`row-${item.id}-${key}`}>
                                        <StyledTableCell>{item.created_by}</StyledTableCell>
                                        <StyledTableCell>{moment(item.created_on).format("DD-MMM-YYYY")}</StyledTableCell>
                                        <StyledTableCell>{item.comment}</StyledTableCell>
                                        <StyledTableCell>
                                            {shippingRequestStatus !== "CLOSE" && !readOnly
                                                ? <span>
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() => handleEditRow(item.id)}
                                                    >
                                                        <img alt="t" className="" src={editIcon}></img>{" "}
                                                    </a>
                                                </span>
                                                : ""}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(DynamicTableComponent);