import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardContent from "@mui/material/CardContent";
import {DateFormat} from "../common/utils/DateUtil";
import { useTranslation } from "react-i18next";
import { useStyles } from "../common/ThemeStye";



const ShipmentReviewerSection = (props) => {
    const { actions,id } = props;

    const { t, i18n } = useTranslation();

    const classes = useStyles();
 
    const [reviewShippingData, setreviewShippingData] = useState([])
    
    useEffect(() => {
        actions.getAllShippingData().then((res) => {

            let data = res?.data?.filter(
                (x) => x?.id == id?.id.toString()
            );
            setreviewShippingData(data)
        })
    }, [actions, id?.id]);





    return (
        <>
            <br></br>
            <Card variant="outlined">
                <CardHeader
                    title={
                        <Typography className="heading_box">
                            {" "}
                            {t("navbar.Regulatory_Reviewer")}{" "}
                        </Typography>
                    }
                    className={classes.header}
                />
                <CardContent>
                    <div className="row  mt-2 mr-0 no-marrgin ">
                        <div className="col-lg-3  col-12 form-outline form-margin">
                            <label className="form-label">{ t("regulatory_reviewer.Regulatory Reviewer 1_Comment")}:</label>
                            <span>{reviewShippingData.length === 0 ? "" : reviewShippingData[0].review1comments}</span>
                        </div>

                        <div className="col-lg-3  col-12 form-outline form-margin">
                            <label className="form-label">{ t("regulatory_reviewer.Regulatory Reviewer 1 Created_Date")}:</label>
                            <span>{reviewShippingData.length === 0 ? "" : reviewShippingData[0].reviewer1recreatedate === null ? "":  DateFormat(reviewShippingData[0].reviewer1recreatedate,i18n.resolvedLanguage)}</span>
                        </div>

                        <div className="col-lg-3 col-12 form-outline form-margin">
                            <label className="form-label">{t("regulatory_reviewer.Regulatory Reviewer 1_By")}:</label>

                            <span>{reviewShippingData.length === 0 ? "" : reviewShippingData[0].review1by}</span>

                        </div>

                        

                    </div>

                    <div className="row  mt-2 mr-0 no-marrgin ">
                        <div className="col-lg-3  col-12 form-outline form-margin">
                            <label className="form-label">{ t("regulatory_reviewer.Regulatory Reviewer 2_Comment")}:</label>
                            <span>{reviewShippingData.length === 0 ? "" : reviewShippingData[0].review2comments}</span>
                        </div>

                        <div className="col-lg-3  col-12 form-outline form-margin">
                            <label className="form-label">{ t("regulatory_reviewer.Regulatory Reviewer 2 Created_Date")}:</label>
                            <span>{reviewShippingData.length === 0 ? "" : reviewShippingData[0].reviewer2recreatedate === null ? "" : DateFormat(reviewShippingData[0].reviewer2recreatedate,i18n.resolvedLanguage)}</span>
                        </div>

                        <div className="col-lg-3 col-12 form-outline form-margin">
                            <label className="form-label">{t("regulatory_reviewer.Regulatory Reviewer 2_By")}:</label>

                            <span>{reviewShippingData.length === 0 ? "" : reviewShippingData[0].review2by}</span>

                        </div>

                   

                    </div>

                </CardContent>
            </Card>
        </>
    );
};

export default ShipmentReviewerSection;
