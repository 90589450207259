import React from "react";
import { get } from "lodash";
import FileManager from "../common/FileManager/FileManager";
import { useTranslation } from "react-i18next";
import { ifRequestCompletedApprovals } from "../../Services/util";
import _ from "lodash";
import {
  STATUS,
} from "../../constants";
const ShippingMaterialDetails = (props) => {
  const { t } = useTranslation();
  const { shippingMaterialSelectedValue, countries, phases, curencys, materialSpecificData,
    shippingRequestDetails, allUnits, allCurrency, allContainer } = props;
    // const [currency, setCurrency] = useState(null);
  const getContainerTypeName = (id) => {
    let typeOfContainer = allContainer.find((i) => i.id === id) || "";
    return typeOfContainer.container_type;
  };
  const getMaterialNameifBlank = () => {
    if (
      Object.keys(shippingMaterialSelectedValue).length !== 0 &&
      Object.getPrototypeOf(shippingMaterialSelectedValue.materailName) ===
        Object.prototype
    ) {
      return shippingMaterialSelectedValue.materailName.description;
    } else {
      return shippingMaterialSelectedValue.materailName;
    }
  };

  const isunitvalid = (obj) =>{
    if(obj?.unitvalid == null || Object.keys(obj).length == 0) return true
    if(obj?.unitvalid == true) return true
    return false;
  }

  const iscontainervalid = (obj) =>{
    if(obj?.containervalid == null || Object.keys(obj).length == 0) return true
    if(obj?.containervalid == true) return true
    return false;
  }

  const isphasevalid = (obj) =>{
    if(obj?.phasevalid == null || Object.keys(obj).length == 0) return true
    if(obj?.phasevalid == 1) return true
    return false;
  }
  
  const iscurrencyvalid = (obj) =>{
    if(obj?.currencyvalid == null || Object.keys(obj).length == 0) return true
    if(obj?.currencyvalid == true) return true
    return false;
  }

  const inprogressstatus = [
    null,
    undefined,
    STATUS.INPUT,
    STATUS.AWAITING_FOR_APPROVAL,
  ]

  // const getCurrencyCode = (id) => {
  //   if (typeof curencys === "string") {
  //     if (curencys === "1") {
  //       return "USD";
  //     } else if (curencys === "2") {
  //       return "EURO";
  //     } else if (curencys === "3") {
  //       return "REST OF WORLD";
  //     } else {
  //       return curencys;t
  //     }
  //   } else {
  //     if (curencys === 1) {
  //       return "USD";
  //     } else if (curencys === 2) {
  //       return "EURO";
  //     } else if (curencys === 3) {
  //       return "REST OF WORLD";
  //     } else {
  //       return curencys;
  //     }
  //   }
  // };

  const getCurrencyCode = (id) => {
    if (id) {
      return inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
        ? allCurrency?.find(item => item.id === parseInt(id) && item.is_active === true)?.currency_code
        :allCurrency?.find(item => item.id === parseInt(id))?.currency_code
    }
    return id;
  }
  return (
    <>
      <div>
        <label className="col-form-label ml-2" >
          {t("shippingrequest.material(name)")}{" "}
        </label>{" "}
        <label  className='ml-2'>{getMaterialNameifBlank()}</label>
      </div>

      <div style={{ display: "flex" }}>
        <label className="col-form-label ml-2">
          <br />
          {t("materials.tariff_classification_no")}
        </label>
        {""}
        <table className="table">
          <thead>
            <tr>
              <th>{t("materials.hs_germany")} </th>
              <th>{t("materials.htsus")}</th>
              <th>{t("materials.hs_foreign")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{shippingMaterialSelectedValue?.hts_code_germany}</td>
              <td>{get(shippingMaterialSelectedValue, "htsCode", null)}</td>
              <td>{shippingMaterialSelectedValue?.hts_code_china}</td>
            </tr>
          </tbody>
        </table>
        {/* <div>
          <label className="col-form-label ml-2">
           {t("materials.hs_germany")}{" "}
          </label>{" "}
          <label className="ml-2">
            {shippingMaterialSelectedValue?.hts_code_germany}
          </label>
        </div>

        <div>
          <label className="col-form-label ml-2">
          {t("materials.htsus")}{" "}
          </label>{" "}
          <label className="ml-2">
            {get(shippingMaterialSelectedValue, "htsCode", null)}
          </label>
        </div>

        <div>
          <label className="col-form-label ml-2">
          {t("materials.hs_foreign")}{" "}
          </label>{" "}
          <label className="ml-2">
            {shippingMaterialSelectedValue?.hts_code_china}
          </label>
        </div> */}
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("shipping_log_fields.is_hazardous")}{" "}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "isHazardous", false)
            ? "True"
            : "False"}
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("shipping_log_fields.hazardous_sds")}{" "}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "hazardousds", false)
            ? "True"
            : "False"}
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("materials.unNo")}{" "}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "un_no", null)}
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("material.Class_Sub")}{" "}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "transportClassification", null)}
        </label>
      </div>

      <div>
        <label className="col-form-label ml-2">
          {t("material.pck_grp")}{" "}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "packGroup", null)}
        </label>
      </div>




      <div>
        <label className="col-form-label ml-2">
          {t("shipping_log_fields.attach_documents")}{" "}
        </label>{" "}
        <label className="ml-2">
          {/* <If
            condition={
              get(shippingMaterialSelectedValue, "hazard_doc_name", false) &&
              get(shippingMaterialSelectedValue, "hazard_doc_name", false) !==
                "undefined"
            }
          >
            <Then>
              <div className="fileDisplaySection">
              
              </div>
            </Then>
          </If> */}
          <FileManager
            tableName="Shipping_material"
            files={get(shippingMaterialSelectedValue, "documents", [])}
            onChange={() => {}}
            multiple={false}
            readOnly={true}
          />
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
        {t("shipping_request.blinded_material")}{" "}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "isBlindedMaterial", false)
            ? "True"
            : "False"}
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("shippingrequest.quantityOfContainer(s)")}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "quantity", null)}
        </label>
      </div>
      <div style={{border:(iscontainervalid(shippingMaterialSelectedValue))?"":" 3px solid orange" }}>
        <label className="col-form-label ml-2">
          {t("shippingrequest.type_of_container")}
        </label>{" "}
        <label className="ml-2">
          {
            getContainerTypeName(get(shippingMaterialSelectedValue, "containerType", null))
          }
        </label>
      </div>
      <div style={{border:(isunitvalid(shippingMaterialSelectedValue))?"":"3px solid orange"}}>
        <label className="col-form-label ml-2">
          {t("shippingrequest.unitPerContainer")}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "unit", null)} {" "}
          {inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
            ? allUnits?.find(item =>
              item.abbrevaton === get(shippingMaterialSelectedValue, "unitType", null)
              && item.isactive === true
            )?.abbrevaton
             : get(shippingMaterialSelectedValue, "unitType", null)}
            
        </label>
      </div>
      <div style={{border:(iscurrencyvalid(shippingMaterialSelectedValue))?"":" 3px solid orange" }}>
        <label className="col-form-label ml-2">
          {t("shippingrequest.total_value_of_material")}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "amountAndUnit", null)}{" "}
              {inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
            ?  allCurrency?.find(item =>
              item.currency_code === getCurrencyCode(get(shippingMaterialSelectedValue, "curency", null)) &&
              item.is_active === true
            )?.currency_code
            :getCurrencyCode(get(shippingMaterialSelectedValue, "curency", null))}
          
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("process_shipping_request.batch_no")}
        </label>{" "}
        <label className="ml-2">{shippingMaterialSelectedValue.batchNo}</label>
      </div>

      <div style={{border:(isphasevalid(shippingMaterialSelectedValue))?"":"3px solid orange"}}>
        <label className="col-form-label ml-2">
          {t("process_shipping_request.aggregation_state")}
        </label>{" "}
        <label className="ml-2">
          {" "}
          {(function () {
            const aggregationName = shippingMaterialSelectedValue.aggregation
              ? shippingMaterialSelectedValue.aggregation
              : shippingMaterialSelectedValue.aggregationName;
            if (
              aggregationName &&
              !Number.isSafeInteger(Number(aggregationName))
            ) {
              return aggregationName;
            } else if (
              aggregationName &&
              Number.isSafeInteger(Number(aggregationName))
            ) {

              const phase = inprogressstatus.includes(_.get(shippingRequestDetails, ["0", "status"], undefined))
                ? phases.find((item) => item.id == aggregationName && item.is_active === true)
                : phases.find((item) => item.id == aggregationName);
                

              return phase?.phase_english;
            }
            return aggregationName;
          })()}
        </label>
      </div>

      <div>
        <label className="col-form-label ml-2">
          {t("process_shipping_request.country_of_origin")}
        </label>{" "}
        <label className="ml-2">
          {" "}
          {(function () {
            const countryName = shippingMaterialSelectedValue.countryName
              ? shippingMaterialSelectedValue.countryName
              : shippingMaterialSelectedValue.countryOrigin;
            if (countryName && !Number.isSafeInteger(Number(countryName))) {
              return countryName;
            } else if (
              countryName &&
              Number.isSafeInteger(Number(countryName))
            ) {
              const country = countries.find((item) => item.id == countryName);
              return country?.name;
            }
            return null;
          })()}
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("process_shipping_request.country_of_manufacturer")}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "manufactureCountry", null)}
        </label>
      </div>
      <div>
        <label className="col-form-label ml-2">
          {t("shipping_request.Comments")}
        </label>{" "}
        <label className="ml-2">
          {get(shippingMaterialSelectedValue, "comment", null)}
        </label>
      </div>
    </>
  );
};

export default ShippingMaterialDetails;
