import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import recipients from "../../../redux/recipients";
import shipping from "../../../redux/shipping";
import address from "../../../redux/address";
import RecipientsComponent from "./RecipientsComponent";
import AddRecipientsComponent from "./AddRecipientsComponent";
import InspectRecipientsComponent from "./InspectRecipientsComponent";
import tableListing from "../../../redux/tableListing";
const {
  getAllrecipients,
  getAllTitle,
  saveRecipients,
  updateRecipients,
  updateRecipientsAccess,
  updateRecipientsApproval,
  getSelectedRecipientData,
  setRecipentTableOptions,
} = recipients.actions;
const { getAddress, getAllAddress, getAllAddressList } = address.actions;
const { getCountries, finalRecipientSelect, clearFinalRecipientSelect, destinationAddrSelect, setShipper } =
  shipping.actions;

const {saveTableData} = tableListing.actions;

const mapStateToProps = (state) => ({
  allRecipients: state[recipients.name].allRecipients,
  countries: state[shipping.name].countries,
  allTitle: state[recipients.name].allTitle,
  allAddress: state[address.name].allAddress,
  redirectTo: state[shipping.name].redirectTo,
  allAddressList: state[address.name].allAddressList,
  tableRecipientOptions: state[recipients.name].tableRecipientOptions,
});



const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setRecipentTableOptions,
      getAllrecipients,
      getAllAddress,
      getCountries,
      getAllTitle,
      getAddress,
      saveRecipients,
      updateRecipients,
      updateRecipientsAccess,
      updateRecipientsApproval,
      finalRecipientSelect,
      clearFinalRecipientSelect,
      getAllAddressList,
      destinationAddrSelect,
      setShipper,
      saveTableData,
      getSelectedRecipientData,
    },
    dispatch,
  ),
});

export default {
  RecipientsComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecipientsComponent),
  AddRecipientsComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddRecipientsComponent),
  InspectRecipientsComponent: connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InspectRecipientsComponent),
};
